import { type ReactNode } from "react";

import { cn } from "@/utils";

const QuinoPopoverTitle = ({
  title,
  description,
  className,
}: {
  title?: ReactNode | ReactNode[];
  description?: ReactNode | ReactNode[];
  className?: string;
}) => {
  return (
    <article className={cn("flex flex-col gap-2 p-2 pb-4", className)}>
      {title && <h2 className="line-clamp-2 text-xs font-bold text-primary-onBg">{title}</h2>}
      {description && <p className="text-xs text-primitive-grey-800">{description}</p>}
    </article>
  );
};
export default QuinoPopoverTitle;
