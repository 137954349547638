import { useMemo } from "react";

import { NavLink } from "react-router-dom";

import AvatarPlaceholder from "@/assets/svgs/v3/avatar-placeholder.svg";
import LockIcon from "@/assets/svgs/v3/lock-fill.svg";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import "@/components/v3";
import { BulletIcon, WorkspaceSettingsButton } from "@/components/v3";
import { type WorkspaceListItem } from "@/types/schemas/api/workspaceEntity.ts";
import { cn } from "@/utils";

const WorkspaceCard = ({ workspace }: { workspace: WorkspaceListItem }) => {
  const avatarImage = useMemo(
    () => (workspace?.imageUrl ? workspace.imageUrl : workspace.numOfUsers > 1 ? AvatarPlaceholder : LockIcon),
    [workspace?.imageUrl, workspace.numOfUsers],
  );

  const subtitle = useMemo(() => {
    if (workspace.numOfUsers > 1)
      return (
        <>
          <Typography className="text-secondary-onBg" size="extra-small">
            {workspace.numOfUsers} members
          </Typography>
          <span className="h-1 w-1">
            <BulletIcon />
          </span>
          <Typography className="text-secondary-onBg" size="extra-small">
            {workspace.numOfProjects} projects
          </Typography>
        </>
      );
    return (
      <Typography className="text-secondary-onBg" size="extra-small">
        {workspace.numOfProjects} projects
      </Typography>
    );
  }, [workspace.numOfProjects, workspace.numOfUsers]);
  return (
    <NavLink
      className={({ isActive }) =>
        cn(
          "group flex cursor-pointer flex-row items-center justify-between gap-2 rounded-lg p-2 transition-colors hover:bg-tertiary-container-hover lg:w-[16.5rem]",
          isActive && "bg-container-tertiary",
        )
      }
      to={workspace.id}
    >
      <section className="flex flex-row gap-2">
        <Avatar
          className="bg-primitive-purple-300 p-2.5 data-[has-image=true]:p-0"
          data-has-image={!!workspace?.imageUrl}
        >
          <AvatarImage alt="@shadcn" src={avatarImage} />
          <AvatarFallback className="bg-primitive-purple-300">W</AvatarFallback>
        </Avatar>
        <section className="hidden flex-col justify-center gap-1 lg:flex">
          <Typography className="line-clamp-1 font-bold !leading-[110%] text-primary" size="sm">
            {workspace.name}
          </Typography>
          <section className="flex flex-row items-center gap-2">{subtitle}</section>
        </section>
      </section>
      <section className="hidden h-8 w-8 opacity-0 transition-opacity group-hover:opacity-100 lg:flex">
        <WorkspaceSettingsButton workspaceId={workspace.id} />
      </section>
    </NavLink>
  );
};

export default WorkspaceCard;
