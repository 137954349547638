import { Svg, type SVGProps, Path } from "@react-pdf/renderer";

const QuinoMark = (props: SVGProps) => (
  <Svg {...props} fill="none" height="816" viewBox="0 0 856 816" width="856">
    <Path
      d="M240.902 561.893C261.748 581.654 273.217 608.311 273.217 636.984H319.713C319.713 595.448 303.052 556.779 272.907 528.184L240.902 561.893Z"
      fill="#33124D"
      fillOpacity={0.05}
    />
    <Path
      d="M177.344 415.764V462.259C260.029 462.182 327.292 394.841 327.292 312.156H280.797C280.874 369.268 234.456 415.764 177.344 415.764Z"
      fill="#33124D"
      fillOpacity={0.05}
    />
    <Path
      d="M724.763 140.107H660.754C659.979 62.6917 596.822 0 519.329 0C469.269 0 425.253 26.1926 400.068 65.5589C376.123 31.6946 336.756 9.45412 292.198 9.45412C222.299 9.45412 165.032 64.0866 160.615 132.9H160.46C160.46 133.365 160.538 133.753 160.538 134.218C160.383 136.697 160.15 139.1 160.15 141.579C160.15 143.129 160.15 144.679 160.228 146.229C104.898 177.304 69.9486 236.198 69.9486 300.595C69.9486 307.104 70.336 313.691 71.0335 320.278C26.0101 360.419 0.4375 417.221 0.4375 478.053C0.4375 554.539 40.6563 623.662 106.215 661.479C113.5 742.614 181.926 806.468 264.921 806.468C321.955 806.468 372.093 776.478 400.3 731.222C429.515 781.98 484.148 815.999 546.607 815.999C632.701 815.999 704.072 751.06 714.146 667.601H724.763C796.986 667.601 855.571 609.016 855.571 536.793V270.992C855.648 198.614 797.064 140.107 724.763 140.107ZM377.75 650.087L377.595 652.955C374.573 712.934 325.055 759.972 264.921 759.972C202.771 759.972 152.091 709.369 152.091 647.22V647.065C152.168 612.891 168.984 580.731 197.037 561.126L170.379 522.999C140.622 543.767 119.699 574.299 110.632 608.629C70.7235 577.632 46.9332 530.051 46.9332 478.053C46.9332 426.985 70.0261 379.56 110.322 347.943L121.481 339.186L118.847 325.315C117.297 317.101 116.522 308.809 116.522 300.595C116.522 256.501 138.84 215.895 174.796 191.95C190.837 223.722 219.432 248.752 254.924 259.136L268.02 214.5C232.528 204.039 207.653 171.104 207.111 134.218C210.831 90.4341 247.562 55.9498 292.275 55.9498C339.469 55.9498 377.828 94.3088 377.828 141.502V650.087H377.75ZM651.997 572.672H468.727V526.176H651.997V572.672ZM787.842 454.495H468.727V408H787.842V454.495ZM787.842 335.466H468.727V288.971H787.842V335.466Z"
      fill="#33124D"
      fillOpacity={0.05}
    />
  </Svg>
);

export { QuinoMark };
