import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const ArrowUpRight = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-4 w-4 fill-default", props.className)}
  >
    <path
      d="M12.0001 3.33325C12.1769 3.33325 12.3465 3.40349 12.4715 3.52851C12.5965 3.65354 12.6668 3.82311 12.6668 3.99992V9.33325C12.6668 9.51006 12.5965 9.67963 12.4715 9.80466C12.3465 9.92968 12.1769 9.99992 12.0001 9.99992C11.8233 9.99992 11.6537 9.92968 11.5287 9.80466C11.4037 9.67963 11.3335 9.51006 11.3335 9.33325V5.60925L4.70012 12.2426C4.57439 12.364 4.40598 12.4312 4.23119 12.4297C4.05639 12.4282 3.88918 12.3581 3.76557 12.2345C3.64197 12.1109 3.57186 11.9437 3.57034 11.7689C3.56882 11.5941 3.63601 11.4257 3.75745 11.2999L10.3908 4.66659H6.66679C6.48998 4.66659 6.32041 4.59635 6.19538 4.47132C6.07036 4.3463 6.00012 4.17673 6.00012 3.99992C6.00012 3.82311 6.07036 3.65354 6.19538 3.52851C6.32041 3.40349 6.48998 3.33325 6.66679 3.33325H12.0001Z"
      fill="inherit"
    />
  </svg>
);
export default ArrowUpRight;
