import { useEffect } from "react";

import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useConsentCookie } from "@/service/cookies.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

function CookieConsentModal(): JSX.Element {
  const [open, setModalKey] = useModalState(ModalKeys.COOKIE_NOTICE_MODAL_OPEN);
  const { acceptCookie, rejectCookie, isPresent } = useConsentCookie();
  enum Action {
    ACCEPT,
    REJECT,
  }

  const handleAction = (action: Action): void => {
    if (action === Action.ACCEPT) acceptCookie();
    else rejectCookie();
  };

  useEffect(() => {
    if (isPresent) setModalKey(false);
  }, [isPresent, setModalKey]);

  return (
    <Dialog open={open}>
      <DialogContent hideClose className="gap-6">
        <DialogHeader className="gap-6">
          <DialogTitle className="!mt-0 text-2xl">Cookie Policy</DialogTitle>
          <DialogDescription asChild className="!text-base font-normal">
            <h3>
              We use cookies and similar technologies to help personalize content and provide a better experience. By
              clicking accept, you agree to this, as outlined in our Cookie Policy.
            </h3>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button id={`close-yes-no-modal-cookie-consent`} variant="ghost" onClick={() => handleAction(Action.REJECT)}>
            Reject
          </Button>
          <Button id={`accept-yes-no-modal-cookie-consent`} onClick={() => handleAction(Action.ACCEPT)}>
            Accept
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default CookieConsentModal;
