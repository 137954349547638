import { useEffect } from "react";

import { Link } from "react-router-dom";

import { UpgradeForAccess } from "@/components/icons/summary";
import { Button } from "@/components/ui/button.tsx";
import { isCookieConsentAccepted } from "@/service/cookies.ts";
import { useIsTemporaryPage } from "@/service/hooks/misc.ts";
import { mixpanelTrackLinks } from "@/service/mixpanel";
import { useClaimSummaryRoute } from "@/utils/route.ts";

import "./index.css";

const TemporarySummaryOverlay = ({ documentId }: { documentId: string }) => {
  const route = useClaimSummaryRoute(documentId);
  const { matched: isTemporary } = useIsTemporaryPage();
  useEffect(() => {
    setTimeout(() => {
      const isAccepted = isCookieConsentAccepted();
      if (!isAccepted) return;
      mixpanelTrackLinks("#subscribe-now-link", "prereg_fds_document_sign_in_clicked", {
        with_button: "subscribe",
      });
    }, 1000);
  }, []);
  if (!isTemporary) return null;
  return (
    <div className="absolute bottom-12 z-10 w-full">
      <div className="temporary-overlay-bg relative mx-auto flex w-full flex-col items-start gap-8 self-stretch border border-solid border-primitive-blue-200 p-6">
        <div className="text-primitive-blue-900">
          <h3 className="text-sm font-medium uppercase leading-[135%]">upgrade for access</h3>
          <h2 className="font-jakarta text-3xl font-bold leading-[135%] tracking-tight">Log in or sign up to</h2>
          <h2 className="font-jakarta text-3xl font-bold leading-[135%] tracking-tight">continue reading</h2>
        </div>
        <div>
          <Link reloadDocument id="subscribe-now-link" to={route}>
            <Button className="bg-white p-4 font-bold text-primary-onBg hover:bg-white/80">Log in or sign up</Button>
          </Link>
        </div>
        <UpgradeForAccess className="absolute right-0 top-1/2 -translate-y-1/2" />
      </div>
    </div>
  );
};
export default TemporarySummaryOverlay;
