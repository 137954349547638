import { Link } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import { VisitUpRight } from "@/components/icons";
import { Button } from "@/components/ui/button.tsx";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectId } from "@/utils";

const LinkToLibrary = () => {
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  return (
    <Link to={`${ROUTES.WORKSPACES}/${workspaceId}/${projectId}`}>
      <Button className="h-12 w-12 rounded-none p-4" size="icon" variant="icon">
        <VisitUpRight />
      </Button>
    </Link>
  );
};

export default LinkToLibrary;
