import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useNote, useUpdateNote } from "@/api/note";
import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useModalState } from "@/zustand/slices/modal-slice";

const FormSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: "Must be at least 1 character",
    })
    .max(100, { message: "Must be at most 100 characters" }),
});
type FormSchemaType = z.infer<typeof FormSchema>;

const UpdateNoteModal = () => {
  const [{ isOpen, noteId = "", workspaceId = "" }, setState] = useModalState(ModalKeys.UPDATE_NOTE_OPEN);

  const { data: note } = useNote(workspaceId ?? "", noteId ?? "", { enabled: !!noteId });

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: note?.name ?? "",
    },
  });

  const { mutate: updateNote, status } = useUpdateNote();

  const handleSubmit = useCallback(
    ({ name }: FormSchemaType) => {
      if (!note) return;
      updateNote({ noteId: note.id, name: name, workspaceId: workspaceId ?? "" });
    },
    [note, updateNote, workspaceId],
  );

  useEffect(() => {
    if (isOpen && note) {
      form.setValue("name", note.name);
    }
  }, [note, form, isOpen]);

  useEffect(() => {
    if (status === "success" || status === "error") {
      setState({ isOpen: false, noteId: null, workspaceId: null });
    }
  }, [setState, status]);

  return (
    <Dialog open={isOpen} onOpenChange={(open) => setState({ isOpen: open, noteId: null, workspaceId: null })}>
      <DialogContent className="gap-6">
        <DialogHeader>
          <DialogTitle>Update Note</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="px-2">
                  <FormLabel className="text-primary">Note Name</FormLabel>
                  <FormControl>
                    <Input className="resize-none" placeholder="Write here..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-6">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setState({ isOpen: false, noteId: null, workspaceId: null })}
              >
                Cancel
              </Button>
              <Button type="submit">Update Note</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateNoteModal;
