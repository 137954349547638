import { useCallback, useMemo } from "react";

import { ModalKeys } from "@/assets/constants/modal";
import UploadProfilePicture from "@/components/modals/account/upload-profile-picture";
import { useAppAuthState, useDeletePhoto } from "@/firebase/hooks";
import { mixpanelTrack } from "@/service/mixpanel";
import { useModalState } from "@/zustand/slices/modal-slice";

import { ChangeAvatarIcon, DeleteAvatarIcon } from "../icons";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import MyUserAvatar from "../user/my-user-avatar";

const ProfilePictureEditor = () => {
  const [user] = useAppAuthState();
  const [, setProfilePictureUploadModalState] = useModalState(ModalKeys.UPLOAD_PROFILE_PICTURE_MODAL_OPEN);
  const openProfilePictureUploadModal = useCallback(
    () => setProfilePictureUploadModalState(true),
    [setProfilePictureUploadModalState],
  );
  const { mutate: deletePhoto } = useDeletePhoto();
  const hasImage = useMemo(() => !!user?.photoURL, [user?.photoURL]);

  return (
    <div>
      <UploadProfilePicture />
      <Popover>
        <PopoverTrigger>
          <div onClick={hasImage ? () => null : openProfilePictureUploadModal}>
            <MyUserAvatar large={true} />
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-[240px] rounded-[12px] p-2 shadow-md data-[visible=false]:hidden"
          data-visible={hasImage}
        >
          <button
            className="flex w-full cursor-pointer items-center gap-3 rounded-md p-2 text-label-l3-regular font-normal text-primary-onBg hover:bg-slate-50"
            onClick={openProfilePictureUploadModal}
          >
            <ChangeAvatarIcon />
            Change Avatar
          </button>
          <button
            className="flex w-full cursor-pointer items-center gap-3 rounded-md p-2 text-label-l3-regular font-normal text-primary-onBg hover:bg-slate-50"
            onClick={() => {
              mixpanelTrack("profile_image_delete");
              deletePhoto();
            }}
          >
            <DeleteAvatarIcon />
            Delete
          </button>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ProfilePictureEditor;
