import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { auth } from "@/firebase";
import { queryClient } from "@/service/react-query.ts";
import type { WorkspaceListItem } from "@/types/schemas/api/workspaceEntity.ts";

export const getWorkspaceFromList = (workspaceId: string) => {
  const data = queryClient.getQueryData<WorkspaceListItem[]>([auth.currentUser?.uid, QUERY_KEYS.WORKSPACES]);

  return data?.find((item) => item.id === workspaceId);
};
