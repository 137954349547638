import { useAssignAdmin, useRemoveMember } from "@/api/workspace.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { ChevronDown, DeleteUserIcon, UserAdd } from "@/components/v3";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";

const MemberRoleSelector = ({ userId }: { userId: string }) => {
  const { mutate: assignAdmin } = useAssignAdmin();
  const { mutate: removeMember } = useRemoveMember();
  const currentWorkspaceId = useCurrentWorkspaceId();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex flex-row gap-2 border-0 p-0 !text-xs !font-bold text-secondary-onBg">
        Member
        <ChevronDown className="h-4 w-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center" className="z-[4000] border-stroke-default p-2">
        <DropdownMenuItem
          className="cursor-pointer justify-between gap-6 rounded-md p-2 text-label-l3-regular text-primary hover:bg-tertiary-container-hover"
          onClick={() =>
            assignAdmin({
              workspaceId: currentWorkspaceId,
              newAdminId: userId,
            })
          }
        >
          Make admin <UserAdd className="h-4 w-4 fill-default" />
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer justify-between gap-6 rounded-md p-2 text-label-l3-regular text-primary hover:bg-tertiary-container-hover"
          onClick={() =>
            removeMember({
              workspaceId: currentWorkspaceId,
              userId,
            })
          }
        >
          Remove <DeleteUserIcon />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export default MemberRoleSelector;
