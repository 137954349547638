import { useCallback, useMemo } from "react";

import {
  keepPreviousData,
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useAuthState } from "react-firebase-hooks/auth";

import { fetchMySubscription } from "@/api/document";
import { useProjectData } from "@/api/project";
import { deleteRequest, postRequest } from "@/api/utils";
import { BACKEND_URL, ResourceCardType } from "@/assets/constants/constants";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { auth } from "@/firebase";
import { useAppAuthState } from "@/firebase/hooks.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router";
import { type QueryConfig } from "@/types/react-query";
import { basicArrayStringResponseSchema, type FolderElement, type ProjectEntity } from "@/types/schemas";
import { type WorkspaceEntity } from "@/types/schemas/api/workspaceEntity.ts";
import { useCurrentProjectId, validateZodSchema } from "@/utils";

const searchItemsInProject = async (
  projectId: string,
  params: { searchText: string; offset: number; limit: number },
) => {
  return postRequest<FolderElement[]>(`${BACKEND_URL}/v2/projects/search/${projectId}`, params);
};

const deleteMultipleResources = async ({ resourceIds }: { resourceIds: string[] }) =>
  validateZodSchema(
    deleteRequest<string[]>(`${BACKEND_URL}/v2/folders/multiple-folder-element`, resourceIds),
    basicArrayStringResponseSchema,
  );

export const useProjectChildElements = (
  workspaceId: string,
  projectId: string,
  options?: QueryConfig<ProjectEntity, unknown, FolderElement[]>,
) => {
  const { data, ...rest } = useProjectData(workspaceId, projectId, { enabled: options?.enabled });

  const memoData = useMemo(() => data?.directChildElements ?? [], [data]);

  return { data: memoData, ...rest };
};

export const useProjectFolders = (
  workspaceId: string,
  projectId: string,
  options?: QueryConfig<ProjectEntity, unknown, FolderElement[]>,
) => {
  const { data, ...rest } = useProjectData(workspaceId, projectId, { enabled: options?.enabled });
  const memoData = useMemo(
    () => data?.directChildElements.filter(({ elementType }) => elementType === ResourceCardType.FOLDER_ELEMENT) ?? [],
    [data],
  );

  return { data: memoData, ...rest };
};

export const useProjectFiles = (
  workspaceId: string,
  projectId: string,
  options?: QueryConfig<ProjectEntity, unknown, FolderElement[]>,
) => {
  const { data, ...rest } = useProjectData(workspaceId, projectId, { enabled: options?.enabled });

  const memoData = useMemo(
    () => data?.directChildElements.filter(({ elementType }) => elementType !== ResourceCardType.FOLDER_ELEMENT) ?? [],
    [data],
  );

  return { data: memoData, ...rest };
};

export const useFiles = () => {
  const currentWorkspaceId = useCurrentWorkspaceId()!;
  const currentProjectId = useCurrentProjectId()!;

  return useProjectFiles(currentWorkspaceId, currentProjectId ?? "", {
    enabled: !!currentProjectId,
    refetchOnMount: "always",
  });
};

export const useFolders = () => {
  const currentWorkspaceId = useCurrentWorkspaceId()!;
  const currentProjectId = useCurrentProjectId()!;
  // const queryClient = useQueryClient();

  return useProjectFolders(currentWorkspaceId, currentProjectId ?? "", {
    enabled: !!currentProjectId,
  });
};

export const useProjectSearchResults = (
  projectId: string,
  searchText: string,
  page: number,
  options?: QueryConfig<FolderElement[]>,
) => {
  const itemsPerPage = 10;
  return useQuery({
    queryKey: ["search-result", searchText],
    queryFn: () =>
      searchItemsInProject(projectId, {
        searchText,
        offset: page * itemsPerPage,
        limit: itemsPerPage,
      }),
    ...options,
    placeholderData: keepPreviousData,
  });
};

export const useAddDocumentToStore = () => {
  const queryClient = useQueryClient();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const [user] = useAppAuthState();
  return useCallback(
    (resource: FolderElement) => {
      queryClient.setQueryData<WorkspaceEntity>([user?.uid, QUERY_KEYS.WORKSPACES, currentWorkspaceId], (oldData) => {
        if (!oldData) return oldData;
        const newData = { ...oldData };
        const idx = newData.projects.findIndex((item) => item.id === resource.id);
        if (idx !== -1) {
          newData.projects = { ...newData.projects };
          newData.projects[idx].directChildElements.push(resource);
        }
        return newData;
      });
      queryClient.setQueryData<ProjectEntity>(
        [QUERY_KEYS.PROJECTS, currentWorkspaceId, resource.projectId],
        (oldData) =>
          oldData
            ? {
                ...oldData,
                directChildElements: [...oldData.directChildElements, resource],
              }
            : oldData,
      );
    },
    [currentWorkspaceId, queryClient, user?.uid],
  );
};

export const useDeleteMultipleResources = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMultipleResources>>,
    unknown,
    Parameters<typeof deleteMultipleResources>[0]
  >,
) => {
  const queryClient = useQueryClient();
  const projectId = useCurrentProjectId();

  return useMutation({
    ...options,
    mutationFn: deleteMultipleResources,
    onSuccess: (_data, vars) => {
      queryClient.setQueryData<ProjectEntity>([QUERY_KEYS.PROJECTS, projectId], (oldData) =>
        oldData
          ? {
              ...oldData,
              directChildElements: oldData.directChildElements.filter(({ id }) => !vars.resourceIds.includes(id)),
            }
          : oldData,
      );
      toast({
        title: `${vars.resourceIds.length} resources deleted`,
        variant: "success",
      });
    },
  });
};

export const useSubscription = () => {
  const [user] = useAuthState(auth);

  return useQuery({
    queryKey: [QUERY_KEYS.MY_SUBSCRIPTION, user?.uid],
    queryFn: () => fetchMySubscription(),
    enabled: user !== null && user !== undefined,
    retry: 4,
    //exponential delay
    retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
