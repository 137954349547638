import { z } from "zod";

export const universityStructureSchema = z
  .object({
    "state-provice": z.string().nullable(),
    domains: z.array(z.string()),
    web_pages: z.array(z.string()),
    country: z.string(),
    alpha_two_code: z.string(),
    name: z.string(),
  })
  .strict();

export const universityStructureArraySchema = z.array(universityStructureSchema);

export type UniversityStructure = z.infer<typeof universityStructureSchema>;
export type UniversityStructureArray = z.infer<typeof universityStructureArraySchema>;
