import { useCallback, useMemo } from "react";

import { type FlashCardVariant } from "@/assets/constants/constants";
import { TutorialVariant } from "@/assets/constants/modal";
import { useTutorial } from "@/service/hooks/tutorial.tsx";
import { type TutorialSlice } from "@/types/app.ts";
import useAppStateStore from "@/zustand/store";

export const useAILoading = (): [boolean, (loading: boolean) => void] => {
  return useAppStateStore((state) => [state.AILoading, state.setAILoading]);
};

export const useDocumentSearchTarget = ({ documentId }: { documentId: string }) => {
  return useAppStateStore((state) => {
    if (Object.hasOwn(state.pdfViewerSearchTarget, documentId)) return state.pdfViewerSearchTarget[documentId];
    return undefined;
  });
};

export const useFlashCardCache = (variant: FlashCardVariant) =>
  useAppStateStore((state) => state.flashCardCache[variant]);

export const useTutorialState = <T extends TutorialVariant>(
  variant: T,
): [TutorialSlice["tutorialStates"][T], (params: TutorialSlice["tutorialStates"][T]) => void] => {
  const [state, setTutorialState] = useAppStateStore((state) => [
    state.tutorialStates[variant],
    state.setTutorialState,
  ]);
  const callback = useCallback(
    (params: TutorialSlice["tutorialStates"][T]) => {
      setTutorialState({ variant: variant, payload: params });
    },
    [setTutorialState, variant],
  );

  return [state, callback];
};

export const useIsAnyTutorialRunning = () => {
  const documentTutorial = useTutorial(TutorialVariant.DOCUMENT_VIEW);
  const libraryTutorial = useTutorial(TutorialVariant.LIBRARY_VIEW);

  return useMemo(() => documentTutorial.run || libraryTutorial.run, [documentTutorial.run, libraryTutorial.run]);
};

export const useIsMonthly = (): [boolean, (isMonthly: boolean) => void] =>
  useAppStateStore((state) => [state.isMonthly, state.setIsMonthly]);

export const useSetLoadingState = () => useAppStateStore((state) => state.setLoadingState);

export const useParagraphEditor = (): [string, ({ editedParagraphId }: { editedParagraphId: string }) => void] => {
  const [state, setState] = useAppStateStore((state) => [state.editedParagraphId, state.setEditedParagraph]);

  return [state, setState];
};

export const useDetailsView = (): [boolean, (value: boolean) => void] =>
  useAppStateStore((state) => [state.showDetailsView, state.setShowDetailsView]);
