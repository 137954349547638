import _ from "lodash";

import { type ParagraphSummary, type SummaryResponse } from "@/types/schemas";

export const combinePages = (pages?: SummaryResponse[]): SummaryResponse | undefined => {
  if (!pages || pages.length === 0) return undefined;

  const last = JSON.parse(JSON.stringify(pages[pages.length - 1])) as SummaryResponse;
  const chapterOrderList = _.union(_.flatten(pages.map((item) => item.chapterIdOrderedList)));

  const idNamePairs = _.merge({}, ...pages.map((item) => item.chapterIdNamePairs)) as Record<
    string,
    { chapterName: string; pageNumberEnd: string; pageNumberStart: string }
  >;
  const paragraphEntities = _.merge({}, ...pages.map((item) => item.paragraphEntities)) as Record<
    string,
    ParagraphSummary
  >;

  const paragraphSummariesOfChapter = _.reduce<SummaryResponse, SummaryResponse["paragraphSummariesOfChapter"]>(
    pages,

    (result, page) => {
      Object.keys(page.paragraphSummariesOfChapter).forEach((chapterKey) => {
        if (Object.hasOwn(result, chapterKey)) {
          result[chapterKey] = _.union(result[chapterKey], page.paragraphSummariesOfChapter[chapterKey]);
        } else {
          result[chapterKey] = page.paragraphSummariesOfChapter[chapterKey];
        }
      });
      return result;
    },
    {},
  );

  last.chapterIdOrderedList = chapterOrderList;
  last.chapterIdNamePairs = idNamePairs;
  last.paragraphSummariesOfChapter = paragraphSummariesOfChapter;
  last.paragraphEntities = paragraphEntities;

  return last;
};
