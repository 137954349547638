import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import TableOfContentsHeaderLink from "@/components/account/table-of-contents-header-link";
import "@/components/account/table-of-contents.css";

const TableOfContents = ({
  headings,
  pathname,
}: {
  headings: { value: string; index: string }[];
  pathname: string;
}) => {
  const [, setChapter] = useState<string>();
  const location = useLocation();
  useEffect(() => {
    if (document !== null) {
      const headingElements = document.querySelectorAll("h1[id],h2[id],h3[id],h4[id]");
      let activeElement: HTMLElement | null;
      const observer = new IntersectionObserver(
        (entries) => {
          const visibleEntries = entries.filter((entry) => entry.isIntersecting);
          visibleEntries.sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);
          if (visibleEntries.length > 0) {
            const topEntry = visibleEntries[0];

            const summaryElement = document.getElementById(`summary-${topEntry.target.id}`);

            if (summaryElement !== null && activeElement !== summaryElement) {
              if (activeElement) {
                activeElement.classList.remove("activeState");
              }
              summaryElement.classList.add("activeState");
              activeElement = summaryElement;
              if (topEntry.target.id) {
                setChapter(topEntry.target.id);
              }
            }
          }
        },
        {
          threshold: [0],
          rootMargin: "0px 0px -70% 0px",
        },
      );

      headingElements.forEach((heading) => {
        observer.observe(heading);
      });

      return () => {
        activeElement?.classList.remove("activeState");
        headingElements.forEach((heading) => {
          observer.unobserve(heading);
        });
      };
    } else {
      return () => {};
    }
  }, [setChapter, location.hash]);

  return (
    <aside className="flex h-full flex-col items-start justify-center lg:min-w-[300px]">
      <h4 className="mb-3 text-landing-base font-bold text-primitive-purple-950">Jump to</h4>
      {headings.map((heading) => (
        <TableOfContentsHeaderLink
          index={heading.index}
          key={heading.index}
          pathname={pathname}
          value={heading.value}
        />
      ))}
    </aside>
  );
};

export default TableOfContents;
