import _ from "lodash";
import { type StateCreator } from "zustand";

import { ANALYTICS_CATEGORY, DOCUMENT_TABS, NOTE_TABS } from "@/assets/constants/constants";
import { customGAEventSender } from "@/service";
import { isDemoPage } from "@/service/hooks/misc.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { type AppSlice, type ProjectTabState, type TabSlice } from "@/types/app.ts";
import { getFromStorage } from "@/zustand/slices/utils";

export const defaultTabState: ProjectTabState = {
  activeDocumentTab: DOCUMENT_TABS.OPEN_NEW,
  activeNoteTab: NOTE_TABS.OPEN_NEW,
  documentTabs: [],
  noteTabs: [],
};
const template: string = JSON.stringify(defaultTabState);
const getNewStateTabState = (): ProjectTabState => JSON.parse(template) as ProjectTabState;

function getProjectTabState(projectId: string, state: TabSlice): ProjectTabState {
  if (!state.projectTabState.hasOwnProperty(projectId)) {
    state.projectTabState[projectId] = getNewStateTabState();
  }
  return state.projectTabState[projectId];
}

function createUpdatePayload(
  state: TabSlice,
  projectId: string,
  projectTabState: ProjectTabState,
): { [key: string]: ProjectTabState } {
  return {
    ...state.projectTabState,
    [projectId]: {
      ...projectTabState,
    },
  };
}

const createTabStateSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], TabSlice> = (set, get) => ({
  projectTabState: {},
  setProjectTabState: ({ id, state }) => {
    console.debug("start", "setProjectTabState", id);
    set(
      (prevState) => ({
        projectTabState: {
          ...prevState.projectTabState,
          [id]: { ...state },
        },
      }),
      false,
      "setProjectTabState",
    );
    console.debug("end", "setProjectTabState", id);
  },
  openDocument: (projectId, documentId) => {
    console.debug("start", "openDocument", projectId, documentId);
    set(
      (state) => {
        const projectTabs = getProjectTabState(projectId, state);
        projectTabs.activeDocumentTab = documentId;
        customGAEventSender(ANALYTICS_CATEGORY.DOCUMENT, "document_open");
        if (documentId === DOCUMENT_TABS.OPEN_NEW || projectTabs.documentTabs.includes(documentId)) {
          return {
            ...state,
            projectTabState: createUpdatePayload(state, projectId, projectTabs),
          };
        }
        projectTabs.documentTabs.push(documentId);
        return {
          ...state,
          projectTabState: createUpdatePayload(state, projectId, projectTabs),
        };
      },
      false,
      "openDocument",
    );
    console.debug("end", "openDocument", projectId, documentId);
  },
  closeDocument: (projectId: string, documentId: string) => {
    console.debug("closeDocument", projectId, documentId);

    set(
      (state) => {
        const projectTabs = getProjectTabState(projectId, state);
        projectTabs.documentTabs = projectTabs.documentTabs.filter((tab) => tab !== documentId);
        projectTabs.activeDocumentTab = DOCUMENT_TABS.OPEN_NEW;
        return {
          projectTabState: createUpdatePayload(state, projectId, projectTabs),
        };
      },
      false,
      "closeDocument",
    );
  },
  deleteDocumentOfProject: (projectId: string, documentId: string) => {
    console.debug("deleteDocumentOfProject", projectId, documentId);
    const projectTabs = getProjectTabState(projectId, get());
    projectTabs.documentTabs = projectTabs.documentTabs.filter((item) => item !== documentId);
    if (projectTabs.activeDocumentTab === documentId) projectTabs.activeDocumentTab = DOCUMENT_TABS.OPEN_NEW;
    set(
      (state) => ({
        projectTabState: createUpdatePayload(state, projectId, projectTabs),
      }),
      false,
      "deleteDocumentOfProject",
    );
  },
  openNote: (projectId: string, noteId: string) => {
    console.debug("openNote", projectId, noteId);
    set(
      (state) => {
        const projectTabs = getProjectTabState(projectId, state);
        projectTabs.activeNoteTab = noteId;
        customGAEventSender(ANALYTICS_CATEGORY.DOCUMENT, "note_open");
        if (isDemoPage())
          mixpanelTrack("note_opened", {
            note_id: noteId,
          });
        if (noteId === NOTE_TABS.OPEN_NEW || projectTabs.noteTabs.includes(noteId)) {
          return {
            ...state,
            projectTabState: createUpdatePayload(state, projectId, projectTabs),
          };
        }
        projectTabs.noteTabs.push(noteId);
        return {
          ...state,
          projectTabState: createUpdatePayload(state, projectId, projectTabs),
        };
      },
      false,
      "openNote",
    );
  },
  closeNote: (projectId: string, noteId: string) => {
    console.debug("closeNote", projectId, noteId);
    set(
      (state) => {
        const projectTabs = getProjectTabState(projectId, state);
        const index = projectTabs.noteTabs.findIndex((tab) => tab === noteId);
        if (index === -1) return {};
        projectTabs.noteTabs = projectTabs.noteTabs.filter((tab) => tab !== noteId);
        projectTabs.activeNoteTab =
          projectTabs.noteTabs.length > 0
            ? index === projectTabs.noteTabs.length
              ? projectTabs.noteTabs[index - 1]
              : projectTabs.noteTabs[index]
            : NOTE_TABS.OPEN_NEW;
        return {
          projectTabState: createUpdatePayload(state, projectId, projectTabs),
        };
      },
      false,
      "closeNote",
    );
  },
  initTabState: (projectId: string) => {
    console.debug("initTabState", projectId);
    const state = get();
    if (state.projectTabState.hasOwnProperty(projectId)) return;
    set(
      (state) => ({
        projectTabState: {
          ...state.projectTabState,
          [projectId]: getNewStateTabState(),
        },
      }),
      false,
      "initTabState",
    );
  },

  validateTabStateForProjects: ({ projectIds }: { projectIds: string[] }) => {
    let projectTabState = getFromStorage() ?? {
      DEFAULT: getNewStateTabState(),
    };
    const currentProjectStateKeys = Object.keys(projectTabState);
    const keysToRemove = _.difference(currentProjectStateKeys, projectIds);
    projectTabState = _.omit(projectTabState, keysToRemove);
    projectTabState = { ...projectTabState, DEFAULT: getNewStateTabState() };
    set({ projectTabState: projectTabState }, false, "validateTabStateForProjects");
  },
  validateTabStateStoreForProject: ({
    projectId,
    documentIds,
    noteIds,
  }: {
    projectId: string;
    documentIds?: string[];
    noteIds?: string[];
  }) => {
    let projectTabState = getFromStorage() ?? {
      DEFAULT: getNewStateTabState(),
    };
    if (!Object.hasOwn(projectTabState, projectId)) return;
    if (documentIds) {
      const documentKeysToRemove = _.difference(projectTabState[projectId].documentTabs, documentIds);
      projectTabState[projectId].documentTabs = _.filter(
        projectTabState[projectId].documentTabs,
        (value) => !documentKeysToRemove.includes(value),
      );
      if (!projectTabState[projectId].documentTabs.includes(projectTabState[projectId].activeDocumentTab))
        projectTabState[projectId].activeDocumentTab = DOCUMENT_TABS.OPEN_NEW;
    }
    if (noteIds) {
      const noteKeysToRemove = _.difference(projectTabState[projectId].noteTabs, noteIds);
      projectTabState[projectId].noteTabs = _.filter(
        projectTabState[projectId].noteTabs,
        (noteId) => !noteKeysToRemove.includes(noteId),
      );
      if (!projectTabState[projectId].noteTabs.some((noteId) => noteId === projectTabState[projectId].activeNoteTab))
        projectTabState[projectId].activeNoteTab = NOTE_TABS.OPEN_NEW;
    }
    projectTabState = { ...projectTabState, DEFAULT: getNewStateTabState() };
    set({ projectTabState: projectTabState }, false, "validateTabStateStoreForProject");
  },
});

export default createTabStateSlice;
