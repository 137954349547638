import { useCallback, useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useBoolean, useDebounceValue } from "usehooks-ts";
import { z } from "zod";

import { useSearchByText } from "@/api/user.ts";
import { ROUTES } from "@/assets/constants/constants.ts";
import MissingDocumentThumbnail from "@/assets/images/v3/missing-document-thumbnail.png";
import AvatarPlaceholder from "@/assets/svgs/v3/avatar-placeholder-purple.svg";
import GeneralProjectIcon from "@/components/library/v3/general-project-icon.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { SearchIcon } from "@/components/v3";
import { InfiniteLoading } from "@/pages/loading";
import { type SearchResponse } from "@/types/home";

const formSchema = z.object({
  searchQuery: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

const searchResponseToIcon = (response: SearchResponse) => {
  if (response.type === "PROJECT")
    return (
      <section className="flex h-12 w-12 items-center justify-center rounded bg-primitive-purple-pale-100">
        <GeneralProjectIcon className="h-6 w-6" projectIcon={response.icon} />
      </section>
    );
  else if (response.type === "WORKSPACE")
    return (
      <section className="flex h-12 w-12 items-center justify-center rounded bg-primitive-purple-pale-100">
        <img alt="Workspace image" src={response?.image ? response.image : AvatarPlaceholder} />
      </section>
    );
  return (
    <section className="flex h-15 w-12 items-center justify-center rounded bg-primitive-purple-pale-100">
      <img alt="Document image" src={response?.image ? response.image : MissingDocumentThumbnail} />
    </section>
  );
};

const searchResponseToLink = (response: SearchResponse) => {
  if (response.type === "WORKSPACE") return `${ROUTES.WORKSPACES}/${response.workspaceId}`;
  if (response.type === "PROJECT") return `${ROUTES.WORKSPACES}/${response.workspaceId}/${response.projectId}`;
  return `${ROUTES.DOCUMENTS}/${response.workspaceId}/${response.projectId}?document=${response.documentId}`;
};

const SearchModal = () => {
  const { value, setValue } = useBoolean();
  const form = useForm<FormSchema>({
    defaultValues: {
      searchQuery: "",
    },
    resolver: zodResolver(formSchema),
  });
  const { mutate: executeSearch, data, variables, isPending, reset } = useSearchByText();

  const result = useMemo(() => {
    if (!data) return null;

    if (isPending)
      return (
        <section>
          <InfiniteLoading />
        </section>
      );

    return (
      <section className="flex flex-col">
        <p className="my-6">
          {data.length} results found for “{variables.textToSearch}”
        </p>
        <section className="flex max-h-96 flex-col gap-3 overflow-y-auto">
          {data.map((item) => (
            <Link
              key={`${item.workspaceId}-${item.projectId}-${item.documentId}`}
              to={searchResponseToLink(item)}
              onClick={() => setValue(false)}
            >
              <section className="flex cursor-pointer flex-row items-center gap-3 rounded p-1 hover:bg-tertiary-container-hover/50">
                {searchResponseToIcon(item)}
                <Typography className="font-bold" size="sm" variant="secondary">
                  {item.name}
                </Typography>
              </section>
            </Link>
          ))}
        </section>
      </section>
    );
  }, [data, isPending, setValue, variables?.textToSearch]);

  const watchedInput = form.watch("searchQuery");

  const [debouncedInput] = useDebounceValue(watchedInput, 500);

  useEffect(() => {
    if (debouncedInput) {
      executeSearch({ textToSearch: debouncedInput });
    }
  }, [debouncedInput, executeSearch]);

  const onOpenChange = useCallback(
    (open: boolean) => {
      setValue(open);
      setTimeout(() => {
        reset();
      }, 500);
    },
    [reset, setValue],
  );

  return (
    <Dialog open={value} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button size="icon-extra-large" variant="icon">
          <SearchIcon />
        </Button>
      </DialogTrigger>
      <DialogContent hideClose>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(() => {})}>
            <FormField
              control={form.control}
              name="searchQuery"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input icon={<SearchIcon />} placeholder="Search in documents" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        {result}
      </DialogContent>
    </Dialog>
  );
};
export default SearchModal;
