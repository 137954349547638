import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const AddFolder = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-primitive-purple-400", props.className)}
    >
      <path
        clipRule="evenodd"
        d="M9.52 3a2 2 0 0 1 1.561.75l1.4 1.75H20a2 2 0 0 1 2 2V19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.52Zm0 2H4v14h16V7.5h-7.52a2 2 0 0 1-1.561-.75L9.519 5ZM12 9a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2H9a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default AddFolder;
