import CountryCodes, { type CountryProperty } from "country-codes-list";

export const countryCodes: { [key: string]: string } = CountryCodes.customList(
  "countryCode" as CountryProperty,
  "{countryNameEn}",
);

export const countryCodeToName = Object.keys(countryCodes).map((item) => ({
  value: item,
  name: countryCodes[item],
}));

export const getCountryName = (countryCode: string | null | undefined) => {
  if (!countryCode) return;
  return countryCodes[countryCode];
};
