import { z } from "zod";

import { FlashCardVariant } from "@/assets/constants/constants.ts";
import { SessionOrderingMethod, SessionVariant } from "@/assets/constants/flashcard.ts";

export const trueFalseFlashCardSchema = z
  .object({
    modelLogId: z.string(),
    modelError: z.boolean(),
    modelResponse: z.object({
      usedTokens: z.number(),
      trueFalseCardList: z.array(
        z.object({
          text: z.string(),
          isCorrect: z.boolean(),
        }),
      ),
    }),
  })
  .strict();

export type GeneratedTrueFalseFlashcard = z.infer<typeof trueFalseFlashCardSchema>;

export const generatedMultipleChoiceFlashCardSchema = z
  .object({
    modelLogId: z.string(),
    modelError: z.boolean(),
    modelResponse: z.object({
      usedTokens: z.number(),
      multipleChoiceQuestionCardList: z.array(
        z.object({
          question: z.string(),
          choices: z.array(
            z.object({
              text: z.string(),
              isCorrect: z.boolean(),
            }),
          ),
        }),
      ),
    }),
  })
  .strict();

export type GeneratedMultipleChoiceFlashCard = z.infer<typeof generatedMultipleChoiceFlashCardSchema>;
export const questionFlashCardSchema = z
  .object({
    modelLogId: z.string(),
    modelError: z.boolean(),
    modelResponse: z.object({
      usedTokens: z.number(),
      questionCardContentList: z.array(
        z.object({
          answer: z.string(),
          question: z.string(),
        }),
      ),
    }),
  })
  .strict();
export type GeneratedQuestionFlashCard = z.infer<typeof questionFlashCardSchema>;

export const basicFlashCardSchema = z
  .object({
    modelLogId: z.string(),
    modelError: z.boolean(),
    modelResponse: z.object({
      usedTokens: z.number(),
      baseCardContentList: z.array(
        z.object({
          concept: z.string(),
          description: z.string(),
        }),
      ),
    }),
  })
  .strict();

export type GeneratedBasicFlashCard = z.infer<typeof basicFlashCardSchema>;

export type GeneratedFlashCard =
  | GeneratedBasicFlashCard
  | GeneratedQuestionFlashCard
  | GeneratedMultipleChoiceFlashCard
  | GeneratedTrueFalseFlashcard;

const flashCardDeckCreatePayloadSchema = z
  .object({
    name: z.string(),
  })
  .strict();

export type FlashCardDeckCreatePayload = z.infer<typeof flashCardDeckCreatePayloadSchema>;

const flashCardBase = z
  .object({
    id: z.string(),
    cardType: z.nativeEnum(FlashCardVariant),
    knowledgeScore: z.number(),
    dateCreated: z.string(),
    generatedCards: basicFlashCardSchema
      .or(questionFlashCardSchema)
      .or(generatedMultipleChoiceFlashCardSchema)
      .or(trueFalseFlashCardSchema)
      .nullish(),
    userId: z.string(),
  })
  .strict();

export type FlashCardBase = z.infer<typeof flashCardBase>;

const basicFlashCardDtoSchema = flashCardBase
  .extend({
    cardType: z.literal(FlashCardVariant.BASIC),
    question: z.string().nullable(),
    questionImage: z.string().nullable(),
    answer: z.string().nullable(),
    answerImage: z.string().nullable(),
  })
  .strict();
export type BasicFlashCardDto = z.infer<typeof basicFlashCardDtoSchema>;

const multipleChoiceAnswerPayloadSchema = z
  .object({
    answer: z.string().nullable(),
    answerImage: z.string().nullable(),
    isCorrectAnswer: z.boolean(),
  })
  .strict();

export type MultipleChoiceAnswerPayload = z.infer<typeof multipleChoiceAnswerPayloadSchema>;

const multipleChoiceCardCreateSchema = z
  .object({
    cardType: z.literal(FlashCardVariant.MULTIPLE),
    question: z.string().nullable(),
    questionImage: z.string().nullable(),
    answers: z.array(multipleChoiceAnswerPayloadSchema),
  })
  .strict();

export type MultipleChoiceCardCreate = z.infer<typeof multipleChoiceCardCreateSchema>;

const multipleChoiceAnswerSchema = z
  .object({
    id: z.string(),
    answer: z.string().nullable(),
    answerImage: z.string().nullable(),
    isCorrectAnswer: z.boolean(),
  })
  .strict();
export type MultipleChoiceAnswer = z.infer<typeof multipleChoiceAnswerSchema>;

const multipleChoiceAnswerDTOSchema = multipleChoiceAnswerSchema
  .extend({
    id: z.string(),
    cardId: z.string().nullish(),
  })
  .strict();
export type MultipleChoiceAnswerDTO = z.infer<typeof multipleChoiceAnswerDTOSchema>;

const multipleFlashCardTypeSchema = flashCardBase
  .extend({
    cardType: z.literal(FlashCardVariant.MULTIPLE),
    correctAnswer: multipleChoiceAnswerSchema,
    incorrectAnswerOne: multipleChoiceAnswerSchema,
    incorrectAnswerTwo: multipleChoiceAnswerSchema,
    incorrectAnswerThree: multipleChoiceAnswerSchema,
    question: z.string().nullable(),
    questionImage: z.string().nullable(),
    generatedCards: generatedMultipleChoiceFlashCardSchema.nullish(),
  })
  .strict();

export type MultipleFlashCardType = z.infer<typeof multipleFlashCardTypeSchema>;

const trueFlaseFlashCardDtoSchema = flashCardBase
  .extend({
    cardType: z.literal(FlashCardVariant.TRUE_FALSE),
    question: z.string().nullable(),
    questionImage: z.string().nullable(),
    answer: z.boolean().optional(),
    generatedCards: generatedMultipleChoiceFlashCardSchema.nullish(),
  })
  .strict();

export type TrueFalseFlashCardDto = z.infer<typeof trueFlaseFlashCardDtoSchema>;

const multipleChoiceFlashCardDtoSchema = flashCardBase
  .extend({
    cardType: z.literal(FlashCardVariant.MULTIPLE),
    question: z.string().nullable(),
    questionImage: z.string().nullable(),
    answers: z.array(multipleChoiceAnswerDTOSchema),
  })
  .strict();

export type MultipleChoiceFlashCardDto = z.infer<typeof multipleChoiceFlashCardDtoSchema>;

export const flashCardWrapperTypeDTOSchema = basicFlashCardDtoSchema
  .or(trueFlaseFlashCardDtoSchema)
  .or(multipleChoiceFlashCardDtoSchema);
export type FlashCardWrapperTypeDTO = z.infer<typeof flashCardWrapperTypeDTOSchema>;
export type FlashCardViewTypes = BasicFlashCardDto | TrueFalseFlashCardDto | MultipleFlashCardType;

export const knowledgeSessionSchema = z
  .object({
    startDate: z.string(),
    endDate: z.string().nullable(),
    type: z.nativeEnum(SessionVariant),
    numOfCards: z.number(),
    id: z.string(),
    userId: z.string(),
    flashcardDeckId: z.string(),
    initialCard: flashCardWrapperTypeDTOSchema,
  })
  .strict();

export type KnowledgeSession = z.infer<typeof knowledgeSessionSchema>;

export const knowledgeSessionResultSchema = z
  .object({
    id: z.string(),
    start_date: z.string(),
    end_date: z.string(),
    type: z.nativeEnum(SessionVariant),
    card: z.array(flashCardWrapperTypeDTOSchema),
    answers: z.array(
      z.object({
        id: z.string(),
        startDate: z.string(),
        endDate: z.string(),
        isAnswerCorrect: z.boolean(),
        cardId: z.string(),
      }),
    ),
  })
  .strict();
export type KnowledgeSessionResult = z.infer<typeof knowledgeSessionResultSchema>;

export const knowledgeSessionInStoreSchema = knowledgeSessionSchema
  .extend({
    currentCardIndex: z.number(),
    isAd: z.boolean(),
    currentStart: z.string(),
    cards: z.array(flashCardWrapperTypeDTOSchema),
    result: knowledgeSessionResultSchema.optional(),
    done: z.boolean(),
    multipleChoiceSelectedAnswer: z.record(z.string(), z.string()),
  })
  .strict();

export type KnowledgeSessionInStore = z.infer<typeof knowledgeSessionInStoreSchema>;

const knowledgeSessionCreationPayloadSchema = z
  .object({
    type: z.nativeEnum(SessionVariant),
    ordering_method: z.nativeEnum(SessionOrderingMethod),
    numOfCards: z.number(),
    //this is an array string, kinda an antipattern but this is what the lord needs
    allowedCardTypes: z.string(),
    startDate: z.string(),
  })
  .strict();

export type KnowledgeSessionCreationPayload = z.infer<typeof knowledgeSessionCreationPayloadSchema>;

const knowledgeSessionUpdateSchema = z
  .object({
    startDate: z.string(),
    endDate: z.string(),
    isAnswerCorrect: z.boolean(),
    cardId: z.string(),
    selectedMultipleAnswer: z.string().optional(),
  })
  .strict();

export type KnowledgeSessionUpdate = z.infer<typeof knowledgeSessionUpdateSchema>;

export const baseFlashCardDeckSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    favourite: z.boolean(),
    userId: z.string(),
    dateCreated: z.string().nullable(),
    colorTag: z.string().nullish(),
    projectId: z.string(),
    dateOpened: z.string().nullable(),
  })
  .strict();

export type BaseFlashCardDeck = z.infer<typeof baseFlashCardDeckSchema>;
export const flashCardDeckSchema = baseFlashCardDeckSchema
  .extend({
    numOfCards: z.number(),
  })
  .strict();

export type FlashCardDeck = z.infer<typeof flashCardDeckSchema>;

export const extendedFlashCardDeckSchema = flashCardDeckSchema
  .extend({
    projectId: z.string(),
    cardList: z.array(flashCardWrapperTypeDTOSchema),
  })
  .strict();
export type ExtendedFlashCardDeck = z.infer<typeof extendedFlashCardDeckSchema>;

export const allFlashCardDecksResultSchema = z
  .object({
    projectId: z.string(),
    projectName: z.string(),
    flashcardDecks: z.array(flashCardDeckSchema),
  })
  .strict();
export type AllFlashCardDecksResult = z.infer<typeof allFlashCardDecksResultSchema>;

export type BasicFlashCardCreate = Omit<BasicFlashCardDto, "id" | "knowledgeScore" | "dateCreated" | "userId">;
export type TrueFalseFlashCardCreate = Omit<TrueFalseFlashCardDto, "id" | "knowledgeScore" | "dateCreated" | "userId">;

export type FlashCardCreatePayload = BasicFlashCardCreate | TrueFalseFlashCardCreate | MultipleChoiceCardCreate;
