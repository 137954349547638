import { type ReactNode } from "react";

import { useMaintenanceMode } from "@/firebase/hooks";
import Maintenance from "@/pages/maintenance";

const MaintenanceGuard = ({ children }: { children: ReactNode }) => {
  const [isMaintenance, loading] = useMaintenanceMode();
  // const { data: userData } = useAuthUserData();
  // const adBlockDetected = useDetectAdBlock();
  // const isFreeUser = useIsFreeUser(); // needs review

  // TODO REENABLE THIS LATER if (adBlockDetected && userData?.onboardingDone && subscription?.subscriptionPlan === "freemium") return null;
  // if (adBlockDetected && userData?.onboardingDone && isFreeUser) return null;

  if (loading) return null;

  if (isMaintenance) return <Maintenance />;

  return children;
};
export default MaintenanceGuard;
