import { QA_BOOK_IDS } from "@/assets/constants/demo.ts";

export const getDemoDocumentIdForQA = (documentId?: string) => {
  if (documentId !== undefined && Object.hasOwn(QA_BOOK_IDS, documentId)) {
    const _id = documentId as keyof typeof QA_BOOK_IDS;

    return QA_BOOK_IDS[_id];
  }
  return QA_BOOK_IDS["DOCUMENT_ID_ONE"];
};
