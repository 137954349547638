import type { ElementRef, SVGProps } from "react";

const SubscriptionFilledIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg {...props} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22ZM9.003 8.997C9.003 9.26222 9.10836 9.51657 9.29589 9.70411C9.48343 9.89164 9.73778 9.997 10.003 9.997H12.589L8.293 14.293C8.19749 14.3852 8.12131 14.4956 8.0689 14.6176C8.01649 14.7396 7.9889 14.8708 7.98775 15.0036C7.9866 15.1364 8.0119 15.2681 8.06218 15.391C8.11246 15.5139 8.18671 15.6255 8.28061 15.7194C8.3745 15.8133 8.48615 15.8875 8.60905 15.9378C8.73194 15.9881 8.86362 16.0134 8.9964 16.0123C9.12918 16.0111 9.2604 15.9835 9.3824 15.9311C9.50441 15.8787 9.61475 15.8025 9.707 15.707L14.003 11.411V13.997C14.003 14.2622 14.1084 14.5166 14.2959 14.7041C14.4834 14.8916 14.7378 14.997 15.003 14.997C15.2682 14.997 15.5226 14.8916 15.7101 14.7041C15.8976 14.5166 16.003 14.2622 16.003 13.997V8.997C16.003 8.73178 15.8976 8.47743 15.7101 8.28989C15.5226 8.10236 15.2682 7.997 15.003 7.997H10.003C9.73778 7.997 9.48343 8.10236 9.29589 8.28989C9.10836 8.47743 9.003 8.73178 9.003 8.997Z"
        fill="#572E9E"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SubscriptionFilledIcon;
