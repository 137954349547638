import { useCallback, useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { useNote, useUpdateParagraphNoteLocal } from "@/api/note.ts";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { type NoteEntity } from "@/types/schemas";

export const useUpdateNoteLocal = () => {
  const queryClient = useQueryClient();

  return useCallback(
    ({ noteId, payload }: { noteId: string; payload: Partial<Omit<NoteEntity, "id">> }) => {
      queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, { id: noteId }], (data) =>
        data ? { ...data, ...payload } : data,
      );
    },
    [queryClient],
  );
};

export const useRemoveAd = ({ noteId, paragraphId }: { paragraphId: string; noteId: string }) => {
  const updateParagraphNoteLocal = useUpdateParagraphNoteLocal();

  return useCallback(() => {
    updateParagraphNoteLocal({
      noteId,
      paragraphId,
      payload: {
        hasAd: false,
      },
    });
  }, [noteId, paragraphId, updateParagraphNoteLocal]);
};

export const useIsMyNote = ({ noteId, workspaceId }: { noteId: string; workspaceId: string }) => {
  const userId = useFirebaseUserId();
  const { data: note } = useNote(workspaceId, noteId);
  return useMemo(() => note?.userId === userId, [note?.userId, userId]);
};
