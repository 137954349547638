import { useMutation, type UseMutationOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { deleteRequest, getRequest, postRequest, putRequest } from "@/api/utils";
import { BACKEND_URL } from "@/assets/constants/constants";
import { toast } from "@/components/ui/use-toast";
import { useAuthUserData } from "@/firebase/hooks.ts";
import { useIsDocumentPage, useIsSharedPage } from "@/service/hooks/react-router";
import {
  type AllFlashCardDecksResult,
  allFlashCardDecksResultSchema,
  type ExtendedFlashCardDeck,
  extendedFlashCardDeckSchema,
  type FlashCardCreatePayload,
  type FlashCardWrapperTypeDTO,
  flashCardWrapperTypeDTOSchema,
  type KnowledgeSession,
  type KnowledgeSessionCreationPayload,
  type KnowledgeSessionResult,
  knowledgeSessionResultSchema,
  knowledgeSessionSchema,
  type KnowledgeSessionUpdate,
} from "@/types/schemas";
import { useCurrentProjectId, validateZodSchema } from "@/utils";
import useAppStateStore from "@/zustand/store.ts";

const CARDS = "cards";
const FLASH_CARDS = "flashcards";
const FLASH_CARD_DECK = "flashcard-deck";
const KNOWLEDGE_SESSION = "knowledge-session";

export const getFlashCardDecks = () =>
  validateZodSchema(
    getRequest<AllFlashCardDecksResult[]>(`${BACKEND_URL}/${CARDS}/${FLASH_CARD_DECK}/all`),
    z.array(allFlashCardDecksResultSchema),
  );

export const getSharedFlashCardDecks = () =>
  validateZodSchema(
    getRequest<AllFlashCardDecksResult[]>(`${BACKEND_URL}/${CARDS}/${FLASH_CARD_DECK}/shared/all`),
    allFlashCardDecksResultSchema,
  );

export const getFlashCardDeck = ({ id, openedAt }: { id: string; openedAt: "library" | "knowledge_check" }) => {
  let url = `${BACKEND_URL}/${CARDS}/${FLASH_CARD_DECK}/${id}`;
  if (openedAt) url = `${url}?opened_at=${openedAt}`;
  return validateZodSchema(getRequest<ExtendedFlashCardDeck>(url), extendedFlashCardDeckSchema);
};

export const getFlashCard = ({ id }: { id: string }) =>
  validateZodSchema(
    getRequest<FlashCardWrapperTypeDTO>(`${BACKEND_URL}/${CARDS}/${FLASH_CARDS}/${id}`),
    flashCardWrapperTypeDTOSchema,
  );

export const createCard = ({
  deckId,
  payload,
  createdAt,
  aiGenerated,
}: {
  deckId: string;
  payload: Omit<FlashCardCreatePayload, "userId">;
  aiGenerated?: boolean;
  createdAt: "note" | "knowledge_check";
}): Promise<FlashCardWrapperTypeDTO> => {
  let url = `${BACKEND_URL}/${CARDS}/${FLASH_CARDS}/${deckId}`;
  if (createdAt) url = `${url}?created_at=${createdAt}`;
  return validateZodSchema(postRequest(url, { ...payload, aiGenerated }), flashCardWrapperTypeDTOSchema);
};

export const updateCard = ({
  cardId,
  payload,
}: {
  cardId: string;
  payload: Omit<FlashCardWrapperTypeDTO, "knowledgeScore" | "dateCreated" | "id" | "userId">;
}): Promise<FlashCardWrapperTypeDTO> =>
  validateZodSchema(
    putRequest(`${BACKEND_URL}/${CARDS}/${FLASH_CARDS}/${cardId}`, payload),
    flashCardWrapperTypeDTOSchema,
  );

export const deleteCard = ({ cardId }: { cardId: string }) =>
  validateZodSchema(
    deleteRequest<FlashCardWrapperTypeDTO>(`${BACKEND_URL}/${CARDS}/${FLASH_CARDS}/${cardId}`),
    flashCardWrapperTypeDTOSchema,
  );

export const createKnowledgeSession = async ({
  deckId,
  payload,
}: {
  deckId: string;
  payload: KnowledgeSessionCreationPayload;
}): Promise<KnowledgeSession> =>
  validateZodSchema(
    postRequest(`${BACKEND_URL}/${CARDS}/${KNOWLEDGE_SESSION}/${deckId}`, payload),
    knowledgeSessionSchema,
  );

export async function putForNextCard({
  knowledgeSessionId,
  payload,
}: {
  knowledgeSessionId: string;
  payload: KnowledgeSessionUpdate;
}): Promise<FlashCardWrapperTypeDTO | KnowledgeSessionResult> {
  return validateZodSchema(
    putRequest(`${BACKEND_URL}/${CARDS}/${KNOWLEDGE_SESSION}/${knowledgeSessionId}/answer`, payload),
    flashCardWrapperTypeDTOSchema.or(knowledgeSessionResultSchema),
  );
}

export const useFlashCardDecksWithProjects = () => {
  const { data } = useAuthUserData();
  const isShared = useIsSharedPage();
  return useQuery({
    queryKey: ["flash-card-decks", { userId: data?.uid, isShared }],
    queryFn: isShared ? getSharedFlashCardDecks : getFlashCardDecks,
    select: (result) => result.sort((a, b) => a.projectName.localeCompare(b.projectName)),
  });
};

export const useFlashCardDecks = (projectId: string) => {
  const { data } = useAuthUserData();
  const isShared = useIsSharedPage();
  return useQuery({
    queryKey: ["flash-card-decks", projectId, { userId: data?.uid }],
    queryFn: isShared ? getSharedFlashCardDecks : getFlashCardDecks,
    enabled: projectId !== "",
    select: (result) => {
      return result
        .filter((item) => (projectId ? item.projectId === projectId : true))
        .map((item) => item.flashcardDecks)
        .reduce((accumulator, value) => {
          accumulator.push(...value);
          return accumulator;
        }, []);
    },
  });
};
export const useFlashCardDeckOfCurrentProject = () => {
  const projectId = useCurrentProjectId();
  return useFlashCardDecks(projectId ?? "");
};

export const useFlashCardDeck = ({ deckId }: { deckId?: string }) =>
  useQuery({
    queryKey: ["flash-card-deck", deckId],
    queryFn: () => getFlashCardDeck({ id: deckId ?? "", openedAt: deckId ? "knowledge_check" : "library" }),
    enabled: deckId !== undefined,
  });

export const useCurrentFlashCardDeck = () => {
  const { deckId } = useParams();
  return useFlashCardDeck({ deckId });
};

export const useFlashCardFromCurrentDeck = <T extends FlashCardWrapperTypeDTO>({
  cardId,
  enabled = true,
}: {
  cardId: string;
  // I stuck with this cause there were some over the top typescript gymnastics for this
  enabled?: boolean;
}) => {
  const { deckId } = useParams();
  return useQuery({
    queryKey: ["flash-card-deck", deckId],
    queryFn: () => getFlashCardDeck({ id: deckId ?? "", openedAt: deckId ? "knowledge_check" : "library" }),
    enabled: deckId !== undefined && enabled,
    select: (data) => data.cardList.find((item) => item.id === cardId) as T,
  });
};

export const useFlashCard = <T extends FlashCardWrapperTypeDTO>({ cardId }: { cardId?: string | null }) =>
  useQuery({
    queryKey: ["flash-card", cardId],
    queryFn: () => getFlashCard({ id: cardId! }) as Promise<T>,
    enabled: !!cardId,
  });
export const useCreateFlashCard = () => {
  const isDocumentPage = useIsDocumentPage();
  const setFlashCardCache = useAppStateStore((state) => state.setFlashCardCache);
  return useMutation({
    mutationKey: ["create-flash-card"],
    mutationFn: ({
      deckId,
      aiGenerated,
      payload,
    }: {
      deckId: string;
      aiGenerated: boolean;
      payload: FlashCardCreatePayload;
    }) => {
      setFlashCardCache(payload.cardType, null);
      return createCard({
        deckId,
        payload: _.omit(payload, "userId"),
        aiGenerated: aiGenerated,
        createdAt: isDocumentPage ? "note" : "knowledge_check",
      });
    },
    onSuccess: () => {
      toast({ title: "Flash card created!", variant: "success" });
    },
    onError: () => {
      toast({ title: "Failed to create flash card!", variant: "destructive" });
    },
  });
};

export const useDeleteFlashCard = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ cardId, deckId: _deckId }: { cardId: string; deckId: string }) => deleteCard({ cardId }),
    onSuccess: (result, { deckId }) => {
      toast({ title: "Flash card deleted!", variant: "success" });
      queryClient.setQueryData<ExtendedFlashCardDeck>(["flash-card-deck", deckId], (oldData) =>
        oldData
          ? {
              ...oldData,
              cardList: oldData.cardList.filter((item) => item.id !== result.id),
            }
          : oldData,
      );
    },
    onError: () => {
      toast({ title: "Failed to delete flash card!", variant: "destructive" });
    },
  });
};

export const useCreateSession = (
  options?: UseMutationOptions<KnowledgeSession, unknown, Parameters<typeof createKnowledgeSession>[0]>,
) =>
  useMutation({
    ...options,
    mutationFn: createKnowledgeSession,
  });

export const usePutForNextCard = (
  options?: UseMutationOptions<
    FlashCardWrapperTypeDTO | KnowledgeSessionResult,
    unknown,
    Parameters<typeof putForNextCard>[0]
  >,
) =>
  useMutation({
    ...options,
    mutationFn: ({ knowledgeSessionId, payload }) =>
      putForNextCard({ knowledgeSessionId, payload: _.omit(payload, "selectedMultipleAnswer") }),
  });

export const useUpdateFlashCard = (
  options?: UseMutationOptions<FlashCardWrapperTypeDTO, unknown, Parameters<typeof updateCard>[0]>,
) => {
  const { deckId } = useParams();
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: updateCard,
    onSuccess: (result) => {
      queryClient.setQueryData<FlashCardWrapperTypeDTO>(["flash-card", result.id], (data) =>
        data ? { ...result } : data,
      );
      if (deckId)
        queryClient.setQueryData<ExtendedFlashCardDeck>(["flash-card-deck", deckId], (data) => {
          if (!data) return data;
          const itemIndex = data.cardList.findIndex((item) => item.id === result.id);
          if (itemIndex !== -1) {
            data.cardList[itemIndex] = result;
          }
          return { ...data, cardList: [...data.cardList] };
        });
    },
  });
};
