import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useGenerateShareLink } from "@/api/workspace";
import { ROUTES } from "@/assets/constants/constants";
import { ModalKeys } from "@/assets/constants/modal";
import { CopyLink } from "@/components/icons";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router";
import { mixpanelTrack } from "@/service/mixpanel";
import { copyTextToClipboard } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice";

function GetProCTAModal() {
  const [open, setState] = useModalState(ModalKeys.GET_PRO_CTA_MODAL);
  const currentWorkspaceId = useCurrentWorkspaceId();
  const { data: generatedLink } = useGenerateShareLink(currentWorkspaceId ?? "");
  const [isCopied, setIsCopied] = useState(false);

  const navigate = useNavigate();

  const handleClose = (): void => {
    setState(false);
  };

  const onClick = useCallback(() => {
    mixpanelTrack("subscription_button_invite_a_friend_modal_clicked");
    navigate(ROUTES.SUBSCRIPTION);
    setState(false);
  }, [setState, navigate]);

  const onCopyClick = () => {
    mixpanelTrack("copy_invitation_link_button_invite_a_friend_modal_clicked");
    void copyTextToClipboard(generatedLink?.shareLink ?? "").then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="gap-12 p-6">
        <DialogHeader className="gap-4">
          <DialogTitle asChild>
            <h3 className="text-h4 text-primary-onBg">Get unlimited summaries</h3>
          </DialogTitle>
          <DialogDescription asChild className="!mt-0">
            <p className="text-xs font-normal text-secondary-onBg">
              You have 3 summaries included in single-user workspaces. Get a pro subscription or invite friends to use
              document summaries without limits.{" "}
            </p>
          </DialogDescription>
        </DialogHeader>
        <section className="flex flex-row justify-center">
          <img alt="Upgrade illustration" src="/files/images/v3-design/upgrade-illustration.svg" />
        </section>
        <DialogFooter>
          <Button variant="ghost" onClick={handleClose}>
            Later
          </Button>
          <Button className="flex items-center gap-2" variant="secondary" onClick={onCopyClick}>
            <CopyLink />
            {isCopied ? "Copied" : "Share workspace with link"}
          </Button>
          <Button onClick={onClick}>Subscribe</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default GetProCTAModal;
