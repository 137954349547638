import { type StateCreator } from "zustand";

import { type AppSlice, type SectionObserverStateSlice } from "@/types/app";

const createSectionObserverStateSlice: StateCreator<
  AppSlice,
  [["zustand/devtools", never]],
  [],
  SectionObserverStateSlice
> = (set) => ({
  sectionState: "",
  setSectionState: (value: string) => set({ sectionState: value }, false, "setSectionState"),
});

export default createSectionObserverStateSlice;
