import { useCallback, useState } from "react";

import { useDeleteDocument } from "@/api/document.ts";
import { Button } from "@/components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { DeleteIcon, DemoHandlerButton } from "@/components/v3";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useDocumentSelectionState } from "@/service/library.ts";

import QuinoPopoverTitle from "../../../generic/quino-popover-title.tsx";

const DeleteSelectedResourceButton = () => {
  const { selectedResources, unselectAllResources } = useDocumentSelectionState();
  const { mutate: deleteDocument } = useDeleteDocument();
  const [isOpen, setOpen] = useState(false);
  const workspaceId = useCurrentWorkspaceId();
  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onDelete = useCallback(() => {
    selectedResources.forEach((item) =>
      deleteDocument({
        documentId: item.id,
        workspaceId,
      }),
    );
    unselectAllResources();
    setOpen(false);
  }, [deleteDocument, selectedResources, unselectAllResources, workspaceId]);

  return (
    <Popover open={isOpen} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <DemoHandlerButton className="" size="sm" variant="destructive">
          <DeleteIcon className="fill-primitive-red-900" />
          Delete
        </DemoHandlerButton>
      </PopoverTrigger>
      <PopoverContent className="w-[22rem]">
        <QuinoPopoverTitle
          description="Are you sure you want to delete this document along with its summary?"
          title={`Delete ${selectedResources.map((item) => `“${item.name}”`).join(", ")}`}
        />
        <section className="flex flex-row items-center justify-end gap-2">
          <Button size="sm" variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="sm" variant="destructive-filled" onClick={onDelete}>
            <DeleteIcon className="fill-white" />
            Delete
          </Button>
        </section>
      </PopoverContent>
    </Popover>
  );
};
export default DeleteSelectedResourceButton;
