import { type GenericActivity, type ProjectIconsEnum, type UserData } from "@/types/schemas";

// export type ACTIVITY_TYPE =
//     | "HIGHLIGHT_SUMMARY"
//     | "NEW_DOCUMENT_UPLOAD"
//     | "UPVOTE"
//     | "DOWNVOTE"
//     | "SUMMARY_GENERATION"
//     | "NEW_WORKSPACE_MEMBERS"
//     | "PROJECT_CREATE"
//     | "LISTED_ACTIVITIES"
//     | "DELETING";

export enum ACTIVITY_TYPE {
  UPVOTE = "UPVOTE",
  DOWNVOTE = "DOWNVOTE",
  DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD",
  DOCUMENT_DELETE = "DOCUMENT_DELETE",
  MULTIPLE_DOCUMENT_DELETE = "MULTIPLE_DOCUMENT_DELETE",
  DOCUMENT_SUMMARY = "DOCUMENT_SUMMARY",
  PARAGRAPH_SUMMARY_HIGHLIGHT = "PARAGRAPH_SUMMARY_HIGHLIGHT",
  PARAGRAPH_SUMMARY_EXPAND = "PARAGRAPH_SUMMARY_EXPAND",
  PARAGRAPH_SUMMARY_UPVOTE_CHANGE = "PARAGRAPH_SUMMARY_UPVOTE_CHANGE",
  AI_FUNCTION_USED = "AI_FUNCTION_USED",
  PROJECT_CREATE = "PROJECT_CREATE",
  PROJECT_DELETE = "PROJECT_DELETE",
  WORKSPACE_CREATE = "WORKSPACE_CREATE",
  WORKSPACE_INVITE_ACCEPT = "WORKSPACE_INVITE_ACCEPT",
  WORKSPACE_ADMIN_CHANGED = "WORKSPACE_ADMIN_CHANGED",
  LISTED_ACTIVITIES = "LISTED_ACTIVITIES",
}

export interface AiFunctionUsedActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.AI_FUNCTION_USED;
  extraInfo: {
    userName: string;
    documentName: string;
    description: string;
  };
}

export interface UpvoteActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.UPVOTE;
  extraInfo: {
    userName: string;
    documentName: string;
    description: string;
  };
}

export interface ParagraphSummaryHighlightActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.PARAGRAPH_SUMMARY_HIGHLIGHT;
  extraInfo: {
    id: string;
    documentName: string;
    paragraphText: string;
    highlighted?: boolean | null;
    expanded?: boolean | null;
    likeDislikeValue?: boolean | null;
    workspaceId: string;
    projectId: string;
    documentId: string;
  };
}

export interface ParagraphSummaryVoteActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.PARAGRAPH_SUMMARY_UPVOTE_CHANGE;
  extraInfo: {
    id: string;
    documentName: string;
    paragraphText: string;
    highlighted?: boolean | null;
    expanded?: boolean | null;
    likeDislikeValue?: "UPVOTE" | "DOWNVOTE";
    workspaceId: string;
    projectId: string;
    documentId: string;
  };
}

export interface ParagraphSummaryExpandActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.PARAGRAPH_SUMMARY_EXPAND;
  extraInfo: {
    id: string;
    documentName: string;
    paragraphText: string;
    highlighted?: boolean | null;
    expanded?: boolean | null;
    likeDislikeValue?: boolean | null;
    workspaceId: string;
    projectId: string;
    documentId: string;
  };
}

export interface DownvoteActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.DOWNVOTE;
  extraInfo: {
    userName: string;
    documentName: string;
    description: string;
  };
}

export interface SummaryGenerationActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.DOCUMENT_SUMMARY;
  extraInfo: {
    id: string;
    documentName: string;
    documentId: string;
    workspaceId: string;
    projectId: string;
  };
}

export interface WorkspaceCratedActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.WORKSPACE_CREATE;
  extraInfo: {
    workspaceId: string;
    name: string;
  };
}
export interface NewWorkspaceMembersActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.WORKSPACE_INVITE_ACCEPT;
  extraInfo: {
    workspaceName: string;
    inviterData: UserData;
    acceptedUserData: UserData;
  };
}
export interface WorkspaceAdminChangedActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.WORKSPACE_ADMIN_CHANGED;
  extraInfo: {
    oldAdminData: UserData;
    newAdminData: UserData;
  };
}

export interface NewProjectCreatedActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.PROJECT_CREATE;
  extraInfo: {
    id: string;
    name: string;
    workspaceId: string;
  };
}

export interface CurrentUserActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.LISTED_ACTIVITIES;
  extraInfo: {
    userName: string;
    projectName: string;
  };
}

export interface NewDocumentUploadActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.DOCUMENT_UPLOAD;
  extraInfo: {
    name: string;
    type: "STARTED" | "FINALIZED";
    workspaceId: string;
    projectId: string;
    documentId: string;
  };
}

export interface DocumentDeleteActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.DOCUMENT_DELETE;
  extraInfo: {
    id: string;
    name: string;
  };
}

export interface MultipleDocumentDeleteActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.MULTIPLE_DOCUMENT_DELETE;
  extraInfo: {
    deletedDocIds: string;
  };
}

export interface ProjectCreateActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.PROJECT_CREATE;
  extraInfo: {
    userName: string;
    objectType: string;
    objectName: string;
  };
}

export interface ProjectDeleteActivity extends GenericActivity {
  activityType: ACTIVITY_TYPE.PROJECT_DELETE;
  extraInfo: {
    id: string;
    name: string;
  };
}

export type Activity =
  | ProjectDeleteActivity
  | ProjectCreateActivity
  | DocumentDeleteActivity
  | MultipleDocumentDeleteActivity
  | WorkspaceCratedActivity
  | WorkspaceAdminChangedActivity
  | NewDocumentUploadActivity
  | AiFunctionUsedActivity
  | UpvoteActivity
  | ParagraphSummaryVoteActivity
  | ParagraphSummaryHighlightActivity
  | DownvoteActivity
  | SummaryGenerationActivity
  | ParagraphSummaryExpandActivity
  | NewWorkspaceMembersActivity
  | NewProjectCreatedActivity
  | CurrentUserActivity;

export interface SearchResponse {
  name: string;
  icon: ProjectIconsEnum;
  image: string;
  workspaceId: string;
  projectId: string;
  documentId: string;
  type: "DOCUMENT" | "WORKSPACE" | "PROJECT";
}
