import { useEffect } from "react";

import { logEvent } from "firebase/analytics";
import ReactGA from "react-ga4";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useLocalStorage, useSessionStorage } from "usehooks-ts";

import { useSubscription } from "@/api/resources.ts";
import { queryLastPayment } from "@/api/stripe";
import { ROUTES } from "@/assets/constants/constants.ts";
import { MISSING_STRIPE_TIMEFRAME, MISSING_STRIPE_TIMEFRAME_STORAGE_KEY } from "@/assets/constants/stripe-fix.ts";
import { useToast } from "@/components/ui/use-toast.ts";
import { analytics } from "@/firebase";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { customGAEventSender } from "@/service";
import type { Payment } from "@/types/stripe";
import { sleep } from "@/utils";

const getDate = (timestamp: string | number) => {
  if (typeof timestamp === "string") {
    return new Date(timestamp);
  }
  return new Date(timestamp * 1000);
};

const useStripeFix = (userId: string | undefined) => {
  const [fixSent, setFixSent] = useLocalStorage<boolean>(MISSING_STRIPE_TIMEFRAME_STORAGE_KEY, false);
  useEffect(() => {
    if (userId && !fixSent) {
      void (async () => {
        const payment = await queryLastPayment(userId);
        if (payment?.created) {
          const createdDate = getDate(payment.created);
          if (
            MISSING_STRIPE_TIMEFRAME.start.getTime() < createdDate.getTime() &&
            createdDate.getTime() < MISSING_STRIPE_TIMEFRAME.end.getTime()
          ) {
            logEvent(analytics, "purchase", {
              // the userId shouldn't be null here because only authenticated users can initiate payment
              transaction_id: payment.id,
              currency: payment.currency.toUpperCase(),
              value: payment.amount / 100,
            });
          }
          setFixSent(true);
        }
      })().catch((e) => console.error(e));
    }
  }, [fixSent, setFixSent, userId]);
};

const StripeGuard = () => {
  const { toast } = useToast();
  const userId = useFirebaseUserId();
  const { refetch } = useSubscription();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [state, setState] = useSessionStorage<"UNSUCCESSFUL_PAYMENT" | "SUCCESSFUL_PAYMENT" | "">(
    "FIREBASE_EVENT_RESULT",
    "",
  );
  useEffect(() => {
    if (location.pathname.includes(ROUTES.UNSUCCESSFUL_PAYMENT)) {
      setState("UNSUCCESSFUL_PAYMENT");
    }
    if (location.pathname.includes(ROUTES.SUCCESSFUL_PAYMENT)) {
      setState("SUCCESSFUL_PAYMENT");
    }
  }, [location, setState]);

  useEffect(() => {
    if (!userId) return;
    if (state === "UNSUCCESSFUL_PAYMENT") {
      customGAEventSender("payment", "canceled_payment");
      setState("");
    }
    if (state === "SUCCESSFUL_PAYMENT") {
      void (async () => {
        let payment: Payment | null = null;
        for (let i = 0; i < 5 && payment === null; i++) {
          await sleep(2000);
          payment = await queryLastPayment(userId);
        }
        if (!payment) {
          console.error("Error processing payment!");
          return;
        }
        customGAEventSender("payment", "payment_successful", payment.id);
        const paymentAmount = payment.amount / 100;
        const currency = payment.currency.toUpperCase();
        const transactionId = payment.currency.toUpperCase();
        logEvent(analytics, "purchase", {
          // the userId shouldn't be null here because only authenticated users can initiate payment
          transaction_id: transactionId,
          currency: currency,
          value: paymentAmount,
        });
        ReactGA.gtag("event", "conversion", {
          send_to: "AW-16465116531/POHdCL-N9KQZEPP6lqs9",
          value: paymentAmount,
          currency: currency,
          transaction_id: transactionId,
        });

        toast({
          title: "Thank you for supporting us! It may take us one minute to update your plan.",
          variant: "success",
        });
      })().catch((e) => console.error(e));
      setState("");
    }
  }, [state, navigate, refetch, searchParams, toast, userId, setState]);

  useStripeFix(userId);

  return null;
};
export default StripeGuard;
