import DocumentBackToLibraryButton from "@/components/document/document-back-to-library-button.tsx";
import DocumentName from "@/components/document/document-name.tsx";
import DocumentSplitViewSelector from "@/components/document/document-split-view-selector.tsx";
import ShareDocumentPopover from "@/components/document/share-document-popover.tsx";
import AiLanguageSwitcher from "@/components/layout/components/ai-language-switcher.tsx";

const DocumentViewHeader = () => {
  return (
    <div className="z-[50] grid grid-cols-3 flex-row items-center justify-center bg-bg-base p-4">
      <section className="flex min-h-[3rem] justify-start">
        <DocumentBackToLibraryButton />
      </section>
      <section className="flex flex-row items-center justify-center">
        <DocumentName className="font-jakarta text-base font-bold text-primitive-purple-900" />
        <ShareDocumentPopover />
      </section>
      <section className="flex flex-row items-center justify-end gap-1">
        <AiLanguageSwitcher mixpanelLocation="fds_page" style="minimalist" />
        <DocumentSplitViewSelector />
      </section>
    </div>
  );
};

export default DocumentViewHeader;
