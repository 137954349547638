import { FormPageLayout } from "@/components/layout";

import PasswordReset from "./password-reset";

const NewPassword = () => {
  return (
    <FormPageLayout title="Change password">
      <PasswordReset />
    </FormPageLayout>
  );
};

export default NewPassword;
