import { useCallback } from "react";

import { createId } from "@paralleldrive/cuid2";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useParams, useSearchParams } from "react-router-dom";

import { addMarkdownParagraph, useAllMyNoteIdsForProjectSorted } from "@/api/note";
import { getRequest, insertParagraphIntoNoteEntity, postRequest, putRequest } from "@/api/utils";
import {
  BACKEND_URL,
  FlashCardVariant,
  type ModelLogFeedback,
  NoteParagraphInteractionType,
  QUERY_PARAMS,
} from "@/assets/constants/constants";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { toast, useToast } from "@/components/ui/use-toast";
import AbortRequestService from "@/service/abort";
import { getDemoDocumentIdForQA } from "@/service/demo";
import { type GenericFetchError } from "@/service/errors";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import {
  useCurrentDocPageNumber,
  useCurrentDocumentTabEntity,
  useCurrentDocumentTabId,
  useCurrentNoteId,
  useCurrentWorkspaceId,
} from "@/service/hooks/react-router";
// import { useIsFreeUser } from "@/service/hooks/settings.ts";
import { useOpenSignUpToQuinoModal } from "@/service/library.ts";
import { mixpanelTrack, useMixpanelTrack } from "@/service/mixpanel";
import { useUserAiLanguage } from "@/service/user.ts";
import { type ModelLogResponse } from "@/types/app.ts";
import {
  type AggregatedFlashCardPayload,
  type AILookupPayload,
  type BulletpointsPayload,
  type SimpleAICallPayload,
  type SummarizationPayload,
} from "@/types/interfaces/model-lake-routes.ts";
import {
  type QuestionSuggestion,
  type QuestionSuggestionCreate,
  simpleModelLakeQueryResponse,
  trialResponseSchema,
  type TrialResponse,
  type GeneratedFlashCard,
  type NoteEntity,
  type NoteParagraph,
  type SimpleModelLakeQueryResponse,
  questionSuggestionCreateSchema,
  type BasicResponse,
  basicResponseSchema,
  basicFlashCardSchema,
  questionFlashCardSchema,
  generatedMultipleChoiceFlashCardSchema,
  trueFalseFlashCardSchema,
  type AvailableModelsResponse,
  availableModelsResponse,
} from "@/types/schemas";
import { demoQuestionSuggestionSchema } from "@/types/schemas/api/demo.ts";
import { getActiveNoteTab, validateZodSchema } from "@/utils";
import { useAILoading } from "@/zustand/hooks";
import useAppStateStore from "@/zustand/store";

const MODEL_LAKE = "/modellake";

const DEFINITION_FLASHCARD = "/definition-flashcard";
const QUESTION_FLASHCARD = "/question-flashcard";
const TRUE_FALSE_CARD = "/true-false-card";
const MULTIPLE_CHOICE_CARD = "/multiple-choice-card";

const getAvailableModels = async () => {
  return validateZodSchema(
    getRequest<AvailableModelsResponse[]>(`${BACKEND_URL}/modellake/available-models`),
    availableModelsResponse.array(),
  );
};

const summarize = async (payload: SummarizationPayload) => {
  const { signal } = AbortRequestService.createController();
  return validateZodSchema(
    postRequest<SimpleModelLakeQueryResponse>(`${BACKEND_URL}/modellake/summarize`, payload, signal),
    simpleModelLakeQueryResponse,
  );
};

const demoSummarize = async ({ text }: { text: string }) => {
  const { signal } = AbortRequestService.createController();
  return validateZodSchema(
    postRequest<TrialResponse>(
      `${BACKEND_URL}/demo-endpoints/demo/summary`,
      {
        inputText: text,
      },
      signal,
    ),
    trialResponseSchema,
  );
};

const bulletpoint = async (payload: BulletpointsPayload) => {
  const { signal } = AbortRequestService.createController();
  return validateZodSchema(
    postRequest<SimpleModelLakeQueryResponse>(`${BACKEND_URL}/modellake/bulletpoints`, payload, signal),
    simpleModelLakeQueryResponse,
  );
};

const demoBulletpoints = async ({ text }: { text: string }) => {
  const { signal } = AbortRequestService.createController();
  return validateZodSchema(
    postRequest<TrialResponse>(
      `${BACKEND_URL}/demo-endpoints/demo/bulletpoints`,
      {
        inputText: text,
      },
      signal,
    ),
    trialResponseSchema,
  );
};

export const getDemoQuestionSuggestions = async ({
  payload,
}: {
  payload: QuestionSuggestionCreate;
}): Promise<QuestionSuggestion> => {
  await validateZodSchema(payload, questionSuggestionCreateSchema);

  return await validateZodSchema(
    postRequest<QuestionSuggestion>(`${BACKEND_URL}/demo-endpoints/demo/question-generation`, {
      documentName: payload.documentId,
    }),
    demoQuestionSuggestionSchema,
  );
};

const aiLookup = async (payload: AILookupPayload) => {
  const { signal } = AbortRequestService.createController();
  return validateZodSchema(
    postRequest<SimpleModelLakeQueryResponse>(`${BACKEND_URL}/modellake/ai-lookup`, payload, signal),
    simpleModelLakeQueryResponse,
  );
};

const demoAiLookup = async ({ text, documentId }: { text: string; documentId: string }) => {
  const { signal } = AbortRequestService.createController();
  return validateZodSchema(
    postRequest<TrialResponse>(
      `${BACKEND_URL}/demo-endpoints/demo/ai-lookup`,
      {
        inputText: text,
        documentId,
      },
      signal,
    ),
    trialResponseSchema,
  );
};

const sendModelLogFeedback = async (params: { modelLogId: string; payload: ModelLogResponse }) => {
  return validateZodSchema(
    putRequest<BasicResponse>(`${BACKEND_URL}/modellake/model-log/${params.modelLogId}`, params.payload),
    basicResponseSchema,
  );
};

export const generateFlashCard = async <T extends FlashCardVariant | "question">({
  variant,
  payload,
}: {
  variant: T;
  payload: AggregatedFlashCardPayload;
}): Promise<GeneratedFlashCard> => {
  const { signal } = AbortRequestService.createController();
  switch (variant) {
    case FlashCardVariant.BASIC:
      return validateZodSchema(
        postRequest(`${BACKEND_URL}${MODEL_LAKE}${DEFINITION_FLASHCARD}`, payload, signal),
        basicFlashCardSchema,
      );
    case "question":
      return validateZodSchema(
        postRequest(`${BACKEND_URL}${MODEL_LAKE}${QUESTION_FLASHCARD}`, payload, signal),
        questionFlashCardSchema,
      );
    case FlashCardVariant.MULTIPLE:
      return validateZodSchema(
        postRequest(`${BACKEND_URL}${MODEL_LAKE}${MULTIPLE_CHOICE_CARD}`, payload, signal),
        generatedMultipleChoiceFlashCardSchema,
      );
    case FlashCardVariant.TRUE_FALSE:
      return validateZodSchema(
        postRequest(`${BACKEND_URL}${MODEL_LAKE}${TRUE_FALSE_CARD}`, payload, signal),
        trueFalseFlashCardSchema,
      );
    default:
      throw new Error("Invalid variant!");
  }
};

export const useCopyToNote = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId = "", workspaceId = "" } = useParams();
  const { matched: isDemo } = useIsDemoLikePage();
  const noteIds = useAllMyNoteIdsForProjectSorted(workspaceId, projectId);
  const { data } = useCurrentDocumentTabEntity();
  const currentDocId = useCurrentDocumentTabId();
  const [, setAILoading] = useAILoading();

  return useMutation({
    mutationFn: async ({ text, page, originalText }: { text: string; page?: number; originalText?: string }) => {
      setAILoading(true);
      if (!data) throw new Error("No document is opened!");
      const currentNoteId = searchParams.get(QUERY_PARAMS.NOTE);
      const noteId = await getActiveNoteTab(noteIds ?? [], currentNoteId, data.workspaceId, data.projectId);
      searchParams.set(QUERY_PARAMS.NOTE, noteId);
      searchParams.set(QUERY_PARAMS.NOTE_TAB, "notes");
      setSearchParams(searchParams);
      if (isDemo) {
        return new Promise<NoteParagraph>((resolve) =>
          resolve({
            markdownText: text,
            noteParagraphType: "TEXT",
            documentId: currentDocId,
            position: 0,
            docPageNumber: page ?? 0,
            docPageTextOrigin: originalText ?? "",
            noteId: noteId,
            modelLogId: "",
            id: createId().toLowerCase(),
            hasAd: false,
            dateCreated: new Date().toISOString(),
          }),
        );
      }
      return await addMarkdownParagraph({
        noteId,
        payload: {
          markdownText: text,
          documentId: currentDocId,
          position: -1,
          docPageNumber: page,
          docPageTextOrigin: originalText,
        },
      });
    },
    onSuccess: (result) => insertParagraphIntoNoteEntity({ workspaceId, result, queryClient }),
    onSettled: () => {
      setAILoading(false);
    },
  });
};

export const useAiFunctionMutation = (
  callback: (params: SimpleAICallPayload) => Promise<SimpleModelLakeQueryResponse>,
) => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const isFreeUser = false; // useIsFreeUser(); isFreemium changed to isFreeUser review needed
  const queryClient = useQueryClient();
  const [, setActiveTab] = useSetActiveNoteTab();
  const [, setLoading] = useAILoading();
  const { data: document } = useCurrentDocumentTabEntity();
  const docPageNumber = useCurrentDocPageNumber();
  const { setLastModelResponse } = useAppStateStore((state) => ({
    setLastModelResponse: state.setLastModelResponse,
  }));
  const [language] = useUserAiLanguage();
  return useMutation({
    mutationFn: async ({ text }: { text: string }) => {
      setLoading(true);
      const noteId = await setActiveTab();
      if (!noteId) throw new Error("no active note!");
      if (!document) throw new Error("no active document!");
      return await callback({
        text,
        noteId,
        documentId: document.id,
        docPageNumber: docPageNumber ?? 0,
        expectedResponseLanguage: language,
      });
    },
    onSuccess: (result) => {
      setLastModelResponse(result);
      queryClient.setQueryData<NoteEntity>(
        [QUERY_KEYS.NOTE, currentWorkspaceId, { id: result.noteParagraph.noteId }],
        (oldData) => {
          return oldData
            ? {
                ...oldData,
                noteParagraphs: oldData.noteParagraphs.concat([
                  { ...result.noteParagraph, hasAd: isFreeUser && oldData.noteParagraphs.length % 1 === 5 },
                ]),
              }
            : oldData;
        },
      );

      mixpanelTrack("note_paragraph_created", {
        content_type: "TEXT",
        paragraph_type: NoteParagraphInteractionType.AI_CREATED,
        model_log_id: result.modelLogId,
      });
    },
    onError: (error: Error) =>
      toast({
        title: error.toString(),
        variant: "destructive",
      }),
    onSettled: () => {
      setLoading(false);
    },
  });
};

export const useDemoAIFunction = (
  callback: (params: { text: string }) => Promise<TrialResponse>,
  type: "ai_lookup" | "ai_bulletpoints" | "ai_summarization",
) => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const [, setActiveTab] = useSetActiveNoteTab();
  const [, setLoading] = useAILoading();
  const { data: document } = useCurrentDocumentTabEntity();
  const { toast } = useToast();
  const openSignUp = useOpenSignUpToQuinoModal();
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  return useMutation({
    mutationFn: async ({ text }: { text: string }) => {
      setLoading(true);
      const noteId = await setActiveTab();
      if (!noteId) throw new Error("no active note!");
      if (!document) throw new Error("no active document!");
      const response = await callback({
        text,
      });
      return { ...response, noteId, documentId: document.id };
    },
    onSuccess: (result) => {
      queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, currentWorkspaceId, { id: result.noteId }], (oldData) => {
        return oldData
          ? {
              ...oldData,
              noteParagraphs: oldData.noteParagraphs.concat([
                {
                  id: createId().toLowerCase(),
                  noteId: result.noteId,
                  dateCreated: new Date().toISOString(),

                  docPageNumber: -1,
                  position: oldData.noteParagraphs.length,
                  markdownText: result.response,
                  hasAd: false,
                  noteParagraphType: "TEXT",
                  documentId: result.documentId,
                  modelLogId: "",
                  docPageTextOrigin: "",
                },
              ]),
            }
          : oldData;
      });
    },
    onError: (e: GenericFetchError) => {
      if (e?.payload?.status === 429) openSignUp();
      else
        toast({
          title: "Something went wrong!",
          variant: "destructive",
        });
    },
    onSettled: (data, error, variables) => {
      if (isDemo) {
        mixpanelTrack(type, {
          input_len: variables.text.length,
          input_num_of_words: _.words(variables.text).length,
          response_len: data ? data.response.length : 0,
          response_num_of_words: data ? _.words(data.response).length : 0,
          error_occured_on_modellake: !!error,
        });
        mixpanelTrack("note_paragraph_created", {
          content_type: "TEXT",
          paragraph_type: NoteParagraphInteractionType.AI_CREATED,
        });
      }
      setLoading(false);
    },
  });
};

export const useSummarizeText = () => {
  const { matched: isDemo } = useIsDemoLikePage();
  const demoFunction = useDemoAIFunction(demoSummarize, "ai_summarization");
  const normalFunction = useAiFunctionMutation(summarize);

  return useCallback(
    ({ text }: { text: string }) => (isDemo ? demoFunction.mutate({ text }) : normalFunction.mutate({ text })),
    [demoFunction, isDemo, normalFunction],
  );
};
export const useBulletPointSummaryText = () => {
  const { matched: isDemo } = useIsDemoLikePage();

  const demoFunction = useDemoAIFunction(demoBulletpoints, "ai_bulletpoints");
  const normalFunction = useAiFunctionMutation(bulletpoint);

  return useCallback(
    ({ text }: { text: string }) => (isDemo ? demoFunction.mutate({ text }) : normalFunction.mutate({ text })),
    [demoFunction, isDemo, normalFunction],
  );
};
export const useAILookupText = () => {
  const { matched: isDemo } = useIsDemoLikePage();
  const currentDocumentId = useCurrentDocumentTabId();
  const demoFunction = useDemoAIFunction(
    ({ text }) => demoAiLookup({ text, documentId: getDemoDocumentIdForQA(currentDocumentId) }),
    "ai_lookup",
  );
  const normalFunction = useAiFunctionMutation(aiLookup);

  return useCallback(
    ({ text }: { text: string }) => (isDemo ? demoFunction.mutate({ text }) : normalFunction.mutate({ text })),
    [demoFunction, isDemo, normalFunction],
  );
};

export const useSendModelLogFeedback = () => {
  const lastModelLog = useAppStateStore((state) => state.lastModelResponse);
  return useMutation({
    mutationFn: async ({ thumbsUp }: { thumbsUp: ModelLogFeedback }) => {
      if (!lastModelLog) throw Error("No last model log!");
      return await sendModelLogFeedback({
        modelLogId: lastModelLog.modelLogId,
        payload: {
          userAccepted: true,
          thumbsUp,
        },
      });
    },
    onError: (error: Error) => {
      toast({ title: error.toString(), variant: "destructive" });
    },
    onSettled: () => {
      useAppStateStore.getState().setLastModelResponse(null);
    },
  });
};

export const useSetActiveNoteTab = (): [string, () => Promise<string | undefined>] => {
  const { projectId = "", workspaceId = "" } = useParams();
  const [noteId, setNoteId] = useCurrentNoteId();
  const noteIds = useAllMyNoteIdsForProjectSorted(workspaceId, projectId);
  const { data: document } = useCurrentDocumentTabEntity();
  const queryClient = useQueryClient();

  const callback = useCallback(async (): Promise<string | undefined> => {
    if (!document) return undefined;
    const newNoteId = await getActiveNoteTab(noteIds ?? [], noteId, document.workspaceId, document.projectId);
    // updating note id query
    queryClient.setQueryData<string[]>([QUERY_KEYS.NOTE_IDS, { projectId }], (data) => {
      if (!data) return [newNoteId];
      if (data.findIndex((item) => item === newNoteId) === -1) {
        return [...data, newNoteId];
      }
      return [...data];
    });
    // setting note id
    setNoteId(newNoteId);
    return newNoteId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, noteIds, noteIds?.length, noteId, queryClient, projectId, setNoteId]);

  return [noteId, callback];
};

export const useAvailableModels = () => {
  return useQuery({
    queryKey: ["available-models"],
    queryFn: getAvailableModels,
  });
};
