import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const SortAscendingIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-text-default", props.className)}
    >
      <path
        d="M17.293 3.465a1 1 0 0 1 1.32-.084l.094.084 2.828 2.828a1 1 0 0 1-1.32 1.497l-.094-.083L19 6.586V19a1 1 0 0 1-1.993.117L17 19V6.586l-1.121 1.121a1 1 0 0 1-1.498-1.32l.083-.094 2.829-2.828ZM13 18a1 1 0 0 1 .117 1.993L13 20H4a1 1 0 0 1-.117-1.993L4 18h9Zm0-7a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h9Zm-2-7a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h7Z"
        fill="inherit"
      />
    </svg>
  );
};
export default SortAscendingIcon;
