import { useCallback, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateNote } from "@/api/note.ts";
import PanelHeader from "@/components/note/common/panel-header.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useCurrentNoteId, useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectId } from "@/utils";

import CheckCircleFilled from "../../v3/icons/common/check-circle-filled.tsx";

const formSchema = z.object({
  name: z.string().min(1),
});
type FormSchema = z.infer<typeof formSchema>;

const NewNoteButton = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const [, setNoteId] = useCurrentNoteId();
  const { mutate: createNewNote } = useCreateNote();
  const [state, setState] = useState({
    open: false,
  });
  const projectId = useCurrentProjectId();
  const form = useForm<FormSchema>({
    defaultValues: {
      name: "",
    },
    resolver: zodResolver(formSchema),
  });

  const onClose = useCallback(() => {
    setTimeout(() => {
      form.reset();
      form.resetField("name");
    }, 100);
  }, [form]);

  const onSubmit = useCallback(
    (data: FormSchema) => {
      createNewNote(
        {
          name: data.name,
          projectId: projectId,
          workspaceId: currentWorkspaceId,
        },
        {
          onSuccess: (result) => {
            toast({ title: "Note created", variant: "success", icon: <CheckCircleFilled /> });
            setNoteId(result.id);
          },
        },
      );
      onClose();
    },
    [createNewNote, currentWorkspaceId, onClose, projectId, setNoteId],
  );

  useEffect(() => {
    onClose();
  }, [form, onClose, state.open]);

  return (
    <Popover open={state.open} onOpenChange={(value) => setState((prev) => ({ ...prev, open: value }))}>
      <PopoverTrigger asChild>
        <Button size="xs" variant="ghost">
          <span className="h-4 w-4">
            <PlusIcon className="h-4 w-4" />{" "}
          </span>
          New note
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-4">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <p className="pb-4 text-xs font-bold  text-secondary-onBg">Create new note</p>
          <section className="flex flex-row gap-2">
            <Input placeholder="Note name" {...form.register("name")} />
            <Button disabled={!form.formState.isValid} size="sm" type="submit">
              Create
            </Button>
          </section>
        </form>
      </PopoverContent>
    </Popover>
  );
};

const NoteEditorHeader = () => {
  return (
    <PanelHeader title="Notes">
      <NewNoteButton />
    </PanelHeader>
  );
};
export default NoteEditorHeader;
