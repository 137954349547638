import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const DocumentIconFilled = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("fill-default dark:fill-white", props.className)}
  >
    <path
      d="M12.4998 1.83337C12.8535 1.83337 13.1926 1.97385 13.4426 2.2239C13.6927 2.47395 13.8332 2.81309 13.8332 3.16671V10.8907C13.8331 11.2443 13.6926 11.5834 13.4425 11.8334L10.4998 14.776C10.2498 15.0261 9.91076 15.1666 9.55717 15.1667H4.49984C4.14622 15.1667 3.80708 15.0262 3.55703 14.7762C3.30698 14.5261 3.1665 14.187 3.1665 13.8334V3.16671C3.1665 2.81309 3.30698 2.47395 3.55703 2.2239C3.80708 1.97385 4.14622 1.83337 4.49984 1.83337H12.4998ZM12.4998 3.16671H4.49984V13.8334H8.49984V10.8334C8.49984 10.5682 8.60519 10.3138 8.79273 10.1263C8.98027 9.93873 9.23462 9.83337 9.49984 9.83337H12.4998V3.16671ZM12.2238 11.1667H9.83317V13.5574L12.2238 11.1667ZM7.1665 7.83337C7.34332 7.83337 7.51288 7.90361 7.63791 8.02864C7.76293 8.15366 7.83317 8.32323 7.83317 8.50004C7.83317 8.67685 7.76293 8.84642 7.63791 8.97144C7.51288 9.09647 7.34332 9.16671 7.1665 9.16671H6.49984C6.32303 9.16671 6.15346 9.09647 6.02843 8.97144C5.90341 8.84642 5.83317 8.67685 5.83317 8.50004C5.83317 8.32323 5.90341 8.15366 6.02843 8.02864C6.15346 7.90361 6.32303 7.83337 6.49984 7.83337H7.1665ZM10.4998 5.16671C10.6766 5.16671 10.8462 5.23695 10.9712 5.36197C11.0963 5.48699 11.1665 5.65656 11.1665 5.83337C11.1665 6.01018 11.0963 6.17975 10.9712 6.30478C10.8462 6.4298 10.6766 6.50004 10.4998 6.50004H6.49984C6.32303 6.50004 6.15346 6.4298 6.02843 6.30478C5.90341 6.17975 5.83317 6.01018 5.83317 5.83337C5.83317 5.65656 5.90341 5.48699 6.02843 5.36197C6.15346 5.23695 6.32303 5.16671 6.49984 5.16671H10.4998Z"
      fill="inherit"
    />
  </svg>
);
export default DocumentIconFilled;
