import NoteWelcomeImage from "@/assets/images/note-welcome-image.png";
import { useCurrentNote } from "@/service/hooks/react-router.ts";

const WelcomeMessage = () => {
  const note = useCurrentNote();
  return (
    <p className="flex items-center gap-3 p-4 font-bold text-primary">
      <img alt="Welcome image" src={NoteWelcomeImage} />
      <span className="truncate">Welcome! Get Started with {note.data?.name}</span>
    </p>
  );
};

export default WelcomeMessage;
