import { useMemo } from "react";

import { projectColorKeys } from "@/assets/constants/projects.ts";
import { Button } from "@/components/ui/button.tsx";
import { Divider } from "@/components/ui/divider.tsx";
import { FormLabel } from "@/components/ui/form.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { enumToProjectIcon } from "@/service/library.ts";
import { ProjectIconsEnum } from "@/types/schemas";

const IconSelectorPopover = ({
  selectedProjectIcon,
  setSelectedIcon,
}: {
  selectedProjectIcon: { iconStyle: ProjectIconsEnum; iconColor: string };
  setSelectedIcon: (params: { iconStyle: ProjectIconsEnum; iconColor: string }) => void;
}) => {
  const SelectedComp = useMemo(() => {
    if (!selectedProjectIcon.iconColor || !selectedProjectIcon.iconStyle) return null;
    const Comp = enumToProjectIcon[selectedProjectIcon.iconStyle];
    return <Comp style={{ color: selectedProjectIcon.iconColor }} />;
  }, [selectedProjectIcon.iconStyle, selectedProjectIcon.iconColor]);

  return (
    <Popover modal>
      <PopoverTrigger asChild>
        <Button className="h-10 w-10 p-3 data-[state=open]:bg-container-tertiary" size="icon" variant="icon">
          {SelectedComp}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="z-[3000] w-auto p-2" side="bottom">
        <FormLabel className="p-2">Customize project icon</FormLabel>
        <section className="grid grid-cols-[repeat(14,_minmax(0,_1fr))] gap-0.5 pt-4">
          {projectColorKeys.map((item) => (
            <Button
              className="h-7 w-7 p-1.5"
              key={item}
              size="icon"
              variant="icon"
              onClick={() =>
                setSelectedIcon({
                  iconColor: item,
                  iconStyle: selectedProjectIcon.iconStyle,
                })
              }
            >
              <span className="h-4 w-4 rounded-full" key={item} style={{ backgroundColor: item }} />
            </Button>
          ))}
        </section>
        <Divider />
        <section className="grid grid-cols-[repeat(14,_minmax(0,_1fr))] gap-0.5 pt-4">
          {(Object.keys(ProjectIconsEnum) as Array<keyof typeof ProjectIconsEnum>).map((item) => {
            const Comp = enumToProjectIcon[item];
            return (
              <Button
                key={item}
                size="icon"
                variant="icon"
                onClick={() =>
                  setSelectedIcon({
                    iconColor: selectedProjectIcon.iconColor,
                    iconStyle: item as ProjectIconsEnum,
                  })
                }
              >
                <Comp />
              </Button>
            );
          })}
        </section>
      </PopoverContent>
    </Popover>
  );
};

export default IconSelectorPopover;
