import { z } from "zod";

export const referralDataSchema = z.object({
  number_of_invited_users: z.number(),
  number_of_subscribed_invited_users: z.number(),
});

export type ReferralData = z.infer<typeof referralDataSchema>;

export const subscriptionPlan = z.enum(["free", "pro", "pro_yearly", "enterprise"]);
const subscriptionType = z.enum(["monthly", "yearly"]);

export const subscriptionResponseSchema = z
  .object({
    id: z.string(),
    subscriptionPlan: subscriptionPlan,
    period: subscriptionType,
    subscriptionEndsAt: z.string().nullish(),
    usageMetrics: z
      .object({
        noPdfExport: z.number().nullish(),
        pdfUploadOnly: z.number().nullish(),
        advertisementsPresent: z.number().nullish(),
        uploadedDocumentPageNum: z.number().nullish(),
        uploadedDocumentSizeMb: z.number().nullish(),
        monthlySingleWorkspaceFdsUsage: z.number().nullish(),
      })
      .nullish(),
    restrictions: z
      .object({
        noPdfExport: z.number().nullish(),
        pdfUploadOnly: z.number().nullish(),
        advertisementsPresent: z.number().nullish(),
        uploadedDocumentPageNum: z.number().nullish(),
        uploadedDocumentSizeMb: z.number().nullish(),
        monthlySingleWorkspaceFdsUsage: z.number().nullish(),
      })
      .nullish(),
  })
  .strict();

export type SubscriptionResponse = z.infer<typeof subscriptionResponseSchema>;

export interface Feature {
  featureDescription: string;
  included: boolean;
}

export interface PlanDescription {
  title: string;
  subTitle: string;
  currency?: string;
  description: string;
  name: string;
  priceId: string;
  product?: string;
  firebaseRole: string;
  price: number | string;
  monthlyPrice: number | string;
  highlighted?: boolean;
  showArrow?: boolean;
}

export interface PlanDescriptionLanding {
  name: string;
  description: string;
  features: string[];
  price: number;
}

export const stripePlanSchema = z
  .object({
    name: z.string(),
    metadata: z.object({
      firebaseRole: subscriptionPlan,
      type: z.enum(["monthly", "one-time", "yearly"]),
      packageSize: z.string().optional(),
      rank: z.number().optional(),
    }),
    type: z.enum(["recurring", "one_time"]),
    unitAmount: z.number(),
    product: z.string(),
    priceId: z.string(),
    productData: z.object({
      subscriptionPlan: z.string().optional(),
      maxCredits: z.number().optional(),
      uploadedFilesPerMonthCounterLimit: z.number().nullable().optional(),
      packageName: z.string().optional(),
      extraCreditNum: z.number().optional(),
    }),
  })
  .strict();
export type StripePlan = z.infer<typeof stripePlanSchema>;

export const stripePlanFeatureSchema = z
  .object({
    name: z.string(),
    subtitle: z.string(),
    priceId: z.string(),
    productId: z.string(),
    monthlyPrice: z.number(),
    firebaseRole: z.string(),
    type: subscriptionType,
    features: z.array(z.object({ featureDescription: z.string(), included: z.boolean() })),
  })
  .strict();
export type StripePlanFeature = z.infer<typeof stripePlanFeatureSchema>;

export const stripePlanResponse = z
  .object({
    subscriptions: z.object({
      monthly: z.array(stripePlanSchema),
      yearly: z.array(stripePlanSchema),
    }),
    products: z.array(stripePlanSchema),
  })
  .strict();
export type StripePlanResponse = z.infer<typeof stripePlanResponse>;

export const stripePlanFeatureResponse = z.array(stripePlanFeatureSchema);
export type StripePlanFeatureResponse = z.infer<typeof stripePlanFeatureResponse>;

export const checkIfOldSubscriptionSchema = z.object({
  isOld: z.boolean(),
});

export type CheckIfOldSubscriptionResponse = z.infer<typeof checkIfOldSubscriptionSchema>;

export type StripePaymentMode = "payment" | "subscription";

export type StripePlanPeriod = z.infer<typeof subscriptionType>;

export interface CheckoutLinkParams {
  email: string;
  userId: string;
  priceId: string;
  mode: StripePaymentMode;
  billingAnchor?: number;
}

export interface CheckoutConfig {
  line_items: [
    {
      price: string;
      quantity: number;
    },
  ];
  mode: StripePaymentMode;
  success_url: string;
  cancel_url: string;
  subscription_data?: {
    billing_cycle_anchor: number;
  };
}

export interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}

export interface CampaignData {
  name: string;
  startDate: FirestoreTimestamp;
  endDate: FirestoreTimestamp;
  tags: string[];
}

export interface CheckoutLinkParams {
  email: string;
  userId: string;
  priceId: string;
  mode: StripePaymentMode;
  billingAnchor?: number;
}

export interface CheckoutConfig {
  line_items: [
    {
      price: string;
      quantity: number;
    },
  ];
  mode: StripePaymentMode;
  success_url: string;
  cancel_url: string;
  subscription_data?: {
    billing_cycle_anchor: number;
  };
}
