import { useCallback, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitErrorHandler, type SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useCreateProject } from "@/api/project.ts";
import { ROUTES } from "@/assets/constants/constants";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { projectColorKeys } from "@/assets/constants/projects.ts";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { ChevronDown } from "@/components/v3";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { enumToProjectIcon, useOpenSignUpToQuinoModal } from "@/service/library.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { ProjectIconsEnum } from "@/types/schemas";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const newProjectSchema = z.object({
  projectName: z
    .string()
    .min(1, {
      message: "Must be at least 1 character",
    })
    .max(100, { message: "Must be at most 100 characters" }),
  icon: z.object({
    iconColor: z.string().length(7).regex(/^#/),
    iconStyle: z.nativeEnum(ProjectIconsEnum),
  }),
});

export type NewProjectFormData = z.infer<typeof newProjectSchema>;

const CreateProjectModal = () => {
  const form = useForm<NewProjectFormData>({
    resolver: zodResolver(newProjectSchema),
    mode: "onSubmit",
    defaultValues: {
      projectName: "",
      icon: {
        iconColor: "#572E9E",
        iconStyle: ProjectIconsEnum.CHART,
      },
    },
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const { matched: isDemoPage } = useIsDemoLikePage();
  const [isOpen, setModalState] = useModalState(ModalKeys.CREATE_PROJECT_MODAL_OPEN);
  const openSignUp = useOpenSignUpToQuinoModal();
  const navigate = useNavigate();
  const workspaceId = useCurrentWorkspaceId();

  const createProject = useCreateProject();

  const handleClose = useCallback(() => {
    form.reset();
    setModalState(false);
  }, [form, setModalState]);

  const handleSubmit: SubmitHandler<NewProjectFormData> = (formData) => {
    if (isDemoPage) {
      mixpanelTrack("project_created", {
        project_name: formData.projectName,
      });
      openSignUp();
      return;
    }
    createProject.mutate(
      {
        name: formData.projectName,
        colorTag: formData.icon.iconColor,
        projectIcon: formData.icon.iconStyle,
        workspaceId: workspaceId ?? "",
      },
      {
        onSuccess: (data) => {
          handleClose();
          navigate(`${ROUTES.WORKSPACES}/${workspaceId}/${data.id}`);
        },
      },
    );
  };

  const handleErrors: SubmitErrorHandler<NewProjectFormData> = (errors) => {
    console.log(errors);
  };

  const handleOpenChange = isOpen ? handleClose : () => setModalState(true);

  useEffect(() => {
    if (createProject.isSuccess) handleClose();
  }, [createProject.isSuccess, handleClose]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-12">
        <DialogHeader>
          <DialogTitle>Create new project</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-12" onSubmit={form.handleSubmit(handleSubmit, handleErrors)}>
            <section className="flex flex-col items-end gap-2">
              <FormField
                control={form.control}
                name="projectName"
                render={({ field }) => (
                  <FormItem className="w-full flex-1">
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input className="resize-none" placeholder="Project name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="icon.iconColor"
                render={({ field }) => (
                  <FormItem className="w-full space-y-0">
                    <FormLabel className="">Select color</FormLabel>

                    <FormControl>
                      <section className="grid grid-cols-[repeat(14,_minmax(0,_1fr))] gap-0.5 pt-1">
                        {projectColorKeys.map((item) => (
                          <Button
                            className="h-7 w-7 p-1.5 data-[selected=true]:bg-tertiary-container-hover"
                            data-selected={item === field.value}
                            key={item}
                            size="icon"
                            type="button"
                            variant="icon"
                            onClick={() => {
                              field.onChange(item);
                            }}
                          >
                            <span className="h-4 w-4 rounded-full" key={item} style={{ backgroundColor: item }} />
                          </Button>
                        ))}
                      </section>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="icon.iconStyle"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel className="">Select icon</FormLabel>
                    <FormControl>
                      <section
                        className="grid max-h-[5rem] grid-cols-[repeat(14,_minmax(0,_1fr))] gap-0.5 overflow-hidden pt-1 transition-all data-[is-expanded=true]:max-h-full"
                        data-is-expanded={isExpanded}
                      >
                        {(Object.keys(ProjectIconsEnum) as Array<keyof typeof ProjectIconsEnum>).map((item) => {
                          const Comp = enumToProjectIcon[item];
                          return (
                            <Button
                              className="data-[selected=true]:bg-tertiary-container-hover"
                              data-selected={item === field.value}
                              key={item}
                              size="icon"
                              type="button"
                              variant="icon"
                              onClick={() => {
                                field.onChange(item);
                              }}
                            >
                              <Comp />
                            </Button>
                          );
                        })}
                      </section>
                    </FormControl>
                    <Button
                      className="group"
                      data-opened={isExpanded}
                      size="xs"
                      type="button"
                      variant="ghost"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      Show all icons <ChevronDown className="group group-data-[opened=true]:rotate-180" />
                    </Button>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </section>
            <DialogFooter className="">
              <Button className="font-extrabold" size="sm" type="button" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={createProject.isPending || !form.formState.isValid} size="sm" type="submit">
                Create
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateProjectModal;
