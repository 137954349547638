import { type Dispatch, type SetStateAction, createContext } from "react";

import type { OnboardingChoices } from "@/types/app.ts";

const OnboardContext = createContext<{
  done: boolean;
  currentStep: number;
  setDone: (value: boolean) => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  onSkip: () => void;
  choices: OnboardingChoices;
  setChoices: Dispatch<SetStateAction<OnboardingChoices>>;
}>({
  done: false,
  setDone: () => new Error("No implementation"),
  onSkip: () => new Error("No implementation"),
  currentStep: 0,
  setCurrentStep: () => new Error("No implementation"),
  choices: {
    occupation: "",
    fieldOfOccupation: "",
    howDoYouKnowUs: {
      choice: "",
      value: "",
    },
    country: "",
  },
  setChoices: () => new Error("No implementation"),
});

export default OnboardContext;
