import { useMemo } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { MemberRoleSelector, StarIconFilled } from "@/components/v3";
import { usePublicData } from "@/service/user.ts";

const CollaboratorCard = ({
  userId,
  isAdmin,
  isLoggedInUserCurrentAdmin,
}: {
  userId: string;
  isAdmin: boolean;
  isLoggedInUserCurrentAdmin: boolean;
}) => {
  const { displayName, avatarFallback, photoUrl, email } = usePublicData(userId);
  const accessControl = useMemo(() => {
    if (isAdmin)
      return (
        <section className="flex flex-row items-center gap-1 text-xs font-bold text-secondary-onBg">
          <StarIconFilled /> Admin
        </section>
      );
    if (isLoggedInUserCurrentAdmin) {
      return <MemberRoleSelector userId={userId} />;
    }
    return <p className="text-xs font-bold text-secondary-onBg">Member</p>;
  }, [isAdmin, isLoggedInUserCurrentAdmin, userId]);

  return (
    <section
      className="order-2 flex flex-row items-center gap-2 p-1 data-[is-first=true]:order-1"
      data-is-first={isAdmin}
    >
      <Avatar className="border-1 h-8 w-8 border-primary-purple">
        <AvatarImage alt="user-avatar" className="h-8 w-8 bg-primary" src={photoUrl ?? ""} />
        <AvatarFallback className="text-xs" fallbackColorIndex={avatarFallback.fallbackColorIndex}>
          {avatarFallback.monogram ?? "QU"}
        </AvatarFallback>
      </Avatar>
      <section className="flex flex-col">
        <p className="text-xs font-bold text-primary">{displayName}</p>
        <p className="text-xs text-secondary-onBg">{email}</p>
      </section>
      <section className="ml-auto">{accessControl}</section>
    </section>
  );
};

export default CollaboratorCard;
