import { useMemo } from "react";

import { useIsMutating } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";

import { Dialog, DialogContent } from "@/components/ui/dialog.tsx";
import { LoadingSpinner } from "@/pages/loading";

const Overlays = () => {
  const isCopyMutating = useIsMutating({ mutationKey: ["clone-project"] });
  const dialog = useMemo(() => {
    if (isCopyMutating > 0)
      return (
        <Dialog open={true}>
          <DialogContent hideClose className="cursor-wait text-xl" overlayClassName="cursor-wait">
            <p className="flex flex-col items-center justify-center gap-2">
              <p className="text-xl font-bold text-primary">Copying the project </p>
              <LoadingSpinner className="w-auto" />
            </p>
          </DialogContent>
        </Dialog>
      );
  }, [isCopyMutating]);

  return (
    <>
      {dialog}
      <Outlet />
    </>
  );
};

export default Overlays;
