import { useEffect, useRef } from "react";

import useAppStateStore from "@/zustand/store";

export const useHeadsObserver = ({ disabled }: { disabled?: boolean }) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const { setSectionState } = useAppStateStore();

  useEffect(() => {
    if (disabled) return;
    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setSectionState(entry.target.id);
        }
      });
    };

    observer.current = new IntersectionObserver(handleObserver, {
      rootMargin: "-20% 0% -35% 0px",
    });

    const elements = Array.from(document.getElementsByClassName("observedSection"));
    elements.forEach((elem) => observer.current?.observe(elem));
    return () => observer.current?.disconnect();
  }, [disabled, setSectionState]);

  return;
};
