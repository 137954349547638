import { useMemo } from "react";

import { QuinoTooltip } from "@/components/generic";
import { Button } from "@/components/ui/button.tsx";
import { SortAscendingIcon, SortDescendingIcon } from "@/components/v3";
import { useLibrarySorting } from "@/service/library.ts";

const SortWorkspaceExplorer = () => {
  const [librarySorting, setLibrarySorting] = useLibrarySorting();

  const icon = useMemo(() => {
    if (librarySorting === "ASC") return <SortDescendingIcon />;
    return <SortAscendingIcon />;
  }, [librarySorting]);

  return (
    <QuinoTooltip align="center" content="Sort items" side="top">
      <Button
        size="icon-large"
        variant="icon"
        onClick={() => setLibrarySorting(librarySorting === "ASC" ? "DESC" : "ASC")}
      >
        {icon}
      </Button>
    </QuinoTooltip>
  );
};
export default SortWorkspaceExplorer;
