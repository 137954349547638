import LetterSizePopover from "@/components/document/common/letter-size-popover.tsx";
import ViewerPanelFooter from "@/components/document/common/viewer-panel-footer.tsx";
import NoteSelector from "@/components/document/note/note-selector.tsx";
import ExportNoteToPdf from "@/components/note/export-note-to-pdf.tsx";

const NoteViewFooter = () => {
  return (
    <ViewerPanelFooter>
      <NoteSelector />
      <LetterSizePopover />
      <ExportNoteToPdf variant="icon" />
    </ViewerPanelFooter>
  );
};

export default NoteViewFooter;
