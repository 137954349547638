import { useState } from "react";

import ReactPlayer from "react-player";

import { Spinner } from "@/components/icons/common";
import { QuinoMarkdown } from "@/utils/util-components.tsx";

interface IProps {
  title: string;
  subtitle?: string;
  videoUrl: string;
}

const TutorialStep = ({ title, subtitle = "???", videoUrl }: IProps) => {
  const [loading, setLoading] = useState(true);
  return (
    <section className="flex flex-col items-center">
      <section className="relative mb-6 flex w-full overflow-hidden rounded-2xl border border-primary/15 shadow shadow-primary/10">
        {loading && (
          <section className="absolute inset-0 top-1/2 grid">
            <Spinner className="mx-auto" />
          </section>
        )}
        <ReactPlayer
          loop
          muted
          playing
          className="relative w-full"
          fallback={
            <section className={"!h-full !w-full !flex-1 !flex-col !items-center !justify-center"}>
              <section className="flex h-full flex-col justify-center">
                <Spinner />
              </section>
            </section>
          }
          height="auto"
          url={videoUrl}
          width="100%"
          onReady={() => setLoading(false)}
        />
      </section>
      <p className=" w-[400px] text-xl font-bold text-primary dark:text-white">{title}</p>
      <p className="mb-6 w-[400px] text-base font-medium text-primary">
        <QuinoMarkdown>{subtitle}</QuinoMarkdown>
      </p>
    </section>
  );
};

export default TutorialStep;
