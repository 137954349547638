import { useLegalInformation } from "@/api/strapi";
import LegalTemplate from "@/components/account/legal-template";

const TermsAndConditions = () => {
  const { data, isLoading, isError } = useLegalInformation();

  return (
    <LegalTemplate
      data={data?.data.attributes.termsAndConditions}
      isError={isError}
      isLoading={isLoading}
      title="Terms and Conditions"
    />
  );
};

export default TermsAndConditions;
