import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const LockIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      {...props}
      className={cn("h-4 w-4", props.className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.3335C9.03492 1.33346 10.0296 1.73454 10.775 2.45247C11.5204 3.17041 11.9585 4.1493 11.9973 5.1835L12 5.3335H12.6667C13.0031 5.33339 13.327 5.46043 13.5737 5.68916C13.8204 5.91789 13.9714 6.23139 13.9967 6.56683L14 6.66683V13.3335C14.0001 13.6699 13.8731 13.9939 13.6443 14.2405C13.4156 14.4872 13.1021 14.6383 12.7667 14.6635L12.6667 14.6668H3.33333C2.99695 14.6669 2.67296 14.5399 2.4263 14.3112C2.17965 14.0824 2.02856 13.7689 2.00333 13.4335L2 13.3335V6.66683C1.99989 6.33044 2.12694 6.00645 2.35566 5.7598C2.58439 5.51314 2.8979 5.36206 3.23333 5.33683L3.33333 5.3335H4C4 4.27263 4.42143 3.25521 5.17157 2.50507C5.92172 1.75492 6.93913 1.3335 8 1.3335ZM12.6667 6.66683H3.33333V13.3335H12.6667V6.66683ZM8 8.00016C8.28439 8.00025 8.56131 8.09127 8.79029 8.25993C9.01928 8.42858 9.18834 8.66604 9.27278 8.9376C9.35722 9.20917 9.35262 9.50063 9.25965 9.76939C9.16667 10.0382 8.9902 10.2702 8.756 10.4315L8.66667 10.4882V11.3335C8.66648 11.5034 8.60141 11.6669 8.48477 11.7904C8.36812 11.914 8.2087 11.9883 8.03907 11.9983C7.86944 12.0082 7.70241 11.953 7.57211 11.844C7.44181 11.7349 7.35807 11.5802 7.338 11.4115L7.33333 11.3335V10.4882C7.07916 10.3414 6.88052 10.1149 6.76821 9.84371C6.65589 9.57254 6.63619 9.2719 6.71216 8.9884C6.78812 8.7049 6.95551 8.45438 7.18836 8.27571C7.4212 8.09703 7.7065 8.00018 8 8.00016ZM8 2.66683C7.29276 2.66683 6.61448 2.94778 6.11438 3.44788C5.61428 3.94797 5.33333 4.62625 5.33333 5.3335H10.6667C10.6667 4.62625 10.3857 3.94797 9.88562 3.44788C9.38552 2.94778 8.70724 2.66683 8 2.66683Z"
        fill="#572E9E"
      />
    </svg>
  );
};
export default LockIcon;
