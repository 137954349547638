import type { ElementRef, SVGProps } from "react";

const InfoIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg {...props} fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99967 1.3335C11.6817 1.3335 14.6663 4.31816 14.6663 8.00016C14.6663 11.6822 11.6817 14.6668 7.99967 14.6668C4.31767 14.6668 1.33301 11.6822 1.33301 8.00016C1.33301 4.31816 4.31767 1.3335 7.99967 1.3335ZM7.99967 2.66683C6.58519 2.66683 5.22863 3.22873 4.22844 4.22893C3.22824 5.22912 2.66634 6.58567 2.66634 8.00016C2.66634 9.41465 3.22824 10.7712 4.22844 11.7714C5.22863 12.7716 6.58519 13.3335 7.99967 13.3335C9.41416 13.3335 10.7707 12.7716 11.7709 11.7714C12.7711 10.7712 13.333 9.41465 13.333 8.00016C13.333 6.58567 12.7711 5.22912 11.7709 4.22893C10.7707 3.22873 9.41416 2.66683 7.99967 2.66683ZM7.99301 6.66683C8.36501 6.66683 8.66634 6.96816 8.66634 7.34016V10.7562C8.79343 10.8295 8.89275 10.9428 8.9489 11.0784C9.00506 11.214 9.01491 11.3643 8.97693 11.506C8.93895 11.6478 8.85525 11.7731 8.73883 11.8624C8.62241 11.9517 8.47976 12.0002 8.33301 12.0002H8.00634C7.91792 12.0002 7.83036 11.9827 7.74867 11.9489C7.66697 11.9151 7.59275 11.8655 7.53022 11.8029C7.4677 11.7404 7.4181 11.6662 7.38426 11.5845C7.35042 11.5028 7.33301 11.4153 7.33301 11.3268V8.00016C7.1562 8.00016 6.98663 7.92992 6.8616 7.8049C6.73658 7.67988 6.66634 7.51031 6.66634 7.3335C6.66634 7.15668 6.73658 6.98712 6.8616 6.86209C6.98663 6.73707 7.1562 6.66683 7.33301 6.66683H7.99301ZM7.99967 4.66683C8.17649 4.66683 8.34605 4.73707 8.47108 4.86209C8.5961 4.98712 8.66634 5.15668 8.66634 5.3335C8.66634 5.51031 8.5961 5.67988 8.47108 5.8049C8.34605 5.92992 8.17649 6.00016 7.99967 6.00016C7.82286 6.00016 7.65329 5.92992 7.52827 5.8049C7.40325 5.67988 7.33301 5.51031 7.33301 5.3335C7.33301 5.15668 7.40325 4.98712 7.52827 4.86209C7.65329 4.73707 7.82286 4.66683 7.99967 4.66683Z"
        fill="#7B7382"
      />
    </svg>
  );
};
export default InfoIcon;
