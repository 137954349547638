import { useCurrentWorkspace } from "@/api/workspace.ts";
import { Button } from "@/components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { QuinoPopoverTitle, QuinoShareLink, ShareArrow } from "@/components/v3";
import { DemoHandlerButton } from "@/components/v3";
import { useCurrentDocumentTabEntity } from "@/service/hooks/react-router.ts";

const ShareDocumentPopover = () => {
  const { data: documentData } = useCurrentDocumentTabEntity();
  const { data: workspace } = useCurrentWorkspace();

  if (!documentData)
    return (
      <Button disabled className="aspect-square rounded-lg data-[state=open]:bg-white" size="icon" variant="icon">
        <ShareArrow />
      </Button>
    );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <DemoHandlerButton
          className="ml-1 aspect-square rounded-lg hover:bg-white/50 data-[state=open]:bg-white"
          size="icon"
          variant="icon"
        >
          <ShareArrow />
        </DemoHandlerButton>
      </PopoverTrigger>
      <PopoverContent align="end" alignOffset={-150} className="z-[100] w-[32rem] gap-1 rounded-xl p-4 font-jakarta">
        <section className="flex flex-col gap-4">
          <QuinoPopoverTitle title={`Share “${documentData?.name}”`} />
          <QuinoShareLink workspaceId={workspace?.id ?? ""} />
        </section>
      </PopoverContent>
    </Popover>
  );
};

export default ShareDocumentPopover;
