import { useEffect, useMemo, useRef } from "react";

import { useDocumentChatSession } from "@/api/document-chat";
import { FunnyStuffType } from "@/assets/constants/funny-stuff.ts";
import FunnyStuff from "@/components/funny-stuff";
import { LockIcon } from "@/components/icons";
import QuinoAnswer from "@/components/note/chat/quino-answer.tsx";
import QuinoChatBaseSuggestions from "@/components/note/chat/quino-chat-base-suggestions.tsx";
import QuinoQuestionSuggestion from "@/components/note/chat/quino-question-suggestion.tsx";
import QuinoQuestion from "@/components/note/chat/quino-question.tsx";
import EmptyImage from "@/components/note/chat/visual/empty-image.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { useIsChatLoading } from "@/service/chat.ts";
// import { useIsFreeUser } from "@/service/hooks/settings.ts";
import { cn } from "@/utils";

const QuinoChatList = () => {
  const { data: sessionData, isLoading: isSessionLoading } = useDocumentChatSession();
  const isFreeUser = false; // useIsFreeUser(); needs review
  const isLoading = useIsChatLoading();
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const content = useMemo(
    () => {
      if (isSessionLoading)
        return (
          <section className="flex flex-1 flex-col gap-4 px-1.5 py-3">
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[92%]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[98%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[98%]" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
                <Skeleton className="h-4 w-full" />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="w-[calc(100%-4rem)] space-y-2">
                <Skeleton className="h-3 w-[116px]" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-[95%]" />
              </div>
            </div>
          </section>
        );

      return (sessionData && sessionData?.elements && sessionData.elements.length > 0) || isLoading ? (
        <>
          {sessionData?.elements.map((item, index) => (
            <section className="flex flex-col gap-2" key={item.id}>
              {/*Refactor this after feature is done*/}
              <QuinoQuestion date={item.dateCreated} description={item.inputText} />
              <QuinoAnswer
                date={item.dateCreated}
                historyElementId={item.id}
                isLastInRow={index === sessionData.elements.length - 1}
              />
              {/*done*/}
              {index % 5 === 0 && isFreeUser && <FunnyStuff adId={FunnyStuffType.quino_doc_300x250} />}
            </section>
          ))}
        </>
      ) : (
        <section className="flex h-full w-full flex-col items-center justify-end gap-6">
          <section className="flex flex-1 flex-col items-center justify-center gap-6">
            <EmptyImage />
            <section>
              <Typography asChild size="large">
                <h2 className="text-center text-xl font-semibold">Ask your questions from Quino AI</h2>
              </Typography>
              <Typography asChild size="small">
                <p className="text-center text-secondary-onBg">
                  Get answers based on your document, a project <br />
                  or a whole workspace and speed up your learning.
                </p>
              </Typography>
              <Typography asChild size="small">
                <p className="mt-6 flex items-center justify-center gap-1 text-center text-xs text-secondary-onBg">
                  Visible only to you
                  <span className="inline-block h-3 w-3">
                    <LockIcon className="h-3 w-3 fill-secondary-onBg" />
                  </span>
                </p>
              </Typography>
            </section>
          </section>
          <QuinoChatBaseSuggestions />
        </section>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionData?.elements?.length, isLoading, isFreeUser, sessionData],
  );

  useEffect(() => {
    if (isSessionLoading) return;
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [sessionData?.elements?.length, isLoading, isSessionLoading]);

  return (
    <section className="relative flex w-full flex-1 flex-col  overflow-hidden">
      <section className="absolute -top-1 z-[100] h-4 w-full bg-gradient-to-b from-bg-layer1 from-15% to-transparent" />
      <section
        className="flex w-full flex-1 flex-col gap-8 overflow-y-auto p-4 data-[loading=true]:overflow-hidden"
        data-loading={isSessionLoading}
      >
        <section
          className={cn(
            "mx-auto flex w-full max-w-5xl flex-col gap-2",
            (!sessionData || !sessionData.elements) && "flex-1",
          )}
        >
          {content}
        </section>
        <section
          className="h-[2rem] w-full data-[hidden=true]:hidden"
          data-hidden={!sessionData?.elements?.length}
          ref={bottomRef}
        >
          <br />
          <br />
          <br />
        </section>
      </section>
      <QuinoQuestionSuggestion />
    </section>
  );
};

export default QuinoChatList;
