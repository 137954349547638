import { ModalKeys } from "@/assets/constants/modal.ts";
import { DemoHandlerButton } from "@/components/v3/demo";
import { NewDocumentIcon } from "@/components/v3/icons";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const AddDocumentsButton = () => {
  const [, setOpen] = useModalState(ModalKeys.DOCUMENT_UPLOAD_OPEN);

  return (
    <DemoHandlerButton className="drop-shadow-none" size="sm" onClick={() => setOpen({ isOpen: true })}>
      <NewDocumentIcon className="fill-white" />
      Add documents
    </DemoHandlerButton>
  );
};
export default AddDocumentsButton;
