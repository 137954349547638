import { ActivityFeed, Announcements, HomeHeader } from "@/components/v3";

const Home = () => {
  return (
    <main className="flex w-full flex-col gap-12">
      <HomeHeader />
      <Announcements />
      <ActivityFeed />
    </main>
  );
};

export default Home;
