import type React from "react";

import { type User } from "@firebase/auth";

import { type FlashCardVariant, type ModelLogFeedback } from "@/assets/constants/constants";
import { type ModalSlice, type TutorialVariant } from "@/assets/constants/modal";
import {
  type ProjectDocumentRedisTicker,
  type GeneratedBasicFlashCard,
  type GeneratedMultipleChoiceFlashCard,
  type GeneratedTrueFalseFlashcard,
  type FolderElement,
  type ProjectEntity,
} from "@/types/schemas";
import { type ModelLakeResponse } from "@/types/schemas";

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare global {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  let gptadslots: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  let googletag: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  let pubwise: any;
}

export interface ProjectTabState {
  documentTabs: string[];
  noteTabs: string[];
  activeDocumentTab: string;
  activeNoteTab: string;
}

export interface TutorialSlice {
  tutorialStates: Record<TutorialVariant, { run: boolean }>;
  setTutorialState: ({ variant, payload }: { variant: TutorialVariant; payload: { run: boolean } }) => void;
}

export interface TabSlice {
  setProjectTabState: ({ id, state }: { id: string; state: ProjectTabState }) => void;
  projectTabState: Record<string, ProjectTabState>;
  openDocument: (projectId: string, documentId: string) => void;
  openNote: (projectId: string, noteId: string) => void;
  closeDocument: (projectId: string, documentId: string) => void;
  deleteDocumentOfProject: (projectId: string, documentId: string) => void;
  closeNote: (projectId: string, noteId: string) => void;
  initTabState: (projectId: string) => void;
  validateTabStateForProjects: ({ projectIds }: { projectIds: string[] }) => void;
  validateTabStateStoreForProject: ({
    projectId,
    documentIds,
    noteIds,
  }: {
    projectId: string;
    documentIds: string[];
    noteIds: string[];
  }) => void;
}

export enum ViewMode {
  SPLIT = "SPLIT",
  TOOLS = "TOOLS",
  DOCUMENT = "DOCUMENT",
}

export interface DocumentPageSlice {
  viewMode: ViewMode;
  setViewMode: (mode: ViewMode) => void;
  showWorkspaces: boolean;
  setShowWorkspaces: (showWorkspaces: boolean) => void;
  docProcessing: Record<string, ProjectDocumentRedisTicker>;
  setDocProcessing: ({ payload }: { payload: ProjectDocumentRedisTicker[] }) => void;
  setAllToProcessed: () => void;
}

export interface AppStateSlice {
  user: User | null;
  setUser: (user: User | null) => void;
  hideAllMaintenance: boolean;
  setHideAllMaintenance: (newValue: boolean) => void;
  isParagraphSelectOpen: boolean;
  setParagraphSelectOpen: (isParagraphSelectOpen: boolean) => void;
  isMonthly: boolean;
  setIsMonthly: (isMonthly: boolean) => void;
  sideBarOpen: boolean;
  setSideBarOpen: (value: boolean) => void;
  isFirebaseActionChecked: boolean;
  setIsFirebaseActionChecked: (value: boolean) => void;
  loadingState: { loading: boolean; message?: string };
  setLoadingState: (loadingState: { loading: boolean; message?: string }) => void;
}

export interface LibraryPageSlice {
  showDetailsView: boolean;
  setShowDetailsView: (value: boolean) => void;
  selectedProjects: Array<ProjectEntity>;
  selectProject: (resource: ProjectEntity) => void;
  unSelectProject: (workspaceId: string) => void;
  selectAllProjects: (workspaces: Array<ProjectEntity>) => void;
  unSelectAllProjects: () => void;
  selectedResources: Array<FolderElement>;
  selectResource: (resource: FolderElement) => void;
  unselectResource: (resourceId: string) => void;
  selectAllResources: (resources: Array<FolderElement>) => void;
  unselectAllResources: () => void;
  libraryView: "grid" | "row";
  setLibraryView: (view: "grid" | "row") => void;
  librarySorting: "ASC" | "DESC";
  setLibrarySorting: (view: "ASC" | "DESC") => void;
  showFilterBar: boolean;
  setShowFilterBar: (show: boolean) => void;
}

export interface AiFunctionSlice {
  usageCount: number;
  AILoading: boolean;
  sessionCache: Record<string, { lastQuestion: string; lastAnswer: string }>;
  setSessionCache: (payload: { id: string; data: { lastQuestion: string; lastAnswer: string } }) => void;
  setAILoading: (AILoading: boolean) => void;
  currentPage: Record<string, number>;
  setActivePage: (documentId: string, value: number) => void;
  lastModelResponse: ModelLakeResponse | null;
  chatDocuSearch: { text: string } | null;
  setChatDocuSearch: (payload: { text: string }) => void;
  setLastModelResponse: (response: AiFunctionSlice["lastModelResponse"]) => void;
  flashCardLoading: boolean;
  setFlashCardLoading: (value: boolean) => void;
  flashCardCache: {
    [FlashCardVariant.BASIC]: GeneratedBasicFlashCard | null;
    [FlashCardVariant.MULTIPLE]: GeneratedMultipleChoiceFlashCard | null;
    [FlashCardVariant.TRUE_FALSE]: GeneratedTrueFalseFlashcard | null;
  };
  setFlashCardCache: <T extends keyof AiFunctionSlice["flashCardCache"]>(
    variant: T,
    payload: AiFunctionSlice["flashCardCache"][T],
  ) => void;
}

export interface KnowledgeDeckSlice {
  editMode: boolean;
  setEditMode: (value: boolean) => void;
}

export interface PdfViewerSlice {
  isSearchPopoverOpen: boolean;
  editedParagraphId: string;
  setEditedParagraph: ({ editedParagraphId }: { editedParagraphId: string }) => void;
  setIsSearchPopoverOpen: (isSearchPopoverOpen: boolean) => void;
  pdfViewerSearchTarget: Record<string, { page?: number; text: string; single?: boolean } | undefined>;
  setPdfViewerSearchTarget: (payload: {
    documentId: string;
    payload?: { page?: number; text: string; single?: boolean };
  }) => void;
  pdfViewerResults: Record<
    string,
    | {
        currentViewedResultIndex: number;
        searchValue: string;
        searchResult: object[];
      }
    | undefined
  >;
  setPdfViewerResults: (payload: { documentId: string; payload?: { results: object[]; searchValue: string } }) => void;
  setCurrentViewedIndex: (payload: { documentId: string; currentIndex: number }) => void;
  clearSearchData: (documentId: string) => void;
}
export interface DemoSlice {}
export interface NavigationDrawerState {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  setDrawerOpen: (value: boolean) => void;
}

export interface SectionObserverStateSlice {
  sectionState: string;
  setSectionState: (value: string) => void;
}

export type AppSlice = TabSlice &
  DocumentPageSlice &
  AiFunctionSlice &
  ModalSlice &
  LibraryPageSlice &
  AppStateSlice &
  TutorialSlice &
  DemoSlice &
  PdfViewerSlice &
  NavigationDrawerState &
  SectionObserverStateSlice;

export interface PdfViewerProps {
  documentId: string;
  workspaceId: string;
  documentName: string;
  fileUrl: string;
}

export interface PdfViewerWrapperProps extends Omit<PdfViewerProps, "fileUrl" | "documentName"> {
  hidden?: boolean;
}

export interface ModelLogResponse {
  userAccepted: boolean;
  thumbsUp: ModelLogFeedback;
}

export interface FileData {
  id: string;
  file: File;
}

export interface YesNoModalProps {
  open: boolean;
  loading?: boolean;
  title: string;
  description?: string | React.ReactNode;
  cancelButtonText?: string;
  acceptButtonText?: string;
  onYes?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  acceptButtonClassName?: string;
  image?: string;
}

export interface OnboardingChoices {
  occupation: string;
  fieldOfOccupation: string;
  howDoYouKnowUs: { choice: string; value: string };
  country: string;
}

export interface OnboardingBEPayload {
  occupation: string;
  fieldOfOccupation: string;
  howDoYouKnowUs: string;
  country: string;
}

export interface OnboardingChoicesWithTime extends OnboardingBEPayload {
  onboardingTimeInSeconds: number;
}

export interface OnboardingStep {
  title: string;
  description: string;
  id: keyof OnboardingChoices;
  options?: { id: string; text: string; icon?: React.ReactNode }[];
}

export type FontScale = "small" | "normal" | "large";

export interface PlanComparisonCategory {
  access: string;
  features?: string[] | React.ReactNode[];
}

export interface Mark {
  value: number;
  label?: React.ReactNode;
}

export interface QuinoTooltipProps {
  content?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  open?: boolean;
  hidden?: boolean;
  side?: "top" | "right" | "bottom" | "left";
  align?: "start" | "center" | "end";
  onOpenChange?: (open: boolean) => void;
  delay?: number;
}

export interface UserDataBackend {
  country: string;
  occupation: string;
  fieldOfOccupation: string;
  surname: string;
  firstName: string;
  displayName: string;
  university: string;
  gender: string;
  dateOfBirth: string;
}

export interface FontSizeAppConfig {
  size: FontScale;
  variant: "default";
}

export type ExportButtonType = "text" | "text-small" | "icon";

export enum UploadType {
  NEW_PROJECT = "NEW_PROJECT",
  EXISTING_PROJECT = "EXISTING_PROJECT",
  NEW_WORKSPACE = "NEW_WORKSPACE",
}

export interface BasePayload {
  workspaceId?: string;
  workspaceName?: string;
  projectId?: string;
  projectName?: string;
}

export interface ExistingProjectPayload extends BasePayload {
  uploadType: UploadType.EXISTING_PROJECT;
  workspaceId: string;
  workspaceName: string;
  projectId: string;
  projectName: string;
}

export interface NewProjectPayload extends BasePayload {
  uploadType: UploadType.NEW_PROJECT;
  workspaceId: string;
  workspaceName: string;
  projectName?: string;
}

export interface NewWorkspacePayload extends BasePayload {
  uploadType: UploadType.NEW_WORKSPACE;
  workspaceName?: string;
  projectName?: string;
}

export type ProjectSelection = NewProjectPayload | ExistingProjectPayload | NewWorkspacePayload;

export interface NewWorkspace extends NewWorkspacePayload {
  hasDefaultWorkspace: false;
  hasDefaultProject: false;
}
export interface ExistingProject extends ExistingProjectPayload {
  hasDefaultWorkspace: true;
  hasDefaultProject: true;
}
export interface NewProject extends NewProjectPayload {
  hasDefaultWorkspace: true;
  hasDefaultProject: false;
}

export type ProjectSelectionPayload = NewProject | ExistingProject | NewWorkspace;
