import { z } from "zod";

import { estimationStatus, summarizationStatus } from "@/assets/constants/constants.ts";

export enum LikeDislikeValue {
  NEUTRAL = "NEUTRAL",
  UPVOTE = "UPVOTE",
  DOWNVOTE = "DOWNVOTE",
}

export const documentSummaryEstimateSchema = z
  .object({
    documentSummaryId: z.string(),
    numOfWords: z.number(),
    tokenCost: z.number(),
    summarizationStatus: z.string(),
    title: z.string(),
    estimationStatus: estimationStatus,
  })
  .strict();

export type DocumentSummaryEstimate = z.infer<typeof documentSummaryEstimateSchema>;

export const chapterListSchema = z
  .object({
    chapterOrderList: z.array(z.string()),
    chapters: z.record(
      z.string(),
      z.object({
        pageOrderList: z.array(z.string()),
        pages: z.record(z.string(), z.array(z.string())),
      }),
    ),
  })
  .strict();
export type ChapterList = z.infer<typeof chapterListSchema>;

export const paragraphUpdateResponse = z
  .object({
    id: z.string(),
    generatedSummary: z.string(),
    userLikeDislikeValue: z.nativeEnum(LikeDislikeValue),
    likeDislikeValue: z.number(),
    highlighted: z.boolean(),
    expanded: z.boolean(),
  })
  .strict();

export type ParagraphUpdateResponse = z.infer<typeof paragraphUpdateResponse>;

export const paragraphSummarySchema = paragraphUpdateResponse
  .extend({
    pageNumbers: z.number().array(),
    highlighted: z.boolean().nullish(),
  })
  .strict();

export type ParagraphSummary = z.infer<typeof paragraphSummarySchema>;

export const summaryResponseSchema = z
  .object({
    id: z.string().nullable(),
    summarizationStatus: summarizationStatus,
    cost: z.number().optional(),
    hasMore: z.boolean().optional().default(false),
    title: z.string().nullable(),
    language: z.string(),
    tokenCount: z.number().nullish(),
    paginationPageNum: z.number().nullish(),
    chapterIdOrderedList: z.string().array(),
    chapterIdNamePairs: z.record(
      z.string(),
      z.object({
        chapterName: z.string(),
        pageNumberStart: z.string(),
        pageNumberEnd: z.string(),
      }),
    ),
    paragraphSummariesOfChapter: z.record(z.string(), z.array(z.string())),
    paragraphEntities: z.record(z.string(), paragraphSummarySchema),
    resummarizationEligible: z.boolean().nullish(),
  })
  .strict();

export type SummaryResponse = z.infer<typeof summaryResponseSchema>;

export const summarizationProgress = z
  .object({
    document_id: z.string(),
    document_summary_id: z.string(),
    summarization_time_in_seconds: z.number(),
    paragraph_num_summarization: z.number(),
    max_paragraph_num_summarization: z.number(),
    processing_finished: z.boolean(),
    error_occured: z.boolean(),
  })
  .strict();

export type SummarizationProgressPayload = z.infer<typeof summarizationProgress>;

export const estimationProcessingStatus = z
  .object({
    document_id: z.string(),
    error_message: z.string(),
    filechunk_number_extraction: z.number(),
    max_filechunk_number_extraction: z.number(),
    text_processing_extraction: z.number(),
    max_text_processing_extraction: z.number(),
    processing_finished: z.boolean(),
    num_of_words: z.number(),
    token_cost: z.number(),
  })
  .strict();

export type EstimationProcessingPayload = z.infer<typeof estimationProcessingStatus>;
export type EstimationStatus = z.infer<typeof estimationStatus>;

export interface ParagraphSummaryUpdate {
  likeDislikeValue: LikeDislikeValue;
  highlighted: boolean;
  expanded: boolean;
}
