import { z } from "zod";

import { ResourceCardType } from "@/assets/constants/constants.ts";

const baseNoteParagraphSchema = z.object({
  id: z.string(),
  noteId: z.string(),
  modelLogId: z.string().nullish(),
  documentId: z.string().nullish(),
  docPageNumber: z.number(),
  position: z.number(),
  docPageTextOrigin: z.string().nullish(),
  dateCreated: z.string(),
  hasAd: z.boolean().nullish(),
});

const markdownParagraph = baseNoteParagraphSchema.merge(
  z.object({
    noteParagraphType: z.literal("TEXT"),
    markdownText: z.string(),
  }),
);

const imageNoteParagraphSchema = baseNoteParagraphSchema.merge(
  z.object({
    noteParagraphType: z.literal("IMAGE"),
    imageCuid: z.string(),
  }),
);

export const noteParagraphSchema = z.discriminatedUnion("noteParagraphType", [
  markdownParagraph,
  imageNoteParagraphSchema,
]);

export const subscriptionLimitsResponseSchema = z
  .object({
    maxCredits: z.number(),
    uploadedFilesPerMonthCounterLimit: z.number().optional(),
    summarizationPerMonthCounterLimit: z.number().optional(),
    lookupPerMonthCounterLimit: z.number().optional(),
    questionFenerationPerMonthCounterLimit: z.number().optional(),
    bulletPointPerMonthCounterLimit: z.number().optional(),
    qaPerMonthCounterLimit: z.number().optional(),
    documentSummarizationPerMonthLimit: z.number().optional(),
  })
  .strict();

export type BaseNoteParagraph = z.infer<typeof baseNoteParagraphSchema>;
export type MarkdownNoteParagraph = z.infer<typeof markdownParagraph>;
export type ImageNoteParagraph = z.infer<typeof imageNoteParagraphSchema>;
export type NoteParagraph = z.infer<typeof noteParagraphSchema>;

export type SubscriptionLimitsResponse = z.infer<typeof subscriptionLimitsResponseSchema>;

export const baseNoteEntitySchema = z
  .object({
    id: z.string(),
    name: z.string(),
    userId: z.string(),
  })
  .strict();

export type BaseNoteEntity = z.infer<typeof noteEntitySchema>;
export const extendedBaseNoteEntitySchema = baseNoteEntitySchema
  .extend({
    projectId: z.string(),
    dateCreated: z.string(),
    dateLastOpen: z.string().optional(),
    elementType: z.literal(ResourceCardType.NOTE_ELEMENT).optional(),
    favourite: z.boolean(),
  })
  .strict();

export type ExtendedBaseNoteEntity = z.infer<typeof extendedBaseNoteEntitySchema>;
export const noteEntitySchema = extendedBaseNoteEntitySchema
  .extend({
    noteParagraphs: z.array(noteParagraphSchema),
  })
  .strict();

export type NoteEntity = z.infer<typeof noteEntitySchema>;

const createNoteParagraphBaseSchema = z
  .object({
    position: z.number(),
    documentId: z.string().optional(),
    docPageNumber: z.number().optional(),
    docPageTextOrigin: z.string().optional(),
  })
  .strict();

const createMarkdownNoteParagraphSchema = createNoteParagraphBaseSchema
  .extend({
    markdownText: z.string(),
  })
  .strict();
export type CreateMarkdownNoteParagraph = z.infer<typeof createMarkdownNoteParagraphSchema>;

const createImageNoteParagraphSchema = createNoteParagraphBaseSchema
  .extend({
    imageToUpload: z.instanceof(File),
  })
  .strict();

export type CreateImageNoteParagraph = z.infer<typeof createImageNoteParagraphSchema>;

export const updateNoteParagraphSchema = z
  .object({
    markdownText: z.string().optional(),
    imageUrl: z.string().optional(),
  })
  .strict();

export type UpgradeNoteParagraph = z.infer<typeof updateNoteParagraphSchema>;
