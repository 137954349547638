import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Psychology = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <path
      d="M13.1436 47.1536C9.9965 46.6359 7.56627 43.3825 7.96047 40.2176C8.04503 39.5373 8.25969 38.8426 8.73715 38.3509C9.21461 37.8592 10.0056 37.6263 10.6132 37.9437C11.2155 38.2585 11.5681 39.0377 12.2433 39.1145C13.085 39.2094 13.5521 38.1844 13.7511 37.361C14.3912 34.719 14.9988 31.7908 13.7121 29.3973C13.0434 28.1537 11.879 27.0909 11.7749 25.6821C11.6487 23.9767 13.2281 22.5393 14.896 22.1555C16.5638 21.7718 18.2967 22.1529 19.9854 22.4222C21.6754 22.6915 23.4993 22.8281 25.0085 22.0215C26.3212 21.3204 27.2917 19.9532 28.7488 19.6462C30.336 19.3119 31.8464 20.3526 33.4102 20.7844C37.6826 21.9682 42.1359 18.5522 46.4837 19.4134C46.9638 19.5083 47.4647 19.6761 47.7626 20.0651C48.2661 20.7259 47.9239 21.7471 47.2643 22.2518C46.6047 22.7565 45.7396 22.8814 44.9147 22.9894C42.0227 23.3679 39.1319 23.7464 36.2398 24.1237C34.4991 24.3513 32.4943 24.7559 31.6903 26.3169C31.1257 27.4122 31.3599 28.7338 31.6578 29.9293C32.1157 31.7713 32.6947 33.6042 32.7701 35.5008C32.8456 37.3974 32.3356 39.4202 30.9331 40.6989C30.2566 41.3155 29.3485 41.8163 29.1352 42.7061C28.9205 43.605 29.5255 44.5181 29.4422 45.4378C29.3199 46.7855 27.8459 47.5504 26.5306 47.8704C24.2292 48.4298 21.786 48.3972 19.5001 47.7767C17.4004 47.207 15.1445 46.1572 13.167 47.0665"
      fill="#E2D7F4"
    />
    <path
      d="M9.8002 32.0598C8.88691 30.2503 8.35091 28.2184 8.49532 26.1969C8.63972 24.1754 9.50357 22.1721 11.0361 20.8453C12.462 19.6108 14.3237 19.041 16.0839 18.3672C17.8454 17.6947 19.6525 16.8075 20.653 15.2088C21.6534 13.6101 21.4401 11.1307 19.7748 10.2474C18.1382 9.37975 16.1034 10.3918 14.2899 10.0094C13.1307 9.7648 12.1745 8.97389 11.1103 8.45225C10.0461 7.93062 8.62932 7.73289 7.78238 8.56152C6.91202 9.41227 7.11498 10.8692 7.60415 11.9827C8.09461 13.0962 8.82056 14.1733 8.81926 15.3909C8.81666 17.1041 7.4116 18.427 6.32788 19.7539C4.84477 21.5686 3.8196 23.7566 3.37596 26.059C3.06503 27.6695 3.09105 29.4984 4.15265 30.7498C5.35475 32.1664 7.42981 32.3642 9.28501 32.4591"
      fill="#E2D7F4"
    />
    <path
      d="M29.4887 16.5815C30.3707 15.8843 31.6639 16.0299 32.6956 16.4761C33.7273 16.9223 34.6431 17.6209 35.7073 17.9851C37.1384 18.4755 38.7842 18.2934 40.0734 17.5038C41.3627 16.7142 42.2721 15.3301 42.4854 13.8328C43.5418 14.0709 44.6321 14.3102 45.6976 14.1151C46.7631 13.92 47.8038 13.172 47.9951 12.1066C48.1707 11.1284 47.597 10.1528 46.8502 9.49716C45.1004 7.95958 42.2773 7.81909 40.3831 9.17455C39.6688 9.68578 39.0951 10.3687 38.4173 10.9255C37.7382 11.4822 36.886 11.9258 36.0144 11.8139C35.1375 11.7021 34.4077 11.0543 33.5373 10.8943C31.8864 10.5899 30.4969 12.065 29.4678 13.3919C29.0997 13.8667 28.7185 14.3727 28.6469 14.9672C28.5754 15.5616 28.9488 16.2563 29.5472 16.3005"
      fill="#E2D7F4"
    />
    <path
      d="M48.7075 20.3018C49.7378 20.7467 50.0124 22.175 49.57 23.2065C49.1277 24.2381 48.1871 24.9562 47.2842 25.6235C46.0275 26.5523 44.7486 27.4889 43.7716 28.7091C42.7945 29.9292 42.1388 31.4902 42.334 33.0408C42.5226 34.5342 43.471 35.8805 43.4958 37.3856C43.5231 39.0208 42.3808 40.5388 40.8977 41.2309C39.4146 41.9229 37.6674 41.8774 36.1036 41.3974C35.3321 41.1606 34.4943 40.825 33.7514 41.1398C32.8837 41.508 32.5883 42.5694 32.4257 43.4969C32.2241 44.6547 32.068 45.8696 32.4361 46.9858C32.8043 48.1019 33.8464 49.0801 35.0186 48.9943C36.2896 48.9006 37.1366 47.7025 38.1019 46.87C40.0195 45.214 42.9884 44.9096 45.2026 46.1428C45.8961 46.5292 46.5153 47.0469 47.2426 47.3643C47.9698 47.6817 48.8779 47.7585 49.5102 47.2785C50.3753 46.6215 50.3168 45.3116 50.0592 44.2553C49.8016 43.199 49.4425 42.0348 49.9577 41.0774C51.0063 39.13 54.5346 39.917 55.5766 37.9671C56.1634 36.8679 55.5415 35.45 54.5593 34.6838C53.577 33.9176 52.3242 33.6158 51.1442 33.2151C49.9642 32.8145 48.7478 32.2304 48.1337 31.1468C47.5119 30.0476 47.6628 28.6297 48.269 27.5214C48.8753 26.4131 49.8758 25.5727 50.9321 24.8794C52.0627 24.1379 53.4144 23.3496 53.607 22.0111C53.7982 20.6803 52.6781 19.4913 51.459 18.9268C50.8684 18.6536 50.1828 18.4715 49.57 18.6887C48.9573 18.906 48.5097 19.6592 48.8129 20.2367"
      fill="#E2D7F4"
    />
    <path
      d="M47.1089 30.1136C47.1089 30.1136 52.0519 24.6624 45.9869 19.8909C45.9869 19.8909 44.2446 12.8162 37.2821 14.8357C37.2821 14.8357 35.0911 11.2191 29.5342 13.2056C28.7489 13.4893 22.8489 9.04784 18.3546 15.621C18.3546 15.621 11.2799 15.3966 10.1513 23.2567C6.99674 25.5467 5.42604 29.3151 6.19159 32.7007C7.20792 37.162 12.1642 40.4419 17.5626 39.4388C17.5626 39.4388 20.242 44.89 27.8909 40.112C27.8909 40.112 29.3626 43.5371 37.3943 40.2241C37.3943 40.2241 40.5423 45.1672 42.2252 44.9956C42.542 44.9626 42.7993 44.8702 42.9973 44.7119C44.1061 43.8539 42.6673 41.775 43.3471 39.7754C44.0137 37.8219 45.9473 38.0727 47.1022 36.1456C47.7226 35.1161 48.2044 33.3342 47.1022 30.1136H47.1089Z"
      fill="#E67D97"
      stroke="#A92C53"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M9.2959 23.9562C9.2959 23.9562 11.4606 21.7058 17.7763 22.1677"
      stroke="#A92C53"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M34.3735 31.4344C34.3735 31.0582 33.7861 25.0724 40.1877 25.4552"
      stroke="#A92C53"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M10.2363 31.4342C10.3485 31.1505 11.6288 28.1609 14.5392 27.6131C15.78 27.3821 16.8227 27.6857 17.1857 27.8111C19.0137 28.4248 19.9245 29.8371 20.1753 30.2529C22.0363 29.3158 24.0096 29.461 25.0523 30.5367C25.2767 30.7677 25.6661 31.2561 25.8179 32.1074"
      stroke="#A92C53"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M40.7104 29.8239C40.882 30.0086 43.1985 31.9291 47.2308 30.4904"
      stroke="#A92C53"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M29.5857 34.6083C29.5857 34.6083 30.2391 37.8091 27.8896 40.1123"
      stroke="#A92C53"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M24.3604 19.0533C24.3604 19.0533 27.4489 16.1891 30.3725 18.7168C30.3725 18.7168 35.1242 15.4698 37.2757 20.459"
      stroke="#A92C53"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);
export default Psychology;
