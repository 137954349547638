import { useState } from "react";

import { type Core } from "@pdftron/pdfjs-express-viewer";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";

import { Button } from "@/components/ui/button.tsx";
import { Command, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";

const ChapterSelectorPopover = ({ documentId }: { documentId: string }) => {
  const [state, setState] = useState<{ open: boolean; chapters: Core.Bookmark[]; selectedName: string }>({
    open: false,
    chapters: [],
    selectedName: "",
  });

  useCustomEventListener<{ pageNumber: number }>(
    `toggle-search-chapter-panel-${documentId}`,
    () => {
      setState((prev) => ({ ...prev, open: !prev.open }));
    },
    [documentId],
  );

  useCustomEventListener<{ bookmarks: Core.Bookmark[] }>(
    `set-bookmarks-${documentId}`,
    ({ bookmarks }) => {
      setState((prev) => ({ ...prev, chapters: bookmarks }));
    },
    [documentId],
  );

  return (
    <Popover open={state.open} onOpenChange={(open) => setState((prev) => ({ ...prev, open }))}>
      <PopoverTrigger asChild>
        <Button className="invisible absolute bottom-0 right-0" variant="outline">
          Open popover
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" alignOffset={8} className="w-80" sideOffset={16}>
        <Command>
          <CommandInput className="text-primary dark:text-white" placeholder="Search chapter..." />
          <CommandGroup className="max-h-40 overflow-y-auto">
            {state.chapters.map((chapter) => {
              const children = chapter.getChildren();
              return (
                <section key={`${chapter.getName()}-${chapter.getPageNumber()}`}>
                  <CommandItem
                    className="py-2 text-primary aria-selected:text-primary dark:text-white"
                    data-selected={state.selectedName === chapter.getName()}
                    key={`${chapter.getName()}-${chapter.getPageNumber()}-${chapter.getIndex()}`}
                    onSelect={() => {
                      setState((prev) => ({ ...prev, selectedName: chapter.getName() }));
                      emitCustomEvent(`got-to-page-${documentId}`, { pageNumber: chapter.getPageNumber() });
                    }}
                  >
                    {chapter.getName()}
                  </CommandItem>
                  {children.map((item, index) => (
                    <CommandItem
                      className="py-2 pl-8 text-primary aria-selected:text-primary dark:text-white"
                      data-selected={state.selectedName === item.getName()}
                      key={`${item.getName()}-${item.getPageNumber()}-${item.getIndex()}`}
                      onSelect={() => {
                        setState((prev) => ({ ...prev, selectedName: item.getName() }));
                        emitCustomEvent(`got-to-page-${documentId}`, { pageNumber: item.getPageNumber() });
                      }}
                    >
                      {index + 1}. {item.getName()}
                    </CommandItem>
                  ))}
                </section>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default ChapterSelectorPopover;
