import { useCallback, useState } from "react";

import { OnboardContext, OnboardStepper, OnboardingCarousel } from "@/components/v3";
import { useAuthUserData } from "@/firebase/hooks.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { type OnboardingChoices } from "@/types/app.ts";

const Onboarding = () => {
  const [done, setDone] = useState(false);
  const { data } = useAuthUserData({
    options: {
      staleTime: 1000,
    },
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [choices, setChoices] = useState<OnboardingChoices>({
    occupation: "",
    fieldOfOccupation: "",
    howDoYouKnowUs: {
      value: "",
      choice: "",
    },
    country: "",
  });
  const onSkip = useCallback(() => {
    switch (currentStep) {
      case 0:
        mixpanelTrack("onboarding_occupation", {
          occupation: "",
          skipped: true,
        });
        break;
      case 1:
        mixpanelTrack("onboarding_field_of_study", {
          fieldOfOccupation: "",
          skipped: true,
        });
        break;
      case 2:
        mixpanelTrack("onboarding_wdyhfu", {
          howDoYouKnowUs: {
            choice: "",
            value: "",
          },
          skipped: true,
        });
        break;
      case 3:
        mixpanelTrack("onboarding_country", {
          country: "",
          skipped: true,
        });
        break;
    }
    if (currentStep >= 3) {
      setDone(true);
      return;
    }
    setCurrentStep(currentStep + 1);
  }, [currentStep]);
  if (!data) return null;

  return (
    <OnboardContext.Provider value={{ onSkip, currentStep, setCurrentStep, choices, setChoices, setDone, done }}>
      <OnboardStepper>
        <OnboardingCarousel />
      </OnboardStepper>
    </OnboardContext.Provider>
  );
};

export default Onboarding;
