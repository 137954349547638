import { useCurrentWorkspace } from "@/api/workspace.ts";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { QuinoPopoverTitle, QuinoShareLink, ShareArrow } from "@/components/v3";
import { DemoHandlerButton } from "@/components/v3";

const ShareWorkspacePopover = () => {
  const { data: currentWorkspace } = useCurrentWorkspace();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <DemoHandlerButton size="sm" variant="secondary">
          <ShareArrow />
          Share workspace
        </DemoHandlerButton>
      </PopoverTrigger>
      <PopoverContent className="w-[26rem]">
        <section className="flex flex-col gap-1">
          <QuinoPopoverTitle title={`Share ${currentWorkspace?.name}`} />
          <QuinoShareLink workspaceId={currentWorkspace?.id ?? ""} />
        </section>
      </PopoverContent>
    </Popover>
  );
};
export default ShareWorkspacePopover;
