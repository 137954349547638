import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import { demoEditProject, fetchProjectDataDemo, fetchProjectDemoProcessingStatus } from "@/api/demo";
import { deleteRequest, getRequest, postRequest, putRequest } from "@/api/utils";
import { BACKEND_URL } from "@/assets/constants/constants";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useAppAuthState, useFirebaseUserId } from "@/firebase/hooks.ts";
import { type GenericFetchError } from "@/service/errors";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { type QueryConfig } from "@/types/react-query";
import {
  type BaseProjectSchema,
  baseProjectSchemaArray,
  type CreateProject,
  type CreateProjectWithDeadlinesSchema,
  type ProjectEntity,
  projectEntitySchema,
  type ProjectProcessingStatus,
  projectProcessingStatusSchema,
  type ResponseProjectEntity,
  type ShareProjectRequest,
  type UpdateProjectSchema,
} from "@/types/schemas";
import { type WorkspaceEntity, type WorkspaceListItem } from "@/types/schemas/api/workspaceEntity.ts";
import { validateZodSchema } from "@/utils";

const PROJECTS = "/v3/projects";

export const getSharedProjects = async (): Promise<BaseProjectSchema[]> =>
  validateZodSchema(getRequest(`${BACKEND_URL}${PROJECTS}/my-shared-projects`), baseProjectSchemaArray);

const fetchProjectProcessingStatus = async ({ projectId }: { projectId: string }) =>
  validateZodSchema(
    getRequest<ProjectProcessingStatus>(`${BACKEND_URL}${PROJECTS}/doc-processing-status/${projectId}`),
    projectProcessingStatusSchema,
  );

export const fetchProjectData = async (workspaceId: string, projectId: string, variant?: string) => {
  let url = `${BACKEND_URL}${PROJECTS}/${workspaceId}/${projectId}`;
  if (variant) url = url + `?variant=${variant}`;
  return validateZodSchema(getRequest<ProjectEntity>(url), projectEntitySchema);
};

const postProject = async (payload: CreateProject) =>
  validateZodSchema(
    postRequest<ProjectEntity>(`${BACKEND_URL}${PROJECTS}/${payload.workspaceId}/`, _.omit(payload, "workspaceId")),
    projectEntitySchema,
  );

const updateProject = async ({
  projectId,
  workspaceId,
  payload,
}: {
  projectId: string;
  workspaceId: string;
  payload: UpdateProjectSchema;
}) =>
  validateZodSchema(
    putRequest<ProjectEntity>(`${BACKEND_URL}${PROJECTS}/${workspaceId}/${projectId}`, payload),
    projectEntitySchema,
  );

const postProjectWithDeadlines = async (payload: CreateProjectWithDeadlinesSchema) =>
  validateZodSchema(postRequest<ProjectEntity>(`${BACKEND_URL}/cts`, payload), projectEntitySchema);

const shareProject = async ({ projectId, email }: ShareProjectRequest) =>
  postRequest(`${BACKEND_URL}${PROJECTS}/share/send-share-email`, {
    projectId,
    email,
  });

const cloneProject = async ({ projectId }: { projectId: string }) => {
  return await validateZodSchema(
    putRequest<ProjectEntity>(`${BACKEND_URL}${PROJECTS}/clone/${projectId}`, {}),
    projectEntitySchema,
  );
};

export const useSendShareRequest = () => {
  return useMutation({
    mutationKey: ["share-project"],
    mutationFn: shareProject,
    onSuccess: () => {
      toast({
        title: "Invitation sent.",
        variant: "success",
      });
    },
  });
};
export const useCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postProject,
    onSuccess: (result, variables) => {
      queryClient.setQueryData<WorkspaceListItem[]>([result.userId, QUERY_KEYS.WORKSPACES], (data) => {
        if (!data) return data;
        const newData = [...data];

        const idx = newData.findIndex((item) => item.id === variables.workspaceId);
        if (idx !== -1) {
          newData[idx] = { ...newData[idx], numOfProjects: newData[idx].numOfProjects + 1 };
        }
        return [...newData];
      });
      queryClient.setQueryData<WorkspaceEntity>([result.userId, QUERY_KEYS.WORKSPACES, result.workspaceId], (data) => {
        if (!data) return data;
        return { ...data, projects: [...data.projects, result] };
      });
    },
  });
};

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  const mixpanelTrack = useMixpanelTrack();
  const { matched: isDemoPage } = useIsDemoLikePage();
  const firebaseUserId = useFirebaseUserId();

  return useMutation({
    mutationFn: isDemoPage ? demoEditProject : updateProject,
    mutationKey: ["edit-project"],
    onSuccess: (result, variables) => {
      if (isDemoPage)
        mixpanelTrack("project_updated", {
          project_id: variables.projectId,
          name: variables.payload.name,
          color_tag: variables.payload.colorTag,
        });
      queryClient.setQueryData<WorkspaceEntity>(
        [firebaseUserId, QUERY_KEYS.WORKSPACES, variables.workspaceId],
        (data) => {
          if (!data) return data;
          const newData = { ...data };

          newData.projects = [...newData.projects];

          const idx = newData.projects.findIndex((item) => item.id === result.id);
          if (idx !== -1) {
            newData.projects[idx] = { ...newData.projects[idx], ...result };
          }

          return { ...newData };
        },
      );

      queryClient.setQueryData<ProjectEntity>([QUERY_KEYS.PROJECTS, result.id], (prev) =>
        prev ? { ...prev, ...result } : prev,
      );
    },
    onError: (e: GenericFetchError) => {
      if (e.payload.status === 403) {
        return toast({
          title: "You are not the owner of this project!",
          variant: "destructive",
        });
      }
      return toast({
        title: "Something went wrong.",
        variant: "destructive",
      });
    },
  });
};

export const useCreateProjectWithDeadlines = () => {
  const queryClient = useQueryClient();
  const [user] = useAppAuthState();
  return useMutation({
    mutationFn: postProjectWithDeadlines,
    onSuccess: (data) => {
      queryClient.setQueryData<BaseProjectSchema[]>([QUERY_KEYS.MY_PROJECTS, { user: user?.uid }], (prev) =>
        prev ? [...prev, data] : undefined,
      );
    },
  });
};

export const useCloneProject = ({ projectId }: { projectId: string }) => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();

  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["clone-project"],
    mutationFn: () => cloneProject({ projectId }),
    onSuccess: async (project) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MY_PROJECTS, { user: firebaseUserId }] });
      navigate(`/library/all/${project.id}`);
      toast({
        title: "Project copied successfully",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Failed to copy the project",
        variant: "destructive",
      });
    },
  });
};

export const useProjectData = (workspaceId: string, projectId: string, options?: QueryConfig<ProjectEntity>) => {
  const { matched: isDemo } = useIsDemoLikePage();
  return useQuery({
    queryFn: () => (isDemo ? fetchProjectDataDemo(projectId) : fetchProjectData(workspaceId, projectId)),
    queryKey: [QUERY_KEYS.PROJECTS, workspaceId, projectId],
    ...options,
    enabled: !!projectId && !!workspaceId,
    staleTime: 5000,
  });
};

const deleteProject = async ({ workspaceId, projectId }: { workspaceId: string; projectId: string }) => {
  return deleteRequest<ResponseProjectEntity>(`${BACKEND_URL}${PROJECTS}/${workspaceId}/${projectId}`);
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();
  return useMutation({
    mutationFn: async ({ workspaceId, projectId }: { workspaceId: string; projectId: string }) => {
      await deleteProject({ workspaceId, projectId });
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData<WorkspaceListItem[]>([firebaseUserId, QUERY_KEYS.WORKSPACES], (data) => {
        if (!data) return data;
        const newData = [...data];

        const idx = newData.findIndex((item) => item.id === variables.workspaceId);
        if (idx !== -1) {
          newData[idx] = { ...newData[idx], numOfProjects: newData[idx].numOfProjects - 1 };
        }
        return [...newData];
      });
      queryClient.setQueryData<WorkspaceEntity>(
        [firebaseUserId, QUERY_KEYS.WORKSPACES, variables.workspaceId],
        (data) => {
          if (!data) return data;
          const newData = { ...data };
          newData.projects = [...newData.projects.filter((item) => item.id !== variables.projectId)];
          return { ...newData };
        },
      );
      void queryClient.removeQueries({ queryKey: [QUERY_KEYS.PROJECTS, variables.workspaceId, variables.projectId] });
    },
    onError: (e: GenericFetchError) => {
      if (e.payload.status === 403) {
        return toast({
          title: "You are not the owner of this project!",
          variant: "destructive",
        });
      }
      return toast({
        title: "Something went wrong.",
        variant: "destructive",
      });
    },
  });
};

export const useProjectProcessingStatus = ({ projectId }: { projectId: string }) => {
  const { matched: isDemo } = useIsDemoLikePage();
  return useQuery({
    enabled: !!projectId,
    refetchOnMount: "always",
    queryFn: () =>
      isDemo
        ? fetchProjectDemoProcessingStatus({ projectId: projectId })
        : fetchProjectProcessingStatus({ projectId: projectId }),
    queryKey: [QUERY_KEYS.PROJECTS, projectId, { variant: "status" }],
  });
};
