import { useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { useScript } from "usehooks-ts";

import { type ProjectColorType } from "@/assets/constants/projects.ts";
import { useThemeMode } from "@/service/hooks/misc.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { getOutlineColor } from "@/utils/index.ts";

export const useDetectAdBlock = () => {
  const status = useScript(
    `https://fdyn.pubwise.io/script/c4742108-b0aa-493a-aec3-f9c47aec9495/v3/dyn/pre_pws.js?type=default`,
    {
      removeOnUnmount: false,
    },
  );

  return useMemo(() => status === "error", [status]);
};

export const useOutlineColor = (colorTag?: string | null): ProjectColorType =>
  useMemo(() => getOutlineColor(colorTag), [colorTag]);

export const useThemeModeImage = (lightImage?: string, darkImage?: string) => {
  const [dark] = useThemeMode();
  return useMemo(() => {
    if (lightImage && darkImage) return dark === "dark" ? darkImage : lightImage;
    else if (lightImage && !darkImage) {
      return lightImage;
    } else if (!lightImage && darkImage) {
      return darkImage;
    }
    return "";
  }, [dark, lightImage, darkImage]);
};

export const useForcedMixpanelRedirectTrack = () => {
  const navigate = useNavigate();

  return useCallback(
    (link: string, event: string, payload?: object) => {
      try {
        mixpanelTrack(event, payload, false, () => {
          setTimeout(() => navigate(link), 1000);
        });
      } catch (e) {
        navigate(link);
      } finally {
        setTimeout(() => navigate(link), 2000);
      }
    },
    [navigate],
  );
};
