import { useMemo } from "react";

import { Navigate } from "react-router-dom";

import { useCurrentWorkspace } from "@/api/workspace.ts";
import { ROUTES, ResourceCardType } from "@/assets/constants/constants.ts";
import { DocumentTile, EmptyProjectExplorerView, ProjectExplorerLoading } from "@/components/v3";
import { useLibrarySorting, useLibraryView } from "@/service/library.ts";
import { useCurrentProjectData } from "@/utils";

const WorkspaceProjectsView = () => {
  const { data: workspace, isLoading: isWorkspaceLoading } = useCurrentWorkspace();

  const { data: currentProject, isLoading, isError } = useCurrentProjectData();
  const [view] = useLibraryView();
  const [librarySorting] = useLibrarySorting();

  return useMemo(() => {
    if (isLoading || isWorkspaceLoading)
      return (
        <section className="relative flex h-full flex-1 flex-col">
          <section className="absolute inset-0 h-full w-full">
            <ProjectExplorerLoading />
          </section>
        </section>
      );
    if (!workspace) return <Navigate to={ROUTES.WORKSPACES} />;
    if (isError) return <section className="flex h-full w-full flex-col justify-center">Error...</section>;

    const documents =
      currentProject?.directChildElements.filter((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT) ??
      [];

    if (documents.length === 0) return <EmptyProjectExplorerView />;

    return (
      <section className="h-full overflow-y-auto">
        <section
          className="grid grid-cols-[repeat(auto-fill,_minmax(min(190px,_100%),_1fr))] flex-col gap-x-3 gap-y-6 data-[view=row]:flex data-[view=row]:gap-y-2"
          data-view={view}
        >
          {documents
            .sort((a, b) => {
              if (librarySorting === "ASC") return a.name.localeCompare(b.name);
              return b.name.localeCompare(a.name);
            })
            .map((item) => (
              <DocumentTile element={item} key={item.id} />
            ))}
        </section>
      </section>
    );
  }, [isLoading, isWorkspaceLoading, workspace, isError, currentProject?.directChildElements, view, librarySorting]);
};

export default WorkspaceProjectsView;
