import { useCallback, useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useMakeSummarization } from "@/api/document-summary.ts";
import { ROUTES } from "@/assets/constants/constants.ts";
import { SummaryDefaultIcon, SummaryDefaultIconBlackAndWhite } from "@/components/icons/summary";
import { Button } from "@/components/ui/button.tsx";
import { useDocumentProcessing } from "@/service/hooks/documents.ts";
import { useCurrentDocumentTabId } from "@/service/hooks/react-router.ts";
import { useUpgradePlanModal } from "@/service/library.ts";
import { useMixpanelTrack } from "@/service/mixpanel";

const View = ({
  title,
  freeChoices,
  description,
  disabled,
  onClick,
  ctaText,
  grayscale = false,
}: {
  title?: string;
  ctaText?: string;
  freeChoices?: number;
  description?: string;
  disabled?: boolean;
  onClick?: () => void;
  grayscale?: boolean;
}) => {
  return (
    <section className="h-full">
      <div className="group flex flex-1 flex-col items-center gap-2">
        <div className="group mb-6 py-5" data-grayscale={grayscale}>
          <SummaryDefaultIcon className="mx-auto group-data-[grayscale=true]:hidden" />
          <SummaryDefaultIconBlackAndWhite className="mx-auto group-data-[grayscale=false]:hidden" />
        </div>
        <h4 className="text-center text-h5 font-bold text-primary-onBg">{title}</h4>
        <p className="mb-4 w-2/3 text-center text-base font-normal text-secondary-onBg">{description}</p>
        <Button
          className="rounded-lg bg-default px-5 py-4 text-label-l3 text-white"
          disabled={disabled}
          onClick={onClick}
        >
          {ctaText}
        </Button>
        {freeChoices && freeChoices > 0 && (
          <section className="text-xs text-secondary-onBg">
            You have <b>{freeChoices}</b> free summaries left.
          </section>
        )}
      </div>
    </section>
  );
};

const PreSummarizationView = ({
  mode,
}: {
  mode: "OUT_OF_SUMMARY" | "RE_SUMMARIZE_FOR_FREE" | "OUT_OF_TOKENS" | "EMPTY_STATE_FREE" | "EMPTY_STATE";
}) => {
  const { processing } = useDocumentProcessing();
  const [, setModal] = useUpgradePlanModal();
  const documentId = useCurrentDocumentTabId();
  const queryClient = useQueryClient();
  const { mutate: makeSummary, isPending } = useMakeSummarization(documentId, queryClient);
  const mixpanelTrack = useMixpanelTrack();

  const emptyStateCta = useMemo(() => (processing ? "Please wait..." : "Summarize document"), [processing]);
  const resummarizationCta = useMemo(() => (processing ? "Please wait..." : "Re-summarize for free"), [processing]);
  const navigate = useNavigate();

  const executeSummary = useCallback(() => {
    makeSummary();
    mixpanelTrack("document_summary_button_clicked");
  }, [makeSummary, mixpanelTrack]);

  if (mode === "OUT_OF_TOKENS")
    return (
      <View
        grayscale
        ctaText="Subscribe to Quino"
        description="Oops, you’re out of tokens. Get extra tokens to summarize more documents."
        title="Not enough tokens"
        onClick={() => navigate(ROUTES.SUBSCRIPTION)}
      />
    );
  if (mode === "OUT_OF_SUMMARY")
    return (
      <>
        <View
          grayscale
          ctaText="Subscribe to Quino"
          description="Oops, you’re out of free summaries. Get a subscription to summarize more documents."
          title="Summary limit reached"
          onClick={() => setModal({ open: true })}
        />
      </>
    );
  if (mode === "EMPTY_STATE")
    return (
      <View
        ctaText={emptyStateCta}
        description="Get a shorter version of this document in a few seconds with the help of AI."
        disabled={isPending || processing}
        title="Save time by summarizing this document"
        onClick={executeSummary}
      />
    );
  if (mode === "EMPTY_STATE_FREE")
    return (
      <View
        ctaText={emptyStateCta}
        description="Get a shorter version of this document in a few seconds with the help of AI."
        disabled={isPending || processing}
        title="Save time by summarizing this document"
        onClick={executeSummary}
      />
    );

  return (
    <View
      ctaText={resummarizationCta || processing}
      description="Get a shorter version of this document in a few seconds with the help of AI."
      disabled={isPending}
      title="Save time by summarizing this document"
      onClick={executeSummary}
    />
  );
};

export default PreSummarizationView;
