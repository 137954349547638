import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const LibraryIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-text-default", props.className)}
    >
      <path
        d="M9.52 3a2 2 0 0 1 1.442.614l.12.137L12.48 5.5H19a2 2 0 0 1 1.995 1.85L21 7.5v1.53a2.001 2.001 0 0 1 1.61 2.398l-.038.147-2.4 8a2 2 0 0 1-1.752 1.418l-.164.007H4a2 2 0 0 1-1.995-1.85L2 19V5a2 2 0 0 1 1.85-1.995L4 3h5.52Zm11.136 8H6.744l-2.4 8h13.912l2.4-8ZM9.519 5H4v8.187l.828-2.762A2 2 0 0 1 6.744 9H19V7.5h-6.52a2 2 0 0 1-1.561-.75L9.519 5Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default LibraryIcon;
