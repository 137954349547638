import { useProjectData } from "@/api/project.ts";
import { ResourceCardType } from "@/assets/constants/constants.ts";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { ResourceButton } from "@/components/v3";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";

const ProjectButtonList = ({ projectId }: { projectId: string }) => {
  const workspaceId = useCurrentWorkspaceId();
  const { data: projectData, isLoading } = useProjectData(workspaceId, projectId);
  if (isLoading)
    return (
      <>
        <Skeleton className="h-10 w-full" />
      </>
    );
  return (
    <>
      {projectData?.directChildElements?.map((resource) => {
        if (resource.elementType !== ResourceCardType.DOCUMENT_ELEMENT) return null;
        return <ResourceButton key={resource.id} resource={resource} />;
      })}
    </>
  );
};

export default ProjectButtonList;
