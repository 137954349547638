import { type MouseEventHandler, useMemo } from "react";

import { DownloadIcon } from "@/components/icons/library";
import { Button } from "@/components/ui/button.tsx";
import { type ExportButtonType } from "@/types/app.ts";

const DownloadButton = ({
  onClick,
  disabled,
  variant,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  variant?: ExportButtonType;
}) =>
  useMemo(() => {
    if (variant === "text")
      return (
        <Button
          className="flex flex-nowrap justify-start gap-1 px-0 text-primary"
          disabled={disabled}
          type="button"
          variant="ghost"
          onClick={onClick}
        >
          <span className="h-4 w-4">
            <DownloadIcon className="h-4 w-4 fill-primary dark:fill-white" />
          </span>
          <span>Export</span>
        </Button>
      );
    if (variant === "text-small")
      return (
        <Button
          className="flex w-max flex-nowrap justify-start gap-1 px-4"
          disabled={disabled}
          type="button"
          variant="ghost"
          onClick={onClick}
        >
          <span className="h-4 w-4">
            <DownloadIcon className="h-4 w-4" />
          </span>
          <span>Export</span>
        </Button>
      );

    return (
      <Button
        className="h-12 w-12 rounded-none p-4"
        disabled={disabled}
        size="icon"
        type="button"
        variant="icon"
        onClick={onClick}
      >
        <span className="h-4 w-4">
          <DownloadIcon className="h-4 w-4" />
        </span>
      </Button>
    );
  }, [disabled, onClick, variant]);

export default DownloadButton;
