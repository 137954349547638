// TODO: abstract out stepper component
import { type DivProps } from "react-html-props";

import { cn } from "@/utils";

const Dot = ({ active, className, ...props }: DivProps & { active?: boolean }) => {
  return (
    <div className="relative h-4 w-4 " {...props}>
      <div
        className={cn(
          "group absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full border-0 border-primary border-opacity-10 transition-all data-[active=true]:border-4 dark:border-white/25",
          className,
          "bg-transparent",
        )}
        data-active={active}
      >
        <div
          className={cn(
            "rounded-full border-0 border-primary border-opacity-50 transition-all group-hover:border-2 group-data-[active=true]:border-4 dark:border-white/50",
            className,
            "bg-transparent",
          )}
        >
          <div
            className={cn(
              "h-4 w-4 rounded-full bg-primary bg-opacity-25 transition-all group-hover:bg-opacity-75 group-data-[active=true]:h-6 group-data-[active=true]:w-6 group-data-[active=true]:bg-opacity-100 dark:bg-white",
              className,
            )}
          />
        </div>
      </div>
    </div>
  );
};

const Line = ({ active, className, ...props }: DivProps & { active?: boolean }) => {
  return (
    <div
      {...props}
      className={cn(
        "relative h-1 w-12 rounded-[100px] bg-primitive-grey-200 data-[active=true]:bg-primitive-purple-400",
        className,
      )}
      data-active={active}
    ></div>
  );
};

export { Dot, Line };
