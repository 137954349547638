import { useCallback, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateDocument } from "@/api/document.ts";
import { Button } from "@/components/ui/button.tsx";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { EditIcon, QuinoPopoverTitle } from "@/components/v3";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useDocumentSelectionState } from "@/service/library.ts";

const formSchema = z.object({
  name: z.string().min(1, { message: "Firstname is required!" }),
});

type FormSchema = z.infer<typeof formSchema>;

const RenameSelectedResourceButton = () => {
  const { selectedResources } = useDocumentSelectionState();
  const { mutate: updateDocument } = useUpdateDocument();
  const [isOpen, setIsOpen] = useState(false);
  const currentWorkspaceId = useCurrentWorkspaceId();
  const form = useForm<FormSchema>({
    defaultValues: {
      name: "",
    },
    resolver: zodResolver(formSchema),
  });

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSubmit = useCallback(
    (data: FormSchema) => {
      updateDocument({
        documentId: selectedResources[0].id,
        workspaceId: currentWorkspaceId,
        payload: {
          name: data.name,
        },
      });
      onClose();
    },
    [currentWorkspaceId, onClose, selectedResources, updateDocument],
  );

  useEffect(() => {
    if (selectedResources.length >= 1) {
      form.setValue("name", selectedResources[0].name);
    } else {
      form.setValue("name", "");
    }
  }, [form, form.setValue, isOpen, selectedResources.length, selectedResources]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button size="sm" variant="ghost">
          <EditIcon />
          Rename
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[25rem] space-y-1">
        <QuinoPopoverTitle title="Rename document" />
        <Form {...form}>
          <form className="flex flex-row items-center gap-2 p-2 pt-0" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input className="resize-none" classNameRoot="flex-1" placeholder="Name" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button size="sm">Save</Button>
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  );
};

export default RenameSelectedResourceButton;
