import { useCallback, useEffect, useMemo } from "react";

import { useInterviewModal, useRespondToInterviewModal } from "@/api/strapi";
import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button";
import {
  DialogFooter,
  DialogHeader,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@/components/ui/dialog";
import { LoadingSpinner } from "@/pages/loading";
import { getStrapiMediaUrl } from "@/utils";
import { useThemeModeImage } from "@/utils/hooks.ts";
import { QuinoMarkdown } from "@/utils/util-components.tsx";
import { useModalState } from "@/zustand/slices/modal-slice";

const InterviewModal = () => {
  const [{ data: hasToOpenData, isSuccess: hasToOpenIsSuccess }, { data: contentData, isLoading }] =
    useInterviewModal();
  const { mutate } = useRespondToInterviewModal();
  const [state, setState] = useModalState(ModalKeys.INTERVIEW_MODAL);

  useEffect(() => {
    if (hasToOpenIsSuccess && hasToOpenData && hasToOpenData.hasToAnswer) {
      setState(true);
    }
  }, [hasToOpenData, hasToOpenIsSuccess, setState]);

  const yesResponse = useCallback(() => {
    mutate({ accepted: true });
    setState(false);
  }, [mutate, setState]);

  const noResponse = useCallback(() => {
    mutate({ accepted: false });
    setState(false);
  }, [mutate, setState]);

  const image = useThemeModeImage(
    getStrapiMediaUrl(contentData?.data.attributes.cover?.data.attributes.url),
    getStrapiMediaUrl(contentData?.data.attributes.darkModeCover?.data.attributes.url),
  );

  const content = useMemo(() => {
    if (isLoading || !contentData)
      return (
        <DialogContent hideClose className="gap-6 p-10">
          <LoadingSpinner />
        </DialogContent>
      );

    return (
      <DialogContent hideClose className="gap-6 p-10">
        <DialogHeader className="gap-6">
          <section>
            <img alt="Voucher Image" className="max-h-full max-w-full" src={image} />
          </section>
          <DialogTitle className="text-2xl">{contentData.data.attributes.title}</DialogTitle>
          <DialogDescription asChild className="list-inside list-disc text-base">
            <QuinoMarkdown>{contentData.data.attributes.description ?? ""}</QuinoMarkdown>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button className="font-bold" type="button" variant="ghost" onClick={noResponse}>
            {contentData.data.attributes.cancelButton}
          </Button>
          <Button autoFocus type="button" onClick={yesResponse}>
            {contentData.data.attributes.acceptButton}
          </Button>
        </DialogFooter>
      </DialogContent>
    );
  }, [contentData, image, isLoading, noResponse, yesResponse]);

  return <Dialog open={state}>{content}</Dialog>;
};

export default InterviewModal;
