import { ChevronLeft } from "lucide-react";
import { Link } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants";
import { Button } from "@/components/ui/button";
import { useIsDemoLikePage, useIsTemporaryPage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectId } from "@/utils";

import { QuinoLogoPurple } from "../v3/icons/common";

const DocumentBackToLibraryButton = () => {
  const { route } = useIsDemoLikePage();
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  const { matched: isTemporary } = useIsTemporaryPage();
  if (isTemporary) return null;
  return (
    <Link to={`${route}${ROUTES.WORKSPACES}/${workspaceId}/${projectId}`}>
      <Button className="p-0 px-2 hover:bg-white/50" variant="ghost">
        <ChevronLeft className="h-4 w-4 bg-transparent stroke-icon-back" />
        <QuinoLogoPurple className="bg-transparent" />
      </Button>
    </Link>
  );
};

export default DocumentBackToLibraryButton;
