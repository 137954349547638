import { useDeleteFlashCard } from "@/api/flash-cards";
import { ModalKeys } from "@/assets/constants/modal";
import { YesNoModal } from "@/components/modals";
import { useModalState } from "@/zustand/slices/modal-slice";

const DeleteFlashcardModal = () => {
  const [modalState, setModalState] = useModalState(ModalKeys.DELETE_FLASHCARD_OPEN);
  const { mutate: deleteFLashCard } = useDeleteFlashCard();
  const handleOnDeleteSelectedProject = () => {
    deleteFLashCard({ cardId: modalState.cardId, deckId: modalState.deckId });
    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () =>
    setModalState({
      isOpen: false,
      cardId: "",
      deckId: "",
    });

  return (
    <YesNoModal
      acceptButtonText={"Delete Card"}
      description={"This is a permanent action. If you delete one of the cards you can't retrieve them later."}
      open={modalState.isOpen}
      title={"Are you sure you want to delete this card?"}
      onCancel={handleCloseDeleteModal}
      onYes={handleOnDeleteSelectedProject}
    />
  );
};

export default DeleteFlashcardModal;
