import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import { Button } from "@/components/ui/button.tsx";
import { ChatPlain } from "@/components/v3/icons/chat";
import { useChatDocuConfig } from "@/service/hooks/documents.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectId } from "@/utils";

const ChatWithProjectButton = () => {
  const navigate = useNavigate();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const currentProjectId = useCurrentProjectId();
  const [, setCurrentState] = useChatDocuConfig();
  const { matched: isDemo } = useIsDemoLikePage();

  const onOpenChatWithProject = useCallback(() => {
    setCurrentState({
      documentOnly: false,
    });
    if (isDemo) {
      navigate(`${ROUTES.DEMO}${ROUTES.DOCUMENTS}/${currentWorkspaceId}/${currentProjectId}?note-tab=chat`);
      return;
    }
    navigate(`${ROUTES.DOCUMENTS}/${currentWorkspaceId}/${currentProjectId}?note-tab=chat`);
  }, [currentProjectId, currentWorkspaceId, isDemo, navigate, setCurrentState]);

  return (
    <Button size="sm" variant="secondary" onClick={onOpenChatWithProject}>
      <ChatPlain className="h-4 w-4 fill-default" /> Chat with project
    </Button>
  );
};

export default ChatWithProjectButton;
