import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const HealthAndMedicine = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <path
      d="M5.64967 19.0699C13.414 15.1602 23.5519 13.2126 32.7509 7.2459C41.95 1.27786 56.9339 3.4984 57.8238 14.2491C58.7137 24.9997 56.2411 27.7622 50.2075 39.5981C44.174 51.434 37.597 57.0078 28.4972 52.3715C19.3973 47.7353 12.1777 39.5994 6.58848 35.1596C1.00056 30.7199 -2.11471 22.9796 5.64967 19.0699Z"
      fill="#E2D7F4"
    />
    <path
      d="M56.7825 35.7525C52.6512 28.8683 48.5199 21.9841 44.3847 15.1037C44.1152 14.5878 43.4183 13.425 42.0014 12.5856C40.3574 11.6115 38.7634 11.6847 38.2012 11.7348C37.4659 10.6721 36.7266 9.60942 35.9912 8.54675C35.1673 10.1138 32.8918 13.9101 28.2253 16.4359C24.6292 18.3803 21.2718 18.6767 19.5122 18.7037L21.4604 22.2844C21.1255 22.758 20.521 23.759 20.3785 25.1682C20.1821 27.1434 21.033 28.6065 21.3295 29.0685L33.7311 49.5633C34.3857 49.9945 35.6755 50.7222 37.4658 50.9378C38.9944 51.1226 40.238 50.857 40.9542 50.6414C43.3529 49.8136 46.5601 48.4121 49.8982 46.0057C52.451 44.1653 54.3838 42.2401 55.7968 40.6153C56.0933 40.265 56.6901 39.468 56.9249 38.2744C57.1482 37.1193 56.9211 36.1876 56.7825 35.7487V35.7525Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M22.9009 21.9567C24.9184 21.5832 28.1103 20.7362 31.4869 18.6147C34.4054 16.782 36.3767 14.6798 37.5818 13.1743"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M23.9972 12.9893C23.9549 13.0239 23.9125 13.0547 23.874 13.0855"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M19.3608 17.6982C19.9653 16.8781 20.8547 15.7884 22.0753 14.6295"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M35.6565 8.32605C33.6737 8.54166 30.2508 9.1731 26.5661 11.283C26.4468 11.3523 26.3313 11.4216 26.2158 11.4871H26.2119"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M24.8344 31.3773C26.9443 31.3349 31.4915 30.9615 36.285 28.1315C41.3057 25.1707 43.8623 21.1318 44.8864 19.2837L53.9306 34.4228C52.8256 36.3133 50.5116 39.6745 46.3611 42.3851C41.2172 45.7425 36.1618 46.2007 33.9248 46.27L24.8306 31.3773H24.8344Z"
      fill="#8A61D1"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M26.188 11.3377C26.1302 11.434 26.0686 11.5264 25.9993 11.6149C25.5026 12.2926 24.7903 12.7739 23.9933 12.9895H23.9895C23.8432 13.0318 23.6853 13.0626 23.5198 13.0857C23.4427 13.0973 23.3619 13.105 23.281 13.1088C23.154 13.1204 23.0231 13.1204 22.8883 13.1088C22.7767 13.1011 22.665 13.0896 22.561 13.0742C22.1106 13.0126 21.6832 12.874 21.2943 12.6661C21.1634 12.5968 21.0402 12.5198 20.9208 12.4351C20.8631 12.3927 20.8053 12.3503 20.7476 12.308C20.7476 12.308 20.7476 12.3041 20.7399 12.3003C20.6821 12.2541 20.6282 12.2079 20.5743 12.1578C20.5281 12.1193 20.4819 12.077 20.4396 12.0346C20.3395 11.9345 20.2471 11.8267 20.1547 11.7151C20.1085 11.6535 20.0584 11.588 20.0161 11.5225C19.966 11.4571 19.9236 11.3878 19.8813 11.3146C19.8389 11.2453 19.8004 11.1722 19.7619 11.099C19.7542 11.0875 19.7504 11.0759 19.7427 11.0682C20.1277 10.6716 21.0672 9.83229 22.4301 9.69754C24.1088 9.53198 25.9416 10.4753 26.188 11.3339V11.3377Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M26.2051 11.6644L26.2128 11.4912C26.1396 11.5335 26.0703 11.5759 26.0049 11.6144C26.0742 11.5258 26.1358 11.4334 26.1936 11.3372C26.2013 11.3718 26.2089 11.4026 26.2128 11.4373C26.2128 11.4527 26.2128 11.4681 26.2128 11.4873C26.2166 11.5066 26.2167 11.5258 26.2167 11.5451C26.2167 11.5874 26.2128 11.6259 26.2012 11.6644H26.2051Z"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M26.2093 11.4911L26.2016 11.6644L26.1207 13.5702C25.778 13.8513 24.7423 14.6445 23.2022 14.7176C22.798 14.7369 22.4206 14.7022 22.0741 14.6291C20.6842 14.3518 19.7794 13.5279 19.583 13.3392L19.7139 11.006C19.7255 11.0291 19.737 11.0483 19.7447 11.0714C19.7524 11.0791 19.7563 11.0907 19.764 11.1022C19.8025 11.1754 19.841 11.2486 19.8833 11.3179C19.9257 11.391 19.968 11.4603 20.0181 11.5258C20.0604 11.5912 20.1066 11.6567 20.1567 11.7183C20.2491 11.8299 20.3415 11.9377 20.4416 12.0378C20.4878 12.0802 20.5302 12.1225 20.5764 12.1611C20.6303 12.215 20.6842 12.2612 20.7419 12.3035C20.7458 12.3074 20.7458 12.3112 20.7496 12.3112C20.8074 12.3574 20.8651 12.3998 20.9229 12.4383C21.0422 12.5268 21.1654 12.6038 21.2964 12.6693C21.7776 12.935 22.3205 13.089 22.8904 13.1121C23.0251 13.1198 23.156 13.1198 23.2831 13.1121C23.3639 13.1082 23.4448 13.1005 23.5218 13.089C23.6873 13.0659 23.8452 13.0351 23.9915 12.9927H23.9954C24.7924 12.7771 25.5047 12.2958 26.0013 11.6182C26.0668 11.5797 26.1361 11.5373 26.2093 11.495V11.4911Z"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M26.2205 11.2911L26.2128 11.4374V11.4914C26.1396 11.5337 26.0703 11.5761 26.0049 11.6146C26.0742 11.526 26.1358 11.4336 26.1936 11.3373C26.2013 11.3219 26.2128 11.3065 26.2205 11.2911Z"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M7.96223 20.5533C7.71582 20.8267 7.42321 21.0654 7.11134 21.2695C7.09594 21.281 7.08053 21.2926 7.06128 21.3041C7.01893 21.3311 6.97273 21.3619 6.93038 21.3811C6.87262 21.4196 6.81487 21.4543 6.75712 21.4812C6.73786 21.4966 6.71476 21.5043 6.69551 21.512C5.52504 22.1281 4.16206 22.2705 3.47287 21.8662L3.13405 21.5351C2.72207 20.8113 3.31886 19.4329 4.1197 18.6475C4.23136 18.5358 4.35072 18.4357 4.47778 18.3433C5.98707 17.219 8.33186 17.3037 8.82854 18.1816L8.89784 18.3433C9.10961 19.0479 8.39732 20.0759 7.96609 20.5533H7.96223Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M3.47359 21.867C3.35808 21.8016 3.25797 21.7207 3.18482 21.6206C3.16557 21.5936 3.14632 21.5667 3.13477 21.5359L3.47359 21.867Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M9.86545 20.6765C9.86545 20.6765 8.39081 23.4025 4.83704 23.1946L3.47021 21.8662C4.15941 22.2705 5.52239 22.128 6.69286 21.512C6.71211 21.5043 6.73521 21.4966 6.75446 21.4812C6.81222 21.4543 6.86997 21.4196 6.92772 21.3811C6.97008 21.3619 7.01628 21.331 7.05863 21.3041C7.07788 21.2925 7.09328 21.281 7.10868 21.2694C7.42055 21.0654 7.71317 20.8267 7.95958 20.5533C8.39081 20.0759 9.1031 19.0479 8.89134 18.3433L9.86545 20.6765Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M7.59031 20.9576L4.46777 18.3433"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M9.25636 10.062C8.89059 10.0851 8.51326 10.0466 8.14749 9.97346C8.12824 9.97346 8.10898 9.96961 8.08588 9.96191C8.03583 9.95036 7.98193 9.94265 7.93958 9.9234C7.87027 9.91185 7.80482 9.89261 7.74707 9.87335C7.72396 9.87335 7.70086 9.8618 7.68161 9.85025C6.41489 9.46908 5.34452 8.61433 5.13661 7.84043L5.12891 7.36685C5.34067 6.5583 6.73445 5.99617 7.85487 6.00002C8.01273 6.00002 8.16674 6.01157 8.3246 6.03467C10.1881 6.29649 11.8014 7.99829 11.5395 8.9724L11.4741 9.13796C11.1314 9.78865 9.90319 10.0197 9.26021 10.0582L9.25636 10.062Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M5.13291 7.84406C5.09826 7.717 5.08285 7.58995 5.09825 7.46674C5.1021 7.43594 5.10981 7.40129 5.12136 7.37048L5.12906 7.84406H5.13291Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M10.5236 11.486C10.5236 11.486 7.55895 12.3946 5.17565 9.74949L5.1333 7.84363C5.34121 8.61367 6.41158 9.47228 7.67831 9.85345C7.69756 9.86115 7.72066 9.8727 7.74376 9.87655C7.80536 9.89965 7.87082 9.91506 7.93627 9.92661C7.97862 9.94201 8.03637 9.95355 8.08258 9.9651C8.10568 9.96895 8.12493 9.97281 8.14418 9.97666C8.50995 10.0498 8.88728 10.0845 9.25305 10.0652C9.89604 10.0267 11.1243 9.7957 11.4669 9.14501L10.5236 11.4898V11.486Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M8.70235 10.0921L8.31348 6.03784"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M40.192 37.1771L41.641 40.5346L45.505 38.616L43.2913 35.3021L46.954 33.3399L45.3843 30.0696L41.641 32.3806L39.709 29.0231L36.328 30.9417L38.0588 34.2556L34.396 36.3486L36.811 39.5753L40.192 37.1771Z"
      fill="#572E9E"
      stroke="#572E9E"
      strokeWidth="1.2"
    />
  </svg>
);
export default HealthAndMedicine;
