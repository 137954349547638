import { CHURNED_USER_CALENDAR_LINK } from "@/assets/constants/constants";
import { ModalKeys } from "@/assets/constants/modal";
import { Stack } from "@/components/generic/layout";
import { YesNoModal } from "@/components/modals";
import { Typography } from "@/components/ui/typography";
import { useModalState } from "@/zustand/slices/modal-slice";

function ChurnedUserModal(): JSX.Element {
  const [open, setModal] = useModalState(ModalKeys.CHURNED_USER_MODAL);

  const navigateToCalendly = (): void => {
    window.open(CHURNED_USER_CALENDAR_LINK);
  };

  return (
    <YesNoModal
      acceptButtonText="Find a Date"
      open={open}
      title="One month Pro Plan for free!"
      onCancel={() => setModal(false)}
      onYes={navigateToCalendly}
    >
      <Stack>
        <Typography className="font-bold">
          Don&apos;t want to leave Quino behind, but finding it difficult to afford a subscription? We&apos;ve got the
          perfect solution for you!
        </Typography>
        <Stack asChild className="gap-4 pl-4">
          <ul>
            <Typography asChild>
              <li>
                Participate in a quick and engaging user interview, lasting a maximum of 15-20 minutes. We&apos;ll ask
                you some simple questions about your experience with Quino.
              </li>
            </Typography>
            <Typography asChild>
              <li>
                As a thank you, you&apos;ll receive a complimentary one-month Pro Plan, including 50,000 tokens and
                exclusive access to the latest features.
              </li>
            </Typography>
          </ul>
        </Stack>
        <Typography>Ready to apply? It&apos;s as simple as a few clicks away!</Typography>
      </Stack>
    </YesNoModal>
  );
}

export default ChurnedUserModal;
