import { forwardRef } from "react";

import { Link, type LinkProps } from "react-router-dom";

import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useOpenSignUpToQuinoModal } from "@/service/library.ts";

const DemoHandlerLink = forwardRef<HTMLAnchorElement, LinkProps>(({ ...props }, ref) => {
  const onClick = useOpenSignUpToQuinoModal();
  const { matched: isDemo } = useIsDemoLikePage();

  return (
    <Link
      ref={ref}
      {...props}
      onClick={(e) => {
        if (isDemo) {
          e.preventDefault();
          onClick();
        }
      }}
    />
  );
});

DemoHandlerLink.displayName = "DemiHandlerNavLink";

export default DemoHandlerLink;
