import { z } from "zod";

import { ResourceCardType } from "@/assets/constants/constants.ts";

import { resourceElementSchema } from "./resources.ts";

export const baseFolderElementSchema = z
  .object({
    id: z.string(),
    elementType: z.nativeEnum(ResourceCardType),
    name: z.string(),
    dateCreated: z.string(),
  })
  .strict();
export type BaseFolderElement = z.infer<typeof baseFolderElementSchema>;
export const baseFolderElementWithChildrenSchema = baseFolderElementSchema
  .extend({
    projectId: z.string(),
    children: z.array(z.string()).optional(),
  })
  .strict();
export type StoreBaseFolderElement = z.infer<typeof baseFolderElementWithChildrenSchema>;
export const folderElementSchema = baseFolderElementSchema
  .extend({
    favourite: z.boolean().nullish(),
    dateCreated: z.string(),
    coverImageUrl: z.string().nullish(),
    projectId: z.string(),
    userId: z.string(),
    authors: z.array(z.string()).optional().nullish(),
  })
  .strict();
export type FolderElement = z.infer<typeof folderElementSchema>;

export const extendedFolderElementSchema = resourceElementSchema.extend({}).strict();

export type ExtendedFolderElement = z.infer<typeof extendedFolderElementSchema>;

// The folder entity is the folder object itself
export const baseFolderEntitySchema = resourceElementSchema
  .extend({
    projectId: z.string(),
  })
  .strict();

export type BaseFolderEntity = z.infer<typeof folderEntitySchema>;
export const folderEntitySchema = baseFolderEntitySchema
  .extend({
    childrenElements: z.array(folderElementSchema),
  })
  .strict();

export type FolderEntity = z.infer<typeof folderEntitySchema>;

const createFolderSchema = z
  .object({
    name: z.string(),
    projectId: z.string(),
  })
  .strict();
export type CreateFolderDTO = z.infer<typeof createFolderSchema>;

export const responseFolderSchema = createFolderSchema.and(resourceElementSchema).and(
  z
    .object({
      dateCreated: z.coerce.date(),
    })
    .strict(),
);
export type ResponseFolderSchema = z.infer<typeof responseFolderSchema>;
