import { collection, getDocs, limit, orderBy, query } from "@firebase/firestore";
import { httpsCallable } from "@firebase/functions";

import { FIRESTORE_USER_COLLECTION_PATH } from "@/assets/constants/firestore-constants";
import { PAYMENT_COLLECTION_NAME, STRIPE_PORTAL_CLOUD_FUNCTION_NAME } from "@/assets/constants/stripe";
import { db, functions } from "@/firebase";
import { customGAEventSender } from "@/service";
import { type Payment } from "@/types/stripe";

export async function queryLastPayment(userId: string | undefined): Promise<Payment | null> {
  if (!userId) return null;
  const paymentCollection = query(
    collection(db, FIRESTORE_USER_COLLECTION_PATH, userId, PAYMENT_COLLECTION_NAME),
    orderBy("created", "desc"),
    limit(1),
  );
  // this is unsafe and can produce a race condition if the payment is not saved to firestore in a timely manner
  const docs = await getDocs(paymentCollection);

  if (docs.docs.length === 0) {
    return null;
  }
  return docs.docs[0].data() as Payment;
}

export async function handlePortalLinkCreation(): Promise<string> {
  customGAEventSender("payment", "portal_creation");
  const portalFunction = httpsCallable<unknown, { url: string }>(functions, STRIPE_PORTAL_CLOUD_FUNCTION_NAME, {});
  return portalFunction({
    returnUrl: window.location.origin,
  }).then((result) => result?.data?.url);
}
