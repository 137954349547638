import { useMutation, type UseMutationOptions, useQueries, useQuery } from "@tanstack/react-query";

import { getRequest, getStrapiRequest, postRequest } from "@/api/utils";
import { STRAPI_API_URL } from "@/assets/constants/constants";
import { type TutorialVariant } from "@/assets/constants/modal";
import { useAuthUserData } from "@/firebase/hooks";
import { verifyAgeOfUser } from "@/service/hooks/user.ts";
import { type ExtendedPollAnswer, type PollAnswer, type QuizAnswer } from "@/types/interfaces/library.tsx";
import {
  type StrapiAnnouncement,
  type StrapiInterviewData,
  type StrapiLegalInfo,
  type StrapiMultiResponse,
  type StrapiNewsAndUpdates,
  type StrapiPlatformUpdate,
  type StrapiPoll,
  type StrapiQuiz,
  type StrapiSingleResponse,
  type StrapiTutorial,
} from "@/types/schemas";
import { getStrapiMediaUrl } from "@/utils";

export const getAnnouncements = async () => {
  return await getStrapiRequest<StrapiMultiResponse<StrapiAnnouncement>>(
    `${STRAPI_API_URL}/announcements?populate[0]=image&sort=order`,
  );
};

export const getNewsAndUpdates = async () => {
  return await getStrapiRequest<StrapiMultiResponse<StrapiNewsAndUpdates>>(
    `${STRAPI_API_URL}/news-and-updates?populate[0]=image`,
  );
};
export const getLegalInformation = async () => {
  return await getStrapiRequest<StrapiSingleResponse<StrapiLegalInfo>>(`${STRAPI_API_URL}/legal-information`);
};

export const getMyPolls = async () => {
  return await getRequest<StrapiPoll[]>(`${STRAPI_API_URL}/polls/custom-interaction/get-my-polls`);
};
export const getMyQuizzes = async () => {
  return await getRequest<StrapiQuiz[]>(`${STRAPI_API_URL}/quizzes/custom-interaction/get-my-quizzes`);
};
export const getPlatformUpdates = async (version: number = 0) => {
  return await getStrapiRequest<StrapiMultiResponse<StrapiPlatformUpdate>>(
    `${STRAPI_API_URL}/platform-updates?populate[slides][populate]=*&filters[version][$gt]=${version}`,
  );
};

export const setPollResponse = async ({ pollId, refusedToAnswer, answer = "NO_ANSWER" }: PollAnswer) =>
  postRequest(`${STRAPI_API_URL}/polls/custom-interaction/answer-poll`, { refusedToAnswer, answer, pollId });

export const setQuizResponse = async (answer: QuizAnswer) =>
  postRequest(`${STRAPI_API_URL}/quizzes/custom-interaction/answer-quiz`, answer);

export const getTutorial = async (variant: TutorialVariant) =>
  getStrapiRequest<StrapiMultiResponse<StrapiTutorial>>(
    `${STRAPI_API_URL}/tutorials?filters[variant][$eq]=${variant}&populate[0]=videoUrl&sort=index`,
  );

export const getHasToOpenInterviewModal = async () =>
  getRequest<{ hasToAnswer: boolean }>(`${STRAPI_API_URL}/interviews/custom-interaction/check-if-has-to-respond`);

export const setInterviewResponse = async ({ accepted }: { accepted: boolean }) =>
  postRequest(`${STRAPI_API_URL}/interviews/custom-interaction/interview-response`, { accepted });

export const getUserNotificationMessage = async () =>
  getStrapiRequest<StrapiSingleResponse<StrapiInterviewData>>(`${STRAPI_API_URL}/user-notification-message?populate=*`);

export const useAnnouncements = () => useQuery({ queryKey: ["announcements"], queryFn: getAnnouncements });
export const useNewsAndUpdates = () => useQuery({ queryKey: ["news-and-updates"], queryFn: getNewsAndUpdates });
export const useLegalInformation = () => useQuery({ queryKey: ["legal-information"], queryFn: getLegalInformation });

export const useTutorialData = (variant: TutorialVariant) => {
  const { data } = useAuthUserData();
  return useQuery({
    queryKey: ["tutorial", variant],
    queryFn: () => getTutorial(variant),
    gcTime: 60 * 60 * 1000,
    enabled: !!data?.uid,
  });
};
export const useInterviewModal = () => {
  const { data: user } = useAuthUserData();
  return useQueries({
    queries: [
      {
        queryKey: ["interview-modal", user?.uid],
        queryFn: () => getHasToOpenInterviewModal(),
        enabled: !!user?.uid,
        retry: 5,
      },
      {
        queryKey: ["interview-modal-data"],
        queryFn: () => getUserNotificationMessage(),
        enabled: !!user?.uid,
      },
    ],
  });
};

export const useRespondToInterviewModal = () => {
  return useMutation({
    mutationKey: ["interview-modal-response"],
    mutationFn: setInterviewResponse,
  });
};

export const useUserPolls = () => {
  const { data: user } = useAuthUserData();
  return useQuery({
    queryKey: ["polls", { userId: user?.uid }],
    queryFn: getMyPolls,
    select: (data) => data.filter((item) => verifyAgeOfUser(user, item.ageOfUserInDays ?? 2)),
    enabled: !!user?.uid,
  });
};

export const useUserQuizzes = () => {
  const { data: user } = useAuthUserData();
  return useQuery({
    queryKey: ["quizzes", { userId: user?.uid }],
    queryFn: getMyQuizzes,
    enabled: !!user?.uid,
    select: (result) => result.at(0),
  });
};

export const useMutationAnswerPoll = (options?: UseMutationOptions<unknown, unknown, Array<ExtendedPollAnswer>>) => {
  return useMutation({
    mutationKey: ["poll-answer"],
    mutationFn: async (answers: Array<ExtendedPollAnswer>) => {
      await Promise.all(
        answers.map((item) =>
          setPollResponse({
            pollId: item.pollId,
            refusedToAnswer: item.refusedToAnswer,
            answer: item.answer,
            pollUid: item.pollUid,
          }),
        ),
      );
    },
    ...options,
  });
};
export const useMutationAnswerQuiz = (options?: UseMutationOptions<unknown, unknown, QuizAnswer[]>) => {
  return useMutation({
    mutationKey: ["quiz-answer"],
    mutationFn: async (answers: QuizAnswer[]) => {
      await Promise.all(answers.map((item) => setQuizResponse(item)));
    },
    ...options,
  });
};

export const usePlatformUpdates = ({ version = 0 }: { version?: number }) => {
  const { data: user } = useAuthUserData();
  return useQuery({
    queryKey: ["platform-updates", { userId: user?.uid, version }],
    queryFn: () => getPlatformUpdates(version),
    select: (result) => {
      return result.data
        .map((update) =>
          update.attributes.slides.map((slide) => ({
            id: slide.id,
            date: update.attributes.date,
            version: update.attributes.version,
            text: slide.text,
            title: slide.title,
            image: {
              src: slide?.image?.data?.attributes?.url
                ? getStrapiMediaUrl(slide.image.data.attributes.url)
                : "/files/images/placeholder-news-and-updates.png",

              alt: slide?.image?.data?.attributes?.alternativeText ?? "slide-text",
            },
          })),
        )
        .flat();
    },
    enabled: !!user?.uid,
  });
};
