import { useCallback, useState } from "react";

import { useDeleteProject } from "@/api/project.ts";
import { Button } from "@/components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { DeleteIcon, DemoHandlerButton } from "@/components/v3";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useProjectSelectionState } from "@/service/library.ts";

import QuinoPopoverTitle from "../../../generic/quino-popover-title.tsx";

const DeleteSelectedProjectButton = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { selectedProjects, unSelectAllProjects } = useProjectSelectionState();
  const { mutate: deleteProject } = useDeleteProject();
  const [isOpen, setOpen] = useState(false);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onDelete = useCallback(() => {
    selectedProjects.forEach((item) => deleteProject({ projectId: item.id, workspaceId }));
    unSelectAllProjects();
    setOpen(false);
  }, [workspaceId, deleteProject, selectedProjects, unSelectAllProjects]);

  return (
    <Popover open={isOpen} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <DemoHandlerButton className="" size="sm" variant="destructive">
          <DeleteIcon className="fill-primitive-red-900" />
          Delete
        </DemoHandlerButton>
      </PopoverTrigger>
      <PopoverContent className="w-[22rem]">
        <QuinoPopoverTitle
          description="Are you sure you want to delete this project along with its summary?"
          title={`Delete ${selectedProjects.map((item) => `“${item.name}”`).join(", ")}`}
        />
        <section className="flex flex-row items-center justify-end gap-2">
          <Button size="sm" variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="sm" variant="destructive-filled" onClick={onDelete}>
            <DeleteIcon className="fill-white" />
            Delete
          </Button>
        </section>
      </PopoverContent>
    </Popover>
  );
};
export default DeleteSelectedProjectButton;
