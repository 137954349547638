import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useDeleteWorkspace, useUpdateWorkspace, useWorkspace } from "@/api/workspace.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button.tsx";
import { DialogFooter } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { DeleteIcon, ImageFileDropzone } from "@/components/v3";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

import CheckCircleFilled from "../../../icons/common/check-circle-filled.tsx";

const FormSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: "Note title must be at least 1 character",
    })
    .max(100, { message: "Must be at most 100 characters" }),
  image: z.instanceof(File).optional(),
});
type FormSchemaType = z.infer<typeof FormSchema>;

const WorkspaceEditPanel = () => {
  const [{ isOpen, workspaceId }, setModalState] = useModalState(ModalKeys.WORKSPACE_SETTINGS);
  const { data: workspace } = useWorkspace(workspaceId);

  const { mutate: updateWorkspace } = useUpdateWorkspace();
  const { mutate: deleteWorkspace } = useDeleteWorkspace();

  const setOpenState = useCallback(
    (value: boolean) => setModalState({ isOpen: value, workspaceId: "" }),
    [setModalState],
  );

  const handleSubmit = useCallback(
    (data: FormSchemaType) => {
      updateWorkspace(
        {
          id: workspaceId,
          payload: {
            name: data.name,
            coverImage: data.image,
          },
        },
        {
          onSuccess: () => {
            toast({
              duration: 5000,
              variant: "success",
              title: "Workspace updated!",
              icon: <CheckCircleFilled />,
            });
          },
        },
      );
      setTimeout(() => setOpenState(false), 100);
    },
    [setOpenState, updateWorkspace, workspaceId],
  );
  const onDeleteClick = useCallback(() => {
    deleteWorkspace(workspaceId);
    setOpenState(false);
  }, [deleteWorkspace, setOpenState, workspaceId]);

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: workspace?.name,
      image: undefined,
    },
  });

  useEffect(() => {
    if (workspace) {
      form.setValue("name", workspace.name);
    }
  }, [form, isOpen, workspace, workspaceId]);

  return (
    <Form {...form}>
      <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="">Name</FormLabel>
              <FormControl>
                <Input className="resize-none" placeholder="Workspace name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="image"
          render={({ field }) => (
            <FormItem className="w-full max-w-full overflow-hidden">
              <FormLabel className="">Workspace image</FormLabel>
              <FormLabel className="text-primary">
                <Typography className="font-normal text-secondary-onBg" size="extra-small">
                  Upload a PNG or JPG image, with max 1 MB file size.
                </Typography>
              </FormLabel>
              <FormControl>
                <ImageFileDropzone
                  onChange={(file) => {
                    field.onChange(file);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          <Button className="mr-auto" size="sm" type="button" variant="destructive" onClick={onDeleteClick}>
            <DeleteIcon />
            Delete workspace
          </Button>
          <Button size="sm" type="button" variant="secondary" onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button disabled={!form.formState.isValid} size="sm" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};
export default WorkspaceEditPanel;
