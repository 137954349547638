import { postRequest } from "@/api/utils";
import { BACKEND_URL } from "@/assets/constants/constants";

const ROOT = "/cloud-functions/brevo";

export const registerBrevoUser = async (): Promise<void> => {
  await postRequest(`${BACKEND_URL}${ROOT}/user-register`, {});
};

export const deleteBrevoUser = async (): Promise<void> => {
  await postRequest(`${BACKEND_URL}${ROOT}/user-remove`, {});
};

export const sendInvitationEmail = async (email: string, invitationLink: string): Promise<void> => {
  await postRequest(`${BACKEND_URL}${ROOT}/send-invitation`, {
    to_email: email,
    invitation_link: invitationLink,
  });
};
