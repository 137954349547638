import { useLegalInformation } from "@/api/strapi";
import LegalTemplate from "@/components/account/legal-template";

const PrivacyPolicy = () => {
  const { data, isLoading, isError } = useLegalInformation();

  return (
    <LegalTemplate
      data={data?.data.attributes.privacyPolicy}
      isError={isError}
      isLoading={isLoading}
      title="Quino Privacy Policy"
    />
  );
};

export default PrivacyPolicy;
