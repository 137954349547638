import { createId } from "@paralleldrive/cuid2";

import { getRequest } from "@/api/utils.ts";
import { ResourceCardType } from "@/assets/constants/constants.ts";
import {
  DEMO_DOCUMENT_TO_PROJECT,
  DEMO_USER_ID,
  DOCUMENT_DATA,
  DOCUMENT_DETAILS_DATA,
  DOCUMENT_SUMMARY_DATA,
  IDS,
  mockBaseProjects,
  mockBaseWorkspaces,
  NOTE_DATA,
  PROJECT_SCHEMA_DATA,
  type ResourceKeys,
  RESOURCES_DATA,
  WORKSPACE_SCHEMA_DATA,
} from "@/assets/constants/demo.ts";
import {
  type CreateMarkdownNoteParagraph,
  type DocumentChatSession,
  type DocumentChatSessionCreate,
  type DocumentDetailsSchema,
  type DocumentEntity,
  type ParagraphSummaryUpdate,
  type ProjectProcessingStatus,
  type SummaryResponse,
  type BaseProjectSchema,
  type NoteEntity,
  type NoteParagraph,
  type ProjectEntity,
  type ResourceElement,
  type UpdateProjectSchema,
  baseProjectSchemaArray,
  projectEntitySchema,
  noteEntitySchema,
  documentDetailsSchema,
  resourceElementSchema,
  projectProcessingStatusSchema,
  summaryResponseSchema,
  noteParagraphSchema,
  documentChatSessionSchema,
  documentEntitySchema,
  type ExtendedBaseNoteEntity,
  type ParagraphUpdateResponse,
  type UpdateResourceElement,
  type BaseDocumentEntity,
} from "@/types/schemas";
import {
  type WorkspaceEntity,
  type WorkspaceListItem,
  workspaceListItemSchemaList,
  workspaceSchema,
} from "@/types/schemas/api/workspaceEntity.ts";
import { validateZodSchema } from "@/utils";

export const getWorkspaceData = (workspaceId: string): WorkspaceEntity => {
  if (Object.hasOwn(WORKSPACE_SCHEMA_DATA, workspaceId)) {
    const _id = workspaceId as keyof typeof IDS.WORKSPACE;
    return WORKSPACE_SCHEMA_DATA[_id];
  }
  return WORKSPACE_SCHEMA_DATA["WORKSPACE_ID_ONE"];
};

export const getProjectData = (projectId: string): ProjectEntity => {
  if (Object.hasOwn(PROJECT_SCHEMA_DATA, projectId)) {
    const _id = projectId as keyof typeof IDS.PROJECT;
    return PROJECT_SCHEMA_DATA[_id];
  }
  return PROJECT_SCHEMA_DATA["PROJECT_ID_ONE"];
};

export const getNoteData = (noteId: string): NoteEntity => {
  if (Object.hasOwn(NOTE_DATA, noteId)) {
    const _id = noteId as keyof typeof IDS.NOTE;
    return NOTE_DATA[_id];
  }
  return NOTE_DATA["NOTE_ID_ONE"];
};

export const getDocumentData = (documentId: string): DocumentEntity => {
  if (Object.hasOwn(DOCUMENT_DATA, documentId)) {
    const _id = documentId as keyof typeof IDS.DOCUMENT;
    return DOCUMENT_DATA[_id];
  }
  return DOCUMENT_DATA["DOCUMENT_ID_ONE"];
};

export const getDocumentDetailsData = (documentId: string): DocumentDetailsSchema => {
  if (Object.hasOwn(DOCUMENT_DETAILS_DATA, documentId)) {
    const _id = documentId as keyof typeof IDS.DOCUMENT;
    return DOCUMENT_DETAILS_DATA[_id];
  }
  return DOCUMENT_DETAILS_DATA["DOCUMENT_ID_ONE"];
};

export const getResourceData = (resourceId: string): ResourceElement => {
  if (Object.hasOwn(RESOURCES_DATA, resourceId)) {
    const _id = resourceId as ResourceKeys;
    return RESOURCES_DATA[_id];
  }
  return RESOURCES_DATA["NOTE_ID_ONE"];
};

export const getDocumentSummaryDataId = (documentId: string): string => {
  if (Object.hasOwn(DOCUMENT_SUMMARY_DATA, documentId)) {
    return DOCUMENT_SUMMARY_DATA[documentId];
  }
  return DOCUMENT_SUMMARY_DATA[IDS.DOCUMENT.DOCUMENT_ID_ONE];
};

export const getProjectNotIds = (projectId: string): string[] => {
  let project: ProjectEntity;
  if (Object.hasOwn(PROJECT_SCHEMA_DATA, projectId)) {
    const _id = projectId as keyof typeof IDS.PROJECT;
    project = PROJECT_SCHEMA_DATA[_id];
  } else {
    project = PROJECT_SCHEMA_DATA["PROJECT_ID_ONE"];
  }
  return project.directChildElements
    .filter((item) => item.elementType === ResourceCardType.NOTE_ELEMENT)
    .map((item) => item.id);
};

export const fetchAllDemo = async () =>
  validateZodSchema(
    new Promise<BaseProjectSchema[]>((resolve) => {
      // Simulating a delay of 500ms to mimic network latency
      setTimeout(() => {
        resolve(mockBaseProjects);
      }, 500);
    }),
    baseProjectSchemaArray,
  );

export const fetchAllDemoWorkspaces = async (): Promise<WorkspaceListItem[]> => {
  return validateZodSchema(
    new Promise<WorkspaceListItem[]>((resolve) => {
      // Simulating a delay of 500ms to mimic network latency
      setTimeout(() => {
        resolve(mockBaseWorkspaces);
      }, 500);
    }),
    workspaceListItemSchemaList,
  );
};

export const fetchDemoWorkspaceData = async (workspaceId: string = "") =>
  validateZodSchema(
    new Promise<WorkspaceEntity>((resolve) => {
      // Simulating a delay of 500ms to mimic network latency
      setTimeout(() => {
        resolve(getWorkspaceData(workspaceId));
      }, 500);
    }),
    workspaceSchema,
  );

export const fetchProjectDataDemo = async (projectId: string, _variant?: string) =>
  validateZodSchema(
    new Promise<ProjectEntity>((resolve) => {
      // Simulating a delay of 500ms to mimic network latency
      setTimeout(() => {
        resolve(getProjectData(projectId));
      }, 500);
    }),
    projectEntitySchema,
  );

export const fetchNoteDataDemo = (noteId: string, _openedAt?: "library" | "document_view"): Promise<NoteEntity> =>
  validateZodSchema(
    new Promise<NoteEntity>((resolve) => {
      // Simulating a delay of 500ms to mimic network latency
      setTimeout(() => {
        resolve(getNoteData(noteId));
      }, 500);
    }),
    noteEntitySchema,
  );

export const fetchDocumentDataDemo = (
  documentId: string,
  _openedAt?: "library" | "document_view",
): Promise<DocumentEntity> =>
  validateZodSchema(
    new Promise<DocumentEntity>((resolve) => {
      // Simulating a delay of 500ms to mimic network latency
      setTimeout(() => {
        resolve(getDocumentData(documentId));
      }, 500);
    }),
    documentEntitySchema,
  );

export const fetchDocumentDetailsDataDemo = (
  documentId: string,
  _openedAt?: "library" | "document_view",
): Promise<DocumentDetailsSchema> =>
  validateZodSchema(
    new Promise<DocumentDetailsSchema>((resolve) => {
      // Simulating a delay of 500ms to mimic network latency
      setTimeout(() => {
        resolve(getDocumentDetailsData(documentId));
      }, 500);
    }),
    documentDetailsSchema,
  );

export const fetchResourceDataMock = (
  resourceId: string,
  _type: ResourceCardType,
  _openedAt?: "library" | "document_view",
) =>
  validateZodSchema(
    new Promise<ResourceElement>((resolve) => {
      setTimeout(() => {
        resolve(getResourceData(resourceId));
      });
    }),
    resourceElementSchema,
  );

export const fetchProjectDemoProcessingStatus = async ({}: { projectId: string }) =>
  validateZodSchema(
    new Promise<ProjectProcessingStatus>((resolve) => {
      setTimeout(() => {
        resolve({
          allDocumentsProcessed: true,
          documentStatuses: [],
        });
      });
    }),
    projectProcessingStatusSchema,
  );

export const fetchDemoDocumentSummary = (documentId: string = "DOCUMENT_SUMMARY_ONE") =>
  validateZodSchema(
    getRequest<SummaryResponse>(`/demo/data/summary/${getDocumentSummaryDataId(documentId)}/data.json`),
    summaryResponseSchema,
  );

export const fetchDemoDocumentSummaryPaged = (
  documentId: string = "DOCUMENT_SUMMARY_ONE",
  offset: number = 0,
  _limit: number = 10,
) =>
  validateZodSchema(
    getRequest<SummaryResponse>(`/demo/data/summary/${getDocumentSummaryDataId(documentId)}/page_${offset + 1}.json`),
    summaryResponseSchema,
  );

export const updateDemoParagraphSummary = ({ payload }: { payload: Partial<ParagraphSummaryUpdate> }) =>
  new Promise<ParagraphUpdateResponse>((resolve) => {
    resolve({
      userLikeDislikeValue: payload.likeDislikeValue,
    } as ParagraphUpdateResponse);
  });

export const createNewNoteDemo = async ({ name, projectId }: { name: string; projectId: string }) =>
  validateZodSchema(
    new Promise<NoteEntity>((resolve) => {
      setTimeout(() => {
        resolve({
          dateCreated: new Date().toISOString(),
          dateLastOpen: new Date().toISOString(),
          name: name,
          projectId: projectId,
          elementType: ResourceCardType.NOTE_ELEMENT,
          id: createId().toLowerCase(),
          favourite: false,
          userId: DEMO_USER_ID,
          noteParagraphs: [],
        });
      }, 500);
    }),
    noteEntitySchema,
  );

export const demoUpdateParagraphNote = async (params: {
  paragraphId: string;
  payload: Partial<CreateMarkdownNoteParagraph>;
}) =>
  validateZodSchema(
    new Promise<NoteParagraph>((resolve) => {
      resolve({
        markdownText: params.payload.markdownText ?? "",
        position: params.payload.position ?? 0,
        documentId: "",
        docPageNumber: params.payload.docPageNumber ?? 0,
        docPageTextOrigin: "",
        modelLogId: "",
        id: "pd3ecrrm0noqav2my5cpz824",
        noteId: "NOTE_ID_TWO",
        dateCreated: "2024-01-10T09:01:42.104Z",
        noteParagraphType: "TEXT",
      });
    }),
    noteParagraphSchema,
  );

export const demoUpdateNote = async ({
  noteId,
  name,
}: {
  noteId: string;
  name: string;
}): Promise<ExtendedBaseNoteEntity> =>
  new Promise<ExtendedBaseNoteEntity>((resolve) => {
    resolve({
      id: noteId,
      name: name,
    } as ExtendedBaseNoteEntity);
  });

export const demoUpdateDocument = async ({
  documentId,
  workspaceId,
  payload,
}: {
  documentId: string;
  workspaceId: string;
  payload: UpdateResourceElement;
}): Promise<BaseDocumentEntity> =>
  new Promise<BaseDocumentEntity>((resolve) => {
    resolve({
      id: documentId,
      workspaceId,
      ...(payload.name && { name: payload.name }),
      ...(payload.favourite && { favourite: payload.favourite }),
    } as unknown as BaseDocumentEntity);
  });

export const demoDeleteNote = async ({
  noteId,
}: {
  noteId: string;
  workspaceId: string;
}): Promise<ExtendedBaseNoteEntity> =>
  new Promise<ExtendedBaseNoteEntity>((resolve) => {
    resolve({
      id: noteId,
    } as ExtendedBaseNoteEntity);
  });

export const demoEditProject = async ({
  projectId,
  payload,
}: {
  projectId: string;
  workspaceId: string;
  payload: UpdateProjectSchema;
}) =>
  new Promise<ProjectEntity>((resolve) => {
    resolve({
      id: projectId,
      name: payload.name,
      ...(payload.colorTag ? { colorTag: payload.colorTag } : {}),
    } as ProjectEntity);
  });

export const createDemoNewSession = async ({
  payload: { projectId: _, documentId, text },
}: {
  payload: DocumentChatSessionCreate;
}): Promise<DocumentChatSession> => {
  const _docId = (documentId ?? IDS.DOCUMENT.DOCUMENT_ID_ONE) as keyof typeof DEMO_DOCUMENT_TO_PROJECT;
  const projectId = DEMO_DOCUMENT_TO_PROJECT[_docId];
  const sessionId = createId();
  return validateZodSchema(
    new Promise<DocumentChatSession>((resolve) =>
      resolve({
        dateCreated: new Date().toISOString(),
        name: text,
        id: sessionId,
        projectId: projectId,
        elements: [],
      }),
    ),
    documentChatSessionSchema,
  );
};
