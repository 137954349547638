import { useCallback, useMemo, useState } from "react";

import { UploadIcon } from "lucide-react";
import { type FileRejection, useDropzone } from "react-dropzone";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { DeleteIcon } from "@/components/v3";

const ImageFileDropzone = ({
  onChange,
  initialImage,
  fileSizeLimit = 1024 * 1024,
  title = "Select image...",
  description = "Upload a PNG or JPG image, with max 1 MB file size.",
}: {
  onChange: (file: File) => void;
  initialImage?: string;
  title?: string;
  description?: string;
  fileSizeLimit?: number;
}) => {
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      // Do something with the files
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles[0]);
        setAcceptedFile(acceptedFiles[0]);
      }
      if (rejectedFiles.length > 0) {
        toast({
          title: "Some file(s) were rejected",
          description: "Maybe the selected file is too large!",
          variant: "destructive",
        });
      }
    },
    [onChange, setAcceptedFile],
  );

  const onDeleteClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange(undefined);
    setAcceptedFile(null);
  }, [onChange]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: fileSizeLimit,

    accept: {
      "image/png": [],
      "image/jpeg": [],
    },
  });

  const { acceptedFileUrl, acceptedFileName } = useMemo(() => {
    if (!acceptedFile)
      return {
        acceptedFileUrl: initialImage ?? "",
        acceptedFileName: "",
      };
    return {
      acceptedFileUrl: URL.createObjectURL(acceptedFile),
      acceptedFileName: acceptedFile.name,
    };
  }, [acceptedFile, initialImage]);

  return (
    <section
      className="group relative flex h-30 w-full cursor-pointer flex-row items-center justify-center gap-4 rounded-lg border border-dashed border-stroke-default data-[has-accepted=true]:border-transparent"
      data-has-accepted={acceptedFileUrl.length > 0 || initialImage}
      {...getRootProps()}
    >
      <section className="group-data-[has-accepted=true]:hidden">
        <UploadIcon className="stroke-text-default" />
      </section>
      <section className="flex max-w-[10rem] flex-col gap-1 group-data-[has-accepted=true]:hidden">
        <Typography className="font-bold" size="extra-small">
          {title}
        </Typography>
        {description && (
          <Typography className="text-secondary-onBg" size="extra-small">
            {description}
          </Typography>
        )}
      </section>
      {acceptedFileUrl && (
        <section className="flex w-full flex-col">
          <section className="flex w-full flex-row items-center gap-4">
            <section className="relative h-30 w-30 overflow-hidden rounded-lg">
              <img alt="Accepted file" className="absolute h-full w-full object-cover" src={acceptedFileUrl} />
            </section>
            <section className="flex flex-1 flex-col gap-4">
              <p className="max-w-[20rem] truncate font-bold text-secondary-onBg">{acceptedFileName}</p>
              <section className="flex flex-row items-center gap-1.5">
                <Button className="flex-1" size="sm" type="button" variant="secondary">
                  Select a different image...
                </Button>
                <Button
                  className="p-3 "
                  size="icon-large"
                  variant="destructive-secondary"
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteClick();
                  }}
                >
                  <DeleteIcon />
                </Button>
              </section>
            </section>
          </section>
        </section>
      )}
      <Input classNameRoot="resize-none absolute" placeholder="Workspace name" type="file" {...getInputProps()} />
    </section>
  );
};
export default ImageFileDropzone;
