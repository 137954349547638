const TableOfContentsHeaderLink = ({ value, index }: { pathname: string; value: string; index: string }) => {
  return (
    <a
      className="summary-header-target w-full border-l-2 border-stroke-default px-3 py-[0.4688rem] text-landing-sm font-normal text-text-default"
      href={`#heading-${index}`}
      hrefLang="en"
      id={`summary-heading-${index}`}
    >
      {value}
    </a>
  );
};

export default TableOfContentsHeaderLink;
