import { useCallback, useMemo } from "react";

import { useQuestionSuggestion, useStreamingMessage } from "@/api/document-chat";
import BulletPoint from "@/assets/svgs/questions/bullet-point.svg";
import CompareSheet from "@/assets/svgs/questions/compare-sheet.svg";
import Essay from "@/assets/svgs/questions/essay.svg";
import Question from "@/assets/svgs/questions/question.svg";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { useChatDocuConfig } from "@/service/hooks/documents.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { TaskType } from "@/types/schemas";

const typeToImageMap = {
  [TaskType.EXPLANATION]: Essay,
  [TaskType.BULLETPOINTS]: BulletPoint,
  [TaskType.TABLE_COMPARISON]: CompareSheet,
  [TaskType.QUESTION]: Question,
};
const QuinoChatBaseSuggestions = () => {
  const { data: suggestion, isLoading } = useQuestionSuggestion();
  const { mutate: sendStreamingMessage } = useStreamingMessage();
  const [storageState] = useChatDocuConfig();
  const click = useCallback(
    (question: string) => {
      if (!question) return;
      mixpanelTrack("qa_recommendation_clicked");
      sendStreamingMessage({ text: question, projectWide: !storageState.documentOnly });
    },
    [sendStreamingMessage, storageState.documentOnly],
  );

  return useMemo(() => {
    if (isLoading)
      return (
        <section className="grid w-full grid-cols-2 gap-2">
          <Skeleton className="h-18 w-full" />
          <Skeleton className="h-18 w-full" />
          <Skeleton className="h-18 w-full" />
          <Skeleton className="h-18 w-full" />
        </section>
      );

    return (
      <section className="grid w-full grid-cols-2 gap-2">
        {suggestion?.questionsList.map((suggestion) => (
          <section
            className="flex cursor-pointer flex-row items-center gap-2 rounded-lg bg-secondary-container-default p-2 hover:bg-secondary-container-hover"
            key={suggestion.task}
            onClick={() => click(suggestion.task)}
          >
            <section>
              <p className="h-18 w-16">
                <img alt="compare sheet" className="h-full w-full" src={typeToImageMap[suggestion.taskType]} />
              </p>
            </section>
            <p className="text-xs font-bold text-default">{suggestion.task}</p>
          </section>
        ))}
      </section>
    );
  }, [click, isLoading, suggestion?.questionsList]);
};
export default QuinoChatBaseSuggestions;
