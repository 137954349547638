import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const QuinoLogoPurple = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 110 32"
    width="110"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-8 w-[110px] fill-primitive-purple-800", props.className)}
  >
    <path
      className="fill-inherit"
      d="M9.23328 21.9095C10.0337 22.6682 10.474 23.6918 10.474 24.7927H12.2592C12.2592 23.1979 11.6195 21.7131 10.4621 20.6152L9.23328 21.9095Z"
    />
    <path
      className="fill-inherit"
      d="M6.79382 16.2984V18.0836C9.96856 18.0806 12.5512 15.495 12.5512 12.3203H10.766C10.7689 14.5132 8.98668 16.2984 6.79382 16.2984Z"
    />
    <path
      className="fill-inherit"
      d="M27.8109 5.71413H25.3532C25.3235 2.74173 22.8985 0.334656 19.9232 0.334656C18.0011 0.334656 16.311 1.34033 15.344 2.85182C14.4247 1.55158 12.9132 0.697652 11.2023 0.697652C8.51852 0.697652 6.31971 2.79529 6.15012 5.43742H6.14417C6.14417 5.45528 6.14714 5.47015 6.14714 5.48801C6.14119 5.58322 6.13226 5.67546 6.13226 5.77067C6.13226 5.83017 6.13226 5.88968 6.13524 5.94919C4.01082 7.14232 2.66892 9.4036 2.66892 11.8761C2.66892 12.1261 2.6838 12.379 2.71057 12.6319C0.981876 14.1731 0 16.3541 0 18.6897C0 21.6264 1.54422 24.2805 4.0614 25.7325C4.34108 28.8477 6.96835 31.2994 10.155 31.2994C12.3449 31.2994 14.2699 30.1479 15.353 28.4103C16.4747 30.3592 18.5723 31.6654 20.9705 31.6654C24.2761 31.6654 27.0165 29.172 27.4033 25.9675H27.8109C30.584 25.9675 32.8333 23.7181 32.8333 20.9451V10.7395C32.8363 7.96054 30.5869 5.71413 27.8109 5.71413ZM14.4871 25.2951L14.4812 25.4052C14.3651 27.7081 12.4639 29.5142 10.155 29.5142C7.76872 29.5142 5.82282 27.5712 5.82282 25.185V25.179C5.8258 23.8669 6.47146 22.6321 7.54855 21.8794L6.52501 20.4155C5.38247 21.2129 4.57911 22.3852 4.23099 23.7033C2.69867 22.5131 1.78523 20.6862 1.78523 18.6897C1.78523 16.729 2.67189 14.908 4.21909 13.6941L4.64755 13.3579L4.54638 12.8253C4.48688 12.5099 4.45712 12.1915 4.45712 11.8761C4.45712 10.1831 5.31403 8.62405 6.69461 7.70466C7.31052 8.92457 8.40843 9.88561 9.77116 10.2843L10.274 8.5705C8.91127 8.16882 7.95617 6.90428 7.93535 5.48801C8.07816 3.80692 9.4885 2.48288 11.2053 2.48288C13.0173 2.48288 14.4901 3.95569 14.4901 5.76769V25.2951H14.4871ZM25.017 22.3227H17.9802V20.5375H25.017V22.3227ZM30.2329 17.7852H17.9802V16H30.2329V17.7852ZM30.2329 13.2151H17.9802V11.4298H30.2329V13.2151Z"
    />
    <path
      className="fill-inherit"
      d="M51.9149 27.5315C52.5542 27.5315 53.1071 27.3587 53.5736 27.0132C54.0574 26.6849 54.4289 26.227 54.688 25.6396L56.1653 27.3501C55.7852 28.1276 55.1805 28.7582 54.3511 29.242C53.5218 29.7258 52.5888 29.9677 51.5521 29.9677C50.1353 29.9677 49.0123 29.5876 48.1829 28.8273C47.3536 28.0671 46.9389 27.0477 46.9389 25.7692V24.577C45.2111 24.3869 43.6993 23.8686 42.4035 23.022C41.1249 22.1581 40.1314 21.0264 39.4231 19.6269C38.7319 18.2274 38.3864 16.6292 38.3864 14.8323C38.3864 12.8626 38.7924 11.1348 39.6045 9.64894C40.4338 8.16304 41.6001 7.01406 43.1032 6.20201C44.6237 5.38995 46.386 4.98392 48.3903 4.98392C50.3945 4.98392 52.1482 5.38995 53.6514 6.20201C55.1718 7.01406 56.3467 8.17168 57.176 9.67485C58.0054 11.1607 58.42 12.8799 58.42 14.8323C58.42 16.6465 58.0659 18.2619 57.3575 19.6787C56.6491 21.0782 55.6383 22.2013 54.3252 23.0479C53.0121 23.8945 51.483 24.4042 49.7379 24.577V25.3286C49.7379 26.0197 49.928 26.5553 50.3081 26.9354C50.6882 27.3328 51.2238 27.5315 51.9149 27.5315ZM41.6001 14.8323C41.6001 16.2318 41.8765 17.4672 42.4294 18.5384C42.9996 19.5923 43.7943 20.413 44.8137 21.0005C45.8504 21.5707 47.0426 21.8557 48.3903 21.8557C49.7379 21.8557 50.9215 21.5707 51.9409 21.0005C52.9775 20.413 53.7723 19.5923 54.3252 18.5384C54.8954 17.4672 55.1805 16.2318 55.1805 14.8323C55.1805 13.4328 54.8954 12.2061 54.3252 11.1521C53.7723 10.0809 52.9775 9.26018 51.9409 8.69002C50.9215 8.10257 49.7379 7.80885 48.3903 7.80885C47.0426 7.80885 45.8504 8.10257 44.8137 8.69002C43.7943 9.26018 42.9996 10.0809 42.4294 11.1521C41.8765 12.2061 41.6001 13.4328 41.6001 14.8323Z"
    />
    <path
      className="fill-inherit"
      d="M64.9476 24.4474C63.5136 24.4474 62.3819 24.0241 61.5525 23.1775C60.7405 22.3136 60.3344 21.1214 60.3344 19.601V10.8152H63.263V18.9012C63.263 19.8515 63.5222 20.6031 64.0405 21.156C64.5589 21.7089 65.25 21.9853 66.1139 21.9853C67.2024 21.9853 68.0922 21.6311 68.7833 20.9227C69.4917 20.2143 69.8459 19.2986 69.8459 18.1756V10.8152H72.7745V24.0068H70.2347L69.8718 21.8298H69.8459C68.7228 23.5749 67.0901 24.4474 64.9476 24.4474Z"
    />
    <path
      className="fill-inherit"
      d="M76.7756 8.63818C76.2054 8.63818 75.7476 8.47404 75.402 8.14576C75.0565 7.80021 74.8837 7.35098 74.8837 6.79809C74.8837 6.2452 75.0565 5.80461 75.402 5.47634C75.7476 5.14806 76.2054 4.98392 76.7756 4.98392C77.3285 4.98392 77.7777 5.1567 78.1233 5.50225C78.4861 5.83053 78.6675 6.26248 78.6675 6.79809C78.6675 7.35098 78.4861 7.80021 78.1233 8.14576C77.7777 8.47404 77.3285 8.63818 76.7756 8.63818ZM78.201 24.0068H75.2983V10.8152H78.201V24.0068Z"
    />
    <path
      className="fill-inherit"
      d="M88.5631 10.3746C89.9971 10.3746 91.1202 10.8066 91.9323 11.6704C92.7616 12.5171 93.1763 13.7006 93.1763 15.221V24.0068H90.2477V15.9208C90.2477 14.9705 89.9885 14.2189 89.4702 13.666C88.9518 13.1131 88.2607 12.8367 87.3968 12.8367C86.3083 12.8367 85.4099 13.1909 84.7015 13.8993C84.0104 14.6077 83.6648 15.5234 83.6648 16.6465V24.0068H80.7362V10.8152H83.2761L83.6389 12.9922H83.6648C84.7879 11.2471 86.4206 10.3746 88.5631 10.3746Z"
    />
    <path
      className="fill-inherit"
      d="M102.102 24.5252C100.668 24.5252 99.4068 24.2314 98.3183 23.644C97.2471 23.0565 96.4091 22.2272 95.8044 21.156C95.2169 20.0848 94.9232 18.8408 94.9232 17.424C94.9232 16.0072 95.2169 14.7632 95.8044 13.692C96.4091 12.6034 97.2471 11.7655 98.3183 11.178C99.4068 10.5906 100.668 10.2969 102.102 10.2969C103.536 10.2969 104.789 10.5906 105.86 11.178C106.949 11.7655 107.787 12.6034 108.374 13.692C108.979 14.7632 109.281 16.0072 109.281 17.424C109.281 18.8408 108.979 20.0848 108.374 21.156C107.787 22.2272 106.949 23.0565 105.86 23.644C104.789 24.2314 103.536 24.5252 102.102 24.5252ZM102.102 22.0372C103.398 22.0372 104.435 21.6225 105.212 20.7931C105.99 19.9465 106.378 18.8235 106.378 17.424C106.378 16.0245 105.99 14.91 105.212 14.0807C104.435 13.2514 103.398 12.8367 102.102 12.8367C100.789 12.8367 99.7437 13.2514 98.9662 14.0807C98.206 14.91 97.8259 16.0245 97.8259 17.424C97.8259 18.8235 98.206 19.9465 98.9662 20.7931C99.7437 21.6225 100.789 22.0372 102.102 22.0372Z"
    />
  </svg>
);
export default QuinoLogoPurple;
