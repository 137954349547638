import { useState } from "react";

import { PlusIcon } from "lucide-react";

import { useCreateParagraph } from "@/api/note.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button.tsx";
import { Divider } from "@/components/ui/divider.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { AddImageParagraphIcon, AddParagraphIcon } from "@/components/v3/icons/note-viewer.tsx";
import { useCurrentNoteId } from "@/service/hooks/react-router.ts";
import { cn } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

export const ParagraphDivider = ({
  position = 0,
  visible,
  className,
}: {
  visible?: boolean;
  position?: number;
  className?: string;
}) => {
  const [state, setState] = useState({ open: false });
  const [noteId] = useCurrentNoteId();
  const { mutate: createParagraph } = useCreateParagraph();

  const [, setOpenModal] = useModalState(ModalKeys.IMAGE_NOTE_PARAGRAPH);

  return (
    <Popover onOpenChange={(value) => setState((prev) => ({ ...prev, open: value }))}>
      <div className="w-full" data-open={state.open || visible}>
        <PopoverTrigger asChild>
          <div className={cn("group w-full", className)} data-open={visible}>
            <Divider
              classNameChild="absolute top-0 h-3"
              classNameRoot="group-hover:visible relative group-data-[open=true]:visible invisible relative cursor-pointer h-3"
            >
              <Button
                className="absolute left-1/2 top-1/2 flex h-5 w-5 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-transparent text-center text-xs  xl:pl-0"
                size="icon"
                variant="icon"
              >
                <PlusIcon className="h-4 w-4" />
              </Button>
            </Divider>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent className="flex w-auto flex-row gap-1.5 rounded-xl p-1.5">
        <Button
          className="gap-1"
          size="xs"
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            createParagraph({
              noteId,
              payload: {
                variant: "TEXT",
                payload: {
                  markdownText: "",
                  position,
                },
              },
            });
          }}
        >
          <AddParagraphIcon className="h-4 w-4" />
          Add Paragraph
        </Button>
        <Button
          className="gap-1"
          size="xs"
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            setOpenModal({ isOpen: true, noteId: noteId, position: position });
          }}
        >
          <AddImageParagraphIcon className="h-4 w-4" />
          Add Image
        </Button>
      </PopoverContent>
    </Popover>
  );
};
export const ParagraphDividerBasic = ({ position = 0, visible }: { visible?: boolean; position?: number }) => {
  const [noteId] = useCurrentNoteId();
  const { mutate: createParagraph } = useCreateParagraph();

  return (
    <div className="group w-full" data-open={visible}>
      <Divider
        classNameChild="absolute top-0  h-6"
        classNameRoot="group-hover:visible group-data-[open=true]:visible invisible relative cursor-pointer h-6"
        onClick={() =>
          createParagraph({
            noteId,
            payload: {
              variant: "TEXT",
              payload: {
                markdownText: "",

                position,
              },
            },
          })
        }
      >
        <Button
          className="flex h-6 w-6 items-center justify-center bg-white text-center"
          size="icon"
          variant="icon"
          onClick={(e) => {
            e.stopPropagation();
            createParagraph({
              noteId,
              payload: {
                variant: "TEXT",
                payload: {
                  markdownText: "",

                  position,
                },
              },
            });
          }}
        >
          +
        </Button>
      </Divider>
    </div>
  );
};
