import { z } from "zod";

function httpUrlToWebSockeUrl(url: string): string {
  return url.replace(/(http)(s)?\:\/\//, "ws$2://");
}

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const MODEL_LAKE_URL = import.meta.env.VITE_MODEL_LAKE_URL;

export const websocketUrl = BACKEND_URL ? httpUrlToWebSockeUrl(BACKEND_URL) : undefined;

export const ROUTE_PATHS = {
  HOME: "home",
  WORKSPACES: "library",
};

const freeRoutes = {
  LOGIN: "/login",
  DEMO: "/demo",
  REGISTER: "/register",
  REGISTER_WITH_REFERRAL: "/REGISTER_WITH_REFERRAL",
  RESET: "/reset",
  FIREBASE_ACTION: "/firebase-action",
  INVITE: "/invite",
  CLAIM_SUMMARY: "/claim-summary",
  TEMPORARY: "/temporary",
};

export const ROUTES = {
  ROOT: "/",
  BETA: "/beta",
  EDITOR: "/editor",
  HOME: `/${ROUTE_PATHS.HOME}`,
  WORKSPACES: `/${ROUTE_PATHS.WORKSPACES}`,
  LIBRARY: "/library",
  DOCUMENTS: "/documents",
  KNOWLEDGE_CHECK: "/knowledge-check",
  CHALLENGES: "/challenges",
  ACCOUNT: "/account",
  POLICY: "/policy",
  UNSUCCESSFUL_PAYMENT: "/unsuccessful_payment",
  SUCCESSFUL_PAYMENT: "/successful_payment",
  UPGRADE_PLANS: "/upgrade",
  PRICING: "/pricing",
  SUBSCRIPTION: "/subscription",
  ...freeRoutes,
} as const;

export const isFreeRoute = (route: string) => {
  return Object.values(freeRoutes).some((item) => route.startsWith(item));
};

export const QUERY_PARAMS = {
  PAGE: "page",
  NOTE: "note",
  FLASHCARD_TAB: "flashcard",
  DOCUMENT: "document",
  CHAT_SESSION: "chat-session",
  NOTE_TAB: "note-tab",
  FULLSCREEN: "fullscreen",
  FDS_QUERY_KEY: "temporary-fds-id",
  REFERRAL_CODE: "rc",
  MIXPANEL_DISTINCT_ID: "mixpanel-distinct-id",
};

// export const SETTING_ROUTES = {
//   PERSONAL_AND_ACCOUNT_DATA: "personal-and-account-data",
//   INTERFACE_CUSTOMIZATION: "interface-customization",
//   INTEGRATIONS: "integrations",
//   SECURITY: "security",
//   PRIVACY_POLICY: "privacy-policy",
//   SUBSCRIPTION_AND_PRICING: "subscription-and-pricing",
// };

export const ACCOUNT_ROUTES = {
  PERSONAL_AND_ACCOUNT_DATA: "personal-and-account-data",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  PRIVACY_POLICY: "privacy-policy",
  COOKIE_POLICY: "cookie-policy",
};

// countryCodes.unshift("");
// countryNamesEn.unshift("");

export const defaultColors = [
  "#33124D",
  "#33124DBF",
  "#33124D80",
  "#33124D40",
  "#33124D1A",
  "#33124D0D",
  "#B994C7",
  "#A5C3FC",
  "#6AE4F1",
  "#FFAAA9",
  "#FFC37A",
  "#FFDD81",
  "#733E9C",
  "#6582EA",
  "#46C9E5",
  "#FB7268",
  "#FD9F11",
  "#F9CA20",
  "#49255B",
  "#315797",
  "#007B8B",
  "#B2393A",
  "#AF5600",
  "#A67406",
];

export const PROJECT_COLORS = [
  "#FFC0BB",
  "#ABE1ED",
  "#FFE68C",
  "#46E5BF",
  "#EBB8F0",
  "#FFD697",
  "#C798EC",
  "#B6C5FB",
  "#74E09F",
  "#F673AA",
  "#C1C1C1",
];

export const TEXT_POPUP_BUTTONS = {
  ADD_TO_NOTES_BUTTON: "addToNotesButton",
  ADD_TO_NOTES_BUTTON_DISABLED: "addToNotesButtonDisabled",
  SUMMARIZE_BUTTON: "summarizeButton",
  SUMMARIZE_BUTTON_DISABLED: "summarizeButtonDisabled",
  BULLET_POINT_SUMMARIZATION_BUTTON: "bulletpointSummarizationButton",
  BULLET_POINT_SUMMARIZATION_BUTTON_DISABLED: "bulletpointSummarizationButtonDisabled",
  AI_LOOKUP_SUMMARIZATION: "aiLookupSummarizationButton",
  AI_LOOKUP_SUMMARIZATION_DISABLED: "aiLookupSummarizationButtonDisabled",
  QUESTION_GENERATION_BUTTON_FREE: "questionGenerationButtonFree",
  QUESTION_GENERATION_BUTTON: "questionGenerationButton",
  QUESTION_GENERATION_BUTTON_DISABLED: "questionGenerationButtonDisabled",
  FEEDBACK_SECTION: "feedbackSection",
};

export const SUBSCRIPTION_CONSTANTS = {
  WORD_LIMIT_SUMMARIZATION: 1000,
  WORD_LIMIT_BULLETPOINTS: 1000,
  WORD_LIMIT_AI_LOOKUP: 10,
  WORD_LIMIT_QUESTION_GENERATION: 1000,
};

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_TO: "quino-redirect-to-path",
};

export const DOCUMENT_STATUS_COLLECTION_ID = "document-processing-status";

export const COMMON_TABS = {
  OPEN_NEW: "OPEN_NEW",
  ADD_CARD: "ADD_CARD",
  PAGE_SUMMARY: "PAGE_SUMMARY",
};

export const SCREEN_SIZE_CONFIG = "SCREEN_SIZE_CONFIG";
export const THEME_MODE = "THEME_MODE";
export const NOTE_TABS = {
  ...COMMON_TABS,
};

export const DOCUMENT_TABS = {
  ...COMMON_TABS,
};

export const FLASH_CARD_CREATE = "FLASH_CARD_CREATE";

export enum ModelLogFeedback {
  Like = 1,
  NoResponse = 0,
  Dislike = -1,
}

export const QUINO_STATE_TABS = "quino-document-view-tab-state-snapshot";

export const POLLING_STORAGE = "POLLING_STORAGE";

export const POLLING_LIMIT = 30;

export const ANALYTICS_CATEGORY = {
  AI_FUNCTIONS: "ai_functions",
  NOTE_EDITOR: "note_editor",
  KNOWLEDGE_CHECK: "knowledge-check",
  DOCUMENT_READER: "document_reader",
  NOTE: "note",
  PROJECT: "project",
  DOCUMENT: "document",
  LEFT_SIDEBAR: "left_sidebar",
  FEEDBACK: "feedback",
};

export const POLL_DELAY = 5000;
export const POLL_DELAY_SECONDS = 5;
export const WAITING_TIME_TO_POLL_RATIO = 1.5;

export const modalTitle: { [key: string]: string } = {
  simple: "Simple Search",
  semantic: "Semantic Search",
  qa: "Question Answering",
};

export const DOCUMENTATION_PAGE = "https://docs.quino.ai/";

export const CAPTCHA_VERIFICATION_FUNCTION = "validateCaptcha";
export const PROVIDERS = {
  PASSWORD: "password",
  GOOGLE: "google.com",
  FACEBOOK: "facebook.com",
  APPLE: "apple.com",
};

export const HAS_AI_USAGE_NOTIFICATION_BEEN_USED_UP = "HAS_NOTIFICATION_POPUP_APPEARED";
export const NUMBER_OF_AI_FUNCTIONS_UNTIL_NOTIFICATION = 5;

export const DEFAULT_PROCESSING_ERROR_MESSAGE = "-";

export const FIRST_DOCUMENT_OPENING = "FIRST_DOCUMENT_OPENING";

export const DOCUMENT_DATA_INDEXED_DB = "document-data-db";

export const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: "https://www.facebook.com/profile.php?id=100089251221328",
  INSTAGRAM: "https://www.instagram.com/quino.ai/",
  TIKTOK: "https://www.tiktok.com/@quino_ai",
  DISCORD: "https://discord.gg/SVJkpqKYSv",
  YOUTUBE: "https://www.youtube.com/@quinoai",
};

export const SLIDE_EVALUATION_ADD_COUNTER = 10;

export const CHURNED_USER_CALENDAR_LINK = "https://calendly.com/quino-szabolcs-vatany/45min";

export const STRAPI_URL = import.meta.env.VITE_STRAPI_URL as string;
export const STRAPI_API_URL = `${STRAPI_URL}/api`;

export const STRAPI_API_KEY = import.meta.env.VITE_STRAPI_API_KEY as string;

export const EMPTY_SEARCH_TERM = "";

export enum ResourceCardType {
  FOLDER_ELEMENT = "folder_element",
  DOCUMENT_ELEMENT = "document_element",
  FLASHCARD_DECK_ELEMENT = "flashcard_deck_element",
  NOTE_ELEMENT = "note_element",
}

export const estimationStatus = z.enum(["UNESTIMATED", "UNDER_ESTIMATION", "ESTIMATED"]);
export const summarizationStatus = z.enum(["UNSUMMARIZED", "SUMMARIZED", "UNDER_SUMMARIZATION"]);

export type SummarizationStatus = z.infer<typeof summarizationStatus>;

export const MINYMA_STRING = `
███╗   ███╗██╗███╗   ██╗██╗   ██╗███╗   ███╗ █████╗ 
████╗ ████║██║████╗  ██║╚██╗ ██╔╝████╗ ████║██╔══██╗
██╔████╔██║██║██╔██╗ ██║ ╚████╔╝ ██╔████╔██║███████║
██║╚██╔╝██║██║██║╚██╗██║  ╚██╔╝  ██║╚██╔╝██║██╔══██║
██║ ╚═╝ ██║██║██║ ╚████║   ██║   ██║ ╚═╝ ██║██║  ██║
╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝   ╚═╝   ╚═╝     ╚═╝╚═╝  ╚═╝
`;

export const QUINO_STRING = `
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░███████░░░░░░░░░░░░░░█████████████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░███████░░░░░░░░░░░░░░█████████████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░████████████████░░░░░░███████████████████░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░████████████████████░░███████████████████████░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░████████░░░░░░░██████████████████████████████████░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░██████░░░░░░░░░░░░░████████████████████████████████░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░██████░░░░░░░░░░░░░░░░██████████████████████████████░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░█████░░░░░░░░░░░░░░░░░███████████████████████████████░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░█████░░░░░░░░░░░░░░░░░░████████████████████████████████████████░░░░░░░░░░░
░░░░░░░░░░░░░░░█████████░░░░░░░░░░░░░░░░░████████████████████████████████████████████░░░░░░░
░░░░░░░░░░░░░████████████░░░░░░░░░░░░░░░░███████████████████████████████████████████████░░░░
░░░░░░░░░░░████████░███████░░░░░░░░░░░░░░████████████████████████████████████████████████░░░
░░░░░░░░░░███████░░░░████████░░░░░░░░░░░░█████████████████████████████████████████████████░░
░░░░░░░░░██████░░░░░░░░██████░░░░░░░░░░░░██████████████████████████████████████████████████░
░░░░░░░░░█████░░░░░░░░░░░░██░░░░░░░░░░░░░██████████████████████████████████████████████████░
░░░░░░░░██████░░░░░░░░░░░░░░░░░░░░░░░░░░░██████████████████████████████████████████████████░
░░░░░░░░██████░░░░░░░░░░░░░░░░░░░░░░░░░░░██████████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░████████░
░░░░░░░░██████░░░░░░░░░░░░░░░░░████░░░░░░██████████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░███████░
░░░░░░░███████░░░░░░░░░░░░░░░░██████░░░░░██████████████████████████████████████████████████░
░░░░░████████░░░░░░░░░░░░░░░░░█████░░░░░░██████████████████████████████████████████████████░
░░░░███████░░░░░░░░░░░░░░░░░██████░░░░░░░██████████████████████████████████████████████████░
░░░██████░░░░░░░░░░░░░░░░░███████░░░░░░░░██████████████████████████████████████████████████░
░░██████░░░░░░░░░░░░░░██████████░░░░░░░░░██████████████████████████████████████████████████░
░░█████░░░░░░░░░░░░░██████████░░░░░░░░░░░██████████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░███████░
░██████░░░░░░░░░░░░░███████░░░░░░░░░░░░░░██████████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░███████░
░█████░░░░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░██████████████████████████████████████████████████░
░█████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██████████████████████████████████████████████████░
░█████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██████████████████████████████████████████████████░
░██████░░░░░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░██████████████████████████████████████████████████░
░░█████░░░░░░░░░█████░░░░░░░███░░░░░░░░░░██████████░░░░░░░░░░░░░░░░░░██████████████████████░
░░░██████░░░░░░███████░░░░░██████░░░░░░░░██████████░░░░░░░░░░░░░░░░░░██████████████████████░
░░░░██████░░░░██████░░░░░░░░██████░░░░░░░█████████████████████████████████████████████████░░
░░░░░███████░██████░░░░░░░░░░█████░░░░░░░█████████████████████████████████████████████████░░
░░░░░░████████████░░░░░░░░░░░██████░░░░░░███████████████████████████████████████████████░░░░
░░░░░░░░█████████░░░░░░░░░░░░░█████░░░░░░██████████████████████████████████████████████░░░░░
░░░░░░░░░░░██████░░░░░░░░░░░░░░░░░░░░░░░░███████████████████████████████████████████░░░░░░░░
░░░░░░░░░░░░██████░░░░░░░░░░░░░░░░░░░░░░████████████████████████████████████░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░█████░░░░░░░░░░░░░░░░░░░░░░████████████████████████████████████░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░███████░░░░░░░░░░░░░░░░░░█████████████████████████████████████░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░████████░░░░░░░░░░░░░░██████████████████████████████████████░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░█████████░░░░░░░░█████████░░█████████████████████████████░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░██████████████████████░░░░░░█████████████████████████░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░█████████████████░░░░░░░░░░░█████████████████████░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
`;

export enum FlashCardVariant {
  BASIC = "basic_flashcard",
  MULTIPLE = "multiple_choice_card",
  TRUE_FALSE = "true_false_card",
}

export const TAB_STORE_KEY = "tab-state-store";

export const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

export const formImageFile = z
  .instanceof(File)
  .refine((file) => allowedTypes.includes(file.type), {
    message: "Invalid file format. Only JPEG, PNG, and GIF files are allowed.",
  })
  .refine((file) => file.size <= 5 * 1024 * 1024, {
    message: "File size exceeds the maximum allowed (5MB).",
  });

export const viewPathSegments = ["all", "starred", "shared"] as const;
export type ViewOptions = (typeof viewPathSegments)[number];

export const viewPathSegmentToSideBarDisplayNameMap: Record<ViewOptions, string> = {
  all: "All projects",
  starred: "Starred",
  shared: "Shared",
};

export const viewToDisplayAll: Record<ViewOptions, boolean> = {
  all: true,
  starred: false,
  shared: true,
};

export const PROFILE_DATA_LIMITS = {
  PROFILE_IMAGE: {
    HEIGHT: 200,
    WIDTH: 200,
    SIZE: 2 * 1024 * 1024,
  },
};

export enum NoteParagraphInteractionType {
  AI_CREATED = "ai_created",
  HUMAN_CREATED = "human_created",
}

export enum RegistrationTypes {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
  EMAIL = "email+password",
}

export const FILE_SIZE_UPLOAD_LIMIT = {
  FREE: 50e6,
  PAYING: 100e6,
};

export const QUINO_ROOT_DOMAIN = import.meta.env.VITE_QUINO_ROOT_DOMAIN;
