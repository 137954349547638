import { useStripePlanFeatures } from "@/api/subscription";
import { CheckMarkIcon, NotIncludedIcon } from "@/components/icons";
import { type PlanDescription } from "@/types/schemas";

const FeatureList = ({ plan }: { plan: PlanDescription }) => {
  const { data: stripePlanFeatures } = useStripePlanFeatures();
  const newPlanFeatures = stripePlanFeatures?.find((featurePlan) => featurePlan.firebaseRole === plan.firebaseRole);

  return (
    <div className="flex flex-col gap-3">
      {newPlanFeatures?.features.map((feature, index) => (
        <div className="flex items-center gap-2" key={`${plan.name} - ${index}`}>
          <div>{feature.included ? <CheckMarkIcon /> : <NotIncludedIcon />}</div>
          <p className="text-sm text-primary-onBg data-[state=false]:text-tertiary-onBg" data-state={feature.included}>
            {feature.featureDescription}
          </p>
        </div>
      ))}
    </div>
  );
};
export default FeatureList;
