import { ModalKeys } from "@/assets/constants/modal.ts";
import { DemoHandlerButton, SettingsIcon } from "@/components/v3";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const WorkspaceSettingsButton = ({ workspaceId }: { workspaceId: string }) => {
  const [, setOpen] = useModalState(ModalKeys.WORKSPACE_SETTINGS);
  return (
    <DemoHandlerButton
      className="h-8 w-8 hover:bg-secondary-container-hover"
      size="icon"
      variant="icon"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen({ isOpen: true, workspaceId });
      }}
    >
      <SettingsIcon />
    </DemoHandlerButton>
  );
};

export default WorkspaceSettingsButton;
