import { useEffect, useMemo } from "react";

import { NavLink } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import { Button } from "@/components/ui/button.tsx";
import { InfiniteLoading } from "@/pages/loading";
import { isCookieConsentAccepted } from "@/service/cookies.ts";
import { useIsTemporaryPage } from "@/service/hooks/misc.ts";
import { mixpanelTrack, mixpanelTrackLinks } from "@/service/mixpanel";
import { getClaimSummaryRoute } from "@/utils/route.ts";

export const EmptyDocumentView = ({ hidden }: { hidden?: boolean }) => {
  if (hidden) return null;
  return (
    <div className="mx-auto flex h-full max-w-[22.25rem] flex-col items-center justify-center gap-4">
      <section>
        <InfiniteLoading className="h-20 w-20" />
      </section>
      <p className="text-base text-primary">
        Your document is almost ready! It may take a moment due to its size or your internet speed.
      </p>
    </div>
  );
};

export const SignUpDemoButton = ({ documentId }: { documentId: string }) => {
  const { matched: isTemporary } = useIsTemporaryPage();
  const signUpLink = useMemo(() => {
    if (isTemporary) return getClaimSummaryRoute(documentId);
    return `${ROUTES.REGISTER}`;
  }, [documentId, isTemporary]);

  useEffect(() => {
    setTimeout(() => {
      const isAccepted = isCookieConsentAccepted();
      if (!isAccepted) return;
      mixpanelTrackLinks("#sign-up-now-link", "prereg_fds_document_sign_in_clicked", {
        with_button: "subscribe",
      });
    }, 1000);
  }, []);

  return (
    <NavLink reloadDocument className="rounded-xl bg-white" id="sign-up-now-link" to={signUpLink}>
      <Button
        className="min-w-[250px] @lg:min-w-[208px] dark:bg-primary-dark dark:text-white"
        type="button"
        variant="contained"
        onClick={() => mixpanelTrack("sign_up_button")}
      >
        Sign up
      </Button>
    </NavLink>
  );
};
