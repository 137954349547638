import { useCallback } from "react";

import { ModalKeys } from "@/assets/constants/modal.ts";
import { YesNoModal } from "@/components/modals";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const UnShareModal = () => {
  const [{ isOpen }, setOpen] = useModalState(ModalKeys.UN_SHARE_MODAL);

  const onClose = useCallback(() => setOpen({ isOpen: false, projectId: "" }), [setOpen]);
  const onYes = useCallback(() => setOpen({ isOpen: false, projectId: "" }), [setOpen]);

  return (
    <YesNoModal
      description="Lorem ipsum"
      open={isOpen && false}
      title="Do you want to un-share the"
      onCancel={onClose}
      onYes={onYes}
    />
  );
};

export default UnShareModal;
