// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useEffect } from "react";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { useCurrentWorkspace } from "@/api/workspace.ts";
import DocumentProcessingProvider from "@/components/document/document-processing-provider.tsx";
import DocumentView from "@/components/document/document-view";
import DocumentViewHeader from "@/components/document/document-view-header.tsx";
import NoteView from "@/components/document/note-view";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable.tsx";
import { WorkspacePanel } from "@/components/v3";
import { NoteViewLoadingScreen } from "@/pages/loading";
import { useIsTemporaryPage } from "@/service/hooks/misc.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { ViewMode } from "@/types/app.ts";
import { useCurrentProjectId } from "@/utils";
import useAppStateStore from "@/zustand/store";

import "./page.css";

const DocumentPage = () => {
  const mixpanelTrack = useMixpanelTrack();
  const navigate = useNavigate();
  const viewMode = useAppStateStore((state) => state.viewMode);
  const showWorkspaces = useAppStateStore((state) => state.showWorkspaces);
  const currentWorkspace = useCurrentWorkspace();
  const currentProjectId = useCurrentProjectId();
  const { matched: isTemporary } = useIsTemporaryPage();

  useEffect(() => {
    if (!currentProjectId && currentWorkspace.data?.projects && currentWorkspace.data?.projects.length > 0) {
      navigate(currentWorkspace.data.projects[0].id);
    }
  }, [currentProjectId, currentWorkspace?.data?.projects, navigate]);

  return (
    <main className="flex h-screen w-screen flex-col overflow-hidden bg-bg-base px-0 pb-0 font-jakarta">
      <Helmet>
        <title>Quino AI | Document</title>
        <meta content="Interact with your documents using various AI tools!" name="description" />
      </Helmet>
      <DocumentProcessingProvider />
      <DocumentViewHeader />
      <ResizablePanelGroup
        className="relative px-4 pb-4"
        direction="horizontal"
        onLayout={(sizes) => {
          mixpanelTrack("resize_document_view", {
            width_pixel_size_document: sizes[1],
            width_pixel_size_note: sizes[2],
          });
        }}
      >
        <div
          className="relative mr-4 w-[15%] px-4 pb-4 transition-all data-[hidden=true]:mr-0 data-[hidden=true]:w-0 data-[hidden=true]:p-0"
          data-hidden={!showWorkspaces || isTemporary}
        ></div>
        <div
          className="absolute left-2.5 mr-4 h-[calc(100%-1rem)] w-[15%]  rounded-2xl bg-bg-layer1 transition-all data-[hidden=true]:-translate-x-[200%]"
          data-hidden={!showWorkspaces || isTemporary}
        >
          <WorkspacePanel />
        </div>
        {(viewMode === ViewMode.TOOLS || viewMode === ViewMode.SPLIT) && (
          <>
            <ResizablePanel
              className="rounded-2xl border border-stroke-default bg-bg-layer1 shadow-document-view-panel"
              minSize={33}
            >
              <NoteViewLoadingScreen />
              <NoteView />
            </ResizablePanel>
          </>
        )}
        {(viewMode === ViewMode.DOCUMENT || viewMode === ViewMode.SPLIT) && (
          <>
            <ResizableHandle
              hoverHandle
              className="data-[hidden=true]:hidden"
              data-hidden={viewMode !== ViewMode.SPLIT}
            />

            <ResizablePanel
              className="rounded-2xl border border-stroke-default bg-bg-layer1 shadow-document-view-panel"
              minSize={33}
            >
              <DocumentView />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </main>
  );
};

export default DocumentPage;
