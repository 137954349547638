import { OneDriveIcon } from "@/components/icons";
import { Button } from "@/components/ui/button.tsx";
import { toast } from "@/components/ui/use-toast.ts";

const OneDrivePicker = ({ className }: { className?: string }) => {
  return (
    <Button
      className={className}
      size="sm"
      variant="secondary"
      onClick={() => {
        toast({
          title: "Coming soon!",
          variant: "success",
        });
      }}
    >
      <OneDriveIcon className="h-4 w-4" />
      Select from OneDrive
    </Button>
  );
};
export default OneDrivePicker;
