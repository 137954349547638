import { useMemo, useRef, useState } from "react";
import * as React from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { NavLink, useSearchParams } from "react-router-dom";

import { QUERY_PARAMS, ROUTES } from "@/assets/constants/constants";
import AppleLogin from "@/components/button/apple-login";
import GoogleLogin from "@/components/button/google-login";
import { FormPageLayout } from "@/components/layout";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { FormDivider } from "@/components/ui/divider";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, useFormField } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { handleCaptchaVerification, registerWithEmailAndPassword } from "@/firebase";
import { cn } from "@/utils";

import { type RegisterFormSchema, registerFormSchema } from "./form-schema";

const captchaKey = import.meta.env.VITE_CAPTCHA_KEY;

const PasswordFormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, children, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? (
      <div className="rounded-lg bg-primitive-orange-50 p-2">
        <p className="mb-4 text-sm font-bold text-primary-onBg">Your password must contain:</p>
        <div className="flex flex-row text-sm font-normal text-primary-onBg">
          <ul className="ml-5 w-1/2 list-disc space-y-2">
            <li>At least 8 characters</li>
            <li>1 lowercase letter</li>
            <li>1 uppercase letter</li>
          </ul>
          <ul className="ml-5 w-1/2 list-disc space-y-2">
            <li>1 number</li>
            <li>1 special character</li>
            <li>passwords match</li>
          </ul>
        </div>{" "}
      </div>
    ) : (
      children
    );

    if (!body) {
      return null;
    }

    return (
      <div
        className={cn("text-sm font-medium text-red-500 dark:text-red-900", className)}
        id={formMessageId}
        ref={ref}
        {...props}
      >
        {body}
      </div>
    );
  },
);

PasswordFormMessage.displayName = "PasswordFormMessage";

const Register = () => {
  const { toast } = useToast();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [searchParams] = useSearchParams();
  const form = useForm<RegisterFormSchema>({
    mode: "onChange",
    defaultValues: {
      newsletterSubscription: false,
      email: searchParams.get("email") ?? "",
      captchaToken: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      password: "",
    },
    resolver: zodResolver(registerFormSchema),
  });
  const [password = "", confirmPassword = ""] = form.watch(["password", "confirmPassword"]);
  const [rulesVisible, setRulesVisible] = useState(false);

  const {
    hasEightCharacters,
    atLeastOneNumber,
    atLeastOneUppercase,
    atLeastOneLowercase,
    atLeastOneSpecialCharacter,
    matching,
  } = useMemo(
    () => ({
      hasEightCharacters: password.length >= 8,
      atLeastOneNumber: password.match(/\d/g) !== null,
      atLeastOneUppercase: password.match(/.*[A-Z].*/g) !== null,
      atLeastOneLowercase: password.match(/.*[a-z].*/g) !== null,
      atLeastOneSpecialCharacter: password.match(/[^A-Za-z0-9]/g) !== null,
      matching: password === confirmPassword && password !== "",
    }),
    [password, confirmPassword],
  );

  const handleSubmit = async (payload: RegisterFormSchema) => {
    if (captchaRef.current === null) return;
    try {
      const captcha = await handleCaptchaVerification(payload.captchaToken);
      captchaRef.current.reset();
      if (!captcha) {
        toast({
          title: "Captcha Failed. Please try again or contact support for assistance.",
          variant: "destructive",
        });
        return;
      }
      const referralCode = searchParams.get(QUERY_PARAMS.REFERRAL_CODE);
      await registerWithEmailAndPassword(
        payload.firstName,
        payload.lastName,
        payload.email,
        payload.password,
        referralCode ?? undefined,
      );
    } catch (e) {
      const error = e as { code: string };
      if (error.code === "auth/email-already-in-use") {
        toast({
          title: "Registration Failed. Email is already in use!",
          variant: "destructive",
        });
        return;
      }
      toast({
        title: "Registration Failed. Please try again or contact support for assistance.",
        variant: "destructive",
      });
    }
  };

  return (
    <FormPageLayout title="Sign up">
      <Helmet>
        <title>Quino AI | Register</title>
        <meta
          content="Register to the Quino AI platform and gain access to a marvelous set of tools to navigate your studies"
          name="description"
        />
      </Helmet>
      <div className="mt-6 flex w-full flex-col justify-between gap-2 sm:flex-row">
        <GoogleLogin className="w-full" />
        <AppleLogin className="w-full" />
      </div>
      <FormDivider className="my-4">or</FormDivider>
      <Form {...form}>
        <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(handleSubmit)}>
          <section className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel htmlFor="firstName">First name</FormLabel>
                  <FormControl>
                    <Input
                      className={cn(field.value.length > 0 && fieldState.error && "!border-red-500")}
                      id="firstName"
                      placeholder="John"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="pt-1 text-caption-1" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel htmlFor="lastName">Last name</FormLabel>
                  <FormControl>
                    <Input
                      className={cn(field.value.length > 0 && fieldState.error && "!border-red-500")}
                      id="lastName"
                      placeholder="Doe"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem className="w-full">
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormControl>
                  <Input
                    className={cn(field.value.length > 0 && fieldState.error && "!border-red-500")}
                    id="email"
                    placeholder="example@email.com"
                    type="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage className={cn(!field.value.length && "hidden")} />
              </FormItem>
            )}
          />
          <section className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="password"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <FormControl>
                    <Input
                      className={cn(field.value.length > 0 && fieldState.error && "!border-red-500")}
                      id="password"
                      placeholder="Password"
                      type="password"
                      onInputCapture={() => setRulesVisible(true)}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel htmlFor="passwordRep">Repeat password</FormLabel>
                  <FormControl>
                    <Input
                      className={cn(field.value.length > 0 && fieldState.error && "!border-red-500")}
                      id="passwordRep"
                      placeholder="Repeat password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </section>
          {rulesVisible && (
            <section className="rounded-lg bg-primitive-orange-50 p-2">
              <p className="mb-4 text-sm font-bold text-primary-onBg">Your password must contain:</p>
              <div className="flex flex-row text-sm font-normal text-primary-onBg">
                <ul className="ml-5 w-1/2 list-disc space-y-2">
                  <li
                    className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                    data-good={hasEightCharacters}
                  >
                    At least 8 characters
                  </li>
                  <li
                    className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                    data-good={atLeastOneLowercase}
                  >
                    1 lowercase letter
                  </li>
                  <li
                    className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                    data-good={atLeastOneUppercase}
                  >
                    1 uppercase letter
                  </li>
                </ul>
                <ul className="ml-5 w-1/2 list-disc space-y-2">
                  <li
                    className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                    data-good={atLeastOneNumber}
                  >
                    1 number
                  </li>
                  <li
                    className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                    data-good={atLeastOneSpecialCharacter}
                  >
                    1 special character
                  </li>
                  <li
                    className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                    data-good={matching}
                  >
                    passwords match
                  </li>
                </ul>
              </div>{" "}
            </section>
          )}
          <section className="flex flex-col gap-2">
            <FormField
              control={form.control}
              name="newsletterSubscription"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      className="h-4.5 w-4.5 rounded-[3.33px] border-stroke-primary-onBg data-[state=checked]:bg-stroke-primary-onBg"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="ml-2 text-sm font-normal">I want to subscribe to newsletter</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="terms"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      className="h-4.5 w-4.5 rounded-[3.33px] border-stroke-primary-onBg data-[state=checked]:bg-stroke-primary-onBg"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="ml-2 text-sm font-normal">
                    I agree with the{" "}
                    <NavLink className="font-bold text-text-link-active-onBg" to={ROUTES.POLICY}>
                      Terms & Conditions
                    </NavLink>{" "}
                    and the{" "}
                    <NavLink className="font-bold text-text-link-active-onBg" to={ROUTES.POLICY}>
                      Privacy Policy
                    </NavLink>
                  </FormLabel>
                </FormItem>
              )}
            />
          </section>
          <ReCAPTCHA
            className="min-h-[5rem] [&>div>div]:flex [&>div>div]:!w-full [&>div>div]:justify-center [&>div]:flex [&>div]:justify-center"
            ref={captchaRef}
            sitekey={captchaKey}
            size="normal"
            onChange={(token) =>
              form.setValue("captchaToken", token ? token : "", {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
          />
          <Button disabled={!form.formState.isValid} type="submit">
            Sign up
          </Button>
        </form>
      </Form>
      <Helmet>
        <title>Quino | Register</title>
      </Helmet>
    </FormPageLayout>
  );
};

export default Register;
