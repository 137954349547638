import { type MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Draggable } from "@hello-pangea/dnd";
import { type MDXEditorMethods } from "@mdxeditor/editor";
import { GripHorizontalIcon } from "lucide-react";
import { useDebounce, useOnClickOutside } from "usehooks-ts";

import { useDeleteParagraphNote, useNote, useNoteParagraph, useUpdateParagraphNote } from "@/api/note.ts";
import { NoteParagraphInteractionType } from "@/assets/constants/constants.ts";
import { FunnyStuffType } from "@/assets/constants/funny-stuff.ts";
import FunnyStuff from "@/components/funny-stuff";
import { CloseIcon } from "@/components/icons/common";
import { useDragAndDropContext } from "@/components/note/drag-and-drop-context.tsx";
import { ParagraphDivider } from "@/components/note/paragraph-divider.tsx";
import QuinoMdxEditor from "@/components/note/quino-mdx-editor";
import { Button } from "@/components/ui/button.tsx";
import { DeleteIcon, QALinkingIcon } from "@/components/v3";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { LoadingSpinner } from "@/pages/loading";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useIsMyNote, useRemoveAd } from "@/service/hooks/notes.ts";
import { useCurrentWorkspaceId, useNavigateToDocumentPage } from "@/service/hooks/react-router.ts";
// import { useIsFreeUser } from "@/service/hooks/settings.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { useBucketImageUrl } from "@/service/note";
import { type ImageNoteParagraph, type MarkdownNoteParagraph } from "@/types/schemas";
import { useParagraphEditor } from "@/zustand/hooks.ts";
import useAppStateStore from "@/zustand/store.ts";

const MarkdownParagraphComponent = ({ noteParagraph }: { noteParagraph: MarkdownNoteParagraph }) => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const { data: note } = useNote(currentWorkspaceId, noteParagraph.noteId);
  const [editedId, setEditedId] = useParagraphEditor();
  const userId = useFirebaseUserId();
  const { isDragActive } = useDragAndDropContext();
  const { mutate: updateParagraphNote } = useUpdateParagraphNote();
  const { mutate: deleteParagraphNote } = useDeleteParagraphNote();
  const [state, setState] = useState<{
    mode: "view" | "edit";
    markdown: string;
  }>({
    mode: "view",
    markdown: noteParagraph.markdownText,
  });
  const isMyNote = useIsMyNote({ noteId: noteParagraph.noteId, workspaceId: currentWorkspaceId });

  const debouncedValue = useDebounce<string>(state.markdown, 500);
  const refEditor = useRef<MDXEditorMethods>(null);
  useEffect(() => {
    if (userId !== note?.userId) return;

    updateParagraphNote({
      paragraphId: noteParagraph.id,
      payload: {
        markdownText: debouncedValue,
      },
    });
  }, [debouncedValue, note?.userId, noteParagraph.id, updateParagraphNote, userId]);

  useEffect(() => {
    if (editedId === noteParagraph.id) {
      setState((state) => ({ ...state, mode: isMyNote ? "edit" : "view" }));
      setTimeout(() => refEditor?.current?.focus(() => {}, { defaultSelection: "rootStart" }));

      return;
    } else if (state.mode === "edit") {
      setState((state) => ({ ...state, mode: "view" }));
      if (!state.markdown) {
        deleteParagraphNote({
          paragraphId: noteParagraph.id,
          noteId: noteParagraph.noteId,
        });
      } else
        updateParagraphNote({
          paragraphId: noteParagraph.id,
          payload: {
            markdownText: state.markdown,
          },
        });
    }
  }, [
    editedId,
    noteParagraph.id,
    state.markdown,
    state.mode,
    updateParagraphNote,
    deleteParagraphNote,
    noteParagraph.noteId,
    isMyNote,
  ]);

  const changeMarkdown = useCallback(
    (value: string | undefined) => {
      setState((prev) => ({ ...prev, markdown: value ?? "" }));
    },
    [setState],
  );

  const discardEditChanges = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setState((prevState) => ({
        ...prevState,
        markdown: noteParagraph?.markdownText ?? "",
      }));
      setEditedId({ editedParagraphId: "" });
    },
    [noteParagraph?.markdownText, setEditedId, setState],
  );

  const saveEditChanges = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (editedId === noteParagraph.id)
        updateParagraphNote({
          paragraphId: noteParagraph.id,
          payload: {
            markdownText: state.markdown,
          },
        });
      setEditedId({ editedParagraphId: "" });
    },
    [noteParagraph.id, editedId, setEditedId, state.markdown, updateParagraphNote],
  );

  const navigateToDocumentPage = useNavigateToDocumentPage();
  const hasLink = useMemo(() => (noteParagraph ? noteParagraph.docPageNumber >= 0 : false), [noteParagraph]);

  const actionBar = useMemo(
    () => (
      <div className="rounded-xl border-stroke-default bg-white shadow-elevation-1 dark:bg-primary-dark">
        <div
          className="gap flex flex-row items-center gap-4 overflow-hidden rounded-xl  p-2 dark:bg-primary-dark-light"
          onClick={(e) => e.stopPropagation()}
        >
          {state.mode === "view" && (
            <>
              <section className="flex w-full flex-row gap-3">
                <Button
                  className="data-[active=false]:hidden"
                  data-active={hasLink}
                  size="xs"
                  variant="ghost"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (noteParagraph?.docPageNumber === null || noteParagraph?.docPageNumber === undefined) return;
                    navigateToDocumentPage(
                      noteParagraph?.documentId ?? "",
                      noteParagraph?.docPageNumber ?? 0,
                      noteParagraph?.docPageTextOrigin ?? "",
                    );
                  }}
                >
                  <QALinkingIcon className="fill-tertiary-text-default" /> Show sources
                </Button>
                <Button
                  size="xs"
                  variant="ghost"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteParagraphNote({
                      paragraphId: noteParagraph?.id ?? "",
                      noteId: noteParagraph.noteId,
                    });
                  }}
                >
                  <DeleteIcon className="fill-tertiary-text-default" /> Delete
                </Button>
              </section>
              <div
                className="h-5 w-px self-stretch bg-white data-[model='']:hidden"
                data-model={noteParagraph?.modelLogId ?? ""}
              />
            </>
          )}
        </div>
      </div>
    ),
    [
      state.mode,
      hasLink,
      noteParagraph?.modelLogId,
      noteParagraph?.docPageNumber,
      noteParagraph?.documentId,
      noteParagraph?.docPageTextOrigin,
      noteParagraph?.id,
      noteParagraph.noteId,
      navigateToDocumentPage,
      deleteParagraphNote,
    ],
  );
  return (
    <section className="rounded-lg border border-transparent group-data-[edit=true]:border-stroke-secondary-onBg group-data-[is-dragging=true]:bg-white group-hover:group-data-[hidden=false]:border-stroke-active">
      <section className="group relative p-4">
        <section
          className="pointer-events-none absolute left-1/2 top-1 z-[2000] -translate-x-1/2 -translate-y-3/4 bg-transparent pb-2 opacity-0 transition-all group-hover:pointer-events-auto group-hover:block group-hover:-translate-y-full group-hover:opacity-100 data-[hidden=true]:pointer-events-none data-[hidden=true]:opacity-0 group-hover:data-[hidden=true]:hidden"
          data-hidden={state.mode === "edit" || isDragActive || !isMyNote}
        >
          {actionBar}
        </section>
        <QuinoMdxEditor
          cancelEdit={discardEditChanges}
          editorRef={refEditor}
          markdown={state.markdown}
          readOnly={isDragActive || !isMyNote}
          saveEdit={saveEditChanges}
          showToolbar={state.mode === "edit"}
          wrapperId={`${noteParagraph.id}-paragraph`}
          onChange={changeMarkdown}
        />
      </section>
    </section>
  );
};

const ImageParagraphComponent = ({ noteParagraph }: { noteParagraph: ImageNoteParagraph & { userId: string } }) => {
  const { isDragActive } = useDragAndDropContext();
  const { mutate: deleteParagraphNote } = useDeleteParagraphNote();

  const imageUrl = useBucketImageUrl(
    "NOTE",
    `${noteParagraph.userId}/${noteParagraph.noteId}/${noteParagraph.imageCuid}`,
  );

  const imageContent = useMemo(() => {
    if (!imageUrl) return <LoadingSpinner />;
    return (
      <section>
        <img alt="image-paragraph" className="w -full  h-full" src={imageUrl} />
      </section>
    );
  }, [imageUrl]);

  return (
    <section className="group relative rounded-lg">
      <section
        className="pointer-events-none absolute left-1/2 top-1 z-[2000] -translate-x-1/2 -translate-y-3/4 bg-transparent pb-2 opacity-0 transition-all group-hover:pointer-events-auto group-hover:block group-hover:-translate-y-full group-hover:opacity-100 data-[hidden=true]:pointer-events-none data-[hidden=true]:opacity-0 group-hover:data-[hidden=true]:hidden"
        data-hidden={isDragActive}
      >
        <div className="gap flex flex-row items-center gap-4 overflow-hidden rounded-xl border border-stroke-default bg-primary/75 bg-white p-1.5 shadow-elevation-1">
          <Button
            size="xs"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              deleteParagraphNote({
                paragraphId: noteParagraph.id,
                noteId: noteParagraph.noteId,
              });
            }}
          >
            <DeleteIcon className="fill-secondary-text-default" /> Delete
          </Button>
        </div>
      </section>
      <section
        className={`flex select-none flex-row justify-center overflow-hidden rounded-lg border border-transparent group-data-[edit=true]:border-stroke-secondary-onBg group-hover:group-data-[hidden=false]:border-stroke-active  export-${noteParagraph.id}-paragraph`}
      >
        {imageContent}
      </section>
    </section>
  );
};

export const NoteParagraphComponent = ({
  noteId,
  noteParagraphId,
  index,
}: {
  noteId: string;
  noteParagraphId: string;
  index: number;
}) => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  const isFreeUser = false; //  useIsFreeUser(); needs review
  const [showCloseButton, setShowCloseButton] = useState(false);
  const { data: note } = useNote(currentWorkspaceId, noteId);
  const { data: noteParagraph } = useNoteParagraph(currentWorkspaceId, noteId, noteParagraphId);
  const content = useMemo(() => {
    if (noteParagraph?.noteParagraphType === "IMAGE")
      return <ImageParagraphComponent noteParagraph={{ ...noteParagraph, userId: note?.userId ?? "" }} />;
    if (noteParagraph?.noteParagraphType === "TEXT")
      return <MarkdownParagraphComponent noteParagraph={noteParagraph} />;
    return <LoadingSpinner />;
  }, [note?.userId, noteParagraph]);
  const isMyNote = useIsMyNote({ noteId: noteId, workspaceId: currentWorkspaceId });

  const isParagraphSelectOpen = useAppStateStore((state) => state.isParagraphSelectOpen);
  const [editedId, setEditedId] = useParagraphEditor();

  const ref = useRef(null);
  const handleClickOutside = () => {
    // Your custom logic here
    if (editedId === noteParagraphId && !isParagraphSelectOpen) setEditedId({ editedParagraphId: "" });
  };
  useOnClickOutside(ref, handleClickOutside);

  const removeAd = useRemoveAd({
    noteId,
    paragraphId: noteParagraphId,
  });

  useEffect(() => {
    if (noteParagraph?.hasAd) {
      setTimeout(() => {
        setShowCloseButton(true);
      }, 1500);
    } else {
      setShowCloseButton(false);
    }
  }, [noteParagraph?.hasAd]);

  return (
    <Draggable draggableId={noteParagraphId} index={index} key={noteParagraph?.id}>
      {(provided, snapshot) => (
        <section
          className="mt-3 flex w-[37.5rem] max-w-full flex-row justify-center rounded-lg shadow-primary transition-shadow data-[is-dragging=true]:shadow-subscription-plan dark:border-white dark:bg-primary-dark-deep dark:shadow-none"
          ref={provided.innerRef}
          {...provided.draggableProps}
          data-is-dragging={snapshot.isDragging}
          data-is-drop={snapshot.isDropAnimating}
        >
          <section className="relative flex w-full  flex-col rounded-lg" ref={ref}>
            <div className="sticky -top-[1px] z-[1000]" id={`${noteParagraphId}-paragraph`} />
            {noteParagraph?.hasAd && isFreeUser && (
              <section className="bg-red/75 absolute z-[1000] flex h-full w-full flex-col items-center justify-center rounded-lg bg-primary/25 p-4 backdrop-blur-sm">
                <section className="relative flex h-full w-full flex-col items-center justify-center">
                  {/*done*/}
                  <FunnyStuff adId={FunnyStuffType.quino_note_300x250} />
                  {showCloseButton && (
                    <Button
                      className="absolute right-2 top-2"
                      id="close-ad-on-document-page"
                      size="icon"
                      variant="icon"
                      onClick={removeAd}
                    >
                      <CloseIcon />
                    </Button>
                  )}
                </section>
              </section>
            )}
            <div className="rounded-lg">
              <article
                className="group relative [&_[data-radix-popper-content-wrapper]]:!z-10"
                data-edit={editedId === noteParagraphId}
                data-hidden={snapshot.isDragging}
                data-is-dragging={snapshot.isDragging}
                onClick={() => setEditedId({ editedParagraphId: noteParagraphId })}
              >
                <section
                  className="reorder-handle invisible absolute left-1/2 top-0 z-50 flex -translate-x-1/2 justify-center group-hover:visible data-[hidden=true]:invisible group-hover:data-[hidden=true]:invisible"
                  data-hidden={editedId === noteParagraphId || snapshot.isDragging || !isMyNote}
                  {...provided.dragHandleProps}
                  onDragStart={() => {
                    if (isDemo)
                      mixpanelTrack("note_paragraph_grabbed", {
                        paragraph_type: noteParagraph?.modelLogId
                          ? NoteParagraphInteractionType.AI_CREATED
                          : NoteParagraphInteractionType.HUMAN_CREATED,
                      });
                  }}
                >
                  <GripHorizontalIcon
                    className="h-4 w-4 cursor-grab dark:stroke-white"
                    onClick={(e) => e.stopPropagation()}
                  />
                </section>
                {content}
              </article>
            </div>
            {isMyNote && (
              <ParagraphDivider
                className="visible absolute -bottom-1.5 translate-y-1/2"
                position={(noteParagraph?.position ?? 0) + 1}
              />
            )}
          </section>
        </section>
      )}
    </Draggable>
  );
};
