import { ROUTES } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button";
import {
  DemoHandlerButton,
  FeedbackIcon,
  HomeIcon,
  HomeFilledIcon,
  Challenges,
  LibraryIconFilledIcon,
  LibraryIcon,
  Subscription,
  SubscriptionFilledIcon,
} from "@/components/v3";
import { LogoutButton } from "@/components/v3";
import DemoHandlerNavlink from "@/components/v3/demo/demo-handler-navlink.tsx";
import { cn } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice.ts";
import useAppStateStore from "@/zustand/store.ts";

const routes = [
  {
    id: "home",
    label: "Home",
    route: ROUTES.HOME,
    activeIcon: <HomeFilledIcon />,
    icon: <HomeIcon />,
  },
  {
    id: "library",
    label: "Library",
    route: ROUTES.WORKSPACES,
    activeIcon: <LibraryIconFilledIcon />,
    icon: <LibraryIcon />,
  },
  {
    id: "challenges",
    label: "Challenges",
    route: ROUTES.CHALLENGES,
    activeIcon: <Challenges />,
    icon: <Challenges />,
    disabled: true,
  },
  {
    id: "subscriptions",
    label: "Subscriptions",
    route: ROUTES.SUBSCRIPTION,
    activeIcon: <SubscriptionFilledIcon />,
    icon: <Subscription />,
  },
];

const WorkspaceSidebar = () => {
  const [, setFeedbkacModalOpen] = useModalState(ModalKeys.FEEDBACK);
  const setDrawerOpen = useAppStateStore((state) => state.setDrawerOpen);

  return (
    <aside className="sticky top-20">
      <nav className=" flex flex-col gap-2 p-4 pt-6">
        {routes
          .filter((item) => item.disabled !== true)
          .map((route) => (
            <DemoHandlerNavlink
              className={({ isActive }) =>
                cn(
                  "flex w-full justify-start rounded-lg fill-text-default text-default",
                  isActive
                    ? "bg-container-tertiary fill-text-link-active-onBg text-text-link-active-onBg [&_[data-active=true]]:inline"
                    : "[&_[data-inactive=true]]:inline",
                )
              }
              key={route.id}
              to={route.route}
              onClick={() => setDrawerOpen(false)}
            >
              <Button className="w-full justify-start gap-2 p-3 text-inherit" size="md" variant="ghost">
                <span className="hidden h-6 w-6 [&>svg]:fill-inherit" data-inactive="true">
                  {route.icon}
                </span>
                <span className="hidden h-6 w-6 [&>svg]:fill-inherit" data-active="true">
                  {route.activeIcon}
                </span>
                {route.label}
              </Button>
            </DemoHandlerNavlink>
          ))}
        <DemoHandlerButton
          className="gap-2 p-3"
          size="md"
          variant="ghost"
          onClick={() => {
            setDrawerOpen(false);
            setFeedbkacModalOpen(true);
          }}
        >
          <span className="h-6 w-6">
            <FeedbackIcon />
          </span>
          Send feedback
        </DemoHandlerButton>
        <LogoutButton />
      </nav>
    </aside>
  );
};

export default WorkspaceSidebar;
