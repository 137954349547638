import React from "react";

import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import { ACCOUNT_ROUTES, ROUTE_PATHS, ROUTES } from "@/assets/constants/constants";
import CacheBuster from "@/cache-buster";
import { PersonalAccount } from "@/components/account";
import CookiePolicy from "@/components/account/cookie-policy";
import PrivacyPolicy from "@/components/account/privacy-policy";
import TermsAndConditions from "@/components/account/terms-and-conditions";
import { PreservedQueryNavigation } from "@/components/button/navigation-utils.tsx";
import { AppWrapper } from "@/components/guards";
import ActiveUseCaseGuard from "@/components/guards/active-use-case-guard";
import AlreadyAuthGuard from "@/components/guards/already-auth-guard";
import Authenticated from "@/components/guards/authenticated";
import MobileRoute from "@/components/guards/mobile";
import MobileDemoRoute from "@/components/guards/mobile-demo.tsx";
import { TooltipProvider } from "@/components/ui/tooltip";
import { WorkspaceDetails, MainLayout, WorkspaceProjectsView } from "@/components/v3";
import "@/index.css";
import Account from "@/pages/account";
import DocumentPage from "@/pages/documents";
import { LoadingBase } from "@/pages/loading";
import LoginPlain from "@/pages/login/plain.tsx";
import Register from "@/pages/register";
import Reset from "@/pages/reset";
import NewPassword from "@/pages/reset/new-password";
import Subscription from "@/pages/subscription";
import Home from "@/pages/v3/home";
import Workspaces from "@/pages/v3/workspace";
import { queryClient } from "@/service/react-query.ts";

import { version } from "../package.json";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const mainContent = [
  {
    element: <MainLayout />,
    children: [
      {
        path: ROUTE_PATHS.HOME,
        element: <Home />,
      },
      {
        path: ROUTE_PATHS.WORKSPACES,
        element: <Workspaces />,
        children: [
          {
            path: ":workspaceId",
            element: <WorkspaceDetails />,
          },
          {
            path: ":workspaceId/:projectId",
            element: <WorkspaceProjectsView />,
          },
        ],
      },
      {
        path: "subscription",
        element: <Subscription />,
      },
      {
        path: "account",
        element: <Account />,
        children: [
          {
            index: true,
            element: <Navigate to={ACCOUNT_ROUTES.PERSONAL_AND_ACCOUNT_DATA} />,
          },
          {
            path: ACCOUNT_ROUTES.PERSONAL_AND_ACCOUNT_DATA,
            element: <PersonalAccount />,
          },
          {
            path: ACCOUNT_ROUTES.TERMS_AND_CONDITIONS,
            element: <TermsAndConditions />,
          },
          {
            path: ACCOUNT_ROUTES.PRIVACY_POLICY,
            element: <PrivacyPolicy />,
          },
          {
            path: ACCOUNT_ROUTES.COOKIE_POLICY,
            element: <CookiePolicy />,
          },
        ],
      },
    ],
  },
  {
    path: `documents/:workspaceId`,
    element: <DocumentPage />,
  },
  {
    path: `documents/:workspaceId/:projectId`,
    element: <DocumentPage />,
  },
];

const router = sentryCreateBrowserRouter([
  {
    element: <AppWrapper />,
    children: [
      {
        element: <AlreadyAuthGuard />,
        children: [
          {
            path: ROUTES.LOGIN,
            element: <LoginPlain />,
          },
          {
            path: ROUTES.REGISTER,
            element: <Register />,
          },
          {
            path: ROUTES.RESET,
            children: [
              {
                index: true,
                element: <Reset />,
              },
              {
                path: "new-password",
                element: <NewPassword />,
              },
            ],
          },
          ...[ROUTES.DEMO, ROUTES.TEMPORARY].map((route) => ({
            path: route,
            element: <MobileDemoRoute />,
            children: mainContent,
          })),
        ],
      },
      {
        element: <MobileRoute />,
        children: [
          {
            element: <Authenticated />,
            children: [
              {
                element: <ActiveUseCaseGuard />,
                children: mainContent,
              },
            ],
          },
        ],
      },
      { path: ROUTES.POLICY, element: <PrivacyPolicy /> },
      {
        path: "*",
        element: <PreservedQueryNavigation to={ROUTES.HOME} />,
      },
    ],
  },
]);

const isProduction = process.env.NODE_ENV === "production";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        loadingComponent={<LoadingBase />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        onCacheClear={() => {}}
      >
        <TooltipProvider>
          <QueryClientProvider client={queryClient}>
            {import.meta.env.VITE_REACT_APP_SHOW_DEV_TOOLS ? <ReactQueryDevtools initialIsOpen={false} /> : null}
            <RouterProvider router={router} />
          </QueryClientProvider>
        </TooltipProvider>
      </CacheBuster>
    </CookiesProvider>
  </React.StrictMode>,
);
