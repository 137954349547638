import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Professional = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <path
      d="M13.1431 47.1536C9.99601 46.6359 7.56578 43.3825 7.95998 40.2176C8.04454 39.5373 8.2592 38.8426 8.73666 38.3509C9.21412 37.8592 10.0051 37.6263 10.6127 37.9437C11.215 38.2585 11.5676 39.0377 12.2428 39.1145C13.0845 39.2094 13.5516 38.1844 13.7506 37.361C14.3907 34.719 14.9983 31.7908 13.7116 29.3973C13.0429 28.1537 11.8785 27.0909 11.7744 25.6821C11.6483 23.9767 13.2276 22.5393 14.8955 22.1555C16.5633 21.7718 18.2962 22.1529 19.9849 22.4222C21.6749 22.6915 23.4989 22.8281 25.008 22.0215C26.3207 21.3204 27.2912 19.9532 28.7483 19.6462C30.3355 19.3119 31.8459 20.3526 33.4097 20.7844C37.6821 21.9682 42.1354 18.5522 46.4832 19.4134C46.9633 19.5083 47.4642 19.6761 47.7621 20.0651C48.2656 20.7259 47.9234 21.7471 47.2638 22.2518C46.6042 22.7565 45.7391 22.8814 44.9143 22.9894C42.0222 23.3679 39.1314 23.7464 36.2393 24.1237C34.4986 24.3513 32.4938 24.7559 31.6898 26.3169C31.1252 27.4122 31.3594 28.7338 31.6573 29.9293C32.1152 31.7713 32.6942 33.6042 32.7696 35.5008C32.8451 37.3974 32.3351 39.4202 30.9326 40.6989C30.2561 41.3155 29.3481 41.8163 29.1347 42.7061C28.92 43.605 29.525 44.5181 29.4417 45.4378C29.3194 46.7855 27.8454 47.5504 26.5301 47.8704C24.2287 48.4298 21.7855 48.3972 19.4997 47.7767C17.3999 47.207 15.144 46.1572 13.1665 47.0665"
      fill="#E2D7F4"
    />
    <path
      d="M9.79971 32.0598C8.88642 30.2503 8.35042 28.2184 8.49483 26.1969C8.63924 24.1754 9.50309 22.1721 11.0356 20.8453C12.4615 19.6108 14.3232 19.041 16.0834 18.3672C17.845 17.6947 19.652 16.8075 20.6525 15.2088C21.6529 13.6101 21.4396 11.1307 19.7743 10.2474C18.1377 9.37975 16.1029 10.3918 14.2894 10.0094C13.1302 9.7648 12.174 8.97389 11.1098 8.45225C10.0456 7.93062 8.62883 7.73289 7.78189 8.56152C6.91154 9.41227 7.11449 10.8692 7.60366 11.9827C8.09413 13.0962 8.82007 14.1733 8.81877 15.3909C8.81617 17.1041 7.41111 18.427 6.3274 19.7539C4.84428 21.5686 3.81911 23.7566 3.37548 26.059C3.06454 27.6695 3.09056 29.4984 4.15216 30.7498C5.35426 32.1664 7.42933 32.3642 9.28452 32.4591"
      fill="#E2D7F4"
    />
    <path
      d="M29.4882 16.5815C30.3702 15.8843 31.6634 16.0299 32.6951 16.4761C33.7268 16.9223 34.6427 17.6209 35.7069 17.9851C37.1379 18.4755 38.7837 18.2934 40.0729 17.5038C41.3622 16.7142 42.2716 15.3301 42.4849 13.8328C43.5413 14.0709 44.6316 14.3102 45.6971 14.1151C46.7626 13.92 47.8033 13.172 47.9946 12.1066C48.1702 11.1284 47.5965 10.1528 46.8497 9.49716C45.0999 7.95958 42.2768 7.81909 40.3826 9.17455C39.6683 9.68578 39.0946 10.3687 38.4168 10.9255C37.7377 11.4822 36.8855 11.9258 36.0139 11.8139C35.137 11.7021 34.4072 11.0543 33.5368 10.8943C31.8859 10.5899 30.4964 12.065 29.4674 13.3919C29.0992 13.8667 28.718 14.3727 28.6464 14.9672C28.5749 15.5616 28.9483 16.2563 29.5467 16.3005"
      fill="#E2D7F4"
    />
    <path
      d="M48.707 20.3018C49.7374 20.7467 50.0119 22.175 49.5695 23.2065C49.1272 24.2381 48.1866 24.9562 47.2837 25.6235C46.027 26.5523 44.7481 27.4889 43.7711 28.7091C42.794 29.9292 42.1383 31.4902 42.3335 33.0408C42.5221 34.5342 43.4705 35.8805 43.4953 37.3856C43.5226 39.0208 42.3803 40.5388 40.8972 41.2309C39.4141 41.9229 37.6669 41.8774 36.1031 41.3974C35.3316 41.1606 34.4938 40.825 33.7509 41.1398C32.8832 41.508 32.5879 42.5694 32.4252 43.4969C32.2236 44.6547 32.0675 45.8696 32.4356 46.9858C32.8038 48.1019 33.8459 49.0801 35.0181 48.9943C36.2891 48.9006 37.1361 47.7025 38.1014 46.87C40.0191 45.214 42.9879 44.9096 45.2021 46.1428C45.8956 46.5292 46.5148 47.0469 47.2421 47.3643C47.9693 47.6817 48.8774 47.7585 49.5097 47.2785C50.3748 46.6215 50.3163 45.3116 50.0587 44.2553C49.8011 43.199 49.442 42.0348 49.9572 41.0774C51.0058 39.13 54.5341 39.917 55.5761 37.9671C56.1629 36.8679 55.541 35.45 54.5588 34.6838C53.5765 33.9176 52.3237 33.6158 51.1437 33.2151C49.9637 32.8145 48.7473 32.2304 48.1333 31.1468C47.5114 30.0476 47.6623 28.6297 48.2686 27.5214C48.8748 26.4131 49.8753 25.5727 50.9317 24.8794C52.0622 24.1379 53.4139 23.3496 53.6065 22.0111C53.7977 20.6803 52.6776 19.4913 51.4586 18.9268C50.8679 18.6536 50.1823 18.4715 49.5695 18.6887C48.9568 18.906 48.5092 19.6592 48.8124 20.2367"
      fill="#E2D7F4"
    />
    <path
      d="M45.7326 26.4871C46.2469 19.8388 46.7612 13.1959 47.2755 6.54761C41.6022 6.68154 34.8414 7.25476 27.3359 8.74942C19.5947 10.2869 12.9465 12.4245 7.54639 14.5406C11.2911 20.0478 15.0411 25.555 18.7858 31.0622L9.07319 45.7034C9.5982 47.4124 10.1232 49.1214 10.6482 50.8303C16.1233 51.0821 22.9216 50.9428 30.6199 49.7374C38.4789 48.5053 45.1165 46.5017 50.3183 44.5409C48.7915 38.5248 47.2647 32.5033 45.7379 26.4871H45.7326Z"
      fill="#A788DD"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M45.7331 26.4871C40.5312 26.8674 34.799 27.5906 28.6704 28.8603C25.6864 29.4764 22.8524 30.1728 20.1792 30.9121"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M9.92676 45.8627C15.4072 45.9269 21.5787 45.6859 28.302 44.9037C36.2628 43.9823 43.3397 42.493 49.388 40.8644"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M26.6097 29.2937C26.0418 31.9027 25.2275 34.8598 24.0489 38.042C22.9936 40.8921 21.831 43.41 20.6953 45.585"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M32.5003 28.3512C32.0289 30.9709 31.4074 33.8102 30.5824 36.8102C29.8164 39.6013 28.9699 42.1728 28.1021 44.5246"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M38.1151 27.2579C37.9543 29.808 37.7776 32.3741 37.59 34.9616C37.3811 37.8813 37.1561 40.7688 36.915 43.6349"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M41.9233 31.0872C35.8161 32.6836 29.6499 32.9086 29.6499 32.9086"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M16.8091 37.4417C20.902 37.3452 24.2556 37.1417 26.6128 36.9702C31.5253 36.6113 37.5254 35.9899 37.5254 35.9899"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M16.7505 41.4387C21.363 41.4387 26.4738 41.203 31.981 40.5708C35.9936 40.1101 39.7436 39.494 43.199 38.7922"
      stroke="#572E9E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);
export default Professional;
