import { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DragDropContext, Droppable, type DropResult } from "@hello-pangea/dnd";
import { useIsMutating } from "@tanstack/react-query";
import { useDebounce } from "usehooks-ts";

import { useNote, useNoteOrdering, useUpdateOrdering } from "@/api/note";
import { Spinner } from "@/components/icons/common";
import DragAndDropProvider, { useDragAndDropContext } from "@/components/note/drag-and-drop-context.tsx";
import WelcomeMessage from "@/components/note/note-editor/welcome-message.tsx";
import { NoteParagraphComponent } from "@/components/note/note-paragraph.tsx";
import { ParagraphDivider } from "@/components/note/paragraph-divider.tsx";
import { useIsMyNote } from "@/service/hooks/notes.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useAILoading } from "@/zustand/hooks";

import "./index.css";

const NoteContentWrapper = ({ children }: { children?: ReactNode }) => (
  <section className="z-20 mt-0 flex-1 overflow-y-auto p-4 pt-6">{children}</section>
);

const NoteTabContentInternal = ({ id }: { id: string }) => {
  const workspaceId = useCurrentWorkspaceId();
  const { data, isLoading, isError } = useNote(workspaceId, id);
  const { data: dataPositions, isSuccess } = useNoteOrdering(workspaceId, id);
  const [aiLoading] = useAILoading();
  const { mutate: updateOrdering } = useUpdateOrdering();
  const [orderingCache, setOrderingCache] = useState<string[] | undefined>(undefined);
  const debouncedCache = useDebounce(orderingCache, 1000);
  const paragraphs = useMemo(
    () =>
      orderingCache?.map((noteId, index) => (
        <NoteParagraphComponent index={index} key={noteId} noteId={id} noteParagraphId={noteId} />
      )),
    [id, orderingCache],
  );
  const isParagraphAddMutation = useIsMutating({ mutationKey: ["CREATE_PARAGRAPH"] });
  const noParagraphs = useMemo(() => data?.noteParagraphs.length === 0, [data?.noteParagraphs.length]);
  const isMyNote = useIsMyNote({ noteId: id, workspaceId: workspaceId });

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    spinnerRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [aiLoading]);

  useEffect(() => {
    if (paragraphs && !scrolled) {
      spinnerRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setScrolled(true);
    }
  }, [id, scrolled, paragraphs, setScrolled]);

  useEffect(() => {
    if (isSuccess && orderingCache?.length !== dataPositions?.length) {
      setOrderingCache(dataPositions);
    }
  }, [isSuccess, orderingCache, dataPositions, setOrderingCache]);

  useEffect(() => {
    if (debouncedCache) {
      updateOrdering({ noteId: id, ordering: debouncedCache });
    }
  }, [id, debouncedCache, updateOrdering]);

  const { setIsDragActive } = useDragAndDropContext();
  const setOrdering = useCallback(
    (result: DropResult) => {
      setIsDragActive(false);
      const { destination, source, draggableId } = result;
      if (!destination) return;
      if (destination.droppableId === source.droppableId && destination.index === source.index) return;
      setOrderingCache((oldIds) => {
        if (!oldIds) return undefined;
        const newIds = Array.from(oldIds);
        newIds.splice(source.index, 1);
        newIds.splice(destination.index, 0, draggableId);
        return newIds;
      });
    },
    [setOrderingCache, setIsDragActive],
  );

  const spinnerRef = useRef<HTMLDivElement | null>(null);
  if (isLoading || orderingCache === undefined)
    return (
      <NoteContentWrapper>
        <section className="flex flex-row justify-center py-4">
          <Spinner />
        </section>
      </NoteContentWrapper>
    );
  if (isError) return <NoteContentWrapper>error</NoteContentWrapper>;
  return (
    <NoteContentWrapper>
      {paragraphs?.length === 0 && <WelcomeMessage />}
      <div className="flex flex-col items-center px-4">
        {isMyNote && <ParagraphDivider visible={noParagraphs} />}
        <DragDropContext
          onDragEnd={setOrdering}
          onDragStart={() => {
            setIsDragActive(true);
          }}
        >
          <Droppable droppableId="note-paragraph">
            {(provided) => (
              <section
                className="flex w-full flex-col items-center"
                id={`paragraph-holder-${id}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {paragraphs}
                {provided.placeholder}
              </section>
            )}
          </Droppable>
        </DragDropContext>
        <section ref={spinnerRef} />
        <section
          className="mt-4 hidden items-center justify-center data-[active=true]:flex"
          data-active={aiLoading || isParagraphAddMutation > 0}
        >
          <Spinner className="h-12 w-12" />
        </section>
        <div className="min-h-[400px] w-full bg-transparent" />
      </div>
    </NoteContentWrapper>
  );
};

export const NoteTabContent = ({ id }: { id: string }) => {
  return (
    <DragAndDropProvider>
      <section className="relative flex h-full flex-1 overflow-hidden">
        <section className="absolute left-0 top-0 z-[100] h-4 w-full bg-gradient-to-b from-bg-layer1 from-15% to-transparent" />
        <NoteTabContentInternal id={id} />
      </section>
    </DragAndDropProvider>
  );
};
