import { useEffect } from "react";

import ReactGA from "react-ga4";
import { useLocation, useSearchParams } from "react-router-dom";
import { useBoolean } from "usehooks-ts";

import { QUERY_PARAMS } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { MaintenanceGuard } from "@/components/guards";
import AppState from "@/components/guards/app-state";
import FirebaseActionGuard from "@/components/guards/firebase-action-guard.tsx";
import GuardLoader from "@/components/guards/guard-loader.tsx";
import Overlays from "@/components/guards/overlays";
import ShareActionGuard from "@/components/guards/share-action-guard.tsx";
import StripeGuard from "@/components/guards/stripe-guard";
import TemporaryFdsGuard from "@/components/guards/temporary-fds-guard.tsx";
import { AllModalProvider } from "@/components/modals";
import StopFunnyStuffBlockModal from "@/components/modals/stop-funny-stuff-block.tsx";
import { Toaster } from "@/components/ui/toaster.tsx";
import { initializeAnalytics } from "@/firebase";
import init from "@/initializer.ts";
import { useConsentCookie } from "@/service/cookies.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

initializeAnalytics();
init();

const AppWrapper = () => {
  const { value, setTrue } = useBoolean();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookieNoticeModalOpen, updateCookieNoticeOpen] = useModalState(ModalKeys.COOKIE_NOTICE_MODAL_OPEN);
  const { isAccepted, isPresent } = useConsentCookie();
  useEffect(() => {
    try {
      if (!isPresent) {
        updateCookieNoticeOpen(true);
        return;
      }
      searchParams.delete(QUERY_PARAMS.MIXPANEL_DISTINCT_ID);
      setSearchParams(searchParams);
    } finally {
      setTimeout(() => setTrue(), 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieNoticeModalOpen, isPresent, isAccepted, updateCookieNoticeOpen]);

  useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);

  if (!value) return null;

  return (
    <>
      <AppState>
        <AllModalProvider>
          <StripeGuard />
          <MaintenanceGuard>
            <TemporaryFdsGuard>
              <ShareActionGuard>
                <FirebaseActionGuard>
                  <Overlays />
                </FirebaseActionGuard>
              </ShareActionGuard>
            </TemporaryFdsGuard>
          </MaintenanceGuard>
        </AllModalProvider>
        <StopFunnyStuffBlockModal />
        <Toaster />
      </AppState>
      <GuardLoader />
    </>
  );
};

export default AppWrapper;
