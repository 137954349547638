import { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import GeneralProjectIcon from "@/components/library/v3/general-project-icon.tsx";
import { useUserPublicData } from "@/firebase/hooks";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useIsSelectedWorkspace, useLibraryView, useProjectSelectionState } from "@/service/library.ts";
import { type ProjectEntity, type ProjectIconsEnum } from "@/types/schemas";

const ProjectCard = ({ project }: { project: ProjectEntity }) => {
  const { matched: isDemo } = useIsDemoLikePage();
  const { selectProject, unSelectProject, anySelected, unSelectAllProjects } = useProjectSelectionState();
  const isSelected = useIsSelectedWorkspace(project.id);
  const navigate = useNavigate();
  const workspaceId = useCurrentWorkspaceId();
  const [view] = useLibraryView();
  const [publicDataResponse] = useUserPublicData({ userId: project.userId });
  const publicData = useMemo(() => {
    return publicDataResponse?.data();
  }, [publicDataResponse]);

  return (
    <section
      className="group flex cursor-pointer flex-col gap-3 rounded-md border border-transparent p-1 transition-colors hover:bg-tertiary-container-hover data-[view=row]:flex-row data-[selected=true]:border-tertiary-default data-[selected=true]:bg-primitive-purple-100"
      data-selected={isSelected}
      data-view={view}
      onClick={(e) => {
        if (anySelected) {
          if (e.ctrlKey || e.metaKey) {
            isSelected ? unSelectProject(project.id) : selectProject(project);
          } else {
            unSelectAllProjects();
            isSelected ? unSelectProject(project.id) : selectProject(project);
          }
        } else isSelected ? unSelectProject(project.id) : selectProject(project);
      }}
      onDoubleClick={() => {
        if (isDemo) {
          navigate(`${ROUTES.DEMO}${ROUTES.WORKSPACES}/${workspaceId}/${project.id}`);
          return;
        }
        navigate(`${ROUTES.WORKSPACES}/${workspaceId}/${project.id}`);
      }}
    >
      <section className="flex h-40 w-full flex-row items-center justify-center rounded bg-stroke-tertiary-on-bg group-data-[view=row]:h-12 group-data-[view=row]:w-12 group-data-[selected=true]:bg-transparent">
        <span className="h-10 w-10 group-data-[view=row]:h-6 group-data-[view=row]:w-6">
          <GeneralProjectIcon
            className="group-data-[view=row]:h-6 group-data-[view=row]:w-6"
            color={project.colorTag ?? "#733E9C"}
            projectIcon={project.projectIcon as ProjectIconsEnum}
          />
        </span>
      </section>
      <div className="flex w-full">
        <p className="line-clamp-2 h-[2.5rem] w-1/2 select-none truncate text-sm font-bold text-secondary-onBg group-data-[view=row]:my-auto group-data-[view=row]:line-clamp-1 group-data-[view=row]:flex group-data-[view=grid]:w-full group-data-[view=row]:items-center">
          {project.name}
        </p>
        <div className="hidden w-1/2 justify-between group-data-[view=row]:flex ">
          <p className="my-auto line-clamp-1  flex h-[2.5rem] max-w-[200px] items-center text-sm text-tertiary-onBg">
            {publicData?.displayName}
          </p>
          <p className="my-auto line-clamp-1  flex h-[2.5rem] max-w-[200px] items-center text-sm text-tertiary-onBg">
            {new Intl.DateTimeFormat().format(project.dateCreated ? new Date(project.dateCreated) : new Date())}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProjectCard;
