import { useDocument } from "@/api/document.ts";
import { LoadingSpinner } from "@/pages/loading";
import { useCurrentDocumentTabId, useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { cn } from "@/utils";

const DocumentName = ({ className }: { className?: string }) => {
  const documentId = useCurrentDocumentTabId();
  const workspaceId = useCurrentWorkspaceId();
  const { data: document, isLoading } = useDocument(workspaceId, documentId);
  if (isLoading)
    return (
      <h6 className="truncate">
        <LoadingSpinner className="h-4 w-4" />
      </h6>
    );
  return <h6 className={cn("truncate", className)}>{document?.name ?? "Document Name"}</h6>;
};

export default DocumentName;
