import { useQuery } from "@tanstack/react-query";

import { getRequest } from "@/api/utils";
import { UNIVERSITY_API_URL } from "@/assets/constants/api.ts";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { type UniversityStructureArray, universityStructureArraySchema } from "@/types/schemas";
import { validateZodSchema } from "@/utils";

export const getUniversities = ({ name = "" }: { name?: string }) =>
  validateZodSchema(
    getRequest<UniversityStructureArray>(`${UNIVERSITY_API_URL}?name=${name}`),
    universityStructureArraySchema,
  );

export const useUniversityData = ({ name = "" }: { name?: string }) =>
  useQuery({
    queryKey: [QUERY_KEYS.UNIVERSITY_DATA, name],
    queryFn: () => getUniversities({ name }),
    select: (result) =>
      result
        ? result
            .filter((value, index, self) => index === self.findIndex((t) => t.name === value.name))
            .map((item) => ({ value: item.name.toLowerCase(), label: item.name }))
        : [],
    gcTime: Infinity,
    enabled: !!name,
  });
