import { useCallback, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { type FirebaseError } from "firebase/app";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { setPasswordResetSuccess } from "@/api/user.ts";
import { ROUTES } from "@/assets/constants/constants";
import { BackToLogin } from "@/components/button/navigation";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { forgottenPasswordReset, resetPassword } from "@/firebase";
import { useMixpanelTrack } from "@/service/mixpanel";
import { cn, validPassword } from "@/utils";

export const resetFormSchema = z
  .object({
    oldPassword: z.string().min(1, { message: "Old password is required" }),
    newPassword: z.string().regex(validPassword),
    confirmPassword: z.string().min(1, { message: "Confirm Password is required" }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Password don't match",
  });
export type ResetFormSchema = z.infer<typeof resetFormSchema>;

const PasswordReset = ({ oldRequired = false }: { oldRequired?: boolean }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mixpanelTrack = useMixpanelTrack();
  const form = useForm<ResetFormSchema>({
    defaultValues: {
      oldPassword: oldRequired ? "" : "default-dummy-value",
      confirmPassword: "",
      newPassword: "",
    },
    mode: "onBlur",
    resolver: zodResolver(resetFormSchema),
  });

  const [newPassword = "", confirmPassword = ""] = form.watch(["newPassword", "confirmPassword"]);
  const [rulesVisible, setRulesVisible] = useState(false);

  const {
    hasEightCharacters,
    atLeastOneNumber,
    atLeastOneUppercase,
    atLeastOneLowercase,
    atLeastOneSpecialCharacter,
    matching,
  } = useMemo(
    () => ({
      hasEightCharacters: newPassword.length >= 8,
      atLeastOneNumber: newPassword.match(/\d/g) !== null,
      atLeastOneUppercase: newPassword.match(/.*[A-Z].*/g) !== null,
      atLeastOneLowercase: newPassword.match(/.*[a-z].*/g) !== null,
      atLeastOneSpecialCharacter: newPassword.match(/[^A-Za-z0-9]/g) !== null,
      matching: newPassword === confirmPassword && newPassword !== "",
    }),
    [newPassword, confirmPassword],
  );

  const handleResetPassword = useCallback(
    async ({ newPassword, oldPassword }: ResetFormSchema): Promise<void> => {
      try {
        if (oldRequired) {
          await resetPassword(newPassword, oldPassword);
        } else {
          void setPasswordResetSuccess();
          const oobCode = searchParams.get("oobCode");
          await forgottenPasswordReset(oobCode ?? "", newPassword);
          mixpanelTrack("password_reset_success");
          navigate(ROUTES.LOGIN);
        }
        toast({ title: "Updated password", variant: "success" });
        form.reset();
      } catch (e) {
        const err = e as FirebaseError;
        if (err.code === "auth/wrong-password") {
          toast({ title: "Old password is wrong", variant: "destructive" });
          return;
        }
        toast({ title: "Failed to update password", variant: "destructive" });
      }
    },
    [oldRequired, toast, form, searchParams, mixpanelTrack, navigate],
  );

  return (
    <Form {...form}>
      <p className="mb-8 mt-4 text-sm text-secondary-onBg">Enter a new password below to change your password.</p>
      <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(handleResetPassword)}>
        {oldRequired && (
          <FormField
            control={form.control}
            name="oldPassword"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Old Password</FormLabel>
                <FormControl>
                  <Input placeholder="Old Password" type="password" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
        )}
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field, fieldState }) => (
            <FormItem className="w-full">
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  className={cn(fieldState.error && "!border-red-500")}
                  placeholder="New Password"
                  type="password"
                  onInputCapture={() => setRulesVisible(true)}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field, fieldState }) => (
            <FormItem className="w-full">
              <FormLabel>Repeat Password</FormLabel>
              <FormControl>
                <Input
                  className={cn(fieldState.error && "!border-red-500")}
                  placeholder="Repeat New Password"
                  type="password"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        {rulesVisible && (
          <section className="rounded-lg bg-primitive-orange-50 p-2">
            <p className="mb-4 text-sm font-bold text-primary-onBg">Your password must contain:</p>
            <div className="flex flex-row text-sm font-normal text-primary-onBg">
              <ul className="ml-5 w-1/2 list-disc space-y-2">
                <li
                  className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                  data-good={hasEightCharacters}
                >
                  At least 8 characters
                </li>
                <li
                  className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                  data-good={atLeastOneLowercase}
                >
                  1 lowercase letter
                </li>
                <li
                  className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                  data-good={atLeastOneUppercase}
                >
                  1 uppercase letter
                </li>
              </ul>
              <ul className="ml-5 w-1/2 list-disc space-y-2">
                <li
                  className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                  data-good={atLeastOneNumber}
                >
                  1 number
                </li>
                <li
                  className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                  data-good={atLeastOneSpecialCharacter}
                >
                  1 special character
                </li>
                <li
                  className="data-[good=true]:list-image-checkmark data-[good=true]:text-primitive-green-800"
                  data-good={matching}
                >
                  passwords match
                </li>
              </ul>
            </div>{" "}
          </section>
        )}
        <section className="flex flex-col">
          <Button className="w-full" disabled={!form.formState.isValid} id="change-password-button">
            Change Password
          </Button>
          <BackToLogin />
        </section>
      </form>
    </Form>
  );
};
export default PasswordReset;
