import React from "react";

import { editorRootElementRef$, readOnly$, TooltipWrap, useCellValue } from "@mdxeditor/editor";
import * as RadixSelect from "@radix-ui/react-select";
import classNames from "classnames";
import { ChevronDown } from "lucide-react";

import { DropDownIcon } from "@/components/icons/common";
import { CheckIcon } from "@/components/v3";

/**
 * @internal
 */
export const SelectItem = React.forwardRef<
  HTMLDivElement | null,
  { className?: string; children: React.ReactNode; value: string }
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <RadixSelect.Item
      {...props}
      className={classNames(
        "group flex w-[7.25rem] cursor-pointer flex-row items-center gap-3 rounded-md p-2 text-right text-sm text-primary last:rounded-b-lg hover:bg-primitive-purple-100 data-[highlighted]:bg-default/15 data-[state=checked]:!font-bold",
        className,
      )}
      ref={forwardedRef}
    >
      <span className="invisible h-4 w-4 group-data-[state=checked]:visible">
        <CheckIcon className="h-4 w-4" />
      </span>
      <RadixSelect.ItemText className="text-sm group-data-[state=checked]:!font-bold">{children}</RadixSelect.ItemText>
    </RadixSelect.Item>
  );
});

SelectItem.displayName = "SelectItem";

/**
 * @internal
 */
export const SelectTrigger: React.FC<{ title: string; placeholder: string; className?: string }> = ({
  title,
  placeholder,
  className,
}) => {
  const readOnly = useCellValue(readOnly$);
  return (
    <TooltipWrap title={title}>
      <RadixSelect.Trigger
        aria-label={placeholder}
        className={classNames(
          "flex w-20 cursor-pointer flex-row items-center justify-between gap-1 rounded-sm !font-bold text-tertiary-text-default",
          className,
        )}
        data-toolbar-item={true}
        disabled={readOnly}
      >
        <RadixSelect.Value className="!font-bold" placeholder={placeholder} />
        <RadixSelect.Icon className="!h-4 !w-4">
          <ChevronDown className="!h-4 !w-4 stroke-tertiary-text-default" />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
    </TooltipWrap>
  );
};

/**
 * @internal
 */
export const SelectContent: React.FC<{ children: React.ReactNode; className?: string }> = ({ children }) => {
  const editorRootElementRef = useCellValue(editorRootElementRef$);

  return (
    <RadixSelect.Portal container={editorRootElementRef?.current}>
      <RadixSelect.Content
        className="z-100 flex flex-col gap-2 overflow-hidden rounded-lg border border border-stroke-default bg-white p-2"
        position="popper"
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <RadixSelect.Viewport className="flex flex-col gap-1" data-editor-dropdown={true}>
          {children}
        </RadixSelect.Viewport>
      </RadixSelect.Content>
    </RadixSelect.Portal>
  );
};

/**
 * @internal
 */
export const SelectButtonTrigger: React.FC<{ children: React.ReactNode; title: string; className?: string }> = ({
  children,
  title,
  className,
}) => {
  const readOnly = useCellValue(readOnly$);
  return (
    <TooltipWrap title={title}>
      <RadixSelect.Trigger className={classNames("flex flex-row", className)} disabled={readOnly}>
        {children}
        <RadixSelect.Icon>
          <DropDownIcon />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
    </TooltipWrap>
  );
};

/**
 * The properties of the {@link Select} React component.
 */
export interface SelectProps<T extends string> {
  value: T;
  onChange: (value: T) => void;
  onOpenChange?: (value: boolean) => void;
  triggerTitle: string;
  placeholder: string;
  items: ({ label: string | JSX.Element; value: T } | "separator")[];
}

/**
 * A toolbar primitive you can use to build dropdowns, such as the block type select.
 * See {@link SelectProps} for more details.
 */
export const MySelect = <T extends string>(props: SelectProps<T>) => {
  return (
    <RadixSelect.Root value={props.value || undefined} onOpenChange={props.onOpenChange} onValueChange={props.onChange}>
      <SelectTrigger
        className="w-[5.5rem] px-1 text-xs font-medium"
        placeholder={props.placeholder}
        title={props.triggerTitle}
      />
      <SelectContent className="gap-2 p-2">
        {props.items.map((item, index) => {
          if (item === "separator") {
            return <RadixSelect.Separator key={index} />;
          }
          return (
            <SelectItem className="group-data-[state=checked]:!font-bold" key={index} value={item.value}>
              {item.label}
            </SelectItem>
          );
        })}
      </SelectContent>
    </RadixSelect.Root>
  );
};
