import React, { useMemo } from "react";

import { type OnboardingChoices, type OnboardingStep } from "@/types/app.ts";
import { cn } from "@/utils";

export const PollButton = ({
  selected,
  onClick,
  text = "Text",
  className,
  icon,
}: {
  selected: boolean;
  onClick: () => void;
  text?: string;
  className?: string;
  icon: React.ReactNode;
}) => {
  return (
    <section
      className={cn(
        "flex w-full max-w-sm cursor-pointer flex-row items-center justify-start gap-4 rounded-2xl border border-primary/15 p-3 transition-all hover:border-tertiary-default hover:bg-primitive-purple-100 data-[selected=true]:border-tertiary-default data-[selected=true]:bg-primitive-purple-100",
        className,
      )}
      data-selected={selected}
      onClick={onClick}
    >
      <section className="h-15 w-15 [&>svg]:h-15 [&>svg]:w-15">{icon}</section>
      <p className="whitespace-pre-wrap break-words text-start text-base font-bold text-primary dark:text-white">
        {text}
      </p>
    </section>
  );
};

export const PollButtons = ({
  selected,
  onSelectCallback,
  step,
}: {
  id: keyof OnboardingChoices;
  selected?: string;
  onSelectCallback: (value: string) => void;
  step: OnboardingStep;
}) => {
  const content = useMemo(
    () =>
      step.options?.map((item) => (
        <PollButton
          icon={item.icon}
          key={item.id}
          selected={selected === item.id}
          text={item.text}
          onClick={() => onSelectCallback(item.id)}
        />
      )),
    [onSelectCallback, step, selected],
  );

  return <section className="grid w-full grid-cols-2 gap-2 p-[1px]">{content}</section>;
};
export const MultiPollButtons = ({
  selectedItems,
  onSelectCallback,
  step,
}: {
  id: keyof OnboardingChoices;
  selectedItems?: string[];
  onSelectCallback: (value: string) => void;
  step: OnboardingStep;
}) => {
  const content = useMemo(
    () =>
      step.options?.map((item) => (
        <PollButton
          icon={item.icon}
          key={item.id}
          selected={selectedItems?.includes(item.id) ?? false}
          text={item.text}
          onClick={() => onSelectCallback(item.id)}
        />
      )),
    [onSelectCallback, step, selectedItems],
  );

  return <section className="flex flex-row gap-6">{content}</section>;
};
