import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const MoreVertical = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-4 w-4 fill-primitive-grey-600", props.className)}
  >
    <path
      d="M12 16.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
      fill="inherit"
    />
  </svg>
);
export default MoreVertical;
