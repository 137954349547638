import React, { useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { useUpdateDocument } from "@/api/document.ts";
import { ROUTES } from "@/assets/constants/constants";
import DocumentProcessingImage from "@/assets/images/v3/document-processing-image.png";
import EmptyPdfError from "@/assets/images/v3/empty-pdf-error.png";
import OtherError from "@/assets/images/v3/empty-pdf-error.png";
import EncryptedDocumentImage from "@/assets/images/v3/encrypted-document-image.png";
import MissingDocumentThumbnail from "@/assets/images/v3/missing-document-thumbnail.png";
import { Button } from "@/components/ui/button.tsx";
import { StarIconFilled, StarIcon } from "@/components/v3";
import { useDocumentProcessingState } from "@/service/document.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useDocumentSelectionState, useIsSelectedDocument, useLibraryView } from "@/service/library.ts";
import { type FolderElement } from "@/types/schemas";
import { useCurrentProjectId } from "@/utils";

const DocumentTile = ({ element }: { element: FolderElement }) => {
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  const [view] = useLibraryView();
  const navigate = useNavigate();
  const { selectResource, unselectResource, anySelected, unselectAllResources } = useDocumentSelectionState();
  const { mutate: updateDocument } = useUpdateDocument();
  const { problemLevel } = useDocumentProcessingState(element.id);
  const { matched: isDemo } = useIsDemoLikePage();

  const isSelected = useIsSelectedDocument(element.id);

  const { subtitle, displayedImage, type } = useMemo(() => {
    if (!problemLevel)
      return {
        displayedImage: MissingDocumentThumbnail,
        subtitle: "",
        type: "ok",
      };

    if (problemLevel === "UNDER_PROCESSING")
      return { displayedImage: DocumentProcessingImage, subtitle: "Document processing....", type: "processing" };
    if (problemLevel === "OK" || problemLevel === "TOLERABLE_PDF_ERRORS")
      return {
        displayedImage: element?.coverImageUrl ?? MissingDocumentThumbnail,
        subtitle: element?.authors?.join(", ") ?? "",
        type: "ok",
      };
    if (problemLevel === "EMPTY_PDF_ERROR")
      return { displayedImage: EmptyPdfError, subtitle: "Error: No text in document", type: "error" };
    if (problemLevel === "ENCRYPTED_PDF")
      return { displayedImage: EncryptedDocumentImage, subtitle: "Error: Encrypted document", type: "error" };
    if (problemLevel === "NOT_RECOVERABLE_ERRORS")
      return { displayedImage: OtherError, subtitle: "Error: Document can't be used", type: "error" };

    return { displayedImage: OtherError, subtitle: "Try re-uploading to fix", type: "error" };
  }, [element?.coverImageUrl, element?.authors, problemLevel]);

  const onToggleFavourite = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      updateDocument({
        workspaceId,
        documentId: element.id,
        payload: {
          favourite: !element.favourite,
        },
      });
    },
    [element.favourite, element.id, updateDocument, workspaceId],
  );

  return (
    <section
      className="group relative flex h-80 w-full cursor-pointer flex-col overflow-hidden rounded-md border border-transparent p-1 transition-colors hover:bg-tertiary-container-hover data-[view=row]:h-16 data-[view=row]:flex-row data-[view=row]:items-center data-[view=row]:gap-2 data-[selected=true]:border-tertiary-default data-[selected=true]:bg-primitive-purple-100"
      data-is-starred={!!element.favourite}
      data-selected={isSelected}
      data-view={view}
      onClick={(e) => {
        if (anySelected) {
          if (e.ctrlKey || e.metaKey) {
            isSelected ? unselectResource(element.id) : selectResource(element);
          } else {
            unselectAllResources();
            isSelected ? unselectResource(element.id) : selectResource(element);
          }
        } else isSelected ? unselectResource(element.id) : selectResource(element);
      }}
      onDoubleClick={() => {
        if (isDemo) {
          navigate(`${ROUTES.DEMO}${ROUTES.DOCUMENTS}/${workspaceId}/${projectId}?document=${element.id}`);
          return;
        }

        navigate(`${ROUTES.DOCUMENTS}/${workspaceId}/${projectId}?document=${element.id}`);
      }}
    >
      <Button
        className="absolute left-3 top-3 z-50 h-6 w-6 rounded-full bg-black/30 p-1 opacity-0 transition-opacity hover:bg-black/50 group-hover:opacity-100 group-data-[is-starred=true]:opacity-100"
        size="icon"
        variant="icon"
        onClick={onToggleFavourite}
      >
        <StarIconFilled className="hidden fill-white group-data-[is-starred=true]:block" />
        <StarIcon className="hidden fill-white group-data-[is-starred=false]:block" />
      </Button>
      <section className="relative w-full overflow-hidden rounded-sm group-data-[view=row]:mb-2 group-data-[view=row]:h-16 group-data-[view=row]:w-12 group-data-[view=grid]:flex-1 group-data-[view=row]:pt-2">
        <img alt="image" className="absolute h-full w-full object-cover" src={displayedImage} />
      </section>
      <section className="flex flex-col-reverse items-start group-data-[view=row]:grid group-data-[view=row]:flex-1  group-data-[view=row]:grid-cols-3">
        <p className="line-clamp-1 text-sm font-bold text-primitive-grey-800 group-data-[view=grid]:line-clamp-2 group-data-[view=grid]:max-h-[2.5rem] group-data-[view=grid]:min-h-[2.5rem]">
          {element.name}
        </p>
        <p
          className="text-xs font-medium text-primitive-grey-600 data-[state-type=error]:text-feedback-negative data-[state-type=processing]:text-default group-data-[view=grid]:mb-1  group-data-[view=grid]:mt-3 group-data-[view=row]:text-center group-data-[view=row]:text-sm"
          data-state-type={type}
        >
          {subtitle}
        </p>
        <p className="text-xs text-primitive-grey-600 group-data-[view=grid]:mb-1  group-data-[view=grid]:mt-3 group-data-[view=grid]:hidden group-data-[view=row]:text-end group-data-[view=row]:text-sm">
          {new Intl.DateTimeFormat().format(element.dateCreated ? new Date(element.dateCreated) : new Date())}
        </p>
      </section>
    </section>
  );
};
export default DocumentTile;
