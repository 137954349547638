import { createContext, type Dispatch, type SetStateAction, useContext } from "react";

import { type FlashCardVariant } from "@/assets/constants/constants";
import { type KnowledgeSessionInStore } from "@/types/schemas";

export const DeckContext = createContext<{
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  newCards: { tempId: string; variant: FlashCardVariant }[];
  addNewCard: (payload: { tempId: string; variant: FlashCardVariant }) => void;
  removeNewCard: (payload: { tempId: string }) => void;
  session?: KnowledgeSessionInStore;
  setSession: Dispatch<SetStateAction<KnowledgeSessionInStore | undefined>>;
}>({
  editMode: false,
  setEditMode: () => {
    throw new Error("No implementation");
  },
  newCards: [],
  addNewCard: () => {
    throw new Error("No implementation");
  },
  removeNewCard: () => {
    throw new Error("No implementation");
  },
  session: undefined,
  setSession: () => {
    throw new Error("No implementation");
  },
});

export const useDeckContext = () => useContext(DeckContext);
