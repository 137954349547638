import { useMemo } from "react";

import { QUERY_PARAMS, ROUTES } from "@/assets/constants/constants.ts";

export const getClaimSummaryRoute = (documentId: string) => {
  return `${ROUTES.CLAIM_SUMMARY}?${QUERY_PARAMS.FDS_QUERY_KEY}=${documentId}`;
};

export const useClaimSummaryRoute = (documentId: string) => {
  return useMemo(() => `${ROUTES.CLAIM_SUMMARY}?${QUERY_PARAMS.FDS_QUERY_KEY}=${documentId}`, [documentId]);
};
