const QuestionMark = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="275"
      viewBox="0 0 312 275"
      width="312"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M163.218 146.599C163.218 146.599 209.754 135.17 233.629 123.344C233.629 123.344 248.75 182.569 267.08 204.195C267.08 204.195 222.933 220.741 196.506 243.504C196.506 243.504 169.456 204.22 163.218 146.599Z"
        fill="#46C9E5"
      />
      <path
        d="M173.695 149.099C173.695 149.099 221.314 147.244 246.932 140.486C246.932 140.486 249.844 201.158 263.387 225.862C263.387 225.862 217.076 233.176 186.792 250.073C186.792 250.077 168.276 206.442 173.695 149.099Z"
        fill="#FFFBF8"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M180.496 162.528C201.997 162.067 226.24 159.347 237.927 155.92Z" fill="#FFFBF8" />
      <path
        d="M180.496 162.528C201.997 162.067 226.24 159.347 237.927 155.92"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M180.852 173.632C203.465 172.764 228.993 169.133 241.336 164.839Z" fill="#FFFBF8" />
      <path
        d="M180.852 173.632C203.465 172.764 228.993 169.133 241.336 164.839"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M181.207 184.736C204.351 183.928 230.471 180.372 243.091 176.127Z" fill="#FFFBF8" />
      <path
        d="M181.207 184.736C204.351 183.928 230.471 180.372 243.091 176.127"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M181.564 195.84C204.734 194.757 230.907 190.647 243.575 185.901Z" fill="#FFFBF8" />
      <path
        d="M181.564 195.84C204.734 194.757 230.907 190.647 243.575 185.901"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M184.731 207.219C206.966 204.784 232.668 200.513 245.409 196.315Z" fill="#FFFBF8" />
      <path
        d="M184.731 207.219C206.966 204.784 232.668 200.513 245.409 196.315"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M186.216 219.175C207.965 214.317 233.28 210.295 245.913 207.362Z" fill="#FFFBF8" />
      <path
        d="M186.216 219.175C207.965 214.317 233.28 210.295 245.913 207.362"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M189.327 230.968C201.937 225.85 213.886 223.195 226.634 221.898Z" fill="#FFFBF8" />
      <path
        d="M189.327 230.968C201.937 225.85 213.886 223.195 226.634 221.898"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path
        d="M127.275 97.7626C127.275 97.7626 140.822 56.8131 143.237 32.9542C143.237 32.9542 195.484 49.7454 220.68 46.1088C220.68 46.1088 212.167 87.682 216.847 118.713C216.851 118.715 174.062 120.557 127.275 97.7626Z"
        fill="#FFFBF8"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M140.794 96.2603C147.229 77.8908 152.623 56.4795 153.434 45.4858Z" fill="#FFFBF8" />
      <path
        d="M140.794 96.2603C147.229 77.8908 152.623 56.4795 153.434 45.4858"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M150.299 99.4832C156.743 80.0418 161.775 57.2522 162.061 45.4276Z" fill="#FFFBF8" />
      <path
        d="M150.299 99.4832C156.743 80.0418 161.775 57.2522 162.061 45.4276"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M159.805 102.707C166.468 82.8345 171.752 59.5671 172.166 47.5234Z" fill="#FFFBF8" />
      <path
        d="M159.805 102.707C166.468 82.8345 171.752 59.5671 172.166 47.5234"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M169.311 105.93C175.75 85.9479 180.581 62.4595 180.588 50.216Z" fill="#FFFBF8" />
      <path
        d="M169.311 105.93C175.75 85.9479 180.581 62.4595 180.588 50.216"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M179.943 106.856C184.942 87.2375 189.487 64.0975 189.98 51.9666Z" fill="#FFFBF8" />
      <path
        d="M179.943 106.856C184.942 87.2375 189.487 64.0975 189.98 51.9666"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M190.528 109.393C193.323 89.4174 197.956 66.6838 199.485 55.0459Z" fill="#FFFBF8" />
      <path
        d="M190.528 109.393C193.323 89.4174 197.956 66.6838 199.485 55.0459"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M201.491 110.5C201.164 98.1879 202.711 87.2182 205.66 76.0013Z" fill="#FFFBF8" />
      <path
        d="M201.491 110.5C201.164 98.1879 202.711 87.2182 205.66 76.0013"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path
        d="M104.934 122.387L68.5052 123.787C61.8089 72.3526 96.8251 39.2854 143.209 45.7976C206.261 54.65 207.537 118.383 176.886 147.471C158.6 164.826 151.322 172.29 152.735 200.185L117.635 202.247C111.351 173.174 120.856 152.84 141.134 133.242C155.021 119.82 159.069 88.0908 133.308 83.3023C114.86 79.8731 100.573 98.3204 104.934 122.387Z"
        fill="#FB7268"
      />
      <path
        d="M140.674 258.252C154.22 257.746 164.849 247.903 164.415 236.268C163.981 224.633 152.648 215.611 139.102 216.117C125.556 216.624 114.927 226.466 115.361 238.102C115.795 249.737 127.128 258.759 140.674 258.252Z"
        fill="#FB7268"
      />
      <path
        d="M214.579 71.3711L208.05 78.4943L209.534 87.3982L225.858 97.6378L247.672 87.3982L244.704 60.0928L242.924 61.4284C239.412 62.0714 231.081 63.595 225.858 64.5448C220.634 65.4945 216.162 69.4914 214.579 71.3711Z"
        fill="#FFFBF8"
      />
      <path
        d="M283.621 39.8717C283.621 39.8717 271.799 38.4091 267.64 43.8847C263.482 49.3604 258.285 44.2645 252.578 45.4587C246.87 46.6529 244.689 48.6155 244.689 48.6155C244.689 48.6155 246.496 52.8292 246.371 56.2223C246.242 59.6161 251.746 62.6598 256.937 61.5568C262.129 60.4538 261.869 60.5138 264.344 60.2024C266.818 59.8909 283.768 59.7061 287.56 47.1872L283.621 39.8717Z"
        fill="#F9CA20"
      />
      <path
        d="M252.507 66.9284C252.507 66.9284 248.235 66.209 248.463 73.5278C248.691 80.8466 251.063 85.0366 246.594 87.6066C242.126 90.1766 238.121 86.5902 232.149 90.8808C226.177 95.1713 224.178 96.048 224.178 96.048L229.953 103.274L247.164 110.04L249.365 108.444C249.365 108.444 256.41 101.892 257.962 87.7182C259.514 73.5445 252.507 66.9284 252.507 66.9284Z"
        fill="#F9CA20"
      />
      <path
        d="M287.259 47.2454C287.259 47.2454 286.676 54.7903 275.131 58.134C263.585 61.4778 258.46 59.2389 253.642 63.6148C248.825 67.9945 260.696 77.946 257.373 92.0634C254.05 106.181 248.107 109.406 248.107 109.406"
        stroke="#33124D"
        strokeMiterlimit="10"
        strokeWidth="1.96595"
      />
      <path
        d="M247.427 17.5649C247.427 17.5649 240.152 23.4585 241.062 34.9769C241.972 46.4953 250.162 55.4529 244.251 60.4901C238.34 65.5272 220.968 62.6305 208.126 77.8997"
        stroke="#33124D"
        strokeMiterlimit="10"
        strokeWidth="1.96595"
      />
      <path
        d="M249.859 109.842C249.859 109.842 218.591 99.1527 206.53 74.9742C206.53 74.9742 200.46 77.2667 198.793 82.351C204.742 93.4121 225.814 115.036 245.705 116.982C248.801 115.679 249.518 112.507 249.859 109.842Z"
        fill="#6582EA"
      />
      <path
        d="M244.591 12.8456L249.345 7.01831C249.345 7.01831 287.186 21.753 295.218 42.4673L291.833 48.6822C273.295 46.0331 251.868 32.1043 244.591 12.8456Z"
        fill="#6582EA"
      />
      <path d="M287.184 41.2903C281.4 39.7521 262.192 27.7504 251.402 14.4562" stroke="white" strokeWidth="1.96595" />
      <path
        d="M246.472 62.7455C246.589 63.3771 247.409 63.9956 247.913 63.9026C248.417 63.8097 248.728 63.2201 248.612 62.5886C248.495 61.957 248.048 61.0439 247.544 61.1368C247.04 61.2298 246.356 62.114 246.472 62.7455Z"
        fill="#F9CA20"
      />
      <path
        d="M249.429 63.6358C249.546 64.2673 250.389 64.3939 250.893 64.301C251.396 64.208 251.708 63.6185 251.591 62.987C251.474 62.3554 250.973 61.9158 250.47 62.0087C249.966 62.1017 249.313 63.0042 249.429 63.6358Z"
        fill="#F9CA20"
      />
      <path
        d="M247.414 66.1367C247.472 66.4524 248.032 66.4902 248.372 66.4275C248.711 66.3648 248.937 66.0571 248.879 65.7413C248.821 65.4255 248.5 65.2187 248.16 65.2813C247.82 65.344 247.355 65.8209 247.414 66.1367Z"
        fill="#F9CA20"
      />
      <path d="M234.036 261.485L242.135 269.576" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M242.135 261.485L234.036 269.576" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M88.2544 24.4099L96.3534 32.5009" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M96.3534 24.4099L88.2544 32.5009" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M43.0846 249.701L52.2531 258.861" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M52.2531 249.701L43.0846 258.861" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path
        d="M273.243 148.924C278.876 151.884 285.841 149.716 288.8 144.084C291.76 138.451 289.592 131.486 283.96 128.527C278.327 125.568 271.362 127.735 268.403 133.367C265.444 139 267.611 145.965 273.243 148.924Z"
        stroke="#33124D"
        strokeMiterlimit="10"
        strokeWidth="1.96595"
      />
      <path d="M288.587 91.2039L307.688 110.272" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M307.688 91.2039L288.587 110.272" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M298.14 87.2551V114.225" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M284.631 100.744H311.647" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M12.6075 138.688L29.3476 155.399" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M29.3476 138.688L12.6075 155.399" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M20.9784 135.231V158.863" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path d="M9.13336 147.041H32.8059" stroke="#33124D" strokeMiterlimit="10" strokeWidth="1.96595" />
      <path
        d="M37.4269 63.0161L38.4084 63.5783L38.8266 62.5264C39.9613 59.672 41.8678 57.8231 43.8202 57.0559C45.75 56.2975 47.7529 56.5713 49.316 58.005C50.4618 59.0559 50.9696 60.1563 51.083 61.2084C51.1981 62.2762 50.9165 63.3887 50.3184 64.4541C49.1046 66.6168 46.7026 68.3774 44.4408 68.8594C40.6383 69.6695 37.357 70.9331 34.6644 73.0183C31.9601 75.1125 29.9127 77.9864 28.5035 81.9106L28.2192 82.7022L28.9568 83.105L37.278 87.6504L38.1328 88.1174L38.6062 87.2653C40.3926 84.0497 41.8828 82.4072 43.6413 81.3737C45.4451 80.3134 47.6187 79.8329 50.9868 79.1474C56.8968 77.9444 62.1645 73.453 64.2333 67.7749C66.3345 62.0076 65.0976 55.1313 58.2242 49.3655C53.1788 45.1331 47.2665 43.6321 41.8101 44.9271C36.3523 46.2225 31.4986 50.2794 28.4909 56.8689L28.1187 57.6844L28.896 58.1296L37.4269 63.0161Z"
        fill="#FFFBF8"
        stroke="#33124D"
        strokeWidth="1.96395"
      />
      <path
        d="M24.4826 101.388C27.9966 103.406 32.4907 102.702 34.3882 99.3908C36.2856 96.0799 34.6244 91.8411 31.1104 89.8233C27.5965 87.8055 23.1024 88.5098 21.2049 91.8206C19.3075 95.1314 20.9687 99.3703 24.4826 101.388Z"
        fill="#FFFBF8"
        stroke="#33124D"
        strokeWidth="1.96395"
      />
      <path
        d="M73.8055 141.472C73.8055 141.472 110.58 167.812 133.713 177.664C133.713 177.664 100.633 224.842 96.4275 251.247C96.4275 251.247 57.4606 229.762 24.9388 224.792C24.936 224.795 36.4162 181.303 73.8055 141.472Z"
        fill="#F9CA20"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M71.0985 155.5C87.4837 167.676 107.238 179.754 117.991 183.991Z" fill="#F9CA20" />
      <path
        d="M71.0985 155.5C87.4837 167.676 107.238 179.754 117.991 183.991"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M64.9132 164.031C82.3689 176.548 103.616 188.693 115.364 192.661Z" fill="#F9CA20" />
      <path
        d="M64.9132 164.031C82.3689 176.548 103.616 188.693 115.364 192.661"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M58.7266 172.562C76.5452 185.433 98.1923 197.978 110.12 202.145Z" fill="#F9CA20" />
      <path
        d="M58.7266 172.562C76.5452 185.433 98.1923 197.978 110.12 202.145"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M64.1601 194.041C75.8908 203.01 90.9984 211.207 100.083 213.327Z" fill="#F9CA20" />
      <path
        d="M64.1601 194.041C75.8908 203.01 90.9984 211.207 100.083 213.327"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M59.7751 205.841C71.9301 213.513 86.917 221.405 95.6838 223.923Z" fill="#F9CA20" />
      <path
        d="M59.7751 205.841C71.9301 213.513 86.917 221.405 95.6838 223.923"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <path d="M54.3928 218.299C67.8737 224.144 82.5165 232.041 90.4186 235.361Z" fill="#F9CA20" />
      <path
        d="M54.3928 218.299C67.8737 224.144 82.5165 232.041 90.4186 235.361"
        stroke="#33124D"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.96395"
      />
      <circle cx="55.9369" cy="188.366" fill="#33124D" r="3.23845" transform="rotate(42.395 55.9369 188.366)" />
      <circle cx="48.9994" cy="200.767" fill="#33124D" r="3.23845" transform="rotate(42.395 48.9994 200.767)" />
      <circle cx="43.1317" cy="213.747" fill="#33124D" r="3.23845" transform="rotate(42.395 43.1317 213.747)" />
    </svg>
  );
};

export default QuestionMark;
