import { useCallback, useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitErrorHandler, type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { useSendShareRequest, useProjectData } from "@/api/project.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { SharedContactCard } from "@/components/modals//project/shared-contact-card";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const newProjectSchema = z.object({
  email: z.string().email(),
});

export type NewProjectFormData = z.infer<typeof newProjectSchema>;

const ShareModal = () => {
  const [{ isOpen, projectId, workspaceId }, setModalState] = useModalState(ModalKeys.SHARE_MODAL);

  const { data: currentProject } = useProjectData(workspaceId, projectId, { enabled: projectId !== "" });

  const sendShareRequest = useSendShareRequest();
  const form = useForm<NewProjectFormData>({
    resolver: zodResolver(newProjectSchema),
    defaultValues: {
      email: "",
    },
  });
  const handleClose = useCallback(() => {
    form.reset();
    setModalState({ isOpen: false, projectId: "", workspaceId: "" });
  }, [form, setModalState]);

  const handleSubmit: SubmitHandler<NewProjectFormData> = (formData) => {
    sendShareRequest.mutate({
      email: formData.email,
      projectId,
    });
  };

  const handleErrors: SubmitErrorHandler<NewProjectFormData> = (errors) => {
    console.log(errors);
  };

  const handleOpenChange = isOpen
    ? handleClose
    : () => setModalState({ isOpen: false, projectId: "", workspaceId: "" });

  useEffect(() => {
    if (sendShareRequest.isSuccess) handleClose();
  }, [sendShareRequest.isSuccess, handleClose]);

  const SharedWithList = useMemo(() => {
    return (
      <section className="">
        <h3 className="mb-3 text-lg font-semibold text-primary">People with access</h3>
        <section className="max-h-[12rem] overflow-y-auto">
          {currentProject && <SharedContactCard userId={currentProject.userId} />}
          {currentProject?.sharedWithUserIds?.map((user) => <SharedContactCard key={user} userId={user} />)}
        </section>
      </section>
    );
  }, [currentProject]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-6">
        <DialogHeader>
          <DialogTitle>Share the project</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(handleSubmit, handleErrors)}>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="px-2">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input className="resize-none" placeholder="Email to Share To..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {SharedWithList}
            <DialogFooter className="mt-6">
              <Button type="button" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Send email</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;
