import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const MenuIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg {...props} className={cn("h-4 w-4 fill-default dark:fill-white", props.className)} viewBox="0 0 24 24">
    <path
      d="M20 18a1 1 0 0 1 .117 1.993L20 20H4a1 1 0 0 1-.117-1.993L4 18h16Zm0-7a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h16Zm0-7a1 1 0 1 1 0 2H4a1 1 0 0 1 0-2h16Z"
      fill="inherit"
    />
  </svg>
);
export default MenuIcon;
