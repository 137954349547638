import { useMemo } from "react";

import { useCurrentWorkspace, useWorkspaces } from "@/api/workspace.ts";
import AvatarPlaceholder from "@/assets/svgs/v3/avatar-placeholder.svg";
import LockIcon from "@/assets/svgs/v3/lock-fill.svg";
import { Accordion, AccordionContent, AccordionHeaderTrigger, AccordionItem } from "@/components/ui/accordion.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { WorkspaceAccordionCard } from "@/components/v3";

const WorkspaceSelectorAccordion = () => {
  const { data: workspaces } = useWorkspaces({
    refetchInterval: (query) => (query.state.data === undefined ? 1000 : false),
  });
  const { data: currentWorkspace } = useCurrentWorkspace();
  const avatarImage = useMemo(
    () =>
      currentWorkspace?.imageUrl ??
      (currentWorkspace && currentWorkspace.userIds.length > 1 ? AvatarPlaceholder : LockIcon),
    [currentWorkspace],
  );

  return useMemo(() => {
    if (!workspaces) return null;
    return (
      <Accordion collapsible className="w-full" type="single">
        <AccordionItem disabled={workspaces.length === 1} value="item-1">
          <AccordionHeaderTrigger className="h-12 overflow-hidden border-b px-4 py-3">
            <section className="flex flex-row items-center gap-2 overflow-hidden">
              <Avatar
                className="h-6 w-6 bg-primitive-purple-300 p-1.5 data-[has-image=true]:p-0"
                data-has-image={!!currentWorkspace?.imageUrl}
              >
                <AvatarImage src={avatarImage} />
                <AvatarFallback className="text-sm">{currentWorkspace?.name.at(0)}</AvatarFallback>
              </Avatar>
              <Typography className="overflow-hidden truncate whitespace-nowrap text-sm font-bold text-primary">
                {currentWorkspace?.name}
              </Typography>
            </section>
          </AccordionHeaderTrigger>
          <AccordionContent className="px-1 py-0" containerClassName="py-1">
            <section className="flex max-h-[150px] flex-col gap-1 overflow-y-scroll">
              {workspaces
                .filter((workspaces) => workspaces.numOfProjects > 0)
                .filter((workspace) => workspace.id !== currentWorkspace?.id)
                .map((workspace) => (
                  <WorkspaceAccordionCard key={workspace.id} workspace={workspace} />
                ))}
            </section>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }, [avatarImage, currentWorkspace?.id, currentWorkspace?.imageUrl, currentWorkspace?.name, workspaces]);
};
export default WorkspaceSelectorAccordion;
