import { useQuery } from "@tanstack/react-query";

import { getRequest, postRequest } from "@/api/utils";
import { BACKEND_URL } from "@/assets/constants/constants";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { useAppAuthState } from "@/firebase/hooks.ts";
import {
  checkIfOldSubscriptionSchema,
  type CheckIfOldSubscriptionResponse,
  type StripePlanFeatureResponse,
} from "@/types/schemas";
import { validateZodSchema } from "@/utils";

const SUBSCRIPTION = "/subscriptions";

export const getStripePlanFeatures = async (): Promise<StripePlanFeatureResponse> =>
  getRequest(`${BACKEND_URL}${SUBSCRIPTION}/quino-clean-product-list`);

export const checkIfOldSubscription = async (): Promise<CheckIfOldSubscriptionResponse> =>
  validateZodSchema(
    postRequest(`${BACKEND_URL}${SUBSCRIPTION}/check-if-old-subscription`),
    checkIfOldSubscriptionSchema,
  );

export const useStripePlanFeatures = () => {
  const [user] = useAppAuthState();
  return useQuery({
    queryFn: () => getStripePlanFeatures(),
    queryKey: [QUERY_KEYS.STRIPE_PLAN_FEATURES, user?.uid],
    enabled: !!user,

    staleTime: Infinity,
    gcTime: Infinity,
  });
};

export const useCheckIfOldSubscription = () => {
  const [user] = useAppAuthState();
  return useQuery({
    queryFn: () => checkIfOldSubscription(),
    queryKey: [QUERY_KEYS.CHECK_IF_OLD_SUBSCRIPTION, user?.uid],
    enabled: !!user,

    staleTime: Infinity,
    gcTime: Infinity,
  });
};
