import { type ElementRef, type SVGProps } from "react";

const CheckMarkIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M14.128 3.64343C14.253 3.76845 14.3232 3.93799 14.3232 4.11477C14.3232 4.29154 14.253 4.46108 14.128 4.5861L6.63334 12.0814C6.56524 12.1496 6.48438 12.2036 6.39539 12.2405C6.30639 12.2774 6.211 12.2963 6.11467 12.2963C6.01834 12.2963 5.92295 12.2774 5.83396 12.2405C5.74496 12.2036 5.66411 12.1496 5.59601 12.0814L1.87201 8.35743C1.81007 8.29549 1.76093 8.22196 1.72741 8.14103C1.69389 8.0601 1.67664 7.97336 1.67664 7.88577C1.67664 7.79817 1.69389 7.71143 1.72741 7.6305C1.76093 7.54957 1.81007 7.47604 1.87201 7.4141C1.93395 7.35216 2.00748 7.30303 2.08841 7.2695C2.16934 7.23598 2.25608 7.21873 2.34367 7.21873C2.43127 7.21873 2.51801 7.23598 2.59894 7.2695C2.67987 7.30303 2.7534 7.35216 2.81534 7.4141L6.11534 10.7141L13.1847 3.64343C13.3097 3.51845 13.4792 3.44824 13.656 3.44824C13.8328 3.44824 14.003 3.51845 14.128 3.64343Z"
        fill="#A788DD"
        fillRule="evenodd"
      />
    </svg>
  );
};

const NotIncludedIcon = () => {
  return (
    <svg fill="none" height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99999 1.83325C11.682 1.83325 14.6667 4.81792 14.6667 8.49992C14.6667 12.1819 11.682 15.1666 7.99999 15.1666C4.31799 15.1666 1.33333 12.1819 1.33333 8.49992C1.33333 4.81792 4.31799 1.83325 7.99999 1.83325ZM7.99999 3.16659C6.58551 3.16659 5.22895 3.72849 4.22876 4.72868C3.22856 5.72888 2.66666 7.08543 2.66666 8.49992C2.66666 9.91441 3.22856 11.271 4.22876 12.2712C5.22895 13.2713 6.58551 13.8333 7.99999 13.8333C9.41448 13.8333 10.771 13.2713 11.7712 12.2712C12.7714 11.271 13.3333 9.91441 13.3333 8.49992C13.3333 7.08543 12.7714 5.72888 11.7712 4.72868C10.771 3.72849 9.41448 3.16659 7.99999 3.16659ZM10.6667 7.83325C10.8366 7.83344 11 7.8985 11.1236 8.01515C11.2471 8.1318 11.3215 8.29122 11.3314 8.46085C11.3414 8.63048 11.2862 8.7975 11.1772 8.92781C11.0681 9.05811 10.9134 9.14185 10.7447 9.16192L10.6667 9.16658H5.33333C5.16341 9.1664 4.99997 9.10133 4.87642 8.98469C4.75286 8.86804 4.6785 8.70862 4.66855 8.53899C4.65859 8.36936 4.71378 8.20233 4.82284 8.07203C4.9319 7.94173 5.0866 7.85799 5.25533 7.83792L5.33333 7.83325H10.6667Z"
        fill="#FF6473"
      />
    </svg>
  );
};

const CurlyArrow = () => {
  return (
    <svg fill="none" height="52" viewBox="0 0 61 52" width="61" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M58.8507 4.21671C59.669 4.34592 60.4371 3.7873 60.5663 2.96901C60.6955 2.15072 60.1369 1.38263 59.3186 1.25342L58.8507 4.21671ZM34.14 43.6284L33.8568 45.1014L34.14 43.6284ZM43.5451 21.137L42.8872 22.485L43.5451 21.137ZM4.68564 49.9872C4.94 50.7756 5.78533 51.2085 6.57374 50.9541L19.4216 46.8091C20.21 46.5547 20.643 45.7094 20.3886 44.921C20.1343 44.1326 19.2889 43.6996 18.5005 43.954L7.08016 47.6385L3.39566 36.2181C3.14129 35.4297 2.29596 34.9968 1.50755 35.2512C0.719138 35.5055 0.286206 36.3509 0.540568 37.1393L4.68564 49.9872ZM59.3186 1.25342C42.8989 -1.33914 32.2639 8.75529 27.8122 19.6679C25.5921 25.1102 24.8442 30.8835 25.7161 35.5773C26.5869 40.2649 29.1787 44.2018 33.8568 45.1014L34.4233 42.1554C31.4258 41.579 29.413 39.0528 28.6657 35.0294C27.9194 31.0122 28.5399 25.8266 30.5899 20.8011C34.6786 10.7783 44.1915 1.90211 58.8507 4.21671L59.3186 1.25342ZM33.8568 45.1014C41.7876 46.6266 48.6164 42.5169 51.3528 36.8758C52.7279 34.0411 53.0842 30.7803 51.9625 27.6753C50.8379 24.5627 48.294 21.7857 44.2031 19.789L42.8872 22.485C46.4246 24.2115 48.3322 26.4562 49.141 28.6947C49.9524 30.9408 49.7237 33.3605 48.6536 35.5665C46.4996 40.0069 40.9976 43.4197 34.4233 42.1554L33.8568 45.1014ZM44.2031 19.789C38.8636 17.183 34.1013 16.6917 29.8393 17.8132C25.6088 18.9264 22.0543 21.5806 19.0118 24.9313C12.9872 31.5666 8.58589 41.4078 4.77809 48.8428L7.44827 50.2104C11.3924 42.5092 15.5554 33.2009 21.2329 26.948C24.0416 23.8547 27.1225 21.6302 30.6028 20.7144C34.0514 19.8069 38.075 20.1363 42.8872 22.485L44.2031 19.789Z"
        fill="#EB9838"
      />
    </svg>
  );
};

const Slash = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg {...props} fill="none" height="20" viewBox="0 0 29 20" width="29" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 18.0105L27.5 1.0105" stroke="#A50E1E" strokeLinecap="round" strokeWidth="2" />
    </svg>
  );
};

const UpgradeToProIcon = () => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99998 1.33325C11.682 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.682 14.6666 7.99998 14.6666C4.31798 14.6666 1.33331 11.6819 1.33331 7.99992C1.33331 4.31792 4.31798 1.33325 7.99998 1.33325ZM7.99998 2.66659C6.58549 2.66659 5.22894 3.22849 4.22874 4.22868C3.22855 5.22888 2.66665 6.58543 2.66665 7.99992C2.66665 9.41441 3.22855 10.771 4.22874 11.7712C5.22894 12.7713 6.58549 13.3333 7.99998 13.3333C9.41447 13.3333 10.771 12.7713 11.7712 11.7712C12.7714 10.771 13.3333 9.41441 13.3333 7.99992C13.3333 6.58543 12.7714 5.22888 11.7712 4.22868C10.771 3.22849 9.41447 2.66659 7.99998 2.66659ZM10.002 5.33125C10.1654 5.33111 10.3231 5.39099 10.4453 5.49951C10.5675 5.60803 10.6455 5.75764 10.6646 5.91992L10.6686 5.99792V9.33125C10.6685 9.50117 10.6034 9.66461 10.4867 9.78816C10.3701 9.91172 10.2107 9.98607 10.041 9.99603C9.87142 10.006 9.70439 9.9508 9.57409 9.84174C9.44379 9.73268 9.36005 9.57798 9.33998 9.40925L9.33531 9.33125V7.60725L6.47131 10.4713C6.35134 10.5908 6.19036 10.6602 6.02106 10.6654C5.85176 10.6706 5.68684 10.6111 5.55979 10.4991C5.43275 10.3871 5.35311 10.2309 5.33705 10.0623C5.32098 9.89365 5.3697 9.72525 5.47331 9.59125L5.52865 9.52858L8.39265 6.66458H6.66865C6.49878 6.66423 6.33546 6.59905 6.21203 6.48235C6.0886 6.36565 6.01437 6.20623 6.0045 6.03666C5.99463 5.86708 6.04987 5.70013 6.15892 5.5699C6.26798 5.43967 6.42264 5.35598 6.59131 5.33592L6.66865 5.33125H10.002Z"
      fill="white"
    />
  </svg>
);

const EnvelopeSwoshIcon = () => (
  <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5001 2.66675C13.8365 2.66664 14.1605 2.79369 14.4071 3.02241C14.6538 3.25114 14.8049 3.56464 14.8301 3.90008L14.8334 4.00008V12.0001C14.8335 12.3365 14.7065 12.6605 14.4778 12.9071C14.249 13.1538 13.9355 13.3049 13.6001 13.3301L13.5001 13.3334H2.83341C2.49703 13.3335 2.17304 13.2065 1.92638 12.9778C1.67973 12.749 1.52864 12.4355 1.50341 12.1001L1.50008 12.0001V11.3334H2.83341V12.0001H13.5001V4.94275L8.87341 9.56942C8.69842 9.74439 8.46476 9.84826 8.21761 9.86094C7.97047 9.87362 7.72741 9.79422 7.53542 9.63808L7.45941 9.56942L2.83341 4.94275V5.33341H1.50008V4.00008C1.49997 3.6637 1.62702 3.3397 1.85575 3.09305C2.08447 2.8464 2.39798 2.69531 2.73341 2.67008L2.83341 2.66675H13.5001ZM4.16675 8.66675C4.34356 8.66675 4.51313 8.73699 4.63815 8.86201C4.76318 8.98703 4.83342 9.1566 4.83342 9.33342C4.83342 9.51023 4.76318 9.6798 4.63815 9.80482C4.51313 9.92984 4.34356 10.0001 4.16675 10.0001H0.833415C0.656604 10.0001 0.487034 9.92984 0.36201 9.80482C0.236986 9.6798 0.166748 9.51023 0.166748 9.33342C0.166748 9.1566 0.236986 8.98703 0.36201 8.86201C0.487034 8.73699 0.656604 8.66675 0.833415 8.66675H4.16675ZM12.5574 4.00008H3.77608L8.16675 8.39075L12.5574 4.00008ZM3.50008 6.66675C3.67 6.66694 3.83344 6.732 3.95699 6.84865C4.08055 6.96529 4.1549 7.12472 4.16486 7.29434C4.17482 7.46397 4.11963 7.631 4.01057 7.7613C3.90151 7.89161 3.74681 7.97535 3.57808 7.99541L3.50008 8.00008H1.50008C1.33016 7.99989 1.16673 7.93483 1.04317 7.81818C0.919612 7.70154 0.845258 7.54211 0.8353 7.37249C0.825342 7.20286 0.880531 7.03583 0.989591 6.90553C1.09865 6.77522 1.25335 6.69148 1.42208 6.67141L1.50008 6.66675H3.50008Z"
      fill="#572E9E"
    />
  </svg>
);

export { CheckMarkIcon, NotIncludedIcon, CurlyArrow, Slash, UpgradeToProIcon, EnvelopeSwoshIcon };
