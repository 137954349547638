import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const ShareArrow = ({ className, ...props }: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      className={cn("h-4 w-4 fill-primary", className)}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.74265 2.994C6.79331 2.464 7.34665 2.02733 7.90465 2.346C9.06465 3.02533 10.1593 3.79933 11.218 4.62666C12.5246 5.65866 13.5733 6.63133 14.316 7.408C14.638 7.746 14.608 8.28 14.3 8.60266C13.5645 9.36337 12.7866 10.0819 11.97 10.7547C10.678 11.8127 9.33998 12.838 7.88331 13.66C7.38931 13.9387 6.85398 13.62 6.75865 13.126L6.74731 13.0507L6.59598 10.668C5.38531 10.6933 4.21331 11.1093 3.27598 11.8833L3.09931 12.0313L3.01398 12.1007L2.84931 12.2287C2.82265 12.2487 2.79598 12.2687 2.76931 12.2873L2.61598 12.394C2.56836 12.4253 2.5199 12.4553 2.47065 12.484L2.33331 12.558C1.68931 12.876 1.33331 12.5947 1.33331 11.3333C1.33331 8.39733 3.49665 5.78466 6.42131 5.38866L6.59398 5.368L6.74265 2.994ZM8.01531 3.97666L7.88731 6.29133C7.88299 6.37045 7.85062 6.44545 7.79601 6.50287C7.7414 6.56029 7.66812 6.59638 7.58931 6.60466L6.68198 6.69933C4.63865 6.94333 3.01731 8.54066 2.71665 10.6287C3.716 9.89182 4.90208 9.45019 6.13998 9.354L6.40465 9.33866L7.54065 9.31466C7.62706 9.3128 7.71081 9.34457 7.77424 9.40328C7.83768 9.46199 7.87583 9.54303 7.88065 9.62933L8.01198 12.0267C9.08065 11.346 10.0993 10.5627 11.1213 9.72666C11.7814 9.17832 12.4169 8.60109 13.026 7.99666L12.854 7.826L12.4833 7.47066C11.8129 6.8426 11.1158 6.2436 10.394 5.67533C9.62844 5.07176 8.83465 4.5049 8.01531 3.97666Z"
        fill="#572E9E"
      />
    </svg>
  );
};
export default ShareArrow;
