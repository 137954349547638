import { useContext, useMemo } from "react";

import { onboardingSteps } from "@/assets/constants/onboarding.tsx";
import { Line } from "@/components/ui/stepper.tsx";
import { OnboardContext } from "@/components/v3";

const OnboardStepper = ({ children }: { children: React.ReactNode }) => {
  const ctx = useContext(OnboardContext);
  const stepperDots = useMemo(
    () => onboardingSteps.map((item, index) => <Line active={index <= ctx.currentStep} key={item.id} />),
    [ctx.currentStep],
  );

  return (
    <section className="flex max-w-full flex-col gap-12 overflow-hidden rounded-none bg-white dark:bg-primary-dark-light">
      <section className="mx-auto mb-6 flex flex-row items-center justify-between gap-1">{stepperDots}</section>
      {children}
    </section>
  );
};
export default OnboardStepper;
