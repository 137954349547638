import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useDocument, useUpdateDocument } from "@/api/document";
import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useModalState } from "@/zustand/slices/modal-slice";

const FormSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: "Must be at least 1 character",
    })
    .max(100, { message: "Must be at most 100 characters" }),
});
type FormSchemaType = z.infer<typeof FormSchema>;

const UpdateDocumentModal = () => {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
    },
  });

  const [{ isOpen, documentId, workspaceId }, setModalState] = useModalState(ModalKeys.UPDATE_DOCUMENT_MODAL);
  const { data: document } = useDocument(workspaceId, documentId);

  const { mutate: updateDocument } = useUpdateDocument();

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setModalState({
        isOpen: open,
        documentId: "",
        workspaceId: "",
      });
    },
    [setModalState],
  );

  const handleSubmit = useCallback(
    ({ name }: FormSchemaType) => {
      updateDocument({
        documentId: documentId,
        workspaceId: workspaceId,
        payload: {
          name,
        },
      });
      handleOpenChange(false);
    },
    [documentId, handleOpenChange, updateDocument, workspaceId],
  );

  useEffect(() => {
    if (document?.name) form.setValue("name", document?.name);
  }, [document?.name, form]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-6">
        <DialogHeader>
          <DialogTitle>Update Document {document?.name}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="px-2">
                  <FormLabel className="text-primary">Document Name</FormLabel>
                  <FormControl>
                    <Input className="resize-none" placeholder="Write here..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-6">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setModalState({ isOpen: false, documentId: "", workspaceId: "" })}
              >
                Cancel
              </Button>
              <Button type="submit">Update Document</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateDocumentModal;
