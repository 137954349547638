import { type StateCreator } from "zustand";

import { type AppSlice, type PdfViewerSlice } from "@/types/app.ts";

const createPdfViewerSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], PdfViewerSlice> = (set, get) => ({
  isSearchPopoverOpen: false,
  editedParagraphId: "",
  setEditedParagraph: ({ editedParagraphId }: { editedParagraphId: string }) =>
    set({ editedParagraphId }, false, "setEditedParagraphs"),
  setIsSearchPopoverOpen: (isSearchPopoverOpen: boolean) =>
    set({ isSearchPopoverOpen }, false, "setIsSearchPopoverOpen"),
  pdfViewerSearchTarget: {},
  pdfViewerResults: {},
  setPdfViewerSearchTarget: ({ payload, documentId }) => {
    set(
      (state) => ({
        ...state,
        pdfViewerSearchTarget: {
          ...state.pdfViewerSearchTarget,
          [documentId]: payload,
        },
      }),
      false,
      "setPdfViewerSearchTarget",
    );
  },
  setPdfViewerResults: ({ documentId, payload }) => {
    const _state = get();
    if (payload === undefined) {
      set(
        (state) => ({
          pdfViewerResults: {
            ...state.pdfViewerResults,
            [documentId]: payload,
          },
        }),
        false,
        "setPdfViewerResults",
      );
      return;
    }
    if (!Object.hasOwn(_state.pdfViewerResults, documentId)) {
      set(
        (state) => ({
          pdfViewerResults: {
            ...state.pdfViewerResults,
            [documentId]: {
              currentViewedResultIndex: 0,
              searchResult: payload.results,
              searchValue: payload.searchValue,
            },
          },
        }),
        false,
        "setPdfViewerResults",
      );
    } else {
      const currentIndex = _state.pdfViewerResults[documentId]?.currentViewedResultIndex ?? 0;
      set(
        (state) => ({
          pdfViewerResults: {
            ...state.pdfViewerResults,
            [documentId]: {
              currentViewedResultIndex: currentIndex,
              searchResult: payload.results,
              searchValue: payload.searchValue,
            },
          },
        }),
        false,
        "setPdfViewerResults",
      );
    }
  },
  setCurrentViewedIndex: ({ documentId, currentIndex }) => {
    const _state = get();
    if (!Object.hasOwn(_state.pdfViewerResults, documentId)) return;
    const res = _state.pdfViewerResults[documentId]!;
    set(
      (state) => ({
        pdfViewerResults: {
          ...state.pdfViewerResults,
          [documentId]: {
            currentViewedResultIndex: currentIndex,
            searchResult: res.searchResult,
            searchValue: res.searchValue,
          },
        },
      }),
      false,
      "setCurrentViewedIndex",
    );
  },
  clearSearchData: (documentId) => {
    const state = get();
    if (!Object.hasOwn(state.pdfViewerResults, documentId)) return;
    delete state.pdfViewerResults[documentId];
    delete state.pdfViewerSearchTarget[documentId];
    set({ ...state }, false, "clearSearchData");
  },
});

export default createPdfViewerSlice;
