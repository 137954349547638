import InstagramIllustration from "@/assets/images/v3/instagram.png";

const Instagram = () => {
  return (
    <section className="h-15 w-15 p-1.5">
      <img alt="instagram" className="h-full w-full" src={InstagramIllustration} />
    </section>
  );
};

export default Instagram;
