import { useMemo } from "react";

import { useIsMutating } from "@tanstack/react-query";

export const useIsChatLoading = () => {
  const isStreamingLoading = useIsMutating({ mutationKey: ["session-chat", "streaming"] });
  const isLoadingCreate = useIsMutating({ mutationKey: ["session-chat", "create"] });
  const isLoadingInstruction = useIsMutating({ mutationKey: ["session-chat", "instruction"] });
  return useMemo(
    () => isLoadingCreate !== 0 || isLoadingInstruction !== 0 || isStreamingLoading !== 0,
    [isLoadingCreate, isLoadingInstruction, isStreamingLoading],
  );
};
