import { useMemo } from "react";

import { type WebViewerInstance } from "@pdftron/pdfjs-express-viewer";
import _ from "lodash";

import { useDocument } from "@/api/document.ts";
import { SUBSCRIPTION_CONSTANTS, TEXT_POPUP_BUTTONS } from "@/assets/constants/constants";
import { isError, isProcessingFinished } from "@/service/hooks/documents.ts";
import { isHealthyDocument } from "@/utils";
import useAppStateStore from "@/zustand/store";

const generateTooltipText = (
  text: string,
  error: string,
  disabled: boolean,
  loading: boolean,
  processing: boolean,
): string => {
  if (loading) {
    return "You have to wait until the current AI function is finished";
  }

  if (processing) {
    return "Document is under processing";
  }

  if (disabled) {
    return error;
  }

  return text;
};

export default function updateUIBasedOnSelection(
  selectedText: string,
  instance: WebViewerInstance,
  isFreemium: boolean,
  isAllowed: boolean,
): void {
  const { UI } = instance;
  const count = _.words(selectedText).length;
  const appState = useAppStateStore.getState();
  const isLoading = appState.AILoading;

  const isSummarizationDisabled = count > SUBSCRIPTION_CONSTANTS.WORD_LIMIT_SUMMARIZATION || isLoading || !isAllowed;
  const isBulletPointsDisabled = count > SUBSCRIPTION_CONSTANTS.WORD_LIMIT_BULLETPOINTS || isLoading || !isAllowed;
  const isLookupDisabled = count > SUBSCRIPTION_CONSTANTS.WORD_LIMIT_AI_LOOKUP || isLoading || !isAllowed;
  const isQuestionGenerationDisabled =
    count > SUBSCRIPTION_CONSTANTS.WORD_LIMIT_QUESTION_GENERATION || isLoading || !isAllowed;

  UI.updateElement(TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON_DISABLED, {
    title: generateTooltipText(
      "Add to notes",
      "You have to wait until the current AI function is finished",
      isLoading,
      isLoading,
      isLoading,
    ),
  });
  UI.updateElement(TEXT_POPUP_BUTTONS.SUMMARIZE_BUTTON_DISABLED, {
    title: generateTooltipText(
      "Summarization",
      `Summarization. Word limit exceeded, maximum is ${SUBSCRIPTION_CONSTANTS.WORD_LIMIT_SUMMARIZATION}`,
      isSummarizationDisabled,
      isLoading,
      isAllowed,
    ),
  });
  UI.updateElement(TEXT_POPUP_BUTTONS.BULLET_POINT_SUMMARIZATION_BUTTON_DISABLED, {
    title: generateTooltipText(
      "Bullet point Summarization",
      `Bullet points Summarization. Word limit exceeded, maximum is ${SUBSCRIPTION_CONSTANTS.WORD_LIMIT_BULLETPOINTS}`,
      isBulletPointsDisabled,
      isLoading,
      isAllowed,
    ),
  });
  UI.updateElement(TEXT_POPUP_BUTTONS.AI_LOOKUP_SUMMARIZATION_DISABLED, {
    title: generateTooltipText(
      "AI Lookup",
      `AI Lookup. Word limit exceeded, maximum is ${SUBSCRIPTION_CONSTANTS.WORD_LIMIT_AI_LOOKUP}`,
      isLookupDisabled,
      isLoading,
      isAllowed,
    ),
  });
  UI.updateElement(TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_DISABLED, {
    title: generateTooltipText(
      "Question generation",
      `Question generation. Word limit exceeded, maximum is ${SUBSCRIPTION_CONSTANTS.WORD_LIMIT_QUESTION_GENERATION}`,
      isQuestionGenerationDisabled,
      isLoading,
      isAllowed,
    ),
  });

  if (isLoading) {
    UI.disableElements([TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON]);
    UI.enableElements([TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON_DISABLED]);
  } else {
    UI.enableElements([TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON]);
    UI.disableElements([TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON_DISABLED]);
  }

  if (isSummarizationDisabled) {
    UI.disableElements([TEXT_POPUP_BUTTONS.SUMMARIZE_BUTTON]);
    UI.enableElements([TEXT_POPUP_BUTTONS.SUMMARIZE_BUTTON_DISABLED]);
  } else {
    UI.enableElements([TEXT_POPUP_BUTTONS.SUMMARIZE_BUTTON]);
    UI.disableElements([TEXT_POPUP_BUTTONS.SUMMARIZE_BUTTON_DISABLED]);
  }

  if (isBulletPointsDisabled) {
    UI.disableElements([TEXT_POPUP_BUTTONS.BULLET_POINT_SUMMARIZATION_BUTTON]);
    UI.enableElements([TEXT_POPUP_BUTTONS.BULLET_POINT_SUMMARIZATION_BUTTON_DISABLED]);
  } else {
    UI.enableElements([TEXT_POPUP_BUTTONS.BULLET_POINT_SUMMARIZATION_BUTTON]);
    UI.disableElements([TEXT_POPUP_BUTTONS.BULLET_POINT_SUMMARIZATION_BUTTON_DISABLED]);
  }

  if (isLookupDisabled) {
    UI.disableElements([TEXT_POPUP_BUTTONS.AI_LOOKUP_SUMMARIZATION]);
    UI.enableElements([TEXT_POPUP_BUTTONS.AI_LOOKUP_SUMMARIZATION_DISABLED]);
  } else {
    UI.enableElements([TEXT_POPUP_BUTTONS.AI_LOOKUP_SUMMARIZATION]);
    UI.disableElements([TEXT_POPUP_BUTTONS.AI_LOOKUP_SUMMARIZATION_DISABLED]);
  }

  if (isQuestionGenerationDisabled) {
    UI.enableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_DISABLED]);
    UI.disableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON]);
    UI.disableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_FREE]);
  } else {
    if (isFreemium) {
      UI.enableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_FREE]);
      UI.disableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON]);
      UI.disableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_DISABLED]);
    } else {
      UI.enableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON]);
      UI.disableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_FREE]);
      UI.disableElements([TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_DISABLED]);
    }
  }
}

export const useDocumentProcessingData = (workspaceId: string, documentId: string) => {
  const { data: document, isLoading } = useDocument(workspaceId, documentId);
  const docProcessingData = useAppStateStore((state) => state.docProcessing[documentId]);

  const data = useMemo(() => {
    const response = {
      isAiAllowed: false,
      isError: false,
    };
    if (document?.documentDetails.processingStatus === "PROCESSED") {
      response.isAiAllowed = true;
    }
    if (isProcessingFinished(docProcessingData) && isHealthyDocument(document?.documentDetails)) {
      response.isAiAllowed = true;
    }
    if (document?.documentDetails.processingStatus === "ERROR" || isError(docProcessingData)) {
      response.isError = true;
    }
    return response;
  }, [document, docProcessingData]);

  return { data, isLoading };
};
