import { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { type QuinoTooltipProps } from "@/types/app.ts";
import { cn } from "@/utils";

export const QuinoTooltip = ({
  content,
  children,
  align = "start",
  side = "right",
  open,
  onOpenChange,
  hidden,
  delay = 500,
  className,
}: QuinoTooltipProps) => (
  <TooltipProvider>
    <Tooltip delayDuration={delay} open={open && !hidden} onOpenChange={onOpenChange}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          align={align}
          className={cn(
            "z-10 rounded-[4px] border-none bg-white p-1.5 text-label-l4 text-primary shadow-tooltip",
            hidden ? "hidden" : "",
            className,
          )}
          side={side}
        >
          {content}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  </TooltipProvider>
);
