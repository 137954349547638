import { useMemo } from "react";

import { Spinner } from "@/components/icons/common";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { type YesNoModalProps } from "@/types/app.ts";

const YesNoModal = ({
  open,
  loading,
  title,
  description,
  cancelButtonText = "Cancel",
  acceptButtonText = "Yes",
  onYes,
  onCancel,
  children,
  acceptButtonClassName,
  image,
}: YesNoModalProps) => {
  const descriptionContent = useMemo(() => {
    if (typeof description === "string") {
      return <h3>{description}</h3>;
    }
    return description;
  }, [description]);

  const content = useMemo(
    () =>
      loading ? (
        <DialogFooter className="justify-center">
          <Spinner className="h-8 w-8" />
        </DialogFooter>
      ) : (
        <DialogFooter>
          <Button className="font-extrabold" id={`close-yes-no-modal-${title}`} variant="ghost" onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button
            className={acceptButtonClassName}
            id={`accept-yes-no-modal-${title}`}
            onClick={() => {
              onYes?.();
            }}
          >
            {acceptButtonText}
          </Button>
        </DialogFooter>
      ),
    [loading, title, onCancel, acceptButtonClassName, cancelButtonText, acceptButtonText, onYes],
  );

  return (
    <Dialog open={open} onOpenChange={(open) => !open && onCancel?.()}>
      <DialogContent className="gap-12">
        <DialogHeader className="gap-4">
          {image && <img alt="reborn-image" src={image} />}
          <DialogTitle className="!mt-2 tracking-semi-tight">{title}</DialogTitle>
          <DialogDescription asChild className="!mt-0 !text-sm font-normal">
            {descriptionContent}
          </DialogDescription>
        </DialogHeader>
        {children}
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default YesNoModal;
