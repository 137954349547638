import { useCallback, useState } from "react";

import { PlusIcon } from "lucide-react";

import { HistoryIcon } from "@/components/icons/note";
import QuinoSessionHistoryItem from "@/components/note/chat/quino-session-history-item.tsx";
import PanelHeader from "@/components/note/common/panel-header.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { useNewChat } from "@/service/hooks/chat.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useDocumentChatSessionId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectChatHistory, useOpenSignUpToQuinoModal } from "@/service/library.ts";
import { useMixpanelTrack } from "@/service/mixpanel";

const QuinoHistoryPopover = () => {
  const [state, setState] = useState({
    drawerOpen: false,
  });
  const onClose = useCallback(() => setState({ drawerOpen: false }), [setState]);
  const { matched: isDemo } = useIsDemoLikePage();
  const openSignUpModal = useOpenSignUpToQuinoModal();
  const mixpanelTrack = useMixpanelTrack();
  const historyData = useCurrentProjectChatHistory();
  const [sessionId] = useDocumentChatSessionId();
  if (historyData.length === 0) return null;
  return (
    <Popover open={state.drawerOpen} onOpenChange={(open) => setState((prev) => ({ ...prev, drawerOpen: open }))}>
      <PopoverTrigger asChild>
        <Button
          className="data-[open=true]:bg-tertiary-container-hover"
          data-open={state.drawerOpen}
          size="xs"
          type="button"
          variant="ghost"
          onClick={() => {
            if (isDemo) {
              mixpanelTrack("document_chat_history_opened");
              if (!state.drawerOpen) openSignUpModal();
              return;
            }
            setState((prevState) => ({
              ...prevState,
              drawerOpen: !prevState.drawerOpen,
            }));
          }}
        >
          <HistoryIcon className="h-4 w-4" />
          <span className="inline whitespace-nowrap text-xs">History</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 rounded-xl p-1.5" sideOffset={8}>
        <Command className="gap-4">
          <CommandInput className="text-primary dark:text-white" placeholder="Search" />
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup className="max-h-40 gap-1 overflow-y-auto">
            {historyData.map((chat) => {
              return (
                <CommandItem
                  className="group mt-1 flex flex-col items-start gap-2 overflow-hidden rounded-lg p-0 text-primary first:mt-0 aria-selected:bg-container-tertiary aria-selected:text-primary data-[selected-session=true]:bg-container-tertiary dark:text-white"
                  data-selected-session={sessionId === chat.id}
                  key={chat.id}
                  value={chat.name}
                >
                  <QuinoSessionHistoryItem session={chat} onCloseCallback={onClose} />
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const NewTopicButton = () => {
  const handleNewChat = useNewChat();
  const [sessionId] = useDocumentChatSessionId();
  if (!sessionId) return null;
  return (
    <Button size="xs" variant="ghost" onClick={handleNewChat}>
      <span className="h-4 w-4">
        <PlusIcon className="h-4 w-4" />{" "}
      </span>
      New topic
    </Button>
  );
};

const QuinoChatHeader = () => {
  return (
    <PanelHeader title="Q&A">
      <QuinoHistoryPopover />
      <NewTopicButton />
    </PanelHeader>
  );
};

export default QuinoChatHeader;
