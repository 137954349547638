import Dexie, { type Table } from "dexie";

import { DOCUMENT_DATA_INDEXED_DB } from "@/assets/constants/constants";

export interface FileInIndexedDb {
  id: string;
  fileData: Blob;
}

export class FileDataDexieDB extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  documents!: Table<FileInIndexedDb>;

  constructor() {
    super(DOCUMENT_DATA_INDEXED_DB);
    this.version(1).stores({
      documents: "id",
    });
  }
}

export const documentDb = new FileDataDexieDB();
