import { useCallback, useState } from "react";

import { ChevronDown, PlusIcon } from "lucide-react";

import { useWorkspaces } from "@/api/workspace.ts";
import { Input } from "@/components/ui/input.tsx";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { type ProjectSelection, UploadType } from "@/types/app.ts";

const OTHER = "other";

const SelectCreateWorkspace = ({
  placeholder = "Select or create a workspace",
  allowOther,
  onCallback,
}: {
  placeholder?: string;
  allowOther?: boolean;
  onCallback?: (payload: Partial<ProjectSelection>) => void;
}) => {
  const { data: workspaces } = useWorkspaces();
  const [value, setValue] = useState<undefined | string>(undefined);
  const [open, setOpen] = useState(false);

  const onSelectValueChange = useCallback(
    (value: string) => {
      setValue(value);
      if (value && value !== OTHER && workspaces)
        onCallback &&
          onCallback({
            uploadType: UploadType.NEW_PROJECT,
            projectName: "",
            projectId: "",
            workspaceId: value,
            workspaceName: workspaces?.find((workspace) => workspace.id === value)?.name ?? "",
          });
      else if (value === OTHER) {
        onCallback &&
          onCallback({
            uploadType: UploadType.NEW_WORKSPACE,
            projectName: "",
            projectId: "",
            workspaceName: "",
            workspaceId: "",
          });
      }
    },
    [onCallback, workspaces],
  );

  return (
    <section className="w-full">
      {value === OTHER && (
        <section className="relative">
          <Input
            autoFocus
            className="border-primitive-purple-300"
            classNameRoot="w-full"
            placeholder="Workspace name"
            onChange={(e) => {
              onCallback &&
                onCallback({
                  uploadType: UploadType.NEW_WORKSPACE,
                  workspaceName: e.target.value ?? "",
                });
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
          <section
            className="absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2 cursor-pointer "
            onClick={() => setOpen(!open)}
          >
            <ChevronDown className=" h-4 w-4 -transform text-default" />
          </section>
        </section>
      )}
      <Select open={open} value={value} onOpenChange={setOpen} onValueChange={onSelectValueChange}>
        <SelectTrigger
          className="w-full data-[hidden=true]:h-0 data-[hidden=true]:border-0 data-[hidden=true]:p-0"
          data-hidden={value === OTHER}
          hideArrow={value === OTHER}
          onClick={(e) => e.preventDefault()}
        >
          {value !== OTHER && <SelectValue placeholder={placeholder} />}
        </SelectTrigger>
        <SelectContent className="z-[3000]">
          <SelectGroup>
            {workspaces?.map((item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.name}
              </SelectItem>
            ))}
            {allowOther && (
              <SelectItem value={OTHER}>
                <section className="flex flex-row items-center gap-1 font-bold text-default">
                  <PlusIcon className="h-4 w-4 fill-default" /> Create a new one
                </section>
              </SelectItem>
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
    </section>
  );
};
export default SelectCreateWorkspace;
