const DropzoneIllustration = () => {
  return (
    <svg fill="none" height="100" viewBox="0 0 101 100" width="101" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.9781 34.2859C57.6865 21.2327 73.5995 16.5835 73.5995 16.5835C73.5995 16.5835 89.3478 34.5123 94.2459 57.1578C94.2459 57.1578 78.6363 63.4842 67.9783 72.404C59.4952 53.2016 57.254 45.8352 44.9781 34.2859Z"
        fill="#FFF0F2"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.23774"
      />
      <path
        d="M44.7198 39.86C60.4991 30.755 77.0768 30.6088 77.0768 30.6088C77.0768 30.6088 87.3555 52.1447 85.9103 75.2688C85.9103 75.2688 69.1685 77.1115 56.4864 82.7965C53.5453 62.0109 51.2241 55.0352 44.7198 39.86Z"
        fill="white"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.23774"
      />
      <path
        d="M53.6653 44.4029C55.3401 42.9681 56.4135 40.8541 56.5848 38.6567C55.4516 39.8314 55.147 41.7274 55.856 43.1976C56.072 43.6454 56.4212 44.0835 56.9117 44.1647C57.356 44.2381 57.7878 43.9955 58.158 43.7397C59.2634 42.9732 60.2176 41.9891 60.9503 40.8611C61.0904 41.2089 61.2318 41.5586 61.3718 41.9065C62.1324 40.9694 62.8943 40.0342 63.6549 39.0971C64.2504 40.1434 64.6326 41.3104 64.7749 42.5052C64.6141 40.9791 65.047 39.3968 65.9632 38.165C66.2602 37.7642 66.6835 37.369 67.1819 37.4155C67.5822 37.4536 67.9053 37.784 68.0567 38.1562C68.208 38.5284 68.2171 38.9415 68.2244 39.343C68.2926 39.2008 68.3623 39.0589 68.4305 38.9167C69.0265 39.6358 70.1015 39.9087 70.9677 39.5607C71.834 39.2127 72.4225 38.2718 72.355 37.3412C71.8385 37.7399 71.41 38.2939 71.3013 38.9385C71.1926 39.5831 71.4715 40.3112 72.0552 40.6054C72.6451 40.902 73.3884 40.6919 73.8951 40.269C74.4019 39.8462 74.7227 39.2466 75.033 38.6636"
        fill="white"
      />
      <path
        d="M53.6653 44.4029C55.3401 42.9681 56.4135 40.8541 56.5848 38.6567C55.4516 39.8314 55.147 41.7274 55.856 43.1976C56.072 43.6454 56.4212 44.0835 56.9117 44.1647C57.356 44.2381 57.7878 43.9955 58.158 43.7397C59.2634 42.9732 60.2176 41.9891 60.9503 40.8611C61.0904 41.2089 61.2318 41.5586 61.3718 41.9065C62.1324 40.9694 62.8943 40.0342 63.6549 39.0971C64.2504 40.1434 64.6326 41.3104 64.7749 42.5052C64.6141 40.9791 65.047 39.3968 65.9632 38.165C66.2602 37.7642 66.6835 37.369 67.1819 37.4155C67.5822 37.4536 67.9053 37.784 68.0567 38.1562C68.208 38.5284 68.2171 38.9415 68.2244 39.343C68.2926 39.2008 68.3623 39.0589 68.4305 38.9167C69.0265 39.6358 70.1015 39.9087 70.9677 39.5607C71.834 39.2127 72.4225 38.2718 72.355 37.3412C71.8385 37.7399 71.41 38.2939 71.3013 38.9385C71.1926 39.5831 71.4715 40.3112 72.0552 40.6054C72.6451 40.902 73.3884 40.6919 73.8951 40.269C74.4019 39.8462 74.7227 39.2466 75.033 38.6636"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.23774"
      />
      <path
        d="M57.294 51.6788C57.7736 50.4122 58.6045 49.2812 59.6692 48.4424C60.1233 48.084 60.6924 47.7657 61.2556 47.8991C61.7618 48.0199 62.1245 48.4908 62.2665 48.992C62.4085 49.4931 62.3689 50.025 62.3294 50.5439C62.5962 49.1784 63.8162 48.1913 65.0919 47.6335C65.5507 47.4321 66.0385 47.2687 66.5394 47.2732C67.0403 47.2777 67.5596 47.4703 67.8617 47.8711C68.1639 48.272 68.1822 48.8931 67.8308 49.2508C68.0868 48.3006 68.5563 47.4086 69.195 46.6618C69.6243 47.3729 70.5444 47.7078 71.3613 47.555C72.1783 47.4021 72.877 46.8246 73.2999 46.1087C73.7227 45.3928 73.8907 44.5511 73.9188 43.7198C73.0985 44.6292 72.6478 45.8623 72.6902 47.0858C72.6997 47.3752 72.7431 47.6791 72.9186 47.9096C73.1811 48.2546 73.6842 48.3392 74.1034 48.2251C74.5227 48.1111 74.8784 47.8419 75.2204 47.5774"
        fill="white"
      />
      <path
        d="M57.294 51.6788C57.7736 50.4122 58.6045 49.2812 59.6692 48.4424C60.1233 48.084 60.6924 47.7657 61.2556 47.8991C61.7618 48.0199 62.1245 48.4908 62.2665 48.992C62.4085 49.4931 62.3689 50.025 62.3294 50.5439C62.5962 49.1784 63.8162 48.1913 65.0919 47.6335C65.5507 47.4321 66.0385 47.2687 66.5394 47.2732C67.0403 47.2777 67.5596 47.4703 67.8617 47.8711C68.1639 48.272 68.1822 48.8931 67.8308 49.2508C68.0868 48.3006 68.5563 47.4086 69.195 46.6618C69.6243 47.3729 70.5444 47.7078 71.3613 47.555C72.1783 47.4021 72.877 46.8246 73.2999 46.1087C73.7227 45.3928 73.8907 44.5511 73.9188 43.7198C73.0985 44.6292 72.6478 45.8623 72.6902 47.0858C72.6997 47.3752 72.7431 47.6791 72.9186 47.9096C73.1811 48.2546 73.6842 48.3392 74.1034 48.2251C74.5227 48.1111 74.8784 47.8419 75.2204 47.5774"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.23774"
      />
      <path
        d="M61.8327 53.9095L62.4903 57.0415C62.4162 56.5919 62.3432 56.1343 62.4156 55.685C62.488 55.2357 62.7327 54.7887 63.1434 54.5937C63.4793 54.4342 63.868 54.4609 64.2403 54.4772C65.3491 54.5252 66.4635 54.4485 67.5557 54.2468C68.0283 54.1595 68.508 54.0439 68.9117 53.7813C69.3153 53.5186 69.6305 53.0795 69.6292 52.5974C69.5263 53.6677 69.6224 54.7585 69.9133 55.7943C69.9935 56.0787 70.135 56.404 70.4252 56.4613C70.6249 56.5015 70.8217 56.3947 70.9958 56.2881C72.2281 55.5339 73.334 54.5721 74.2521 53.4578C74.3885 53.7124 74.5249 53.9669 74.6611 54.2231C75.4173 53.7983 76.1732 53.375 76.9278 52.95"
        fill="white"
      />
      <path
        d="M61.8327 53.9095L62.4903 57.0415C62.4162 56.5919 62.3432 56.1343 62.4156 55.685C62.488 55.2357 62.7327 54.7887 63.1434 54.5937C63.4793 54.4342 63.868 54.4609 64.2403 54.4772C65.3491 54.5252 66.4635 54.4485 67.5557 54.2468C68.0283 54.1595 68.508 54.0439 68.9117 53.7813C69.3153 53.5186 69.6305 53.0795 69.6292 52.5974C69.5263 53.6677 69.6224 54.7585 69.9133 55.7943C69.9935 56.0787 70.135 56.404 70.4252 56.4613C70.6249 56.5015 70.8217 56.3947 70.9958 56.2881C72.2281 55.5339 73.334 54.5721 74.2521 53.4578C74.3885 53.7124 74.5249 53.9669 74.6611 54.2231C75.4173 53.7983 76.1732 53.375 76.9278 52.95"
        stroke="#572E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.23774"
      />
      <path
        d="M7.8462 59.915C12.0764 61.7724 17.6411 59.3401 19.1436 54.9708C19.7174 53.3021 19.7279 51.3807 18.8547 49.8481C17.9816 48.3154 16.1234 47.3081 14.4102 47.7147C12.2083 48.2386 11.0178 50.8646 11.4058 53.094C11.7937 55.3233 13.3537 57.1672 15.0388 58.6808C22.1078 65.0387 32.5567 67.3972 41.67 64.6882"
        stroke="#FFF0F2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.88131"
      />
      <path
        d="M38.9269 59.1504C41.3669 60.3689 43.8035 61.5866 46.2425 62.8084C46.4626 62.9181 46.7097 63.0601 46.7669 63.2969C46.8216 63.5295 46.6808 63.7591 46.5457 63.9545C44.933 66.2947 43.3212 68.6315 41.7118 70.9726"
        stroke="#FFF0F2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.88131"
      />
      <path
        d="M4.35503 57.6001C3.26374 56.7402 2.11059 54.9102 2.10876 53.8637"
        stroke="#FFF0F2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.88131"
      />
    </svg>
  );
};
export default DropzoneIllustration;
