import { useMemo } from "react";

import { useSubscription } from "@/api/resources.ts";
import { useStripePlanFeatures } from "@/api/subscription.ts";
import { InfiniteLoading } from "@/pages/loading";

import SubscriptionPlanList from "../../components/v3/subscription/subscription-plan-list.tsx";

const Subscription = () => {
  const { isLoading } = useStripePlanFeatures();

  const { isLoading: isLoadingCurrentPlan } = useSubscription();

  const isPageLoading = useMemo(() => isLoading || isLoadingCurrentPlan, [isLoading, isLoadingCurrentPlan]);

  if (isPageLoading)
    return (
      <main className="flex h-full  w-full flex-col items-center justify-center">
        <InfiniteLoading />
      </main>
    );

  return <SubscriptionPlanList headerClassName="-top-24" />;
};

export default Subscription;
