import { BACKEND_URL } from "@/assets/constants/constants";
import { type AuthProviderDataResponse, authProviderDataResponseSchema } from "@/types/schemas";
import { validateZodSchema } from "@/utils";

import { postRequest } from "./utils";

export const fetchAuthProviderData = async (email: string): Promise<AuthProviderDataResponse> =>
  validateZodSchema(
    postRequest<AuthProviderDataResponse>(`${BACKEND_URL}/users/is-mail-in-use`, { email: email }),
    authProviderDataResponseSchema,
  );
