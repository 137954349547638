import { type ReactNode } from "react";

import { Typography } from "@/components/ui/typography.tsx";

const HomeHeaderCard = ({
  title,
  subTitle,
  icon,
  onClick,
}: {
  title?: string;
  subTitle?: string;
  icon?: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <section
      className="flex w-full cursor-pointer flex-row gap-3 rounded-xl border border-stroke-secondary-onBg p-3 transition-colors duration-300 hover:bg-tertiary-container-hover"
      onClick={onClick}
    >
      <span className="h-10 w-10">{icon}</span>
      <section className="flex flex-col">
        <Typography className="font-bold" size="small">
          {title}
        </Typography>
        <Typography className="text-secondary-onBg" size="extra-small">
          {subTitle}
        </Typography>
      </section>
    </section>
  );
};

export default HomeHeaderCard;
