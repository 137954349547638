export enum SessionVariant {
  LEARNING = "learning",
  EVALUATION = "evaluation",
}

export enum SessionOrderingMethod {
  RANDOM = "random",
  LEITNER = "leitner",
  CHRONOLOGICAL = "chronological",
}
