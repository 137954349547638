import { cn } from "@/utils";

const WorkspaceInfoCard = ({
  content,
}: {
  content: { icon: JSX.Element; title: string; description: string; bgColor: string };
}) => {
  return (
    <div
      className={cn(
        "mt-4 flex items-center gap-3 rounded-lg border-stroke-tertiary-on-bg p-3 data-[color=bg-white]:border",
        content.bgColor,
      )}
      data-color={content.bgColor}
    >
      <div>{content.icon}</div>
      <div>
        <h4 className="text-body-5 font-bold text-primary-onBg">{content.title}</h4>
        <p className="text-body-5 text-secondary-onBg">{content.description}</p>
      </div>
    </div>
  );
};

export default WorkspaceInfoCard;
