import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const ChatPlain = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-10 w-10 fill-primitive-green-600", props.className)}
    >
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10H4a2 2 0 0 1-2-2v-8C2 6.477 6.477 2 12 2Zm0 2a8 8 0 0 0-8 8v8h8a8 8 0 1 0 0-16Zm0 10a1 1 0 0 1 .117 1.993L12 16H9a1 1 0 0 1-.117-1.993L9 14h3Zm3-4a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h6Z"
        fill="inherit"
        fillRule="nonzero"
      />
    </svg>
  );
};
export default ChatPlain;
