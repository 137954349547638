import { useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { logout, sendVerificationEmail } from "@/firebase";
import { useAuthUserData, useUpdateFirebaseUserData } from "@/firebase/hooks.ts";
import { LoadingSpinner } from "@/pages/loading";
import { customGAEventSender } from "@/service";
import { useModalState } from "@/zustand/slices/modal-slice";

import { EnvelopeIcon } from "../icons";

const EmailVerification = () => {
  const [{ open, sendAtStart }, openChange] = useModalState(ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN);
  const [{ mailSent, loading }, setState] = useState({ mailSent: false, loading: false, error: false });
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { mutate: updateUser } = useUpdateFirebaseUserData();
  const { data } = useAuthUserData();

  const buttonContent = useMemo(() => {
    if (mailSent) return "Mail sent";
    if (loading) return <LoadingSpinner className="h-5 w-5" />;
    if (data?.verificationEmailSent) return "Send Mail Again";
    return "Send Mail";
  }, [data?.verificationEmailSent, loading, mailSent]);

  const verificationEmailHandler = useCallback(() => {
    setState({ error: false, loading: true, mailSent: true });
    try {
      const result = sendVerificationEmail();
      if (result) {
        customGAEventSender("login", "verification_email_sent");
        toast({
          title: "Verification email sent! Don't forget to check your spam folder.",
          variant: "success",
        });
        updateUser({ payload: { verificationEmailSent: true } });
      }
      setState({ error: false, loading: false, mailSent: true });
    } catch (e) {
      setState({ error: true, loading: false, mailSent: false });
    }
  }, [toast, updateUser]);

  const handleLogout = useCallback(() => {
    customGAEventSender("login", "email_verification_aborted");
    void queryClient.invalidateQueries({ queryKey: ["firebase-user-data"] });
    openChange({ open: false });
    logout();
  }, [openChange, queryClient]);

  const handleNewVerificationEmail = useCallback(() => {
    customGAEventSender("login", "new_verification_email_requested");
    verificationEmailHandler();
  }, [verificationEmailHandler]);

  useEffect(() => {
    if (open && sendAtStart && !data?.verificationEmailSent) verificationEmailHandler();
  }, [data?.verificationEmailSent, open, sendAtStart, verificationEmailHandler]);

  return (
    <Dialog open={open}>
      <DialogContent hideClose className="gap-6 rounded-2xl px-5 py-8">
        <DialogHeader className="flex flex-col items-center gap-4">
          <EnvelopeIcon />
          <DialogTitle className="text-center text-h3 text-primary-onBg">Check Your Email</DialogTitle>
          <DialogDescription className="text-center text-label-l3-regular text-secondary-onBg">
            We just sent you an email to <span className="font-bold text-text-link-active-onBg">{data?.email}</span>.
            Click on the activation link in the email to log in to Quino!
          </DialogDescription>
        </DialogHeader>
        <Button disabled={loading || mailSent} variant="secondary" onClick={handleNewVerificationEmail}>
          {buttonContent}
        </Button>
        <Button className="-mt-3 w-full" disabled={loading} variant="ghost" onClick={handleLogout}>
          Back to Login
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EmailVerification;
