import { createId } from "@paralleldrive/cuid2";
import { type FileRejection } from "react-dropzone";

import { ModalKeys } from "@/assets/constants/modal.ts";
import EmptyWorkspaceProjectExplorerIllustration from "@/assets/svgs/v3/empty-workspace-project-explorer-illustration.svg";
import { toast } from "@/components/ui/use-toast.ts";
import { EmptyView } from "@/components/v3";
import { useFileUploadDropzone } from "@/service/hooks/documents.ts";
import { useIsFreeUser } from "@/service/hooks/settings.ts";
import { useUpgradePlanModal } from "@/service/library.ts";
import { type FileData } from "@/types/app.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const EmptyProjectExplorerView = () => {
  const [, setModalState] = useModalState(ModalKeys.DOCUMENT_UPLOAD_OPEN);
  const isFree = useIsFreeUser();
  const [, setUpdatePlanModalState] = useUpgradePlanModal();

  const { getRootProps, getInputProps, isDragActive } = useFileUploadDropzone({
    onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        if (isFree) {
          setUpdatePlanModalState({ open: true });
        } else
          toast({
            title: "Some files were rejected",
            duration: 2000,
            description: rejectedFiles
              .map((item) => `${item.file.name} (${rejectedFiles[0].errors.map((item) => item.message).join(", ")})`)
              .join(", "),
            variant: "destructive",
          });
      }
      if (acceptedFiles) {
        const inputFileData: FileData[] = acceptedFiles.map((item) => {
          const cuid = createId().toLowerCase();
          return {
            id: cuid,
            file: item,
          };
        });

        setModalState({ initialFilePayload: inputFileData, isOpen: true });
      }
    },
    noClick: true,
    multiple: true,
  });

  return (
    <div {...getRootProps()} className="group overflow-hidden rounded-lg" data-is-drag={isDragActive}>
      <input {...getInputProps()} />
      <EmptyView
        description="Drag your files here or click on “Add documents”."
        imageUrl={EmptyWorkspaceProjectExplorerIllustration}
        title="Upload documents, textbooks, presentations and so on"
      />
    </div>
  );
};

export default EmptyProjectExplorerView;
