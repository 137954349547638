import { useDocumentTitle } from "usehooks-ts";

import { Typography } from "@/components/ui/typography.tsx";
import { InfiniteLoading } from "@/pages/loading";
import { useDocumentProcessing } from "@/service/hooks/documents";

const DocumentProcessingIndicator = () => {
  const { progressData, title, processing } = useDocumentProcessing();

  useDocumentTitle(title, { preserveTitleOnUnmount: false });

  if (!processing) return null;

  return (
    <div className="fixed bottom-8 left-8 z-50 flex min-w-[16rem] flex-col gap-2 rounded-lg border border-stroke-default bg-white text-primary shadow-elevation-2">
      <div className="mx-auto flex max-w-sm flex-col justify-center gap-4 overflow-hidden rounded-2xl bg-white p-4">
        <Typography className="text-base font-bold">Document processing...</Typography>
        <div className="flex min-w-[16rem] flex-row items-center gap-4 bg-white text-primary">
          <section className="relative flex flex-row gap-2">
            <section className="relative">
              <InfiniteLoading className="h-20 w-20" value={progressData?.percentage} />
            </section>
          </section>
          <section className="font-jakarta text-sm">
            Your document is being processed, please wait. In case of problems, try <b>reloading</b> this page.
          </section>
        </div>
      </div>
    </div>
  );
};

export default DocumentProcessingIndicator;
