import { isMobile } from "react-device-detect";
import { Navigate, Outlet } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants";
import { useIsAuthenticated } from "@/firebase/hooks";
import MobileDisabled from "@/pages/mobile-disabled";

const MobileRoute = () => {
  const [isAuthenticated, loading] = useIsAuthenticated();

  if (loading) return null;

  if (isMobile && isAuthenticated) return <MobileDisabled />;

  if (isAuthenticated) return <Outlet />;

  return <Navigate to={ROUTES.LOGIN} />;
};

export default MobileRoute;
