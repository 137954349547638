import { forwardRef } from "react";

import { NavLink, type NavLinkProps } from "react-router-dom";

import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useOpenSignUpToQuinoModal } from "@/service/library.ts";

const DemiHandlerNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ ...props }, ref) => {
  const onClick = useOpenSignUpToQuinoModal();
  const { matched: isDemo } = useIsDemoLikePage();

  return (
    <NavLink
      ref={ref}
      {...props}
      onClick={(e) => {
        if (isDemo) {
          e.preventDefault();
          onClick();
        }
      }}
    />
  );
});

DemiHandlerNavLink.displayName = "DemiHandlerNavLink";

export default DemiHandlerNavLink;
