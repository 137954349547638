import { useCallback, useRef, useState } from "react";

import { PROFILE_DATA_LIMITS } from "@/assets/constants/constants";
import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { useUpdateUserPhotoUrl } from "@/firebase/hooks";
import { useMixpanelTrack } from "@/service/mixpanel";
import { useModalState } from "@/zustand/slices/modal-slice";

import ImageFileDropzone from "../../v3/dropzone/image-file-dropzone.tsx";

const UploadProfilePicture = () => {
  const [open, setModalKey] = useModalState(ModalKeys.UPLOAD_PROFILE_PICTURE_MODAL_OPEN);
  const [selectedFile, setSelectedFile] = useState<File>();
  const handleClose = useCallback(() => {
    setSelectedFile(undefined);
    setModalKey(false);
  }, [setModalKey]);

  const { mutate: update } = useUpdateUserPhotoUrl();
  const inputRef = useRef<HTMLInputElement>(null);
  const mixpanelTrack = useMixpanelTrack();

  const handleOnSubmit = useCallback(
    (selectedFile: File): void => {
      if (selectedFile) {
        if (selectedFile.size > PROFILE_DATA_LIMITS.PROFILE_IMAGE.SIZE) {
          toast({
            title: "Image is too large",
            description: `The image can be at most 2 MB large`,
            variant: "destructive",
          });
          return;
        }

        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);
        image.onload = () => {
          update({ file: selectedFile });
          if (inputRef.current) {
            inputRef.current.files = null;
            inputRef.current.value = "";
          }
          handleClose();
        };

        image.onerror = () => {
          toast({
            title: "Failed to upload image!",
            variant: "destructive",
          });
          if (inputRef.current) {
            inputRef.current.files = null;
            inputRef.current.value = "";
          }
        };
        // setImage(selectedFile);
      }
    },
    [update, handleClose],
  );

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent
        className="gap-6"
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold tracking-[-0.2px]">Upload image</DialogTitle>
        </DialogHeader>
        <p className="text-xs font-normal text-secondary-onBg">JPEG, PNG, max. size 2 MB</p>
        <ImageFileDropzone
          description=""
          fileSizeLimit={PROFILE_DATA_LIMITS.PROFILE_IMAGE.SIZE}
          title="Select from device..."
          onChange={(file) => {
            if (selectedFile) {
              mixpanelTrack("profile_image_edit");
            }
            setSelectedFile(file);
          }}
        />
        <DialogFooter>
          <Button size="sm" type="button" variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={false} size="sm" type="submit" onClick={() => handleOnSubmit(selectedFile!)}>
            Upload
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UploadProfilePicture;
