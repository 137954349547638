import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const NewDocumentIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-text-default", props.className)}
    >
      <path
        d="M9.05733 1.33333C9.37036 1.33341 9.67339 1.44362 9.91333 1.64467L10 1.724L12.9427 4.66667C13.164 4.88802 13.3004 5.18019 13.328 5.492L13.3333 5.60933V13.3333C13.3334 13.6697 13.2064 13.9937 12.9777 14.2404C12.7489 14.487 12.4354 14.6381 12.1 14.6633L12 14.6667H4C3.66361 14.6668 3.33962 14.5397 3.09297 14.311C2.84631 14.0823 2.69523 13.7688 2.67 13.4333L2.66666 13.3333V2.66667C2.66656 2.33028 2.7936 2.00629 3.02233 1.75964C3.25106 1.51298 3.56456 1.3619 3.9 1.33667L4 1.33333H9.05733ZM8 2.66667H4V13.3333H12V6.66667H9C8.73478 6.66667 8.48043 6.56131 8.29289 6.37377C8.10535 6.18624 8 5.93188 8 5.66667V2.66667ZM8 7.66667C8.17681 7.66667 8.34638 7.73691 8.4714 7.86193C8.59643 7.98695 8.66666 8.15652 8.66666 8.33333V9.33333H9.66666C9.84348 9.33333 10.013 9.40357 10.1381 9.5286C10.2631 9.65362 10.3333 9.82319 10.3333 10C10.3333 10.1768 10.2631 10.3464 10.1381 10.4714C10.013 10.5964 9.84348 10.6667 9.66666 10.6667H8.66666V11.6667C8.66666 11.8435 8.59643 12.013 8.4714 12.1381C8.34638 12.2631 8.17681 12.3333 8 12.3333C7.82319 12.3333 7.65362 12.2631 7.52859 12.1381C7.40357 12.013 7.33333 11.8435 7.33333 11.6667V10.6667H6.33333C6.15652 10.6667 5.98695 10.5964 5.86193 10.4714C5.7369 10.3464 5.66666 10.1768 5.66666 10C5.66666 9.82319 5.7369 9.65362 5.86193 9.5286C5.98695 9.40357 6.15652 9.33333 6.33333 9.33333H7.33333V8.33333C7.33333 8.15652 7.40357 7.98695 7.52859 7.86193C7.65362 7.73691 7.82319 7.66667 8 7.66667ZM9.33333 2.94267V5.33333H11.724L9.33333 2.94267Z"
        fill="inherit"
      />
    </svg>
  );
};

export default NewDocumentIcon;
