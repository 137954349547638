import { useDeleteMultipleResources } from "@/api/resources";
import { ModalKeys } from "@/assets/constants/modal";
import { YesNoModal } from "@/components/modals";
import { useModalState } from "@/zustand/slices/modal-slice";
import useAppStateStore from "@/zustand/store";

const DeleteMultipleModal = () => {
  const [{ isOpen }, setState] = useModalState(ModalKeys.DELETE_MULTIPLE_MODAL);

  const { mutate: deleteResources } = useDeleteMultipleResources();

  const [selectedResources, unSelectResources] = useAppStateStore((state) => [
    state.selectedResources,
    state.unselectAllResources,
  ]);

  const handleDelete = () => {
    deleteResources({ resourceIds: selectedResources.map(({ id }) => id) });
    unSelectResources();
    setState({ isOpen: false });
  };

  return (
    <YesNoModal
      description="Are you sure you want to delete the selected files? This action cannot be undone later."
      open={isOpen}
      title="Delete Files"
      onCancel={() => setState({ isOpen: false })}
      onYes={handleDelete}
    />
  );
};

export default DeleteMultipleModal;
