import { create as _create, type StateCreator } from "zustand";
import { devtools as _devtools, persist } from "zustand/middleware";

import { TAB_STORE_KEY } from "@/assets/constants/constants";
import { type AppSlice } from "@/types/app.ts";
import createAIFunctionSlice from "@/zustand/slices/ai-function-slice";
import createAppStateSlice from "@/zustand/slices/app-slice";
import createDocumentPageSlice from "@/zustand/slices/document-slice";
import drawerStateSlice from "@/zustand/slices/drawer-state-slice.ts";
import createLibrarySlice from "@/zustand/slices/library-slice";
import createModalSlice from "@/zustand/slices/modal-slice";
import createPdfViewerSlice from "@/zustand/slices/pdfi-viewer-slice";
import createSectionObserverStateSlice from "@/zustand/slices/section-observer-slice";
import createTabStateSlice from "@/zustand/slices/tab-slice";
import createTutorialSlice from "@/zustand/slices/tutorial-slice";

export const devtools = (process.env.NODE_ENV === "production"
  ? (fn: never) => fn
  : _devtools) as unknown as typeof _devtools;

const resetFunctions: Array<() => void> = [];

export const create = (<T>(f: StateCreator<T> | undefined) => {
  if (f === undefined) return create;
  const store = _create(f);
  const initialState = store.getState();
  resetFunctions.push(() => {
    store.setState(initialState, true);
  });
  return store;
}) as typeof _create;

export const resetAllStores = (): void => {
  resetFunctions.forEach((r) => r());
};

const useAppStateStore = create<AppSlice>()(
  devtools(
    persist(
      (...slice) => ({
        ...createAppStateSlice(...slice),
        ...createTutorialSlice(...slice),
        ...createPdfViewerSlice(...slice),
        ...createModalSlice(...slice),
        ...createTabStateSlice(...slice),
        ...createLibrarySlice(...slice),
        ...createDocumentPageSlice(...slice),
        ...createAIFunctionSlice(...slice),
        ...drawerStateSlice(...slice),
        ...createSectionObserverStateSlice(...slice),
      }),
      {
        name: TAB_STORE_KEY,
        partialize: (state) => ({
          projectTabState: state.projectTabState,
          usageCount: state.usageCount,
          currentPage: state.currentPage,
          showDetailsView: state.showDetailsView,
          libraryView: state.libraryView,
          librarySorting: state.librarySorting,
        }),
      },
    ),
    { name: "Quino-App-Store" },
  ),
);

export default useAppStateStore;
