import * as React from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { cn } from "@/utils";

const CheckMark = ({ className }: { className?: string }) => (
  <svg
    className={cn("", className)}
    fill="none"
    height="10"
    viewBox="0 0 13 10"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.628 1.14343C12.753 1.26845 12.8232 1.43799 12.8232 1.61477C12.8232 1.79154 12.753 1.96108 12.628 2.0861L5.13334 9.58143C5.06524 9.64957 4.98438 9.70361 4.89539 9.74049C4.80639 9.77736 4.711 9.79634 4.61467 9.79634C4.51834 9.79634 4.42295 9.77736 4.33396 9.74049C4.24496 9.70361 4.16411 9.64957 4.09601 9.58143L0.372007 5.85743C0.310066 5.79549 0.260933 5.72196 0.227411 5.64103C0.193889 5.5601 0.176636 5.47336 0.176636 5.38577C0.176636 5.29817 0.193889 5.21143 0.227411 5.1305C0.260933 5.04957 0.310066 4.97604 0.372007 4.9141C0.433947 4.85216 0.50748 4.80303 0.588409 4.7695C0.669338 4.73598 0.756077 4.71873 0.843673 4.71873C0.93127 4.71873 1.01801 4.73598 1.09894 4.7695C1.17987 4.80303 1.2534 4.85216 1.31534 4.9141L4.61534 8.2141L11.6847 1.14343C11.8097 1.01845 11.9792 0.948242 12.156 0.948242C12.3328 0.948242 12.503 1.01845 12.628 1.14343Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

const MinusIcon = ({ className }: { className?: string }) => (
  <svg
    className={cn("", className)}
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M2.5 8.49992C2.5 8.32311 2.57024 8.15354 2.69526 8.02851C2.82029 7.90349 2.98986 7.83325 3.16667 7.83325H13.8333C14.0101 7.83325 14.1797 7.90349 14.3047 8.02851C14.4298 8.15354 14.5 8.32311 14.5 8.49992C14.5 8.67673 14.4298 8.8463 14.3047 8.97132C14.1797 9.09635 14.0101 9.16659 13.8333 9.16659H3.16667C2.98986 9.16659 2.82029 9.09635 2.69526 8.97132C2.57024 8.8463 2.5 8.67673 2.5 8.49992Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    className={cn(
      "transition-color group h-6 w-6 shrink-0 rounded-lg border border-primary/25 duration-200 ease-in-out hover:border-primary/50 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=indeterminate]:bg-primary dark:border-white/25 dark:data-[state=checked]:bg-white dark:data-[state=indeterminate]:bg-white",
      className,
    )}
    ref={ref}
    {...props}
  >
    <CheckboxPrimitive.Indicator className="flex items-center justify-center">
      <CheckMark className="z-20 hidden h-[8.85px] w-[12.65px] group-data-[state=checked]:inline" />
      <MinusIcon className="z-20 hidden h-[8.85px] w-[12.65px] stroke-white pb-0.5 group-data-[state=indeterminate]:inline dark:stroke-primary-dark" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
