import { useMemo } from "react";

import { useAnnouncements } from "@/api/strapi";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { AnnouncementCard } from "@/components/v3";

const Announcements = () => {
  const { data: announcements, isLoading } = useAnnouncements();
  const content = useMemo(() => {
    if (isLoading)
      return (
        <>
          <Skeleton className="col-span-2 h-72 rounded-3xl" />
          <Skeleton className="h-72 rounded-3xl" />
          <Skeleton className="h-72 rounded-3xl" />
        </>
      );

    return announcements?.data.map((announcement) => {
      return <AnnouncementCard announcement={announcement} key={announcement.id} />;
    });
  }, [announcements?.data, isLoading]);
  return <section className="grid w-full grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">{content} </section>;
};

export default Announcements;
