import { type ReactNode } from "react";

import { cn } from "@/utils";

const ViewerPanelFooter = ({ children, className }: { children?: ReactNode | ReactNode[]; className?: string }) => {
  return (
    <div
      className={cn(
        "absolute bottom-0 left-1/2 z-20 mx-auto flex h-12 w-full -translate-x-1/2 justify-center divide-x divide-stroke-default overflow-hidden border-t border-t-stroke-default bg-bg-layer1",
        className,
      )}
    >
      {children}
    </div>
  );
};
export default ViewerPanelFooter;
