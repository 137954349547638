import { type StateCreator } from "zustand";

import { FlashCardVariant } from "@/assets/constants/constants";
import { type AiFunctionSlice, type AppSlice } from "@/types/app.ts";

const createAIFunctionSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], AiFunctionSlice> = (set) => ({
  flashCardLoading: false,
  setFlashCardLoading: (flashCardLoading) => set({ flashCardLoading }, false, "setFlashCardLoading"),
  flashCardCache: {
    [FlashCardVariant.BASIC]: null,
    [FlashCardVariant.MULTIPLE]: null,
    [FlashCardVariant.TRUE_FALSE]: null,
  },
  setFlashCardCache: (variant, payload) =>
    set((state) => ({ flashCardCache: { ...state.flashCardCache, [variant]: payload } }), false, "setFlashCardCache"),
  usageCount: 0,
  increaseAiFunctionUsageCount: () => {
    set(
      (state) => ({
        usageCount: state.usageCount + 1,
      }),
      false,
      "increaseAiFunctionUsageCount",
    );
  },
  AILoading: false,
  sessionCache: { NEW: { lastQuestion: "", lastAnswer: "" } },
  setSessionCache: ({ id, data }) =>
    set(
      (prev) => ({
        sessionCache: {
          ...prev.sessionCache,
          [id]: data,
        },
      }),
      false,
      "setSessionCache",
    ),
  currentPage: {},
  setAILoading: (AILoading: boolean) => {
    set({ AILoading }, false, "setAILoading");
  },
  setActivePage: (documentId, page) => {
    set(
      (state) => {
        return {
          ...state,
          currentPage: {
            ...state.currentPage,
            [documentId]: page,
          },
        };
      },
      false,
      "setActivePage",
    );
  },
  lastModelResponse: null,
  setLastModelResponse: (lastModelResponse) => {
    set(
      {
        lastModelResponse,
      },
      false,
      "setLastModelResponse",
    );
  },
  setChatDocuSearch: (payload) => set((state) => ({ ...state, chatDocuSearch: payload }), false, "setChatDocuSearch"),
  chatDocuSearch: null,
});

export default createAIFunctionSlice;
