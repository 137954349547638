import { useEffect, useState } from "react";

import { ChevronsUpDown, Search } from "lucide-react";

import { useUniversityData } from "@/api/misc";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContentNoPortal, PopoverTrigger } from "@/components/ui/popover";
import { Check } from "@/components/v3";
import { cn } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const UniversitySelector = ({
  modal = false,
  value: initialValue = "",
  setValue: setValueCallback,
}: {
  value?: string;
  modal?: boolean;
  setValue?: (value: string) => void;
}) => {
  const [openModal] = useModalState(ModalKeys.EDIT_USER_INFORMATION_MODAL_OPEN);
  const [open, setOpen] = useState(false);
  const [selectorState, setSelectorState] = useState({ value: initialValue, label: initialValue });

  const [searchValue, setSearchValue] = useState("");
  const [searchValueApi, setSearchValueApi] = useState("");

  const { data, isFetching } = useUniversityData({ name: searchValueApi });

  useEffect(() => {
    if (!openModal) setOpen(false);
  }, [openModal]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchValueApi(searchValue);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  useEffect(() => {
    setSelectorState({ value: initialValue, label: initialValue });
  }, [initialValue]);
  return (
    <Popover modal={modal} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          aria-expanded={open}
          className="h-10 w-full justify-between truncate rounded-lg border border-stroke-tertiary-on-bg p-2 text-sm font-normal capitalize text-primary-onBg"
          role="combobox"
          variant="outline"
        >
          <p className="truncate">{selectorState.label ? selectorState.label : "Select a university..."}</p>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContentNoPortal className="z-[301] w-[400px] p-0">
        <div className="flex items-center border-b px-3">
          <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
          <Input
            className="border-0 text-primary-onBg dark:text-white placeholder:dark:text-white/50"
            classNameRoot="w-full"
            placeholder="Enter a university name..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <ul className="max-h-[200px] overflow-y-auto">
          {isFetching && <div className="py-2 pl-6 text-primary-onBg">Fetching universities…</div>}
          {data && data.length > 0 ? (
            data?.map((item) => (
              <li
                className="flex cursor-pointer flex-row items-center gap-2 py-2 text-sm text-primary-onBg hover:bg-black/5 data-[state=checked]:font-bold dark:text-white"
                data-state={item.value === selectorState.value}
                key={`${item.value}`}
                onClick={() => {
                  const newValue =
                    item.value === selectorState.value
                      ? { value: "", label: "" }
                      : { value: item.value, label: item.label };
                  setSelectorState(newValue);
                  setValueCallback?.(newValue.value);

                  setOpen(false);
                }}
              >
                <Check
                  className={cn("ml-2 h-4 w-4", selectorState.value === item.value ? "opacity-100" : "opacity-0")}
                />
                {item.label}
              </li>
            ))
          ) : (
            <li
              className="flex cursor-pointer flex-row items-center gap-2 py-2 text-sm text-primary-onBg hover:bg-black/5 dark:text-white"
              onClick={() => {
                const newValue =
                  "other" === selectorState.value ? { value: "", label: "" } : { value: "other", label: "Other" };
                setSelectorState(newValue);
                setValueCallback?.("other");

                setOpen(false);
              }}
            >
              <Check className={cn("ml-2 h-4 w-4", selectorState.value === "other" ? "opacity-100" : "opacity-0")} />
              Other
            </li>
          )}
        </ul>
      </PopoverContentNoPortal>
    </Popover>
  );
};

export default UniversitySelector;
