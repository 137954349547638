import { type FileData } from "@/types/app.ts";

export enum ModalKeys {
  SHARE_MODAL = "SHARE_MODAL",
  UN_SHARE_MODAL = "UN_SHARE_MODAL",
  IMAGE_NOTE_PARAGRAPH = "IMAGE_NOTE_PARAGRAPH",
  INTERVIEW_MODAL = "INTERVIEW_MODAL",
  QUINO_REBORN = "QUINO_REBORN",
  WORKSPACE_SETTINGS = "WORKSPACE_SETTINGS",
  SHARING_QUESTIONS = "SHARING_QUESTIONS",
  SIGN_UP_TO_QUINO = "SIGN_UP_TO_QUINO",
  CHURNED_USER_MODAL = "CHURNED_USER_MODAL",
  GET_PRO_CTA_MODAL = "GET_PRO_CTA_MODAL",
  COOKIE_NOTICE_MODAL_OPEN = "COOKIE_NOTICE_MODAL_OPEN",
  POLL_MODAL = "POLL_MODAL",
  CREATE_DEADLINE_MODAL = "CREATE_DEADLINE_MODAL",
  CREATE_FOLDER_MODAL = "CREATE_FOLDER_MODAL",
  CREATE_NEW_FLASHCARD_DIALOG = "CREATE_NEW_FLASHCARD_DIALOG",
  CREATE_NOTE_OPEN = "CREATE_NOTE_OPEN",
  CREATE_WORKSPACE = "CREATE_WORKSPACE",
  CREATE_PROJECT_MODAL_OPEN = "CREATE_PROJECT_MODAL_OPEN",
  CREATE_EVALUATION_SESSION_DIALOG = "CREATE_EVALUATION_SESSION_DIALOG",
  DELETE_ACCOUNT_MODAL_OPEN = "DELETE_ACCOUNT_MODAL_OPEN",
  DELETE_DOCUMENT_MODAL = "DELETE_DOCUMENT_MODAL",
  DELETE_FLASHCARD_OPEN = "DELETE_FLASHCARD_OPEN",
  DELETE_FOLDER_MODAL = "DELETE_FOLDER_MODAL",
  DELETE_KNOWLEDGE_DECK_OPEN = "DELETE_KNOWLEDGE_DECK_OPEN",
  DELETE_MULTIPLE_MODAL = "DELETE_MULTIPLE_MODAL",
  DELETE_NEW_FLASHCARD_OPEN = "DELETE_NEW_FLASHCARD_OPEN",
  DELETE_NOTE_MODAL = "DELETE_NOTE_MODAL",
  DELETE_PROJECT_MODAL = "DELETE_PROJECT_MODAL",
  DOCUMENT_UPLOAD_OPEN = "DOCUMENT_UPLOAD_OPEN",
  GOOGLE_DRIVE_PICKER_OPEN = "GOOGLE_DRIVE_PICKER_OPEN",
  EDIT_CARD_IN_SESSION_MODAL_OPEN = "EDIT_CARD_IN_SESSION_MODAL_OPEN",
  EDIT_DECK_MODAL_OPEN = "EDIT_DECK_MODAL_OPEN",
  EDIT_PROJECT_MODAL_OPEN = "EDIT_PROJECT_MODAL_OPEN",
  EDIT_USER_INFORMATION_MODAL_OPEN = "EDIT_USER_INFORMATION_MODAL_OPEN",
  CHANGE_PASSWORD_MODAL_OPEN = "CHANGE_PASSWORD_MODAL_OPEN",
  EMAIL_VERIFICATION_MODAL_OPEN = "EMAIL_VERIFICATION_MODAL_OPEN",
  FILL_FORM_MODAL_OPEN = "FILL_FORM_MODAL_OPEN",
  FEEDBACK = "FEEDBACK",
  CANCELLATION = "CANCELLATION",
  ONBOARDING = "ONBOARDING",
  INVITATION_MODAL_OPEN = "INVITATION_MODAL_OPEN",
  LEAVE_SESSION_MODAL_OPEN = "LEAVE_SESSION_MODAL_OPEN",
  NEW_KNOWLEDGE_DECK_OPEN = "NEW_KNOWLEDGE_DECK_OPEN",
  NEWS_AND_UPDATES_MODAL_OPEN = "NEWS_AND_UPDATES_MODAL_OPEN",
  OPEN_LOGOUT_MODAL = "OPEN_LOGOUT_MODAL",
  QUESTION_ANSWERING_HISTORY_MODAL = "QUESTION_ANSWERING_HISTORY_MODAL",
  QUESTION_ANSWERING_POPOVER = "QUESTION_ANSWERING_POPOVER",
  SEARCH_OPEN = "SEARCH_OPEN",
  SIMPLE_SEARCH_POPOVER = "SIMPLE_SEARCH_POPOVER",
  STOP_ADBLOCK_MODAL = "STOP_ADBLOCK_MODAL",
  TESTER_APPLICATION_MODEL_OPEN = "TESTER_APPLICATION_MODAL_OPEN",
  TOKEN_INFO_MODAL = "TOKEN_INFO_MODAL",
  UPDATE_DOCUMENT_MODAL = "UPDATE_DOCUMENT_MODAL",
  UPDATE_FOLDER_MODAL = "UPDATE_FOLDER_MODAL",
  UPDATE_NOTE_OPEN = "UPDATE_NOTE_OPEN",
  UPDATE_PLAN_MODAL_OPEN = "UPDATE_PLAN_MODAL_OPEN",
  RENEW_SUBSCRIPTION = "RENEW_SUBSCRIPTION",
  UPLOAD_PROFILE_PICTURE_MODAL_OPEN = "UPLOAD_PROFILE_PICTURE_MODAL_OPEN",
}

export interface ModalStateType {
  [ModalKeys.DOCUMENT_UPLOAD_OPEN]: {
    isOpen: boolean;
    initialFilePayload?: FileData[];
    toOpen?: "summary" | "chat";
  };
  [ModalKeys.SHARE_MODAL]: { isOpen: boolean; projectId: string; workspaceId: string };
  [ModalKeys.UN_SHARE_MODAL]: { isOpen: boolean; projectId: string };
  [ModalKeys.INTERVIEW_MODAL]: boolean;
  [ModalKeys.RENEW_SUBSCRIPTION]: boolean;
  [ModalKeys.SIGN_UP_TO_QUINO]: boolean;
  [ModalKeys.IMAGE_NOTE_PARAGRAPH]: { isOpen: boolean; noteId: string; position: number };
  [ModalKeys.WORKSPACE_SETTINGS]: { isOpen: boolean; workspaceId: string };
  [ModalKeys.QUINO_REBORN]: boolean;
  [ModalKeys.STOP_ADBLOCK_MODAL]: boolean;
  [ModalKeys.SHARING_QUESTIONS]: boolean;
  [ModalKeys.TOKEN_INFO_MODAL]: boolean;
  [ModalKeys.UPDATE_FOLDER_MODAL]: { isOpen: boolean; folderId: string };
  [ModalKeys.CREATE_DEADLINE_MODAL]: boolean;
  [ModalKeys.CREATE_NEW_FLASHCARD_DIALOG]: boolean;
  [ModalKeys.CREATE_NOTE_OPEN]: boolean;
  [ModalKeys.CREATE_WORKSPACE]: boolean;
  [ModalKeys.CREATE_FOLDER_MODAL]: { isOpen: boolean };
  [ModalKeys.EDIT_DECK_MODAL_OPEN]: { isOpen: boolean; deckId: string };
  [ModalKeys.EDIT_CARD_IN_SESSION_MODAL_OPEN]: boolean;
  [ModalKeys.LEAVE_SESSION_MODAL_OPEN]: boolean;
  [ModalKeys.QUESTION_ANSWERING_HISTORY_MODAL]: boolean;
  [ModalKeys.NEW_KNOWLEDGE_DECK_OPEN]: boolean;
  [ModalKeys.CREATE_EVALUATION_SESSION_DIALOG]: boolean;
  [ModalKeys.DELETE_MULTIPLE_MODAL]: { isOpen: boolean };
  [ModalKeys.POLL_MODAL]: boolean;
  [ModalKeys.DELETE_KNOWLEDGE_DECK_OPEN]: { isOpen: boolean; deckId: string };
  [ModalKeys.DELETE_FOLDER_MODAL]: { isOpen: boolean; folderId: string };
  [ModalKeys.DELETE_FLASHCARD_OPEN]: {
    isOpen: boolean;
    cardId: string;
    deckId: string;
  };
  [ModalKeys.DELETE_NEW_FLASHCARD_OPEN]: {
    isOpen: boolean;
    tempId: string;
    cardId: string;
    deckId: string;
  };
  [ModalKeys.DELETE_NOTE_MODAL]: { isOpen: boolean; noteId: string };
  [ModalKeys.DELETE_DOCUMENT_MODAL]: { isOpen: boolean; documentId: string; workspaceId: string };
  [ModalKeys.UPDATE_DOCUMENT_MODAL]: { isOpen: boolean; documentId: string; workspaceId: string };
  [ModalKeys.FILL_FORM_MODAL_OPEN]: boolean;
  [ModalKeys.FEEDBACK]: boolean;
  [ModalKeys.CANCELLATION]: boolean;
  [ModalKeys.DELETE_PROJECT_MODAL]: {
    isOpen: boolean;
    projectId: string | null;
    workspaceId: string | null;
  };
  [ModalKeys.CREATE_PROJECT_MODAL_OPEN]: boolean;
  [ModalKeys.GOOGLE_DRIVE_PICKER_OPEN]: boolean;
  [ModalKeys.EDIT_PROJECT_MODAL_OPEN]: { isOpen: boolean; projectId: string; workspaceId: string };
  [ModalKeys.UPDATE_NOTE_OPEN]: { isOpen: boolean; noteId: string | null; workspaceId: string | null };
  [ModalKeys.COOKIE_NOTICE_MODAL_OPEN]: boolean;
  [ModalKeys.UPDATE_PLAN_MODAL_OPEN]: { open: boolean; conversion?: boolean };
  [ModalKeys.OPEN_LOGOUT_MODAL]: boolean;
  [ModalKeys.TESTER_APPLICATION_MODEL_OPEN]: boolean;
  [ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN]: { open: boolean; sendAtStart?: boolean };
  [ModalKeys.DELETE_ACCOUNT_MODAL_OPEN]: boolean;
  [ModalKeys.INVITATION_MODAL_OPEN]: boolean;
  [ModalKeys.ONBOARDING]: boolean;
  [ModalKeys.NEWS_AND_UPDATES_MODAL_OPEN]: boolean;
  [ModalKeys.CHURNED_USER_MODAL]: boolean;
  [ModalKeys.GET_PRO_CTA_MODAL]: boolean;
  [ModalKeys.EDIT_USER_INFORMATION_MODAL_OPEN]: boolean;
  [ModalKeys.CHANGE_PASSWORD_MODAL_OPEN]: boolean;
  [ModalKeys.UPLOAD_PROFILE_PICTURE_MODAL_OPEN]: boolean;
}

export interface ModalSlice {
  modals: ModalStateType;

  setModalKey: <T extends keyof ModalStateType>(key: T, value: ModalStateType[T]) => void;
}

export enum TutorialVariant {
  LIBRARY_VIEW = "library-view",
  DOCUMENT_VIEW = "document-view",
}
