import React, { type CSSProperties } from "react";

interface IProps {
  id: string;
  onClick?: () => void;
  icon: string;
  disabled?: boolean;
  title?: string;
  style?: CSSProperties | undefined;
}

const PopupButton = ({ onClick = () => {}, icon, disabled, id, style, title }: IProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      className={`QuinoPopupButton ${disabled ? "Disabled" : ""}`}
      disabled={disabled}
      id={id}
      style={style}
      type="button"
      onClick={handleClick}
    >
      <img alt="" src={icon} />
      <span className="truncate">{title}</span>
    </button>
  );
};

export default PopupButton;
