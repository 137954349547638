import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import Illustration from "@/assets/svgs/v3/renew-subscription.svg";
import { YesNoModal } from "@/components/modals";
import { useRenewSubscriptionChecked } from "@/service/subscription";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const RenewSubscription = () => {
  const [checked, setChecked] = useRenewSubscriptionChecked();
  const [open, setOpen] = useModalState(ModalKeys.RENEW_SUBSCRIPTION);
  const navigate = useNavigate();
  const onAccept = useCallback(() => {
    navigate(ROUTES.SUBSCRIPTION);
    setOpen(false);
    setChecked(true);
  }, [navigate, setOpen, setChecked]);

  const onCancel = useCallback(() => {
    setOpen(false);
    setChecked(true);
  }, [setOpen, setChecked]);

  return (
    <YesNoModal
      acceptButtonText="Accept subscription"
      cancelButtonText="Cancel subscription"
      description="Your Quino subscription is about to expire. Please renew it manually to access Quino’s Pro features later on."
      image={Illustration}
      open={open && !checked}
      title="Renew your subscription"
      onCancel={onCancel}
      onYes={onAccept}
    />
  );
};
export default RenewSubscription;
