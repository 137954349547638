import { useMemo } from "react";

import { CheckMarkIcon, QuoteIcon } from "@/components/icons/summary";
import { Button } from "@/components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Typography } from "@/components/ui/typography.tsx";
import { useChatDocuConfig } from "@/service/hooks/documents.ts";
import { useCurrentProjectData } from "@/utils";

const ProjectDocumentSelector = () => {
  const [storageState, setCurrentState] = useChatDocuConfig();
  const currentProject = useCurrentProjectData();
  const text = useMemo(() => {
    if (storageState.documentOnly) return "Current document";
    return currentProject?.data?.name;
  }, [storageState.documentOnly, currentProject?.data?.name]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className="flex max-w-[8.75rem] flex-row justify-start gap-1 overflow-hidden rounded-lg text-start"
          size="xs"
          type="button"
          variant="ghost"
        >
          <span className="h-4 w-4">
            <QuoteIcon className="h-4 w-4" />
          </span>
          <span className="truncate">{text}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="ml-6 w-72 overflow-hidden rounded-xl p-2">
        <Typography className="p-2 pb-4 font-bold text-secondary-onBg" size="extra-small">
          Select the source of AI answers
        </Typography>
        <Button
          className="group mb-1 w-full justify-start rounded p-2 text-sm font-normal data-[selected=true]:font-bold"
          data-selected={storageState.documentOnly}
          size="sm"
          variant="ghost"
          onClick={() => setCurrentState({ documentOnly: true })}
        >
          <span className="h-4 w-4">
            <CheckMarkIcon className="invisible h-4 w-4 group-data-[selected=true]:visible" />
          </span>
          <p className="truncate text-primary">Current document only</p>
        </Button>
        <Button
          className="group w-full justify-start rounded p-2 text-sm font-normal data-[selected=true]:font-bold"
          data-selected={!storageState.documentOnly}
          size="sm"
          variant="ghost"
          onClick={() => setCurrentState({ documentOnly: false })}
        >
          <span className="h-4 w-4">
            <CheckMarkIcon className="invisible h-4 w-4 group-data-[selected=true]:visible" />
          </span>
          <p className="truncate text-primary">All documents in {currentProject?.data?.name}</p>
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default ProjectDocumentSelector;
