import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal";
import QuinoSignUpToQuinoIllustration from "@/assets/images/v3/sign-up-illustration.png";
import { YesNoModal } from "@/components/modals";
import { useModalState } from "@/zustand/slices/modal-slice";

const SignUpToQuino = () => {
  const [open, setState] = useModalState(ModalKeys.SIGN_UP_TO_QUINO);
  const navigate = useNavigate();

  return (
    <YesNoModal
      acceptButtonText="Let's go"
      description="If you want to explore more of what Quino has to offer, sign up for free and try out additional features to enhance your productivity."
      image={QuinoSignUpToQuinoIllustration}
      open={open}
      title="Sign up to Quino"
      onCancel={() => setState(false)}
      onYes={() => {
        setState(false);
        navigate(`${ROUTES.REGISTER}`);
      }}
    />
  );
};

export default SignUpToQuino;
