import { type StateCreator } from "zustand";

import { type AppSlice, type DocumentPageSlice, ViewMode } from "@/types/app.ts";
import { type ProjectDocumentRedisTicker } from "@/types/schemas";

const createDocumentPageSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], DocumentPageSlice> = (
  set,
) => ({
  showWorkspaces: true,
  setShowWorkspaces: (showWorkspaces) => set({ showWorkspaces }),
  viewMode: ViewMode.SPLIT,
  setViewMode: (viewMode) => set({ viewMode }),
  docProcessing: {},
  setDocProcessing: ({ payload }) => {
    const result = payload.reduce<Record<string, ProjectDocumentRedisTicker>>((obj, item) => {
      obj[item.documentId] = item;
      return obj;
    }, {});
    set((state) => ({ docProcessing: { ...state.docProcessing, ...result } }), false, "setDocProcessing");
  },
  setAllToProcessed: () => {
    set(
      (state) => {
        const keys = Object.keys(state.docProcessing);
        keys.forEach(
          (item) =>
            (state.docProcessing[item] = {
              ...state.docProcessing[item],
              documentDownloaded: true,
              documentHealthcheckDone: true,
              documentMetadataGatheringDone: true,
              dbWritingDone: true,
              textEmbeddingDone: true,
              paragraphAggregationDone: true,
              textractionDone: true,
            }),
        );
        return {
          docProcessing: { ...state.docProcessing },
        };
      },
      false,
      "setAllToProcessed",
    );
  },
});

export default createDocumentPageSlice;
