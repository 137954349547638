import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const FeedbackIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-text-default", props.className)}
    >
      <path
        d="M19 3C19.7652 2.99996 20.5015 3.29233 21.0583 3.81728C21.615 4.34224 21.9501 5.06011 21.995 5.824L22 6V16C22 16.7652 21.7077 17.5015 21.1827 18.0583C20.6578 18.615 19.9399 18.9501 19.176 18.995L19 19H15.303L12.693 20.74C12.5089 20.8626 12.2955 20.9342 12.0746 20.9473C11.8538 20.9605 11.6334 20.9149 11.436 20.815L11.306 20.74L8.698 19H5C4.23479 19 3.49849 18.7077 2.94174 18.1827C2.38499 17.6578 2.04989 16.9399 2.005 16.176L2 16V6C1.99996 5.23479 2.29233 4.49849 2.81728 3.94174C3.34224 3.38499 4.06011 3.04989 4.824 3.005L5 3H19ZM19 5H5C4.75507 5.00003 4.51866 5.08996 4.33563 5.25272C4.15259 5.41547 4.03566 5.63975 4.007 5.883L4 6V16C4.00003 16.2449 4.08996 16.4813 4.25272 16.6644C4.41547 16.8474 4.63975 16.9643 4.883 16.993L5 17H8.697C9.03444 16.9999 9.3664 17.0852 9.662 17.248L9.807 17.336L12 18.798L14.193 17.336C14.4739 17.1487 14.7976 17.0355 15.134 17.007L15.303 17H19C19.2449 17 19.4813 16.91 19.6644 16.7473C19.8474 16.5845 19.9643 16.3603 19.993 16.117L20 16V6C20 5.75507 19.91 5.51866 19.7473 5.33563C19.5845 5.15259 19.3603 5.03566 19.117 5.007L19 5ZM8.5 10C8.89782 10 9.27936 10.158 9.56066 10.4393C9.84196 10.7206 10 11.1022 10 11.5C10 11.8978 9.84196 12.2794 9.56066 12.5607C9.27936 12.842 8.89782 13 8.5 13C8.10218 13 7.72064 12.842 7.43934 12.5607C7.15804 12.2794 7 11.8978 7 11.5C7 11.1022 7.15804 10.7206 7.43934 10.4393C7.72064 10.158 8.10218 10 8.5 10ZM15.5 10C15.8978 10 16.2794 10.158 16.5607 10.4393C16.842 10.7206 17 11.1022 17 11.5C17 11.8978 16.842 12.2794 16.5607 12.5607C16.2794 12.842 15.8978 13 15.5 13C15.1022 13 14.7206 12.842 14.4393 12.5607C14.158 12.2794 14 11.8978 14 11.5C14 11.1022 14.158 10.7206 14.4393 10.4393C14.7206 10.158 15.1022 10 15.5 10Z"
        fill="inherit"
      />
    </svg>
  );
};
export default FeedbackIcon;
