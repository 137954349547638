import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const DeniedIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-[17px] w-[17px] fill-primitive-red-800", props.className)}
  >
    <path
      d="M8.50016 1.83325C12.1822 1.83325 15.1668 4.81792 15.1668 8.49992C15.1668 12.1819 12.1822 15.1666 8.50016 15.1666C4.81816 15.1666 1.8335 12.1819 1.8335 8.49992C1.8335 4.81792 4.81816 1.83325 8.50016 1.83325ZM8.50016 3.16659C7.08567 3.16659 5.72912 3.72849 4.72893 4.72868C3.72873 5.72888 3.16683 7.08543 3.16683 8.49992C3.16683 9.91441 3.72873 11.271 4.72893 12.2712C5.72912 13.2713 7.08567 13.8333 8.50016 13.8333C9.91465 13.8333 11.2712 13.2713 12.2714 12.2712C13.2716 11.271 13.8335 9.91441 13.8335 8.49992C13.8335 7.08543 13.2716 5.72888 12.2714 4.72868C11.2712 3.72849 9.91465 3.16659 8.50016 3.16659ZM11.1668 7.83325C11.3367 7.83344 11.5002 7.8985 11.6237 8.01515C11.7473 8.1318 11.8217 8.29122 11.8316 8.46085C11.8416 8.63048 11.7864 8.7975 11.6773 8.92781C11.5683 9.05811 11.4136 9.14185 11.2448 9.16192L11.1668 9.16658H5.8335C5.66358 9.1664 5.50014 9.10133 5.37658 8.98469C5.25303 8.86804 5.17867 8.70862 5.16871 8.53899C5.15876 8.36936 5.21395 8.20233 5.32301 8.07203C5.43207 7.94173 5.58677 7.85799 5.7555 7.83792L5.8335 7.83325H11.1668Z"
      fill="inherit"
    />
  </svg>
);
export default DeniedIcon;
