import { useMemo } from "react";

import { XIcon } from "lucide-react";

import { Button } from "@/components/ui/button.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import {
  DeleteSelectedResourceButton,
  MoveSelectedResourceButton,
  RenameSelectedResourceButton,
  ShareSelectedResourceButton,
} from "@/components/v3";
import { useAnyDocumentError } from "@/service/hooks/documents.ts";
import useAppStateStore from "@/zustand/store.ts";

const SelectedResourceToolbarContainer = () => {
  const [selectedResources, unselectAll] = useAppStateStore((state) => [
    state.selectedResources,
    state.unselectAllResources,
  ]);
  const anyError = useAnyDocumentError(selectedResources.map((item) => item.id));
  const text = useMemo(() => `${selectedResources.length} selected`, [selectedResources]);
  return (
    <section className="flex h-full flex-row items-center gap-1 lg:gap-6">
      <section className="flex flex-row items-center gap-2">
        <Button className="gap-0.5 lg:mr-2 lg:gap-2" size="icon" variant="icon" onClick={() => unselectAll()}>
          <XIcon className="h-4 w-4" />
        </Button>
        <Typography className="line-clamp-1 truncate !whitespace-normal !text-primary" size="sm">
          <span>{text}</span>
        </Typography>
      </section>
      <section className="flex flex-row items-center gap-1">
        {!anyError && (
          <div className="hidden">
            <MoveSelectedResourceButton />
          </div>
        )}
        {!anyError && selectedResources.length === 1 && <RenameSelectedResourceButton />}
        {!anyError && <ShareSelectedResourceButton />}
        <DeleteSelectedResourceButton />
      </section>
    </section>
  );
};

export default SelectedResourceToolbarContainer;
