import { GoogleDriveIcon } from "@/components/icons";
import { Button } from "@/components/ui/button.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { cn } from "@/utils";

const GoogleDrivePicker = ({ className }: { className?: string }) => {
  return (
    <Button
      className={cn(className)}
      size="sm"
      variant="secondary"
      onClick={() => {
        toast({
          title: "Coming soon!",
          variant: "success",
        });
      }}
    >
      <GoogleDriveIcon className="h-4 w-4" /> Select from Google Drive
    </Button>
  );
};

export default GoogleDrivePicker;
