import { useCallback, useEffect } from "react";

import { deleteUser } from "@firebase/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { deleteBrevoUser } from "@/api/brevo";
import { setAccountClosed } from "@/api/user.ts";
import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { useToast } from "@/components/ui/use-toast";
import { useAppAuthState } from "@/firebase/hooks";
import { mixpanelTrack } from "@/service/mixpanel";
import { useModalState } from "@/zustand/slices/modal-slice";

const formSchema = z
  .object({
    reason: z
      .enum([
        "too_expensive",
        "wrong_ai_results",
        "bad_user_experience",
        "just_wanted_to_try_out",
        "did_not_help_to_study",
      ])
      .optional(),
    otherReason: z.string().optional(),
  })
  .refine((data) => data.otherReason || data.reason);
type FormSchema = z.infer<typeof formSchema>;

const DeleteAccount = () => {
  const [user] = useAppAuthState();
  const [open, setModalKey] = useModalState(ModalKeys.DELETE_ACCOUNT_MODAL_OPEN);
  const { toast } = useToast();
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });
  const handleClose = useCallback(() => {
    setModalKey(false);
  }, [setModalKey]);

  const handleSubmit = async (data: FormSchema): Promise<void> => {
    if (!user) return;
    try {
      void deleteBrevoUser();
    } catch (e) {
      console.log("brevo error", e);
    }
    try {
      await setAccountClosed({
        payload: {
          reason: data.reason ? data.reason : data.otherReason ? data.otherReason : "no-reason",
        },
      });
    } catch (e) {
      console.log(e);
    }
    deleteUser(user)
      .then(() => {
        mixpanelTrack("account_closed");
      })
      .catch(() => {
        toast({
          title: "Due to security reasons please log out and log back in to prove your identity!",
          variant: "destructive",
        });
      });
  };

  useEffect(() => {
    form.reset();
    form.setValue("otherReason", undefined);
    form.setValue("reason", undefined);
  }, [open, form]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="gap-6 p-6">
        <DialogHeader>
          <DialogTitle className="text-h4 text-primary-onBg">Why do you want to delete your account?</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="reason"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormControl>
                    <RadioGroup
                      className="flex flex-col gap-3"
                      value={field.value}
                      onValueChange={(value) => {
                        field.onChange(value);
                        form.setValue("otherReason", "");
                      }}
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem checked={field.value === "too_expensive"} value="too_expensive" />
                        </FormControl>
                        <FormLabel className="text-label-l3-regular font-normal text-primary-onBg">
                          Too expensive
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem checked={field.value === "wrong_ai_results"} value="wrong_ai_results" />
                        </FormControl>
                        <FormLabel className="text-label-l3-regular font-normal text-primary-onBg">
                          Wrong AI Results
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem checked={field.value === "bad_user_experience"} value="bad_user_experience" />
                        </FormControl>
                        <FormLabel className="text-label-l3-regular font-normal text-primary-onBg">
                          Bad user experience
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem
                            checked={field.value === "just_wanted_to_try_out"}
                            value="just_wanted_to_try_out"
                          />
                        </FormControl>
                        <FormLabel className="text-label-l3-regular font-normal text-primary-onBg">
                          I just wanted to try out
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem
                            checked={field.value === "did_not_help_to_study"}
                            value="did_not_help_to_study"
                          />
                        </FormControl>
                        <FormLabel className="text-label-l3-regular font-normal text-primary-onBg">
                          It didn&apos;t help to study
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="otherReason"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      className="resize-none"
                      placeholder="Other..."
                      rows={5}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        form.setValue("reason", undefined);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="pt-6">
              <Button size="sm" type="button" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={!form.formState.isValid} size="sm" type="submit">
                Delete account
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteAccount;
