import LightBulb from "@/assets/svgs/maintenance/book-with-lightbulb.svg";
import Books from "@/assets/svgs/maintenance/books.svg";
import Hammer from "@/assets/svgs/maintenance/hammer.svg";
import Wrench from "@/assets/svgs/maintenance/wrench.svg";
import { DiscordIcon } from "@/components/icons/common";
import { Button } from "@/components/ui/button";
import { Divider } from "@/components/ui/divider";
import { useMixpanelTrack } from "@/service/mixpanel";

const Maintenance = () => {
  const mixpanelTrack = useMixpanelTrack();
  return (
    <main className="relative grid min-h-screen w-full place-content-center bg-white">
      <section className="mx-auto flex max-w-2xl flex-col items-center gap-8 px-6">
        <h1 className="text-center text-5xl font-bold text-primary md:text-8xl">Website under maintenance</h1>
        <h5 className="w-full text-center text-2xl text-primary sm:w-2/3 md:w-full">
          Until we come back, hop into our community channels or learn more about the possibilities and features of
          Quino:
        </h5>
        <Button
          id="join-discord-button"
          onClick={() => {
            mixpanelTrack("join_discord_button_click");
            window.open("https://discord.gg/Cq2JmCQem4")?.focus();
          }}
        >
          Join discord <DiscordIcon className="ml-2 fill-white" />{" "}
        </Button>
        <Button
          id="join-facebook-button"
          variant="ghost"
          onClick={() => {
            mixpanelTrack("join_facebook_button_click");
            window.open("https://www.facebook.com/groups/quinoai")?.focus();
          }}
        >
          Join our Facebook Group
        </Button>
        <Divider className="flex-1" classNameChild="flex-2 text-primary">
          or read more about the app
        </Divider>
        <Button
          id="open-quino-documentation-button"
          variant="secondary"
          onClick={() => window.open("https://docs.quino.ai/", "_blank")?.focus()}
        >
          Quino Documentation
        </Button>
        <img alt="LightBulb" className="absolute left-0 top-0 w-5/12 md:w-1/4" src={Books} />
        <img alt="LightBulb" className="absolute bottom-0 left-0 w-4/12 md:w-1/4" src={Wrench} />
        <img alt="LightBulb" className="absolute bottom-0 right-0 w-4/12 md:w-1/4" src={Hammer} />
        <img alt="LightBulb" className="absolute right-0 top-0 w-5/12 md:w-1/4" src={LightBulb} />
      </section>
    </main>
  );
};

export default Maintenance;
