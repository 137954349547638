import { ModalKeys } from "@/assets/constants/modal.ts";
import FunnyImage from "@/assets/svgs/refresh-page.svg";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { useUpgradePlanModal } from "@/service/library.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

export default function StopFunnyStuffBlockModal() {
  const [open] = useModalState(ModalKeys.STOP_ADBLOCK_MODAL);
  const [, setModalKey] = useUpgradePlanModal();

  return (
    <Dialog open={open}>
      <DialogContent hideClose className="max-h-[800px] w-full max-w-[800px] gap-6 p-10">
        <DialogHeader className="flex w-full">
          <DialogTitle className="text-2xl font-bold">Don&apos;t Block!</DialogTitle>
        </DialogHeader>
        <section className="flex w-full items-center justify-between">
          <Typography asChild>
            <h3>
              The free version of Quino relies on ads to sustain its operation. Please disable your ad blocker and
              refresh the page to access the free plan. ❌
              <br />
              <br />
              If you prefer an ad-free experience, consider subscribing to our premium plan and unlock even more
              features! ⏫ 💸
            </h3>
          </Typography>
        </section>
        <img alt="funny-stuff-image" src={FunnyImage} />
        <section
          className="flex flex-row-reverse items-center gap-8"
          style={{
            gap: "32px",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <Button
            color="primary"
            id={"subscribe-in-modal-button"}
            onClick={() => {
              setModalKey({ open: true });
            }}
          >
            Subscribe
          </Button>
          <Button
            id={`refresh-page-in-modal-button`}
            variant="ghost"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh Page
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
}
