import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const HomeIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-text-default", props.className)}
    >
      <path
        clipRule="evenodd"
        d="M10.6713 2.84313C11.4291 2.16956 12.571 2.16956 13.3287 2.84313L17.2629 6.34013L17.5136 4.8356C17.6044 4.29083 18.1196 3.92281 18.6644 4.0136C19.2092 4.1044 19.5772 4.61963 19.4864 5.1644L19.0288 7.90988L21.6644 10.2526C22.0772 10.6195 22.1143 11.2516 21.7474 11.6644C21.3805 12.0771 20.7484 12.1143 20.3356 11.7474L19.8715 11.3348L19.0851 19.199C18.9829 20.2214 18.1225 21 17.095 21H6.905C5.8775 21 5.01716 20.2214 4.91492 19.199L4.12851 11.3348L3.66437 11.7474C3.25159 12.1143 2.61952 12.0771 2.2526 11.6644C1.88568 11.2516 1.92286 10.6195 2.33565 10.2526L10.6713 2.84313ZM5.95707 9.70945C5.97557 9.77064 5.98845 9.83449 5.99505 9.90049L6.905 19H17.095L18.005 9.90049C18.0116 9.83449 18.0245 9.77064 18.043 9.70945L12 4.33795L5.95707 9.70945Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default HomeIcon;
