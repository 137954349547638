import { useMemo } from "react";

import { format } from "date-fns";
import { NavLink } from "react-router-dom";

import { useCheckIfOldSubscription } from "@/api/subscription";
import { ROUTES } from "@/assets/constants/constants";
import { ModalKeys } from "@/assets/constants/modal";
import {
  CancellationIcon,
  CheckmarkIcon,
  CompareIcon,
  RecurringPaymentIcon,
  SmallPurpleCalendarIcon,
} from "@/components/icons";
import { Button } from "@/components/ui/button";
import { type SubscriptionResponse } from "@/types/schemas";
import { useModalState } from "@/zustand/slices/modal-slice";

const CurrentPlanInfoCard = ({
  currentPlan,
  isPlanFree,
}: {
  currentPlan: SubscriptionResponse;
  isPlanFree: boolean;
}) => {
  const [, setCancellationModalOpen] = useModalState(ModalKeys.CANCELLATION);
  const { data: isOldSubscription } = useCheckIfOldSubscription();

  const content = useMemo(() => {
    return isPlanFree ? (
      <div className="flex flex-1 gap-6">
        <div className="flex flex-1 flex-col gap-2">
          <h5 className="text-h6 font-bold text-primary-onBg">Free plan</h5>
          <p className="mb-2 text-caption-1 text-secondary-onBg">Current plan</p>
          <div>
            <div className="mb-1 flex gap-2">
              <CheckmarkIcon />
              <p className="text-sm text-primary-onBg">No payment required</p>
            </div>
            {/*{limits?.documentSummarizationPerMonthLimit && (*/}
            {/*  <div className="flex gap-2">*/}
            {/*    <DocumentPageIcon />*/}
            {/*    <p className="text-sm text-primary-onBg">*/}
            {/*      {`${*/}
            {/*        limits?.documentSummarizationPerMonthLimit - currentPlan.fullDocumentSummaryPerMonthCounter*/}
            {/*      } of ${limits?.documentSummarizationPerMonthLimit} document summaries has left.`}*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*)}*/}
            {/* {currentPlan.subscriptionPlan === "all_access_free" && (
              <div className="flex gap-2">
                <DocumentPageIcon />
                <p className="text-sm text-primary-onBg">
                  {`You have unlimited summaries left.`}
                </p>
              </div>
            )} */}
          </div>
        </div>
        <div>
          <NavLink to={ROUTES.SUBSCRIPTION}>
            <Button variant="secondary">
              Compare plans
              <CompareIcon />
            </Button>
          </NavLink>
        </div>
      </div>
    ) : (
      <div className="flex flex-1 gap-6">
        <div className="flex flex-1 flex-col gap-2">
          <h5 className="text-h6 text-primary-onBg">Pro plan</h5>
          <p className="mb-2 text-caption-1 text-secondary-onBg">Current plan</p>
          <div>
            <div className="mb-1 flex gap-2">
              <RecurringPaymentIcon />
              <p className="text-sm text-primary-onBg">
                {currentPlan?.period === "monthly"
                  ? "14 EUR / month, monthly billing"
                  : "12 EUR / month, yearly billing"}
              </p>
            </div>
            <div className="flex gap-2">
              <SmallPurpleCalendarIcon />
              <p className="text-sm text-primary-onBg">
                Subscription renews on {format(new Date(currentPlan?.subscriptionEndsAt ?? 0), "MMM dd, yyyy")}
              </p>
            </div>
          </div>
        </div>
        <div>
          {isOldSubscription?.isOld ? (
            <Button variant="secondary" onClick={() => setCancellationModalOpen(true)}>
              Ask for cancellation
              <CancellationIcon />
            </Button>
          ) : (
            <NavLink to={ROUTES.SUBSCRIPTION}>
              <Button variant="secondary">
                Manage subscription
                <CompareIcon />
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    );
  }, [
    isPlanFree,
    currentPlan?.period,
    currentPlan?.subscriptionEndsAt,
    setCancellationModalOpen,
    isOldSubscription?.isOld,
  ]);

  return <>{content}</>;
};

export default CurrentPlanInfoCard;
