import { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { useSubscription } from "@/api/resources.ts";
import { getAnnouncements } from "@/api/strapi";
import { getActivityHistory } from "@/api/user.ts";
import { useAuthUserData, useFirebaseUserId, useIsAuthenticated, useMaintenanceMode } from "@/firebase/hooks.ts";
import Loading from "@/pages/loading";
import { useIsCountryAllowed } from "@/service/user.ts";

const GuardLoader = () => {
  const authUserId = useFirebaseUserId();
  const { isLoading: loading } = useAuthUserData();
  const [isMaintenance, isMaintenanceLoading] = useMaintenanceMode();
  const isCountryAllowed = useIsCountryAllowed();
  const [, isAuthenticatedLoading] = useIsAuthenticated();
  const { isLoading: isSubscriptionLoading } = useSubscription();

  const queryClient = useQueryClient();
  const [loopFinised, setLoopFinished] = useState<boolean>(true);

  const isLoaderVisible = useMemo(
    () =>
      loading || isMaintenanceLoading || isCountryAllowed === null || isAuthenticatedLoading || isSubscriptionLoading,
    [isAuthenticatedLoading, isCountryAllowed, isMaintenanceLoading, isSubscriptionLoading, loading],
  );

  const internalLoaderVisible = useMemo(() => isLoaderVisible || !loopFinised, [isLoaderVisible, loopFinised]);

  useEffect(() => {
    if (isLoaderVisible) {
      setLoopFinished(false);
    }
  }, [isLoaderVisible]);

  useEffect(() => {
    if (isLoaderVisible && authUserId) {
      void queryClient.prefetchQuery({
        queryKey: ["announcements"],
        queryFn: getAnnouncements,
      });
      void queryClient.prefetchInfiniteQuery({
        queryKey: [authUserId, "activity-history"],
        queryFn: ({ pageParam }) => getActivityHistory(pageParam),
        initialPageParam: 0,
      });
    }
  }, [isLoaderVisible, authUserId, queryClient]);

  if (isMaintenance) return null;

  return (
    <section
      className="fixed left-0 top-0 z-[100000] h-screen w-screen bg-default data-[hidden=true]:hidden"
      data-hidden={!internalLoaderVisible}
    >
      {internalLoaderVisible && <Loading onLoopFinished={() => setLoopFinished(true)} />}
    </section>
  );
};

export default GuardLoader;
