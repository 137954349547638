import { useMemo } from "react";

import { Link } from "react-router-dom";

import { useCurrentWorkspace } from "@/api/workspace.ts";
import { ROUTES } from "@/assets/constants/constants";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from "@/components/ui/breadcrumb.tsx";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentProjectData } from "@/utils";

const WorkspaceHeaderBreadcrumb = () => {
  const { data: workspace } = useCurrentWorkspace();
  const { data: project } = useCurrentProjectData();
  const { matched: isDemo } = useIsDemoLikePage();
  const link = useMemo(() => {
    if (isDemo) return `${ROUTES.DEMO}${ROUTES.WORKSPACES}/${workspace?.id}`;
    return `${ROUTES.WORKSPACES}/${workspace?.id}`;
  }, [isDemo, workspace?.id]);
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <Link to={link}>
          <BreadcrumbItem className="text-xl font-bold text-primary">{workspace?.name}</BreadcrumbItem>
        </Link>
        {project && (
          <>
            <BreadcrumbSeparator className="mt-1 [&>svg]:h-4 [&>svg]:w-4" />
            <BreadcrumbItem className="text-xl font-bold text-primary">{project?.name}</BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
export default WorkspaceHeaderBreadcrumb;
