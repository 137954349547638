import { type ReactNode } from "react";

import { Typography } from "@/components/ui/typography.tsx";

const PanelHeader = ({ title, children }: { title?: string; children?: ReactNode | ReactNode[] }) => {
  return (
    <section
      className="sticky top-0 z-10 flex flex-row items-center justify-between bg-bg-layer1 px-6 pb-3 pt-6 dark:border-b-white/25"
      key={"content"}
    >
      <Typography asChild size="large">
        <h1 className="mr-2 truncate !whitespace-nowrap font-bold text-primary">{title}</h1>
      </Typography>
      <section className="flex flex-row gap-2">{children} </section>
    </section>
  );
};
export default PanelHeader;
