import { useMemo } from "react";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { CheckCircleFilled } from "@/components/v3";
import CloseCircleFilled from "@/components/v3/icons/common/close-circle-filled.tsx";
import { cn } from "@/utils";

const variantToIconMap = {
  default: null,
  success: <CheckCircleFilled />,
  destructive: <CloseCircleFilled />,
  warning: null,
  loading: null,
};

export function Toaster() {
  const { toasts } = useToast();

  const currentToast = useMemo(() => (toasts.length > 0 ? toasts[0] : null), [toasts]);

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        variant,
        duration = 5000,
        icon,
        description,
        action,
        secondaryAction,
        ...props
      }) {
        const theVariant = variant ?? "default";
        const renderedIcon = icon ?? variantToIconMap[theVariant];
        return (
          <Toast key={id} {...props} data-variant={theVariant} duration={duration} variant={theVariant}>
            <div className="flex w-full flex-row gap-4 overflow-hidden">
              {renderedIcon && <ToastIcon className={cn(!description && "self-center")}>{renderedIcon}</ToastIcon>}
              <section className="flex flex-1 flex-col justify-center gap-6 overflow-hidden">
                <section className="flex flex-col  gap-1">
                  {title && <ToastTitle className="">{title}</ToastTitle>}
                  {description && <ToastDescription>{description}</ToastDescription>}
                </section>
                {action && secondaryAction && (
                  <section className="flex flex-row gap-2">
                    {action}
                    {secondaryAction}
                  </section>
                )}
              </section>
            </div>
            {!secondaryAction && action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport className={currentToast?.viewportClassname} />
    </ToastProvider>
  );
}
