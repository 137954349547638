import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const FriendsAndFamily = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <path
      d="M37.7018 51.9806L38.339 44.7399C37.0214 45.1608 35.3195 45.5817 33.3003 45.7525C30.9091 45.9538 28.8717 45.7525 27.3589 45.478V51.9806C28.3532 52.4625 30.0368 53.1153 32.2145 53.2251C35.0755 53.3715 36.6343 52.493 37.7018 51.9806Z"
      fill="#FDF3EE"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M18.4395 23.4018C18.3358 27.0191 18.287 30.6974 18.2992 34.6137C18.2992 36.3705 18.3297 38.4323 19.5741 40.5307C19.7266 40.7869 20.4952 42.0009 21.9836 43.2026C25.6864 46.2038 30.481 45.7829 32.2683 45.6304C35.05 45.3864 38.3928 45.0997 41.028 42.3608C44.1269 39.1399 43.9988 34.8638 43.95 33.9122C43.95 33.9122 50.2818 35.0041 51.0077 30.0691C51.7336 25.1342 45.5055 24.8231 41.9735 25.2928C41.9735 25.2928 42.0772 20.6201 40.6742 19.5831C38.9052 20.2114 37.002 20.797 34.9768 21.3155C28.8096 22.8894 23.1731 23.3896 18.4395 23.3957V23.4018Z"
      fill="#FDF3EE"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M46.0898 27.2163C46.9805 27.0394 47.8955 27.3566 48.4628 28.0337C49.1094 28.8084 49.2009 29.9369 48.6824 30.8458"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M49.9279 25.9209C50.0438 25.2438 52.0508 12.7082 45.0113 7.8647C42.3883 6.06518 39.4419 5.96758 34.6168 5.80898C28.6936 5.61378 24.5395 5.43688 21.221 8.25511C17.1279 11.726 16.9449 17.4662 16.9632 19.2718C11.3572 20.8456 6.47109 22.4561 6.62359 23.3284C6.62359 23.3284 6.63579 23.426 6.7395 23.5053C8.08761 24.5972 31.3105 23.2857 40.6924 19.3145C41.8698 19.5402 44.017 20.1075 46.1947 21.7179C48.2077 23.2003 49.3484 24.9022 49.934 25.9209H49.9279Z"
      fill="#ED8657"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M39.3525 7.43933C39.3525 7.43933 41.2192 11.1787 40.7556 16.1624"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M35.9016 31.2646L34.9316 33.2106"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M33.3983 35.707C32.8737 36.1706 31.794 36.9941 30.1897 37.3967C28.8965 37.7201 27.7985 37.6286 27.1396 37.5249"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M28.2253 28.4111L26.5112 34.3831H29.4698"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M32.8342 29.3697C33.5113 29.8943 34.6032 29.7723 35.0851 29.0647C35.4389 28.54 35.3962 27.7958 35.0241 27.3505C34.3653 26.5575 32.7244 26.7649 32.3157 27.6616C32.0656 28.2106 32.3157 28.9671 32.8281 29.3697H32.8342Z"
      fill="#B4341F"
    />
    <path
      d="M22.5971 29.3673C23.3657 29.7394 24.4149 29.3978 24.7382 28.6048C24.9761 28.0192 24.7809 27.2994 24.3295 26.9395C23.5182 26.299 21.9566 26.8419 21.7492 27.8057C21.6211 28.3974 22.0176 29.0867 22.6032 29.3673H22.5971Z"
      fill="#B4341F"
    />
    <path
      d="M37.7664 25.1161C37.0161 24.8599 36.0339 24.5976 34.8749 24.4939C33.2157 24.3413 31.831 24.5549 30.8794 24.7806"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M26.1599 24.8056C25.3913 24.6043 24.3543 24.4152 23.1221 24.4091C21.8898 24.403 20.8528 24.5799 20.0781 24.769"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M40.0991 43.6489C40.0991 43.6489 47.5717 42.2824 49.0174 33.175C49.0174 33.175 46.8397 34.6574 43.9605 33.9132C43.9605 33.9132 44.3082 39.8302 40.1052 43.6489H40.0991Z"
      fill="#B4341F"
    />
    <path
      d="M41.0378 19.5831C41.0378 19.5831 42.2579 22.1025 41.9711 25.2928C41.9711 25.2928 45.863 24.4876 49.8341 26.0736C49.8341 26.0736 47.6259 20.4432 41.0317 19.5831H41.0378Z"
      fill="#B4341F"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M21.0596 31.0081L21.9197 32.7954"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M22.248 30.8762L23.1021 32.6635"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M17.5474 19.8778C17.5474 19.8778 31.3518 20.3658 37.8728 19.2739"
      stroke="#B4341F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);
export default FriendsAndFamily;
