import { useCallback, useEffect, useMemo } from "react";

import { type User } from "@firebase/auth";
import { useLocalStorage } from "usehooks-ts";

import { useSubscription } from "@/api/resources.ts";
import { handlePortalLinkCreation } from "@/api/stripe";
import { useCurrentWorkspace } from "@/api/workspace.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useUpgradePlanModal } from "@/service/library.ts";
import { type CheckoutLinkParams, type StripePlan } from "@/types/schemas";
import { createCheckoutLink } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

export async function handlePaymentRedirect({ user, priceId }: { user: User; priceId: string }): Promise<void> {
  if (!user || !user.email || !user.uid) {
    toast({ title: "There was a problem redirecting you to our payment page", variant: "warning" });
    return;
  }
  const { email, uid } = user;

  const checkoutLinkParams: CheckoutLinkParams = {
    email,
    userId: uid,
    priceId,
    mode: "subscription",
  };

  toast({ title: "We are redirecting you to our payment platform...", variant: "default" });
  await createCheckoutLink(checkoutLinkParams);
}

export const portalCreateHandler = async (): Promise<void> => {
  const portalLink = await handlePortalLinkCreation();
  window.location.assign(portalLink);
};

export const calculatePrice = (plan: StripePlan) => {
  return {
    price:
      plan.metadata.type === "yearly" ? (plan.unitAmount / 100 / 12).toFixed(0) : (plan.unitAmount / 100).toFixed(0),
    monthlyPrice:
      plan.metadata.type === "yearly"
        ? ((plan.unitAmount / 100 / 12) * 1.2).toFixed(0)
        : (plan.unitAmount / 100).toFixed(0),
  };
};

export const useIsSummaryUsedUp = () => {
  const { data: subscription } = useSubscription();
  const { data: workspace } = useCurrentWorkspace();
  return useMemo(() => {
    if (!workspace) return true;

    if (workspace.userIds.length > 1) return;
    if (
      subscription?.restrictions?.monthlySingleWorkspaceFdsUsage &&
      subscription?.usageMetrics?.monthlySingleWorkspaceFdsUsage
    ) {
      return (
        subscription.usageMetrics.monthlySingleWorkspaceFdsUsage >=
        subscription.restrictions.monthlySingleWorkspaceFdsUsage
      );
    }
    return false;
  }, [
    subscription?.restrictions?.monthlySingleWorkspaceFdsUsage,
    subscription?.usageMetrics?.monthlySingleWorkspaceFdsUsage,
    workspace,
  ]);
};

export const useIsPdfExportAllowed = () => {
  const { data: subscription } = useSubscription();

  return useMemo(() => {
    if (subscription?.restrictions?.noPdfExport !== undefined) {
      return false;
    }
    return true;
  }, [subscription?.restrictions?.noPdfExport]);
};

export const useExportHandler = (callback: () => void) => {
  const isAllowed = useIsPdfExportAllowed();
  const [, setModal] = useUpgradePlanModal();

  return useCallback(() => {
    if (!isAllowed) {
      setModal({ open: true });
      return;
    }
    callback();
  }, [isAllowed, callback, setModal]);
};

export const useRenewSubscriptionChecked = () => {
  return useLocalStorage("RENEW_CHECKED", false);
};

export const useShowRenewSubscription = () => {
  const { data: user } = useSubscription();
  const [, setOpen] = useModalState(ModalKeys.RENEW_SUBSCRIPTION);
  const [checked] = useRenewSubscriptionChecked();
  useEffect(() => {
    if (user?.subscriptionEndsAt && !checked) {
      const endDate = new Date(user.subscriptionEndsAt);
      const now = new Date();

      if (now.getTime() - endDate.getTime() > 0) {
        setOpen(true);
      }
    }
  }, [user, checked, setOpen]);
};
