import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { ROUTES, SCREEN_SIZE_CONFIG, THEME_MODE } from "@/assets/constants/constants";
import { useAuthUserData, useUpdateFirebaseUserData } from "@/firebase/hooks.ts";
import { type FontScale, type FontSizeAppConfig } from "@/types/app.ts";

const getFontConfig = (value: FontScale) => {
  switch (value) {
    case "small":
      return {
        label: "text-sm",
        choice: "text-xl leading-[1.75rem]",
        main: "text-[2rem] leading-[3rem]",
      };
    case "normal":
      return {
        label: "text-lg",
        choice: "text-[1.375rem] leading-[1.875rem]",
        main: "text-[2rem] leading-[3rem]",
      };
    case "large":
      return {
        label: "text-xl",
        choice: "text-[1.625rem] leading-[2.125rem]",
        main: "text-[2.25rem] leading-[3.25rem]",
      };
    default:
      return {
        label: "text-lg",
        choice: "text-[1.375rem] leading-[1.875rem]",
        main: "text-[2rem] leading-[3rem]",
      };
  }
};

export const useTypographyConfig = (): [FontSizeAppConfig, Dispatch<SetStateAction<FontSizeAppConfig>>] => {
  const { data } = useAuthUserData();
  const { matched: isDemo } = useIsDemoLikePage();

  const [actualValue, setValue] = useLocalStorage<FontSizeAppConfig>(SCREEN_SIZE_CONFIG, {
    variant: "default",
    size: "normal",
  });

  useEffect(() => {
    const size = data?.letterSize;
    if (size)
      setValue((prev) => {
        return { ...prev, size: size };
      });
  }, [data?.letterSize, setValue]);

  const value: FontSizeAppConfig = useMemo(
    () =>
      isDemo
        ? {
            variant: "default",
            size: "normal",
          }
        : actualValue,
    [actualValue, isDemo],
  );

  return useMemo(() => [value, setValue], [setValue, value]);
};
export const useKnowledgeCardFonts = () => {
  const [config] = useTypographyConfig();
  return useMemo(() => getFontConfig(config.size), [config.size]);
};

export const useThemeMode = (): [
  "dark" | "light" | undefined,
  Dispatch<SetStateAction<"dark" | "light" | undefined>>,
] => {
  const { data } = useAuthUserData();
  const { mutate: updateUser } = useUpdateFirebaseUserData();
  const [themeMode, setThemeMode] = useLocalStorage<"light" | "dark" | undefined>(THEME_MODE, undefined);
  const [initialLoad, setInitialLoad] = useState(true);

  const disabledCall = useCallback(() => {}, []);

  useEffect(() => {
    if (data?.themeMode) setThemeMode(data.themeMode);
  }, [data?.themeMode, setThemeMode]);

  useEffect(() => {
    if (themeMode === "dark") {
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("light");
      if (!initialLoad)
        updateUser({
          payload: {
            themeMode: "dark",
          },
        });
      else setInitialLoad(false);
    } else if (themeMode === "light") {
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("light");
      if (!initialLoad)
        updateUser({
          payload: {
            themeMode: "light",
          },
        });
      else setInitialLoad(false);
    } else {
      document.documentElement.classList.remove("light");
      document.documentElement.classList.remove("light");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeMode, updateUser, updateUser]);

  return [themeMode, disabledCall];
};
export const isDemoPage = () => location.pathname.includes(ROUTES.DEMO);

export const useIsDemoLikePage = () => {
  const location = useLocation();
  return useMemo(() => {
    if (location.pathname.includes(ROUTES.DEMO)) return { matched: true, route: ROUTES.DEMO };
    if (location.pathname.includes(ROUTES.TEMPORARY)) return { matched: true, route: ROUTES.TEMPORARY };
    return { matched: false, route: "" };
  }, [location.pathname]);
};
export const useIsTemporaryPage = () => {
  const location = useLocation();
  return useMemo(() => {
    if (location.pathname.includes(ROUTES.TEMPORARY)) return { matched: true, route: ROUTES.TEMPORARY };
    return { matched: false, route: "" };
  }, [location.pathname]);
};
export const useIsDemoPage = () => {
  const location = useLocation();
  return useMemo(() => {
    if (location.pathname.includes(ROUTES.DEMO)) return { matched: true, route: ROUTES.DEMO };
    return { matched: false, route: "" };
  }, [location.pathname]);
};
