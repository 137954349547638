import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useSubscription } from "@/api/resources";
import { ROUTES } from "@/assets/constants/constants";
import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useUpgradePlanModal } from "@/service/library.ts";
import { useModalState } from "@/zustand/slices/modal-slice";

function UpgradePlanModal() {
  const [modalState, setPlanModalOpen] = useUpgradePlanModal();
  const [, setChurnedModal] = useModalState(ModalKeys.CHURNED_USER_MODAL);
  const { data: currentPlan } = useSubscription();
  const navigate = useNavigate();

  const handleClose = (): void => {
    setPlanModalOpen({ open: false });

    if (modalState.conversion && currentPlan?.subscriptionPlan === "free") {
      setChurnedModal(true);
    }
  };

  const onClick = useCallback(() => {
    navigate(ROUTES.SUBSCRIPTION);
    setPlanModalOpen({ open: false });
  }, [setPlanModalOpen, navigate]);

  return (
    <Dialog open={modalState.open} onOpenChange={handleClose}>
      <DialogContent className="gap-12 p-6">
        <DialogHeader className="gap-4">
          <DialogTitle asChild>
            <h3 className="text-2xl font-bold tracking-[-0.2px] text-primary-onBg">Upgrade your plan</h3>
          </DialogTitle>
          <DialogDescription asChild className="!mt-0">
            <p className="text-xs font-normal text-secondary-onBg">
              You&apos;ve reached the limit of your free plan. Upgrade now to unlock unlimited uploads and take full
              advantage of all our premium features.{" "}
            </p>
          </DialogDescription>
        </DialogHeader>
        <section className="flex flex-row justify-center">
          <img alt="Upgrade illustration" src="/files/images/v3-design/upgrade-illustration.svg" />
        </section>
        <DialogFooter>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={onClick}>Subscribe to Quino</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default UpgradePlanModal;
