import { useCallback, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateProject } from "@/api/project.ts";
import { Button } from "@/components/ui/button.tsx";
import { FormControl, Form, FormField, FormItem, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { EditIcon, IconSelectorPopover, QuinoPopoverTitle } from "@/components/v3";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentSelectedProject } from "@/service/library.ts";
import { ProjectIconsEnum } from "@/types/schemas";

const formSchema = z.object({
  name: z.string().min(1, { message: "Firstname is required!" }),
  icon: z.object({
    iconColor: z.string().length(7).regex(/^#/),
    iconStyle: z.nativeEnum(ProjectIconsEnum),
  }),
});

type FormSchema = z.infer<typeof formSchema>;

const RenameSelectedProjectButton = () => {
  const project = useCurrentSelectedProject();

  const form = useForm<FormSchema>({
    defaultValues: {
      name: "",
    },
    resolver: zodResolver(formSchema),
  });

  const { mutate: updateDocument } = useUpdateProject();
  const [isOpen, setIsOpen] = useState(false);
  const currentWorkspaceId = useCurrentWorkspaceId();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSubmit = useCallback(
    (data: FormSchema) => {
      updateDocument({
        projectId: project?.id ?? "",
        workspaceId: currentWorkspaceId,
        payload: {
          name: data.name,
          colorTag: data.icon.iconColor,
          projectIcon: data.icon.iconStyle,
        },
      });
      onClose();
    },
    [currentWorkspaceId, onClose, project?.id, updateDocument],
  );

  useEffect(() => {
    if (project) {
      form.setValue("name", project.name);
      form.setValue("icon", {
        iconColor: project.colorTag ?? "#572E9E",
        iconStyle: (project.projectIcon as ProjectIconsEnum) ?? ProjectIconsEnum.CHART,
      });
    } else {
      setTimeout(() => {
        form.setValue("name", "");
        form.setValue("icon", { iconColor: "", iconStyle: ProjectIconsEnum.CHART });
      }, 300);
    }
  }, [form, form.setValue, isOpen, project]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button size="sm" variant="ghost">
          <EditIcon />
          Rename
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[25rem] space-y-1">
        <QuinoPopoverTitle title="Rename project" />
        <Form {...form}>
          <form className="flex flex-row items-center gap-2 p-2 pt-0" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input className="resize-none" classNameRoot="flex-1" placeholder="Project name" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="icon"
              render={({ field }) => (
                <FormItem className="space-y-0">
                  <FormControl>
                    <IconSelectorPopover
                      selectedProjectIcon={{ iconStyle: field.value.iconStyle, iconColor: field.value.iconColor }}
                      setSelectedIcon={(value) => {
                        field.onChange(value);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button size="sm">Save</Button>
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  );
};

export default RenameSelectedProjectButton;
