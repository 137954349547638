import { type ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from "react";

import _ from "lodash";
import { ReactTyped } from "react-typed";
import { useBoolean } from "usehooks-ts";

import { useDocumentChatElementLocal, useQuestionSuggestion, useUpdateHistoryElement } from "@/api/document-chat";
import { useStreamingMessage } from "@/api/document-chat";
import { useCurrentProjectDocumentDictionary } from "@/api/document.ts";
import { LAST_ELEMENT_ID } from "@/assets/constants/chat.ts";
import { ModelLogFeedback } from "@/assets/constants/constants.ts";
import AddToNoteButton from "@/components/button/add-to-note-button.tsx";
import { QuinoIcon, Spinner } from "@/components/icons/common";
import { SuggestionSkeleton, QuestionsSuggestionArrow } from "@/components/icons/common";
import {
  DislikeIcon,
  DislikeIconFilled,
  HideSourceButton,
  LikeIcon,
  LikeIconFilled,
  UpLineFirst,
  UpLineSecond,
  UpLineThird,
} from "@/components/icons/summary";
import { Avatar } from "@/components/ui/avatar.tsx";
import { Button } from "@/components/ui/button.tsx";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { QALinkingIcon } from "@/components/v3/icons";
import { LoadingSpinner } from "@/pages/loading";
import { useIsChatLoading } from "@/service/chat.ts";
import type { GenericFetchError } from "@/service/errors";
import { useChatDocuConfig } from "@/service/hooks/documents.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useNavigateToDocumentPage } from "@/service/hooks/react-router.ts";
import { useOpenSignUpToQuinoModal } from "@/service/library.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { formatWithUTC } from "@/service/time-ago.ts";
import { type DocumentChatItem, type TaskType } from "@/types/schemas";
import { useStringifiedFeedback } from "@/utils";
import { QuinoMarkdownChat } from "@/utils/util-components.tsx";
import useAppStateStore from "@/zustand/store.ts";

const QuinoAnswerError = ({ title, question }: { title: string; question: string }) => {
  const mixpanelTrack = useMixpanelTrack();
  const setSearchPayload = useAppStateStore((state) => state.setChatDocuSearch);
  const setCallback = useCallback(() => {
    mixpanelTrack("document_chat_reload_failed_response");
    setSearchPayload({ text: question });
  }, [mixpanelTrack, question, setSearchPayload]);

  return (
    <section className="mr-auto flex w-10/12 flex-col gap-4 rounded-2xl border border-error bg-error-light p-4 animate-in fade-in-0 slide-in-from-left-2">
      <p className="font-bold text-primary"> {title}</p>
      <p className="text-sm text-error">We couldn’t give you a response due to an internal error.</p>
      <section className="flex flex-row justify-center">
        <Button className="font-bold" variant="outline" onClick={setCallback}>
          Reload Response
        </Button>
      </section>
    </section>
  );
};

const getPriority = (value: number) => {
  if (value < 0.33) {
    return <UpLineFirst className="fill-important-secondary" />;
  }
  if (value < 0.7) {
    return <UpLineSecond className="fill-important-primary" />;
  }
  return <UpLineThird className="fill-primitive-orange-600" />;
};

function useSourceComponents(
  entity: DocumentChatItem | undefined,
  mixpanelTrack: (message: string, payload?: object) => void,
  navigateToDocumentPage: (documentId: string, page: number, searchTarget?: string) => void,
) {
  const projectDocuments = useCurrentProjectDocumentDictionary();

  return useMemo(
    () =>
      entity?.modelError
        ? null
        : entity?.sources
        ? Object.keys(entity.sources).map((sourceKey) => {
            let title = sourceKey;
            if (Object.hasOwn(projectDocuments, sourceKey)) {
              title = projectDocuments[sourceKey].name;
            }
            return (
              <section className="mb-2" key={sourceKey}>
                <Typography className="truncate font-bold text-secondary-onBg" size="extra-small">
                  {title}
                </Typography>
                <section className="mt-[1.2rem] flex flex-row flex-wrap gap-1.5">
                  {entity.sources[sourceKey].map((item, index) => (
                    <HoverCard key={item.id}>
                      <HoverCardTrigger>
                        <Button
                          className="h-8 gap-1 p-0 px-2 font-bold"
                          // style={{
                          //   backgroundColor: `rgba(51,18,77,${clamp(item.relationScore, 0.1, 1)})`,
                          //   color: clamp(item.relationScore, 0, 1) > 0.5 ? "white" : "#33124D",
                          // }}
                          size="xs"
                          variant="secondary"
                          onClick={() => {
                            mixpanelTrack("document_chat_source_click", { idx_of_source: index });
                            navigateToDocumentPage(item.documentId, item.documentPageNum, item.sourceText);
                          }}
                        >
                          Page {item.documentPageNum} {getPriority(item.relationScore)}
                        </Button>
                      </HoverCardTrigger>
                      <HoverCardContent
                        align="start"
                        className="z-[4000] h-80 max-h-[20rem] w-80 overflow-hidden break-words rounded-lg border border-stroke-secondary-onBg p-4 text-primary"
                        side="bottom"
                      >
                        <section className="h-full max-h-full w-full overflow-hidden overflow-y-auto pr-2">
                          {item.sourceText}
                        </section>
                      </HoverCardContent>
                    </HoverCard>
                  ))}
                </section>
              </section>
            );
          })
        : null,
    [entity?.modelError, entity?.sources, mixpanelTrack, navigateToDocumentPage, projectDocuments],
  );
}

const TypeWriter = () => {
  const randomOrderMessages = useMemo(
    () =>
      _.shuffle([
        "Hold tight, crunching the text files...",
        "One moment, diving into the details...",
        "Give me a moment, dissecting the information...",
        "Hang on, processing your request...",
        "Wait a moment, sorting through the data...",
        "Just a second, sifting through the facts...",
        "Almost there, scrutinizing the details...",
        "Just a little patience, reviewing your document...",
        "Stay with me, examining the data closely...",
        "Hold on, parsing through the information...",
        "Just a tick, dissecting your document...",
        "In progress, analyzing your data...",
        "Almost done, digging into the details...",
        "Just a jiffy, processing your information...",
        "Be patient, delving into the data...",
      ]),
    [],
  );

  return (
    <section className="my-2 text-base text-primary">
      <ReactTyped loop backSpeed={12} strings={randomOrderMessages} typeSpeed={12} />
    </section>
  );
};

const QuestionSuggestion = ({
  suggestion,
  position,
  hasChatItem = false,
}: {
  suggestion: { task: string; taskType: TaskType };
  position: number;
  hasChatItem?: boolean;
}) => {
  const { mutate: sendStreamingMessage } = useStreamingMessage();
  const [storageState] = useChatDocuConfig();
  const mixpanelTrack = useMixpanelTrack();
  const click = useCallback(() => {
    mixpanelTrack("qa_recommendation_clicked", {
      position,
      at_start: !hasChatItem,
    });
    sendStreamingMessage({ text: suggestion.task, projectWide: !storageState.documentOnly });
  }, [hasChatItem, mixpanelTrack, position, sendStreamingMessage, storageState.documentOnly, suggestion]);

  return (
    <Button
      className="flex h-auto min-h-[48px] justify-start border-primary/25 p-0 py-[13px] pl-[12px] pr-12 text-left text-sm font-normal"
      variant="outline"
      onClick={click}
    >
      <Typography asChild className="leading-6">
        <span>{suggestion.task}</span>
      </Typography>
      <QuestionsSuggestionArrow />
    </Button>
  );
};
export const QuestionSuggestionList = ({ item, emptyEntity }: { item?: DocumentChatItem; emptyEntity?: boolean }) => {
  const { data: questionSuggestions, isLoading, isError, error } = useQuestionSuggestion(item);
  const openSignUp = useOpenSignUpToQuinoModal();

  const isChatLoading = useIsChatLoading();
  const { matched: isDemoPage } = useIsDemoLikePage();

  const content = useMemo(() => {
    if (isError) return null;
    return isLoading || emptyEntity || isChatLoading ? (
      <section className="mt-10 h-full min-w-[350px] space-y-2">
        <SuggestionSkeleton color={item?.inputText ? "bg-white" : "bg-neutral-100"} />
        <SuggestionSkeleton color={item?.inputText ? "bg-white" : "bg-neutral-100"} />
        <SuggestionSkeleton color={item?.inputText ? "bg-white" : "bg-neutral-100"} />
      </section>
    ) : (
      <section className="relative mb-8 mt-5 flex w-full flex-col gap-3">
        {questionSuggestions?.questionsList
          .filter((suggestion) => suggestion.task.length > 0)
          .map((suggestion, index) => (
            <QuestionSuggestion
              hasChatItem={item !== undefined}
              key={suggestion.task}
              position={index}
              suggestion={suggestion}
            />
          ))}
      </section>
    );
  }, [isError, isLoading, emptyEntity, isChatLoading, item, questionSuggestions?.questionsList]);

  useEffect(() => {
    if (isError) {
      try {
        const _error = error as GenericFetchError;
        if (_error?.payload?.status === 429) openSignUp();
      } catch (e) {
        console.error("Something went wrong", e);
      }
    }
  }, [error, isError, openSignUp]);

  return (
    <section className="data-[hidden=true]:hidden" data-hidden={isChatLoading}>
      {!isDemoPage ? null : content}
    </section>
  );
};

const AnswerContext = createContext<{
  showSources: boolean;
  toggleSources: () => void;
}>({
  showSources: false,
  toggleSources: () => {
    throw new Error("Not implemented");
  },
});

const QuinoAnswerHoverCard = ({
  children,
  documentChatElement,
}: {
  children: ReactNode;
  documentChatElement: DocumentChatItem;
}) => {
  const { showSources, toggleSources } = useContext(AnswerContext);

  const mixpanelTrack = useMixpanelTrack();
  const updateHistoryElement = useUpdateHistoryElement();
  const dislikeValue = useStringifiedFeedback(documentChatElement?.thumbsUp);

  const toggleLike = useCallback(() => {
    updateHistoryElement.mutate({
      elementId: documentChatElement?.id ?? "",
      payload: {
        thumbsUp: dislikeValue === "LIKE" ? ModelLogFeedback.NoResponse : ModelLogFeedback.Like,
      },
    });
  }, [updateHistoryElement, documentChatElement?.id, dislikeValue]);

  const toggleDislike = useCallback(() => {
    updateHistoryElement.mutate({
      elementId: documentChatElement?.id ?? "",
      payload: {
        thumbsUp: dislikeValue === "DISLIKE" ? ModelLogFeedback.NoResponse : ModelLogFeedback.Dislike,
      },
    });
  }, [updateHistoryElement, documentChatElement?.id, dislikeValue]);

  return (
    <HoverCard closeDelay={200} openDelay={0}>
      <HoverCardTrigger asChild>{children}</HoverCardTrigger>
      <HoverCardContent
        hideWhenDetached
        avoidCollisions={false}
        className="flex h-10 w-auto flex-row items-center rounded-xl border border-modal-stroke p-1.5 data-[shared=true]:hidden"
        side="top"
        sideOffset={10}
        style={{
          zIndex: 0,
        }}
      >
        <section className="flex flex-row items-center justify-between gap-1">
          <AddToNoteButton
            text={documentChatElement?.responseText ?? ""}
            onClick={() => {
              mixpanelTrack("document_chat_added_to_note");
            }}
          />
          <Button className="group" data-source-shown={showSources} size="xs" variant="ghost" onClick={toggleSources}>
            <span className="h-4 w-4 group-data-[source-shown=true]:hidden">
              <QALinkingIcon />
            </span>
            <span className="hidden h-4 w-4 group-data-[source-shown=true]:inline">
              <HideSourceButton />
            </span>
            Show sources
          </Button>
          <Separator className="h-4" orientation="vertical" />
          <section className="flex flex-row items-center gap-1">
            <section className="flex flex-row items-center gap-0.5">
              <Button size="icon" variant="icon" onClick={toggleLike}>
                <LikeIcon
                  className="hidden h-4 w-4 fill-primary/25 data-[like=DISLIKE]:block data-[like=EMPTY]:block"
                  data-like={dislikeValue}
                />
                <LikeIconFilled
                  className="hidden h-4 w-4 fill-primary/25 data-[like=LIKE]:block"
                  data-like={dislikeValue}
                />
              </Button>
              <Button size="icon" variant="icon" onClick={toggleDislike}>
                <DislikeIcon
                  className="hidden fill-primary/25 data-[like=EMPTY]:block data-[like=LIKE]:block"
                  data-like={dislikeValue}
                />
                <DislikeIconFilled
                  className="hidden fill-primary/25 data-[like=DISLIKE]:block"
                  data-like={dislikeValue}
                />
              </Button>
            </section>
          </section>
        </section>
      </HoverCardContent>
    </HoverCard>
  );
};

const QuinoAnswer = ({ historyElementId, date }: { historyElementId: string; isLastInRow: boolean; date?: string }) => {
  const { value: showSources, toggle: toggleSources } = useBoolean(false);

  const mixpanelTrack = useMixpanelTrack();
  const { data: documentChatElement } = useDocumentChatElementLocal(historyElementId);

  const navigateToDocumentPage = useNavigateToDocumentPage();
  const sourcesComponents = useSourceComponents(documentChatElement, mixpanelTrack, navigateToDocumentPage);

  const isChatLoading = useIsChatLoading();

  const emptyEntity = useMemo(
    () => documentChatElement && documentChatElement.responseText.length === 0,
    [documentChatElement],
  );

  if (!documentChatElement)
    return (
      <section className="flex flex-row">
        <Spinner />
      </section>
    );

  if (documentChatElement.modelError)
    return <QuinoAnswerError question={documentChatElement.inputText} title={"Answer from Quino"} />;

  return (
    <AnswerContext.Provider value={{ showSources, toggleSources }}>
      <QuinoAnswerHoverCard documentChatElement={documentChatElement}>
        <section className="mr-auto w-full rounded-lg p-2 pb-3 outline outline-1 outline-transparent animate-in fade-in-0 slide-in-from-left-2 hover:outline-tertiary-default data-[state=open]:outline-tertiary-default dark:bg-primary-dark-light">
          <section className="flex flex-row gap-3">
            <section>
              <Avatar className="h-8 w-8 bg-text-dark-purple">
                <QuinoIcon className="h-8 w-8 bg-tertiary-default p-2" />
              </Avatar>
            </section>
            <section className="flex w-full flex-col">
              <section className="flex flex-row items-center  justify-between gap-2 pb-1">
                <Typography className="flex flex-row items-center gap-2 font-semibold" size="extra-small">
                  <span>Answer from Quino</span>
                  {isChatLoading && documentChatElement.id === LAST_ELEMENT_ID && !emptyEntity && (
                    <LoadingSpinner className="h-4 w-4" />
                  )}
                </Typography>
                {date && (
                  <Typography className="text-secondary-onBg" size="extra-small">
                    {formatWithUTC(date)}
                  </Typography>
                )}
              </section>
              <section className="text-sm text-primary">
                {emptyEntity && <TypeWriter />}
                <QuinoMarkdownChat>{documentChatElement.responseText}</QuinoMarkdownChat>
              </section>
              <section className="mt-4 space-y-4 data-[hidden=true]:hidden" data-hidden={!showSources}>
                {sourcesComponents}
              </section>
            </section>
          </section>
        </section>
      </QuinoAnswerHoverCard>
    </AnswerContext.Provider>
  );
};
export default QuinoAnswer;
