import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitErrorHandler, type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateProject, useProjectData } from "@/api/project";
import { ModalKeys } from "@/assets/constants/modal";
import { projectColorKeys } from "@/assets/constants/projects";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useModalState } from "@/zustand/slices/modal-slice";

const editProjectSchema = z.object({
  projectName: z
    .string()
    .min(1, {
      message: "Must be at least 1 character",
    })
    .max(100, { message: "Must be at most 100 characters" }),

  colorTag: z.string().length(7).regex(/^#/),
});

export type EditProjectFormData = z.infer<typeof editProjectSchema>;

const EditProjectModal = () => {
  const form = useForm<EditProjectFormData>({
    resolver: zodResolver(editProjectSchema),
    defaultValues: {
      projectName: "",
      colorTag: "#FFC0BB",
    },
  });

  const [{ isOpen, projectId, workspaceId }, setModalState] = useModalState(ModalKeys.EDIT_PROJECT_MODAL_OPEN);
  const { data } = useProjectData(workspaceId, projectId, { enabled: !!projectId });
  const editProject = useUpdateProject();

  const handleSubmit: SubmitHandler<EditProjectFormData> = (formData) => {
    editProject.mutate({
      projectId: projectId,
      workspaceId: workspaceId,
      payload: {
        name: formData.projectName,
        colorTag: formData.colorTag,
      },
    });
  };

  const handleErrors: SubmitErrorHandler<EditProjectFormData> = (errors) => {
    console.log(errors);
  };

  const handleClose = useCallback(() => {
    form.reset();
    setModalState({ isOpen: false, projectId: "", workspaceId: "" });
  }, [form, setModalState]);

  const handleOpenChange = isOpen ? handleClose : () => setModalState({ isOpen: true, projectId: "", workspaceId: "" });

  useEffect(() => {
    if (editProject.isSuccess) handleClose();
  }, [editProject.isSuccess, handleClose]);

  useEffect(() => {
    form.setValue("colorTag", data?.colorTag ?? "#B6C5FB");
    form.setValue("projectName", data?.name ?? "");
  }, [data, form, form.setValue]);
  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-6">
        <DialogHeader>
          <DialogTitle>Edit Project</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(handleSubmit, handleErrors)}>
            <FormField
              control={form.control}
              name="projectName"
              render={({ field }) => (
                <FormItem className="px-2">
                  <FormControl>
                    <Input className="resize-none" placeholder="Project name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="colorTag"
              render={({ field }) => (
                <FormItem className="px-2">
                  <FormLabel>Label Colour</FormLabel>
                  <FormControl>
                    <section className="flex w-full flex-row flex-wrap gap-x-2 gap-y-4">
                      {projectColorKeys.map((item) => (
                        <span
                          aria-selected={item === field.value}
                          className="block h-10 w-10 rounded-full border hover:border-primary aria-[selected=true]:border-primary"
                          key={item}
                          style={{ backgroundColor: item }}
                          onClick={() => field.onChange(item)}
                        />
                      ))}
                    </section>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-6">
              <Button type="button" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Update Project</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditProjectModal;
