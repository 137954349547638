import { ModalKeys } from "@/assets/constants/modal.ts";
import { NewProjectIcon } from "@/components/v3/icons";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

import DemoHandlerButton from "../../demo/demo-handler-button.tsx";

const NewProjectButton = () => {
  const [, setOpen] = useModalState(ModalKeys.CREATE_PROJECT_MODAL_OPEN);

  return (
    <>
      <DemoHandlerButton className="drop-shadow-none" size="sm" variant="secondary" onClick={() => setOpen(true)}>
        <NewProjectIcon />
        New project
      </DemoHandlerButton>
    </>
  );
};

export default NewProjectButton;
