import { useCallback, useMemo, useRef, useState } from "react";

import { useBoolean } from "usehooks-ts";

import { useActivityHistory } from "@/api/user.ts";
import { FilterIcon } from "@/components/icons";
import { Button } from "@/components/ui/button.tsx";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "@/components/ui/select";
import { Typography } from "@/components/ui/typography.tsx";
import { ActivityCard } from "@/components/v3";
import { LoadingSpinner } from "@/pages/loading";
import { getWorkspaceFromList } from "@/service/workspace.ts";
import type { Activity } from "@/types/home";
import type { WorkspaceListItem } from "@/types/schemas/api/workspaceEntity.ts";

const ActivityFeedList = () => {
  const { data, fetchNextPage } = useActivityHistory();

  const lastPage = useMemo(() => data?.pages.at(data.pages.length - 1), [data?.pages]);

  const { value: isShown, toggle } = useBoolean(false);

  const { users, workspaces } = useMemo(() => {
    if (!data)
      return {
        workspaces: [],
        users: [],
      };

    const workspaces: WorkspaceListItem[] = data.pages
      .flatMap((page) => page.activityHistoryList)
      .map((item) => getWorkspaceFromList(item.workspaceId))
      .filter((item) => item !== undefined)
      .filter((item, index, array) => {
        return (
          array.findIndex((o) => {
            return o?.id === item?.id;
          }) === index
        );
      }) as WorkspaceListItem[];

    const users = data.pages
      .flatMap((page) => page.activityHistoryList)
      .map((item) => item.userData)
      .filter((item, index, array) => {
        return (
          array.findIndex((o) => {
            return o?.userId === item?.userId;
          }) === index
        );
      });
    return { workspaces, users };
  }, [data]);

  const [stagedFilterState, setStagedFilterState] = useState<{
    workspace?: { id: string; name: string };
    user?: { id: string; name: string };
  }>({
    workspace: undefined,
    user: undefined,
  });

  const [filterState, setFilterState] = useState<{
    workspace?: { id: string; name: string };
    user?: { id: string; name: string };
  }>({
    workspace: undefined,
    user: undefined,
  });

  const filteredContent = useMemo(() => {
    if (!data) return null;

    let newData = data.pages.flatMap((page) => page.activityHistoryList);
    if (filterState.user) {
      newData = newData.filter((item) => item.userData.userId === filterState.user?.id);
    }

    if (filterState.workspace) {
      newData = newData.filter((item) => item.workspaceId === filterState.workspace?.id);
    }

    return newData
      .sort((a, b) => {
        const aDate = new Date(a.dateCreated);
        const bDate = new Date(b.dateCreated);
        return bDate.getTime() - aDate.getTime();
      })
      .map((activity) => <ActivityCard activity={activity as Activity} key={activity.id} />);
  }, [data, filterState.user, filterState.workspace]);
  const observer = useRef<IntersectionObserver>();

  const scrollTarget = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && lastPage?.hasMore) {
          void fetchNextPage();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchNextPage, lastPage?.hasMore],
  );

  return (
    <section className="flex flex-col gap-4 border-red-900">
      <section className="flex flex-col gap-6">
        <section className="mb-2 flex flex-row items-center justify-start gap-4">
          <Typography className="font-bold" size="large">
            Activity feed
          </Typography>
          <Button disabled={!data || data.pages.length === 0} size="xs" variant="ghost" onClick={toggle}>
            <FilterIcon className="h-4 w-4 fill-default" /> Show filters
          </Button>
        </section>
        {isShown && (
          <section className="mb-6 hidden flex-row gap-4 data-[is-shown=true]:flex" data-is-shown={isShown}>
            <Select
              value={stagedFilterState.workspace?.id ?? ""}
              onValueChange={(value) =>
                setStagedFilterState((prev) => ({
                  ...prev,
                  workspace: {
                    id: value,
                    name: workspaces.find((item) => item.id === value)?.name ?? "",
                  },
                }))
              }
            >
              <SelectTrigger className="w-[180px] border-stroke-default bg-transparent">
                <span className="truncate">
                  {!stagedFilterState.workspace?.name && "Filter by workspace"}
                  {stagedFilterState.workspace?.name}
                </span>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {workspaces.map((item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Select
              value={stagedFilterState.user?.id ?? ""}
              onValueChange={(value) =>
                setStagedFilterState((prev) => ({
                  ...prev,
                  user: {
                    id: value,
                    name: users.find((item) => item.userId === value)?.nickName ?? "",
                  },
                }))
              }
            >
              <SelectTrigger className="w-[180px] truncate border-stroke-default bg-transparent">
                <span className="truncate">
                  {!stagedFilterState.user?.name && "Filter by user"}
                  {stagedFilterState.user?.name}
                </span>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {users.map((item) => (
                    <SelectItem key={item.userId} value={item.userId}>
                      {item.nickName}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Button size="sm" variant="secondary" onClick={() => setFilterState(stagedFilterState)}>
              Apply filters
            </Button>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                setFilterState({ workspace: undefined, user: undefined });
                setStagedFilterState({ workspace: undefined, user: undefined });
              }}
            >
              Clear filters
            </Button>
          </section>
        )}
      </section>
      {filteredContent}
      <p
        className="hidden flex-row justify-center data-[active=true]:flex"
        data-active={lastPage?.hasMore}
        ref={scrollTarget}
      >
        <LoadingSpinner />
      </p>
    </section>
  );
};
export default ActivityFeedList;
