import { type MouseEvent, forwardRef, useCallback } from "react";

import { Button, type ButtonProps } from "@/components/ui/button.tsx";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useOpenSignUpToQuinoModal } from "@/service/library.ts";

const DemoHandlerButton = forwardRef<HTMLButtonElement, ButtonProps>(({ onClick: _onClick, ...props }, ref) => {
  const onClick = useOpenSignUpToQuinoModal();
  const { matched: isDemo } = useIsDemoLikePage();

  const clickHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onClick();
    },
    [onClick],
  );

  return <Button ref={ref} {...props} onClick={isDemo ? clickHandler : _onClick} />;
});

DemoHandlerButton.displayName = "DemoHandlerButton";

export default DemoHandlerButton;
