import { useMemo } from "react";

import { Link, useSearchParams } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import AvatarPlaceholder from "@/assets/svgs/v3/avatar-placeholder.svg";
import LockIcon from "@/assets/svgs/v3/lock-fill.svg";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import type { WorkspaceListItem } from "@/types/schemas/api/workspaceEntity.ts";

const WorkspaceAccordionCard = ({ workspace }: { workspace: WorkspaceListItem }) => {
  const workspaceId = useCurrentWorkspaceId();
  const avatarImage = useMemo(
    () => workspace?.imageUrl ?? (workspace && workspace.numOfUsers > 1 ? AvatarPlaceholder : LockIcon),
    [workspace],
  );
  const { matched: isDemo } = useIsDemoLikePage();
  const [searchParams] = useSearchParams();
  const link = useMemo(() => {
    if (isDemo) return `${ROUTES.DEMO}${ROUTES.DOCUMENTS}/${workspace.id}?${searchParams.toString()}`;
    return `${ROUTES.DOCUMENTS}/${workspace.id}?${searchParams.toString()}`;
  }, [isDemo, searchParams, workspace.id]);

  return (
    <Link to={link}>
      <section
        className="group flex  flex-row items-center gap-2 overflow-hidden rounded-lg p-3 py-2 transition-colors duration-300 hover:bg-container-tertiary"
        data-selected={workspace.id === workspaceId}
      >
        <Avatar
          className="h-6 w-6 bg-primitive-purple-300 p-1.5 data-[has-image=true]:p-0"
          data-has-image={!!workspace.imageUrl}
        >
          <AvatarImage src={avatarImage} />
          <AvatarFallback>{workspace.name.at(0)}</AvatarFallback>
        </Avatar>
        <Typography className="line-clamp-1 overflow-hidden  truncate  text-sm font-normal text-primary group-hover:text-primary group-data-[selected=true]:font-bold">
          {workspace.name}
        </Typography>
      </section>
    </Link>
  );
};
export default WorkspaceAccordionCard;
