import React from "react";

import { isMobile } from "react-device-detect";

import CookieConsentModal from "@/components/modals/cookie-consent-modal.tsx";
import DeleteMultipleModal from "@/components/modals/delete-multiple-modal";
import { DeleteDocumentModal, UpdateDocumentModal } from "@/components/modals/document";
import EmailVerification from "@/components/modals/email-verification";
import { DeleteFlashcardModal } from "@/components/modals/flash-card";
import InterviewModal from "@/components/modals/interview.tsx";
import { CreateNoteModal, DeleteNoteModal, UpdateNoteModal } from "@/components/modals/note";
import CreateImageNoteParagraph from "@/components/modals/note/create-image-note-paragraph.tsx";
import UpgradePlanModal from "@/components/modals/plan";
import AskForCancellationModal from "@/components/modals/plan/ask-for-cancellation-modal.tsx";
import ChurnedUserModal from "@/components/modals/plan/churned-modal";
import GetProCTAModal from "@/components/modals/plan/get-pro-cta.tsx";
import DeleteProjectModal from "@/components/modals/project/delete-project";
import EditProjectModal from "@/components/modals/project/edit-project";
import ShareModal from "@/components/modals/project/share-modal.tsx";
import UnShareModal from "@/components/modals/project/un-share-modal.tsx";
import QuinoReborn from "@/components/modals/quino-reborn.tsx";
import SignUpToQuino from "@/components/modals/sign-up-to-quino.tsx";
import { CreateDocumentModal, CreateProjectModal, RenewSubscription, WorkspaceSettingsModal } from "@/components/v3";
import Feedback from "@/components/v3/modals/feedback/feedback.tsx";

const AllModalProviderContent = ({ children }: { children?: React.ReactNode }) => {
  return (
    <>
      <CreateDocumentModal />
      <EmailVerification />
      <UnShareModal />
      <ShareModal />
      <CreateNoteModal />
      <UpdateNoteModal />
      <CreateImageNoteParagraph />
      <DeleteNoteModal />
      <UpdateDocumentModal />
      <DeleteDocumentModal />
      <DeleteFlashcardModal />
      <EditProjectModal />
      <DeleteProjectModal />
      <RenewSubscription />
      <DeleteMultipleModal />
      <UpgradePlanModal />
      <ChurnedUserModal />
      <GetProCTAModal />
      <Feedback />
      <AskForCancellationModal />
      <QuinoReborn />
      <WorkspaceSettingsModal />
      <InterviewModal />
      <SignUpToQuino />
      <CookieConsentModal />
      <CreateProjectModal />
      {children}
    </>
  );
};

export const AllModalProvider = ({ children }: { children?: React.ReactNode }) => {
  if (isMobile) return children;

  return <AllModalProviderContent>{children}</AllModalProviderContent>;
};

export default AllModalProvider;
