import { useCallback } from "react";

import { useDeleteNote } from "@/api/note.ts";
import { ModalKeys } from "@/assets/constants/modal";
import { YesNoModal } from "@/components/modals";
import { toast } from "@/components/ui/use-toast";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useModalState } from "@/zustand/slices/modal-slice";

const DeleteNoteModal = () => {
  const [{ isOpen, noteId }, setState] = useModalState(ModalKeys.DELETE_NOTE_MODAL);
  const currentWorkspaceId = useCurrentWorkspaceId();
  const { mutate: deleteNote } = useDeleteNote();

  const handleDelete = useCallback(() => {
    if (noteId.length > 0) {
      deleteNote(
        { noteId, workspaceId: currentWorkspaceId },
        {
          onSuccess: () => toast({ title: "Note deleted", variant: "success" }),
        },
      );
      setState({ isOpen: false, noteId: "" });
    }
  }, [noteId, deleteNote, currentWorkspaceId, setState]);

  return (
    <YesNoModal
      description="Are you sure you want to delete this note? This action cannot be undone later."
      open={isOpen}
      title="Delete Note"
      onCancel={() => setState({ isOpen: false, noteId: "" })}
      onYes={handleDelete}
    />
  );
};

export default DeleteNoteModal;
