import * as React from "react";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronRight } from "lucide-react";

import { More } from "@/components/v3";
import { cn } from "@/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item className={cn("border-b", className)} ref={ref} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      className={cn(
        "flex flex-1 items-center justify-between py-4 font-medium transition-all [&[data-disabled]>svg]:invisible [&[data-state=open]>svg]:rotate-90",
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
      <ChevronRight className="h-4 w-4 shrink-0 stroke-icon-back transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionHeaderTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      className={cn(
        "group flex flex-1 items-center justify-between py-4 font-medium transition-all [&[data-disabled]>span>svg]:invisible",
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
      <span className="relative h-4 w-4">
        <More className="absolute h-4 w-4 shrink-0  stroke-icon-back opacity-100 transition-opacity duration-200 group-data-[state=open]:opacity-0" />
        <ChevronRight className="absolute h-4 w-4 shrink-0 rotate-90  stroke-icon-back opacity-0 transition-opacity duration-200 group-data-[state=open]:opacity-100" />
      </span>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionHeaderTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & { containerClassName?: string }
>(({ className, containerClassName, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    className={cn(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className,
    )}
    ref={ref}
    {...props}
  >
    <div className={cn("pb-4 pt-0", containerClassName)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent, AccordionHeaderTrigger };
