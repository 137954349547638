import { Typography } from "@/components/ui/typography.tsx";

const EmptyView = ({ imageUrl, title, description }: { imageUrl: string; title: string; description: string }) => {
  return (
    <section className="flex w-full flex-row items-center justify-center gap-12 rounded-lg border border-dashed p-18 transition-all group-data-[is-drag=true]:border-solid group-data-[is-drag=true]:border-default">
      <img alt="empty workspace" className="h-50 w-50" src={imageUrl} />
      <section className="flex flex-col gap-1">
        <Typography className="max-w-[24rem] font-bold" size="xl">
          {title}
        </Typography>
        <Typography className="text-secondary-onBg" size="small">
          {description}
        </Typography>
      </section>
    </section>
  );
};
export default EmptyView;
