import { useCallback, useMemo } from "react";

import { format } from "date-fns";
import { NavLink } from "react-router-dom";

import { useSubscription } from "@/api/resources";
import { ACCOUNT_ROUTES, ROUTES } from "@/assets/constants/constants";
import { getCountryName } from "@/assets/constants/country-codes";
import { ModalKeys } from "@/assets/constants/modal";
import { fieldOfOccupationOptions, occupationOptions } from "@/assets/constants/onboarding";
import CurrentPlanInfoCard from "@/components/account/current-plan-info-card";
import InfoCard from "@/components/account/info-card";
import ProfilePictureEditor from "@/components/account/profile-picture-editor";
import {
  CalendarIcon,
  ChangePasswordIcon,
  CookiePolicyIcon,
  CountryIcon,
  EditPenIcon,
  FieldIcon,
  GenderIcon,
  LogOutIcon,
  OccupationIcon,
  PrivacyPolicyIcon,
  SchoolIcon,
  TermsAndConditionsIcon,
  TrashbinIcon,
  UpgradeIcon,
} from "@/components/icons";
import { Button } from "@/components/ui/button";
import { logout } from "@/firebase";
import { useAuthUserData, useIsPasswordProvider } from "@/firebase/hooks";
import { useModalState } from "@/zustand/slices/modal-slice";

const PersonalAccount = () => {
  const { data: userData } = useAuthUserData();
  const { data: currentPlan } = useSubscription();
  const isPasswordProvider = useIsPasswordProvider();

  const [, setAccountEditModalState] = useModalState(ModalKeys.EDIT_USER_INFORMATION_MODAL_OPEN);
  const openAccountEditModal = useCallback(() => setAccountEditModalState(true), [setAccountEditModalState]);

  const [, setAccountDeleteModalState] = useModalState(ModalKeys.DELETE_ACCOUNT_MODAL_OPEN);
  const openAccountDeleteModal = useCallback(() => setAccountDeleteModalState(true), [setAccountDeleteModalState]);

  const [, setChangePasswordModalState] = useModalState(ModalKeys.CHANGE_PASSWORD_MODAL_OPEN);
  const openChangePasswordModal = useCallback(() => setChangePasswordModalState(true), [setChangePasswordModalState]);

  const getFieldOfOccupationText = (fieldOfOccupation: string | null | undefined) => {
    if (!fieldOfOccupation) return;
    return fieldOfOccupationOptions
      .filter((fieldOfOccupationOption) => fieldOfOccupationOption.id === fieldOfOccupation)
      .at(0)?.text;
  };

  const getOccupationText = (occupation: string | null | undefined) => {
    if (!occupation) return;
    return occupationOptions.filter((occupationOption) => occupationOption.id === occupation).at(0)?.text;
  };
  const isPlanFree = currentPlan?.subscriptionPlan === "free";

  const userSubscriptionLabel = useMemo(() => {
    return isPlanFree ? (
      <p className="absolute bottom-2 right-0 rounded-[100px] bg-white px-3 py-1.5 text-caption-1 font-bold italic text-primary-onBg">
        Free
      </p>
    ) : (
      <p className="absolute bottom-2 right-0 rounded-[100px] bg-pro-plan-emblem-radial-gradient px-3 py-1.5 text-caption-1 font-bold italic text-text-link-active-onBg">
        Pro
      </p>
    );
  }, [isPlanFree]);

  const userCurrentName = useMemo(() => {
    return userData?.firstName && userData?.lastName
      ? `${userData.firstName} ${userData.lastName}`
      : userData?.displayName;
  }, [userData?.firstName, userData?.lastName, userData?.displayName]);

  const userSubscriptionActionBtn = useMemo(() => {
    return (
      isPlanFree && (
        <NavLink to={ROUTES.SUBSCRIPTION}>
          <Button>
            <UpgradeIcon />
            Upgrade
          </Button>
        </NavLink>
      )
    );
  }, [isPlanFree]);

  return (
    <section className="no-scrollbar mx-auto flex w-[1200px] flex-col gap-12 overflow-x-auto">
      <div className="flex gap-10">
        <h4 className="min-w-[244px] text-h5 text-primary-onBg">Personal data</h4>
        <div className="flex flex-1 flex-col gap-6">
          <div className="flex gap-6">
            <div className="relative h-36 w-36">
              <ProfilePictureEditor />
              {userSubscriptionLabel}
            </div>
            <div className="flex flex-col justify-center gap-1">
              <h4 className="text-lg font-bold text-primary-onBg">{userCurrentName}</h4>
              <p className="text-sm text-secondary-onBg">{userData?.email}</p>
              <div className="mt-3 flex gap-2">
                {userSubscriptionActionBtn}
                <Button variant="secondary" onClick={openAccountEditModal}>
                  <EditPenIcon />
                  Edit information
                </Button>
                {isPasswordProvider && (
                  <Button variant="secondary" onClick={openChangePasswordModal}>
                    <ChangePasswordIcon />
                    Change password
                  </Button>
                )}
                <Button variant="ghost" onClick={() => logout()}>
                  <LogOutIcon />
                  Log out
                </Button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 flex-wrap gap-2">
            <InfoCard icon={<CountryIcon />} title="Country" value={getCountryName(userData?.countryCode)} />
            <InfoCard
              icon={<CalendarIcon className="shrink-0" />}
              title="Date of Birth"
              value={userData?.dateOfBirth && format(new Date(userData?.dateOfBirth), "PPP")}
            />
            <InfoCard
              icon={<FieldIcon />}
              title="Field"
              value={getFieldOfOccupationText(userData?.fieldOfOccupation)}
            />
            <InfoCard icon={<GenderIcon className="shrink-0" />} title="Gender" value={userData?.gender} />
            <InfoCard
              icon={<OccupationIcon className="shrink-0" />}
              title="Occupation"
              value={getOccupationText(userData?.occupation)}
            />
            <InfoCard
              icon={<SchoolIcon className="shrink-0" />}
              title="University or school"
              value={userData?.university}
            />
          </div>
        </div>
      </div>
      <div className="jus flex gap-10">
        <h4 className="min-w-[244px] text-h5 text-primary-onBg">Your subscription</h4>
        <CurrentPlanInfoCard currentPlan={currentPlan!} isPlanFree={isPlanFree} />
      </div>
      <div className="flex gap-10">
        <h4 className="min-w-[244px] text-h5 text-primary-onBg">Legal documents</h4>
        <div className="flex w-full gap-2">
          <NavLink className="w-full" to={`/account/${ACCOUNT_ROUTES.TERMS_AND_CONDITIONS}`}>
            <Button className="w-full" variant="secondary">
              Terms & Conditions
              <TermsAndConditionsIcon />
            </Button>
          </NavLink>
          <NavLink className="w-full" to={`/account/${ACCOUNT_ROUTES.PRIVACY_POLICY}`}>
            <Button className="w-full" variant="secondary">
              Privacy Policy
              <PrivacyPolicyIcon />
            </Button>
          </NavLink>
          <NavLink className="w-full" to={`/account/${ACCOUNT_ROUTES.COOKIE_POLICY}`}>
            <Button className="w-full" variant="secondary">
              Cookie Policy
              <CookiePolicyIcon />
            </Button>
          </NavLink>
        </div>
      </div>
      <div className="flex gap-10">
        <h4 className="min-w-[244px] text-h5 text-primary-onBg">Delete account</h4>
        <div className="flex flex-col gap-6">
          <p className="text-sm text-primary-onBg">
            Please note that deleting your account is a permanent action. You will lose all your documents, notes and
            data created with your account.
          </p>
          <div>
            <Button variant="destructive-secondary" onClick={openAccountDeleteModal}>
              <TrashbinIcon />
              Delete account
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalAccount;
