import { useMemo } from "react";

import DarkEmptyDocuChatImage from "@/assets/svgs/dark-empty-docu-chat.svg";
import EmptyDocuChatImage from "@/assets/svgs/empty-docu-chat.svg";
import { useThemeMode } from "@/service/hooks/misc.ts";

const EmptyImage = ({ className = "" }: { className?: string }) => {
  const [mode] = useThemeMode();
  const emptyImage = useMemo(() => (mode === "dark" ? DarkEmptyDocuChatImage : EmptyDocuChatImage), [mode]);

  return (
    <section className={className}>
      <img alt="no content" src={emptyImage} />
    </section>
  );
};
export default EmptyImage;
