import { useEffect, useMemo, useState } from "react";

import { getDownloadURL } from "firebase/storage";

import { useProjectData } from "@/api/project.ts";
import { ResourceCardType } from "@/assets/constants/constants.ts";
import { getFlashCardImageRef, getNoteParagarphImageRef } from "@/firebase";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectId } from "@/utils";

export const getFirebaseImageUrlForFlashCard = async (path: string) => {
  return await getDownloadURL(getFlashCardImageRef(path));
};
export const getFirebaseImageUrlForNoteParagraph = async (path: string) => {
  return await getDownloadURL(getNoteParagarphImageRef(path));
};

export const useBucketImageUrl = (bucket: "NOTE" | "FLASHCARD", path: string) => {
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    void (async () => {
      switch (bucket) {
        case "FLASHCARD":
          setImageUrl(await getFirebaseImageUrlForFlashCard(path));
          break;
        case "NOTE":
          setImageUrl(await getFirebaseImageUrlForNoteParagraph(path));
      }
    })();
  }, [bucket, path]);

  return imageUrl;
};

export const useNotesFromProject = (workspaceId: string, projectId: string) => {
  const { data: projectData, isSuccess } = useProjectData(workspaceId, projectId);
  return useMemo(
    () => ({
      notes:
        projectData?.directChildElements.filter((item) => item.elementType === ResourceCardType.NOTE_ELEMENT) ?? [],
      isSuccess,
    }),
    [isSuccess, projectData?.directChildElements],
  );
};

export const useNotesFromCurrentWorkspace = (projectId: string) => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  return useNotesFromProject(currentWorkspaceId, projectId);
};

export const useNotesFromCurrentProject = () => {
  const currentProjectId = useCurrentProjectId();
  return useNotesFromCurrentWorkspace(currentProjectId);
};
