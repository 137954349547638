import { type ReactNode } from "react";

import { HU, DE, ES, TR, US } from "country-flag-icons/react/3x2";

export interface PollAnswer {
  pollId: number;
  refusedToAnswer: boolean;
  answer?: string;
  pollUid: string;
}

export interface ExtendedPollAnswer extends PollAnswer {
  sendToMixpanel: boolean;
}

export interface QuizAnswer {
  quizId: number;
  responses: { refusedToAnswer: boolean; answer: string; mixpanelId: string }[];
}

export enum SUPPORTED_LANGUAGES {
  EN = "en",
  TR = "tr",
  ES = "es",
  DE = "de",
  HU = "hu",
}

export const SUPPORTED_LANGUAGES_LABELS: Record<
  SUPPORTED_LANGUAGES,
  { label: string; icon: ReactNode; status?: "beta" | "fully-functional" }
> = {
  [SUPPORTED_LANGUAGES.EN]: { label: "English (US)", icon: <US />, status: "fully-functional" },
  [SUPPORTED_LANGUAGES.DE]: { label: "German", icon: <DE />, status: "beta" },
  [SUPPORTED_LANGUAGES.TR]: { label: "Turkish", icon: <TR />, status: "beta" },
  [SUPPORTED_LANGUAGES.ES]: { label: "Spanish", icon: <ES />, status: "beta" },
  [SUPPORTED_LANGUAGES.HU]: { label: "Hungarian", icon: <HU />, status: "fully-functional" },
};

export interface FileUploadState {
  loading: boolean;
  message: string;
  value: number;
  status: "NO_STATE" | "UPLOADING" | "ERROR" | "DONE";
}

export interface UploadState {
  [key: string]: FileUploadState;
}
