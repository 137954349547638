import { useEffect, useMemo, useRef, useState } from "react";

import WebViewer, { type WebViewerInstance, type WebViewerOptions } from "@pdftron/pdfjs-express-viewer";
import _ from "lodash";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { Helmet } from "react-helmet";
import { useDebounce } from "usehooks-ts";

import {
  useAILookupText,
  useBulletPointSummaryText,
  useCopyToNote,
  useSendModelLogFeedback,
  useSummarizeText,
} from "@/api/ai-function.ts";
import { ANALYTICS_CATEGORY, type ModelLogFeedback, TEXT_POPUP_BUTTONS } from "@/assets/constants/constants";
import { FunnyStuffType } from "@/assets/constants/funny-stuff.ts";
import { TutorialVariant } from "@/assets/constants/modal";
import PopupButton from "@/components/button/pdfviewer/popup-button";
import FunnyStuff from "@/components/funny-stuff";
import { CloseIcon } from "@/components/icons/common";
import { EmptyDocumentView } from "@/components/pdf/components.tsx";
import { Button } from "@/components/ui/button.tsx";
import { customGAEventSender } from "@/service";
import { useIsDemoLikePage, useThemeMode } from "@/service/hooks/misc.ts";
import { useIsSharedPage, usePageToOpenParam } from "@/service/hooks/react-router";
import { useIsFreeUser } from "@/service/hooks/settings.ts";
import { useTutorial } from "@/service/hooks/tutorial";
import { useMixpanelTrack, mixpanelTrack as mixpanelTrackService } from "@/service/mixpanel";
import updateUIBasedOnSelection, { useDocumentProcessingData } from "@/service/web-viewer";
import { type PdfViewerProps } from "@/types/app.ts";
import { useAILoading, useDocumentSearchTarget } from "@/zustand/hooks";
import useAppStateStore from "@/zustand/store";

const defaultConfig: WebViewerOptions = {
  path: "/webviewer/lib",
  initialDoc: "/files/demo.pdf",
  css: "/files/styles/webviewer-styles.css",
  extension: "pdf",
  // default example key for localhost
  licenseKey: (import.meta.env.VITE_PDFJS_EXPRESS_LICENSE_KEY as string) ?? "VauUQ5wW5G3jACYRTNQn",
  config: "/files/config/config.js",
};

let timeoutId: NodeJS.Timeout | undefined = undefined;
let timeoutFunnyStuff: NodeJS.Timeout | undefined | null;

// function updateStyles(style: CSSStyleDeclaration, mode: "dark" | "light" | undefined) {
//   style.setProperty(`--view-header-background`, mode === "dark" ? "#252525" : "#FFF");
//   style.setProperty(`--rukkola-primary-color`, mode === "dark" ? "#FFF" : "#33124d");
//   style.setProperty(`--rukkola-tooltip-color`, mode === "dark" ? "#000" : "#33124d");
//   style.setProperty(`--rukkola-primary-75`, mode === "dark" ? "#FFFFFFbf" : "#33124dbf");
//   style.setProperty(`--rukkola-primary-10`, mode === "dark" ? "#252525" : "#33124d1a");
//   style.setProperty(`--image-filter`, mode === "dark" ? "brightness(0) invert(1)" : "none");
//   style.setProperty(`--bg-text-popup`, mode === "dark" ? "#252525" : "#FFF");
//   style.setProperty(`--border-text-popup`, mode === "dark" ? "1px solid black" : "1px solid rgba(51, 18, 77, 0.25)");
// }

const PdfViewer = ({ fileUrl, workspaceId, documentId, documentName }: PdfViewerProps) => {
  const [viewedPagesCount, setViewedPagesCount] = useState(0);
  const isSharedPage = useIsSharedPage();
  const viewer = useRef<HTMLDivElement | null>(null);
  const [pageToOpen] = usePageToOpenParam();
  const [pdfViewerSelectedText, setPdfViewerSelectedText] = useState("");
  const [adOpen, setAdOpen] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [aiLoading] = useAILoading();
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);
  const { setState } = useTutorial(TutorialVariant.DOCUMENT_VIEW);
  const [loaded, setLoaded] = useState(false);
  const [mode] = useThemeMode();
  const config: WebViewerOptions = useMemo(() => ({ ...defaultConfig, initialDoc: fileUrl }), [fileUrl]);
  const { currentPage, setActivePage, lastModelResponse, searchResults } = useAppStateStore((state) => ({
    setActivePage: state.setActivePage,
    lastModelResponse: state.lastModelResponse,
    currentPage: state.currentPage,
    searchResults:
      documentId && Object.hasOwn(state.pdfViewerResults, documentId) ? state.pdfViewerResults[documentId] : undefined,
  }));

  const [setPdfViewerSearchTarget, setPdfViewerResults, clearSearchData] = useAppStateStore((state) => [
    state.setPdfViewerSearchTarget,
    state.setPdfViewerResults,
    state.clearSearchData,
  ]);
  const searchTarget = useDocumentSearchTarget({ documentId });
  const isFree = useIsFreeUser(); // needs review
  const copyToNotesMutation = useCopyToNote();
  const summarizeText = useSummarizeText();
  const bulletPointList = useBulletPointSummaryText();
  const aiLookup = useAILookupText();
  const modelLogFeedback = useSendModelLogFeedback();

  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  const docProcessingData = useDocumentProcessingData(workspaceId, documentId);
  const debouncedSearchValue = useDebounce(searchResults?.searchValue, 2000);

  const [zoomLevel, setZoomLevel] = useState<number | undefined>(undefined);
  const debouncedZoom = useDebounce(zoomLevel);
  useCustomEventListener<{ text: string }>(
    `run-tutorial-${documentId}`,
    () => {
      setState({ run: true });
      mixpanelTrack("tutorial_document_reopen");
    },
    [setState],
  );
  useCustomEventListener<{ text: string }>(
    `copy-to-notes-${documentId}`,
    (data) => {
      mixpanelTrack("note_highlight_text_added");
      copyToNotesMutation.mutate({
        text: data.text,
        page: currentPage[documentId],
        originalText: data.text,
      });
    },
    [copyToNotesMutation.mutate, currentPage],
  );
  useCustomEventListener<{ text: string }>(
    `summarize-text-${documentId}`,
    (data) => {
      summarizeText({
        text: data.text,
      });
    },
    [summarizeText],
  );
  useCustomEventListener<{ text: string }>(
    `bullet-point-list-${documentId}`,
    (data) => {
      bulletPointList({
        text: data.text,
      });
    },
    [bulletPointList],
  );
  useCustomEventListener<{ text: string }>(
    `ai-lookup-${documentId}`,
    (data) => {
      aiLookup({
        text: data.text,
      });
    },
    [aiLookup],
  );
  useCustomEventListener<{ feedback: ModelLogFeedback }>(
    `modellog-feedback-${documentId}`,
    (data) => {
      modelLogFeedback.mutate({
        thumbsUp: data.feedback,
      });
    },
    [modelLogFeedback.mutate],
  );

  useCustomEventListener<{ pageNumber: number }>(
    `page-number-${documentId}`,
    ({ pageNumber }) => {
      setActivePage(documentId, pageNumber);
      setViewedPagesCount((prev) => prev + 1);
    },
    [setActivePage, documentId],
  );

  useCustomEventListener<{ pageNumber: number }>(
    `got-to-page-${documentId}`,
    ({ pageNumber }) => {
      instance?.Core.documentViewer.setCurrentPage(pageNumber, true);
    },
    [documentId, instance],
  );

  //change buttons based on selection
  useEffect(() => {
    if (instance && pageToOpen) {
      const current = instance.Core.documentViewer.getCurrentPage();
      if (current === pageToOpen) return;
      instance.Core.documentViewer.setCurrentPage(pageToOpen, false);
    }
  }, [instance, pageToOpen]);

  useEffect(() => {
    if (!isFree || isDemo) return;

    if (timeoutFunnyStuff) {
      clearTimeout(timeoutFunnyStuff);
      timeoutFunnyStuff = setTimeout(() => {
        // setAdOpen(isFree);
        timeoutFunnyStuff = null;
      }, 2500);
      return;
    }

    if (viewedPagesCount % 10 === 0 && viewedPagesCount > 0) {
      if (!timeoutFunnyStuff) {
        timeoutFunnyStuff = setTimeout(() => {
          // setAdOpen(isFree);
          timeoutFunnyStuff = null;
        }, 2500);
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [isDemo, isFree, viewedPagesCount]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return () => clearTimeout(timeoutFunnyStuff);
  }, []);

  useEffect(() => {
    if (instance && searchTarget && !timeoutId) {
      timeoutId = setTimeout(() => {
        instance.Core.documentViewer.clearSearchResults();
        clearSearchData(documentId);
        if (searchTarget.page !== undefined)
          instance.Core.documentViewer.setCurrentPage(searchTarget.page === 0 ? 1 : searchTarget.page, false);
        if (searchTarget.single) {
          instance.UI.disableElements(["searchPanel"]);
          instance.UI.searchText(searchTarget.text);
          instance.UI.searchText(searchTarget.text);
        } else {
          instance.UI.enableElements(["searchPanel"]);
          instance.UI.searchTextFull(searchTarget.text);
          instance.UI.searchTextFull(searchTarget.text);
        }
        setPdfViewerSearchTarget({ documentId, payload: undefined });
        timeoutId = undefined;
      }, 500);
    }
  }, [searchTarget, instance, setPdfViewerSearchTarget, documentId, clearSearchData]);

  useEffect(() => {
    if (
      searchResults !== undefined &&
      searchResults.currentViewedResultIndex < searchResults.searchResult.length &&
      instance
    ) {
      instance.Core.documentViewer.setActiveSearchResult(
        searchResults.searchResult[searchResults.currentViewedResultIndex],
      );
    }
  }, [searchResults, searchResults?.currentViewedResultIndex, instance]);

  useEffect(() => {
    if (instance) {
      if (pdfViewerSelectedText.length > 0)
        mixpanelTrack("pdf_highlight_text", {
          number_of_words: _.words(pdfViewerSelectedText).length,
          number_of_characters: pdfViewerSelectedText.length,
        });
      updateUIBasedOnSelection(pdfViewerSelectedText, instance, isFree, docProcessingData.data.isAiAllowed);
    }
  }, [instance, pdfViewerSelectedText, aiLoading, isFree, docProcessingData.data.isAiAllowed, mixpanelTrack]);

  useEffect(() => {
    if (!instance) return;
    updateUIBasedOnSelection(
      lastModelResponse?.modelResponse.responseText ?? "",
      instance,
      isFree,
      docProcessingData.data.isAiAllowed,
    );
  }, [instance, lastModelResponse, isFree, docProcessingData.data.isAiAllowed]);

  useEffect(() => {
    if (adOpen) {
      setTimeout(() => {
        setShowCloseButton(true);
      }, 1500);
    } else {
      setShowCloseButton(false);
    }
  }, [adOpen]);

  useEffect(() => {
    if (debouncedSearchValue) {
      mixpanelTrack("document_simple_search", {
        search_value: debouncedSearchValue,
        input_len: debouncedSearchValue.length,
        input_num_of_words: _.words(debouncedSearchValue).length,
      });
    }
  }, [debouncedSearchValue, mixpanelTrack]);

  useEffect(() => {
    if (debouncedZoom) {
      mixpanelTrack("zoom_document_view", { zoom_size: debouncedZoom });
    }
  }, [debouncedZoom, mixpanelTrack]);

  useEffect(() => {
    if (instance) {
      instance.UI.setTheme(mode ?? "light");
      // const style = instance.UI.iframeWindow.document.documentElement.style;
      // updateStyles(style, mode);
      setTimeout(() => {
        void instance.Core.documentViewer
          .getDocument()
          .getBookmarks()
          .then((bookmarks) => {
            console.log(bookmarks);
            emitCustomEvent(`set-bookmarks-${documentId}`, { bookmarks });
          });
      }, 1000);
    }
  }, [mode, instance, documentId]);

  useEffect(() => {
    if (loaded || viewer.current === null) return;
    WebViewer(config, viewer.current)
      .then((_instance: WebViewerInstance) => {
        if (!_instance) return;

        // now you can access APIs through the WebViewer instance
        const { Core, UI } = _instance;

        //initial style
        UI.setTheme(mode ?? "light");
        // const style = UI.iframeWindow.document.documentElement.style;
        // updateStyles(style, mode);

        let selectedText = "";
        // adding an event listener for when a document is loaded
        Core.documentViewer.addEventListener("documentLoaded", () => {
          if (pageToOpen) {
            Core.documentViewer.setCurrentPage(pageToOpen, false);
          }
          UI.setFitMode(UI.FitMode.FitWidth);
          setInstance(_instance);
        });
        const tool = Core.documentViewer.getTool(Core.Tools.ToolNames.TEXT_SELECT);
        tool.addEventListener("selectionComplete", (_startQuad, allQuads) => {
          selectedText = "";
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Object.keys(allQuads)
            .map(Number)
            .forEach((pageNum: number) => {
              const text = Core.documentViewer.getSelectedText(pageNum);
              selectedText += text;
            });
          setPdfViewerSelectedText(selectedText);
        });
        // adding an event listener for when the page number has changed
        Core.documentViewer.addEventListener("pageNumberUpdated", (pageNumber: number) => {
          emitCustomEvent(`page-number-${documentId}`, { pageNumber });
          // setPagesOfDocuments(paramDocumentId, pageNumber);
          // setViewedPagesCount((prev) => prev + 1);
        });
        Core.documentViewer.addEventListener("zoomUpdated", (zoom: number) => {
          setZoomLevel(zoom);
        });
        UI.addEventListener("visibilityChanged", (event: { detail: { element: string; isVisible: boolean } }) => {
          if (event.detail.element === "outlinesPanel" && event.detail.isVisible) {
            mixpanelTrackService("toc_document_view");
          }
        });
        const searchListener = (searchValue: string, _options: unknown, results: object[]): void => {
          setPdfViewerResults({ documentId, payload: { results, searchValue } });
        };
        UI.addSearchListener(searchListener);
        UI.addEventListener("keydown", (e: KeyboardEvent): void => {
          if ((e.key == "f" && (e.ctrlKey || e.metaKey)) || e.key == "/") {
            UI.enableElements(["searchPanel"]);
            mixpanelTrackService("document_simple_search_shortcut_used");
            customGAEventSender(ANALYTICS_CATEGORY.NOTE_EDITOR, "ctrl_f_used");
          }
        });
        UI.contextMenuPopup.update([]);
        UI.annotationPopup.update([]);
        // Core.documentViewer.setTextHighlightColor(
        //   theme.palette.rukkola.primary.opacity15,
        // );

        // Core.documentViewer.setSearchHighlightColors({
        //   searchResult: theme.palette.rukkola.primary.opacity15,
        //   activeSearchResult: theme.palette.rukkola.primary.opacity50,
        // });
        UI.disableElements([
          "themeChangeButton",
          "rotateHeader",
          "rotateCounterClockwiseButton",
          "rotateClockwiseButton",
          "viewControlsDivider2",
          "viewControlsDivider1",
          "layoutHeader",
          "singleLayoutButton",
          "doubleLayoutButton",
          "coverLayoutButton",
          "pageTransitionHeader",
          "panToolButton",
          "selectToolButton",
          "moreButton",
          "outlinesPanelButton",
          "pageNavOverlay",
          TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON,
          TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON_DISABLED,
          ...(isSharedPage ? ["textPopup"] : []),
        ]);

        UI.textPopup.update([
          {
            type: "customElement",
            render: () => (
              <svg className="notchIcon" viewBox="0 0 33 11">
                <path
                  // eslint-disable-next-line max-len
                  d="M14.0556 9.89213L3.20246 0.91742C2.48535 0.324425 1.58394 2.81413e-07 0.653405 2.00063e-07L32.5559 2.98907e-06C31.6254 2.90772e-06 30.724 0.324428 30.0068 0.917421L19.1537 9.89212C17.6745 11.1153 15.5348 11.1154 14.0556 9.89213Z"
                  fill="inherit"
                />
              </svg>
            ),
          },
          // {
          //   type: "actionButton",
          //   img: "/files/images/add-to-text.svg",
          //   title: "Add to notes",
          //   dataElement: TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON,
          //   onClick: () => emitCustomEvent(`copy-to-notes-${documentId}`, { text: selectedText }),
          // },
          // {
          //   type: "actionButton",
          //   img: "/files/images/add-to-text-disabled.svg",
          //   title: "Add to notes",
          //   dataElement: TEXT_POPUP_BUTTONS.ADD_TO_NOTES_BUTTON_DISABLED,
          // },
          {
            className: "CustomElement",
            type: "customElement",
            dataElement: TEXT_POPUP_BUTTONS.SUMMARIZE_BUTTON,
            title: "Summarization",
            render: () => (
              <PopupButton
                icon="/files/images/summarize-icon.svg"
                id="summarization-pop-button-pdf-viewer-enabled"
                title="Summary"
                onClick={() => emitCustomEvent(`summarize-text-${documentId}`, { text: selectedText })}
              />
            ),
          },
          {
            type: "customElement",
            dataElement: TEXT_POPUP_BUTTONS.SUMMARIZE_BUTTON_DISABLED,
            title: "Summarization",
            render: () => (
              <PopupButton
                disabled
                icon="/files/images/summarize-icon-disabled.svg"
                id="bullet-point-summarization-pop-button-pdf-viewer-enabled"
                title="Summary"
              />
            ),
          },
          {
            type: "customElement",
            title: "Bullet point Summarization",
            dataElement: TEXT_POPUP_BUTTONS.BULLET_POINT_SUMMARIZATION_BUTTON,
            render: () => (
              <PopupButton
                icon="/files/images/bulletpoint-icon.svg"
                id="bullet-point-summarization-pop-button-pdf-viewer-enabled"
                title="Bullet point summary"
                onClick={() => emitCustomEvent(`bullet-point-list-${documentId}`, { text: selectedText })}
              />
            ),
          },
          {
            type: "customElement",
            title: "Bullet point Summarization",
            dataElement: TEXT_POPUP_BUTTONS.BULLET_POINT_SUMMARIZATION_BUTTON_DISABLED,
            render: () => (
              <PopupButton
                disabled
                icon="/files/images/bulletpoint-icon-disabled.svg"
                id="bullet-point-summarization-pop-button-pdf-viewer-enabled"
                title="Bullet point summary"
              />
            ),
          },
          {
            type: "customElement",
            title: "AI Lookup",
            dataElement: TEXT_POPUP_BUTTONS.AI_LOOKUP_SUMMARIZATION,
            render: () => (
              <PopupButton
                icon={"/files/images/ai-lookup-icon.svg"}
                id="ai-lookup-pop-button-pdf-viewer-enabled"
                title="AI Lookup"
                onClick={() => emitCustomEvent(`ai-lookup-${documentId}`, { text: selectedText })}
              />
            ),
          },
          {
            type: "customElement",
            title: "AI Lookup",
            dataElement: TEXT_POPUP_BUTTONS.AI_LOOKUP_SUMMARIZATION_DISABLED,
            render: () => (
              <PopupButton
                disabled
                icon={"/files/images/ai-lookup-icon-disabled.svg"}
                id="ai-lookup-pop-button-pdf-viewer-enabled"
                title="AI Lookup"
              />
            ),
          },
          // {
          //   type: "customElement",
          //   title: "Generate flashcard",
          //   dataElement: TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON,
          //   render: () => (
          //     <PopupButton
          //       icon="/files/images/question-generation-icon.svg"
          //       id="bullet-point-summarization-pop-button-pdf-viewer-enabled"
          //       title="Generate flashcard"
          //       onClick={() => emitCustomEvent(`bullet-point-list-${documentId}`, { text: selectedText })}
          //     />
          //   ),
          // },
          // {
          //   type: "customElement",
          //   title: "Generate flashcard",
          //   dataElement: TEXT_POPUP_BUTTONS.QUESTION_GENERATION_BUTTON_DISABLED,
          //   render: () => (
          //     <PopupButton
          //       icon="/files/images/question-generation-icon-disabled.svg"
          //       id="bullet-point-summarization-pop-button-pdf-viewer-enabled"
          //       title="Generate flashcard"
          //     />
          //   ),
          // },
        ]);

        UI.setHeaderItems((header) => {
          const searchChapterButton = {
            img: "/files/images/v3-design/search-chapter-icon.svg",
            index: -1,
            type: "actionButton",
            element: "searchChapterPanel",
            title: "Search Chapters",
            onClick: () => {
              emitCustomEvent(`toggle-search-chapter-panel-${documentId}`);
            },
          };
          const previousPageButton = {
            type: "statefulButton",
            initialState: "Page",
            states: {
              Page: {
                // Checkout https://www.pdftron.com/api/web/WebViewerInstance.html to see more APIs related with viewerInstance
                title: "Previous page",
                img: "/files/images/v3-design/chevron-left.svg",
                className: "page-stepper",
                onClick: (update: () => void) => {
                  const currentPage = Core.documentViewer.getCurrentPage();
                  const totalPages = Core.documentViewer.getPageCount();
                  const atFirstPage = currentPage <= 1;

                  if (atFirstPage) {
                    Core.documentViewer.setCurrentPage(totalPages, false);
                  } else {
                    Core.documentViewer.setCurrentPage(currentPage - 1, false);
                  }
                  update();
                },
              },
            },
            mount: (update: () => void) => {
              Core.documentViewer.addEventListener("pageNumberUpdated.prevPageButton", () => {
                update();
              });
            },
            unmount: () => {
              Core.documentViewer.removeEventListener("pageNumberUpdated.prevPageButton");
            },
            dataElement: "prevPageButton",
          };

          const pageDisplay = {
            type: "statefulButton",
            initialState: "Page",
            states: {
              Page: {
                // Checkout https://www.pdftron.com/api/web/WebViewerInstance.html to see more APIs related with viewerInstance
                className: "page-display",
                getContent: () => {
                  try {
                    return `${Core.documentViewer.getCurrentPage()} of ${Core.documentViewer.getPageCount()}`;
                  } catch (error) {
                    return `${Core.documentViewer.getCurrentPage()}`;
                  }
                },
                title: "Current page",
                img: "",
                onClick: () => {},
              },
            },
            mount: (update: () => void) => {
              Core.documentViewer.addEventListener("pageNumberUpdated.pageDisplay", () => {
                update();
              });
            },
            unmount: () => {
              Core.documentViewer.removeEventListener("pageNumberUpdated.pageDisplay");
            },
            dataElement: "pageDisplay",
          };

          const nextPageButton = {
            type: "statefulButton",
            initialState: "Page",
            states: {
              Page: {
                // Checkout https://www.pdftron.com/api/web/WebViewerInstance.html to see more APIs related with viewerInstance
                title: "Next page",
                className: "page-stepper",
                img: "/files/images/v3-design/chevron-right.svg",
                onClick: (update: () => void) => {
                  const currentPage = Core.documentViewer.getCurrentPage();
                  const totalPages = Core.documentViewer.getPageCount();
                  const atLastPage = currentPage === totalPages;

                  if (atLastPage) {
                    Core.documentViewer.setCurrentPage(1, false);
                  } else {
                    Core.documentViewer.setCurrentPage(currentPage + 1, false);
                  }
                  update();
                },
              },
            },
            mount: (update: () => void) => {
              Core.documentViewer.addEventListener("pageNumberUpdated.nextPageButton", () => {
                update();
              });
            },
            unmount: () => {
              Core.documentViewer.removeEventListener("pageNumberUpdated.nextPageButton");
            },
            dataElement: "nextPageButton",
          };

          const items = header.getItems();
          items.splice(11, 1);
          items.splice(1, 2);
          items.reverse();
          items.splice(items.length - 1, 0, searchChapterButton);

          // add page stepper
          items.splice(3, 0, nextPageButton);
          items.splice(3, 0, pageDisplay);
          items.splice(3, 0, previousPageButton);
          items.splice(3, 0, {
            dataElement: "spacerHeaderCustomQuino",
            className: "custom-ribbons-container",
            type: "customElement",
            render: () => <div className="custom-ribbons-container" />,
          });

          // swap outline and search buttons
          const outline = items.pop();
          const search = items.splice(2, 1)[0];

          search && items.push(search);
          outline && items.unshift(outline);

          items.push({
            img: "/files/images/v3-design/download.svg",
            type: "actionButton",
            element: "downloadPDFButton",
            title: "Download",
            onClick: () => {
              void UI?.downloadPdf({
                filename: documentName,
              });
            },
          });

          header.update(items);
        });
        UI.updateElement("thumbnailsPanelButton", {
          img: "/files/images/page-view.svg",
          title: "Pages",
        });
        UI.updateElement("zoomThumbInButton", {
          img: "/files/images/v3-design/zoom-in-icon.svg",
        });
        UI.updateElement("zoomThumbOutButton", {
          img: "/files/images/v3-design/zoom-out-icon.svg",
        });
        UI.updateElement("outlinesPanelButton", {
          img: "/files/images/outlines-icon.svg",
          title: "Pages",
        });
        UI.updateElement("leftPanelButton", {
          img: "/files/images/panel-icon.svg",
          title: "Pages",
        });
        UI.updateElement("viewControlsButton", {
          img: "/files/images/view-controls.svg",
          title: "Page Transition",
        });
        UI.updateElement("searchButton", {
          img: "/files/images/search-icon.svg",
          title: "Search",
        });
        UI.updateElement("zoomOutButton", {
          img: "/files/images/zoom-out.svg",
        });
        UI.updateElement("zoomInButton", {
          img: "/files/images/zoom-in.svg",
        });
        UI.updateElement("zoomOverlay", {
          img: "/files/images/zoom-overlay.svg",
        });
      })
      .catch((err) => console.error(err))
      .finally(() => setLoaded(true));
  }, [config, documentId, documentName, isSharedPage, loaded, mode, pageToOpen, setPdfViewerResults]);

  useEffect(() => {
    if (instance) {
      void instance.Core.documentViewer.loadDocument(fileUrl, {
        filename: `${documentId}.pdf`,
      });
    }
  }, [documentId, fileUrl, instance]);

  return (
    <div className="relative h-full bg-white @container">
      {adOpen && (
        <section className="absolute flex h-full w-full items-center justify-center bg-primary/25 backdrop-blur-sm">
          <section className="h-7/12 relative w-7/12">
            {/*done*/}
            <FunnyStuff adId={FunnyStuffType.quino_doc_300x250} className="flex w-full justify-center" />
            {showCloseButton && (
              <Button
                className="absolute -right-6 -top-6 p-1"
                id="close-ad-on-document-page"
                size="icon"
                variant="icon"
                onClick={() => setAdOpen(false)}
              >
                <CloseIcon className="h-5 w-5" />
              </Button>
            )}
          </section>
        </section>
      )}
      {!loaded && <EmptyDocumentView />}
      <div className="webviewer invisible h-full data-[loaded=true]:visible" data-loaded={loaded} ref={viewer} />
      <Helmet>
        <title>Quino AI | Document | {documentName}</title>
        <meta
          content="Interact with your documents using various AI tools! Summarzie and chat with your documents!"
          name="description"
        />
      </Helmet>
    </div>
  );
};
export default PdfViewer;
