import React, { useCallback, useEffect, useRef } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import autosize from "autosize";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useDocumentChatSession, useStreamingMessage } from "@/api/document-chat";
import AiModelSelector from "@/components/note/chat/ai-model-selector.tsx";
import ProjectDocumentSelector from "@/components/note/chat/project-document-selector.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { LockIcon } from "@/components/v3";
import { useIsChatLoading } from "@/service/chat.ts";
import { useChatDocuConfig } from "@/service/hooks/documents.ts";
import useAppStateStore from "@/zustand/store.ts";

const FormSchema = z.object({
  prompt: z.string().min(1, {
    message: "Input must be at least 1 character",
  }),
  documentOnly: z.boolean(),
});
type FormSchemaType = z.infer<typeof FormSchema>;

const QuinoChatForm = () => {
  const [storageState] = useChatDocuConfig();

  const form = useForm<FormSchemaType>({
    defaultValues: {
      documentOnly: storageState.documentOnly,
    },
    resolver: zodResolver(FormSchema),
  });
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    form.setValue("documentOnly", storageState.documentOnly);
  }, [storageState.documentOnly, form]);

  const chatDocuSearch = useAppStateStore((state) => state.chatDocuSearch);
  const { data: sessionData } = useDocumentChatSession();

  const { mutate: sendStreamingMessage } = useStreamingMessage();

  const submitHandler = useCallback(
    ({ prompt, documentOnly }: { prompt: string; documentOnly: boolean }) => {
      sendStreamingMessage({ text: prompt, projectWide: !documentOnly });
      form.reset({
        prompt: "",
        documentOnly,
      });
      setTimeout(() => textareaRef?.current && autosize.update(textareaRef.current), 100);
    },
    [form, sendStreamingMessage],
  );

  const textAreaEdit = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        void form.handleSubmit(submitHandler)();
      }
    },
    [form, submitHandler],
  );

  useEffect(() => {
    if (chatDocuSearch) form.setValue("prompt", chatDocuSearch.text);
  }, [chatDocuSearch, form]);

  useEffect(() => {
    if (!sessionData)
      form.reset({
        documentOnly: storageState.documentOnly,
        prompt: "",
      });
  }, [form, sessionData, storageState.documentOnly]);

  useEffect(() => {
    if (textareaRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      autosize(textareaRef.current);
    }
  }, []);

  const isLoading = useIsChatLoading();

  return (
    <Form {...form}>
      <form
        className="z-20 w-full border-t bg-gradient-to-t from-white from-[75%] to-transparent px-3 py-1 dark:from-transparent"
        onSubmit={form.handleSubmit(submitHandler)}
      >
        <FormField
          control={form.control}
          name="prompt"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Typography asChild size="small">
                  <Textarea
                    className="mx-auto max-h-36 min-h-0 w-full resize-none rounded-lg border-0 px-1 py-2 text-sm font-normal text-primary/75 transition-all placeholder:text-primary/50 focus-visible:ring-0 focus-visible:ring-offset-0 dark:placeholder:text-white/50"
                    disabled={isLoading}
                    placeholder="Type your questions here..."
                    ref={textareaRef}
                    rows={1}
                    value={field.value}
                    onChange={field.onChange}
                    onKeyDown={textAreaEdit}
                  />
                </Typography>
              </FormControl>
            </FormItem>
          )}
        />
        <section className="flex items-center justify-between">
          <section className="flex flex-row items-center gap-2">
            <section>
              <ProjectDocumentSelector />
            </section>
            <section>
              <AiModelSelector />
            </section>
            <section>
              <Button
                disabled
                className="flex flex-row items-center gap-1 p-1.5 text-xs text-tertiary-text-default opacity-40"
                size="sm"
                variant="ghost"
              >
                <LockIcon />
                Private
              </Button>
            </section>
          </section>
          <Button disabled={!form.formState.isValid} size="xs" type="submit" variant="ghost">
            Send
          </Button>
        </section>
      </form>
    </Form>
  );
};

export default QuinoChatForm;
