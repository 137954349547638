import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Document = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-10 w-10 fill-default", props.className)}
    >
      <path
        d="M18 2a2 2 0 0 1 2 2v11.586A2 2 0 0 1 19.414 17L15 21.414a2 2 0 0 1-1.414.586H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12Zm0 2H6v16h6v-4.5a1.5 1.5 0 0 1 1.5-1.5H18V4Zm-.414 12H14v3.586L17.586 16ZM10 11a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h1Zm5-4a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2Z"
        fill="inherit"
        fillRule="nonzero"
      />
    </svg>
  );
};
export default Document;
