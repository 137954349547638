import { useCallback } from "react";

import { useCopyToNote } from "@/api/ai-function.ts";
import { AddToNoteIcon } from "@/components/icons/summary";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/utils";

function AddToNoteButton({ text, onClick, className }: { onClick?: () => void; text: string; className?: string }) {
  const { mutate: addToNote } = useCopyToNote();

  const callback = useCallback(() => {
    addToNote({
      text,
    });
    onClick?.();
  }, [addToNote, onClick, text]);

  return (
    <Button className={cn("", className)} size="xs" variant="ghost" onClick={callback}>
      <span>
        <AddToNoteIcon />
      </span>
      Add to Notes
    </Button>
  );
}

export default AddToNoteButton;
