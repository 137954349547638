export enum ACCESS_STATUS {
  LOADING = "LOADING",
  UNKNOWN = "UNKNOWN",
  HAS_ACCESS = "HAS_ACCESS",
  NO_ACCESS = "NO_ACCESS",
}

export enum PERMISSION {
  NO_CREDIT = "NO_CREDIT",
}

export const DISABLED_COUNTRY_TAGS = ["IN", "PK"];

export const PRESERVED_QUERY_PARAMS = ["redirectTo"];

export const FORMATS = ["PDF", "DOC", "DOCX", "PPT", "PPTX", "XLS", "XSLX", "TXT", "LTX"];
export const ACCEPTED_FORMATS_FREE = {
  "application/pdf": [".pdf"],
};
export const ACCEPTED_FORMATS = {
  ...ACCEPTED_FORMATS_FREE,
  "application/x-latex": [".latex"],
  "text/x-tex": [".tex", ".ltx", ".sty", ".cls"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
  "text/plain": [".txt"],
  "text/html": [".html", ".htm"],
};
