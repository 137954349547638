import { z } from "zod";

import { PERMISSION } from "@/assets/constants/user.ts";
import { ACTIVITY_TYPE } from "@/types/home";

const userData = z.object({
  userId: z.string(),
  nickName: z.string(),
  email: z.string(),
  joinedDate: z.string(),
});

const genericActivity = z.object({
  activityType: z.nativeEnum(ACTIVITY_TYPE),
  extraInfo: z.any(),
  id: z.string(),
  workspaceId: z.string(),
  userData: userData,
  imageUrl: z.string(),
  dateCreated: z.string(),
});
export type UserData = z.infer<typeof userData>;
export type GenericActivity = z.infer<typeof genericActivity>;

export const permissionSchema = z
  .object({
    name: z.nativeEnum(PERMISSION),
    category: z.string(),
  })
  .strict();
export type Permission = z.infer<typeof permissionSchema>;

export const roleSchema = z
  .object({
    name: z.string(),
    permissions: z.array(permissionSchema),
  })
  .strict();

export type RoleSchema = z.infer<typeof roleSchema>;

export const countryIsSchema = z
  .object({
    ip: z.string(),
    country: z.string(),
  })
  .strict();

export type CountryIsSchema = z.infer<typeof countryIsSchema>;

export type UserOnboardingDataSchema = {
  documentUploaded: boolean;
  profileCompleted: boolean;
  rewardClaimed: boolean;
  extraTokensToAdd?: number | undefined;
};
