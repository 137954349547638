import mixpanel from "mixpanel-browser";

import { MINYMA_STRING, QUINO_STRING } from "@/assets/constants/constants";

window.global ||= window;

export default function init(): void {
  const token = import.meta.env.VITE_MIXPANEL_TOKEN;
  mixpanel.init(token, {
    debug: process.env.NODE_ENV === "development",
    api_host: import.meta.env.VITE_PROXY_URL,
    persistence: "cookie",
    cross_subdomain_cookie: true,
  });
  if (process.env.NODE_ENV === "production") {
    console.log(MINYMA_STRING);
    console.log(`%c \n${QUINO_STRING}`, "color:#33124D");
  }
}
