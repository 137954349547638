import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const Subscription = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-text-default", props.className)}
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34314C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34314C16.1566 4.84285 14.1217 4 12 4ZM15.003 7.997C15.2481 7.99679 15.4848 8.0866 15.668 8.24938C15.8512 8.41216 15.9683 8.63658 15.997 8.88L16.003 8.997V13.997C16.0027 14.2519 15.9051 14.497 15.7302 14.6824C15.5552 14.8677 15.316 14.9792 15.0616 14.9942C14.8072 15.0091 14.5566 14.9263 14.3612 14.7627C14.1657 14.5991 14.0401 14.3671 14.01 14.114L14.003 13.997V11.411L9.707 15.707C9.52704 15.8863 9.28557 15.9905 9.03162 15.9982C8.77767 16.006 8.53029 15.9168 8.33972 15.7488C8.14916 15.5807 8.02969 15.3464 8.0056 15.0935C7.98151 14.8406 8.05459 14.588 8.21 14.387L8.293 14.293L12.589 9.997H10.003C9.74821 9.99646 9.50322 9.89869 9.31808 9.72364C9.13293 9.54859 9.02159 9.30947 9.00679 9.05511C8.99198 8.80074 9.07483 8.55032 9.23842 8.35497C9.402 8.15962 9.63399 8.03409 9.887 8.004L10.003 7.997H15.003Z"
        fill="inherit"
      />
    </svg>
  );
};

export default Subscription;
