import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const ArtAndDesign = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <g clipPath="url(#clip0_2426_507)">
      <path
        d="M4.05507 22.2404L8.18046 25.9863L6.34237 33.0655L15.2933 37.7918L15.8463 51.9421L27.8469 40.9892L36.6003 49.7764L46.4311 43.4227L48.1894 35.0608L55.5789 33.5914L47.6963 23.2194L54.3772 14.8849L40.6595 8.6379L27.7992 16.129L23.732 7.58457L11.3823 16.2533L14.0832 21.6587L4.05507 22.2404Z"
        fill="#E2D7F4"
      />
      <path
        d="M34.5511 45.1237C34.2913 44.7964 20.2396 18.5477 20.2396 18.5477L13.1704 12.3527L13.3488 21.1012L27.8691 48.5938C29.2461 49.5545 31.0392 49.6429 32.4598 48.8201C33.7639 48.0649 34.5597 46.6492 34.5511 45.1237Z"
        fill="#ED8657"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M15.3536 22.922L19.0023 21.0003"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M1.07972 12.3714C3.31668 11.4448 4.23968 11.5915 4.63119 11.9896C5.32475 12.6957 4.33314 14.1277 5.1638 15.0901C5.88213 15.9195 7.39241 15.7522 8.16499 15.6562C9.78119 15.4687 12.0015 13.5001 13.0014 12.5003"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M31.2927 43.2763C31.4649 43.116 34.0007 37.5154 34.1836 37.21C34.3665 36.9047 46.9137 42.419 46.9137 42.419C46.9137 42.419 46.0205 44.544 46.0161 44.5552C45.9987 44.6004 44.0648 49.2148 44.0474 49.2599L31.2927 43.2763Z"
        fill="#F7C6AE"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M37.002 40.5006C40.9282 42.1683 41.3896 42.8433 45.3124 44.5032"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M36.0055 37.798C35.5818 34.5347 35.1546 31.2636 34.7309 28.0003L38.0539 22.594C38.087 22.542 38.1792 22.4544 38.1792 22.4544L49.0464 12.9827C49.2778 12.7868 49.5939 12.7405 49.8672 12.8722L52.3676 14.0856C52.6149 14.2007 52.7716 14.4494 52.7893 14.7221L53.6262 30.1277C53.6364 30.2354 53.6197 30.3456 53.5726 30.4506L51.0015 35.5007C48.7183 37.4642 46.9681 39.4962 44.685 41.4597C41.7904 40.2429 38.8958 39.026 36.0011 37.8092L36.0055 37.798Z"
        fill="#F7C6AE"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M48.001 27.0006C48.501 29.7637 45.5005 30.9797 43.8841 30.2637L43.8876 30.2715C42.6086 29.7055 41.7746 27.5869 42.8437 26.1135C43.5524 25.1362 44.7713 24.8684 45.8163 25.117"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M50.9103 13.3728L46.0183 24.7471"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2426_507">
        <rect fill="white" height="57" width="60" />
      </clipPath>
    </defs>
  </svg>
);
export default ArtAndDesign;
