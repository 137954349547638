import { useMemo } from "react";

import { useAvailableModels } from "@/api/ai-function.ts";
import { AIModelIcon } from "@/components/icons/summary";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { InfiniteLoading } from "@/pages/loading";
import { useUserElectedModel } from "@/service/user.ts";

const AiModelSelector = () => {
  const [selectedModel, setSelectedModel] = useUserElectedModel();
  const { data, isLoading } = useAvailableModels();

  const selectValues = useMemo(
    () =>
      data?.map((model) => (
        <SelectItem className="text-sm" key={model.modelCode} value={model.modelCode}>
          {model.modelName}
        </SelectItem>
      )),
    [data],
  );
  if (isLoading) return <InfiniteLoading className="h-4 w-4" />;
  return (
    <Select defaultValue={selectedModel} onValueChange={(value) => setSelectedModel(value)}>
      <SelectTrigger
        hideArrow
        className="h-7 gap-1 rounded-lg border-0 p-1.5 text-xs font-bold text-tertiary-text-default transition-colors duration-300 ease-in-out hover:bg-tertiary-container-hover"
      >
        <span className="h-4 w-4">
          <AIModelIcon className="h-4 w-4" />
        </span>
        <SelectValue className="h-7 text-xs" />
      </SelectTrigger>
      <SelectContent align="center" className="w-72 overflow-hidden rounded-xl p-2">
        <Typography className="p-2 pb-4 font-bold text-secondary-onBg" size="extra-small">
          Select an AI model to answer
        </Typography>
        {selectValues}
      </SelectContent>
    </Select>
  );
};

export default AiModelSelector;
