import { createContext, type MouseEvent, type Ref, useEffect, useMemo } from "react";

import {
  BoldItalicUnderlineToggles,
  headingsPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  type MDXEditorMethods,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { createPortal } from "react-dom";

import { useDragAndDropContext } from "@/components/note/drag-and-drop-context.tsx";
import { useTypographyConfig } from "@/service/hooks/misc.ts";
import { cn } from "@/utils";

import "./index.css";
import { MyBlockTypeSelect } from "./toolbar/my-block-type-select";

const EditorContext = createContext<{ showToolbar: boolean; portalTarget: HTMLElement | null; isDragActive: boolean }>({
  showToolbar: true,
  isDragActive: false,
  portalTarget: null,
});

const QuinoMdxEditor = ({
  markdown = "",
  onChange,
  showToolbar = false,
  readOnly = false,
  editorRef,
  wrapperId,
}: {
  markdown?: string;
  onChange?: (value: string) => void;
  saveEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
  cancelEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
  showToolbar?: boolean;
  readOnly?: boolean;
  editorRef?: Ref<MDXEditorMethods>;
  wrapperId: string;
}) => {
  const [config] = useTypographyConfig();

  const target = document.getElementById(wrapperId);
  useEffect(() => {
    if (!target || !showToolbar) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          const item = document.getElementById(`${wrapperId}-toolbar`);
          item?.classList.toggle("-translate-y-[110%]", false);
        } else {
          const item = document.getElementById(`${wrapperId}-toolbar`);
          item?.classList.toggle("-translate-y-[110%]", true);
        }
      },
      { threshold: [1] },
    );
    observer.observe(target);
    return () => {
      observer.disconnect();
    };
  }, [target, wrapperId, showToolbar]);
  const { isDragActive } = useDragAndDropContext();
  const dragCss = useMemo(() => (isDragActive ? "[&_*]:select-none" : ""), [isDragActive]);
  return (
    <EditorContext.Provider value={{ showToolbar: showToolbar, portalTarget: target, isDragActive }}>
      <MDXEditor
        className={cn(dragCss, "editor-base")}
        contentEditableClassName={`prose export-${wrapperId} prose-size-${config.size}`}
        markdown={markdown}
        placeholder="Add text here..."
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          quotePlugin(),
          thematicBreakPlugin(),
          linkPlugin(),
          tablePlugin(),
          markdownShortcutPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <EditorContext.Consumer>
                {/*  TODO finish the design for the selectors, NOTE: try to negotiate to stay with the current one otherwise all of them had to be rewritten*/}
                {({ showToolbar, portalTarget, isDragActive }) => (
                  <>
                    {showToolbar &&
                      portalTarget !== null &&
                      createPortal(
                        <section
                          className="quino-toolbar-root absolute z-[1000]  flex h-8 -translate-y-[110%] flex-row items-center gap-2 overflow-hidden rounded-xl border border-stroke-default bg-white px-4 py-5 shadow-elevation-1 transition-transform animate-in fade-in-5 dark:bg-primary-dark-light [&>*]:flex [&>*]:items-center [&_div]:gap-2 [&_svg]:h-4 [&_svg]:w-4"
                          data-is-drag={isDragActive}
                          id={`${wrapperId}-toolbar`}
                        >
                          <MyBlockTypeSelect />
                          <BoldItalicUnderlineToggles />
                          <ListsToggle />
                        </section>,
                        portalTarget,
                      )}
                  </>
                )}
              </EditorContext.Consumer>
            ),
          }),
        ]}
        readOnly={readOnly}
        ref={editorRef}
        onChange={onChange}
      />
    </EditorContext.Provider>
  );
};

export default QuinoMdxEditor;
