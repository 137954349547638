import React from "react";

import { GraphTopLeft } from "@/components/icons/login";
import { LogoWhite } from "@/components/icons/login";
import { GraphTopRight } from "@/components/icons/login";
import { GraphBottomLeft } from "@/components/icons/login";
import { GraphBottomMiddle } from "@/components/icons/login";
import { GraphBottomRight } from "@/components/icons/login";
import { cn } from "@/utils";

const FormPageLayout = ({
  children,
  title,
  wide,
  className,
}: {
  children?: React.ReactNode;
  title?: string;
  wide?: boolean;
  className?: string;
}) => (
  <main className="relative flex h-screen flex-col justify-start overflow-y-hidden bg-primitive-purple-600 font-jakarta">
    <GraphTopLeft className="absolute left-[82px] top-[50.66px] h-[268px] w-[260px]" />
    <LogoWhite className="absolute left-1/2 top-[50px] h-[47.73px] w-[172px] translate-x-[-50%]" />
    <GraphTopRight className="absolute right-[35px] top-[144px] hidden h-[137px] w-[145px] sm:block" />
    <GraphBottomLeft className="absolute bottom-[25.09px] left-[47px] hidden h-[278.91px] w-[282px] sm:block" />
    <GraphBottomMiddle className="absolute -bottom-18 left-1/2 h-[221.56px] w-[203.62px] translate-x-[-50%]" />
    <GraphBottomRight className="absolute bottom-[46.96px] right-[20px] h-[303.04px] w-[300px]" />
    <section className="flex h-full w-full flex-col justify-center px-2">
      <section className="overflow-auto pb-20 pt-40">
        <section
          className={cn(
            `relative z-[100] mx-auto w-full rounded-2xl bg-white px-5 py-8 ${
              wide ? "w-max px-12 pt-12" : "max-w-[32rem]"
            }`,
            className,
          )}
        >
          {title && <h1 className="text-center text-h3 text-primary-onBg">{title}</h1>}
          {children}
        </section>
      </section>
    </section>
  </main>
);

export default FormPageLayout;
