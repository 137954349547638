import { z } from "zod";

import { passwordField } from "@/assets/constants/form";
import { validateFormString } from "@/utils";

export const registerFormSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: "Firstname is required!" })
      .refine(validateFormString, () => ({ message: "Contains profanity!" })),
    lastName: z
      .string()
      .min(1, { message: "Lastname is required!" })
      .refine(validateFormString, () => ({ message: "Contains profanity!" })),
    email: z.string().email({ message: "Valid email is required!" }),
    newsletterSubscription: z.boolean(),
    password: passwordField,
    confirmPassword: z.string().min(1, { message: "Confirm Password is required" }),
    captchaToken: z.string().min(1, { message: "Captcha must be accepted!" }),
    terms: z.literal(true, {
      errorMap: () => ({ message: "You must accept Terms and Conditions" }),
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Password don't match",
  });
export type RegisterFormSchema = z.infer<typeof registerFormSchema>;
