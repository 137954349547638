/* eslint-disable no-undef  */

/* eslint-disable @typescript-eslint/no-unsafe-call  */

/* eslint-disable @typescript-eslint/no-unsafe-call  */

/* eslint-disable @typescript-eslint/no-unsafe-member-access  */
import { useEffect, useMemo } from "react";

import { type FunnyStuffType } from "@/assets/constants/funny-stuff.ts";

// import { useIsFreeUser } from "@/service/hooks/settings.ts";

const FunnyStuffInternal = ({ adId }: { adId: FunnyStuffType }) => {
  useEffect(() => {
    const loadAd = () => {
      if (typeof pubwise !== "undefined" && pubwise.enabled === true) {
        pubwise.que.push(() => {
          pubwise.renderAd(adId);
        });
      } else {
        googletag.cmd.push(() => {
          googletag.display(adId);
          googletag.pubads().refresh([gptadslots[adId]]);
        });
      }
    };

    // Load ad when component mounts
    setTimeout(() => {
      loadAd();
    }, 1000);

    // Clean up function if needed
    // For example, remove the ad when component unmounts
    return () => {
      // Remove or cleanup ad if necessary
    };
  }, [adId]);

  const resolution = useMemo(() => {
    const items = adId.split("_");
    const res = items[2].split("x");
    return {
      x: Number.parseInt(res[0]),
      y: Number.parseInt(res[1]),
    };
  }, [adId]);

  return (
    <div
      id={adId}
      style={{
        backgroundColor: "transparent",
        height: `${resolution.y}px`,
        width: `${resolution.x}px`,
      }}
    ></div>
  );
};

const FunnyStuff = ({ adId, className }: { adId: FunnyStuffType; className?: string }) => {
  const isFreeUser = false; // useIsFreeUser(); needs review

  return useMemo(() => {
    if (process.env.NODE_ENV === "production") return null;
    if (isFreeUser)
      return (
        <section className={className}>
          <FunnyStuffInternal adId={adId} />
        </section>
      );
    return null;
  }, [adId, className, isFreeUser]);
};

export default FunnyStuff;
