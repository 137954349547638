import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Check = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-default", props.className)}
    >
      <path
        d="M21.192 5.465a1 1 0 0 1 0 1.414L9.95 18.122a1.1 1.1 0 0 1-1.556 0l-5.586-5.586a1 1 0 1 1 1.415-1.415l4.95 4.95L19.777 5.465a1 1 0 0 1 1.414 0Z"
        fill="inherit"
      />
    </svg>
  );
};
export default Check;
