import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const ListIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      {...props}
      className={cn("h-4 w-4 fill-text-default", props.className)}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3.33333 2.34375C2.97971 2.34375 2.64057 2.48423 2.39052 2.73427C2.14048 2.98432 2 3.32346 2 3.67708V6.34375C2 6.69737 2.14048 7.03651 2.39052 7.28656C2.64057 7.53661 2.97971 7.67708 3.33333 7.67708H12.6667C13.0203 7.67708 13.3594 7.53661 13.6095 7.28656C13.8595 7.03651 14 6.69737 14 6.34375V3.67708C14 3.32346 13.8595 2.98432 13.6095 2.73427C13.3594 2.48423 13.0203 2.34375 12.6667 2.34375H3.33333ZM3.33333 3.67708H12.6667V6.34375H3.33333V3.67708ZM3.33333 9.01042C2.97971 9.01042 2.64057 9.15089 2.39052 9.40094C2.14048 9.65099 2 9.99013 2 10.3438V13.0104C2 13.364 2.14048 13.7032 2.39052 13.9532C2.64057 14.2033 2.97971 14.3438 3.33333 14.3438H12.6667C13.0203 14.3438 13.3594 14.2033 13.6095 13.9532C13.8595 13.7032 14 13.364 14 13.0104V10.3438C14 9.99013 13.8595 9.65099 13.6095 9.40094C13.3594 9.15089 13.0203 9.01042 12.6667 9.01042H3.33333ZM3.33333 10.3438H12.6667V13.0104H3.33333V10.3438Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default ListIcon;
