import { useEffect, useMemo } from "react";

import cuid2 from "@paralleldrive/cuid2";
import { type QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import camelize from "camelize";
import { SSE } from "sse.js";

import { getDemoQuestionSuggestions } from "@/api/ai-function.ts";
import { createDemoNewSession } from "@/api/demo";
import {
  insertDocumentChatSessionWithInitialMessage,
  onDeleteSessionSuccess,
  onUpdateSessionSuccess,
  onUpdateSuccess,
} from "@/api/document-chat/utils.ts";
import { useCurrentProjectDocumentIds } from "@/api/document.ts";
import { deleteRequest, getRequest, postRequest, putRequest } from "@/api/utils.ts";
import { DEMO_SESSION_ID, DEMO_SESSION_LANGUAGE, LAST_ELEMENT_ID } from "@/assets/constants/chat.ts";
import { BACKEND_URL, MODEL_LAKE_URL, ModelLogFeedback } from "@/assets/constants/constants.ts";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { useToast } from "@/components/ui/use-toast.ts";
import { getAccessToken } from "@/firebase";
import { getDemoDocumentIdForQA } from "@/service/demo";
import { GenericEventError, type GenericFetchError } from "@/service/errors";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import {
  useCurrentDocumentTabEntity,
  useDocumentChatSessionId,
  useNoteTabState,
  useSetChatSessionWithTab,
} from "@/service/hooks/react-router.ts";
import { useCurrentDocumentTabId } from "@/service/hooks/react-router.ts";
import { useOpenSignUpToQuinoModal } from "@/service/library.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { useUserAiLanguage, useUserElectedModel } from "@/service/user.ts";
import { type StreamingMessagePayload } from "@/types/interfaces/api.ts";
import { type SUPPORTED_LANGUAGES } from "@/types/interfaces/library.tsx";
import {
  type QuestionSuggestion,
  type QuestionSuggestionCreate,
  type DocChatSessionHistory,
  type DocumentChatItem,
  type DocumentChatSession,
  type DocumentChatSessionCreate,
  type DocumentChatSessionInstruction,
  type UpdateDocChatElementPayload,
  type UpdateDocChatSessionPayload,
  type ChatSource,
} from "@/types/schemas";
import { useCurrentProjectId } from "@/utils";

const DOCUMENT_CHAT = "document-chat-session";

export const getQuestionSuggestions = async ({ payload }: { payload: QuestionSuggestionCreate }) => {
  return await postRequest<QuestionSuggestion>(`${BACKEND_URL}/${DOCUMENT_CHAT}/task-generation`, payload);
};

export const getChatHistoryInProject = ({ projectId }: { projectId: string }) =>
  getRequest<DocChatSessionHistory>(`${BACKEND_URL}/${DOCUMENT_CHAT}/in-project/${projectId}`);

export const getDocumentChatHistory = ({ sessionId }: { sessionId: string }) =>
  getRequest<DocumentChatSession>(`${BACKEND_URL}/${DOCUMENT_CHAT}/${sessionId}`);

export const updateDocumentChatHistory = ({
  sessionId,
  payload,
}: {
  sessionId: string;
  payload: UpdateDocChatSessionPayload;
}) => putRequest<Omit<DocumentChatSession, "elements">>(`${BACKEND_URL}/${DOCUMENT_CHAT}/${sessionId}`, payload);

export const updateDocChatElement = ({
  elementId,
  payload,
}: {
  elementId: string;
  payload: UpdateDocChatElementPayload;
}) => putRequest<DocumentChatItem>(`${BACKEND_URL}/${DOCUMENT_CHAT}/doc-chat-element/${elementId}`, payload);

export const getDocChatElement = ({ elementId }: { elementId: string }) =>
  getRequest<DocumentChatItem>(`${BACKEND_URL}/${DOCUMENT_CHAT}/doc-chat-element/${elementId}`);

export const deleteDocumentChatHistory = ({ sessionId }: { sessionId: string }) =>
  deleteRequest<DocumentChatSession>(`${BACKEND_URL}/${DOCUMENT_CHAT}/${sessionId}`);

export const createNewSession = ({ payload }: { payload: DocumentChatSessionCreate }) =>
  postRequest<DocumentChatSession>(`${BACKEND_URL}/${DOCUMENT_CHAT}/open-new`, payload);

export const createNewSessionInstruction = ({ payload }: { payload: DocumentChatSessionInstruction }) =>
  postRequest<DocumentChatItem>(`${BACKEND_URL}/${DOCUMENT_CHAT}/instruct`, payload);
export const getLastMessage = ({ sessionId }: { sessionId: string }) =>
  getRequest<DocumentChatItem>(`${BACKEND_URL}/${DOCUMENT_CHAT}/last-element?doc_chat_session_id=${sessionId}`);

export const useQuestionSuggestion = (item?: DocumentChatItem) => {
  const currentDocumentId = useCurrentDocumentTabId();
  const projectId = useCurrentProjectId();
  const [language] = useUserAiLanguage();
  const { matched: isDemo } = useIsDemoLikePage();
  return useQuery({
    queryKey: [
      "question-suggestions",
      projectId,
      currentDocumentId,
      {
        inputText: item?.inputText,
      },
      language,
    ],
    queryFn: async () => {
      const result = await (isDemo
        ? getDemoQuestionSuggestions({
            payload: {
              expectedResponseLanguage: language,
              documentId: getDemoDocumentIdForQA(currentDocumentId),
            },
          })
        : getQuestionSuggestions({ payload: { expectedResponseLanguage: language, documentId: currentDocumentId } }));

      return result;
    },

    enabled: !!currentDocumentId,
    retry: false,
    refetchOnMount: false,
  });
};

export const useProjectChatHistory = (projectId?: string) => {
  const { matched: isDemoPage } = useIsDemoLikePage();
  return useQuery({
    queryKey: [QUERY_KEYS.CHAT_HISTORY_COMPLETE, { projectId }],
    queryFn: () => getChatHistoryInProject({ projectId: projectId ?? "" }),

    enabled: projectId !== undefined && !isDemoPage,
  });
};
export const useDocumentChatSession = () => {
  const [sessionId, setSessionId] = useDocumentChatSessionId();
  const queryClient = useQueryClient();
  const { matched: isDemoPage } = useIsDemoLikePage();
  const result = useQuery({
    queryKey: [QUERY_KEYS.CHAT_SESSION, { sessionId }],
    queryFn: () => getDocumentChatHistory({ sessionId }),
    enabled: sessionId !== "" && sessionId !== undefined && sessionId !== null && !isDemoPage,
  });

  useEffect(() => {
    if (result.isSuccess && result.data) {
      result.data.elements.forEach((item) => {
        queryClient.setQueryData<DocumentChatItem>([QUERY_KEYS.CHAT_ELEMENT, { elementId: item.id }], () => item);
      });
    }
  }, [queryClient, result.data, result.isSuccess]);

  useEffect(() => {
    if (result.isError) {
      setSessionId("");
    }
  }, [result.isError, setSessionId]);
  return result;
};

export const useLastDocumentElement = () => {
  const { data } = useDocumentChatSession();
  const lastId = useMemo(() => {
    if (!data || data.elements.length === 0) return;
    return data.elements[data.elements.length - 1].id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.elements?.length]);

  return useDocumentChatElementLocal(lastId);
};

export const useDocumentChatElementLocal = (elementId?: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CHAT_ELEMENT, { elementId }],
    queryFn: () => getDocChatElement({ elementId: elementId ?? "" }),
    enabled: false,
  });
};

export const useUpdateSession = () => {
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateDocumentChatHistory,
    onSuccess: onUpdateSessionSuccess(queryClient, isDemo, mixpanelTrack),
  });
};

export const useUpdateHistoryElement = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateDocChatElement,
    onSuccess: onUpdateSuccess(queryClient),
  });
};

export const useDeleteSession = () => {
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  const queryClient = useQueryClient();
  const [sessionId, setSessionId] = useDocumentChatSessionId();
  return useMutation({
    mutationFn: deleteDocumentChatHistory,
    onSuccess: onDeleteSessionSuccess(sessionId, setSessionId, isDemo, mixpanelTrack, queryClient),
  });
};

async function createAndStoreNewSession(
  queryClient: QueryClient,
  payload: DocumentChatSessionCreate,
  isDemo?: boolean,
) {
  const result = isDemo ? await createDemoNewSession({ payload }) : await createNewSession({ payload });
  insertDocumentChatSessionWithInitialMessage(queryClient, result);
  return result;
}

const ragCall = async ({
  text,
  documentIds,
  sessionId,
  expectedResponseLanguage,
  serviceToUse,
}: StreamingMessagePayload): Promise<SSE> => {
  return new SSE(`${MODEL_LAKE_URL}/retrieval/document-chat-stream`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      "Content-Type": "application/json",
    },
    payload: JSON.stringify({
      text: text,
      documentIds: documentIds,
      documentChatSessionId: sessionId,
      expectedResponseLanguage,
      serviceToUse,
    }),
  });
};

const demoRagCall = async ({
  text,
  documentIds,
  sessionId,
  expectedResponseLanguage,
  serviceToUse,
}: StreamingMessagePayload): Promise<SSE> => {
  return new SSE(`${MODEL_LAKE_URL}/retrieval/demo-document-chat-stream`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      "Content-Type": "application/json",
    },
    payload: JSON.stringify({
      text: text,
      documentIds: documentIds,
      documentChatSessionId: sessionId,
      expectedResponseLanguage,
      serviceToUse,
    }),
  });
};

// const terminating = "</s>";
export function getEventStreamContent({
  sessionId,
  text,
  serviceToUse,
  documentIds,
  queryClient,
  isDemoPage,
  language,
}: {
  documentIds: string[];
  sessionId: string;
  text: string;
  serviceToUse: string;
  language: SUPPORTED_LANGUAGES;
  queryClient: QueryClient;
  isDemoPage?: boolean;
}): Promise<string> {
  return new Promise(async (resolve, reject) => {
    let source: SSE;
    if (isDemoPage) {
      source = await demoRagCall({
        sessionId: DEMO_SESSION_ID,
        text,
        documentIds: [getDemoDocumentIdForQA(documentIds[0])],
        expectedResponseLanguage: DEMO_SESSION_LANGUAGE,
        serviceToUse,
      });
    } else
      source = await ragCall({
        sessionId,
        text,
        documentIds,
        expectedResponseLanguage: language,
        serviceToUse,
      });

    let messageCache = "";

    source.addEventListener("error", (e: MessageEvent) => {
      reject(new GenericEventError("Out of requests!", e));
    });

    source.addEventListener("TEXT_CHUNK", (event: MessageEvent<string>) => {
      console.log(event, "");

      const jsonData = camelize(JSON.parse(event.data)) as {
        textChunk?: string;
        pieceType: "TEXT_CHUNK";
        isGenerationFinished: boolean;
      };

      console.log(jsonData);

      queryClient.setQueryData<DocumentChatItem>([QUERY_KEYS.CHAT_ELEMENT, { elementId: LAST_ELEMENT_ID }], (data) => {
        if (!data) return data;

        return { ...data, responseText: messageCache };
      });

      messageCache += jsonData.textChunk ?? "";
    });

    source.addEventListener("SOURCE_LIST", (event: MessageEvent<string>) => {
      const jsonData = camelize(JSON.parse(event.data)) as {
        textChunk?: string;
        pieceType: "SOURCE_LIST";
        sourceList: Record<string, ChatSource[]>;
      };
      console.log(jsonData);

      queryClient.setQueryData<DocumentChatItem>([QUERY_KEYS.CHAT_ELEMENT, { elementId: LAST_ELEMENT_ID }], (data) => {
        if (!data) return data;

        return { ...data, sources: jsonData.sourceList };
      });
    });

    source.addEventListener("SOURCE_SCORE_LIST", (event: MessageEvent<string>) => {
      const jsonData = camelize(JSON.parse(event.data)) as {
        textChunk?: string;
        pieceType: "SOURCE_SCORE_LIST";
        sourceList: Record<string, ChatSource[]>;
      };

      console.log(jsonData);

      // const jsonData = JSON.parse(event.data) as {text_chunk?:string};
      //
      // console.log(event,"")
      //
      // queryClient.setQueryData<DocumentChatItem>([QUERY_KEYS.CHAT_ELEMENT, { elementId: LAST_ELEMENT_ID }], (data) => {
      //   if (!data) return data;
      //
      //   return { ...data, responseText: messageCache };
      // });
      //
      // messageCache += jsonData.text_chunk ?? "";
    });

    source.onreadystatechange = (e) => {
      if (e.readyState === 2) {
        resolve(messageCache);
      }
    };

    source.onabort = () => {
      resolve(messageCache);
    };
  });
}

const setLastMessage = (queryClient: QueryClient, chatElement: DocumentChatItem, usedSessionId: string) => {
  queryClient.setQueryData<DocumentChatItem>([QUERY_KEYS.CHAT_ELEMENT, { elementId: chatElement.id }], () => ({
    ...chatElement,
  }));
  queryClient.setQueryData<DocumentChatSession>([QUERY_KEYS.CHAT_SESSION, { sessionId: usedSessionId }], (data) => {
    if (!data) return data;

    if (chatElement.id === LAST_ELEMENT_ID) {
      return { ...data, name: data.name + " ", elements: [...data.elements, { ...chatElement }] };
    }

    const index = data.elements.findIndex((item) => item.id === LAST_ELEMENT_ID);
    if (index !== -1) {
      data.elements[index] = { ...chatElement };
    }

    return { ...data, name: data.name + " ", elements: [...data.elements] };
  });
};

const deleteLastMessage = (queryClient: QueryClient, sessionId: string) => {
  queryClient.setQueryData<DocumentChatSession>([QUERY_KEYS.CHAT_SESSION, { sessionId: sessionId }], (data) => {
    if (!data) return data;

    return { ...data, elements: [...data.elements.filter((item) => item.id !== LAST_ELEMENT_ID)] };
  });
};

export const useStreamingMessage = () => {
  const [language] = useUserAiLanguage();
  const [sessionId] = useDocumentChatSessionId();
  const [, setNoteTabState] = useNoteTabState();
  const queryClient = useQueryClient();
  const { data: documentEntity } = useCurrentDocumentTabEntity();
  const { toast } = useToast();
  const setSession = useSetChatSessionWithTab();
  const [selectedModel] = useUserElectedModel();
  const documentIds = useCurrentProjectDocumentIds();
  const { matched: isDemoPage } = useIsDemoLikePage();
  const openSignUp = useOpenSignUpToQuinoModal();
  return useMutation({
    mutationKey: ["session-chat", "streaming"],
    mutationFn: async ({ text, projectWide }: { text: string; projectWide: boolean }) => {
      if (!documentEntity) {
        toast({
          title: "No opened document!",
          variant: "destructive",
        });
        throw new Error("No opened document!");
      }

      const docId = projectWide ? undefined : documentEntity.id;
      const projectId = projectWide ? documentEntity.projectId : undefined;

      const docIds = projectWide && documentIds ? documentIds : [documentEntity.id];

      setNoteTabState("chat");
      let _sessionId = sessionId;
      if (!_sessionId) {
        const { id } = await createAndStoreNewSession(queryClient, { text, documentId: docId, projectId }, isDemoPage);
        _sessionId = id;
        setSession(id);
      }

      const initElement = {
        id: LAST_ELEMENT_ID,
        dateCreated: new Date().toISOString(),
        docChatSessionId: sessionId,
        sources: {},
        inputText: text,
        modelError: false,
        responseText: "",
        thumbsUp: ModelLogFeedback.NoResponse,
        usedTokens: 0,
        userAccepted: true,
      };

      setLastMessage(queryClient, initElement, _sessionId);

      const result = await getEventStreamContent({
        sessionId: _sessionId,
        text: text,
        documentIds: docIds,
        queryClient,
        isDemoPage,
        language,
        serviceToUse: selectedModel,
      });

      return {
        usedSessionId: _sessionId,
        result,
      };
    },
    onSuccess: async ({ usedSessionId }) => {
      if (isDemoPage) {
        const result = queryClient.getQueryData<DocumentChatItem>([
          QUERY_KEYS.CHAT_ELEMENT,
          { elementId: LAST_ELEMENT_ID },
        ])!;
        const randomId = cuid2.createId();
        const newEntry: DocumentChatItem = { ...result, id: randomId };
        setLastMessage(queryClient, newEntry, usedSessionId);
        return;
      }

      try {
        const result = await getLastMessage({ sessionId: usedSessionId });
        setLastMessage(queryClient, result, usedSessionId);
      } catch (e) {}
    },
    onError: (e: GenericFetchError) => {
      console.log(e, "error");

      deleteLastMessage(queryClient, sessionId);
      if (e.payload.status === 429) openSignUp();
      else
        toast({
          title: "Something went wrong!",
          variant: "destructive",
        });
    },
  });
};
