import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const GridIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-text-default", props.className)}
    >
      <path
        clipRule="evenodd"
        d="M6 8.66667C6.35362 8.66667 6.69276 8.80715 6.94281 9.0572C7.19286 9.30725 7.33333 9.64638 7.33333 10V12.6667C7.33333 13.0203 7.19286 13.3594 6.94281 13.6095C6.69276 13.8595 6.35362 14 6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10C2 9.64638 2.14048 9.30725 2.39052 9.0572C2.64057 8.80715 2.97971 8.66667 3.33333 8.66667H6ZM12.6667 8.66667C13.0031 8.66657 13.327 8.79361 13.5737 9.02234C13.8204 9.25106 13.9714 9.56457 13.9967 9.90001L14 10V12.6667C14.0001 13.0031 13.8731 13.3271 13.6443 13.5737C13.4156 13.8204 13.1021 13.9714 12.7667 13.9967L12.6667 14H10C9.66362 14.0001 9.33962 13.8731 9.09297 13.6443C8.84631 13.4156 8.69523 13.1021 8.67 12.7667L8.66667 12.6667V10C8.66656 9.66362 8.7936 9.33963 9.02233 9.09297C9.25106 8.84632 9.56456 8.69523 9.9 8.67001L10 8.66667H12.6667ZM6 10H3.33333V12.6667H6V10ZM12.6667 10H10V12.6667H12.6667V10ZM12.6667 2.00001C13.0031 1.9999 13.327 2.12694 13.5737 2.35567C13.8204 2.5844 13.9714 2.8979 13.9967 3.23334L14 3.33334V6.00001C14.0001 6.33639 13.8731 6.66038 13.6443 6.90704C13.4156 7.15369 13.1021 7.30478 12.7667 7.33001L12.6667 7.33334H10C9.66362 7.33345 9.33962 7.2064 9.09297 6.97767C8.84631 6.74895 8.69523 6.43544 8.67 6.10001L8.66667 6.00001V3.33334C8.66656 2.99695 8.7936 2.67296 9.02233 2.42631C9.25106 2.17965 9.56456 2.02857 9.9 2.00334L10 2.00001H12.6667ZM6 2.00001C6.33638 1.9999 6.66038 2.12694 6.90703 2.35567C7.15369 2.5844 7.30477 2.8979 7.33 3.23334L7.33333 3.33334V6.00001C7.33344 6.33639 7.2064 6.66038 6.97767 6.90704C6.74894 7.15369 6.43544 7.30478 6.1 7.33001L6 7.33334H3.33333C2.99695 7.33345 2.67296 7.2064 2.4263 6.97767C2.17965 6.74895 2.02856 6.43544 2.00333 6.10001L2 6.00001V3.33334C1.99989 2.99695 2.12694 2.67296 2.35566 2.42631C2.58439 2.17965 2.8979 2.02857 3.23333 2.00334L3.33333 2.00001H6ZM12.6667 3.33334H10V6.00001H12.6667V3.33334ZM6 3.33334H3.33333V6.00001H6V3.33334Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default GridIcon;
