import { type StateCreator } from "zustand";

import { type AppSlice, type AppStateSlice } from "@/types/app.ts";

const createAppStateSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], AppStateSlice> = (set) => ({
  user: null,
  setUser: (user) => set({ user }, false, "setUser"),
  hideAllMaintenance: false,
  isParagraphSelectOpen: false,
  setHideAllMaintenance: (hideAllMaintenance: boolean) => set({ hideAllMaintenance }, false, "setParagraphSelectOpen"),
  setParagraphSelectOpen: (isParagraphSelectOpen: boolean) =>
    set({ isParagraphSelectOpen }, false, "setParagraphSelectOpen"),
  isMonthly: false,
  setIsMonthly: (isMonthly: boolean) => set({ isMonthly }, false, "setIsMonthly"),
  loadingState: { loading: false },
  setLoadingState: (loadingState) => set({ loadingState }, false, "setLoadingState"),
  sideBarOpen: false,
  setSideBarOpen: (value) => set({ sideBarOpen: value }, false, "setSideBarOpen"),
  isFirebaseActionChecked: false,
  setIsFirebaseActionChecked: (value) => set({ isFirebaseActionChecked: value }, false, "setIsFirebaseActionChecked"),
});

export default createAppStateSlice;
