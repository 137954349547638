import { useCallback, useMemo, useState } from "react";

import { useStripePlanFeatures } from "@/api/subscription.ts";
import { FormPageLayout } from "@/components/layout";
import { PlanCard } from "@/components/subscription";
import { Typography } from "@/components/ui/typography.tsx";
import { useAppAuthState } from "@/firebase/hooks.ts";
import { handlePaymentRedirect, portalCreateHandler } from "@/service/subscription";
import type { PlanDescription, StripePlanPeriod } from "@/types/schemas";
import { cn } from "@/utils";

const CountryDisabled = () => {
  const [period, setPeriod] = useState<StripePlanPeriod>("yearly");
  const { data: stripePlans } = useStripePlanFeatures();
  const [user] = useAppAuthState();
  const [loading, setLoading] = useState(false);

  const planClickHandler = useCallback(
    (plan: PlanDescription): void => {
      setLoading(true);
      if (plan.priceId === "free" || !user) return;
      void handlePaymentRedirect({ priceId: plan.priceId, user });
    },
    [user],
  );

  const activePlans = useMemo(() => stripePlans?.filter((item) => item.type === period), [period, stripePlans]);

  return (
    <FormPageLayout wide className="w-[40rem] px-5 py-8">
      <section className="relative flex flex-col items-center justify-center gap-6">
        <Typography className="!font-bold" size="h3">
          Subscribe to continue
        </Typography>
        <div className={cn("right-0 top-0 flex items-center gap-4")}>
          <div className="flex gap-1">
            <button
              className="rounded-[100px] border border-stroke-secondary-onBg bg-transparent px-5 py-4 text-label-l3-regular font-bold text-tertiary-onBg data-[state=monthly]:border-stroke-primary-onBg data-[state=monthly]:text-primary-onBg"
              data-state={period}
              onClick={() => setPeriod("monthly")}
            >
              Monthly
            </button>
            <button
              className="rounded-[100px] border border-stroke-secondary-onBg bg-transparent px-5 py-4 text-label-l3-regular font-bold text-tertiary-onBg data-[state=yearly]:border-stroke-primary-onBg data-[state=yearly]:text-primary-onBg"
              data-state={period}
              onClick={() => setPeriod("yearly")}
            >
              Yearly{" "}
              <span className="text-decorative-red/50 data-[state=yearly]:text-decorative-red" data-state={period}>
                -20%
              </span>
            </button>
          </div>
        </div>
        {activePlans?.map((plan) => {
          const mappedPlan = {
            firebaseRole: plan.firebaseRole,
            name: plan.name,
            priceId: plan.priceId,
            title: "",
            description: plan.type === "yearly" ? "Per month, billed yearly" : "Per month",
            subTitle: "Boost your study sessions",
            monthlyPrice: plan.monthlyPrice,
            price: plan.monthlyPrice,
          };
          return (
            <PlanCard
              buttonPosition="bottom"
              className="w-full p-0 pb-6"
              isLoading={loading}
              key={plan.priceId}
              period={period}
              plan={mappedPlan}
              portalCreateHandler={portalCreateHandler}
              onPlanClick={(plan) => planClickHandler(plan)}
            />
          );
        })}
      </section>
      <Typography className="text-center text-default">
        Are you representing an organization?{" "}
        <a className="font-bold" href="mailto:hello@quino.ai">
          Contact us
        </a>
      </Typography>
    </FormPageLayout>
  );
};

export default CountryDisabled;
