import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const ChevronDown = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-default", props.className)}
    >
      <path
        d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414l-5.657 5.657Z"
        fill="inherit"
      />
    </svg>
  );
};
export default ChevronDown;
