import { useMemo } from "react";

import { useCurrentWorkspace } from "@/api/workspace.ts";
import { ProjectTile } from "@/components/v3";
import { EmptyWorkspace } from "@/components/v3/workspaces";
import { LoadingSpinner } from "@/pages/loading";
import { useLibrarySorting, useLibraryView } from "@/service/library.ts";

const WorkspaceDetails = () => {
  const { data: workspace, isLoading, isError } = useCurrentWorkspace();
  const [librarySorting] = useLibrarySorting();
  const [view] = useLibraryView();

  const content = useMemo(() => {
    if (isLoading) return null;
    if (isError) return null;
    if (!workspace) return null;
    if (workspace.projects.length === 0) return <EmptyWorkspace />;

    return (
      <section
        className="grid grid-cols-[repeat(auto-fill,_minmax(min(180px,_100%),_1fr))] flex-col gap-x-3 gap-y-6 data-[view=row]:flex data-[view=row]:gap-y-2"
        data-view={view}
      >
        {workspace.projects
          .sort((a, b) => {
            if (librarySorting === "ASC") return a.name.localeCompare(b.name);
            return b.name.localeCompare(a.name);
          })
          .map((project) => (
            <ProjectTile key={project.id} project={project} />
          ))}
      </section>
    );
  }, [isError, isLoading, workspace, librarySorting, view]);

  if (isLoading)
    return (
      <section className="flex h-full flex-1 flex-col justify-center pb-32">
        <LoadingSpinner />
      </section>
    );

  if (isError) return <section>Error...</section>;

  return content;
};

export default WorkspaceDetails;
