import { z } from "zod";

export const resourceElementSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    projectId: z.string(),
    dateLastOpen: z.string().optional(),
    dateCreated: z.string(),
    favourite: z.boolean(),
    userId: z.string(),
  })
  .strict();

export type ResourceElement = z.infer<typeof resourceElementSchema>;

export const updateResourceElementSchema = z
  .object({ name: z.string().optional(), favourite: z.boolean().optional() })
  .strict();

export type UpdateResourceElement = z.infer<typeof updateResourceElementSchema>;
