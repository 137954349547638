import ReactGA from "react-ga4";

import { isCookieConsentAccepted } from "@/service/cookies.ts";

export const customGAEventSender = (category: string, action: string, label?: string, value?: number): void => {
  if (process.env.NODE_ENV === "production" && isCookieConsentAccepted())
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value,
    });
};

export const sendGAConversionEvent = () => {
  if (process.env.NODE_ENV === "production" && isCookieConsentAccepted())
    ReactGA.gtag("event", "conversion", {
      send_to: "AW-16465116531/POHdCL-N9KQZEPP6lqs9",
    });
};
