import { useEffect, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useDocumentSummaryPaginated } from "@/api/document-summary.ts";
import LetterSizePopover from "@/components/document/common/letter-size-popover.tsx";
import ViewerPanelFooter from "@/components/document/common/viewer-panel-footer.tsx";
import ExportSummaryToPdfLink from "@/components/document/summary/export-summary-to-pdf-link.tsx";
import { QuinoTooltip } from "@/components/generic";
import {
  CheckMarkIcon,
  MagnifierIcon,
  PlayerArrowIcon,
  PlayerPlayIcon,
  PlayerStopIcon,
  PlayerVoiceChangeIcon,
  ReadAloudIcon,
} from "@/components/icons/summary";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { PlayerProgress } from "@/components/ui/progress";
import { Select, SelectContent, SelectItem, ChapterSelectorTrigger } from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast.ts";
import { CommandPopoverIcon } from "@/components/v3/icons";
import { useCurrentDocumentTabEntity } from "@/service/hooks/react-router.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { type SummaryResponse } from "@/types/schemas";
import { cn } from "@/utils";

const chapterType = z.object({
  chapter: z.string(),
});

type ChapterForm = z.infer<typeof chapterType>;

const ChaptersForm = ({ summary }: { summary: SummaryResponse }) => {
  const form = useForm<ChapterForm>({
    resolver: zodResolver(chapterType),
    defaultValues: {
      chapter: summary.chapterIdOrderedList[0],
    },
  });

  const chapter = form.watch("chapter");

  useEffect(() => {
    if (chapter) {
      const target = document.getElementById(`target-${chapter}`);
      if (!target) return;
      const parent = target.parentNode as HTMLElement;
      parent.scrollTop = target.offsetTop - 40;
      target?.scrollIntoView({ behavior: "smooth", inline: "start", block: "start" });
    }
  }, [chapter]);

  console.log(summary);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredChapters = summary?.chapterIdOrderedList?.filter((chapter) =>
    summary.chapterIdNamePairs[chapter].chapterName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const chapterNamesList = useMemo(() => {
    return (
      <div className="mt-14 overflow-y-auto">
        {filteredChapters?.map((chapter) => (
          <SelectItem className="font-jakarta text-text-active" key={chapter} value={chapter}>
            {summary.chapterIdNamePairs[chapter].chapterName}
          </SelectItem>
        ))}
      </div>
    );
  }, [filteredChapters, summary.chapterIdNamePairs]);

  return (
    <Form {...form}>
      <form className="flex flex-1 items-center justify-center" onSubmit={() => {}}>
        <FormField
          control={form.control}
          name="chapter"
          render={({ field }) => (
            <FormItem className="w-full">
              <Select
                disabled={summary?.chapterIdOrderedList.length <= 1}
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                  mixpanelTrack("fds_section_selection_button_clicked");
                }}
              >
                <FormControl className="overflow-hidden bg-bg-layer1">
                  <ChapterSelectorTrigger className="flex h-full w-full items-center justify-center gap-2 rounded-none border-none px-6 py-4 font-jakarta text-xs font-bold text-text-link-active-onBg hover:bg-container-tertiary">
                    <CommandPopoverIcon />
                    Jump to chapter
                  </ChapterSelectorTrigger>
                </FormControl>
                <SelectContent className="ml-10 h-[230px] w-[350px] rounded-xl p-2">
                  <div className="fixed top-0 z-10 w-full bg-white px-2 pb-4 pt-2">
                    <MagnifierIcon className="absolute left-4 top-5" />
                    <Input
                      className="rounded-lg border-stroke-tertiary-on-bg p-2 pl-7 font-jakarta text-secondary-onBg"
                      placeholder="Search"
                      type="search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  {chapterNamesList}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};

const speakers = ["Joseph, US", "Linda, UK", "Kathy, Australia"];
const speeds = ["Slow 0.75x", "Normal 1.0x", "Fast 1.5x"];

const CustomSelectItem = ({ title, items }: { title: string; items: string[] }) => {
  const [currentItem, setcurrentItem] = useState(items[0]);
  return (
    <div className="my-4 flex flex-col items-start justify-center gap-1">
      <h4 className="p-2 pb-4 font-jakarta text-xs font-bold text-secondary-onBg">{title}</h4>
      {items.map((item) => (
        <div
          className="flex cursor-pointer items-center justify-start gap-3.5 p-2"
          key={item}
          onClick={() => setcurrentItem(item)}
        >
          <CheckMarkIcon className={cn(currentItem !== item && "hidden")} />
          <div className={cn("h-4 w-4", currentItem === item && "hidden")} />
          <p className={cn("font-jakarta text-label-l3-regular text-text-active", currentItem === item && "font-bold")}>
            {item}
          </p>
        </div>
      ))}
    </div>
  );
};

const ReadAloudPopover = () => {
  const [progress, setProgress] = useState(0);
  const [play, setPlay] = useState(false);
  const [toggleSpeaker, setToggleSpeaker] = useState(false);
  const [toggleSpeed, setToggleSpeed] = useState(false);

  const handleSpeakerClick = () => {
    setToggleSpeed(false);
    setToggleSpeaker(!toggleSpeaker);
  };
  const handleSpeedClick = () => {
    setToggleSpeaker(false);
    setToggleSpeed(!toggleSpeed);
  };

  useEffect(() => {
    const timer = setTimeout(() => setProgress(80), 500);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Popover open={false}>
      <PopoverTrigger asChild>
        <section className="h-12 w-12">
          <Button
            className="h-12 w-12 rounded-none p-4"
            size="icon"
            variant="icon"
            onClick={() => {
              toast({
                title: "Coming soon!",
                variant: "success",
              });
            }}
          >
            <ReadAloudIcon />
          </Button>
        </section>
      </PopoverTrigger>
      <PopoverContent className="z-50 p-2">
        <div>
          <div className="justify-betweeen flex items-center gap-8">
            <Button className="h-7 w-7 rounded-lg p-1.5 hover:bg-container-tertiary" size="icon" variant="icon">
              <PlayerArrowIcon />
            </Button>
            <p className="w-[170px] overflow-x-hidden truncate whitespace-nowrap font-jakarta text-xs font-bold text-text-active">
              Chapter 1: This is the title
            </p>
            <Button className="h-7 w-7 rounded-lg p-1.5 hover:bg-container-tertiary" size="icon" variant="icon">
              <PlayerArrowIcon className="rotate-180" />
            </Button>
          </div>
          {toggleSpeaker && (
            <div>
              <CustomSelectItem items={speakers} title="Select speaker" />
            </div>
          )}
          {toggleSpeed && (
            <div>
              <CustomSelectItem items={speeds} title="Change playback speed" />
            </div>
          )}
          {!toggleSpeaker && !toggleSpeed && (
            <div className="mx-auto w-[234px] px-2 py-4">
              <PlayerProgress value={progress} />
            </div>
          )}
          <div className="flex items-center justify-between gap-6 px-[29px]">
            <Button
              className={cn(
                "h-10 w-10 rounded-lg p-2 hover:bg-container-tertiary",
                toggleSpeaker && "bg-primitive-purple-200",
              )}
              size="icon"
              variant="icon"
              onClick={handleSpeakerClick}
            >
              <PlayerVoiceChangeIcon />
            </Button>
            <button
              className="flex items-center justify-center rounded-full bg-default p-3 hover:bg-primitive-purple-800"
              onClick={() => setPlay(!play)}
            >
              {play ? <PlayerPlayIcon /> : <PlayerStopIcon />}
            </button>
            <Button
              className={cn(
                "h-10 w-10 rounded-lg p-2 font-jakarta text-label-l4 hover:bg-container-tertiary",
                toggleSpeed && "bg-primitive-purple-200",
              )}
              size="icon"
              variant="icon"
              onClick={handleSpeedClick}
            >
              1.0x
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const SummaryFooter = () => {
  const { data: currentDocument } = useCurrentDocumentTabEntity();
  const documentId = currentDocument?.id;
  const { data: summary } = useDocumentSummaryPaginated(documentId);
  return (
    <ViewerPanelFooter>
      {summary ? <ChaptersForm summary={summary} /> : <section className="flex-1" />}
      <LetterSizePopover />
      <QuinoTooltip align="center" className="z-50 font-semibold" content={"Coming soon!"} side="bottom">
        <section>
          <ReadAloudPopover />
        </section>
      </QuinoTooltip>
      {summary && summary.summarizationStatus === "SUMMARIZED" && documentId && (
        <ExportSummaryToPdfLink documentId={documentId} summary={summary} />
      )}
    </ViewerPanelFooter>
  );
};

export default SummaryFooter;
