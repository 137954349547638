import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Pencil = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-primitive-purple-400", props.className)}
    >
      <path
        clipRule="evenodd"
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM13.246 14H10.754L9.185 19.49C10.061 19.82 11.009 20 12 20C12.867 20 13.701 19.862 14.483 19.607L14.815 19.491L13.245 14H13.246ZM12 4C10.3017 3.99974 8.64734 4.53995 7.27647 5.54245C5.9056 6.54494 4.88933 7.9577 4.37478 9.57619C3.86023 11.1947 3.8741 12.9349 4.41438 14.545C4.95466 16.1551 5.99332 17.5515 7.38 18.532L8.83 13.45C8.91355 13.1585 9.0623 12.8898 9.26499 12.6642C9.46769 12.4386 9.71903 12.2621 10 12.148V11.385C10 11.131 10.049 10.879 10.143 10.642L11.536 7.161C11.5732 7.06837 11.6373 6.98899 11.72 6.93308C11.8026 6.87717 11.9002 6.84729 12 6.84729C12.0998 6.84729 12.1974 6.87717 12.28 6.93308C12.3627 6.98899 12.4268 7.06837 12.464 7.161L13.857 10.642C13.951 10.879 14 11.131 14 11.385V12.148C14.2809 12.2623 14.5321 12.4391 14.7346 12.6648C14.9372 12.8906 15.0857 13.1594 15.169 13.451L16.62 18.531C18.0062 17.5503 19.0445 16.154 19.5845 14.5441C20.1245 12.9342 20.1383 11.1943 19.6238 9.57604C19.1093 7.95781 18.0932 6.54526 16.7226 5.54282C15.352 4.54038 13.6981 4.00004 12 4Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Pencil;
