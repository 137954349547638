import * as React from "react";
import { useMemo } from "react";

import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { avatarFallbackColorPalette } from "@/service/user";
import { cn } from "@/utils";

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full", className)}
    ref={ref}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image className={cn("aspect-square h-full w-full object-cover", className)} ref={ref} {...props} />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

interface AvatarFallbackProps extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> {
  fallbackColorIndex?: number;
}

const AvatarFallback = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Fallback>, AvatarFallbackProps>(
  ({ className, fallbackColorIndex = 4, ...props }, ref) => {
    const _fallbackColorIndex = useMemo(
      () => Math.abs(fallbackColorIndex % avatarFallbackColorPalette.length),
      [fallbackColorIndex],
    );

    return (
      <AvatarPrimitive.Fallback
        className={cn(
          "flex h-full w-full items-center justify-center rounded-full bg-primitive-purple-800 text-primitive-purple-200",
          className,
          `bg-${avatarFallbackColorPalette[_fallbackColorIndex].bgColor}`,
          `text-${avatarFallbackColorPalette[_fallbackColorIndex].textColor}`,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
