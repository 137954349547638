import { type ReactNode, useEffect } from "react";

import { useMatch, useNavigate } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useAppAuthState } from "@/firebase/hooks.ts";
import { useSpecificSearchParam } from "@/service/hooks/react-router.ts";
import { useHandleFDSClaimCache, useTemporaryFDSClaimState } from "@/service/user.ts";
import { getClaimSummaryRoute } from "@/utils/route.ts";

const TOKEN_KEY = "temporary-fds-id";

const TemporaryFdsGuard = ({ children }: { children: ReactNode }) => {
  useHandleFDSClaimCache();
  const navigate = useNavigate();
  const [auth] = useAppAuthState();
  const matched = useMatch(ROUTES.CLAIM_SUMMARY);
  const temporaryDocumentId = useSpecificSearchParam(TOKEN_KEY);
  const [, setCache] = useTemporaryFDSClaimState();
  useEffect(() => {
    if (matched && temporaryDocumentId) {
      if (!auth?.uid) {
        const redirect = getClaimSummaryRoute(temporaryDocumentId);
        toast({
          title: "Register or log in to claim the summary!",
          variant: "success",
        });
        navigate(`${ROUTES.REGISTER}?redirectTo=${encodeURIComponent(redirect)}`);
        setCache(temporaryDocumentId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.uid, navigate, temporaryDocumentId]);

  return children;
};

export default TemporaryFdsGuard;
