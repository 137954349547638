export class FreeUserError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

export class GenericFetchError extends Error {
  readonly payload: Response;
  constructor(message: string, payload: Response) {
    super(message);
    this.message = message;
    this.payload = payload;
  }
}

export class GenericEventError extends Error {
  readonly event: Event;
  constructor(message: string, event: Event) {
    super(message);
    this.message = message;
    this.event = event;
  }
}
