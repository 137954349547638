import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const ImageIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg {...props} className={cn("h-4 w-4 fill-default dark:fill-white", props.className)} viewBox="0 0 16 16">
    <path
      d="M13.3333 2C13.6696 1.99989 13.9936 2.12694 14.2403 2.35566C14.4869 2.58439 14.638 2.8979 14.6633 3.23333L14.6666 3.33333V12.6667C14.6667 13.0031 14.5396 13.327 14.3109 13.5737C14.0822 13.8204 13.7687 13.9714 13.4333 13.9967L13.3333 14H2.66659C2.3302 14.0001 2.00621 13.8731 1.75955 13.6443C1.5129 13.4156 1.36181 13.1021 1.33659 12.7667L1.33325 12.6667V3.33333C1.33315 2.99695 1.46019 2.67296 1.68892 2.4263C1.91764 2.17965 2.23115 2.02856 2.56659 2.00333L2.66659 2H13.3333ZM6.58592 8.03333L2.81459 11.8047C2.7707 11.8487 2.72089 11.8864 2.66659 11.9167V12.6667H13.3333V11.9167C13.2789 11.8864 13.2291 11.8487 13.1853 11.8047L11.2999 9.92L10.8286 10.3913L10.9666 10.5293C11.0285 10.5913 11.0776 10.6648 11.1111 10.7457C11.1445 10.8266 11.1618 10.9133 11.1617 11.0009C11.1617 11.0885 11.1444 11.1752 11.1109 11.2561C11.0773 11.3369 11.0282 11.4104 10.9663 11.4723C10.9043 11.5342 10.8308 11.5833 10.7499 11.6168C10.669 11.6503 10.5822 11.6675 10.4947 11.6675C10.4071 11.6674 10.3204 11.6502 10.2395 11.6166C10.1586 11.5831 10.0851 11.5339 10.0233 11.472L6.58659 8.03333H6.58592ZM13.3333 3.33333H2.66659V10.0667L5.99658 6.73733C6.14175 6.59221 6.33536 6.50577 6.54032 6.49457C6.74527 6.48338 6.94715 6.54822 7.10725 6.67667L7.17458 6.73667L9.88525 9.448L10.7106 8.62267C10.8557 8.47754 11.0494 8.3911 11.2543 8.3799C11.4593 8.36871 11.6611 8.43355 11.8213 8.562L11.8893 8.62267L13.3333 10.0673V3.33333ZM10.3333 4.66667C10.5985 4.66667 10.8528 4.77202 11.0404 4.95956C11.2279 5.1471 11.3333 5.40145 11.3333 5.66667C11.3333 5.93188 11.2279 6.18624 11.0404 6.37377C10.8528 6.56131 10.5985 6.66667 10.3333 6.66667C10.068 6.66667 9.81368 6.56131 9.62614 6.37377C9.43861 6.18624 9.33325 5.93188 9.33325 5.66667C9.33325 5.40145 9.43861 5.1471 9.62614 4.95956C9.81368 4.77202 10.068 4.66667 10.3333 4.66667Z"
      fill="inherit"
    />
  </svg>
);
export default ImageIcon;
