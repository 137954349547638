import { AppleIcon } from "@/components/icons/common";
import { Button } from "@/components/ui/button";
import { signUpWithApple } from "@/firebase";
import { cn } from "@/utils";

const AppleLogin = ({ className }: { className?: string }) => (
  <Button
    className={cn(
      "h-12 w-full bg-secondary-container-default px-5 py-4 text-label-l3 text-secondary-text-default",
      className,
    )}
    id="log-in-with-apple-icon"
    variant="secondary"
    onClick={() => void signUpWithApple()}
  >
    <AppleIcon className="h-6 w-6" />
    Continue with Apple
  </Button>
);

export default AppleLogin;
