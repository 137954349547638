import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { z } from "zod";

import { fetchAuthProviderData } from "@/api/login";
import { ROUTES } from "@/assets/constants/constants";
import { Button } from "@/components/ui/button.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useToast } from "@/components/ui/use-toast.ts";
import { logInWithEmailAndPassword } from "@/firebase";
import { useMixpanelTrack } from "@/service/mixpanel";
import { cn } from "@/utils";

import { SignUpLink } from "../button/navigation";

const loginFormSchema = z.object({
  email: z.string().email({ message: "Valid email is required!" }),
  password: z.string().min(1, { message: "Invalid password!" }),
});

export type LoginFormSchema = z.infer<typeof loginFormSchema>;

const LoginForm = () => {
  const { toast } = useToast();
  const mixpanelTrack = useMixpanelTrack();
  const form = useForm<LoginFormSchema>({
    mode: "onBlur",
    resolver: zodResolver(loginFormSchema),
  });

  const [emailAuth, setEmailAuth] = useState(false);
  const [proceedBtnDisabled, setProceedBtnDisabled] = useState(true);
  const navigate = useNavigate();

  const handleLoginSubmit = async ({ password, email }: LoginFormSchema) => {
    try {
      await logInWithEmailAndPassword(email, password);
    } catch {
      toast({
        title:
          "Your password is incorrect or this account doesn't exist. Please check your credentials and try again. Contact support if the issue persists.",
        variant: "destructive",
      });
    }
  };

  const proceedWithEmail = async (email: string) => {
    const isMailInUse = await fetchAuthProviderData(email);
    if (isMailInUse.provider === "password") {
      setEmailAuth(true);
      form.reset(undefined, { keepDirtyValues: true });
    } else if (isMailInUse.provider === "google.com" || isMailInUse.provider === "apple.com") {
      toast({ title: "This email is already registered with Google or Apple authentication!", variant: "success" });
    } else {
      navigate(`/register?email=${email}`);
    }
  };

  return (
    <Form {...form}>
      <form className="flex w-full flex-col items-center gap-6" onSubmit={form.handleSubmit(handleLoginSubmit)}>
        <FormField
          control={form.control}
          name="email"
          render={({ field, fieldState }) => (
            <FormItem className="w-full">
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormControl>
                <Input
                  className={cn(
                    "rounded-lg border-stroke-tertiary-on-bg p-2 text-sm font-normal text-primitive-grey-600",
                    fieldState.error && "!border-red-500",
                  )}
                  disabled={emailAuth}
                  id="email"
                  placeholder="Email address"
                  type="email"
                  onInputCapture={() => setProceedBtnDisabled(false)}
                  {...field}
                />
              </FormControl>
              <FormMessage className="mt-0 pt-0" />
            </FormItem>
          )}
        />
        {emailAuth && (
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem className="w-full">
                <FormLabel htmlFor="password">Password</FormLabel>
                <FormControl>
                  <Input
                    className={cn(
                      "rounded-lg border-stroke-tertiary-on-bg p-2 text-sm font-normal text-primitive-grey-600",
                      fieldState.error && "!border-red-500",
                    )}
                    id="password"
                    placeholder="Password"
                    type="password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {emailAuth && (
          <NavLink
            className="self-end text-sm font-normal text-text-link-active-onBg"
            id="forgot-password-button"
            to={ROUTES.RESET}
          >
            Forgot Password?
          </NavLink>
        )}
        {emailAuth ? (
          <Button
            className="w-full"
            disabled={!form.formState.isValid}
            type="submit"
            onClick={() => mixpanelTrack("login_button_clicked")}
          >
            Log in
          </Button>
        ) : (
          <Button
            className="w-full"
            disabled={proceedBtnDisabled}
            onClick={() => proceedWithEmail(form.getValues("email"))}
          >
            Continue with email
          </Button>
        )}
        {emailAuth && (
          <p className="mt-1 self-center text-sm text-secondary-onBg">
            Don&apos; have an account? <SignUpLink />
          </p>
        )}
      </form>
    </Form>
  );
};

export default LoginForm;
