import AboutQuino from "@/components/mobile-disabled/AboutQuino";
import QuestionMark from "@/components/mobile-disabled/QuestionMark";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { useFirebaseUserId } from "@/firebase/hooks";
import { mixpanelTrack } from "@/service/mixpanel";

const MobileDisabled = () => {
  const currentUserId = useFirebaseUserId();

  const handleBtnClick = () => {
    mixpanelTrack("mobile_disabled_notification", { firebaseUserId: currentUserId });
    toast({ title: "Inquiry sent!", variant: "success" });
  };

  return (
    <main className="h-auto w-screen">
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <section className="mx-auto flex h-auto w-screen flex-col items-start">
        <div className="flex flex-col items-start gap-4 p-5">
          <h1 className="text-h4 font-bold text-primary">Mobile version is coming soon!</h1>
          <p className="text-base font-normal text-primary">
            Stay tuned! The mobile version is on the way, until then you can try out our web application by accessing
            from a computer.
          </p>
          <QuestionMark className="h-[17.125rem] w-[19.375rem] self-center" />
          <Button className="mb-[3.625rem] h-[3rem] w-[20.563rem] self-center" onClick={handleBtnClick}>
            Notify Me
          </Button>
        </div>
        <AboutQuino />
      </section>
    </main>
  );
};

export default MobileDisabled;
