import { type ReactNode, useState } from "react";

import { useGenerateShareLink, useWorkspace } from "@/api/workspace.ts";
import { CopyLink } from "@/components/icons";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { InfoIcon } from "@/components/v3";
import { InfiniteLoading } from "@/pages/loading";
import { copyTextToClipboard } from "@/utils";

const BaseShareLinkDescription = ({ workspaceId }: { workspaceId: string }) => {
  const { data: workspace } = useWorkspace(workspaceId);
  return (
    <div className="flex items-center gap-1 pb-2 pt-2 text-caption-2 text-tertiary-onBg">
      <InfoIcon />
      <p>
        With this link, anyone can access <b className="mx-px text-primary-onBg">{workspace?.name}</b> in the next 12
        hours.
      </p>
    </div>
  );
};

const QuinoShareLink = ({
  workspaceId,
  description = <BaseShareLinkDescription workspaceId={workspaceId} />,
}: {
  workspaceId: string;
  description?: ReactNode;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const { data: generatedLink, isLoading } = useGenerateShareLink(workspaceId);

  const onCopyClick = () => {
    void copyTextToClipboard(generatedLink?.shareLink ?? "").then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };
  if (isLoading)
    return (
      <section className="flex w-full items-center justify-center">
        <InfiniteLoading className="h-10 w-10" />
      </section>
    );
  return (
    <section>
      <section className="flex flex-row items-center justify-between gap-2">
        <Input
          readOnly
          className="h-auto rounded-none !border-0 !p-0 text-sm"
          classNameRoot="flex-1 rounded-lg border p-2"
          value={generatedLink?.shareLink ?? ""}
        />
        <section className="self-stretch">
          <Button className="h-full w-full" size="sm" variant="secondary" onClick={onCopyClick}>
            <CopyLink />
            {isCopied ? "Copied" : "Copy"}
          </Button>
        </section>
      </section>
      {description && <p className="flex flex-row items-center pt-1 text-xs text-primary">{description}</p>}
    </section>
  );
};
export default QuinoShareLink;
