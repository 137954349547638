import { useCallback, useMemo } from "react";

import { useLastDocumentElement, useQuestionSuggestion, useStreamingMessage } from "@/api/document-chat";
import SuggestionIllustration from "@/assets/svgs/suggestion-illustration.svg";
import { LightIcon } from "@/components/icons";
import { useIsChatLoading } from "@/service/chat.ts";
import { useChatDocuConfig } from "@/service/hooks/documents.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { type DocumentChatItem } from "@/types/schemas";

const QuestionSuggestionInternal = ({ lastElement }: { lastElement: DocumentChatItem }) => {
  const suggestion = useQuestionSuggestion(lastElement);
  const { mutate: sendStreamingMessage, isPending } = useStreamingMessage();
  const isChatLoading = useIsChatLoading();
  const [storageState] = useChatDocuConfig();

  const selectedSuggestion = useMemo(() => {
    if (suggestion?.data?.questionsList && suggestion.data.questionsList.length > 0) {
      try {
        return suggestion.data.questionsList[Math.floor(Math.random() * suggestion.data.questionsList.length)];
      } catch {
        return suggestion.data.questionsList[0];
      }
    }
    return undefined;
  }, [suggestion?.data?.questionsList]);

  const click = useCallback(() => {
    if (!selectedSuggestion) return;
    if (isPending) return;
    if (isChatLoading) return;
    mixpanelTrack("qa_recommendation_clicked");
    sendStreamingMessage({ text: selectedSuggestion.task, projectWide: !storageState.documentOnly });
  }, [selectedSuggestion, isPending, isChatLoading, sendStreamingMessage, storageState.documentOnly]);

  if (!suggestion.data) return null;
  return (
    <section
      className="relative z-50 flex w-full cursor-pointer flex-col gap-1.5 bg-container-tertiary p-4 text-default duration-300 animate-in fade-in-40 slide-in-from-bottom"
      onClick={click}
    >
      <p className="flex flex-row items-center gap-1 text-label-l4-regular font-medium">
        Suggested question
        <span className="h-3 w-3">
          <LightIcon />
        </span>
      </p>
      {selectedSuggestion && (
        <p className="max-w-[calc(100%-45px)] cursor-pointer text-sm font-bold">{selectedSuggestion?.task}</p>
      )}
      <img alt="suggestion illustration" className="absolute bottom-0 right-0 w-16" src={SuggestionIllustration} />
    </section>
  );
};

const QuinoQuestionSuggestion = () => {
  const { data: lastElement } = useLastDocumentElement();

  return <>{lastElement && <QuestionSuggestionInternal lastElement={lastElement} />}</>;
};

export default QuinoQuestionSuggestion;
