import { type MouseEventHandler, type ReactNode } from "react";

import { TabsTrigger } from "@/components/ui/tabs";
import { cn } from "@/utils";

const QuinoTabTrigger = ({
  onClick,
  children,
  className,
  value,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  className?: string;
  value: string;
}) => {
  return (
    <TabsTrigger
      className={cn(
        "group relative h-12 flex-1 border-transparent p-4 font-jakarta text-label-l3 text-text-default hover:bg-white/50   data-[state=active]:text-text-active data-[state=active]:no-underline data-[state=active]:hover:bg-white",
        className,
      )}
      value={value}
      onClick={onClick}
    >
      {children}
      <span className="absolute bottom-0 h-px w-full bg-stroke-default transition-all group-data-[state=active]:h-[3px] group-data-[state=active]:bg-stroke-active" />
    </TabsTrigger>
  );
};
export default QuinoTabTrigger;
