import * as React from "react";

import { Slot } from "@radix-ui/react-slot";

import { cn } from "@/utils";

export interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

const Stack = React.forwardRef<HTMLDivElement, StackProps>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : "section";

  return <Comp ref={ref} {...props} className={cn("flex flex-col", className)} />;
});

Stack.displayName = "Stack";

export { Stack };
