import { ModalKeys } from "@/assets/constants/modal.ts";
import { DocumentIcon, HomeHeaderCard } from "@/components/v3";
import { ChatPlain } from "@/components/v3/icons/chat";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const HomeHeader = () => {
  const [, setDocumentUploadOpen] = useModalState(ModalKeys.DOCUMENT_UPLOAD_OPEN);

  return (
    <div className="grid w-full grid-cols-3 gap-4">
      <HomeHeaderCard
        icon={<DocumentIcon />}
        subTitle="Save time by shortening your docs"
        title="Summarize document"
        onClick={() => setDocumentUploadOpen({ isOpen: true, toOpen: "summary" })}
      />
      <HomeHeaderCard
        icon={<ChatPlain />}
        subTitle="Ask questions about your files’ content"
        title="Chat with your files"
        onClick={() => setDocumentUploadOpen({ isOpen: true, toOpen: "chat" })}
      />
    </div>
  );
};

export default HomeHeader;
