import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import {
  activePlugins$,
  allowedHeadingLevels$,
  type BlockType,
  convertSelectionToNode$,
  currentBlockType$,
  useCellValue,
  usePublisher,
} from "@mdxeditor/editor";
import { $createParagraphNode } from "lexical";

import useAppStateStore from "@/zustand/store.ts";

import { MySelect } from "./primitives/my-select";

/**
 * A toolbar component that allows the user to change the block type of the current selection.
 * Supports paragraphs, headings and block quotes.
 */
export const MyBlockTypeSelect = () => {
  const convertSelectionToNode = usePublisher(convertSelectionToNode$);
  const currentBlockType = useCellValue(currentBlockType$);
  const activePlugins = useCellValue(activePlugins$);
  const hasQuote = activePlugins.includes("quote");
  const hasHeadings = activePlugins.includes("headings");
  const setParagraphSelectOpen = useAppStateStore((state) => state.setParagraphSelectOpen);
  if (!hasQuote && !hasHeadings) {
    return null;
  }
  const items: { label: string | JSX.Element; value: BlockType }[] = [{ label: "Paragraph", value: "paragraph" }];

  if (hasQuote) {
    items.push({ label: "Quote", value: "quote" });
  }

  if (hasHeadings) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const allowedHeadingLevels = useCellValue(allowedHeadingLevels$);
    items.push(...allowedHeadingLevels.map((n) => ({ label: `Heading ${n}`, value: `h${n}` }) as const));
  }

  return (
    <MySelect<BlockType>
      items={items}
      placeholder="Block type"
      triggerTitle="Select block type"
      value={currentBlockType}
      onChange={(blockType) => {
        switch (blockType) {
          case "quote":
            convertSelectionToNode(() => $createQuoteNode());
            break;
          case "paragraph":
            convertSelectionToNode(() => $createParagraphNode());
            break;
          default:
            if (blockType == "") {
            } else if (blockType.startsWith("h")) {
              convertSelectionToNode(() => $createHeadingNode(blockType));
            } else {
              throw new Error(`Unknown block type: ${blockType}`);
            }
        }
      }}
      onOpenChange={(value) => setParagraphSelectOpen(value)}
    />
  );
};
