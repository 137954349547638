import { useMemo } from "react";

import { Link } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants";
import { SummaryCouldNotProcessIcon } from "@/components/icons/summary";
import { Button } from "@/components/ui/button";
import { Typography } from "@/components/ui/typography.tsx";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { type DocumentSummaryRedisTicker } from "@/types/schemas";
import { useCurrentProjectId } from "@/utils";

export const getSummaryPercentage = (progress?: DocumentSummaryRedisTicker) => {
  if (!progress) return 0;
  return (progress.paragraphNumSummarization / progress.maxParagraphNumSummarization) * 100;
};

export const ErrorScreen = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const currentProjectId = useCurrentProjectId();
  const link = useMemo(() => {
    return `${ROUTES.WORKSPACES}${currentWorkspaceId ? `/${currentWorkspaceId}` : ""}${
      currentProjectId ? `/${currentProjectId}` : ""
    }`;
  }, [currentProjectId, currentWorkspaceId]);
  return (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <div className="mb-6 py-5">
        <SummaryCouldNotProcessIcon className="mx-auto" />
      </div>
      <h4 className="text-h5 font-bold text-primary-onBg">Couldn&apos;t process this document</h4>
      <p className="mb-4 w-2/3 text-center text-base font-normal text-secondary-onBg">
        This document has failed to process. Try reuploading this or a different document.
      </p>
      <Link to={link}>
        <Button className="rounded-lg bg-default px-5 py-4 text-label-l3 text-white">Go back to Workspaces</Button>
      </Link>
    </div>
  );
};

export const NoDocumentView = () => {
  return (
    <article className="mx-auto w-[42rem] max-w-2xl px-6 pt-6">
      <Typography asChild>
        <h1 className="text-xl font-bold text-primary/75">There is no document opened</h1>
      </Typography>
      <Typography asChild size="small">
        <p className="text-primary">Please select a document to be viewed!</p>
      </Typography>
    </article>
  );
};

export const EstimationLoading = () => {
  return (
    <Typography asChild>
      <div>Loading...</div>
    </Typography>
  );
};
