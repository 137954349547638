import { useCallback } from "react";

import { useDocumentChatSessionId } from "@/service/hooks/react-router.ts";

const useNewChat = () => {
  const [, setSessionId] = useDocumentChatSessionId();

  return useCallback(() => {
    setSessionId("");
  }, [setSessionId]);
};

export { useNewChat };
