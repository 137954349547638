import { useCallback, useMemo } from "react";

import { useWorkspace } from "@/api/workspace.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Tabs, TabsContent, TabsList } from "@/components/ui/tabs.tsx";
import { QuinoTabTrigger, WorkspaceEditPanel } from "@/components/v3";
import WorkspaceCollaboratorsPanel from "@/components/v3/modals/workspace/workspace-settings/workspace-collaborators-panel.tsx";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const WorkspaceSettingsModal = () => {
  const [{ isOpen, workspaceId }, setModalState] = useModalState(ModalKeys.WORKSPACE_SETTINGS);
  const setOpenState = useCallback(
    (value: boolean) => setModalState({ isOpen: value, workspaceId: "" }),
    [setModalState],
  );
  const { data: workspace, isLoading } = useWorkspace(workspaceId);
  const currentUserId = useFirebaseUserId();
  const isAdmin = useMemo(() => workspace?.adminId === currentUserId, [workspace?.adminId, currentUserId]);

  if (isLoading) return null;
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setOpenState(value)}>
      <DialogContent className="h-auto overflow-hidden">
        <section className="flex flex-1 flex-col overflow-hidden ">
          <DialogHeader className="w-full">
            <DialogTitle className="mb-6">Workspace settings</DialogTitle>
          </DialogHeader>
          {isAdmin ? (
            <Tabs className="flex w-full max-w-full flex-1 flex-col gap-12" defaultValue="colab">
              <TabsList className="h-fit p-0">
                <QuinoTabTrigger value="edit">Details</QuinoTabTrigger>
                <QuinoTabTrigger value="colab">Collaborators</QuinoTabTrigger>
              </TabsList>
              <TabsContent className="mt-0 max-w-full flex-1 overflow-hidden" value="edit">
                <WorkspaceEditPanel />
              </TabsContent>
              <TabsContent className="flex-1" value="colab">
                <WorkspaceCollaboratorsPanel />
              </TabsContent>
            </Tabs>
          ) : (
            <section className="flex-1">
              <WorkspaceCollaboratorsPanel />
            </section>
          )}
        </section>
      </DialogContent>
    </Dialog>
  );
};
export default WorkspaceSettingsModal;
