import { useCallback } from "react";

import { type StateCreator } from "zustand";

import { ModalKeys, type ModalSlice, type ModalStateType } from "@/assets/constants/modal";
import { type AppSlice } from "@/types/app.ts";
import useAppStateStore from "@/zustand/store";

export const getModalValue = <T extends keyof ModalStateType>(state: ModalSlice, key: T): ModalStateType[T] =>
  state.modals[key];

export const useModalState = <T extends keyof ModalStateType>(
  modalKey: T,
): [ModalStateType[T], (params: ModalStateType[T]) => void] => {
  const { value, setKey } = useAppStateStore((state) => ({
    value: getModalValue(state, modalKey),
    setKey: state.setModalKey,
  }));

  const setState = useCallback((params: ModalStateType[T]) => setKey(modalKey, params), [setKey, modalKey]);

  return [value, setState];
};

const createModalSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], ModalSlice> = (set) => ({
  modals: {
    [ModalKeys.RENEW_SUBSCRIPTION]: false,
    [ModalKeys.ONBOARDING]: false,
    [ModalKeys.SEARCH_OPEN]: {
      open: false,
      text: "",
      searchType: "simple",
      result: null,
      loading: false,
    },
    [ModalKeys.SHARE_MODAL]: { isOpen: false, projectId: "", workspaceId: "" },
    [ModalKeys.UN_SHARE_MODAL]: { isOpen: false, projectId: "" },
    [ModalKeys.POLL_MODAL]: false,
    [ModalKeys.QUINO_REBORN]: false,
    [ModalKeys.WORKSPACE_SETTINGS]: { isOpen: false, workspaceId: "" },
    [ModalKeys.IMAGE_NOTE_PARAGRAPH]: { isOpen: false, noteId: "", position: -1 },
    [ModalKeys.INTERVIEW_MODAL]: false,
    [ModalKeys.SIGN_UP_TO_QUINO]: false,
    [ModalKeys.STOP_ADBLOCK_MODAL]: false,
    [ModalKeys.SHARING_QUESTIONS]: false,
    [ModalKeys.TOKEN_INFO_MODAL]: false,
    [ModalKeys.CREATE_DEADLINE_MODAL]: false,
    [ModalKeys.CREATE_NEW_FLASHCARD_DIALOG]: false,
    [ModalKeys.CREATE_NOTE_OPEN]: false,
    [ModalKeys.CREATE_WORKSPACE]: false,
    [ModalKeys.EDIT_DECK_MODAL_OPEN]: { isOpen: false, deckId: "" },
    [ModalKeys.EDIT_CARD_IN_SESSION_MODAL_OPEN]: false,
    [ModalKeys.LEAVE_SESSION_MODAL_OPEN]: false,
    [ModalKeys.QUESTION_ANSWERING_HISTORY_MODAL]: false,
    [ModalKeys.NEW_KNOWLEDGE_DECK_OPEN]: false,
    [ModalKeys.DELETE_KNOWLEDGE_DECK_OPEN]: { isOpen: false, deckId: "" },
    [ModalKeys.DELETE_FLASHCARD_OPEN]: {
      isOpen: false,
      cardId: "",
      deckId: "",
    },
    [ModalKeys.DELETE_NEW_FLASHCARD_OPEN]: {
      isOpen: false,
      cardId: "",
      deckId: "",
      tempId: "",
    },
    [ModalKeys.CREATE_EVALUATION_SESSION_DIALOG]: false,
    [ModalKeys.FILL_FORM_MODAL_OPEN]: false,
    [ModalKeys.FEEDBACK]: false,
    [ModalKeys.CANCELLATION]: false,
    [ModalKeys.COOKIE_NOTICE_MODAL_OPEN]: false,
    [ModalKeys.DELETE_NOTE_MODAL]: { isOpen: false, noteId: "" },
    [ModalKeys.EDIT_PROJECT_MODAL_OPEN]: { isOpen: false, projectId: "", workspaceId: "" },
    [ModalKeys.DELETE_PROJECT_MODAL]: { isOpen: false, projectId: null, workspaceId: null },
    [ModalKeys.CREATE_PROJECT_MODAL_OPEN]: false,
    [ModalKeys.GOOGLE_DRIVE_PICKER_OPEN]: false,
    [ModalKeys.DOCUMENT_UPLOAD_OPEN]: {
      isOpen: false,
      initialFilePayload: undefined,
    },
    [ModalKeys.UPDATE_DOCUMENT_MODAL]: { isOpen: false, documentId: "", workspaceId: "" },
    [ModalKeys.UPDATE_NOTE_OPEN]: { isOpen: false, noteId: null, workspaceId: null },
    [ModalKeys.DELETE_DOCUMENT_MODAL]: { isOpen: false, documentId: "", workspaceId: "" },
    [ModalKeys.UPDATE_PLAN_MODAL_OPEN]: { open: false },
    [ModalKeys.OPEN_LOGOUT_MODAL]: false,
    [ModalKeys.SIMPLE_SEARCH_POPOVER]: { isOpen: false, loading: false },
    [ModalKeys.QUESTION_ANSWERING_POPOVER]: { isOpen: false, loading: false },
    [ModalKeys.TESTER_APPLICATION_MODEL_OPEN]: false,
    [ModalKeys.DELETE_ACCOUNT_MODAL_OPEN]: false,
    [ModalKeys.EDIT_USER_INFORMATION_MODAL_OPEN]: false,
    [ModalKeys.CHANGE_PASSWORD_MODAL_OPEN]: false,
    [ModalKeys.UPLOAD_PROFILE_PICTURE_MODAL_OPEN]: false,
    [ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN]: { open: false },
    [ModalKeys.INVITATION_MODAL_OPEN]: false,
    [ModalKeys.NEWS_AND_UPDATES_MODAL_OPEN]: false,
    [ModalKeys.CHURNED_USER_MODAL]: false,
    [ModalKeys.GET_PRO_CTA_MODAL]: false,
    [ModalKeys.DELETE_MULTIPLE_MODAL]: {
      isOpen: false,
    },
    [ModalKeys.CREATE_FOLDER_MODAL]: {
      isOpen: false,
    },
    [ModalKeys.DELETE_FOLDER_MODAL]: {
      isOpen: false,
      folderId: "",
    },
    [ModalKeys.UPDATE_FOLDER_MODAL]: {
      isOpen: false,
      folderId: "",
    },
  },
  setModalKey: (key, value) => {
    set((state) => ({ modals: { ...state.modals, [key]: value } }), false, "setModalKey");
  },
});

export default createModalSlice;
