import { useEffect } from "react";

import { useDocument } from "@/api/document.ts";
import { ResourceCardType } from "@/assets/constants/constants.ts";
import { PdfViewerWrapper } from "@/components/pdf";
import { useCurrentDocumentId, useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectData } from "@/utils";

const DocumentView = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data: currentProject } = useCurrentProjectData();
  const [documentId, setDocumentId] = useCurrentDocumentId();

  const { isSuccess } = useDocument(workspaceId, documentId);

  useEffect(() => {
    if (!documentId)
      setDocumentId(
        currentProject?.directChildElements
          ?.filter((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT)
          .at(0)?.id ?? "",
      );
  }, [currentProject?.directChildElements, documentId, setDocumentId]);

  return (
    <div className="h-full">
      {documentId && isSuccess && currentProject?.workspaceId && (
        <PdfViewerWrapper documentId={documentId} workspaceId={currentProject?.workspaceId} />
      )}
    </div>
  );
};

export default DocumentView;
