import { QuinoTooltip } from "@/components/generic";
import { Button } from "@/components/ui/button.tsx";
import { GridIcon, ListIcon } from "@/components/v3/icons";
import { useLibraryView } from "@/service/library.ts";

const GridViewToggle = () => {
  const [view, setView] = useLibraryView();

  return (
    <QuinoTooltip align="center" content={view === "row" ? "Grid view" : "List view"} side="top">
      <Button
        className="fill-primary dark:fill-white"
        size="icon-large"
        variant="icon"
        onClick={() => setView(view === "row" ? "grid" : "row")}
      >
        {view === "row" ? <GridIcon /> : <ListIcon />}
      </Button>
    </QuinoTooltip>
  );
};
export default GridViewToggle;
