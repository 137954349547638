import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

export const MailSent = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-10 w-10 fill-primitive-green-600", props.className)}
  >
    <path
      d="M33.3334 6.66669C34.2175 6.66669 35.0653 7.01788 35.6905 7.643C36.3156 8.26812 36.6668 9.11597 36.6668 10V30C36.6668 30.8841 36.3156 31.7319 35.6905 32.357C35.0653 32.9822 34.2175 33.3334 33.3334 33.3334H6.66677C5.78272 33.3334 4.93487 32.9822 4.30975 32.357C3.68463 31.7319 3.33344 30.8841 3.33344 30V28.3334H6.66677V30H33.3334V12.3717L21.7751 23.93C21.3063 24.3987 20.6705 24.662 20.0076 24.662C19.3447 24.662 18.7089 24.3987 18.2401 23.93L6.66677 12.3567V13.3334H3.33344V10C3.33344 9.11597 3.68463 8.26812 4.30975 7.643C4.93487 7.01788 5.78272 6.66669 6.66677 6.66669H33.3334ZM10.0001 21.6667C10.4249 21.6672 10.8335 21.8298 11.1424 22.1214C11.4513 22.4131 11.6372 22.8116 11.6621 23.2357C11.687 23.6597 11.549 24.0773 11.2763 24.4031C11.0037 24.7288 10.6169 24.9382 10.1951 24.9884L10.0001 25H1.66677C1.24197 24.9996 0.833386 24.8369 0.524493 24.5453C0.215599 24.2537 0.0297151 23.8551 0.00481985 23.431C-0.0200753 23.007 0.117898 22.5894 0.390548 22.2636C0.663199 21.9379 1.04995 21.7285 1.47177 21.6784L1.66677 21.6667H10.0001ZM8.33344 16.6667C8.77547 16.6667 9.19939 16.8423 9.51195 17.1548C9.82451 17.4674 10.0001 17.8913 10.0001 18.3334C10.0001 18.7754 9.82451 19.1993 9.51195 19.5119C9.19939 19.8244 8.77547 20 8.33344 20H3.33344C2.89141 20 2.46749 19.8244 2.15493 19.5119C1.84237 19.1993 1.66677 18.7754 1.66677 18.3334C1.66677 17.8913 1.84237 17.4674 2.15493 17.1548C2.46749 16.8423 2.89141 16.6667 3.33344 16.6667H8.33344Z"
      fill="inherit"
    />
  </svg>
);

export default MailSent;
