import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

import DocumentProcessingProvider from "@/components/document/document-processing-provider.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { QuinoPageHeader, WorkspaceSidebar } from "@/components/v3";
import { usePageTitle } from "@/service/hooks/react-router.ts";
import { useShowRenewSubscription } from "@/service/subscription";

const MainLayout = () => {
  useShowRenewSubscription();
  const title = usePageTitle();
  return (
    <section className="flex min-h-screen w-full flex-col bg-primitive-purple-pale-50">
      <Helmet>
        <title>Quino AI | {title}</title>
      </Helmet>
      <DocumentProcessingProvider />
      <section className="flex h-full flex-1 flex-col">
        <QuinoPageHeader />
        <section className="flex h-full flex-1 flex-row pt-20">
          <section className="hidden lg:block">
            <WorkspaceSidebar />
          </section>
          <section className="mx-auto max-w-[1200px] flex-1">
            <section className="flex h-full flex-1 flex-col items-start gap-12 self-stretch bg-primitive-purple-pale-50 p-3 py-6 xl:px-12">
              <Typography className="font-semibold" size="h1">
                {title}
              </Typography>
              <main className="relative flex w-full flex-1">
                <Outlet />
              </main>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};
export default MainLayout;
