import { type ReactNode, useMemo } from "react";

import CountryDisabled from "@/pages/country-disabled";
import { useIsInFreeUserGroup } from "@/service/hooks/settings.ts";
import { useIsCountryAllowed } from "@/service/user.ts";

const CountryLock = ({ children }: { children: ReactNode }) => {
  const isCountryAllowed = useIsCountryAllowed();
  const isFreeUser = useIsInFreeUserGroup();
  return useMemo(() => {
    if (isCountryAllowed === null) return null;
    if (isCountryAllowed || !isFreeUser) return children;
    return <CountryDisabled />;
  }, [children, isCountryAllowed, isFreeUser]);
};

export default CountryLock;
