import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Highschool = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <path
      d="M5.64967 19.0699C13.414 15.1602 23.5519 13.2126 32.7509 7.2459C41.95 1.27786 56.9339 3.4984 57.8238 14.2491C58.7137 24.9997 56.2411 27.7622 50.2075 39.5981C44.174 51.434 37.597 57.0078 28.4972 52.3715C19.3973 47.7353 12.1777 39.5994 6.58848 35.1596C1.00056 30.7199 -2.11471 22.9796 5.64967 19.0699Z"
      fill="#E2D7F4"
    />
    <g clipPath="url(#clip0_2410_156)">
      <path
        d="M34.3205 13.2086C34.3205 13.2086 33.6156 12.5641 33.0709 8.93002C32.9142 7.88455 31.9815 7.13624 30.9347 7.21859C28.998 7.36539 26.0145 7.53367 24.0493 7.38329C23.0809 7.3081 22.1944 7.93109 21.9452 8.87632C21.4111 10.8885 20.4641 13.8459 19.4922 13.8746"
        fill="#ED8657"
      />
      <path
        d="M34.3205 13.2086C34.3205 13.2086 33.6156 12.5641 33.0709 8.93002C32.9142 7.88455 31.9815 7.13624 30.9347 7.21859C28.998 7.36539 26.0145 7.53367 24.0493 7.38329C23.0809 7.3081 22.1944 7.93109 21.9452 8.87632C21.4111 10.8885 20.4641 13.8459 19.4922 13.8746C25.4422 11.3811 28.3626 11.1179 34.3205 13.2086Z"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M44.0759 50.932C42.7728 51.598 41.5659 51.6517 40.9998 51.6732C38.1089 51.7699 27.6312 51.734 13.4259 51.4798C12.8741 51.3187 11.9021 50.9535 11.2115 50.0405C10.7201 49.3925 10.3499 48.4616 10.3819 47.1368C10.4745 43.3881 9.51679 38.9556 12.4255 37.0365C12.4255 37.0365 10.6062 24.6054 15.4695 17.4732C20.3327 10.3446 32.4375 10.8924 35.6203 14.1506C41.6763 18.5796 43.9798 23.3415 44.9232 25.8836C45.1617 26.5245 45.6531 29.0307 46.6286 34.0433C48.2663 42.4644 48.5796 45.3753 47.127 47.8923C46.8386 48.39 45.9165 49.9904 44.0759 50.932Z"
        fill="#ED8657"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M15.0029 35.3204C18.0029 34.4995 25.8978 35.0004 27.5029 35.0002C31.1344 34.9609 32.2008 35.1557 32.7135 35.3204C35.1771 36.1117 36.676 37.7193 36.9715 38.0415C40.254 41.6577 39.4102 46.3838 39.3497 46.6953C39.0578 48.1776 38.4739 49.3019 38.0467 49.9857"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M17.9214 15.7404C20.3708 16.2953 23.4682 17.2441 26.8077 18.952C30.3145 20.7458 38.681 25.0243 41.6467 33.5063C42.373 35.5829 42.6258 37.6739 43.1313 41.8558C43.4767 44.7165 43.5052 46.3313 42.8892 48.1322C42.3338 49.7577 41.4545 50.9393 40.7958 51.6804"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M18.2258 35.0275L17.8164 40.7418H20.7002L19.8849 34.9308L18.2258 35.0275Z"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M19.5005 16.127C19.5005 16.127 23.1177 20.3089 23.3918 24.7879C23.666 29.267 31.1175 29.847 34.9376 30.18C38.7577 30.513 41.168 31.5585 41.7341 33.7067"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M16.8175 16.0978C17.3444 16.0477 17.8677 15.994 18.3947 15.9438C18.683 17.8558 18.9714 19.7677 19.2598 21.6796H16.3262C16.4899 19.8178 16.6573 17.9596 16.821 16.0978H16.8175Z"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M33.0983 22.6389C33.8246 21.9729 34.7253 20.9919 35.5014 19.6385C36.8899 17.2146 37.132 14.8945 37.1676 13.6342L49.105 21.8655L41.8849 34.2286C41.3046 32.4706 40.1974 29.8319 38.0434 27.1609C36.306 25.0055 34.4761 23.559 33.0947 22.6353L33.0983 22.6389Z"
        fill="#F1EEF6"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M31.0383 12.1303C31.0383 12.1303 30.7001 11.8546 30.7285 9.50586H24.1243C24.1243 9.50586 23.8787 12.2341 23.2734 12.3988"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M38.3196 18.9862C38.1669 19.0053 38.2784 19.0794 38.3809 19.121C38.831 19.3039 39.6744 19.2993 39.9709 19.7307C40.175 20.0275 39.9991 20.5384 39.9587 20.8581C39.8774 21.501 40.6012 21.2729 41.0095 21.1553C41.1121 21.1257 41.6475 20.7843 41.7203 20.9653C41.9318 21.4914 41.9471 22.1028 42.5598 22.3501C43.2056 22.6108 43.8427 22.1701 44.496 22.4604"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
      <path
        d="M36.0581 21.8281C36.3553 21.8732 37.0159 21.9576 37.3298 22.0593C37.706 22.1813 37.8273 22.3855 37.9545 22.7325C38.0488 22.9899 38.2457 23.9641 38.7078 23.7767C38.9795 23.6666 39.1078 23.2925 39.4257 23.2639C39.6141 23.247 39.7701 23.4208 39.89 23.5343C40.0679 23.7027 40.2455 23.8729 40.4214 24.0434"
        stroke="#B4341F"
        strokeLinecap="round"
        strokeWidth="1.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2410_156">
        <rect fill="white" height="47.6739" transform="translate(8.25098 5.16296)" width="43" />
      </clipPath>
    </defs>
  </svg>
);
export default Highschool;
