import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const StarIconFilled = ({ className, ...props }: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-[17px] fill-secondary-onBg", className)}
    >
      <path
        d="M7.77999 1.9118C7.85335 1.78591 7.95845 1.68145 8.08478 1.60885C8.21112 1.53625 8.35428 1.49805 8.49999 1.49805C8.6457 1.49805 8.78886 1.53625 8.9152 1.60885C9.04153 1.68145 9.14663 1.78591 9.21999 1.9118L11.0833 5.11047L14.702 5.89447C14.8443 5.9254 14.9761 5.9931 15.0841 6.09083C15.1921 6.18855 15.2726 6.31288 15.3176 6.45142C15.3626 6.58996 15.3705 6.73788 15.3405 6.88042C15.3105 7.02296 15.2437 7.15515 15.1467 7.2638L12.68 10.0245L13.0533 13.7078C13.0681 13.8528 13.0445 13.9992 12.9849 14.1323C12.9254 14.2654 12.832 14.3805 12.7141 14.4662C12.5961 14.5519 12.4578 14.6051 12.3128 14.6206C12.1679 14.6361 12.0214 14.6133 11.888 14.5545L8.49999 13.0611L5.11199 14.5545C4.9786 14.6133 4.83212 14.6361 4.68716 14.6206C4.5422 14.6051 4.40385 14.5519 4.2859 14.4662C4.16796 14.3805 4.07457 14.2654 4.01504 14.1323C3.95551 13.9992 3.93193 13.8528 3.94666 13.7078L4.31999 10.0245L1.85332 7.26447C1.75614 7.15582 1.68916 7.02358 1.65907 6.88095C1.62897 6.73832 1.63682 6.59029 1.68182 6.45164C1.72682 6.31299 1.80741 6.18857 1.91553 6.0908C2.02365 5.99303 2.15553 5.92534 2.29799 5.89447L5.91666 5.11047L7.77999 1.9118Z"
        fill="inherit"
      />
    </svg>
  );
};
export default StarIconFilled;
