import * as React from "react";

import { EyeIcon, EyeOffIcon } from "@/components/icons/common";
import { cn } from "@/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  classNameRoot?: string;
  icon?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, icon, classNameRoot, type, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const inputType = type === "password" && showPassword ? "text" : type;

    return (
      <div className={cn("relative", classNameRoot)}>
        <span className="absolute left-2 top-1/2 -translate-y-1/2">{icon}</span>
        <input
          autoComplete="current-password"
          className={cn(
            "placeholder:text-muted-foreground flex h-10 w-full rounded-lg border border-stroke-tertiary-on-bg  bg-transparent p-2 text-sm font-normal text-primary-onBg ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-primitive-grey-600 focus:border focus:border-stroke-primary-onBg focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[has-icon=true]:pl-6 dark:border-white dark:text-white",
            className,
          )}
          data-has-icon={!!icon}
          ref={ref}
          type={inputType}
          {...props}
        />
        {type === "password" && (
          <button
            className="absolute right-3 top-1/2 -translate-y-1/2"
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeOffIcon className="h-4 w-4 text-gray-500" />
            ) : (
              <EyeIcon className="h-4 w-4 text-gray-500" />
            )}
          </button>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export interface InputWithIconProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: React.FC<React.SVGProps<React.ElementRef<"svg">>>;
}

const InputWithIcon = React.forwardRef<HTMLInputElement, InputWithIconProps>(
  ({ className, type, icon, ...props }, ref) => {
    const Icon = icon;
    return (
      <div className="relative w-full">
        <div aria-hidden="true" className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5">
          <Icon />
        </div>
        <input
          className={cn(
            "flex h-10 w-full  rounded-2xl border border-primary/50 bg-white py-6 pl-12 pr-5 text-sm placeholder:text-primary/50 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:border-white/50 dark:bg-primary-dark dark:placeholder:text-white/50",
            className,
          )}
          ref={ref}
          type={type}
          {...props}
        />
      </div>
    );
  },
);
InputWithIcon.displayName = "Input";

export { Input, InputWithIcon };
