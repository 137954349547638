import { z } from "zod";

import { ModelLogFeedback } from "@/assets/constants/constants.ts";

export enum TaskType {
  TABLE_COMPARISON = "TABLE_COMPARISON",
  BULLETPOINTS = "BULLETPOINTS",
  QUESTION = "QUESTION",
  EXPLANATION = "EXPLANATION",
}
export const docProcessingStatusSchema = z
  .object({
    document_id: z.string(),
    document_downloaded: z.boolean(),
    document_healthcheck_done: z.boolean(),
    textraction_done: z.boolean(),
    semantic_encoding_done: z.boolean(),
    db_writing_done: z.boolean(),
    error_message: z.string(),
  })
  .strict();
export type DocProcessingPayload = z.infer<typeof docProcessingStatusSchema>;

export const chatSource = z
  .object({
    id: z.string(),
    searchScore: z.number(),
    sourceText: z.string(),
    relationScore: z.number(),
    documentId: z.string(),
    documentPageNum: z.number(),
  })
  .strict();

export type ChatSource = z.infer<typeof chatSource>;

export const documentChatItemSchema = z
  .object({
    id: z.string(),
    docChatSessionId: z.string(),
    inputText: z.string(),
    responseText: z.string(),
    usedTokens: z.number(),
    modelError: z.boolean(),
    thumbsUp: z.nativeEnum(ModelLogFeedback),
    userAccepted: z.boolean(),
    dateCreated: z.string(),
    sources: z.record(z.string(), z.array(chatSource)),
  })
  .strict();

export type DocumentChatItem = z.infer<typeof documentChatItemSchema>;

export const documentChatSessionSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    projectId: z.string(),
    dateCreated: z.string(),
    elements: z.array(documentChatItemSchema),
  })
  .strict();
export type DocumentChatSession = z.infer<typeof documentChatSessionSchema>;

export const documentChatSessionCreateSchema = z
  .object({
    text: z.string(),
    documentId: z.string().optional(),
    projectId: z.string().optional(),
  })
  .strict();

export type DocumentChatSessionCreate = z.infer<typeof documentChatSessionCreateSchema>;

const documentChatSessionInstructionSchema = documentChatSessionCreateSchema
  .extend({
    docChatSessionId: z.string(),
  })
  .strict();

export type DocumentChatSessionInstruction = z.infer<typeof documentChatSessionInstructionSchema>;

const plainDocChatSessionSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    projectId: z.string(),
    dateCreated: z.string(),
  })
  .strict();

export type PlainDocChatSession = z.infer<typeof plainDocChatSessionSchema>;

export const questionSuggestionSchema = z
  .object({
    modelLogId: z.string(),
    modelError: z.boolean(),
    questionsList: z.array(
      z.object({
        task: z.string(),
        taskType: z.nativeEnum(TaskType),
      }),
    ),
    usedTokens: z.number(),
  })
  .strict();

export type QuestionSuggestion = z.infer<typeof questionSuggestionSchema>;

export const questionSuggestionCreateSchema = z
  .object({
    documentId: z.string(),
    expectedResponseLanguage: z.string(),
  })
  .strict();

export type QuestionSuggestionCreate = z.infer<typeof questionSuggestionCreateSchema>;

const docChatSessionHistorySchema = z
  .object({
    docChatSessions: z.record(z.string(), z.array(plainDocChatSessionSchema)),
    count: z.number(),
  })
  .strict();

export type DocChatSessionHistory = z.infer<typeof docChatSessionHistorySchema>;

const updateDocChatSessionPayloadSchema = z
  .object({
    name: z.string(),
  })
  .strict();

export type UpdateDocChatSessionPayload = z.infer<typeof updateDocChatSessionPayloadSchema>;

const updateDocChatElementPayloadSchema = z
  .object({
    thumbsUp: z.number(),
  })
  .strict();

export type UpdateDocChatElementPayload = z.infer<typeof updateDocChatElementPayloadSchema>;

const documentUpdateEntitySchema = z
  .object({
    documentName: z.string(),
  })
  .strict();

export type DocumentUpdateEntity = z.infer<typeof documentUpdateEntitySchema>;

const documentProgressSchema = z
  .object({
    processing: z.boolean(),
    percentage: z.number().optional(),
    error: z.boolean(),
  })
  .strict();

export type DocumentProgress = z.infer<typeof documentProgressSchema>;

export const projectDocumentRedisTickerSchema = z
  .object({
    projectId: z.string(),
    documentId: z.string(),
    documentDownloaded: z.boolean(),
    documentHealthcheckDone: z.boolean(),
    textractionDone: z.boolean(),
    documentMetadataGatheringDone: z.boolean(),
    textEmbeddingDone: z.boolean(),
    paragraphAggregationDone: z.boolean(),
    dbWritingDone: z.boolean(),
    errorMessage: z.string().nullish(),
  })
  .strict();

export type ProjectDocumentRedisTicker = z.infer<typeof projectDocumentRedisTickerSchema>;

export const documentSummaryRedisTickerSchema = z
  .object({
    documentId: z.string(),
    documentSummaryId: z.string(),
    paragraphNumSummarization: z.number(),
    maxParagraphNumSummarization: z.number(),
  })
  .strict();

export type DocumentSummaryRedisTicker = z.infer<typeof documentSummaryRedisTickerSchema>;

export const documentDetailsSchema = z
  .object({
    id: z.string(),
    dateStartedProcessing: z.string().nullable(),
    dateFinishedProcessing: z.string().nullable(),
    sizeOfDocumentInMb: z.number().nullish(),
    numberOfPages: z.number().nullable(),
    documentHealthLevel: z
      .union([
        z.literal("ENCRYPTED_PDF"),
        z.literal("EMPTY_PDF_ERROR"),
        z.literal("NOT_RECOVERABLE_ERRORS"),
        z.literal("TOLERABLE_PDF_ERRORS"),
        z.literal("NOT_KNOWN_ERROR"),
        z.literal("LANGUAGE"),
        z.literal("OK"),
      ])
      .nullable(),
    processingStatus: z
      .union([z.literal("NOT_PROCESSED"), z.literal("UNDER_PROCESSING"), z.literal("PROCESSED"), z.literal("ERROR")])
      .nullable(),
    errorMessage: z
      .union([
        z.literal("NOT_RECOVERABLE_ERRORS"),
        z.literal("ERROR_DURING_EMBEDDING"),
        z.literal("ERROR_DURING_REACHING_MODELLAKE"),
        z.literal("ERROR_DURING_FILE_DOWNLOAD"),
        z.literal("ERROR_DURING_TEXTRACTION"),
        z.literal("ERROR_DURING_SEMANTIC_ENCODING"),
        z.literal("ERROR_DURING_DB_UPLOAD"),
        z.literal("EMPTY_PDF_ERROR"),
        z.literal("ERROR_DURING_PARAGRAPH_AGGREGATION"),
        z.null(),
        z.undefined(),
      ])
      .nullable(),
    documentId: z.string(),
  })
  .strict();

export type DocumentDetailsSchema = z.infer<typeof documentDetailsSchema>;

export const baseDocumentEntitySchemaDTO = z
  .object({
    id: z.string(),
    name: z.string(),
    userId: z.string(),
    projectId: z.string(),
    documentUrl: z.string(),
    dateLastOpen: z.string(),
    dateCreated: z.string(),
    favourite: z.boolean(),
    coverImageUrl: z.string().nullish(),
    inheritedFromUrl: z.string().nullish(),
    documentDetails: z.null(),
    authors: z.array(z.string()).nullish(),
  })
  .strict();
export const extendedDocumentEntitySchemaDTO = baseDocumentEntitySchemaDTO
  .extend({
    documentDetails: documentDetailsSchema,
  })
  .strict();
export const baseDocumentEntitySchema = extendedDocumentEntitySchemaDTO
  .extend({
    workspaceId: z.string(),
  })
  .strict();

export type BaseDocumentEntity = z.infer<typeof baseDocumentEntitySchema>;
export const documentEntitySchema = baseDocumentEntitySchema
  .extend({
    documentDetails: documentDetailsSchema,
  })
  .strict();

export type DocumentEntity = z.infer<typeof documentEntitySchema>;
