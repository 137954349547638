import { Navigate, Outlet } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants";
import { PreservedQueryNavigation } from "@/components/button/navigation-utils.tsx";
import { useIsAuthenticated } from "@/firebase/hooks";
import { useIsDemoPage, useIsTemporaryPage } from "@/service/hooks/misc.ts";
import { useCurrentDocumentTabId } from "@/service/hooks/react-router.ts";
import { getClaimSummaryRoute } from "@/utils/route.ts";

const AlreadyAuthGuardRoute = ({ redirectPath = ROUTES.HOME }: { redirectPath?: string }) => {
  const [isAuthenticated, loading] = useIsAuthenticated();
  const { matched: isDemoPage } = useIsDemoPage();
  const isTemporaryPage = useIsTemporaryPage();
  const documentId = useCurrentDocumentTabId();
  if (loading) return null;

  if (!isAuthenticated) return <Outlet />;

  if (isDemoPage) return <Navigate to={redirectPath} />;

  if (isTemporaryPage && documentId) return <Navigate to={getClaimSummaryRoute(documentId)} />;

  return <PreservedQueryNavigation replace to={redirectPath} />;
};

export default AlreadyAuthGuardRoute;
