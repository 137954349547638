import { cn } from "@/utils";

const OnboardingSlide = ({
  title = "Title",
  description = "Description",
  className,
  children,
}: {
  title?: string;
  description?: string;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <article className={cn("flex flex-col gap-12", className)}>
      <header className="mx-auto flex w-full flex-col gap-3">
        <h1 className="text-center text-2xl font-bold text-primary dark:text-white">{title}</h1>
        <h5 className="text-center text-primary dark:text-white">{description}</h5>
      </header>
      {children}
    </article>
  );
};
export default OnboardingSlide;
