import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const SearchIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-text-default", props.className)}
    >
      <path
        clipRule="evenodd"
        d="M6.99999 1.33333C6.09638 1.33341 5.2059 1.54957 4.40282 1.96379C3.59975 2.37801 2.90738 2.97827 2.38348 3.7145C1.85957 4.45072 1.51933 5.30156 1.39112 6.19602C1.26292 7.09049 1.35049 8.00264 1.6465 8.85638C1.94252 9.71012 2.43842 10.4807 3.09281 11.1038C3.7472 11.7269 4.54112 12.1845 5.40833 12.4384C6.27553 12.6923 7.19088 12.7351 8.078 12.5633C8.96512 12.3914 9.79828 12.01 10.508 11.4507L12.9427 13.8853C13.0684 14.0068 13.2368 14.074 13.4116 14.0724C13.5864 14.0709 13.7536 14.0008 13.8772 13.8772C14.0008 13.7536 14.0709 13.5864 14.0724 13.4116C14.074 13.2368 14.0068 13.0684 13.8853 12.9427L11.4507 10.508C12.1093 9.67241 12.5194 8.66826 12.6341 7.61048C12.7487 6.55269 12.5632 5.48401 12.0988 4.52672C11.6344 3.56944 10.9099 2.76223 10.0082 2.19748C9.10646 1.63273 8.06396 1.33326 6.99999 1.33333ZM2.66665 6.99999C2.66665 5.85072 3.1232 4.74852 3.93586 3.93587C4.74851 3.12321 5.85071 2.66666 6.99999 2.66666C8.14926 2.66666 9.25146 3.12321 10.0641 3.93587C10.8768 4.74852 11.3333 5.85072 11.3333 6.99999C11.3333 8.14927 10.8768 9.25147 10.0641 10.0641C9.25146 10.8768 8.14926 11.3333 6.99999 11.3333C5.85071 11.3333 4.74851 10.8768 3.93586 10.0641C3.1232 9.25147 2.66665 8.14927 2.66665 6.99999Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default SearchIcon;
