import { useMemo } from "react";

import { Link } from "react-router-dom";

import { Button } from "@/components/ui/button.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { type StrapiAnnouncement } from "@/types/schemas";
import { cn, getStrapiMediaUrl } from "@/utils";

const AnnouncementCard = ({ announcement }: { announcement: StrapiAnnouncement }) => {
  const url = useMemo(
    () => getStrapiMediaUrl(announcement.attributes.image.data.attributes.url),
    [announcement.attributes.image.data.attributes.url],
  );

  const button = useMemo(() => {
    if (!announcement.attributes.deepLink || !announcement.attributes.buttonLabel) return null;
    return (
      <Link to={announcement.attributes.deepLink}>
        <Button
          className="absolute bottom-0 left-6 translate-y-16 transition-all duration-300 group-hover:-translate-y-6"
          variant="white"
        >
          {announcement.attributes.buttonLabel}
        </Button>
      </Link>
    );
  }, [announcement.attributes.buttonLabel, announcement.attributes.deepLink]);

  return (
    <section
      className={cn(
        "group relative h-72 overflow-hidden rounded-3xl bg-primitive-grey-300 p-6 data-[card-type=primary]:col-span-2",
      )}
      data-card-type={announcement.attributes.type}
    >
      <img
        alt={announcement.attributes.image.data.attributes.alternativeText}
        className="absolute left-0 top-0 h-full w-full object-cover transition-all duration-300 group-hover:scale-110"
        src={url}
      />
      {button}
      <Typography className="relative z-10 font-bold md:text-xl md:font-bold group-data-[card-type=secondary]:md:text-h5 xl:text-h2 group-data-[card-type=secondary]:xl:text-h5">
        {announcement.attributes.title}
      </Typography>
    </section>
  );
};
export default AnnouncementCard;
