import React, { useRef } from "react";

import ParagraphSummary from "@/components/document/paragraph-summary.tsx";
// import { Divider } from "@/components/ui/divider.tsx";
import { LoadingSpinner } from "@/pages/loading";
import { useCurrentDocumentTabId } from "@/service/hooks/react-router.ts";
import { useHeadsObserver } from "@/service/hooks/section-observer.ts";
import { type SummaryResponse } from "@/types/schemas";

// import { getPagesTagForSummaryParagraph } from "@/utils";

const CHAPTER_EVENT_LISTENER_LIMIT = 10;

const ChapterList = ({
  chapterId,
  currentDocumentId,
  summary,
}: {
  chapterId: string;
  currentDocumentId: string;
  summary: SummaryResponse;
}) => {
  const ref = useRef(null);
  const paragraphIds = summary.paragraphSummariesOfChapter[chapterId];
  if (!paragraphIds) return null;

  return (
    <section className="flex flex-col gap-2" ref={ref}>
      {paragraphIds.map((paragraphId) => (
        <React.Fragment key={`${chapterId}-${paragraphId}`}>
          <ParagraphSummary
            key={`${summary.id}-${paragraphId}`}
            paragraphId={paragraphId}
            paramDocumentId={currentDocumentId ?? ""}
          />
        </React.Fragment>
      ))}
    </section>
  );
};

const Summarized = ({
  summary,
  scrollTarget,
}: {
  summary: SummaryResponse;
  scrollTarget: (node: HTMLDivElement) => void;
}) => {
  const currentDocumentId = useCurrentDocumentTabId();

  useHeadsObserver({ disabled: summary.chapterIdOrderedList.length >= CHAPTER_EVENT_LISTENER_LIMIT && false });

  return (
    <section className="relative flex-1 overflow-hidden font-jakarta">
      <section className="absolute top-0 z-[100] h-4 w-full bg-gradient-to-b from-bg-layer1 to-transparent " />
      <section className="h-full overflow-y-auto">
        <section className="mx-auto flex h-full w-full max-w-2xl flex-1 flex-col gap-6 p-4 px-4 pt-16">
          {summary.chapterIdOrderedList.map((chapter) => (
            <article
              className="observedSection relative flex scroll-m-[102px] flex-col animate-in fade-in-10 slide-in-from-left-1"
              id={`target-${chapter}`}
              key={chapter}
            >
              <header
                className="mb-2 flex items-center justify-between px-2 data-[invisible=true]:hidden"
                data-invisible={chapter === "-"}
              >
                <h2 className="text-h5 font-bold text-primary-onBg " id={`${chapter} - title`}>
                  {summary.chapterIdNamePairs[chapter].chapterName}
                </h2>
                <p className="block whitespace-nowrap text-secondary-onBg">
                  Page {summary.chapterIdNamePairs[chapter].pageNumberStart}-
                  {summary.chapterIdNamePairs[chapter].pageNumberEnd}
                </p>
              </header>
              <ChapterList chapterId={chapter} currentDocumentId={currentDocumentId} summary={summary} />
            </article>
          ))}
          <p
            className="hidden flex-row justify-center data-[active=true]:flex"
            data-active={summary.hasMore}
            ref={scrollTarget}
          >
            <LoadingSpinner />
          </p>
          <div className="flex w-full py-10"></div>
        </section>
      </section>
    </section>
  );
};

export default Summarized;
