import EmptyWorkspaceIllustration from "@/assets/images/v3/svg/empty-workspace-illustration.svg";
import { EmptyView } from "@/components/v3";

const EmptyWorkspace = () => {
  return (
    <EmptyView
      description="Drag your documents and study materials here to use Quino’s AI features."
      imageUrl={EmptyWorkspaceIllustration}
      title="Start uploading your first documents"
    />
  );
};
export default EmptyWorkspace;
