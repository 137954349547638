import { QuinoTooltip } from "@/components/generic";
import { SelectFontSizeIcon } from "@/components/icons/summary";
import { Button } from "@/components/ui/button.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { RadioGroup, RadioGroupItemCheckMark } from "@/components/ui/radio-group.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { useLetterSize } from "@/service/user.ts";
import { type FontScale } from "@/types/app.ts";
import { cn } from "@/utils";

const formats: { value: FontScale; label: string }[] = [
  {
    value: "small",
    label: "Small",
  },
  {
    value: "normal",
    label: "Medium",
  },
  {
    value: "large",
    label: "Large",
  },
];

const LetterSizePopover = () => {
  const { letterSize, onSizeChange } = useLetterSize();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <section className="h-12 w-12">
          <QuinoTooltip align="center" className="font-semibold" content={"Select font size"} side="bottom">
            <Button className="h-12 w-12 rounded-none p-4" size="icon" variant="icon">
              <SelectFontSizeIcon />
            </Button>
          </QuinoTooltip>
        </section>
      </PopoverTrigger>
      <PopoverContent className="z-[2000] h-[146px] w-[432px] rounded-xl p-2">
        <section className="flex flex-row items-center justify-between gap-2">
          <section>
            <RadioGroup
              className="flex flex-col gap-1 pl-1"
              defaultValue={letterSize.size}
              onValueChange={onSizeChange}
            >
              {formats.map((item, index) => (
                <div className="flex  items-center gap-3.5 p-2" key={item.value}>
                  <RadioGroupItemCheckMark id={`r${index + 1}`} value={item.value} />
                  <Label
                    className={cn(
                      "cursor-pointer font-jakarta text-label-l3-regular text-text-active",
                      letterSize.size === item.value && "font-bold",
                    )}
                    htmlFor={`r${index + 1}`}
                  >
                    {item.label}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </section>
          <section className="h-[130px] w-[287px] overflow-hidden rounded-lg border border-stroke-default font-jakarta">
            <Typography>
              The phenomenon of quantum entanglement, a cornerstone in the realm of quantum mechanics, elucidates the
              intrinsic correlation between quantum particles, transcending spatial separations. This entwined state,
              despite its spatial disparity, results in a phenomenon where the properties of one particle
              instantaneously affect the state of its entangled counterpart, defying classical conceptions of locality.
            </Typography>
          </section>
        </section>
      </PopoverContent>
    </Popover>
  );
};
export default LetterSizePopover;
