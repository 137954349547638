import { useCallback, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { createId } from "@paralleldrive/cuid2";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { UploadIcon } from "lucide-react";
import { type FileRejection, useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { ANALYTICS_CATEGORY } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Stack } from "@/components/generic/layout.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { DeleteIcon, ImageIcon, MailSent } from "@/components/v3";
import { feedbackStorage, saveFeedback } from "@/firebase";
import { useAppAuthState } from "@/firebase/hooks.ts";
import { customGAEventSender } from "@/service";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const buttonTexts: string[] = ["General", "Bug", "Idea"];

const FormSchema = z
  .object({
    subject: z
      .string()
      .min(1, {
        message: "There must be a subject at least",
      })
      .max(256, {
        message: "Must be at most 256 characters",
      }),
    type: z.string(),
    imageName: z.string().min(0),
    message: z.string().min(0),
  })
  .refine((data) => data.imageName || data.message, { message: "Add either a message or an image" });
type FormSchemaType = z.infer<typeof FormSchema>;

const Feedback = () => {
  const [open, setState] = useModalState(ModalKeys.FEEDBACK);
  const [selectedButtonIndex, setButtonIndex] = useState<number>(0);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [user] = useAppAuthState();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      type: "General",
      subject: "",
      message: "",
      imageName: "",
    },
  });

  const handleOnChange = useCallback(
    (selectedFile: File) => {
      if (selectedFile) {
        setImageFile(selectedFile);
        form.setValue("imageName", selectedFile.name, { shouldValidate: true });
      }
    },
    [form, setImageFile],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      // Do something with the files
      if (acceptedFiles.length > 0) {
        handleOnChange(acceptedFiles[0]);
      }
      if (rejectedFiles.length > 0) {
        toast({
          title: "Some files were rejected",
          description: "Maybe the selected file is too large!",
          variant: "destructive",
        });
      }
    },
    [handleOnChange],
  );

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
  } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 1024 * 1024,
    noClick: true,
    noKeyboard: true,

    accept: {
      "image/png": [],
      "image/jpeg": [],
    },
  });

  const handleSubmit = useCallback(
    async (data: FormSchemaType) => {
      try {
        setLoading(true);

        if (!user) return;

        customGAEventSender(ANALYTICS_CATEGORY.FEEDBACK, "feedback_sent");

        let imageUrl: null | string = null;
        if (imageFile) {
          const cuid = createId().toLowerCase();
          const storageRef = ref(feedbackStorage, `${user.uid}/${cuid}`);
          const result = await uploadBytes(storageRef, imageFile);
          imageUrl = await getDownloadURL(result.ref);
        }
        void saveFeedback({
          userId: user.uid,
          type: buttonTexts[selectedButtonIndex],
          subject: data.subject,
          message: data.message,
          timestamp: new Date().toISOString(),
          imageUrl,
        });

        form.reset();
        form.setValue("subject", "");
        form.setValue("imageName", "");
        form.setValue("message", "");
        form.setValue("type", "General");
        setImageFile(null);
        setState(false);
        toast({ variant: "success", title: "Thanks for sharing your feedback!", icon: <MailSent /> });
      } catch {
        toast({ variant: "destructive", title: "Error sending feedback!" });
      } finally {
        setLoading(false);
      }
    },
    [form, imageFile, selectedButtonIndex, setState, user],
  );

  return (
    <Dialog open={open} onOpenChange={setState}>
      <DialogContent className="flex max-h-[95vh] max-w-xl flex-col gap-4">
        <DialogHeader className="gap-3">
          <DialogTitle className="">Send feedback</DialogTitle>
          <DialogDescription>Your thoughts are valuable in helping improve our products. </DialogDescription>
        </DialogHeader>
        <section {...getRootProps()} className="flex h-full flex-col gap-6 overflow-hidden">
          <input {...getInputProps()} />
          <Form {...form}>
            <form className="flex flex-1 flex-col gap-12 overflow-hidden" onSubmit={form.handleSubmit(handleSubmit)}>
              <section className="grid grid-cols-3 gap-2 rounded-xl border border-stroke-default p-1.5">
                {buttonTexts.map((item, index) => (
                  <Button
                    className="flex cursor-pointer flex-row justify-center rounded-lg text-xs font-bold"
                    key={item}
                    size="sm"
                    type="button"
                    variant={selectedButtonIndex === index ? "contained" : "ghost"}
                    onClick={() => setButtonIndex(index)}
                  >
                    {item}
                  </Button>
                ))}
              </section>
              <section className="flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="subject"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="text-primary">Subject</FormLabel>
                      <FormControl>
                        <Input className="" placeholder="Subject" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="text-primary">Message</FormLabel>
                      <FormControl>
                        <Textarea className="resize-none" placeholder="Your message" rows={5} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {imageFile ? (
                  <Stack className="flex flex-row items-center gap-2">
                    <Button
                      className="h-10 w-10 p-3"
                      size="icon"
                      type="button"
                      variant="secondary"
                      onClick={openDropzone}
                    >
                      <ImageIcon className="h-4 w-4 fill-default" />
                    </Button>
                    <Typography className="max-w-[250px] overflow-hidden overflow-ellipsis whitespace-nowrap !text-xs font-bold !text-secondary-onBg">
                      {imageFile.name}
                    </Typography>
                    <Button
                      className="h-10 w-10 p-3"
                      id="remove-image-button"
                      size="icon"
                      variant="destructive"
                      onClick={() => {
                        setImageFile(null);
                        form.setValue("imageName", "", { shouldValidate: true });
                      }}
                    >
                      <DeleteIcon className="h-4 w-4 " />
                    </Button>
                  </Stack>
                ) : (
                  <section>
                    <Button
                      className="cursor-pointer gap-2 self-center pl-2 text-default"
                      size="sm"
                      type="button"
                      variant="secondary"
                      onClick={openDropzone}
                    >
                      <span className="h-4 w-4">
                        <UploadIcon className=" h-4 w-4 stroke-default text-default" />
                      </span>
                      Upload Image
                    </Button>
                  </section>
                )}
              </section>
              <DialogFooter>
                <Button size="sm" type="button" variant="ghost" onClick={() => setState(false)}>
                  Cancel
                </Button>
                <Button disabled={loading} size="sm">
                  Send feedback
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default Feedback;
