import { useCallback, useMemo } from "react";

import { createId } from "@paralleldrive/cuid2";
import { type UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import {
  createNewNoteDemo,
  demoDeleteNote,
  demoUpdateNote,
  demoUpdateParagraphNote,
  fetchNoteDataDemo,
} from "@/api/demo";
import { useProjectData } from "@/api/project.ts";
import { deleteRequest, getRequest, insertParagraphIntoNoteEntity, postRequest, putRequest } from "@/api/utils";
import { BACKEND_URL, NoteParagraphInteractionType, ResourceCardType } from "@/assets/constants/constants";
import { QUERY_KEYS } from "@/assets/constants/query-keys";
import { toast } from "@/components/ui/use-toast";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentNoteId, useCurrentWorkspaceId, useIsDocumentPage } from "@/service/hooks/react-router";
import { useCurrentNoteTabsOfProject } from "@/service/hooks/zustand.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { type ReorderPayload } from "@/types/interfaces/api.ts";
import { type QueryConfig } from "@/types/react-query";
import {
  type CreateImageNoteParagraph,
  type CreateMarkdownNoteParagraph,
  type BaseNoteParagraph,
  type NoteEntity,
  type NoteParagraph,
  type ProjectEntity,
  type ResourceElement,
  noteEntitySchema,
  extendedFolderElementSchema,
  noteParagraphSchema,
  type ExtendedBaseNoteEntity,
  extendedBaseNoteEntitySchema,
} from "@/types/schemas";
import { useCurrentProjectId, validateZodSchema } from "@/utils";
import { useParagraphEditor } from "@/zustand/hooks.ts";
import useAppStateStore from "@/zustand/store.ts";

export const fetchNoteData = (
  workspaceId: string,
  noteId: string,
  openedAt?: "library" | "document_view",
): Promise<NoteEntity> => {
  let url = `${BACKEND_URL}/v3/notes/${workspaceId}/${noteId}`;
  if (openedAt) url = `${url}?opened_at=${openedAt}`;
  return validateZodSchema(getRequest(url), noteEntitySchema);
};

export const useAllMyNoteIdsForProjectSorted = (workspaceId: string, projectId: string) => {
  const { data } = useProjectData(workspaceId, projectId);
  const currentUserId = useFirebaseUserId();
  return useMemo(
    () =>
      data?.directChildElements
        .filter((item) => item.elementType === ResourceCardType.NOTE_ELEMENT && item.userId === currentUserId)
        .map((item) => item.id),
    [data, currentUserId],
  );
};
export const createNewNote = async ({
  name,
  projectId,
  workspaceId,
}: {
  name: string;
  projectId: string;
  workspaceId: string;
}) =>
  validateZodSchema(
    postRequest<NoteEntity>(`${BACKEND_URL}/v3/notes/${workspaceId}/`, {
      name,
      projectId,
    }),
    noteEntitySchema,
  );

export const updateNoteOrdering = async ({ noteId, ordering }: { noteId: string; ordering: ReorderPayload }) =>
  putRequest(`${BACKEND_URL}/v3/notes/paragraph/reorder/${noteId}`, {
    ...ordering,
  });

export const updateNote = async ({
  workspaceId,
  noteId,
  name,
}: {
  workspaceId: string;
  noteId: string;
  name: string;
}) =>
  validateZodSchema(
    putRequest<ExtendedBaseNoteEntity>(`${BACKEND_URL}/v3/notes/${workspaceId}/note/${noteId}`, {
      name,
    }),
    extendedBaseNoteEntitySchema,
  );

export const deleteNote = async ({ noteId, workspaceId }: { noteId: string; workspaceId: string }) =>
  validateZodSchema(
    deleteRequest<ExtendedBaseNoteEntity>(`${BACKEND_URL}/v3/notes/${workspaceId}/${noteId}`),
    extendedFolderElementSchema,
  );

export const addMarkdownParagraph = async ({
  noteId,
  payload,
}: {
  noteId: string;
  payload: CreateMarkdownNoteParagraph;
}): Promise<NoteParagraph> =>
  validateZodSchema(
    postRequest(`${BACKEND_URL}/v3/notes/paragraph/text-note-paragraph/${noteId}`, payload),
    noteParagraphSchema,
  );

export const addImageParagraph = async ({
  noteId,
  payload,
}: {
  noteId: string;
  payload: CreateImageNoteParagraph;
}): Promise<NoteParagraph> => {
  const body = new FormData();
  body.append("image_to_upload", payload.imageToUpload);
  body.append("to_position", payload.position.toString());
  return await validateZodSchema(
    postRequest(`${BACKEND_URL}/v3/notes/paragraph/image-note-paragraph/${noteId}`, body),
    noteParagraphSchema,
  );
};

export const addParagraph = async ({
  noteId,
  payload,
  isDemo,
}: {
  noteId: string;
  payload:
    | { variant: "TEXT"; payload: CreateMarkdownNoteParagraph }
    | {
        variant: "IMAGE";
        payload: CreateImageNoteParagraph;
      };
  isDemo?: boolean;
}): Promise<NoteParagraph> => {
  if (payload.variant === "TEXT") {
    if (isDemo)
      return {
        markdownText: payload.payload.markdownText,
        position: payload.payload.position,
        documentId: payload.payload.documentId ?? "",
        docPageNumber: payload.payload.docPageNumber ?? -1,
        docPageTextOrigin: payload.payload.docPageTextOrigin ?? "",
        modelLogId: "",
        id: createId().toLowerCase(),
        noteId: noteId,
        dateCreated: new Date().toISOString(),
        noteParagraphType: "TEXT",
      };
    return validateZodSchema(addMarkdownParagraph({ noteId, payload: payload.payload }), noteParagraphSchema);
  }
  if (payload.variant === "IMAGE") {
    if (isDemo)
      return {
        imageCuid: createId().toLowerCase(),
        position: payload.payload.position,
        documentId: payload.payload.documentId ?? "",
        docPageNumber: payload.payload.docPageNumber ?? -1,
        docPageTextOrigin: payload.payload.docPageTextOrigin ?? "",
        modelLogId: "",
        id: createId().toLowerCase(),
        noteId: noteId,
        dateCreated: new Date().toISOString(),
        noteParagraphType: "IMAGE",
      };
    return validateZodSchema(addImageParagraph({ noteId, payload: payload.payload }), noteParagraphSchema);
  }
  throw new Error("Invalid payload!");
};

export const updateParagraphNote = async (params: {
  paragraphId: string;
  payload: Partial<CreateMarkdownNoteParagraph>;
}) =>
  validateZodSchema(
    putRequest<NoteParagraph>(`${BACKEND_URL}/v3/notes/paragraph/${params.paragraphId}`, params.payload),
    noteParagraphSchema,
  );

export const deleteParagraphNote = async ({
  paragraphId,
  noteId,
  isDemo,
}: {
  paragraphId: string;
  noteId: string;
  isDemo?: boolean;
}): Promise<NoteParagraph> => {
  if (isDemo) {
    return {
      docPageTextOrigin: "",
      markdownText: "",
      noteId: noteId,
      id: paragraphId,
      noteParagraphType: "TEXT",
      documentId: "",
      dateCreated: "",
      hasAd: false,
      position: -1,
      docPageNumber: 0,
    };
  }

  return validateZodSchema(
    deleteRequest<NoteParagraph>(`${BACKEND_URL}/v3/notes/paragraph/${paragraphId}`),
    noteParagraphSchema,
  );
};
export const useNote = (workspaceId: string, noteId: string, options?: QueryConfig<NoteEntity>) => {
  const isDocumentPage = useIsDocumentPage();
  const { matched: isDemo } = useIsDemoLikePage();
  return useQuery({
    queryKey: [QUERY_KEYS.NOTE, workspaceId, { id: noteId }],
    queryFn: () =>
      isDemo
        ? fetchNoteDataDemo(noteId, isDocumentPage ? "document_view" : "library")
        : fetchNoteData(workspaceId, noteId, isDocumentPage ? "document_view" : "library"),
    enabled: !!noteId,
    ...options,
  });
};

export const useNoteOrdering = (
  workspaceId: string,
  noteId: string,
  options?: QueryConfig<NoteEntity, unknown, string[]>,
) => {
  const isDocumentPage = useIsDocumentPage();
  return useQuery({
    queryKey: [QUERY_KEYS.NOTE, workspaceId, { id: noteId }],
    queryFn: () => fetchNoteData(workspaceId, noteId, isDocumentPage ? "document_view" : "library"),
    enabled: !!noteId,
    select: (data) => data?.noteParagraphs.sort((a, b) => a.position - b.position).map((item) => item.id) ?? [],
    ...options,
  });
};

export const useNoteParagraph = (workspaceId: string, noteId: string, paragraphId: string) => {
  const isDocumentPage = useIsDocumentPage();
  return useQuery({
    queryKey: [QUERY_KEYS.NOTE, workspaceId, { id: noteId }],
    queryFn: () => fetchNoteData(workspaceId, noteId, isDocumentPage ? "document_view" : "library"),
    select: (result) => result.noteParagraphs.find((item) => item.id === paragraphId),
  });
};

export const useCreateNote = () => {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  const isDocumentPage = useIsDocumentPage();
  const { openNote } = useCurrentNoteTabsOfProject();
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  return useMutation({
    mutationFn: isDemo ? createNewNoteDemo : createNewNote,
    onSuccess: (result) => {
      if (isDemo) {
        mixpanelTrack("note_created", {
          note_id: result.id,
        });
      }

      if (isDemo) {
        queryClient.setQueryData<ResourceElement>([QUERY_KEYS.RESOURCE, result.id], () => ({
          ...result,
          elementType: ResourceCardType.NOTE_ELEMENT,
        }));
        queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, workspaceId, { id: result.id }], () => ({ ...result }));
      }
      queryClient.setQueryData<ProjectEntity>([QUERY_KEYS.PROJECTS, workspaceId, projectId], (oldData) =>
        oldData
          ? {
              ...oldData,
              directChildElements: [
                ...oldData.directChildElements,
                {
                  id: result.id,
                  elementType: ResourceCardType.NOTE_ELEMENT,
                  dateCreated: result.dateCreated,
                  favourite: result.favourite,
                  name: result.name,
                  projectId: result.projectId,
                  userId: result.userId,
                },
              ],
            }
          : oldData,
      );
      if (isDocumentPage) openNote(projectId ?? "", result.id);
    },
    onError: () => toast({ title: "Failed to create note", variant: "destructive" }),
  });
};

export const useUpdateNote = () => {
  const queryClient = useQueryClient();
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  const currentWorkspaceId = useCurrentWorkspaceId();
  return useMutation({
    mutationFn: isDemo ? demoUpdateNote : updateNote,
    onSuccess: (data) => {
      if (isDemo) mixpanelTrack("note_updated", { note_id: data.id });
      toast({ title: "Note updated", variant: "success" });

      queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, currentWorkspaceId, { id: data.id }], (oldData) =>
        oldData
          ? {
              ...oldData,
              ...data,
            }
          : oldData,
      );
    },
  });
};

export const useCreateParagraph = () => {
  const workspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const [, setEditedId] = useParagraphEditor();
  const track = useMixpanelTrack();
  const { matched: isDemo } = useIsDemoLikePage();
  return useMutation({
    mutationKey: ["CREATE_PARAGRAPH"],
    mutationFn: (payload: Parameters<typeof addParagraph>[0]) => {
      return addParagraph({ ...payload, isDemo });
    },
    onSuccess: (result) => {
      if (isDemo)
        track("note_paragraph_created", {
          content_type: result.noteParagraphType,
          paragraph_type: "human_created",
        });
      insertParagraphIntoNoteEntity({ workspaceId, result, queryClient });
      setEditedId({ editedParagraphId: result.id });
    },
  });
};

export const useUpdateParagraphNote = () => {
  const queryClient = useQueryClient();
  const { matched: isDemo } = useIsDemoLikePage();
  const [tab] = useCurrentNoteId();
  const mixpanelTrack = useMixpanelTrack();
  const currentWorkspaceId = useCurrentWorkspaceId();
  // Demo update
  const demoUpdate = useMutation({
    mutationFn: demoUpdateParagraphNote,
    onSuccess: (_result, variables) => {
      queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, currentWorkspaceId, { id: tab }], (oldData) => {
        if (!oldData) return oldData;
        const index = oldData.noteParagraphs.findIndex((item) => item.id === variables.paragraphId);
        if (index === -1) return;

        if (isDemo) {
          const oldParagraph = oldData.noteParagraphs[index];
          if (oldParagraph.noteParagraphType === "TEXT") {
            const difference = Math.abs(
              oldParagraph.markdownText.length - (variables.payload.markdownText?.length ?? 0),
            );
            mixpanelTrack("note_paragraph_edited", {
              character_differences: difference,
              difference_type: difference > 50 ? "longer" : "shorter",
              paragraph_type: oldParagraph.modelLogId
                ? NoteParagraphInteractionType.AI_CREATED
                : NoteParagraphInteractionType.HUMAN_CREATED,
            });
          }
        }

        oldData.noteParagraphs[index] = {
          ...oldData.noteParagraphs[index],
          ...variables.payload,
        };
        return {
          ...oldData,
          name: oldData.name + " ",
          noteParagraphs: oldData.noteParagraphs,
        };
      });
    },
  });

  //Standard Update
  const standardUpdate = useMutation({
    mutationFn: updateParagraphNote,
    onSuccess: (result) => {
      queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, currentWorkspaceId, { id: result.noteId }], (oldData) => {
        if (!oldData) return oldData;
        const index = oldData.noteParagraphs.findIndex((item) => item.id === result.id);
        if (index === -1) return;
        oldData.noteParagraphs[index] = {
          ...result,
          ...{
            hasAd: oldData.noteParagraphs[index].hasAd,
          },
        };
        return {
          ...oldData,
          name: oldData.name + " ",
          noteParagraphs: oldData.noteParagraphs,
        };
      });
    },
  });

  return useMemo(() => (isDemo ? demoUpdate : standardUpdate), [demoUpdate, isDemo, standardUpdate]);
};

export const useUpdateParagraphNoteLocal = () => {
  const queryClient = useQueryClient();
  const currentWorkspaceId = useCurrentWorkspaceId();

  return useCallback(
    ({
      paragraphId,
      noteId,
      payload,
    }: {
      paragraphId: string;
      noteId: string;
      payload: Partial<Omit<BaseNoteParagraph, "id">>;
    }) => {
      queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, currentWorkspaceId, { id: noteId }], (oldData) => {
        if (!oldData) return oldData;
        const index = oldData.noteParagraphs.findIndex((item) => item.id === paragraphId);
        if (index === -1) return;
        oldData.noteParagraphs[index] = {
          ...oldData.noteParagraphs[index],
          ...payload,
        };
        return {
          ...oldData,
          name: oldData.name + " ",
          noteParagraphs: oldData.noteParagraphs,
        };
      });
    },
    [currentWorkspaceId, queryClient],
  );
};

export const useDeleteParagraphNote = () => {
  const workspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  return useMutation({
    mutationFn: ({ paragraphId, noteId }: { paragraphId: string; noteId: string }) =>
      deleteParagraphNote({ paragraphId, noteId, isDemo }),
    onSuccess: (result) => {
      if (isDemo) {
        const note = queryClient.getQueryData<NoteEntity>([QUERY_KEYS.NOTE, workspaceId, { id: result.noteId }]);
        const paragraph = note?.noteParagraphs.find((item) => item.id === result.id);
        if (paragraph)
          mixpanelTrack("note_paragraph_deleted", {
            content_type: paragraph.noteParagraphType,
            paragraph_type: paragraph.modelLogId
              ? NoteParagraphInteractionType.AI_CREATED
              : NoteParagraphInteractionType.HUMAN_CREATED,
          });
      }
      queryClient.setQueryData<NoteEntity>([QUERY_KEYS.NOTE, workspaceId, { id: result.noteId }], (oldData) =>
        oldData
          ? {
              ...oldData,
              noteParagraphs: oldData.noteParagraphs.filter((item) => item.id !== result.id),
            }
          : oldData,
      );
    },
  });
};

export const useUpdateOrdering = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  return useMutation({
    mutationFn: async ({ noteId, ordering }: { noteId: string; ordering: string[] }) => {
      const newOrdering = _.reduce<string, Record<string, number>>(
        ordering,
        (prev, curr, index) => {
          prev[curr] = index;
          return prev;
        },
        {},
      );
      if (!isDemo) await updateNoteOrdering({ noteId, ordering: { paragraphIdPositionPairs: newOrdering } });
      return newOrdering;
    },
    onSuccess: (result_positions: Record<string, number>, variables: { noteId: string; ordering: string[] }) => {
      queryClient.setQueryData<NoteEntity>(
        [QUERY_KEYS.NOTE, currentWorkspaceId, { id: variables.noteId }],
        (oldData) => {
          if (!oldData) return oldData;

          Object.keys(result_positions).forEach((noteParagraphId) => {
            const paragraphIndex = oldData.noteParagraphs.findIndex((item) => item.id === noteParagraphId);
            if (oldData.noteParagraphs.find((item) => item.id === noteParagraphId)) {
              const paragraph = oldData.noteParagraphs[paragraphIndex];
              if (isDemo) {
                mixpanelTrack("note_paragraph_reorder", {
                  content_type: paragraph.noteParagraphType,
                  from_idx: paragraph.position,
                  to_idx: result_positions[noteParagraphId],
                  paragraph_type: paragraph.modelLogId
                    ? NoteParagraphInteractionType.AI_CREATED
                    : NoteParagraphInteractionType.HUMAN_CREATED,
                });
              }
              paragraph.position = result_positions[noteParagraphId];
            }
          });
          oldData?.noteParagraphs.sort((a, b) => a.position - b.position);
          return { ...oldData, name: oldData.name + " " };
        },
      );
    },
  });
};

export const useDeleteNote = (options?: UseMutationOptions<ExtendedBaseNoteEntity, unknown, { noteId: string }>) => {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  const unselectResource = useAppStateStore((state) => state.unselectResource);
  const { matched: isDemo } = useIsDemoLikePage();
  const mixpanelTrack = useMixpanelTrack();
  const [currentNoteId, setCurrentNoteId] = useCurrentNoteId();
  return useMutation({
    ...options,
    mutationFn: isDemo ? demoDeleteNote : deleteNote,
    onSuccess: (data, vars) => {
      if (isDemo) {
        mixpanelTrack("note_deleted", { note_id: vars.noteId });
      }
      queryClient.setQueryData<string[]>([QUERY_KEYS.NOTE_IDS, workspaceId, { projectId: data.projectId }], (array) => {
        return array ? array.filter((item) => item !== vars.noteId) : array;
      });
      queryClient.setQueryData<ProjectEntity>([QUERY_KEYS.PROJECTS, workspaceId, projectId], (oldData) =>
        oldData
          ? {
              ...oldData,
              directChildElements: oldData.directChildElements.filter(({ id }) => id != vars.noteId),
            }
          : oldData,
      );
      unselectResource(vars.noteId);
      if (currentNoteId === vars.noteId) {
        setCurrentNoteId("");
      }
    },
  });
};
