import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const FinanceAndEconomics = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <g clipPath="url(#clip0_2426_905)">
      <path
        d="M46.0253 45.8397C51.4791 44.464 56.476 38.5268 55.6985 34.223C54.921 29.9193 46.0264 26.59 44.6559 21.4583C43.2854 16.3266 39.3277 7.67669 35.1933 8.84711C23.0578 12.2826 26.3102 1.92293 15.7356 9.37162C5.16089 16.8203 14.5968 30.1133 15.1388 31.7796C15.6808 33.4459 12.771 37.6553 13.7942 45.2405C14.8174 52.8258 25.2386 53.1785 27.5717 50.5282C33.8987 43.3407 40.5716 47.2153 46.0253 45.8397Z"
        fill="#E2D7F4"
      />
      <g clipPath="url(#clip1_2426_905)">
        <path
          d="M5.92578 31.8186C5.92578 31.8186 18.3851 30.5297 28.4046 36.3536C28.4046 36.3536 36.4615 44.4105 36.9389 50.5049C36.9389 50.5049 20.7561 46.7125 11.9512 51.9954C11.9512 51.9954 13.0333 38.5866 5.92578 31.8186Z"
          fill="#3340CB"
        />
        <path
          d="M52.6129 33.8495L42.848 26.811C42.848 26.811 42.4131 26.4503 41.278 26.811L39.4746 25.1667C40.1164 24.3711 41.4478 22.504 41.6122 19.8785C41.8243 16.3672 39.8035 13.8796 38.801 12.6384C36.499 9.80603 33.6613 8.75581 32.2982 8.26784C30.7812 7.73212 27.551 6.63417 23.7798 7.73743C22.7137 8.04506 20.6822 8.63912 18.9955 10.5062C16.1472 13.6462 16.6511 17.7728 16.7253 18.3244C17.4043 23.2572 21.7165 25.8403 23.1221 26.689C27.185 29.1236 31.9216 29.219 35.5496 28.0097C36.5945 27.6596 37.4219 27.2406 37.9842 26.9224C38.5623 27.3202 39.1352 27.7127 39.7133 28.1105C39.7133 28.1105 39.1723 29.6328 39.883 30.2109C40.4983 30.7095 47.9771 35.4408 49.9396 36.6819C50.2154 36.8569 50.5708 36.8092 50.7936 36.5705C51.4248 35.9022 52.0507 35.2339 52.6818 34.5656C52.8781 34.3534 52.8463 34.0193 52.6129 33.8495ZM21.4301 21.3743C19.4569 19.0723 17.6642 14.8714 19.3986 12.0974C21.4778 8.77703 27.5457 9.22788 31.2161 10.8085C36.8332 13.2272 40.9067 20.0482 39.1352 23.2572C37.056 27.0231 26.2462 26.9966 21.4301 21.3743Z"
          fill="#7998F8"
          stroke="#3340CB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.20392"
        />
        <path
          d="M44.2323 28.1211C44.2323 28.1211 44.1633 29.3198 42.4023 30.5345"
          stroke="#3340CB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.20392"
        />
        <path
          d="M7.6875 29.0217C7.6875 29.0217 20.1468 27.7328 30.1663 33.5567C30.1663 33.5567 38.2232 41.6137 38.7006 47.7081C38.7006 47.7081 22.5178 43.9156 13.713 49.1985C13.713 49.1985 14.795 35.7898 7.6875 29.0217Z"
          fill="white"
          stroke="#3340CB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.20392"
        />
        <path
          d="M13.375 31.1914C13.375 31.1914 19.4005 35.7264 19.1989 43.5818"
          stroke="#3340CB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.20392"
        />
        <path
          d="M18.3203 31.6641C18.3203 31.6641 22.9932 35.117 24.5473 43.174"
          stroke="#3340CB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.20392"
        />
        <path
          d="M24.0391 32.8154C24.0391 32.8154 26.341 35.3561 27.2533 37.9975"
          stroke="#3340CB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.20392"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2426_905">
        <rect fill="white" height="57" width="60" />
      </clipPath>
      <clipPath id="clip1_2426_905">
        <rect fill="white" height="49" transform="translate(5.92578 5)" width="47.1535" />
      </clipPath>
    </defs>
  </svg>
);
export default FinanceAndEconomics;
