import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const CheckCircleFilled = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-10 w-10 fill-primitive-green-600", props.className)}
  >
    <path
      d="M19.9999 3.33325C29.2049 3.33325 36.6666 10.7949 36.6666 19.9999C36.6666 29.2049 29.2049 36.6666 19.9999 36.6666C10.7949 36.6666 3.33325 29.2049 3.33325 19.9999C3.33325 10.7949 10.7949 3.33325 19.9999 3.33325ZM25.8916 13.9683L17.6416 22.2183L14.1083 18.6833C13.9535 18.5284 13.7698 18.4055 13.5676 18.3217C13.3653 18.2379 13.1486 18.1947 12.9297 18.1946C12.4876 18.1944 12.0635 18.3699 11.7508 18.6824C11.438 18.9949 11.2622 19.4189 11.2621 19.861C11.2619 20.3031 11.4374 20.7272 11.7499 21.0399L16.3466 25.6366C16.5168 25.8069 16.719 25.942 16.9415 26.0342C17.164 26.1264 17.4024 26.1739 17.6433 26.1739C17.8841 26.1739 18.1226 26.1264 18.345 26.0342C18.5675 25.942 18.7697 25.8069 18.9399 25.6366L28.2499 16.3266C28.5627 16.0139 28.7383 15.5897 28.7383 15.1474C28.7383 14.7051 28.5627 14.281 28.2499 13.9683C27.9372 13.6555 27.513 13.4798 27.0708 13.4798C26.6285 13.4798 26.2043 13.6555 25.8916 13.9683Z"
      fill="inherit"
    />
  </svg>
);
export default CheckCircleFilled;
