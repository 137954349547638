import { Navigate, Outlet } from "react-router-dom";

import { useSpecificSearchParam } from "@/service/hooks/react-router.ts";

const HandleRedirect = () => {
  // const { value, setTrue } = useBoolean(false);
  //
  // const [queryParams] = useSearchParams();
  // const navigate = useNavigate();
  // useEffectOnce(() => {
  //   try {
  //     const value = queryParams.get("redirectTo");
  //     if (value) navigate(value);
  //   } finally {
  //     setTrue();
  //   }
  // });

  const redirectTo = useSpecificSearchParam("redirectTo");

  if (redirectTo) return <Navigate to={redirectTo} />;

  return <Outlet />;
};

export default HandleRedirect;
