import React from "react";

import { twMerge } from "tailwind-merge";

import { cn } from "@/utils";

const Divider = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    classNameRoot?: string;
    classNameChild?: string;
    children?: React.ReactNode;
    onClick?: () => void;
  }
>(({ className, onClick, classNameRoot, classNameChild, children }, ref) => (
  <div className={twMerge("flex w-full items-center justify-center", classNameRoot)} ref={ref} onClick={onClick}>
    <hr className={twMerge("flex h-auto w-full border-b border-stroke-tertiary-on-bg", className)} />
    {children && <div className={twMerge("mx-4", classNameChild)}>{children}</div>}
    <hr className={twMerge("flex h-auto w-full border-b border-stroke-tertiary-on-bg ", className)} />
  </div>
));

Divider.displayName = "Divider";

const FormDivider = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <Divider
    className={cn("w-auto flex-1 gap-2.5", className)}
    classNameChild={cn("flex-2 mb-10 text-secondary-onBg py-1.5", className)}
  >
    {children}
  </Divider>
);

export { Divider, FormDivider };
