import { useMemo } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { demoUpdateDocument, fetchDocumentDataDemo } from "@/api/demo";
import { useProjectData } from "@/api/project.ts";
import { deleteRequest, getRequest, postRequest, putRequest } from "@/api/utils";
import { BACKEND_URL, ResourceCardType } from "@/assets/constants/constants";
import { DEMO_USER_ID, IDS, TEMPORARY_PROJECT_ID } from "@/assets/constants/demo.ts";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { type GenericFetchError } from "@/service/errors";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId, useIsDocumentPage } from "@/service/hooks/react-router";
import { type QueryConfig } from "@/types/react-query";
import {
  type ProjectDocumentRedisTicker,
  type ProjectEntity,
  type UpdateResourceElement,
  type DocumentEntity,
  type SubscriptionResponse,
  type BaseDocumentEntity,
  projectDocumentRedisTickerSchema,
  baseDocumentEntitySchemaDTO,
  extendedDocumentEntitySchemaDTO,
  subscriptionResponseSchema,
  type FolderElement,
} from "@/types/schemas";
import { useCurrentProjectId, validateZodSchema } from "@/utils";
import useAppStateStore from "@/zustand/store";

const DOCUMENTS = "/documents";

const fetchDocumentProcessingOfProjectStatus = (projectId: string) =>
  validateZodSchema(
    getRequest<ProjectDocumentRedisTicker[]>(`${BACKEND_URL}${DOCUMENTS}/document-processing-of-project/${projectId}`),
    z.array(projectDocumentRedisTickerSchema),
  );

const postRevectorizeDocument = (documentId: string): Promise<string> =>
  postRequest(`${BACKEND_URL}${DOCUMENTS}/revectorize-document/${documentId}`);

const postRegenerateCovertOfDocument = (documentId: string) =>
  postRequest(`${BACKEND_URL}${DOCUMENTS}/set-cover/${documentId}`);

const fetchDocumentData = async (
  workspaceId: string,
  documentId: string,
  openedAt?: "library" | "document_view",
): Promise<DocumentEntity> => {
  let url = `${BACKEND_URL}/documents/${workspaceId}/${documentId}`;
  if (openedAt) {
    url = `${url}?opened_at=${openedAt}`;
  }
  const data = await validateZodSchema<DocumentEntity>(getRequest(url), extendedDocumentEntitySchemaDTO);
  data.workspaceId = workspaceId;
  return data;
};
export const fetchTemporaryDocumentData = async (workspaceId: string, documentId: string): Promise<DocumentEntity> => {
  const url = `${BACKEND_URL}${DOCUMENTS}/temp-document/${documentId}`;

  const result = await getRequest<DocumentEntity>(url);

  const now = new Date().toISOString();

  return {
    id: result.id,
    name: result.name,
    projectId: TEMPORARY_PROJECT_ID,
    workspaceId: workspaceId,
    favourite: false,
    userId: DEMO_USER_ID,
    dateLastOpen: now,
    documentUrl: "asd",
    dateCreated: now,
    inheritedFromUrl: undefined,
    documentDetails: {
      id: result.id,
      sizeOfDocumentInMb: 2,
      errorMessage: undefined,
      documentId: result.id,
      dateFinishedProcessing: now,
      documentHealthLevel: "OK",
      dateStartedProcessing: now,
      processingStatus: "PROCESSED",
      numberOfPages: 10,
    },
  };
};
const updateDocument = ({
  documentId,
  workspaceId,
  payload,
}: {
  documentId: string;
  workspaceId: string;
  payload: UpdateResourceElement;
}): Promise<BaseDocumentEntity> =>
  validateZodSchema(
    putRequest(`${BACKEND_URL}/documents/${workspaceId}/${documentId}`, payload),
    baseDocumentEntitySchemaDTO,
  );

const deleteDocument = ({
  workspaceId,
  documentId,
}: {
  workspaceId: string;
  documentId: string;
}): Promise<DocumentEntity> =>
  validateZodSchema(
    deleteRequest(`${BACKEND_URL}/documents/${workspaceId}/${documentId}`),
    baseDocumentEntitySchemaDTO,
  );

const fetchMySubscription = async (): Promise<SubscriptionResponse> =>
  validateZodSchema(getRequest(`${BACKEND_URL}/subscriptions/my-subscription`), subscriptionResponseSchema);

export const useCurrentProjectDocumentIds = () => {
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  const { data: projectData } = useProjectData(workspaceId, projectId);

  return useMemo(() => {
    return (
      projectData?.directChildElements
        .filter((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT)
        .map((item) => item.id) ?? []
    );
  }, [projectData]);
};
export const useCurrentProjectDocumentDictionary = () => {
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  const { data: projectData } = useProjectData(workspaceId, projectId);

  return useMemo(() => {
    const documents =
      projectData?.directChildElements.filter((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT) ?? [];
    const result: Record<string, FolderElement> = {};

    documents.forEach((document) => {
      result[document.id] = document;
    });

    return result;
  }, [projectData]);
};
export const useProjectProcessingStatus = (projectId?: string, options?: QueryConfig<ProjectDocumentRedisTicker[]>) => {
  return useQuery({
    queryKey: ["project-processing-status", projectId],
    queryFn: () => fetchDocumentProcessingOfProjectStatus(projectId!),
    enabled: !!projectId && (options?.enabled !== undefined ? options.enabled : true),
    refetchInterval: 2000,
  });
};

export const useDocument = (workspaceId: string, documentId: string, options?: QueryConfig<DocumentEntity>) => {
  const isDocumentPage = useIsDocumentPage();
  const { matched: isDemoLike } = useIsDemoLikePage();
  return useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT, workspaceId, documentId],
    queryFn: () => {
      if (isDemoLike && Object.hasOwn(IDS.DOCUMENT, documentId)) {
        return fetchDocumentDataDemo(documentId);
      }
      if (isDemoLike) {
        return fetchTemporaryDocumentData(workspaceId, documentId);
      }
      return fetchDocumentData(workspaceId, documentId, isDocumentPage ? "document_view" : "library");
    },
    enabled: !!documentId,
    ...options,
  });
};

const useDeleteDocument = () => {
  const queryClient = useQueryClient();
  const unselectResource = useAppStateStore((state) => state.unselectResource);
  const currentWorkspaceId = useCurrentWorkspaceId();
  const activeProject = useCurrentProjectId();
  return useMutation({
    mutationFn: deleteDocument,
    onSuccess: (_, { documentId }) => {
      unselectResource(documentId);
      queryClient.setQueryData<ProjectEntity>([QUERY_KEYS.PROJECTS, currentWorkspaceId, activeProject], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          directChildElements: oldData.directChildElements.filter(({ id }) => documentId !== id),
        };
      });
    },
    onError: (e: GenericFetchError) => {
      if (e.payload.status === 403) {
        return toast({
          title: "You are not the owner of this document!",
          variant: "destructive",
        });
      }
      return toast({
        title: "Something went wrong.",
        variant: "destructive",
      });
    },
  });
};

const useUpdateDocument = () => {
  const queryClient = useQueryClient();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const currentProjectId = useCurrentProjectId();
  const { matched: isDemoLike } = useIsDemoLikePage();
  return useMutation({
    mutationFn: (props: Parameters<typeof updateDocument>[0]) =>
      isDemoLike ? demoUpdateDocument(props) : updateDocument(props),
    onSuccess: (result, variables) => {
      toast({ title: "Document updated", variant: "success" });

      console.log(result);

      queryClient.setQueryData<DocumentEntity>([QUERY_KEYS.DOCUMENT, currentWorkspaceId, result.id], (oldData) => {
        return oldData
          ? {
              ...oldData,
              ...result,
              documentDetails: result.documentDetails ? result.documentDetails : oldData.documentDetails,
              coverImageUrl: result.coverImageUrl ? result.coverImageUrl : oldData.coverImageUrl,
            }
          : oldData;
      });
      queryClient.setQueryData<ProjectEntity>(
        [QUERY_KEYS.PROJECTS, currentWorkspaceId, currentProjectId],
        (oldData) => {
          if (!oldData) return oldData;

          const idx = oldData.directChildElements.findIndex((item) => item.id === result.id);
          if (idx === -1) return oldData;
          const newArray = { ...oldData, directChildElements: [...oldData.directChildElements] };

          newArray.directChildElements[idx] = {
            ...newArray.directChildElements[idx],
            ...result,
            ...variables.payload,
          };
          return newArray;
        },
      );
    },
    onError: (e: GenericFetchError) => {
      if (e.payload.status === 403) {
        return toast({
          title: "You are not the owner of this document!",
          variant: "destructive",
        });
      }
      return toast({
        title: "Something went wrong.",
        variant: "destructive",
      });
    },
  });
};

const useReVectorizedDocument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postRevectorizeDocument,
    onSuccess: (_result, documentId) => {
      queryClient.setQueryData<DocumentEntity>([QUERY_KEYS.DOCUMENT, documentId], (oldData) =>
        oldData
          ? {
              ...oldData,
              documentDetails: {
                ...oldData.documentDetails,
                processingStatus: "UNDER_PROCESSING",
              },
            }
          : oldData,
      );
    },
  });
};

const useRegenerateCover = () => {
  return useMutation({
    mutationFn: postRegenerateCovertOfDocument,
  });
};

export {
  fetchDocumentData,
  deleteDocument,
  fetchMySubscription,
  useDeleteDocument,
  useUpdateDocument,
  useReVectorizedDocument,
  useRegenerateCover,
};
