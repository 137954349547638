import { useCallback, useMemo } from "react";

import { matchPath, useLocation, useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useDocument } from "@/api/document";
import { useNote } from "@/api/note";
import {
  DOCUMENT_TABS,
  NOTE_TABS,
  QUERY_PARAMS,
  ResourceCardType,
  ROUTES,
  type ViewOptions,
} from "@/assets/constants/constants";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { type FolderElement, type StoreBaseFolderElement } from "@/types/schemas";
import { useCurrentProjectId } from "@/utils";
import useAppStateStore from "@/zustand/store";

export const useOpenCardTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useCallback(
    (variant: "basic" | "multiple" | "true-false") => {
      searchParams.set(QUERY_PARAMS.NOTE_TAB, "add-card");
      searchParams.set(QUERY_PARAMS.FLASHCARD_TAB, variant);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );
};

export const useNoteTabState = () => useSearchParamsState(QUERY_PARAMS.NOTE_TAB, "summary");
export const useFlashCardTabState = () => useSearchParamsState(QUERY_PARAMS.FLASHCARD_TAB, "basic");

export const useCurrentDocumentTabId = () => {
  return useSearchParamsState(QUERY_PARAMS.DOCUMENT, "")[0];
};
export const useCurrentDocumentId = (): readonly [string, (newValue: string) => void] => {
  return useSearchParamsState(QUERY_PARAMS.DOCUMENT, "");
};
export const useCurrentWorkspaceId = () => {
  const { workspaceId } = useParams();

  return workspaceId ?? "";
};

export const useDocumentChatSessionId = (): readonly [string, (newValue: string) => void] => {
  return useSearchParamsState(QUERY_PARAMS.CHAT_SESSION, "");
};
export const useSetChatSessionWithTab = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (sessionId: string) => {
      params.set(QUERY_PARAMS.CHAT_SESSION, sessionId);
      params.set(QUERY_PARAMS.NOTE_TAB, "chat");
      setParams(params);
    },
    [params, setParams],
  );
};
export const useCurrentDocumentTabEntity = () => {
  const documentId = useCurrentDocumentTabId();
  const workspaceId = useCurrentWorkspaceId();
  return useDocument(workspaceId, documentId);
};
export const useCurrentDocPageNumber = (): number | undefined => {
  const documentId = useCurrentDocumentTabId();
  return useAppStateStore((state) => state.currentPage[documentId]);
};

export const useCurrentNoteId = (): [string, (value: string) => void] => {
  const [searchParam, setSearchParams] = useSearchParams();
  const [tab] = useSearchParamsState(QUERY_PARAMS.NOTE, "");
  const handleTabChange = useCallback(
    (value: string) => {
      searchParam.delete(QUERY_PARAMS.NOTE);
      searchParam.set(QUERY_PARAMS.NOTE, value);
      searchParam.set(QUERY_PARAMS.NOTE_TAB, "notes");
      setSearchParams(searchParam);
    },
    [searchParam, setSearchParams],
  );
  return [tab, handleTabChange];
};

export const useCurrentNote = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const [currentNoteId] = useCurrentNoteId();
  return useNote(currentWorkspaceId, currentNoteId);
};

export const useIsDocumentPage = (): boolean => {
  const match = useMatch("/documents/*");
  const matchTwo = useMatch("/demo/documents/*");
  const matchThree = useMatch("/temporary/documents/*");
  return useMemo(() => match !== null || matchTwo !== null || matchThree !== null, [match, matchTwo, matchThree]);
};
export const useIsSharedPage = (): boolean => {
  const { view } = useParams();
  const matchTwo = useMatch("/documents/shared/*");
  const mathcThree = useMatch("/knowledge-check/shared/*");
  return useMemo(() => view === "shared" || matchTwo !== null || mathcThree !== null, [view, matchTwo, mathcThree]);
};

export const useIsLibraryPage = (): boolean => {
  const match = useMatch("/library/*");
  const matchDemo = useMatch("/demo/library/*");
  return useMemo(() => match !== null || matchDemo !== null, [match, matchDemo]);
};

export function useSearchParamsState(
  searchParamName: string,
  defaultValue: string,
): readonly [searchParamsState: string, setSearchParamsState: (newState: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  useLocation();
  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState: string) => {
    const next = Object.assign(
      {},
      [...searchParams.entries()].reduce((o, [key, value]) => ({ ...o, [key]: value }), {}),
      { [searchParamName]: newState },
    );
    setSearchParams(next);
  };
  return [searchParamsState, setSearchParamsState];
}

export const useNavigateToDocumentPage = (): ((documentId: string, page: number, searchTarget?: string) => void) => {
  const setPdfViewerSearchTarget = useAppStateStore((state) => state.setPdfViewerSearchTarget);
  const [searchParams, setSearchParams] = useSearchParams();
  return useCallback(
    (documentId: string, page: number, searchTarget?: string) => {
      if (searchTarget)
        setPdfViewerSearchTarget({
          documentId,
          payload: {
            text: searchTarget,
            page: page,
            single: true,
          },
        });
      searchParams.set(QUERY_PARAMS.DOCUMENT, documentId);
      searchParams.set(QUERY_PARAMS.PAGE, page.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, setPdfViewerSearchTarget],
  );
};

const getPageValue = (value: string) => {
  const res = Number.parseInt(value);
  if (isNaN(res)) return undefined;
  if (res < 0) return undefined;
  return res;
};

export const usePageToOpenParam = (): [number, (value: string) => void] => {
  const documentId = useCurrentDocumentTabId();
  const currentPages = useAppStateStore((state) => state.currentPage);
  const [state, setState] = useSearchParamsState(QUERY_PARAMS.PAGE, "");
  const value = useMemo(() => {
    const value = getPageValue(state);
    if (value === undefined) {
      if (Object.hasOwn(currentPages, documentId)) return currentPages[documentId];
      return 0;
    }
    return value;
  }, [state, currentPages, documentId]);
  return [value, setState];
};

export const useResourceRedirectPath = ({
  resourceId,
  rootPath,
  type,
}: {
  resourceId: string;
  rootPath: string;
  type: ResourceCardType;
  viewParam?: ViewOptions;
}) => {
  const { route } = useIsDemoLikePage();
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    if (type === ResourceCardType.DOCUMENT_ELEMENT) {
      searchParams.set(QUERY_PARAMS.DOCUMENT, resourceId);
      const tabValue = searchParams.get(QUERY_PARAMS.NOTE_TAB);
      searchParams.set(QUERY_PARAMS.NOTE_TAB, tabValue ? tabValue : "summary");
      return [route, "documents", rootPath].join("/").concat(`?${searchParams.toString()}`);
    } else if (type === ResourceCardType.NOTE_ELEMENT) {
      searchParams.set(QUERY_PARAMS.NOTE, resourceId);
      const tabValue = searchParams.get(QUERY_PARAMS.NOTE_TAB);
      searchParams.set(QUERY_PARAMS.NOTE_TAB, tabValue ? tabValue : "summary");
      return [route, "documents", rootPath].join("/").concat(`?${searchParams.toString()}`);
    } else if (type === ResourceCardType.FOLDER_ELEMENT) {
      return resourceId;
    } else return "";
  }, [type, searchParams, resourceId, route, rootPath]);
};

export const useGoBack = () => {
  const navigate = useNavigate();
  return useCallback(() => navigate(-1), [navigate]);
};

export const useNavigateWithSelected = ({ resource }: { resource: FolderElement }) => {
  const redirectPath = useResourceRedirectPath({
    resourceId: resource.id,
    rootPath: resource.projectId,
    type: resource.elementType,
  });

  const projectId = useCurrentProjectId();
  const [selectedResources, setProjectTabState, unselectAllResources] = useAppStateStore((state) => [
    state.selectedResources,
    state.setProjectTabState,
    state.unselectAllResources,
  ]);

  const navigate = useNavigate();
  const mixpanelTrack = useMixpanelTrack();
  return useCallback(() => {
    if (!projectId) return;
    const firstDocument = selectedResources.find((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT);
    const allDocuments = selectedResources
      .filter((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT)
      .map((item) => item.id);
    const firstNote = selectedResources.find((item) => item.elementType === ResourceCardType.NOTE_ELEMENT);
    const allNotes = selectedResources
      .filter((item) => item.elementType === ResourceCardType.NOTE_ELEMENT)
      .map((item) => item.id);

    if (resource.elementType === ResourceCardType.NOTE_ELEMENT && !allNotes.includes(resource.id)) {
      mixpanelTrack("note_opened", { note_id: resource.id });
      allNotes.push(resource.id);
    } else if (resource.elementType === ResourceCardType.DOCUMENT_ELEMENT && !allDocuments.includes(resource.id)) {
      mixpanelTrack("document_opened", { document_id: resource.id });
      allDocuments.push(resource.id);
    }

    setProjectTabState({
      id: projectId,
      state: {
        documentTabs: allDocuments,
        noteTabs: allNotes,
        activeNoteTab:
          resource.elementType === ResourceCardType.NOTE_ELEMENT ? resource.id : firstNote?.id ?? NOTE_TABS.OPEN_NEW,
        activeDocumentTab:
          resource.elementType === ResourceCardType.DOCUMENT_ELEMENT
            ? resource.id
            : firstDocument?.id ?? DOCUMENT_TABS.OPEN_NEW,
      },
    });
    unselectAllResources();
    navigate(redirectPath);
  }, [
    projectId,
    selectedResources,
    resource.elementType,
    resource.id,
    setProjectTabState,
    unselectAllResources,
    navigate,
    redirectPath,
    mixpanelTrack,
  ]);
};

export const useIsChatTab = () => {
  const [tab] = useNoteTabState();
  return useMemo(() => tab === "chat", [tab]);
};

export const useSpecificSearchParam = (key: string) => {
  const [searchParams] = useSearchParams();

  return useMemo(() => searchParams.get(key), [key, searchParams]);
};

export const useNavigateToResource = ({ resource }: { resource: StoreBaseFolderElement }) => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const redirectPath = useResourceRedirectPath({
    resourceId: resource.id,
    rootPath: `${currentWorkspaceId}/${resource.projectId}`,
    type: resource.elementType,
  });

  const navigate = useNavigate();
  return useCallback(() => {
    navigate(redirectPath);
  }, [navigate, redirectPath]);
};

export const usePageTitle = () => {
  const location = useLocation();
  return useMemo(() => {
    if (matchPath(`${ROUTES.WORKSPACES}/*`, location.pathname)) return "Library";
    if (matchPath(`${ROUTES.HOME}/*`, location.pathname)) return "Home";
    if (matchPath(`${ROUTES.ACCOUNT}/*`, location.pathname)) return "Account";
    if (matchPath(`${ROUTES.SUBSCRIPTION}/*`, location.pathname)) return "Subscriptions";
    return "";
  }, [location]);
};
