import { useCallback, useEffect, useMemo, useRef } from "react";

import { useInterval } from "usehooks-ts";

import { useDocumentSummaryPaginated, useDocumentSummaryProcessingStatus } from "@/api/document-summary.ts";
import { useSubscription } from "@/api/resources.ts";
import { summarizationStatus } from "@/assets/constants/constants.ts";
import NotSummarizedState from "@/components/document/summary/not-summarized-state.tsx";
import Summarized from "@/components/document/summary/summarized.tsx";
import TemporarySummaryOverlay from "@/components/document/summary/temporary-summary-overlay";
import { ErrorScreen, NoDocumentView } from "@/components/document/summary/utils.tsx";
import PanelHeader from "@/components/note/common/panel-header.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { LoadingSpinner } from "@/pages/loading";
import { useDocumentProgress } from "@/service/hooks/documents.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentDocumentTabEntity, useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useDocumentProcessingData } from "@/service/web-viewer.ts";

const DocumentSummary = () => {
  // data setup
  const { data: subscription, refetch: refetchSubscription } = useSubscription();
  const { data: currentDocument, isLoading: isDocumentLoading } = useCurrentDocumentTabEntity();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const {
    data: { isError, isAiAllowed },
  } = useDocumentProcessingData(currentWorkspaceId, currentDocument?.id ?? "");

  const { data: summary, refetch, isLoading, fetchNextPage, error } = useDocumentSummaryPaginated(currentDocument?.id);
  const progressData = useDocumentProgress(currentDocument?.id);

  const observer = useRef<IntersectionObserver>();

  const scrollTarget = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && summary?.hasMore) {
          void fetchNextPage();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchNextPage, summary?.hasMore],
  );

  useEffect(() => {
    if (progressData?.percentage && progressData.percentage >= 99.5) {
      setTimeout(() => {
        void refetch();
      }, 500);
    }
  }, [progressData?.percentage, refetch]);

  const { data: documentSummaryProgress } = useDocumentSummaryProcessingStatus(currentDocument?.id, {
    enabled: summary?.summarizationStatus === summarizationStatus.enum.UNDER_SUMMARIZATION,
  });

  const isSummarized = useMemo(
    () =>
      documentSummaryProgress &&
      documentSummaryProgress.paragraphNumSummarization === documentSummaryProgress.maxParagraphNumSummarization,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      documentSummaryProgress,
      documentSummaryProgress?.paragraphNumSummarization,
      documentSummaryProgress?.maxParagraphNumSummarization,
    ],
  );

  useEffect(() => {
    if (isSummarized) {
      void refetch();
    }
  }, [isSummarized, refetch]);

  useInterval(
    () => {
      if (isSummarized) {
        void refetch();
        void refetchSubscription();
      }
    },
    isSummarized && summary?.summarizationStatus !== summarizationStatus.enum.SUMMARIZED ? 2000 : null,
  );

  const content = useMemo(() => {
    if (isDocumentLoading || isLoading)
      return (
        <section className="flex h-full w-full flex-col items-start justify-start gap-6 overflow-hidden px-6">
          <section className="flex w-full flex-col gap-4">
            <section className="h-6 w-full">
              <Skeleton className="h-6 w-4/12"></Skeleton>
            </section>
            <section className="flex w-full flex-col items-start justify-start gap-2">
              <Skeleton className="h-4 w-[95%]"></Skeleton>
              <Skeleton className="h-4 w-full"></Skeleton>
              <Skeleton className="h-4 w-[90%]"></Skeleton>
              <Skeleton className="h-4 w-[93%]"></Skeleton>
            </section>
          </section>
          <section className="flex w-full flex-col gap-4">
            <section className="h-6 w-full">
              <Skeleton className="h-6 w-4/12"></Skeleton>
            </section>
            <section className="flex w-full flex-col items-start justify-start gap-2">
              <Skeleton className="h-4 w-[95%]"></Skeleton>
              <Skeleton className="h-4 w-full"></Skeleton>
              <Skeleton className="h-4 w-[90%]"></Skeleton>
              <Skeleton className="h-4 w-[93%]"></Skeleton>
            </section>
          </section>
          <section className="flex w-full flex-col gap-4">
            <section className="h-6 w-full">
              <Skeleton className="h-6 w-4/12"></Skeleton>
            </section>
            <section className="flex w-full flex-col items-start justify-start gap-2">
              <Skeleton className="h-4 w-[95%]"></Skeleton>
              <Skeleton className="h-4 w-full"></Skeleton>
              <Skeleton className="h-4 w-[90%]"></Skeleton>
              <Skeleton className="h-4 w-[93%]"></Skeleton>
            </section>
          </section>
          <section className="flex w-full flex-col gap-4">
            <section className="h-6 w-full">
              <Skeleton className="h-6 w-4/12"></Skeleton>
            </section>
            <section className="flex w-full flex-col items-start justify-start gap-2">
              <Skeleton className="h-4 w-[95%]"></Skeleton>
              <Skeleton className="h-4 w-full"></Skeleton>
              <Skeleton className="h-4 w-[90%]"></Skeleton>
              <Skeleton className="h-4 w-[93%]"></Skeleton>
            </section>
          </section>
          <section className="flex w-full flex-col gap-4">
            <section className="h-6 w-full">
              <Skeleton className="h-6 w-4/12"></Skeleton>
            </section>
            <section className="flex w-full flex-col items-start justify-start gap-2">
              <Skeleton className="h-4 w-[95%]"></Skeleton>
              <Skeleton className="h-4 w-full"></Skeleton>
              <Skeleton className="h-4 w-[90%]"></Skeleton>
              <Skeleton className="h-4 w-[93%]"></Skeleton>
            </section>
          </section>
        </section>
      );
    if (currentDocument === null || currentDocument === undefined) return <NoDocumentView />;
    if (isError) return <ErrorScreen />;

    if (summary && summary.summarizationStatus === summarizationStatus.enum.SUMMARIZED)
      return <Summarized scrollTarget={scrollTarget} summary={summary} />;

    return (
      <NotSummarizedState
        document={currentDocument}
        documentProcessingProgress={progressData}
        error={error as unknown as Response}
        isAiAllowed={isAiAllowed}
        subscription={subscription}
        summary={summary}
        summaryProgress={documentSummaryProgress}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(summary),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(currentDocument),
    isError,
    summary?.language,
    summary?.summarizationStatus,
    progressData?.processing,
    isAiAllowed,
    subscription,
    documentSummaryProgress?.paragraphNumSummarization,
  ]);

  return (
    <section className="flex h-full flex-col overflow-hidden">
      <section className="relative flex h-full flex-col overflow-hidden">
        <PanelHeader title={summary?.title ?? "Full Document Summary"} />
        {content}
      </section>
      <TemporarySummaryOverlay documentId={currentDocument?.id ?? ""} />
    </section>
  );
};

const DocumentSummaryWrapper = () => {
  const { data: subscription, isLoading, isError } = useSubscription();
  const { matched: isDemo } = useIsDemoLikePage();
  if (isDemo) return <DocumentSummary />;
  if (isError) return <div>Something went wrong!</div>;
  if (isLoading) return <LoadingSpinner />;
  if (!subscription) return null;
  return <DocumentSummary />;
};

export default DocumentSummaryWrapper;
