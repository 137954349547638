import { useState } from "react";

export default class AbortRequestService {
  public static instance: AbortController;

  static {
    AbortRequestService.instance = new AbortController();
  }

  static createController(): AbortController {
    AbortRequestService.instance = new AbortController();
    return AbortRequestService.instance;
  }

  static get(): AbortController {
    if (!AbortRequestService.instance) {
      AbortRequestService.instance = new AbortController();
    }
    return AbortRequestService.instance;
  }

  public static abortRequest(): AbortController {
    AbortRequestService.instance.abort();
    AbortRequestService.instance = new AbortController();
    return AbortRequestService.instance;
  }
}

export function useAbortController(): {
  controller: AbortController;
  abort: () => void;
} {
  const [controller, setController] = useState(AbortRequestService.instance);

  function abort(): void {
    controller.abort();
    const newController = new AbortController();
    setController(newController);
    AbortRequestService.instance = newController;
  }

  return { controller, abort };
}
