import * as React from "react";

import { cn } from "@/utils";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        "flex min-h-[80px] w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm text-primary ring-offset-white placeholder:text-neutral-500 focus-visible:border-tertiary-default focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:border-white dark:bg-primary-dark dark:text-white",
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = "Textarea";

export { Textarea };
