import { Link } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import EmptyActivityFeedIllustration from "@/assets/svgs/v3/empty-activity-feed.svg";
import { FilterIcon } from "@/components/icons";
import { Button } from "@/components/ui/button.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { LibraryIcon } from "@/components/v3";

const EmptyActivityFeed = () => {
  return (
    <>
      <section className="flex flex-row items-center justify-start gap-4">
        <Typography className="font-bold" size="large">
          Activity feed
        </Typography>
        <Button disabled={true} size="xs" variant="ghost">
          <FilterIcon className="h-4 w-4 fill-default" /> Show filters
        </Button>
      </section>

      <section className="flex w-full flex-row items-center justify-center gap-12 rounded-lg border border-dashed border-stroke-default py-20">
        <section>
          <img alt="Empty activity feed" src={EmptyActivityFeedIllustration} />
        </section>
        <section className="flex max-w-[19rem] flex-col gap-6 ">
          <section className="flex flex-col gap-1">
            <Typography className="font-bold" size="large">
              Make learning collaborative with shared workspaces
            </Typography>
            <Typography variant="secondary">Invite your friends to a workspace and start learning together.</Typography>
          </section>
          <section className="flex flex-row justify-start">
            <Link to={ROUTES.WORKSPACES}>
              <Button>
                Open library <LibraryIcon className="fill-white" />
              </Button>
            </Link>
          </section>
        </section>
      </section>
    </>
  );
};

export default EmptyActivityFeed;
