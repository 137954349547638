import QuinoChatForm from "@/components/note/chat/quino-chat-form.tsx";
import QuinoChatHeader from "@/components/note/chat/quino-chat-header.tsx";
import QuinoChatList from "@/components/note/chat/quino-chat-list.tsx";

const QuinoChat = () => {
  return (
    <section className="relative flex h-full flex-col">
      <QuinoChatHeader />
      <QuinoChatList />
      <QuinoChatForm />
    </section>
  );
};

export default QuinoChat;
