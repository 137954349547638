import { z } from "zod";

import { projectEntitySchema } from "@/types/schemas";

export const workspaceCreateSchema = z.object({
  name: z.string(),
  coverImage: z.instanceof(File).nullish(),
});
export type WorkspaceCreateSchema = z.infer<typeof workspaceCreateSchema>;
export type WorkspaceUpdateSchema = z.infer<typeof workspaceCreateSchema>;

export const workspaceSchemaBase = z.object({
  id: z.string(),
  name: z.string(),
  adminId: z.string(),
  imageUrl: z.string().nullish(),
});

export type BaseWorkspace = z.infer<typeof workspaceSchemaBase>;

export const workspaceListItemSchema = workspaceSchemaBase.extend({
  numOfUsers: z.number(),
  numOfProjects: z.number(),
});

export const workspaceListItemSchemaList = z.array(workspaceListItemSchema);

export type WorkspaceListItem = z.infer<typeof workspaceListItemSchema>;

export const workspaceSchema = workspaceSchemaBase.extend({
  userIds: z.array(z.string()),
  projects: z.array(projectEntitySchema),
  dateCreated: z.string(),
  isPremium: z.boolean(),
});

export type WorkspaceEntity = z.infer<typeof workspaceSchema>;
