import {
  HighSchool,
  University,
  PhdResearch,
  Professional,
  HealthAndMedicine,
  ArtAndDesign,
  FinanceAndEconomics,
  Psychology,
  Education,
  SalesAndMarketing,
  Instagram,
  TikTok,
  FriendsAndFamily,
  Other,
} from "@/components/v3";
import { type OnboardingStep } from "@/types/app.ts";

export const occupationOptions = [
  {
    id: "highschool",
    text: "Highschool student",
    icon: <HighSchool />,
  },
  {
    id: "university",
    text: "University Student",
    icon: <University />,
  },
  { id: "phd-research", text: "Phd / Researcher", icon: <PhdResearch /> },
  { id: "professional", text: "Professional", icon: <Professional /> },
];

export const fieldOfOccupationOptions = [
  {
    id: "medicine-biology",
    text: "Health and Medicine",
    icon: <HealthAndMedicine />,
  },
  {
    id: "art-design",
    text: "Art and design",
    icon: <ArtAndDesign />,
  },
  { id: "finance-economics", text: "Finance and Economics", icon: <FinanceAndEconomics /> },
  { id: "psychology", text: "Psychology", icon: <Psychology /> },
  { id: "education", text: "Education", icon: <Education /> },
  { id: "sales-and-marketing", text: "Sales and Marketing", icon: <SalesAndMarketing /> },
];

export const howDoYouKnowUsOptions = [
  {
    id: "instagram",
    text: "Instagram",
    icon: <Instagram />,
  },
  {
    id: "tiktok",
    text: "TikTok",
    icon: <TikTok />,
  },
  { id: "friends", text: "Friends", icon: <FriendsAndFamily /> },
  { id: "other", text: "Other", icon: <Other /> },
];

export const onboardingSteps: OnboardingStep[] = [
  {
    id: "occupation",
    title: "What is your occupation?",
    description: "Select your study level or the professional option if you use Quino for work.",
    options: occupationOptions,
  },
  {
    id: "fieldOfOccupation",
    title: "Which of the following best describe your field of work or study?",
    description: "Select the closest option to your field of study or professional field.",
    options: fieldOfOccupationOptions,
  },
  {
    id: "howDoYouKnowUs",
    title: "Where did you hear from Quino?",
    description: "Where did you hear about us? Please select from the options below.",
    options: howDoYouKnowUsOptions,
  },
  {
    id: "country",
    title: "Where are you from?",
    description: "Please choose the country you are from.",
  },
];
