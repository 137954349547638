import { useCallback, useState } from "react";

import { useDeleteSession, useUpdateSession } from "@/api/document-chat";
import QuinoEditableItem from "@/components/v3/note/editabel-command-item.tsx";
import { useDocumentChatSessionId } from "@/service/hooks/react-router.ts";
import { type PlainDocChatSession } from "@/types/schemas";

const QuinoSessionHistoryItem = ({
  session,
  onCloseCallback,
}: {
  session: PlainDocChatSession;
  onCloseCallback: () => void;
}) => {
  const { mutate: onDelete } = useDeleteSession();
  const { mutate: onUpdate } = useUpdateSession();
  const [initialName, setInitialName] = useState(`${session.name}`);
  const [currentSessionId, setSessionId] = useDocumentChatSessionId();
  const [state, setState] = useState<{ inputValue: string }>({
    inputValue: `${session.name}`,
  });
  const setSession = useCallback(() => {
    setSessionId(session.id);
    onCloseCallback();
  }, [setSessionId, session.id, onCloseCallback]);

  const deleteSession = useCallback(() => {
    onDelete({ sessionId: session.id });
  }, [onDelete, session.id]);

  const updateSession = useCallback(() => {
    setInitialName(state.inputValue);
    onUpdate({ sessionId: session.id, payload: { name: state.inputValue } });
  }, [onUpdate, state.inputValue, session.id]);

  return (
    <QuinoEditableItem
      cancelEdit={() => {
        setState((prev) => ({ ...prev, inputValue: initialName }));
      }}
      dateString={session.dateCreated}
      inputValue={state.inputValue}
      selected={currentSessionId === session.id}
      onChange={(e) => setState((prev) => ({ ...prev, inputValue: e.target.value }))}
      onClick={setSession}
      onExecuteDelete={deleteSession}
      onSave={() => {
        if (state.inputValue) updateSession();
      }}
    />
  );
};

export default QuinoSessionHistoryItem;
