import { useMemo } from "react";

import UserAvatar from "@/components/user/user-avatar";
import { useFirebaseUserId, useUserPublicData } from "@/firebase/hooks";
import { LoadingSpinner } from "@/pages/loading";
import { getAvatarFallback } from "@/service/user.ts";

export const SharedContactCard = ({ userId }: { userId: string }) => {
  const currentUserId = useFirebaseUserId();
  const [publicDataResponse, loading] = useUserPublicData({ userId });

  const publicData = useMemo(() => {
    const data = publicDataResponse?.data();
    return {
      photoUrl: data?.photoUrl,
      avatarFallback: getAvatarFallback(data?.displayName),
      displayName: data?.displayName,
    };
  }, [publicDataResponse]);

  if (loading) {
    return <LoadingSpinner className="relative left-0 h-4 w-4" />;
  }
  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center justify-center gap-2 text-base text-primary/75">
        <UserAvatar userId={userId} />
        <p>{publicData.displayName}</p>
      </div>
      <p className="text-sm text-primary/35">{currentUserId === userId ? "Owner" : "Viewer"}</p>
    </div>
  );
};
