import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { useAuthUserData, useMyAvatarFallback } from "@/firebase/hooks.ts";
import { formatWithUTC } from "@/service/time-ago.ts";
import { cn } from "@/utils";

const QuinoQuestion = ({
  description,
  className,
  date,
}: {
  description: string;
  className?: string;
  date?: string;
}) => {
  const { data: user } = useAuthUserData();
  const avatarFallback = useMyAvatarFallback();
  return (
    <section
      className={cn(
        "w-full rounded-2xl p-2 pb-3 animate-in fade-in-0 slide-in-from-right-2 dark:bg-primary-dark",
        className,
      )}
    >
      <section className="flex w-full flex-row items-center gap-3 pb-2">
        <section className="flex h-full flex-col self-start">
          <Avatar className="h-8 w-8">
            <AvatarImage alt="user-image" src={user?.photoUrl ?? ""} />
            <AvatarFallback fallbackColorIndex={avatarFallback.fallbackColorIndex}>
              {avatarFallback.monogram}
            </AvatarFallback>
          </Avatar>
        </section>
        <section className="flex w-full flex-col">
          <section className="flex flex-row items-center justify-between gap-2">
            <Typography className="text-start font-bold text-secondary-onBg" size="extra-small">
              {user?.displayName}
            </Typography>
            {date && (
              <Typography className="text-secondary-onBg" size="extra-small">
                {formatWithUTC(date)}
              </Typography>
            )}
          </section>
          <Typography className="mt-1 break-normal font-bold text-primary" size="small">
            {description}
          </Typography>
        </section>
      </section>
    </section>
  );
};
export default QuinoQuestion;
