import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const BulletIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 4 4"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-1 w-1 fill-primitive-grey-600", props.className)}
  >
    <circle cx="2" cy="2" fill="#7B7382" r="2" />
  </svg>
);
export default BulletIcon;
