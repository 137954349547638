export const FIRESTORE_USER_COLLECTION_PATH = "users";
export const FIRESTORE_STRIPE_PLANS_COLLECTION_PATH = "plans";
export const PASSWORD_PROVIDER_NAME = "password";
export const EMAIL_VERIFIED_FIELD_NAME = "email_verified";

export const FLASHCARD_IMAGE_BUCKET =
  process.env.NODE_ENV === "production"
    ? `${import.meta.env.VITE_GCP_PROJECT_ID}-flashcard-images`
    : "flashcard_images";

export const NOTE_PARAGRAPH_IMAGE_BUCKET = `${import.meta.env.VITE_GCP_PROJECT_ID}-note-images`;
