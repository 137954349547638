import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { useCreateSession } from "@/api/flash-cards";
import { SessionVariant } from "@/assets/constants/flashcard.ts";
import { ModalKeys } from "@/assets/constants/modal";
import { Spinner } from "@/components/icons/common";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Typography } from "@/components/ui/typography.tsx";
import { useDeckContext } from "@/pages/knowledge-check/deck/context";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { type AllowedCardTypes } from "@/types/interfaces";
import { literalToEnum } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice";

const order = [
  {
    value: "random",
    name: "Random",
  },
  {
    value: "leitner",
    name: "Based on performance",
  },
  {
    value: "chronological",
    name: "Chronologically",
  },
];

const formSchema = z.object({
  lengthOfSession: z.number().min(1, { message: "At least 1 card!" }).max(100, { message: "At most 100 is allowed!" }),
  order: z.union([z.literal("random"), z.literal("leitner"), z.literal("chronological")]),
  types: z
    .object({
      all: z.boolean(),
      basic: z.boolean(),
      trueFalse: z.boolean(),
      multiSelect: z.boolean(),
    })
    .refine((data) => data.all || data.basic || data.trueFalse || data.multiSelect, {
      path: ["types"],
      message: "At least one option must be selected",
    }),
});

type FormSchema = z.infer<typeof formSchema>;

const getDataFromCheckboxSelection = (data: FormSchema): string => {
  if (data.types.all) return ["multiple_choice_card", "basic_flashcard", "true_false_card"].join(",");
  const arrayOfResponses: AllowedCardTypes[] = [];
  if (data.types.basic) arrayOfResponses.push("basic_flashcard");
  if (data.types.multiSelect) arrayOfResponses.push("multiple_choice_card");
  if (data.types.trueFalse) arrayOfResponses.push("true_false_card");
  return arrayOfResponses.join(",");
};

const EvaluationSession = () => {
  const context = useDeckContext();
  const { matched: isDemoPage } = useIsDemoLikePage();
  const [isOpen, setModalState] = useModalState(ModalKeys.CREATE_EVALUATION_SESSION_DIALOG);
  const { deckId } = useParams();
  const mixpanelTrack = useMixpanelTrack();
  const { mutate: startKnowledgeSession, isPending } = useCreateSession({
    onSuccess: (result, variables) => {
      if (isDemoPage)
        mixpanelTrack("evaluation_session_started", {
          eval_session_id: result.id,
          length_of_session: result.numOfCards,
          ordering: variables.payload.ordering_method,
          cardtypes: variables.payload.allowedCardTypes,
        });

      context.setSession({
        ...result,
        isAd: false,
        currentCardIndex: 0,
        done: false,
        currentStart: new Date().toISOString(),
        cards: [{ ...result.initialCard }],
        multipleChoiceSelectedAnswer: {},
      });
      handleClose();
    },
  });

  const form = useForm<FormSchema>({
    defaultValues: {
      order: "random",
      types: {
        all: true,
        basic: false,
        trueFalse: false,
        multiSelect: false,
      },
    },
    mode: "onBlur",
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    form.reset();
  }, [isOpen, form]);

  const handleClose = useCallback(() => setModalState(false), [setModalState]);

  const handleSubmit = useCallback(
    (data: FormSchema) => {
      if (!deckId) return;

      startKnowledgeSession({
        deckId,
        payload: {
          type: SessionVariant.EVALUATION,
          ordering_method: literalToEnum(data.order),
          numOfCards: data.lengthOfSession,
          allowedCardTypes: getDataFromCheckboxSelection(data),
          startDate: new Date().toISOString(),
        },
      });
    },
    [startKnowledgeSession, deckId],
  );
  const watched = form.watch("types");

  return (
    <Dialog open={isOpen} onOpenChange={setModalState}>
      <DialogContent className="max-w-2xl gap-6">
        <DialogHeader>
          <DialogTitle>Evaluation Session</DialogTitle>
        </DialogHeader>
        <section
          className="flex flex-row items-center justify-center data-[hidden=true]:hidden"
          data-hidden={!isPending}
        >
          <Spinner className="h-10 w-10" />
        </section>
        <Form {...form}>
          <form
            className="block space-y-6 data-[hidden=true]:hidden"
            data-hidden={isPending}
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <section className="grid grid-cols-2 gap-4">
              <section className="flex w-full flex-col">
                <FormField
                  control={form.control}
                  name="lengthOfSession"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base font-bold">Cards</FormLabel>
                      <FormControl>
                        <Input
                          className="w-full placeholder:font-semibold placeholder:text-primary/30 dark:placeholder:text-white/30"
                          placeholder="Length of session"
                          type="number"
                          {...field}
                          onChange={(event) =>
                            field.onChange(event.target.value === "" ? undefined : parseInt(event.target.value, 10))
                          }
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="order"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base font-bold">Order</FormLabel>
                      <Select defaultValue={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className="font-semibold text-primary">
                            <SelectValue className="font-bold" placeholder="Select and ordering" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {order.map((item) => (
                            <SelectItem className="text-primary" key={item.value} value={item.value}>
                              {item.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </section>
              <section>
                <section className="flex flex-col items-start gap-4 rounded-2xl bg-primary/10 p-6 dark:bg-white/10">
                  <Typography asChild size="small">
                    <h1 className="font-bold">Types of Cards</h1>
                  </Typography>
                  <FormField
                    control={form.control}
                    name="types.all"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={watched?.all ?? false}
                              onCheckedChange={(checked) => {
                                if (checked) {
                                  form.setValue("types.trueFalse", false);
                                  form.setValue("types.multiSelect", false);
                                  form.setValue("types.basic", false);
                                }
                                field.onChange(checked);
                                void form.trigger("types");
                              }}
                            />
                          </FormControl>
                          <FormLabel className="text-base font-bold">All</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="types.basic"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={watched?.basic ?? false}
                              onCheckedChange={(checked) => {
                                if (checked) {
                                  form.setValue("types.all", false);
                                }
                                field.onChange(checked);
                                void form.trigger("types");
                              }}
                            />
                          </FormControl>
                          <FormLabel className="text-base font-bold">Basic Flashcards</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="types.multiSelect"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={watched?.multiSelect ?? false}
                              onCheckedChange={(checked) => {
                                if (checked) {
                                  form.setValue("types.all", false);
                                }
                                field.onChange(checked);
                                void form.trigger("types");
                              }}
                            />
                          </FormControl>
                          <FormLabel className="text-base font-bold">Multiple Choice</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="types.trueFalse"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={watched?.trueFalse ?? false}
                              onCheckedChange={(checked) => {
                                if (checked) {
                                  form.setValue("types.all", false);
                                }
                                field.onChange(checked);
                                void form.trigger("types");
                              }}
                            />
                          </FormControl>
                          <FormLabel className="text-base font-bold">True/False</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                </section>
              </section>
            </section>
            <DialogFooter className="mt-6">
              <Button type="button" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={!form.formState.isValid} type="submit">
                Start Session
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EvaluationSession;
