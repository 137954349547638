import { useEffect, useMemo, useState } from "react";

import { useDebounceValue } from "usehooks-ts";

import { GlobeIcon } from "@/components/icons/summary";
import { Select, SelectContent, SelectContentNoPortal, SelectItem, SelectTrigger } from "@/components/ui/select.tsx";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useSidebarLayoutOpen } from "@/service/library.ts";
import { mixpanelTrack } from "@/service/mixpanel";
import { useUserAiLanguage } from "@/service/user.ts";
import { SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES_LABELS } from "@/types/interfaces/library.tsx";
import { cn } from "@/utils";

const AiLanguageSwitcher = ({
  mixpanelLocation,
  style = "base",
}: {
  mixpanelLocation: "sidebar" | "fds_page";
  style?: "base" | "minimalist";
}) => {
  const [language, setLanguage] = useUserAiLanguage();
  const [openSelect, setOpenSelect] = useState(false);
  const [sidebarOpen] = useSidebarLayoutOpen();

  const [sidebarOpenValue] = useDebounceValue(sidebarOpen, 200);

  const handleValueChange = (value: string) => {
    mixpanelTrack("ai_language_changed", { from: language, to: value, where: mixpanelLocation });
    setLanguage(value as SUPPORTED_LANGUAGES);
  };

  useEffect(() => {
    if (!sidebarOpenValue) setOpenSelect(false);
  }, [sidebarOpenValue]);

  const selectValues = useMemo(
    () =>
      Object.values(SUPPORTED_LANGUAGES).map((languageValue) => (
        <SelectItem
          className="mt-1 w-full cursor-pointer rounded-lg py-2.5 hover:bg-container-tertiary focus:bg-container-tertiary data-[state=checked]:bg-container-tertiary data-[state=checked]:font-bold "
          key={languageValue}
          value={languageValue}
        >
          <div className="flex w-full flex-row items-center gap-2">
            <div className="w-4 overflow-hidden rounded-sm">{SUPPORTED_LANGUAGES_LABELS[languageValue].icon}</div>
            <span className="text-sm text-primary">{SUPPORTED_LANGUAGES_LABELS[languageValue].label}</span>
          </div>
        </SelectItem>
      )),
    [],
  );
  const triggerStyle = useMemo(() => {
    return "bg-transparent !text-label-l3 font-bold text-primary bg-white";
  }, []);

  const { matched: isDemo } = useIsDemoLikePage();
  if (style === "minimalist")
    return (
      <Select
        disabled={isDemo}
        open={openSelect}
        value={language}
        onOpenChange={setOpenSelect}
        onValueChange={(value) => handleValueChange(value)}
      >
        <SelectTrigger
          hideArrow
          className={cn(
            triggerStyle,
            "w-auto border-0 bg-transparent p-0 transition-all hover:bg-white/50 data-[state=open]:bg-white",
          )}
          id="sidebar-ai-language-selector"
        >
          <div className="flex items-center gap-1 px-2 text-base/4 text-primary/75">
            <span className="group mr-1 text-end font-jakarta text-sm font-bold text-default">
              {SUPPORTED_LANGUAGES_LABELS[language]?.label}
            </span>
            <span className="w-4 overflow-hidden rounded-sm">
              <GlobeIcon className="h-4 w-4 fill-default" />
            </span>
          </div>
        </SelectTrigger>
        <SelectContent
          align="end"
          className="z-[2000] w-60 rounded-xl border border-primary-purple-dim px-0 py-0"
          side="top"
        >
          <h2 className="px-2 py-4 font-jakarta text-xs font-bold text-primitive-grey-800">
            Preferred language for AI
          </h2>
          {selectValues}
        </SelectContent>
      </Select>
    );
  return (
    <Select
      disabled={isDemo}
      open={openSelect}
      value={language}
      onOpenChange={setOpenSelect}
      onValueChange={(value) => handleValueChange(value)}
    >
      <SelectTrigger className={triggerStyle} id="sidebar-ai-language-selector">
        <div className="flex items-center gap-1 px-2 text-base/4 text-primary/75">
          <div className="flex items-center gap-2">
            <span className="w-4 overflow-hidden rounded-sm">{SUPPORTED_LANGUAGES_LABELS[language]?.icon}</span>
            <p className="font-bold text-primary/50">AI: </p>
          </div>
          <span className="group min-w-[60px]">{SUPPORTED_LANGUAGES_LABELS[language]?.label}</span>
        </div>
      </SelectTrigger>
      <SelectContentNoPortal
        className="rounded-xl border border-primary-purple-dim px-0 py-0"
        side="top"
        viewportClassName="py-3 px-3"
      >
        {selectValues}
      </SelectContentNoPortal>
    </Select>
  );
};

export default AiLanguageSwitcher;
