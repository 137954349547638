import { GoogleIcon } from "@/components/icons/common";
import { Button } from "@/components/ui/button";
import { signUpWithGoogle } from "@/firebase";
import { cn } from "@/utils";

const GoogleLogin = ({ className }: { className?: string }) => (
  <Button
    className={cn(
      "h-12 w-full bg-secondary-container-default px-5 py-4 text-label-l3 text-secondary-text-default",
      className,
    )}
    id="log-in-with-google-icon"
    variant="secondary"
    onClick={() => void signUpWithGoogle()}
  >
    <GoogleIcon className="h-6 w-6" />
    Continue with Google
  </Button>
);

export default GoogleLogin;
