import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button.tsx";
import { Command, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { CommandPopoverIcon } from "@/components/v3/icons";
import QuinoNoteSelectItem from "@/components/v3/note/quino-note-select-item.tsx";
import { useCurrentNote, useCurrentNoteId } from "@/service/hooks/react-router.ts";
import { useNotesFromCurrentProject } from "@/service/note";

const NoteSelector = () => {
  const [noteId, setNoteId] = useCurrentNoteId();
  const { data: currentNote } = useCurrentNote();
  const { notes } = useNotesFromCurrentProject();
  const [{ open }, setState] = useState({ open: false });

  useEffect(() => {
    if (!noteId && notes && notes.length > 0) {
      setNoteId(notes[0].id);
    }
  }, [noteId, notes, setNoteId]);

  return (
    <Popover open={open} onOpenChange={(open) => setState((prev) => ({ ...prev, open }))}>
      <PopoverTrigger asChild>
        <Button className="flex-1 justify-center truncate rounded-none border-0" variant="outline">
          <span className="h-4 w-4">
            <CommandPopoverIcon />
          </span>
          <p className="truncate">{currentNote?.name}</p>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="center" alignOffset={8} className="w-96 p-2" sideOffset={16}>
        <Command>
          <CommandInput className="text-primary dark:text-white" placeholder="Search" />
          <CommandGroup className="mt-4 max-h-40 overflow-y-auto">
            {notes.map((note) => {
              return (
                <CommandItem
                  className="group mt-1 flex flex-col items-start gap-2 overflow-hidden rounded-lg p-0 text-primary first:mt-0 aria-selected:bg-container-tertiary aria-selected:text-primary data-[selected-session=true]:bg-container-tertiary dark:text-white"
                  data-selected={note.id === currentNote?.id}
                  key={note.id}
                  value={note.name}
                  onSelect={() => {
                    setNoteId(note.id);
                    setState((prev) => ({ ...prev, open: false }));
                  }}
                >
                  <QuinoNoteSelectItem
                    noteElement={note}
                    onCloseCallback={() => setState((prev) => ({ ...prev, open: false }))}
                  />
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default NoteSelector;
