import { useMemo, useState } from "react";

import { useStripePlanFeatures } from "@/api/subscription.ts";
import { PlanCard } from "@/components/subscription";
import { useAppAuthState } from "@/firebase/hooks.ts";
import { handlePaymentRedirect, portalCreateHandler } from "@/service/subscription";
import type { PlanDescription, StripePlanPeriod } from "@/types/schemas";
import { cn } from "@/utils";

const SubscriptionPlanList = ({ headerClassName }: { noFree?: boolean; headerClassName?: string }) => {
  const [planPeriod, setPlanPeriod] = useState<StripePlanPeriod>("yearly");
  const [user] = useAppAuthState();
  const { data: stripePlans } = useStripePlanFeatures();
  const [loading, setLoading] = useState(false);
  const planClickHandler = (plan: PlanDescription): void => {
    setLoading(true);
    if (plan.priceId === "free" || !user) return;
    void handlePaymentRedirect({ priceId: plan.priceId, user });
  };

  const mappedPlans = useMemo<PlanDescription[]>(() => {
    if (!stripePlans) return [];
    return stripePlans
      .filter((plan) => plan.type === planPeriod || plan.firebaseRole === "free" || plan.firebaseRole === "enterprise")
      .map((plan) => {
        const res: PlanDescription = {
          firebaseRole: plan.firebaseRole,
          monthlyPrice:
            plan.type === "yearly"
              ? stripePlans.find((item) => {
                  return item.firebaseRole.startsWith("pro") && item.type === "monthly";
                })?.monthlyPrice ?? 0
              : plan.monthlyPrice,
          highlighted: plan.firebaseRole.startsWith("pro"),
          showArrow: plan.firebaseRole.startsWith("pro"),
          price: plan.monthlyPrice,
          name: plan.name,
          priceId: plan.priceId,
          title: plan.name,
          subTitle: plan.subtitle ?? "Boost your study sessions",
          description:
            plan.firebaseRole === "enterprise"
              ? "Annual billing"
              : plan.type === "yearly"
              ? "Per month, billed yearly"
              : "Per month",
        };
        return res;
      });
  }, [planPeriod, stripePlans]);

  return (
    <>
      <div className={cn("absolute right-0 top-0 flex items-center gap-4", headerClassName)}>
        <h4 className="text-sm text-secondary-onBg">Billing period</h4>
        <div className="flex gap-1">
          <button
            className="rounded-[100px] border border-stroke-secondary-onBg bg-transparent px-5 py-4 text-label-l3-regular font-bold text-tertiary-onBg data-[state=monthly]:border-stroke-primary-onBg data-[state=monthly]:text-primary-onBg"
            data-state={planPeriod}
            onClick={() => setPlanPeriod("monthly")}
          >
            Monthly
          </button>
          <button
            className="rounded-[100px] border border-stroke-secondary-onBg bg-transparent px-5 py-4 text-label-l3-regular font-bold text-tertiary-onBg data-[state=yearly]:border-stroke-primary-onBg data-[state=yearly]:text-primary-onBg"
            data-state={planPeriod}
            onClick={() => setPlanPeriod("yearly")}
          >
            Yearly{" "}
            <span className="text-decorative-red/50 data-[state=yearly]:text-decorative-red" data-state={planPeriod}>
              -20%
            </span>
          </button>
        </div>
      </div>
      <section>
        <div className="flex w-full justify-stretch gap-10">
          {mappedPlans.map((item) => (
            <PlanCard
              highlighted={item.highlighted}
              isLoading={loading}
              key={item.priceId}
              period={planPeriod}
              plan={item}
              portalCreateHandler={portalCreateHandler}
              showArrow={item.showArrow}
              onPlanClick={planClickHandler}
            />
          ))}
        </div>
      </section>
    </>
  );
};
export default SubscriptionPlanList;
