import { useState } from "react";

import { useGenerateShareLink } from "@/api/workspace.ts";
import { Button } from "@/components/ui/button.tsx";
import { ShareArrow } from "@/components/v3";
import { InfiniteLoading } from "@/pages/loading";
import { copyTextToClipboard } from "@/utils";

const ShareWorkspaceButton = ({ workspaceId }: { workspaceId: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { data: generatedLink, isLoading } = useGenerateShareLink(workspaceId);
  const onCopyClick = () => {
    void copyTextToClipboard(generatedLink?.shareLink ?? "").then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  if (isLoading) return <InfiniteLoading className="h-10 w-10" />;

  return (
    <Button size="sm" variant="secondary" onClick={onCopyClick}>
      <ShareArrow />
      {isCopied ? "Copied!" : "Share workspace"}
    </Button>
  );
};
export default ShareWorkspaceButton;
