import { type ReactNode } from "react";

import { Navigate, NavLink } from "react-router-dom";

import { usePreservedQueryParams } from "@/service/user.ts";

export const PreservedQueryLink = ({ children, to }: { children: ReactNode; to: string }) => {
  const redirectTo = usePreservedQueryParams();

  return <NavLink to={{ pathname: to, search: redirectTo }}>{children}</NavLink>;
};

export const PreservedQueryNavigation = ({ to, replace }: { to: string; replace?: boolean }) => {
  const redirectTo = usePreservedQueryParams();
  return <Navigate replace={replace} to={{ pathname: to, search: redirectTo }} />;
};
