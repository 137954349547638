import { useCallback, useEffect, useRef, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { type CountryCode } from "libphonenumber-js/core";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { setUserData } from "@/api/user";
import { ModalKeys } from "@/assets/constants/modal";
import { fieldOfOccupationOptions, occupationOptions } from "@/assets/constants/onboarding";
import { CountrySelector } from "@/components/account";
import UniversitySelector from "@/components/account/university-selector";
import { CalendarIcon } from "@/components/icons/account";
import { Button } from "@/components/ui/button.tsx";
import { Calendar } from "@/components/ui/calendar";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormField, FormItem, FormControl, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { useAuthUserData, useUpdateFirebaseUserData } from "@/firebase/hooks";
import { cn } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice";

const personalData = z.object({
  lastName: z.string(),
  firstName: z.string(),
  displayName: z.string(),
  email: z.string(),
  countryCode: z.string(),
  occupation: z.string(),
  fieldOfOccupation: z.string(),
  university: z.string(),
  gender: z.string(),
  dateOfBirth: z
    .date()
    .nullable()
    .refine((date) => !!date),
  phoneCode: z.custom<CountryCode>(),
  phoneNumber: z.string(),
});

type PersonalDataForm = z.infer<typeof personalData>;

const getNewDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 20);
  return date;
};

const EditInformation = () => {
  const [open, setModalKey] = useModalState(ModalKeys.EDIT_USER_INFORMATION_MODAL_OPEN);
  const [activeTab, setActiveTab] = useState("personal");
  const dialogBodyRef = useRef(null);
  const handleClose = useCallback(() => {
    setModalKey(false);
  }, [setModalKey]);

  const { toast } = useToast();
  const { data: userData, isLoading } = useAuthUserData();

  const { mutate: updateUser, status } = useUpdateFirebaseUserData();
  const form = useForm<PersonalDataForm>({
    resolver: zodResolver(personalData),
    defaultValues: {
      phoneCode: (userData?.phoneCode as CountryCode) ?? "US",
      dateOfBirth: getNewDate(),
    },
  });

  const occupation = form.watch("occupation");

  const handleSubmit = useCallback(
    ({
      gender,
      countryCode,
      fieldOfOccupation,
      occupation,
      firstName,
      lastName,
      displayName,
      university,
      dateOfBirth,
    }: PersonalDataForm) => {
      void setUserData({
        payload: {
          gender,
          dateOfBirth: dateOfBirth?.toISOString() ?? "",
          firstName,
          surname: lastName,
          displayName,
          university,
          fieldOfOccupation,
          occupation,
          country: countryCode,
        },
      });
      updateUser({
        payload: {
          gender,
          countryCode,
          fieldOfOccupation,
          occupation,
          firstName,
          lastName,
          displayName,
          university,
          dateOfBirth: dateOfBirth?.toISOString(),
        },
      });
      handleClose();
    },
    [updateUser, handleClose],
  );

  useEffect(() => {
    if (userData) {
      const { setValue } = form;
      setValue("lastName", userData.lastName ?? "");
      setValue("firstName", userData.firstName ?? "");
      setValue("displayName", userData.displayName ?? "");
      setValue("email", userData.email ?? "");
      setValue("countryCode", userData.countryCode ?? "");
      setValue("occupation", userData.occupation ?? "");
      setValue("fieldOfOccupation", userData.fieldOfOccupation ?? "");
      setValue("university", userData.university ?? "");
      setValue("gender", userData.gender ?? "");
      setValue("dateOfBirth", userData.dateOfBirth ? new Date(userData.dateOfBirth) : getNewDate());
      setValue("phoneNumber", userData.phoneNumber ?? "");
      setValue("phoneCode", (userData.phoneCode as CountryCode) ?? "IN");
    }
  }, [form, userData]);

  useEffect(() => {
    if (status === "success") {
      toast({
        title: "User data updated!",
        variant: "success",
        duration: 100000,
      });
    } else if (status === "error") {
      toast({
        title: "Failed to update user data!",
        variant: "destructive",
      });
    }
  }, [status, toast]);

  useEffect(() => {
    if (isLoading) return;
    if ((occupation === "highschool" || occupation === "professional") && userData?.university === "") {
      form.setValue("university", "");
    }
  }, [occupation, form.setValue, userData?.university, isLoading, form]);

  if (isLoading) return null;

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent
        className="gap-6"
        ref={dialogBodyRef}
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Edit information</DialogTitle>
          <div className="flex justify-center">
            <div
              className="w-full cursor-pointer border-b border-b-stroke-default p-4 text-center font-bold text-tab-default data-[state=personal]:border-b-[3px] data-[state=personal]:border-b-tab-stroke-active data-[state=personal]:text-tab-active"
              data-state={activeTab}
              onClick={() => setActiveTab("personal")}
            >
              Personal info
            </div>
            <div
              className="w-full cursor-pointer border-b border-b-stroke-default p-4 text-center font-bold text-tab-default data-[state=studiesAndWork]:border-b-[3px] data-[state=studiesAndWork]:border-b-tab-stroke-active data-[state=studiesAndWork]:text-tab-active"
              data-state={activeTab}
              onClick={() => setActiveTab("studiesAndWork")}
            >
              Studies and work
            </div>
          </div>
        </DialogHeader>
        <Form {...form}>
          <form className="overflow-hidden" onSubmit={form.handleSubmit(handleSubmit)}>
            {activeTab === "personal" && (
              <div className="mb-12 mt-6 flex flex-col gap-4">
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel className="">Firstname</FormLabel>
                        <FormControl>
                          <Input className="w-full" placeholder="" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel className="">Lastname</FormLabel>
                        <FormControl>
                          <Input className="w-full" placeholder="" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="displayName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel className="">Nickname</FormLabel>
                      <FormControl>
                        <Input className="w-full" placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel className="">Email</FormLabel>
                      <FormControl>
                        <Input className="w-full" disabled={true} placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="countryCode"
                    render={({ field }) => (
                      <FormItem className="col-span-2">
                        <FormLabel>Country</FormLabel>
                        <FormControl>
                          <CountrySelector
                            modal
                            className="relative"
                            container={dialogBodyRef.current}
                            setValue={(value) => form.setValue("countryCode", value)}
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="gender"
                    render={({ field }) => (
                      <FormItem className="col-span-2 w-full">
                        <FormLabel className="">Gender</FormLabel>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className="h-10 w-full rounded-lg border border-stroke-tertiary-on-bg p-2 text-sm font-normal text-primary-onBg">
                              <SelectValue className="text-primary-onBg" placeholder="Select a gender" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent className="z-[301]">
                            <SelectItem className="cursor-pointer" value={"male"}>
                              Male
                            </SelectItem>
                            <SelectItem className="cursor-pointer" value={"female"}>
                              Female
                            </SelectItem>
                            <SelectItem className="cursor-pointer" value={"other"}>
                              Other
                            </SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="dateOfBirth"
                  render={({ field }) => (
                    <FormItem className="col-span-2 flex w-full flex-col">
                      <FormLabel>Date of birth</FormLabel>
                      <Popover modal>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              className={cn(
                                "h-10 w-full rounded-lg border border-stroke-tertiary-on-bg p-2 text-sm font-normal text-primary-onBg",
                                !field.value && "text-muted-foreground",
                              )}
                              variant={"outline"}
                            >
                              {field.value ? (
                                format(field.value, "PPP")
                              ) : (
                                <span className="text-primary-onBg">Pick a date</span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent align="start" className="z-[301] w-auto p-0">
                          <Calendar
                            initialFocus
                            defaultMonth={getNewDate()}
                            disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
                            mode="single"
                            selected={field.value ? field.value : getNewDate()}
                            onSelect={field.onChange}
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            {activeTab === "studiesAndWork" && (
              <div className="mb-12 mt-6 flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="occupation"
                  render={({ field }) => (
                    <FormItem className="col-span-2 w-full">
                      <FormLabel className="">Occupation</FormLabel>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className="h-10 w-full rounded-lg border border-stroke-tertiary-on-bg p-2 text-sm font-normal text-primary-onBg">
                            <SelectValue
                              className="text-primary-onBg"
                              placeholder="Select a verified email to display"
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="z-[301]">
                          {occupationOptions.map((item) => (
                            <SelectItem className="cursor-pointer" key={item.id} value={item.id}>
                              {item.text}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="fieldOfOccupation"
                  render={({ field }) => (
                    <FormItem className="col-span-2 w-full">
                      <FormLabel className="">Field of Occupation</FormLabel>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className="h-10 w-full rounded-lg border border-stroke-tertiary-on-bg p-2 text-sm font-normal text-primary-onBg">
                            <SelectValue
                              className="text-primary-onBg"
                              placeholder="Select a verified email to display"
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="z-[301]">
                          {fieldOfOccupationOptions.map((item) => (
                            <SelectItem className="cursor-pointer" key={item.id} value={item.id}>
                              {item.text}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="university"
                  render={({ field }) => (
                    <FormItem className="col-span-2 w-full">
                      <FormLabel className="">University</FormLabel>
                      <FormControl>
                        <>
                          {(occupation === "university" || occupation === "phd-research") && (
                            <UniversitySelector modal setValue={field.onChange} value={field.value} />
                          )}
                          {(occupation === "highschool" || occupation === "professional") && (
                            <Input
                              className="h-10 w-full rounded-lg border border-stroke-tertiary-on-bg p-2 text-sm font-normal capitalize text-primary-onBg"
                              placeholder=""
                              {...field}
                            />
                          )}
                        </>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}

            <DialogFooter className="mt-6">
              <Button size="sm" type="button" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={!form.formState.isValid} size="sm" type="submit">
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditInformation;
