import { useMemo } from "react";

import { XIcon } from "lucide-react";

import { Button } from "@/components/ui/button.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { DeleteSelectedProjectButton, ShareSelectedProjectButton, RenameSelectedProjectButton } from "@/components/v3";
import useAppStateStore from "@/zustand/store.ts";

const SelectedProjectToolbarContainer = () => {
  const [selectedProjects, unSelectAllProjects] = useAppStateStore((state) => [
    state.selectedProjects,
    state.unSelectAllProjects,
  ]);
  const text = useMemo(() => `${selectedProjects.length} selected`, [selectedProjects]);

  return (
    <section className="flex h-full flex-row items-center gap-6">
      <section className="flex flex-row items-center gap-2">
        <Button className="gap-0.5 lg:mr-2 lg:gap-2" size="icon" variant="icon" onClick={() => unSelectAllProjects()}>
          <XIcon className="h-4 w-4" />
        </Button>
        <Typography className="truncate !whitespace-normal !text-primary" size="sm">
          <span className="flex flex-row items-center">{text}</span>
        </Typography>
      </section>
      <section className="flex flex-row items-center gap-1">
        {selectedProjects.length === 1 && <RenameSelectedProjectButton />}
        <ShareSelectedProjectButton />
        <DeleteSelectedProjectButton />
      </section>
    </section>
  );
};

export default SelectedProjectToolbarContainer;
