import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { doc, setDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { z } from "zod";

import { getRequest, postRequest, putRequest } from "@/api/utils";
import { QUINO_PROXY_URL } from "@/assets/constants/api.ts";
import { BACKEND_URL, type RegistrationTypes } from "@/assets/constants/constants";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { auth, db } from "@/firebase";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { type OnboardingChoicesWithTime, type UserDataBackend } from "@/types/app.ts";
import { type SearchResponse } from "@/types/home";
import { type GenericActivity, type FolderElement, folderElementSchema, type ReferralData } from "@/types/schemas";
import { type CountryIsSchema, type UserOnboardingDataSchema } from "@/types/schemas";
import { validateZodSchema } from "@/utils";

const USERS = "/users";

export const getReferralData = async (): Promise<ReferralData> => getRequest(`${BACKEND_URL}${USERS}/my-invited-users`);

export const postUserRegistration = async (payload: {
  registrationType: RegistrationTypes;
  email: string;
}): Promise<string> => postRequest(`${BACKEND_URL}${USERS}/registration`, payload);

export const postCompletionLevel = async (): Promise<UserOnboardingDataSchema> =>
  postRequest(`${BACKEND_URL}${USERS}/claim-profile-completion-reward`, {});

export const setUserData = async ({ payload }: { payload: UserDataBackend }): Promise<UserOnboardingDataSchema> =>
  postRequest(`${BACKEND_URL}${USERS}/set-user-data`, payload);

export const setOnboardingData = async ({ payload }: { payload: OnboardingChoicesWithTime }): Promise<void> =>
  postRequest(`${BACKEND_URL}${USERS}/onboarding-done`, payload);

export const setPasswordResetSuccess = async (): Promise<void> =>
  postRequest(`${BACKEND_URL}${USERS}/password-reset-success`, {});

export const searchForText = async ({ textToSearch }: { textToSearch: string }): Promise<SearchResponse[]> =>
  postRequest(`${BACKEND_URL}${USERS}/home/search`, { textToSearch });

export const setPasswordResetRequest = async (): Promise<void> =>
  postRequest(`${BACKEND_URL}${USERS}/password-reset-request`, {});
export const setAccountClosed = async ({ payload }: { payload: { reason: string } }): Promise<void> =>
  postRequest(`${BACKEND_URL}${USERS}/account-closed`, payload);

export const getFavouriteElements = async (): Promise<FolderElement[]> =>
  validateZodSchema(getRequest(`${BACKEND_URL}${USERS}/favourite-elements/`), z.array(folderElementSchema));

export const getActivityHistory = async (
  offset: number = 0,
  limit: number = 10,
): Promise<{ activityHistoryList: GenericActivity[]; hasMore: boolean }> => {
  return getRequest<{ activityHistoryList: GenericActivity[]; hasMore: boolean }>(
    `${BACKEND_URL}${USERS}/activity-history?limit=${limit}&offset=${offset}`,
  );
};

export const getRecentlyUsed = async (
  limit: number = 10,
): Promise<
  {
    workspaceName: string;
    dateLastOpen: string;
    documentName: string;
    documentCoverImageUrl: string;
    documentId: string;
    workspaceId: string;
    projectId: string;
  }[]
> => {
  return getRequest(`${BACKEND_URL}${USERS}/user-document-interaction?limit=${limit}`);
};
const fetchUseronboardingState = () =>
  getRequest<UserOnboardingDataSchema>(`${BACKEND_URL}${USERS}/profile-completion-level`);

const claimPreregFDS = ({
  temporaryDocumentId,
  unknownDistinctId,
}: {
  temporaryDocumentId: string;
  unknownDistinctId: string;
}) =>
  postRequest<{
    workspaceId: string;
    documentId: string;
    projectId: string;
    documentName: string;
  }>(`${BACKEND_URL}${USERS}/claim-prereg-fds/${temporaryDocumentId}`, { unknownDistinctId });

const fetchCountryIs = (ip?: string) => getRequest<CountryIsSchema>(`${QUINO_PROXY_URL}/country-is/${ip ? ip : ""}`);
export const useUserOnboardingData = () => {
  const [user] = useAuthState(auth);
  return useQuery({
    queryKey: [QUERY_KEYS.USER_ONBOARDING_DATA, user?.uid],
    queryFn: fetchUseronboardingState,
    select: (result) => ({
      rewardClaimed: result.rewardClaimed,
      documentUploaded: result.documentUploaded,
      profileCompleted: result.profileCompleted,
    }),
    enabled: !!user?.uid,
  });
};
export const useFavouriteElements = () => {
  const [user] = useAuthState(auth);
  return useQuery({
    queryKey: [QUERY_KEYS.FAVOURITE_ELEMENTS, user?.uid],
    queryFn: getFavouriteElements,
  });
};

export const activateReferralCode = async (userId: string): Promise<void> => {
  const docReference = doc(db, "users", userId);

  await putRequest(`${BACKEND_URL}${USERS}/invited-by/${userId}`, {});

  await setDoc(docReference, { referralCode: null }, { merge: true });
};

export const useClaimPreregFDS = () => {
  const queryClient = useQueryClient();
  const currentUserId = useFirebaseUserId();
  return useMutation({
    mutationKey: ["claim-prereg-fds"],
    mutationFn: claimPreregFDS,
    onSuccess: (data) => {
      void queryClient.refetchQueries({
        queryKey: [currentUserId, QUERY_KEYS.WORKSPACES, data.workspaceId],
        exact: true,
      });
    },
  });
};

export const useActivityHistory = () => {
  const firebaseUserId = useFirebaseUserId();

  return useInfiniteQuery({
    queryKey: [firebaseUserId, "activity-history"],
    queryFn: ({ pageParam }) => getActivityHistory(pageParam),
    staleTime: 5000,
    refetchOnMount: true,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => (lastPage.hasMore ? allPages.length : undefined),
  });
};

export const useRecentlyUsed = () => {
  const firebaseUserId = useFirebaseUserId();
  return useQuery({
    queryKey: [firebaseUserId, "recently-used"],
    queryFn: () => getRecentlyUsed(10),
    refetchOnMount: true,
    staleTime: 5000,
  });
};

export const useSearchByText = () => {
  return useMutation({
    mutationFn: searchForText,
  });
};

export const useCountryIs = () =>
  useQuery({
    queryKey: ["country-is"],
    queryFn: async () => {
      const ipQuery = await fetchCountryIs();
      return fetchCountryIs(ipQuery.ip);
    },
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
