import { TAB_STORE_KEY } from "@/assets/constants/constants";
import { type ProjectTabState } from "@/types/app.ts";

export function getFromStorage(): { [p: string]: ProjectTabState } | null {
  const data = localStorage.getItem(TAB_STORE_KEY);
  if (!data) return null;
  const parsedData = JSON.parse(data) as unknown as {
    state: { [p: string]: ProjectTabState };
  };
  return parsedData.state.projectTabState as unknown as {
    [p: string]: ProjectTabState;
  };
}
