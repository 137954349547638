import { useEffect } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants";
import AppleLogin from "@/components/button/apple-login";
import GoogleLogin from "@/components/button/google-login.tsx";
import LoginForm from "@/components/form/login-form.tsx";
import { FormPageLayout } from "@/components/layout";
import { Button } from "@/components/ui/button.tsx";
import { FormDivider } from "@/components/ui/divider.tsx";
import { auth, logout } from "@/firebase";

const LoginPlain = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) navigate(ROUTES.HOME);
  }, [navigate, user]);

  return (
    <FormPageLayout title="Log in or sign up">
      <Helmet>
        <title>Quino AI | Login</title>
        <meta content="Login to your Quino Account." name="description" />
      </Helmet>
      <section className="flex w-full flex-col items-center">
        {user?.email}
        {user && (
          <Button variant="secondary" onClick={() => logout()}>
            Logout
          </Button>
        )}
        <div className="mt-6 flex w-full flex-col justify-between gap-2 sm:flex-row">
          <GoogleLogin />
          <AppleLogin />
        </div>
        <FormDivider className="my-4">or</FormDivider>
      </section>
      <LoginForm />
    </FormPageLayout>
  );
};

export default LoginPlain;
