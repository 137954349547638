import { BookIcon } from "lucide-react";

import { enumToProjectIcon } from "@/service/library.ts";
import { ProjectIconsEnum } from "@/types/schemas";
import { cn } from "@/utils";

const GeneralProjectIcon = ({
  projectIcon = ProjectIconsEnum.CHART,
  color = "#733E9C",
  className,
}: {
  projectIcon: ProjectIconsEnum;
  color?: string | null;
  className?: string;
}) => {
  const Comp = enumToProjectIcon[projectIcon];
  if (!Comp) return <BookIcon className={cn("h-10 w-10 text-default", className)} />;
  return <Comp className={cn("h-10 w-10", className)} style={{ color }} />;
};
export default GeneralProjectIcon;
