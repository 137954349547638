import { useCallback, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { MailSent } from "@/components/v3";
import { saveFeedback } from "@/firebase";
import { useAppAuthState } from "@/firebase/hooks.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const FormSchema = z
  .object({
    subject: z
      .string()
      .min(1, {
        message: "There must be a subject at least",
      })
      .max(256, {
        message: "Must be at most 256 characters",
      }),
    type: z.string(),
    message: z.string().min(0),
  })
  .refine((data) => data.message, { message: "You have to add a message" });
type FormSchemaType = z.infer<typeof FormSchema>;

const AskForCancellationModal = () => {
  const [open, setState] = useModalState(ModalKeys.CANCELLATION);
  const [user] = useAppAuthState();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      type: "General",
      subject: "",
      message: "",
    },
  });

  const handleSubmit = useCallback(
    (data: FormSchemaType) => {
      try {
        setLoading(true);

        if (!user) return;

        void saveFeedback({
          userId: user.uid,
          type: "Cancellation",
          subject: data.subject,
          message: data.message,
          timestamp: new Date().toISOString(),
          imageUrl: null, // imageUrl is being sent with null bc CancellationModal uses the same method like FeedbackModal but in cancellation we do not need it.
        });

        form.reset();
        form.setValue("subject", "");
        form.setValue("message", "");
        form.setValue("type", "Cancellation");
        setState(false);
        toast({ variant: "success", title: "Cancellation request sent successfully!", icon: <MailSent /> });
      } catch {
        toast({ variant: "destructive", title: "Error during sending cancellation request!" });
      } finally {
        setLoading(false);
      }
    },
    [form, setState, user],
  );

  return (
    <Dialog open={open} onOpenChange={setState}>
      <DialogContent className="flex max-h-[95vh] max-w-xl flex-col gap-4">
        <DialogHeader className="gap-4">
          <DialogTitle className="text-h4">Ask for cancellation</DialogTitle>
          <DialogDescription className="text-base">
            Due to a technical reason, we can only cancel your subscription manually. The expected turnaround time is 24
            hours.{" "}
          </DialogDescription>
        </DialogHeader>
        <section className="h-full overflow-hidden">
          <Form {...form}>
            <form
              className="mt-12 flex flex-1 flex-col gap-12 overflow-hidden"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <section className="flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="subject"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-primary">Subject</FormLabel>
                      <FormControl>
                        <Input placeholder="Subscription cancellation" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-primary">Message</FormLabel>
                      <FormControl>
                        <Textarea
                          className="resize-none"
                          placeholder={
                            "Hi Quino team,\n\nI would like to cancel my subscription.\nPlease let me know when it's done.\n\nThank you,\n{name}"
                          }
                          rows={7}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </section>
              <DialogFooter>
                <Button size="sm" type="button" variant="ghost" onClick={() => setState(false)}>
                  Cancel
                </Button>
                <Button disabled={loading} size="sm">
                  Send message
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default AskForCancellationModal;
