import { useCallback } from "react";

import { PlusIcon } from "lucide-react";

import { ModalKeys } from "@/assets/constants/modal.ts";
import { CreateWorkspaceModal, DemoHandlerButton } from "@/components/v3";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const NewWorkspaceButton = () => {
  const [, setOpen] = useModalState(ModalKeys.CREATE_WORKSPACE);

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <>
      <CreateWorkspaceModal />
      <DemoHandlerButton className="flex h-14 w-14 p-4 lg:hidden" size="icon" variant="icon" onClick={onClick}>
        <span className="h-4 w-4">
          <PlusIcon className="h-4 w-4" />
        </span>
      </DemoHandlerButton>
      <DemoHandlerButton className="hidden lg:flex" size="xs" variant="ghost" onClick={onClick}>
        <span className="h-4 w-4">
          <PlusIcon className="h-4 w-4" />{" "}
        </span>
        <span>New</span>
      </DemoHandlerButton>
    </>
  );
};
export default NewWorkspaceButton;
