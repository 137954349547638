import { useMemo } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Check, QuinoPopoverTitle, SplitViewIcon } from "@/components/v3";
import { getViewModeText } from "@/service/document.ts";
import { ViewMode } from "@/types/app.ts";
import useAppStateStore from "@/zustand/store.ts";

const DocumentSplitViewSelector = () => {
  const [viewMode, setViewMode] = useAppStateStore((state) => [state.viewMode, state.setViewMode]);
  const [showWorkspaces, setShowWorkspaces] = useAppStateStore((state) => [
    state.showWorkspaces,
    state.setShowWorkspaces,
  ]);

  const selectValues = useMemo(
    () =>
      Object.values(ViewMode).map((viewModeValue) => (
        <DropdownMenuItem
          className="hover group mt-1 w-full cursor-pointer rounded-lg py-2 pl-10 data-[checked=true]:font-bold"
          data-checked={viewModeValue === viewMode}
          key={viewModeValue}
          onClick={() => setViewMode(viewModeValue)}
        >
          <span className="absolute left-3 hidden h-4 w-4 items-center justify-center group-data-[checked=true]:flex">
            <Check className="h-4 w-4" />
          </span>

          <div className="flex w-full flex-row items-center gap-2">
            <span className="text-sm text-primitive-purple-900">{getViewModeText(viewModeValue)}</span>
          </div>
        </DropdownMenuItem>
      )),
    [setViewMode, viewMode],
  );

  const modeText = useMemo(() => getViewModeText(viewMode), [viewMode]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="group border-0 bg-transparent ">
        <section className="flex flex-row items-center gap-2 rounded-lg px-2 py-3 hover:bg-white/50 group-data-[state=open]:bg-white">
          <h6 className="w-max text-end font-jakarta text-sm font-bold text-default">{modeText}</h6>
          <span className="h-4 w-4">
            <SplitViewIcon className="h-4 w-4" />
          </span>
        </section>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="z-[2000] min-w-[224px] rounded-xl border border-primary-purple-dim px-1 py-1"
      >
        <QuinoPopoverTitle title="Customize document view" />
        <DropdownMenuItem
          className="hover group mt-1 w-full cursor-pointer rounded-lg py-2 pl-10 data-[checked=true]:font-bold"
          data-checked={showWorkspaces}
          onClick={() => setShowWorkspaces(!showWorkspaces)}
        >
          <span className="absolute left-3 hidden h-4 w-4 items-center justify-center group-data-[checked=true]:flex">
            <Check className="h-4 w-4" />
          </span>

          <div className="flex w-full flex-row items-center gap-2">
            <span className="text-sm text-primitive-purple-900">Show Workspaces</span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {selectValues}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DocumentSplitViewSelector;
