import { useMemo } from "react";

import { type QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { fetchAllDemoWorkspaces, fetchDemoWorkspaceData } from "@/api/demo";
import { deleteRequest, getRequest, postRequest, putRequest } from "@/api/utils.ts";
import { BACKEND_URL, ROUTES } from "@/assets/constants/constants.ts";
import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import type { QueryConfig } from "@/types/react-query";
import type { AcceptShareWorkspace } from "@/types/schemas";
import {
  type BaseWorkspace,
  type WorkspaceEntity,
  type WorkspaceCreateSchema,
  type WorkspaceListItem,
  type WorkspaceUpdateSchema,
  workspaceSchema,
  workspaceListItemSchemaList,
} from "@/types/schemas/api/workspaceEntity.ts";
import { validateZodSchema } from "@/utils";

const WORKSPACES = "/workspaces";

export const getAllWorkspaceIds = () => {
  return getRequest<string[]>(`${BACKEND_URL}${WORKSPACES}/my-workspace-ids`);
};

export const getAllWorkspaces = async (): Promise<WorkspaceListItem[]> => {
  return await validateZodSchema(
    getRequest<WorkspaceListItem[]>(`${BACKEND_URL}${WORKSPACES}/my-workspaces`),
    workspaceListItemSchemaList,
  );
};

export const getWorkspace = async (workspaceId: string): Promise<WorkspaceEntity> => {
  return await getRequest<WorkspaceEntity>(`${BACKEND_URL}${WORKSPACES}/${workspaceId}`);
};

export const createWorkspace = (payload: WorkspaceCreateSchema) => {
  const data = new FormData();

  data.set("name", payload.name);
  payload.coverImage && data.set("image_file", payload.coverImage);

  return postRequest<BaseWorkspace>(`${BACKEND_URL}${WORKSPACES}/`, data);
};

export const updateWorkspace = ({ id, payload }: { id: string; payload: Partial<WorkspaceUpdateSchema> }) => {
  const data = new FormData();

  payload.name && data.set("name", payload.name);
  payload.coverImage && data.set("image_file", payload.coverImage);

  return putRequest<WorkspaceEntity>(`${BACKEND_URL}${WORKSPACES}/${id}`, data);
};

export const deleteWorkspace = async (id: string) => {
  return deleteRequest<WorkspaceEntity>(`${BACKEND_URL}${WORKSPACES}/${id}`);
};

export const leaveWorkspace = async (id: string) => {
  return postRequest<WorkspaceEntity>(`${BACKEND_URL}${WORKSPACES}/users/quit-workspace`, { workspaceId: id });
};

export const assignAdmin = async ({ workspaceId, newAdminId }: { workspaceId: string; newAdminId: string }) => {
  return postRequest<WorkspaceEntity>(`${BACKEND_URL}${WORKSPACES}/users/assign-admin`, { newAdminId, workspaceId });
};

export const removeUser = async ({ workspaceId, userId }: { workspaceId: string; userId: string }) => {
  return postRequest<WorkspaceEntity>(`${BACKEND_URL}${WORKSPACES}/users/remove-user`, { userId, workspaceId });
};

export const generateShareLink = (id: string) => {
  return postRequest<{ shareLink: string }>(`${BACKEND_URL}${WORKSPACES}/users/generate-share-link`, {
    workspaceId: id,
  });
};
const acceptWorkspaceShare = async ({ encodedWorkspaceId }: AcceptShareWorkspace) =>
  validateZodSchema(
    postRequest<WorkspaceEntity>(`${BACKEND_URL}${WORKSPACES}/users/accept-invite`, {
      encodedWorkspaceId,
    }),
    workspaceSchema,
  );

export const useWorkspaceIds = () => {
  const firebaseUserId = useFirebaseUserId();

  return useQuery({
    queryKey: [firebaseUserId, QUERY_KEYS.WORKSPACES_IDS],
    queryFn: getAllWorkspaceIds,
    enabled: !!firebaseUserId,
  });
};

export const useWorkspaces = (options?: QueryConfig<WorkspaceListItem[]>) => {
  const firebaseUserId = useFirebaseUserId();
  const { matched: isDemo } = useIsDemoLikePage();
  return useQuery({
    queryKey: [firebaseUserId, QUERY_KEYS.WORKSPACES],
    queryFn: () => (isDemo ? fetchAllDemoWorkspaces() : getAllWorkspaces()),
    ...options,
    enabled: !!firebaseUserId,
    staleTime: 5000,
  });
};
export const useWorkspaceFromList = (workspaceId: string): WorkspaceListItem | undefined => {
  const { data } = useWorkspaces();
  return useMemo(() => data?.find((item) => item.id === workspaceId), [data, workspaceId]);
};
export const useWorkspace = (workspaceId: string = "") => {
  const firebaseUserId = useFirebaseUserId();
  const { matched: isDemo } = useIsDemoLikePage();

  return useQuery({
    queryKey: [firebaseUserId, QUERY_KEYS.WORKSPACES, workspaceId],
    queryFn: () => (isDemo ? fetchDemoWorkspaceData(workspaceId) : getWorkspace(workspaceId)),
    enabled: !!firebaseUserId && !!workspaceId,
    staleTime: 5000,
  });
};
export const useCurrentWorkspace = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  return useWorkspace(currentWorkspaceId);
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();
  return useMutation({
    mutationFn: createWorkspace,
    onSuccess: (resultBase: BaseWorkspace) => {
      const result: WorkspaceListItem = {
        ...resultBase,
        numOfUsers: 0,
        numOfProjects: 0,
      };

      queryClient.setQueryData<WorkspaceListItem[]>([firebaseUserId, QUERY_KEYS.WORKSPACES], (oldData) =>
        oldData ? [...oldData, result] : oldData,
      );
      queryClient.setQueryData<WorkspaceListItem>([firebaseUserId, QUERY_KEYS.WORKSPACES, result.id], (oldData) =>
        oldData
          ? {
              ...oldData,
              ...result,
            }
          : oldData,
      );
    },
  });
};

function updateWorkspaceResolver(queryClient: QueryClient, firebaseUserId: string, result: WorkspaceEntity) {
  queryClient.setQueryData<WorkspaceListItem[]>([firebaseUserId, QUERY_KEYS.WORKSPACES], (oldData) => {
    if (!oldData) return oldData;
    const newData = [...oldData];
    const idx = newData.findIndex((item) => item.id === result.id);
    if (idx !== -1) {
      newData[idx] = {
        ...newData[idx],
        ...result,
        numOfUsers: result.userIds.length,
        numOfProjects: result.projects.length,
        adminId: result.adminId,
      };
    }
    return [...newData];
  });
  queryClient.setQueryData<WorkspaceEntity>([firebaseUserId, QUERY_KEYS.WORKSPACES, result.id], (oldData) =>
    oldData
      ? {
          ...oldData,
          ...result,
        }
      : oldData,
  );
}

export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();
  return useMutation({
    mutationFn: updateWorkspace,
    onSuccess: (result: WorkspaceEntity) => {
      updateWorkspaceResolver(queryClient, firebaseUserId, result);
    },
  });
};
export const useDeleteWorkspace = () => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: deleteWorkspace,
    onSuccess: (result) => {
      queryClient.setQueryData<WorkspaceListItem[]>([firebaseUserId, QUERY_KEYS.WORKSPACES], (oldData) => {
        if (!oldData) return oldData;
        const newData = [...oldData];
        const newDataFiltered = newData.filter((item) => item.id !== result.id);
        return [...newDataFiltered];
      });
      navigate(ROUTES.WORKSPACES);
      toast({
        variant: "success",
        title: "Workspace deleted!",
      });
    },
  });
};
export const useLeaveWorkspace = () => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();
  const workspaceId = useCurrentWorkspaceId();
  const workspace = useWorkspace(workspaceId);
  const navigate = useNavigate();
  return useMutation({
    mutationFn: leaveWorkspace,
    onSuccess: (result) => {
      queryClient.setQueryData<WorkspaceListItem[]>([firebaseUserId, QUERY_KEYS.WORKSPACES], (oldData) => {
        if (!oldData) return oldData;
        const newData = [...oldData];
        const newDataFiltered = newData.filter((item) => item.id !== result.id);
        return [...newDataFiltered];
      });
      navigate(ROUTES.WORKSPACES);
      toast({
        variant: "success",
        title: `You have left workspace: ${workspace.data?.name}`,
      });
    },
  });
};
export const useGenerateShareLink = (workspaceId: string) => {
  const firebaseUserId = useFirebaseUserId();
  return useQuery({
    queryKey: [firebaseUserId, QUERY_KEYS.WORKSPACES, workspaceId, "SHARE-LINK"],
    queryFn: () => generateShareLink(workspaceId),
    refetchOnMount: true,
    staleTime: 60 * 1000,
    enabled: !!workspaceId,
  });
};

export const useAcceptShareRequest = () => {
  return useMutation({
    mutationKey: ["accept-share-project"],
    mutationFn: acceptWorkspaceShare,
  });
};

export const useAssignAdmin = () => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();
  return useMutation({
    mutationFn: assignAdmin,
    onSuccess: (result) => {
      updateWorkspaceResolver(queryClient, firebaseUserId, result);
    },
  });
};
export const useRemoveMember = () => {
  const queryClient = useQueryClient();
  const firebaseUserId = useFirebaseUserId();
  return useMutation({
    mutationFn: removeUser,
    onSuccess: (result) => {
      updateWorkspaceResolver(queryClient, firebaseUserId, result);
    },
  });
};
