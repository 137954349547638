import { useEffect } from "react";

import DocumentProcessingIndicator from "@/components/document/document-processing-indicator.tsx";
import NoteViewFooter from "@/components/document/note/note-view-footer.tsx";
import NoteEditorView from "@/components/document/notes-tabs";
import DocumentSummary from "@/components/document/summary";
import SummaryFooter from "@/components/document/summary/summarized-footer.tsx";
import { QuinoTooltip } from "@/components/generic";
import {
  ChatDocuIcon,
  ChatDocuIconFilled,
  DocumentSummaryIcon,
  DocumentSummaryIconFilled,
  NotesIcon,
  NotesIconFilled,
} from "@/components/icons/tabs/notes";
import QuinoChat from "@/components/note/chat/quino-chat.tsx";
import { Tabs, TabsContent, TabsList } from "@/components/ui/tabs";
import { QuinoTabTrigger } from "@/components/v3";
import { useIsTemporaryPage } from "@/service/hooks/misc.ts";
import { useNoteTabState } from "@/service/hooks/react-router";
import { useMixpanelTrack } from "@/service/mixpanel";
import { cn } from "@/utils";

const NoteView = () => {
  const [tab, setTab] = useNoteTabState();
  const mixpanelTrack = useMixpanelTrack();
  const { matched: isTemporaryPage } = useIsTemporaryPage();

  useEffect(() => {
    if (isTemporaryPage && tab !== "summary") {
      setTab("summary");
    }
  }, [isTemporaryPage, setTab, tab]);

  return (
    <Tabs
      className="tutorial-note-tabs relative flex h-full w-full flex-col overflow-hidden"
      value={tab}
      onValueChange={setTab}
    >
      <TabsList
        className={cn(
          "z-[100] h-[calc(3rem+0.5px)] w-full border-b border-stroke-default bg-bg-layer1 p-0",
          isTemporaryPage && "hidden",
        )}
      >
        <QuinoTabTrigger
          value="summary"
          onClick={() => {
            mixpanelTrack("document_summary_menu_clicked");
          }}
        >
          <QuinoTooltip
            align="center"
            className="z-[1000]"
            content={"Summarize full documents"}
            side="bottom"
            onOpenChange={(value) => value && mixpanelTrack("document_summary_tooltip_shown")}
          >
            <span className="flex flex-1 flex-row items-center justify-center gap-2">
              Summary
              <DocumentSummaryIconFilled className="hidden h-4 w-4 group-data-[state=active]:block" />
              <DocumentSummaryIcon className="block h-4 w-4 group-data-[state=active]:hidden" />
            </span>
          </QuinoTooltip>
        </QuinoTabTrigger>
        <QuinoTabTrigger value="chat" onClick={() => mixpanelTrack("document_chat_open")}>
          <QuinoTooltip align="center" className="z-[1000]" content={"Chat with your uploaded documents"} side="bottom">
            <span className="flex flex-1 flex-row items-center justify-center gap-2">
              Q&A
              <ChatDocuIcon className="block group-data-[state=active]:hidden" />
              <ChatDocuIconFilled className="hidden group-data-[state=active]:block" />
            </span>
          </QuinoTooltip>
        </QuinoTabTrigger>
        <QuinoTabTrigger value="notes">
          <QuinoTooltip align="center" className="z-[1000]" content={"Manage notes in project"} side="bottom">
            <span className="flex flex-1 flex-row items-center justify-center gap-2">
              Notes
              <NotesIconFilled className="hidden group-data-[state=active]:block" />
              <NotesIcon className="block group-data-[state=active]:hidden" />
            </span>
          </QuinoTooltip>
        </QuinoTabTrigger>
      </TabsList>
      <DocumentProcessingIndicator />
      <TabsContent className="mt-0 flex-1 overflow-hidden" value="notes">
        <NoteEditorView />
      </TabsContent>
      <TabsContent className="mt-0 flex-1 overflow-hidden" value="summary">
        <DocumentSummary />
      </TabsContent>
      <TabsContent className="mt-0 flex-1 overflow-hidden" value="chat">
        <QuinoChat />
      </TabsContent>
      {tab === "summary" && <SummaryFooter />}
      {tab === "notes" && <NoteViewFooter />}
    </Tabs>
  );
};

export default NoteView;
