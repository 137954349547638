import { useState } from "react";

import { Check, ChevronDown } from "lucide-react";
import { useElementSize } from "usehooks-ts";

import { countryCodes, countryCodeToName } from "@/assets/constants/country-codes.ts";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/utils";

const CountrySelector = ({
  value = "",
  className = "",
  setValue,
  modal = false,
  placeholder = "Country",
  container = undefined,
}: {
  value: string;
  placeholder?: string;
  container?: HTMLElement | null | undefined;
  className?: string;
  modal?: boolean;
  setValue?: (value: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [squareRef, { width }] = useElementSize<HTMLButtonElement>();
  return (
    <Popover modal={modal} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          aria-expanded={open}
          className={cn(
            "h-10 w-full justify-between rounded-lg border  border-stroke-tertiary-on-bg bg-transparent p-2 text-sm font-normal text-primary-onBg",
            className,
          )}
          ref={squareRef}
          role="combobox"
          variant="outline"
        >
          <p
            className="w-[200px] overflow-hidden truncate text-start text-sm font-normal data-[value='']:font-normal data-[value='']:text-black/30 dark:data-[value='']:text-white/30"
            data-value={value}
          >
            {value ? countryCodes[value] : placeholder}
          </p>
          <ChevronDown className="h-4 w-4 text-default" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[301] w-full p-0" container={container} style={{ width: width }}>
        <Command>
          <CommandInput className="text-primary dark:text-white" placeholder="Search country..." />
          <CommandEmpty className="p-4 text-primary dark:text-white">No country found.</CommandEmpty>
          <CommandGroup className="max-h-40 overflow-y-auto">
            {countryCodeToName.map((country) => (
              <CommandItem
                className="text-primary aria-selected:text-primary dark:text-white"
                key={country.value}
                onSelect={(currentValue) => {
                  setValue?.(currentValue === value ? "" : country.value);
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4 fill-white stroke-primary",
                    value === country.value ? "opacity-100" : "opacity-0",
                  )}
                />
                {country.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
export default CountrySelector;
