import { useEffect } from "react";

import { ModalKeys } from "@/assets/constants/modal.ts";
import { Dialog, DialogContent } from "@/components/ui/dialog.tsx";
import { useAuthUserData } from "@/firebase/hooks.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

import Onboarding from "../../onboarding/onboarding.tsx";

const OnboardingModal = () => {
  const [open, setOpen] = useModalState(ModalKeys.ONBOARDING);
  const { data } = useAuthUserData();
  useEffect(() => {
    if (!data?.onboardingDone) setOpen(true);
    else setOpen(false);
  }, [data?.onboardingDone, setOpen]);
  return (
    <Dialog modal open={open} onOpenChange={() => {}}>
      <DialogContent hideClose className="w-full max-w-[37.5rem] px-6 py-8 md:w-full" id="onboarding-dialog-body">
        <Onboarding />
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingModal;
