import { type StateCreator } from "zustand";

import { TutorialVariant } from "@/assets/constants/modal";
import { type AppSlice, type TutorialSlice } from "@/types/app.ts";

const createTutorialSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], TutorialSlice> = (set) => ({
  tutorialStates: {
    [TutorialVariant.DOCUMENT_VIEW]: { run: false },
    [TutorialVariant.LIBRARY_VIEW]: { run: false },
  },
  setTutorialState: ({ variant, payload }: { variant: TutorialVariant; payload: { run: boolean } }) => {
    set(
      (state) => ({
        tutorialStates: {
          ...state.tutorialStates,
          [variant]: payload,
        },
      }),
      false,
      "setTutorialState",
    );
  },
});

export default createTutorialSlice;
