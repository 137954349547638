import { useCallback } from "react";

import mixpanel, { type Callback, type Dict, type Query } from "mixpanel-browser";
import { type Response } from "mixpanel-browser";

import init from "@/initializer.ts";
import { isDemoPage, useIsDemoLikePage } from "@/service/hooks/misc.ts";

export const isMixpanelLoaded = () => {
  try {
    mixpanel.get_distinct_id();
    return true;
  } catch {
    return false;
  }
};

export const mixpanelTrack = (
  message: string,
  payload?: object,
  _bypass: boolean = false,
  callback?: (response: Response) => void,
) => {
  if (!isMixpanelLoaded()) return;
  // const isAccepted = isCookieConsentAccepted();
  // if (!bypass && !isAccepted) return;
  const isDemo = isDemoPage();
  console.debug(`tracked event: ${isDemo ? `DEMO_${message}` : message}`, JSON.stringify(payload));
  mixpanel.track(isDemo ? `DEMO_${message}` : message, payload, callback);
};
export const mixpanelTrackLinks = (query: Query, eventName: string, properties?: Dict | (() => void)) => {
  if (!isMixpanelLoaded()) return;
  // const isAccepted = isCookieConsentAccepted();
  // if (!isAccepted) return;
  mixpanelTrackLinks(query, eventName, properties);
};

export const mixpanelInit = () => {
  // const isAccepted = isCookieConsentAccepted();
  // if (!isAccepted) return;
  init();
};

export const mixpanelIdentify = (userId: string) => {
  if (!isMixpanelLoaded()) return;
  // const isAccepted = isCookieConsentAccepted();
  // if (!isAccepted) return;
  mixpanel.identify(userId);
};

export const mixpanelPeopleSet = (prop: Dict, callback?: Callback) => {
  if (!isMixpanelLoaded()) return;
  // const isAccepted = isCookieConsentAccepted();
  // if (!isAccepted) return;
  mixpanel.people.set(prop, callback);
};

export const useMixpanelTrack = () => {
  // const isAccepted = useIsCookieConsentAccepted();
  const { matched: isDemoPage } = useIsDemoLikePage();
  return useCallback(
    (message: string, payload?: object, _bypass: boolean = false) => {
      if (!isMixpanelLoaded()) return;
      // if (!bypass && !isAccepted) return;
      console.debug(`tracked event: ${isDemoPage ? `DEMO_${message}` : message}`, JSON.stringify(payload));
      mixpanel.track(isDemoPage ? `DEMO_${message}` : message, payload);
    },
    [isDemoPage],
  );
};
