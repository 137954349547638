import { useCallback, useMemo } from "react";

import { type Step } from "react-joyride";

import { useTutorialData } from "@/api/strapi";
import { type TutorialVariant } from "@/assets/constants/modal";
import { TutorialStep } from "@/components/tutorial";
import { getStrapiMediaUrl } from "@/utils";
import { useTutorialState } from "@/zustand/hooks";

export const useTutorial = (variant: TutorialVariant) => {
  const [{ run }, setState] = useTutorialState(variant);
  const { data } = useTutorialData(variant);

  const steps = useMemo<Step[]>(
    () =>
      data
        ? data.data.map((step) => ({
            content: (
              <TutorialStep
                subtitle={step.attributes.description}
                title={step.attributes.title}
                videoUrl={getStrapiMediaUrl(step.attributes.videoUrl.data.attributes.url)}
              />
            ),
            target: step.attributes.target,
            placement: step.attributes.placement,
            disableBeacon: true,
            disableScrolling: true,
            disableCloseOnEsc: true,
            offset: step.attributes.offset,
            isFixed: true,
            floaterProps: {
              disableFlip: true,
            },
          }))
        : [],
    [data],
  );

  const _setState = useCallback(
    (params: Parameters<typeof setState>[0]) => {
      if (steps.length === 0) return;
      setState(params);
    },
    [setState, steps.length],
  );

  return {
    run,
    setState: _setState,
    steps,
  };
};
