import { useMemo } from "react";

import _ from "lodash";

import { Skeleton } from "@/components/ui/skeleton.tsx";

const ProjectExplorerLoading = () => {
  const skeletons = useMemo(
    () =>
      _.times(20, (i) => (
        <section className="flex w-full flex-col gap-2" key={i}>
          <Skeleton className="h-56 bg-primitive-purple-pale-100" />
          <Skeleton className="h-[11px] w-8/12 bg-primitive-purple-pale-100" />
          <Skeleton className="h-4 w-11/12 bg-primitive-purple-pale-100" />
          <Skeleton className="h-4 w-10/12 bg-primitive-purple-pale-100" />
        </section>
      )),
    [],
  );

  return (
    <section className="grid h-full grid-cols-[repeat(auto-fill,_minmax(min(192px,_100%),_1fr))] gap-x-3 gap-y-6 overflow-hidden">
      {skeletons}
    </section>
  );
};

export default ProjectExplorerLoading;
