import { Link } from "react-router-dom";

import { ROUTES } from "@/assets/constants/constants.ts";
import MissingDocumentThumbnail from "@/assets/images/v3/missing-document-thumbnail.png";
import { formatWithUTC } from "@/service/time-ago.ts";

const RecentlyUsedDocument = ({
  workspaceId,
  projectId,
  documentId,
  workspace,
  documentName,
  lastOpen,
  coverImageUrl,
}: {
  workspace: string;
  workspaceId: string;
  projectId: string;
  documentId: string;
  documentName: string;
  lastOpen: string;
  coverImageUrl: string;
}) => {
  return (
    <Link to={`${ROUTES.DOCUMENTS}/${workspaceId}/${projectId}?document=${documentId}`}>
      <section className="flex flex-row items-stretch gap-3 overflow-hidden rounded p-1 hover:bg-tertiary-container-hover">
        <div className="minh-[5rem] relative w-16 shrink-0">
          <img
            alt="palceholder-document"
            className="absolute h-full w-full object-cover"
            src={coverImageUrl ?? MissingDocumentThumbnail}
          />
        </div>
        <section className="flex flex-col gap-1">
          <section className="text-xs font-medium text-secondary-onBg">{workspace}</section>
          <section className="line-clamp-2 text-sm font-bold text-secondary-onBg">{documentName}</section>
          <section className="mt-1 text-xs font-medium text-primitive-grey-600">{formatWithUTC(lastOpen)}</section>
        </section>
      </section>
    </Link>
  );
};
export default RecentlyUsedDocument;
