export const projectColorMap = {
  "#572E9E": { main: "#572E9E", pastel: "#572E9E", light: "#572E9E" },
  "#2C39A4": { main: "#2C39A4", pastel: "#2C39A4", light: "#2C39A4" },
  "#4253E5": { main: "#4253E5", pastel: "#4253E5", light: "#4253E5" },
  "#7998F8": { main: "#7998F8", pastel: "#7998F8", light: "#7998F8" },
  "#8E2A1C": { main: "#8E2A1C", pastel: "#8E2A1C", light: "#8E2A1C" },
  "#D94626": { main: "#D94626", pastel: "#D94626", light: "#D94626" },
  "#27574B": { main: "#27574B", pastel: "#27574B", light: "#27574B" },
  "#3F8872": { main: "#3F8872", pastel: "#3F8872", light: "#3F8872" },
  "#8D264A": { main: "#8D264A", pastel: "#8D264A", light: "#8D264A" },
  "#C83962": { main: "#C83962", pastel: "#C83962", light: "#C83962" },
  "#E67D97": { main: "#E67D97", pastel: "#E67D97", light: "#E67D97" },
  "#AA5120": { main: "#AA5120", pastel: "#AA5120", light: "#AA5120" },
  "#EB9838": { main: "#EB9838", pastel: "#EB9838", light: "#EB9838" },
  "#F4B948": { main: "#F4B948", pastel: "#F4B948", light: "#F4B948" },
  "#4D4851": { main: "#4D4851", pastel: "#4D4851", light: "#4D4851" },
  "#7B7382": { main: "#7B7382", pastel: "#7B7382", light: "#7B7382" },
  "#B8B3BC": { main: "#B8B3BC", pastel: "#B8B3BC", light: "#B8B3BC" },
};

export const projectColorKeys: Array<keyof typeof projectColorMap> = Object.keys(projectColorMap) as Array<
  keyof typeof projectColorMap
>;
export interface ProjectColorType {
  main: string;
  pastel: string;
  light: string;
}

export const DEFAULT_COLOR_KEY = "#572E9E";
