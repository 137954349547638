import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

export const NewProjectIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-text-default", props.className)}
    >
      <path
        d="M6.34667 2C6.54651 2.00002 6.74379 2.04497 6.92392 2.13152C7.10406 2.21807 7.26243 2.344 7.38733 2.5L8.32066 3.66667H13.3333C13.687 3.66667 14.0261 3.80714 14.2761 4.05719C14.5262 4.30724 14.6667 4.64638 14.6667 5V12.6667C14.6667 13.0203 14.5262 13.3594 14.2761 13.6095C14.0261 13.8595 13.687 14 13.3333 14H2.66667C2.31304 14 1.9739 13.8595 1.72386 13.6095C1.47381 13.3594 1.33333 13.0203 1.33333 12.6667V3.33333C1.33333 2.97971 1.47381 2.64057 1.72386 2.39052C1.9739 2.14048 2.31304 2 2.66667 2H6.34667ZM6.34667 3.33333H2.66667V12.6667H13.3333V5H8.32C8.12015 4.99998 7.92287 4.95503 7.74274 4.86848C7.56261 4.78193 7.40423 4.656 7.27933 4.5L6.346 3.33333H6.34667ZM8 6C8.17681 6 8.34638 6.07024 8.4714 6.19526C8.59643 6.32029 8.66667 6.48986 8.66667 6.66667V8H10C10.1768 8 10.3464 8.07024 10.4714 8.19526C10.5964 8.32029 10.6667 8.48986 10.6667 8.66667C10.6667 8.84348 10.5964 9.01305 10.4714 9.13807C10.3464 9.2631 10.1768 9.33333 10 9.33333H8.66667V10.6667C8.66667 10.8435 8.59643 11.013 8.4714 11.1381C8.34638 11.2631 8.17681 11.3333 8 11.3333C7.82319 11.3333 7.65362 11.2631 7.52859 11.1381C7.40357 11.013 7.33333 10.8435 7.33333 10.6667V9.33333H6C5.82319 9.33333 5.65362 9.2631 5.52859 9.13807C5.40357 9.01305 5.33333 8.84348 5.33333 8.66667C5.33333 8.48986 5.40357 8.32029 5.52859 8.19526C5.65362 8.07024 5.82319 8 6 8H7.33333V6.66667C7.33333 6.48986 7.40357 6.32029 7.52859 6.19526C7.65362 6.07024 7.82319 6 8 6Z"
        fill="inherit"
      />
    </svg>
  );
};

export default NewProjectIcon;
