import { z } from "zod";

import { folderElementSchema } from "@/types/schemas";

export type EventSchema = {
  id: string;
  name: string;
  type: string;
  date: Date;
};

export type CreateEventSchema = Omit<EventSchema, "id">;

export type CreateProjectWithDeadlinesSchema = {
  name: string;
  events: CreateEventSchema[];
};

export enum ProjectIconsEnum {
  CHART = "CHART",
  BOOK = "BOOK",
  GATE = "GATE",
  MESSAGE_DOTS = "MESSAGE_DOTS",
  FOLDER = "FOLDER",
  SUN_CLOUD = "SUN_CLOUD",
  BUS = "BUS",
  UFO = "UFO",
  SOCKS = "SOCKS",
  FINGER_ROCK = "FINGER_ROCK",
  BOMB = "BOMB",
  RABBIT = "RABBIT",
  MUSHROOM = "MUSHROOM",
  TULIP = "TULIP",
  CAMERA = "CAMERA",
  MUSIC = "MUSIC",
  FILM = "FILM",
  ROAD = "ROAD",
  UMBRELLA = "UMBRELLA",
  COMPASS = "COMPASS",
  EARTH = "EARTH",
  FOOT = "FOOT",
  WINE_GLASS = "WINE_GLASS",
  ICE_CREAM = "ICE_CREAM",
  CARROT = "CARROT",
  WINE_BOTTLE_AND_GLASS = "WINE_BOTTLE_AND_GLASS",
  PAPER = "PAPER",
  ARCHIVE = "ARCHIVE",
  DIZZY_FACE = "DIZZY_FACE",
  SWEAT_FACE = "SWEAT_FACE",
  CLUESESS_FACE = "CLUESESS_FACE",
  LEFT_FACE = "LEFT_FACE",
  WINKING_FACE = "WINKING_FACE",
  ANGEL_FACE = "ANGEL_FACE",
  ATOM = "ATOM",
  MAGNET = "MAGNET",
  GLOBE = "GLOBE",
  POTION = "POTION",
  DIARY = "DIARY",
  CALCULATOR = "CALCULATOR",
  COMPASS_2 = "COMPASS_2",
  BOOKMARK = "BOOKMARK",
  BOOK_2 = "BOOK_2",
  BALANCE = "BALANCE",
  BACKPACK = "BACKPACK",
  TRANSLATE = "TRANSLATE",
  RULER_PENCIL = "RULER_PENCIL",
  ASTERISK = "ASTERISK",
  TELESCOPE = "TELESCOPE",
  TELESCOPE_2 = "TELESCOPE_2",
  SPEAKER = "SPEAKER",
  SOLAR_PANEL = "SOLAR_PANEL",
  ANTENNA = "ANTENNA",
  RADIO = "RADIO",
  PUZZLE = "PUZZLE",
  MIDI = "MIDI",
  POWER_LINE = "POWER_LINE",
  KEYBOARD = "KEYBOARD",
  MICROSCOPE = "MICROSCOPE",
  LAPTOP = "LAPTOP",
  GUITAR = "GUITAR",
  CONTROLLER = "CONTROLLER",
  LIGHTNING = "LIGHTNING",
  LAMP = "LAMP",
  CAMCODER = "CAMCODER",
  BULB = "BULB",
  ALARM = "ALARM",
  CURSOR = "CURSOR",
  CLICK = "CLICK",
  HTML = "HTML",
  LTGT = "LTGT",
  BUG = "BUG",
  NOTE = "NOTE",
  RULER = "RULER",
  SCRISSORS = "SCRISSORS",
  FEATHER = "FEATHER",
  PAINT_ROLL = "PAINT_ROLL",
  PALETTE = "PALETTE",
  WIZARD_HAT = "WIZARD_HAT",
  LAYERS = "LAYERS",
  FLASHCARDS = "FLASHCARDS",
  BLING = "BLING",
  MAIL = "MAIL",
  EMAIL = "EMAIL",
  ENVELOPE = "ENVELOPE",
  TARGET = "TARGET",
  WALLET = "WALLET",
  TAG = "TAG",
  SOFA = "SOFA",
  PRESENTATION_1 = "PRESENTATION_1",
  PRESENTATION_2 = "PRESENTATION_2",
  BAGGAGE = "BAGGAGE",
  MONEY_PIG = "MONEY_PIG",
  COIN = "COIN",
  PIE_CHART = "PIE_CHART",
  BAR_CHART = "BAR_CHART",
  CASH = "CASH",
  CELEBRATE = "CELEBRATE",
  CALENDAR = "CALENDAR",
  SHOPPING_BASKET = "SHOPPING_BASKET",
  ANNIVERSARY = "ANNIVERSARY",
  AD = "AD",
  SYDNEY = "SYDNEY",
  NEW_YORK = "NEW_YORK",
  PIZZA_TOWER = "PIZZA_TOWER",
  HOSPITAL = "HOSPITAL",
  WINDMILL = "WINDMILL",
  PYRAMIDS = "PYRAMIDS",
  BRIDGE = "BRIDGE",
  BANK = "BANK",
}
const createProjectSchema = z
  .object({
    name: z.string(),
    workspaceId: z.string(),
    colorTag: z.string().optional(),
    projectIcon: z.nativeEnum(ProjectIconsEnum),
  })
  .strict();

export type CreateProject = z.infer<typeof createProjectSchema>;

const updateProjectSchema = z
  .object({
    name: z.string(),
    colorTag: z.string().optional(),
    projectIcon: z.string().optional(),
  })
  .strict();

export type UpdateProjectSchema = z.infer<typeof updateProjectSchema>;

const shareProjectSchema = z
  .object({
    projectId: z.string(),
    email: z.string(),
  })
  .strict();
export type ShareProjectRequest = z.infer<typeof shareProjectSchema>;

export const acceptShareWorkspaceSchema = z
  .object({
    encodedWorkspaceId: z.string(),
  })
  .strict();

export type AcceptShareWorkspace = z.infer<typeof acceptShareWorkspaceSchema>;

export const projectProcessingStatusSchema = z
  .object({
    allDocumentsProcessed: z.boolean(),
    documentStatuses: z.array(z.object({ id: z.string(), docProcessedStatus: z.string() })),
  })
  .strict();

export type ProjectProcessingStatus = z.infer<typeof projectProcessingStatusSchema>;

const baseProjectSchema = z
  .object({
    id: z.string(),
    userId: z.string(),
    name: z.string(),
    projectIcon: z.string().nullable(),
    colorTag: z.string().nullish(),
    sharedWithUserIds: z.array(z.string()).optional(),
  })
  .strict();

export type BaseProjectSchema = z.infer<typeof baseProjectSchema>;

export const projectEntitySchema = baseProjectSchema
  .extend({
    workspaceId: z.string(),
    coverImageUrl: z.string().nullish(),
    directChildElements: z.array(folderElementSchema),
    dateCreated: z.string(),
  })
  .strict();

export type ProjectEntity = z.infer<typeof projectEntitySchema>;

const responseProjectEntitySchema = projectEntitySchema.extend({
  dateCreated: z.coerce.date(),
  dateLastOpen: z.coerce.date(),
});

export type ResponseProjectEntity = z.infer<typeof responseProjectEntitySchema>;

export const baseProjectSchemaArray = z.array(baseProjectSchema);
