import { useCallback } from "react";

import { useBoolean } from "usehooks-ts";

import { BACKEND_URL } from "@/assets/constants/constants.ts";
import { QuinoTooltip } from "@/components/generic";
import { DownloadSummaryIcon } from "@/components/icons/summary";
import { toast } from "@/components/ui/use-toast.ts";
import { DemoHandlerButton } from "@/components/v3";
import { getAccessToken } from "@/firebase";
import { mixpanelTrack } from "@/service/mixpanel";
import { useExportHandler } from "@/service/subscription";
import { type SummaryResponse } from "@/types/schemas";

const ExportSummaryToPdfLink = ({ documentId, summary }: { documentId: string; summary: SummaryResponse }) => {
  const { value, setValue } = useBoolean(false);

  const executeExport = useExportHandler(
    useCallback(async () => {
      setValue(true);
      const response = await fetch(
        `${BACKEND_URL}/document-converter/summary-pdf-export/${documentId}/${summary.language}`,
        {
          headers: { Authorization: `Bearer ${await getAccessToken()}` },
        },
      );

      if (!response.ok) {
        toast({
          title: "Something went wrong with the export",
          description: `Error code: ${response.status}`,
          variant: "destructive",
        });
        return;
      }

      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = objectUrl;
      link.download = `${summary.title}.pdf`;
      link.click();

      window.URL.revokeObjectURL(objectUrl);

      link.remove();

      mixpanelTrack("summary_exported");
      setValue(false);
    }, [documentId, summary, setValue]),
  );

  return (
    <QuinoTooltip align="end" className="font-semibold" content={"Export to PDF"} side="bottom">
      <DemoHandlerButton
        className="h-12 w-12 rounded-none p-4"
        disabled={value}
        size="icon"
        variant="icon"
        onClick={executeExport}
      >
        <DownloadSummaryIcon />
      </DemoHandlerButton>
    </QuinoTooltip>
  );
};

export default ExportSummaryToPdfLink;
