import { useMemo } from "react";

import { useActivityHistory } from "@/api/user.ts";
import { useWorkspaces } from "@/api/workspace.ts";
import { ActivityFeedList, RecentlyUsed } from "@/components/v3";
import EmptyActivityFeed from "@/components/v3/home/empty-activity-feed.tsx";
import { InfiniteLoading } from "@/pages/loading";

const useInitActivityFeed = () => {
  useWorkspaces();
};

const ActivityFeed = () => {
  useInitActivityFeed();
  const { data, isLoading } = useActivityHistory();

  const content = useMemo(() => {
    if (isLoading) return <InfiniteLoading />;
    if (!data || data.pages.length === 0 || data.pages[0].activityHistoryList.length === 0)
      return <EmptyActivityFeed />;
    return (
      <section className="grid grid-cols-12">
        <section className="col-span-8">
          <ActivityFeedList />
        </section>
        <section className="col-span-4 pl-10">
          <RecentlyUsed />
        </section>
      </section>
    );
  }, [data, isLoading]);

  return <section className="col-span-8 flex h-full flex-col gap-6">{content}</section>;
};
export default ActivityFeed;
