import { type StateCreator } from "zustand";

import { type AppSlice, type NavigationDrawerState } from "@/types/app";

const drawerStateSlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], NavigationDrawerState> = (set) => ({
  isDrawerOpen: false,
  toggleDrawer: () => set((state) => ({ isDrawerOpen: !state.isDrawerOpen })),
  setDrawerOpen: (value: boolean) => set({ isDrawerOpen: value }, false, "setPdfIconClicked"),
});

export default drawerStateSlice;
