import { isMobile } from "react-device-detect";
import { Navigate, Outlet } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { ROUTE_PATHS } from "@/assets/constants/constants.ts";
import { IDS } from "@/assets/constants/demo";
import MobileDisabled from "@/pages/mobile-disabled";
import { useCurrentWorkspaceId, useDocumentChatSessionId } from "@/service/hooks/react-router.ts";

const MobileDemoRoute = () => {
  const [sessionId, setSesionId] = useDocumentChatSessionId();
  const workspaceId = useCurrentWorkspaceId();

  useEffectOnce(() => {
    if (sessionId) setSesionId("");
  });

  if (isMobile) return <MobileDisabled />;
  if (!workspaceId) return <Navigate to={`${ROUTE_PATHS.WORKSPACES}/${IDS.WORKSPACE.WORKSPACE_ID_ONE}`} />;
  return <Outlet />;
};

export default MobileDemoRoute;
