export enum FunnyStuffType {
  quino_project_728x90 = "quino_project_728x90",
  quino_project_300x250 = "quino_project_300x250",
  quino_library_728x90 = "quino_library_728x90",
  quino_library_320x50 = "quino_library_320x50",
  quino_library_300x250 = "quino_library_300x250",
  quino_knowledge_320x50 = "quino_knowledge_320x50",
  quino_knowledge_300x250 = "quino_knowledge_300x250",
  quino_knowledge_728x90 = "quino_knowledge_728x90",
  quino_doc_300x250 = "quino_doc_300x250",
  quino_doc_728x90 = "quino_doc_728x90",
  quino_doc_320x50 = "quino_doc_320x50",
  quino_note_728x90 = "quino_note_728x90",
  quino_note_300x250 = "quino_note_300x250",
  quino_note_320x50 = "quino_note_320x50",
}
