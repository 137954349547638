import { useState } from "react";

import { Button } from "@/components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { MoveIcon, ProjectSelector, QuinoPopoverTitle } from "@/components/v3";

const MoveSelectedResourceButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button size="sm" variant="ghost">
          <MoveIcon />
          Move
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[25rem] space-y-1">
        <QuinoPopoverTitle title="Move documents" />
        <section className="flex flex-row items-center gap-2 p-2 pt-0">
          <ProjectSelector placeholder="Move to..." />
          <Button size="sm" onClick={() => setIsOpen(false)}>
            Move
          </Button>
        </section>
      </PopoverContent>
    </Popover>
  );
};

export default MoveSelectedResourceButton;
