import { useEffect } from "react";

import { Outlet } from "react-router-dom";

import { useSubscription } from "@/api/resources";
import { PASSWORD_PROVIDER_NAME } from "@/assets/constants/firestore-constants";
import { ModalKeys } from "@/assets/constants/modal";
import { GraphTopLeft } from "@/components/icons/login";
import { LogoWhite } from "@/components/icons/login";
import { GraphTopRight } from "@/components/icons/login";
import { GraphBottomLeft } from "@/components/icons/login";
import { GraphBottomMiddle } from "@/components/icons/login";
import { GraphBottomRight } from "@/components/icons/login";
import { OnboardingModal } from "@/components/v3/modals/onboarding";
import { useAuthUserData, useUpdateFirebaseUserData } from "@/firebase/hooks";
import useAppStateStore from "@/zustand/store";

const Authenticated = () => {
  const { data: userData, isLoading, firebaseUser } = useAuthUserData();
  const { data: subscription } = useSubscription();
  const setModalKey = useAppStateStore((state) => state.setModalKey);
  const { mutate: updateUser, isSuccess } = useUpdateFirebaseUserData();

  useEffect(() => {
    if (firebaseUser?.emailVerified && userData && !userData.email_verified) {
      updateUser({ payload: { ...userData, email_verified: true } });
    }
  }, [firebaseUser, firebaseUser?.emailVerified, updateUser, userData]);

  useEffect(() => {
    if (!userData || !firebaseUser) return;

    if (userData.provider === PASSWORD_PROVIDER_NAME && !firebaseUser.emailVerified) {
      setModalKey(ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN, { open: true, sendAtStart: true });
    } else {
      setModalKey(ModalKeys.EMAIL_VERIFICATION_MODAL_OPEN, { open: false });
    }
  }, [firebaseUser, isSuccess, setModalKey, userData]);

  if (isLoading || !subscription) return null;

  if (firebaseUser?.emailVerified === false && userData?.provider === PASSWORD_PROVIDER_NAME) {
    return (
      <div className="relative h-screen w-screen bg-primitive-purple-600">
        <GraphTopLeft className="absolute left-[82px] top-[50.66px] h-[268px] w-[260px]" />
        <LogoWhite className="absolute left-1/2 top-[50px] h-[47.73px] w-[172px] translate-x-[-50%]" />
        <GraphTopRight className="absolute right-[35px] top-[144px] hidden h-[137px] w-[145px] sm:block" />
        <GraphBottomLeft className="absolute bottom-[25.09px] left-[47px] hidden h-[278.91px] w-[282px] sm:block" />
        <GraphBottomMiddle className="absolute -bottom-18 left-1/2 h-[221.56px] w-[203.62px] translate-x-[-50%]" />
        <GraphBottomRight className="absolute bottom-[46.96px] right-[20px] h-[303.04px] w-[300px]" />
      </div>
    );
  }

  if (userData) {
    return (
      <>
        <Outlet />
        <OnboardingModal />
      </>
    );
  }

  return null;
};

export default Authenticated;
