import { type ElementRef, type HTMLProps, useMemo } from "react";

import { useIsMutating } from "@tanstack/react-query";
import ReactLottie from "react-lottie";

import infiniteLoadingData from "@/assets/animation/lottie/load-infinite.json";
import animationData from "@/assets/animation/lottie/new-loading-animation.json";
import animationDataSecondary from "@/assets/animation/lottie/quino-loading-secondary.json";
import { Spinner } from "@/components/icons/common";
import { useThemeMode } from "@/service/hooks/misc.ts";
import { cn } from "@/utils";

export const LottieLoadInfinite = ({}: { className?: string }) => {
  return (
    <ReactLottie
      height={160}
      options={{
        animationData: infiniteLoadingData,
        loop: true,
        autoplay: true,
      }}
      speed={2}
      width={160}
    />
  );
};

const useAnimation = () => {
  const [mode] = useThemeMode();

  return useMemo(() => (mode === "dark" ? animationData : animationData), [mode]);
};

export const InfiniteLoading = ({ value, className }: { value?: number; className?: string }) => {
  return (
    <section className={cn("relative flex flex-row items-center gap-2", className)}>
      <LottieLoadInfinite className="h-40 w-40 animate-spin stroke-primitive-purple-600" />
      {value !== undefined && (
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-base font-bold tracking-tight">
          {value.toFixed(0)}%
        </span>
      )}
    </section>
  );
};

export const LoadingSpinner = (props: HTMLProps<ElementRef<"section">>) => (
  <span {...props} className={cn("flex h-full w-full flex-col items-center justify-center", props.className)}>
    <Spinner className="h-20 w-20 stroke-primary dark:stroke-white" />
  </span>
);

export const LoadingPanel = ({ className }: { className?: string }) => (
  <section className={cn("flex flex-row items-center justify-center", className)}>
    <ReactLottie
      options={{
        loop: true,
        animationData: animationDataSecondary,
      }}
    />
  </section>
);
const Loading = ({ onLoopFinished }: { onLoopFinished?: () => void }) => {
  const animation = useAnimation();
  return (
    <main className="mx-auto flex min-h-screen w-full  flex-col items-center justify-center bg-primitive-purple-600 dark:bg-primary-dark">
      <section className="flex max-w-sm flex-row items-center justify-center">
        <ReactLottie
          eventListeners={[
            {
              eventName: "loopComplete",
              callback: () => {
                console.log("loop finished");
                onLoopFinished?.();
              },
            },
          ]}
          options={{
            loop: true,
            animationData: animation,
            rendererSettings: {},
          }}
        />
      </section>
    </main>
  );
};

export const LoadingBase = ({ title = "" }: { title?: string }) => {
  return (
    <main className="mx-auto flex min-h-screen w-full  flex-col items-center justify-center bg-default">
      <section className="flex max-w-sm flex-row items-center justify-center">
        {title ? (
          <>{title}</>
        ) : (
          <ReactLottie
            options={{
              loop: true,
              animationData: infiniteLoadingData,
            }}
          />
        )}
      </section>
    </main>
  );
};

export const NoteViewLoadingScreen = () => {
  const isLoadingGenerateCard = useIsMutating({ mutationKey: ["generate-flash-card"] });
  const isCreateFlashCardL = useIsMutating({ mutationKey: ["create-flash-card"] });
  const isLoading = useMemo(
    () => isLoadingGenerateCard || isCreateFlashCardL,
    [isCreateFlashCardL, isLoadingGenerateCard],
  );
  const isLoadingCreate = useIsMutating({ mutationKey: ["session-chat", "create"] });
  if (isLoading || isLoadingCreate)
    return (
      <LoadingPanel className="absolute z-[1000] h-full w-full bg-default/50 px-[25%] dark:bg-primary-dark-deep/90" />
    );
  return null;
};

export default Loading;
