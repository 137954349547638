import { Button } from "@/components/ui/button.tsx";
import { Drawer, DrawerContent, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { MenuIcon } from "@/components/v3";
import useAppStateStore from "@/zustand/store.ts";

import WorkspaceSidebar from "../workspaces/workspace-sidebar.tsx";

const QuinoNavigationDrawer = () => {
  const { toggleDrawer, isDrawerOpen, setDrawerOpen } = useAppStateStore((state) => ({
    setDrawerOpen: state.setDrawerOpen,
    toggleDrawer: state.toggleDrawer,
    isDrawerOpen: state.isDrawerOpen,
  }));
  return (
    <Drawer direction="left" open={isDrawerOpen} onOpenChange={setDrawerOpen}>
      <DrawerTrigger asChild>
        <Button className="flex h-12 w-12 p-4 lg:hidden " size="icon" variant="icon" onClick={toggleDrawer}>
          <MenuIcon />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerTitle className="hidden"></DrawerTitle>
        <WorkspaceSidebar />
      </DrawerContent>
    </Drawer>
  );
};
export default QuinoNavigationDrawer;
