import { useEffect, useMemo } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { useCreateNote } from "@/api/note.ts";
import { QUERY_PARAMS } from "@/assets/constants/constants";
import { NoteTabContent } from "@/components/note";
import NoteEditorHeader from "@/components/note/note-editor/note-editor-header.tsx";
import { useNotesFromProject } from "@/service/note";

const NotesTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId = "", workspaceId = "" } = useParams();
  const { mutate: createNewNote } = useCreateNote();

  const activeNoteId = useMemo(() => {
    return searchParams.get(QUERY_PARAMS.NOTE);
  }, [searchParams]);

  const { notes, isSuccess } = useNotesFromProject(workspaceId, projectId);

  useEffect(() => {
    if (!isSuccess) return;
    if (!activeNoteId && notes.length > 0) {
      searchParams.set(QUERY_PARAMS.NOTE, notes.at(0)?.id ?? "");
      setSearchParams(searchParams);
    } else if (notes.length === 0) {
      createNewNote(
        {
          projectId,
          workspaceId,
          name: "New note",
        },
        {
          onSuccess: (data) => {
            searchParams.set(QUERY_PARAMS.NOTE, data.id);
            setSearchParams(searchParams);
          },
        },
      );
    }
  }, [activeNoteId, createNewNote, isSuccess, notes, projectId, searchParams, setSearchParams, workspaceId]);

  return (
    <section className="relative flex h-full w-full flex-col overflow-hidden">
      <NoteEditorHeader />
      {activeNoteId && <NoteTabContent id={activeNoteId} />}
    </section>
  );
};

export default NotesTabs;
