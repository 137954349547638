import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const SplitViewIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-4 w-4 fill-default", props.className)}
  >
    <path
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm5 2h9v14h-9V5ZM8 5H5v14h3V5Z"
      fill="inherit"
      fillRule="evenodd"
    />
  </svg>
);
export default SplitViewIcon;
