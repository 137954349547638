import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import {
  headingsPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
} from "@mdxeditor/editor";
import { Document, Font, Page, View, BlobProvider, StyleSheet } from "@react-pdf/renderer";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Html from "react-pdf-html";
import { Link } from "react-router-dom";
import { useDebounce, useInterval } from "usehooks-ts";

import { useNote } from "@/api/note.ts";
import { NOTE_TABS } from "@/assets/constants/constants.ts";
import DownloadButton from "@/components/button/download-button.tsx";
import { QuinoMark } from "@/components/note/pdf-image";
import { useIsDemoLikePage, useTypographyConfig } from "@/service/hooks/misc.ts";
import { useCurrentNoteId, useCurrentWorkspaceId, useIsLibraryPage } from "@/service/hooks/react-router.ts";
import { useIsInLimitedFreeUserGroup } from "@/service/hooks/settings.ts";
import { useOpenSignUpToQuinoModal, useUpgradePlanModal } from "@/service/library.ts";
import { useMixpanelTrack } from "@/service/mixpanel";
import { useBucketImageUrl } from "@/service/note";
import { type ExportButtonType } from "@/types/app.ts";
import { type ImageNoteParagraph } from "@/types/schemas";

import OpenSansBold from "../../assets/fonts/OpenSans-Bold.woff";
import OpenSansBoldItalic from "../../assets/fonts/OpenSans-BoldItalic.woff";
import OpenSansRegularItalic from "../../assets/fonts/OpenSans-Italic.woff";
import OpenSansRegular from "../../assets/fonts/OpenSans-Regular.woff";

const filteredCssProperties = [
  "color",
  "background",
  "background-color",
  "font",
  "font-weight",
  "font-size",
  "border-left-color",
  "border-left-style",
  "border-left-width",
  "padding-left",
  "margin-left",
  "margin-right",
  "text-decoration",
  "text-decoration-color",
  "text-decoration-style",
];

function applyInlineStyles(element: HTMLElement): HTMLElement {
  let styledElement = element.cloneNode(true) as HTMLElement;

  if (element.nodeType === Node.ELEMENT_NODE) {
    const computedStyles = getComputedStyle(element);
    const newTag = ["BLOCKQUOTE"].includes(element.tagName) ? "p" : element.tagName;

    styledElement = document.createElement(newTag);

    if (element instanceof HTMLImageElement && styledElement instanceof HTMLImageElement) {
      styledElement.src = element.src;
      styledElement.style.width = computedStyles.getPropertyValue("width");
      styledElement.style.maxWidth = "100%";
    }

    for (let i = 0; i < computedStyles.length; i++) {
      const cssProperty = computedStyles[i];

      if (filteredCssProperties.includes(cssProperty)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (styledElement.style as unknown)[cssProperty] = computedStyles.getPropertyValue(cssProperty);
      }
    }

    if (styledElement.tagName === "UL" || styledElement.tagName === "OL") {
      styledElement.style.marginLeft = "-40px";
    }
    if (styledElement.style.textDecorationLine === "underline") {
      styledElement.style.textDecorationLine = "";
      styledElement.style.textDecoration = "underline";
      styledElement.className = "forced-underline";
    } else if (styledElement.style.textDecorationLine === "none") {
      styledElement.style.textDecoration = "";
    }

    element.childNodes.forEach((child) => {
      const childNode = child as HTMLElement;
      const styledChild = applyInlineStyles(childNode);
      styledElement.appendChild(styledChild);
    });
  }

  return styledElement;
}

export function getInlineHTML(element: HTMLElement): string {
  const styledClone = applyInlineStyles(element);

  return styledClone.outerHTML;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
Font.register({
  family: "Open Sans",
  fonts: [
    { src: OpenSansRegular, fontStyle: "normal", fontWeight: 400 },
    { src: OpenSansRegularItalic, fontStyle: "italic", fontWeight: 400 },
    { src: OpenSansBold, fontStyle: "normal", fontWeight: 700 },
    { src: OpenSansBoldItalic, fontStyle: "italic", fontWeight: 700 },
  ],
});

function ConvertedDocument({ html, backgroundColor }: { html: string; backgroundColor: string }): JSX.Element {
  const styles = StyleSheet.create({
    "*": {
      fontFamily: "Open Sans",
    },
    watermark: {
      position: "absolute",
      top: "30%",
      left: "0",
      width: "100%",
      height: "auto",
      transform: "translateX(50%) translateY(-50%)",
    },
  });
  return (
    <Document>
      <Page style={{ backgroundColor, padding: "40px 56px" }}>
        <View>
          <Html stylesheet={styles}>{html}</Html>
        </View>
        <QuinoMark fixed style={styles.watermark} />
      </Page>
    </Document>
  );
}
// I am sorry I had to do this, I will rewrite it eventually...
const ExportNoteToPdf = ({
  workspaceId,
  noteId,
  disabled = false,
  variant,
}: {
  workspaceId: string;
  noteId: string;
  disabled?: boolean;
  variant?: ExportButtonType;
}) => {
  const mixpanelTrack = useMixpanelTrack();
  const [html, setHtml] = useState("");
  const { data: noteData } = useNote(workspaceId, noteId, { enabled: !disabled && noteId !== NOTE_TABS.OPEN_NEW });
  const [, setModalKey] = useUpgradePlanModal();

  const isFreeUser = useIsInLimitedFreeUserGroup();
  const { matched: isDemo } = useIsDemoLikePage();
  const openSignUp = useOpenSignUpToQuinoModal();
  const fileName = useMemo(() => `Note_Export_${noteData?.name.trim()}.pdf`, [noteData?.name]);

  const canRender = useMemo(() => (!html ? 100 : null), [html]);

  useEffect(() => {
    setHtml("");
  }, [noteData]);

  useInterval(() => {
    if (!noteData?.noteParagraphs || isFreeUser) return "";

    const result = noteData.noteParagraphs
      .sort((a, b) => a.position - b.position)
      .map((item) => {
        const res = document.getElementsByClassName(`export-${item.id}-paragraph`);
        if (res.length > 0) return res.item(0) as HTMLElement | null;
        return null;
      })
      .filter((item) => item !== null)
      .map((item) => getInlineHTML(item as HTMLElement))
      .join("\n");

    setHtml(result);
  }, canRender);

  const finalHtml = useMemo(
    () =>
      "<section style='display: flex; flex-direction: column; justify-content: center; overflow: hidden; padding: 10px'>" +
      "<p style='color: #33124d; font-weight: bold; text-align: center; width: 100%; border-bottom: 2px solid #33124d; padding-bottom: 8px'>" +
      noteData?.name +
      "<br/> exported from <a href='https://quino.ai'>Quino AI</a>" +
      "</p>" +
      html +
      "</section>",
    [html, noteData?.name],
  );

  const onFreeUserClick = useCallback(() => {
    if (isDemo) {
      openSignUp();
    } else setModalKey({ open: true });
  }, [isDemo, openSignUp, setModalKey]);

  if (isFreeUser) {
    return <DownloadButton disabled={false} variant={variant} onClick={onFreeUserClick} />;
  }

  return (
    <BlobProvider document={<ConvertedDocument backgroundColor={"#FFF"} html={finalHtml} />}>
      {({ url }) => (
        <Link download={fileName} target="_blank" to={url as string}>
          <DownloadButton
            disabled={disabled}
            variant={variant}
            onClick={(e) => {
              e.stopPropagation();
              mixpanelTrack("note_download_button_clicked");
            }}
          />
        </Link>
      )}
    </BlobProvider>
  );
};

const NoteImageComponent = ({ noteParagraph }: { noteParagraph: ImageNoteParagraph & { userId: string } }) => {
  const imageUrl = useBucketImageUrl(
    "NOTE",
    `${noteParagraph.noteId}/${noteParagraph.imageCuid}/${noteParagraph.userId}`,
  );

  return (
    <section className="flex flex-col">
      <img alt="image-paragraph" className="h-full w-full self-start" src={imageUrl} />
    </section>
  );
};

const ExportNoteToPdfWrapper = React.forwardRef<
  HTMLButtonElement,
  {
    disabled?: boolean;
    variant?: ExportButtonType;
  }
>(({ disabled, variant }) => {
  const [noteId] = useCurrentNoteId();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const isLibraryPage = useIsLibraryPage();
  const { data: noteData } = useNote(currentWorkspaceId, noteId, {
    enabled: !disabled && noteId !== NOTE_TABS.OPEN_NEW,
  });
  const [config] = useTypographyConfig();
  const isFreeUser = useIsInLimitedFreeUserGroup();

  const rawHtmlData = useMemo(() => {
    if (!isLibraryPage || isFreeUser) return <></>;
    return (
      <>
        {noteData?.noteParagraphs.map((noteParagraph) => {
          const id = noteParagraph.id;
          if (noteParagraph.noteParagraphType === "IMAGE")
            return (
              <section
                className={`flex select-none flex-row justify-center export-${noteParagraph.id}-paragraph`}
                key={id}
              >
                <NoteImageComponent noteParagraph={{ ...noteParagraph, userId: noteData.userId }} />
              </section>
            );
          if (noteParagraph.noteParagraphType === "TEXT")
            return (
              <MDXEditor
                contentEditableClassName={`prose export-${id}-paragraph prose-size-${config.size}`}
                key={id}
                markdown={noteParagraph.markdownText}
                placeholder="Add text here..."
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  quotePlugin(),
                  thematicBreakPlugin(),
                  linkPlugin(),
                  tablePlugin(),
                  markdownShortcutPlugin(),
                ]}
                readOnly={true}
              />
            );
          return <Fragment key={id} />;
        })}
      </>
    );
  }, [config.size, isFreeUser, isLibraryPage, noteData?.noteParagraphs, noteData?.userId]);

  const debouncedValue = useDebounce(rawHtmlData, 1000);

  return (
    <>
      <div className="-z-100 fixed hidden">{rawHtmlData}</div>
      {!debouncedValue && <DownloadButton variant="text" />}
      {debouncedValue && <ExportNoteToPdf noteId={noteId} variant={variant} workspaceId={currentWorkspaceId} />}
    </>
  );
});

ExportNoteToPdfWrapper.displayName = "ExportNoteToPdfWrapper";
export default ExportNoteToPdfWrapper;
