import { toast } from "@/components/ui/use-toast.ts";
import { LoadingSpinner } from "@/pages/loading";

export const loadingClaimToast = () =>
  toast({
    variant: "default",
    description: (
      <p className="flex flex-row items-center justify-between">
        <h2>Claiming summary...</h2>
        <span className="!h-10 !w-10">
          <LoadingSpinner className="h-10 w-10" />
        </span>
      </p>
    ),
  });
