import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const More = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-4 w-4 fill-primitive-grey-600", props.className)}
  >
    <path
      d="M6 10.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
      fill="inherit"
    />
  </svg>
);
export default More;
