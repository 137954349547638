import { useMemo } from "react";

import { ResourceCardType } from "@/assets/constants/constants.ts";
import {
  GridViewToggle,
  NewProjectButton,
  SelectedResourceToolbarContainer,
  SortWorkspaceExplorer,
  SelectedProjectToolbarContainer,
  ShareWorkspacePopover,
} from "@/components/v3";
import AddDocumentsButton from "@/components/v3/workspaces/workspace-explorer-header/add-documents-button.tsx";
import { useCurrentProjectData } from "@/utils";
import useAppStateStore from "@/zustand/store.ts";

import ChatWithProjectButton from "./chat-with-project-button.tsx";

const WorkspaceExplorerHeader = () => {
  const { data: currentProject } = useCurrentProjectData();
  const [selectedResources, selectedProjects] = useAppStateStore((state) => [
    state.selectedResources,
    state.selectedProjects,
  ]);

  const crudButtons = useMemo(() => {
    if (selectedProjects.length > 0 && !currentProject) return <SelectedProjectToolbarContainer />;
    if (selectedResources.length > 0 && currentProject) return <SelectedResourceToolbarContainer />;

    if (currentProject) {
      return (
        <>
          <AddDocumentsButton />
          <ShareWorkspacePopover />
          {currentProject.directChildElements.filter((item) => item.elementType === ResourceCardType.DOCUMENT_ELEMENT)
            .length > 0 && <ChatWithProjectButton />}
        </>
      );
    }

    return (
      <>
        <AddDocumentsButton />
        <NewProjectButton />
        <ShareWorkspacePopover />
      </>
    );
  }, [currentProject, selectedProjects.length, selectedResources.length]);

  return (
    <section className="flex w-full flex-row justify-between">
      <section className="flex flex-row gap-2">{crudButtons}</section>
      <section className="flex flex-row items-center gap-2">
        <SortWorkspaceExplorer />
        <GridViewToggle />
      </section>
    </section>
  );
};

export default WorkspaceExplorerHeader;
