import { useEffect, useState } from "react";

import { ROUTES } from "@/assets/constants/constants";
import MyUserAvatar from "@/components/user/my-user-avatar.tsx";
import { DemiHandlerNavLink, QuinoNavigationDrawer, SearchModal } from "@/components/v3";
import { QuinoLogoPurple } from "@/components/v3/icons";

const QuinoPageHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <section
      className="fixed z-[30] flex w-full flex-row items-center justify-between bg-primitive-purple-pale-50 px-2 py-4 transition-all duration-300 data-[is-scrolled=true]:border-b data-[is-scrolled=true]:border-b-stroke-default lg:px-6"
      data-is-scrolled={isScrolled}
    >
      <section className="flex flex-row items-center justify-between gap-1">
        <QuinoNavigationDrawer />
        <DemiHandlerNavLink to={ROUTES.HOME}>
          <QuinoLogoPurple />
        </DemiHandlerNavLink>
      </section>
      <section className="flex flex-row items-center gap-3">
        <SearchModal />
        <DemiHandlerNavLink to={ROUTES.ACCOUNT}>
          <MyUserAvatar />
        </DemiHandlerNavLink>
      </section>
    </section>
  );
};

export default QuinoPageHeader;
