import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const UserAdd = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 24 24"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("fill-default", props.className)}
      fillRule="evenodd"
    >
      <path
        clipRule="evenodd"
        d="M11 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10ZM8 7a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM4 18.5c0-.18.09-.489.413-.899.316-.4.804-.828 1.451-1.222C7.157 15.589 8.977 15 11 15c.375 0 .744.02 1.105.059a1 1 0 1 0 .211-1.99C11.887 13.025 11.447 13 11 13c-2.395 0-4.575.694-6.178 1.672-.8.488-1.484 1.064-1.978 1.69C2.358 16.976 2 17.713 2 18.5c0 .845.411 1.511 1.003 1.986.56.45 1.299.748 2.084.956C6.665 21.859 8.771 22 11 22l.685-.005a1 1 0 1 0-.027-2L11 20c-2.19 0-4.083-.143-5.4-.492-.663-.175-1.096-.382-1.345-.582C4.037 18.751 4 18.622 4 18.5ZM18 14a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default UserAdd;
