import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useCreateWorkspace } from "@/api/workspace.ts";
import { ROUTES } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { ToastActionBase } from "@/components/ui/toast.tsx";
import { useToast } from "@/components/ui/use-toast.ts";
import { CheckCircleFilled, ImageFileDropzone, ShareWorkspaceButton } from "@/components/v3";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const FormSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: "Note title must be at least 1 character",
    })
    .max(100, { message: "Must be at most 100 characters" }),
  image: z.instanceof(File).optional(),
});
type FormSchemaType = z.infer<typeof FormSchema>;

const CreateWorkspaceModal = () => {
  const { toast } = useToast();
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
    },
  });
  const navigate = useNavigate();

  const [state, setState] = useModalState(ModalKeys.CREATE_WORKSPACE);

  const { mutate: createWorkspace } = useCreateWorkspace();

  const handleSubmit = useCallback(
    ({ name, image }: FormSchemaType) => {
      createWorkspace(
        { name, coverImage: image },
        {
          onSuccess: (result) => {
            navigate(`${ROUTES.WORKSPACES}/${result.id}`);
            toast({
              icon: <CheckCircleFilled />,
              title: `${result.name} is now ready to use!`,
              description:
                "Enjoy its full potential with unlimited document summaries by inviting your friends as members.",
              action: (
                <ToastActionBase asChild altText="cancel">
                  <ShareWorkspaceButton workspaceId={result.id} />
                </ToastActionBase>
              ),
              secondaryAction: (
                <ToastActionBase asChild altText="cancel">
                  <Button size="sm" variant="ghost">
                    Later
                  </Button>
                </ToastActionBase>
              ),
            });
          },
        },
      );
      setState(false);
      form.reset();
    },
    [createWorkspace, setState, form, navigate, toast],
  );

  useEffect(() => {
    setTimeout(() => {
      form.reset();
    }, 100);
  }, [state, form]);

  return (
    <Dialog open={state} onOpenChange={setState}>
      <DialogContent className="gap-12">
        <DialogHeader>
          <DialogTitle>Create new workspace</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="">Name</FormLabel>
                  <FormControl>
                    <Input className="resize-none" placeholder="Workspace name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="image"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="">Workspace image (optional)</FormLabel>
                  <FormControl>
                    <ImageFileDropzone
                      onChange={(file) => {
                        field.onChange(file);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button size="sm" type="button" variant="ghost" onClick={() => setState(false)}>
                Cancel
              </Button>
              <Button disabled={!form.formState.isValid} size="sm" type="submit">
                Create
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateWorkspaceModal;
