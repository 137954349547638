import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const HomeFilledIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-text-default", props.className)}
    >
      <path
        d="M10.6713,2.84313 C11.4291,2.16956 12.571,2.16956 13.3287,2.84313 L17.2629,6.34013 L17.5136,4.8356 C17.6044,4.29083 18.1196,3.92281 18.6644,4.0136 C19.2092,4.1044 19.5772,4.61963 19.4864,5.1644 L19.0288,7.90988 L21.6644,10.2526 C22.0772,10.6195 22.1143,11.2516 21.7474,11.6644 C21.3805,12.0771 20.7484,12.1143 20.3356,11.7474 L19.8715,11.3348 L19.0851,19.199 C18.9829,20.2214 18.1225,21 17.095,21 L6.905,21 C5.87749,21 5.01716,20.2214 4.91492,19.199 L4.12851,11.3348 L3.66437,11.7474 C3.25159,12.1143 2.61952,12.0771 2.2526,11.6644 C1.88568,11.2516 1.92286,10.6195 2.33565,10.2526 L10.6713,2.84313 Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default HomeFilledIcon;
