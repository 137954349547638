import { useCallback, useContext, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import AutoHeight from "embla-carousel-auto-height";
import useEmblaCarousel from "embla-carousel-react";

import { registerBrevoUser } from "@/api/brevo.ts";
import { activateReferralCode, setOnboardingData } from "@/api/user.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { onboardingSteps } from "@/assets/constants/onboarding.tsx";
import { CountrySelector } from "@/components/account";
import { PollButtons } from "@/components/button/poll-button.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { OnboardContext, OnboardingSlide } from "@/components/v3";
import { saveOnboardingData } from "@/firebase";
import { useAppAuthState, useAuthUserData } from "@/firebase/hooks.ts";
import { LoadingSpinner } from "@/pages/loading";
import { mixpanelTrack } from "@/service/mixpanel";
import type { OnboardingPayload, PersonalDataWithStripe } from "@/types/schemas";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

import "./onboarding-carousel.css";

const OnboardingCarousel = () => {
  const dialogBody = document.getElementById("onboarding-dialog-body");
  const [open, setOpen] = useModalState(ModalKeys.ONBOARDING);
  const [state, setState] = useState({ loading: false, finished: false });
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      watchDrag: false,
    },
    [AutoHeight()],
  );
  const ctx = useContext(OnboardContext);

  const [startTime, setStartTime] = useState(-1);

  useEffect(() => {
    if (open) {
      setStartTime(new Date().getTime());
    }
  }, [open]);

  const queryClient = useQueryClient();
  const [user] = useAppAuthState();
  useEffect(() => {
    if (emblaApi) emblaApi.scrollTo(ctx.currentStep);
  }, [ctx.currentStep, emblaApi]);
  const { data: userData } = useAuthUserData({
    options: {
      staleTime: 1000,
    },
  });

  const handleSubmit = useCallback(async () => {
    console.log("start loading");
    setState((prev) => ({ ...prev, loading: true }));
    let result: PersonalDataWithStripe | OnboardingPayload;
    try {
      console.debug("started onboarding save");
      result = await saveOnboardingData({
        occupation: ctx.choices.occupation,
        howDoYouKnowUs: ctx.choices.howDoYouKnowUs.choice,
        fieldOfOccupation: ctx.choices.fieldOfOccupation,
        countryCode: ctx.choices.country,
        dateOfOnboarding: new Date().toISOString(),
      });
      console.debug("finished onboarding save");
    } catch (e) {
      console.log("firebase error", e);
    }

    try {
      void registerBrevoUser();
    } catch (e) {
      console.log("brevo error", e);
    }
    console.log("start set onboarding data");
    try {
      const endTime = new Date().getTime();
      const diff = endTime - startTime / 1000;

      void setOnboardingData({
        payload: {
          occupation: ctx.choices.occupation,
          howDoYouKnowUs: ctx.choices.howDoYouKnowUs.choice,
          fieldOfOccupation: ctx.choices.fieldOfOccupation,
          country: ctx.choices.country,
          // TODO add time
          onboardingTimeInSeconds: diff,
        },
      });
    } catch (e) {
      console.log("error saving data", e);
    }
    if (userData?.referralCode) {
      try {
        await activateReferralCode(userData.referralCode);
      } catch {
        toast({
          title: "There was an error activating your referral code.",
          variant: "destructive",
        });
      }
    }
    queryClient.setQueryData<PersonalDataWithStripe>(["firebase-user-data", { user: user?.uid }], (oldData) =>
      oldData ? { ...oldData, ...result, onboardingDone: true } : oldData,
    );

    setState({ finished: true, loading: false });
  }, [
    startTime,
    ctx.choices.occupation,
    ctx.choices.howDoYouKnowUs,
    ctx.choices.fieldOfOccupation,
    ctx.choices.country,
    userData?.referralCode,
    queryClient,
    user?.uid,
  ]);

  useEffect(() => {
    if (ctx.done) {
      setTimeout(() => {
        void handleSubmit();
        setOpen(false);
      }, 500);
    }
  }, [handleSubmit, ctx.done, setOpen]);

  if (state.finished)
    return (
      <section className="mx-auto grid h-60 max-w-lg place-content-center">
        <h1 className="text-center text-3xl font-bold text-primary">
          Congratulations! You&aposve successfully completed the onboarding process.
          <br />
          <br />
          Welcome aboard!
        </h1>
      </section>
    );

  if (state.loading)
    return (
      <section className="h-60">
        <LoadingSpinner />
      </section>
    );
  return (
    <div className="embla w-full flex-1 overflow-hidden" ref={emblaRef}>
      <div className="embla__container flex">
        <OnboardingSlide
          className="embla__slide flex-[0_0_100%] animate-out"
          description={onboardingSteps[0].description}
          title={onboardingSteps[0].title}
        >
          <PollButtons
            id={onboardingSteps[0].id}
            selected={ctx.choices.occupation}
            step={onboardingSteps[0]}
            onSelectCallback={(value) => {
              if (ctx.choices.occupation) return;
              mixpanelTrack("onboarding_occupation", {
                occupation: value,
              });
              ctx.setChoices((prev) => ({ ...prev, occupation: value }));
              setTimeout(() => ctx.setCurrentStep(1), 500);
            }}
          />
        </OnboardingSlide>
        <OnboardingSlide
          className="embla__slide flex-[0_0_100%]"
          description={onboardingSteps[1].description}
          title={onboardingSteps[1].title}
        >
          <PollButtons
            id={onboardingSteps[1].id}
            selected={ctx.choices.fieldOfOccupation}
            step={onboardingSteps[1]}
            onSelectCallback={(value) => {
              if (ctx.choices.fieldOfOccupation) return;
              mixpanelTrack("onboarding_field_of_study", {
                fieldOfOccupation: value,
              });
              ctx.setChoices((prev) => ({ ...prev, fieldOfOccupation: value }));
              setTimeout(() => ctx.setCurrentStep(2), 500);
            }}
          />
        </OnboardingSlide>
        <OnboardingSlide
          className="embla__slide flex-[0_0_100%]"
          description={onboardingSteps[2].description}
          title={onboardingSteps[2].title}
        >
          <section>
            <PollButtons
              id={onboardingSteps[2].id}
              selected={ctx.choices.howDoYouKnowUs.choice}
              step={onboardingSteps[2]}
              onSelectCallback={(value) => {
                if (ctx.choices.howDoYouKnowUs.choice && ctx.choices.howDoYouKnowUs.choice !== "other") return;
                mixpanelTrack("onboarding_wdyhfu", {
                  howDoYouKnowUs: {
                    choice: ctx.choices.howDoYouKnowUs.choice,
                    value: "",
                  },
                });
                ctx.setChoices((prev) => ({ ...prev, howDoYouKnowUs: { value: "", choice: value } }));
                if (value !== "other") setTimeout(() => ctx.setCurrentStep(3), 500);
              }}
            />
            <section
              className="invisible mb-[1px] mt-6 flex w-full flex-row gap-6 px-[1px] data-[choice=other]:visible"
              data-choice={ctx.choices.howDoYouKnowUs.choice}
            >
              <Input
                className="border-primary/75"
                classNameRoot="w-full"
                placeholder="Please specify..."
                value={ctx.choices.howDoYouKnowUs.value}
                onChange={(e) => {
                  ctx.setChoices((prev) => ({
                    ...prev,
                    howDoYouKnowUs: {
                      choice: prev.howDoYouKnowUs.choice,
                      value: e.target.value,
                    },
                  }));
                }}
              />
              <Button
                disabled={
                  !ctx.choices.howDoYouKnowUs.choice ||
                  (ctx.choices.howDoYouKnowUs.choice === "other" && !ctx.choices.howDoYouKnowUs.value)
                }
                size="sm"
                onClick={() => {
                  if (!ctx.choices.howDoYouKnowUs.choice || !ctx.choices.howDoYouKnowUs.value) return;
                  mixpanelTrack("onboarding_wdyhfu", {
                    howDoYouKnowUs: {
                      choice: ctx.choices.howDoYouKnowUs.choice,
                      value: ctx.choices.howDoYouKnowUs.value,
                    },
                  });
                  setTimeout(() => ctx.setCurrentStep(4), 500);
                }}
              >
                Next
              </Button>
            </section>
          </section>
        </OnboardingSlide>
        <OnboardingSlide
          className="embla__slide flex-[0_0_100%] justify-start overflow-y-clip"
          description={onboardingSteps[3].description}
          title={onboardingSteps[3].title}
        >
          <section className="flex w-full flex-row gap-6">
            <CountrySelector
              modal
              className="mb-0.5 w-full"
              container={dialogBody}
              placeholder="Choose country"
              setValue={(value) => {
                ctx.setChoices((prev) => ({ ...prev, country: value }));
              }}
              value={ctx.choices.country}
            />
            <Button
              disabled={!ctx.choices.country}
              onClick={() => {
                mixpanelTrack("onboarding_country", {
                  country: ctx.choices.country,
                });

                setTimeout(() => ctx.setDone(true), 500);
              }}
            >
              Finish
            </Button>
          </section>
        </OnboardingSlide>
      </div>
    </div>
  );
};
export default OnboardingCarousel;
