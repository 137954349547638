import { ROUTES } from "@/assets/constants/constants";
import { PreservedQueryLink } from "@/components/button/navigation-utils.tsx";
import { Button } from "@/components/ui/button";
import { useMixpanelTrack } from "@/service/mixpanel";

const LogInLink = ({ text = "Log in" }) => {
  return (
    <PreservedQueryLink to={ROUTES.LOGIN}>
      <span className="text-sm font-bold text-text-link-active-onBg">{text}</span>
    </PreservedQueryLink>
  );
};

const BackToLogin = ({ text = "Back to Login" }) => {
  return (
    <PreservedQueryLink to={ROUTES.LOGIN}>
      <Button className="mt-2 w-full" variant="ghost">
        {text}
      </Button>
    </PreservedQueryLink>
  );
};

const SignUpLink = ({ text = "Sign up" }) => {
  const mixpanelTrack = useMixpanelTrack();

  return (
    <PreservedQueryLink to={ROUTES.REGISTER}>
      <span className="text-sm font-bold text-text-link-active-onBg" onClick={() => mixpanelTrack("sign_up_clicked")}>
        {text}
      </span>
    </PreservedQueryLink>
  );
};

const SignUpButton = ({ text = "Sign up here!" }) => {
  const mixpanelTrack = useMixpanelTrack();

  return (
    <PreservedQueryLink to={ROUTES.REGISTER}>
      <Button className="font-extrabold" variant="ghost" onClick={() => mixpanelTrack("sign_up_clicked")}>
        {text}
      </Button>
    </PreservedQueryLink>
  );
};

const DemoLink = () => {
  return (
    <PreservedQueryLink to={ROUTES.REGISTER}>
      <span className="text-sm font-bold text-text-link-active-onBg">Back to Demo</span>
    </PreservedQueryLink>
  );
};

const BackToDemo = () => (
  <PreservedQueryLink to={ROUTES.REGISTER}>
    <Button className="p-0 font-bold" variant="ghost">
      Back to Demo
    </Button>
  </PreservedQueryLink>
);
export { BackToLogin, BackToDemo, SignUpButton, SignUpLink, LogInLink, DemoLink };
