import { useParams } from "react-router-dom";

import { type ProjectTabState } from "@/types/app.ts";
import useAppStateStore from "@/zustand/store";

const useCurrentDocumentTabsOfProject = (): {
  tabData: ProjectTabState | undefined;
  openDocument: (projectId: string, documentId: string) => void;
  closeDocument: (projectId: string, documentId: string) => void;
} => {
  const { projectId = "" } = useParams();
  return useAppStateStore((state) => ({
    tabData: state.projectTabState[projectId],
    openDocument: state.openDocument,
    closeDocument: state.closeDocument,
  }));
};

const useCurrentNoteTabsOfProject = (): {
  tabData: ProjectTabState | undefined;
  closeNote: (projectId: string, noteId: string) => void;
  openNote: (projectId: string, noteId: string) => void;
} => {
  const { projectId = "" } = useParams();
  return useAppStateStore((state) => ({
    tabData: state.projectTabState[projectId],
    openNote: state.openNote,
    closeNote: state.closeNote,
  }));
};

export { useCurrentDocumentTabsOfProject, useCurrentNoteTabsOfProject };
