import { LogOutIcon } from "lucide-react";

import { DemoHandlerButton } from "@/components/v3";
import { logout } from "@/firebase";
import useAppStateStore from "@/zustand/store.ts";

const LogoutButton = () => {
  const setDrawerOpen = useAppStateStore((state) => state.setDrawerOpen);

  return (
    <DemoHandlerButton
      className="justify-start gap-2 p-3"
      size="md"
      variant="ghost"
      onClick={() => {
        logout();
        setDrawerOpen(false);
      }}
    >
      <span className="h-6 w-6">
        <LogOutIcon className="h-6 w-6" />
      </span>
      Logout
    </DemoHandlerButton>
  );
};
export default LogoutButton;
