import { type ReactNode, useEffect } from "react";

import { useMatch, useNavigate } from "react-router-dom";

import { useAcceptShareRequest, useWorkspaces } from "@/api/workspace.ts";
import { ROUTES } from "@/assets/constants/constants.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { CheckCircleFilled } from "@/components/v3";
import { useAppAuthState } from "@/firebase/hooks.ts";
import { sleep } from "@/utils";

const ShareActionGuard = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [auth] = useAppAuthState();
  const matched = useMatch(`${ROUTES.INVITE}/:inviteToken`);
  const acceptShareRequest = useAcceptShareRequest();
  const { refetch } = useWorkspaces();
  useEffect(() => {
    if (matched) {
      const projectTokenValue = matched.params.inviteToken;
      if (!projectTokenValue) return;

      if (!auth?.uid) {
        const redirect = `${ROUTES.INVITE}/${projectTokenValue}`;
        toast({
          title: "Log in to claim the project!",
          variant: "success",
        });
        navigate(`${ROUTES.LOGIN}?redirectTo=${encodeURIComponent(redirect)}`);
      } else {
        acceptShareRequest.mutate(
          {
            encodedWorkspaceId: projectTokenValue,
          },
          {
            onSuccess: async (data) => {
              await sleep(4000);
              await refetch();
              await sleep(1000);
              toast({
                icon: <CheckCircleFilled />,
                title: "Successfully joined workspace!",
                variant: "success",
              });
              navigate(`${ROUTES.WORKSPACES}/${data.id}`);
            },
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.uid, matched?.params?.inviteToken]);

  return children;
};

export default ShareActionGuard;
