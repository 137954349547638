import { useMemo } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card.tsx";
import { useUserPublicData } from "@/firebase/hooks.ts";
import { LoadingSpinner } from "@/pages/loading";
import { getAvatarFallback } from "@/service/user.ts";

const UserAvatar = ({ userId }: { userId: string }) => {
  const [publicDataResponse, loading] = useUserPublicData({ userId });

  const publicData = useMemo(() => {
    const data = publicDataResponse?.data();
    return {
      photoUrl: data?.photoUrl,
      avatarFallback: getAvatarFallback(data?.displayName),
      displayName: data?.displayName,
    };
  }, [publicDataResponse]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <Avatar className="border-1 h-12 w-12 border-primary-purple">
          <AvatarImage alt="user-avatar" className="h-6 w-6" src={publicData?.photoUrl ?? ""} />
          <AvatarFallback className="text-xs" fallbackColorIndex={publicData?.avatarFallback.fallbackColorIndex ?? 4}>
            {publicData?.avatarFallback.monogram ?? "QU"}
          </AvatarFallback>
        </Avatar>
      </HoverCardTrigger>
      <HoverCardContent align="center" className="w-max overflow-hidden rounded-lg border-0 p-0" side="top">
        <section className="rounded-lg  p-2 ">{publicData.displayName}</section>
      </HoverCardContent>
    </HoverCard>
  );
};

export default UserAvatar;
