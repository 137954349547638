import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const EditIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-4 fill-text-default", props.className)}
    >
      <path
        clipRule="evenodd"
        d="M12.6667 12.6668C12.8366 12.667 13 12.732 13.1236 12.8487C13.2471 12.9653 13.3215 13.1248 13.3314 13.2944C13.3414 13.464 13.2862 13.631 13.1772 13.7613C13.0681 13.8916 12.9134 13.9754 12.7447 13.9955L12.6667 14.0001H8C7.83008 13.9999 7.66664 13.9349 7.54309 13.8182C7.41953 13.7016 7.34517 13.5422 7.33522 13.3725C7.32526 13.2029 7.38045 13.0359 7.48951 12.9056C7.59857 12.7753 7.75327 12.6915 7.922 12.6715L8 12.6668H12.6667ZM10.7307 2.91212C11.036 2.60699 11.4478 2.43221 11.8794 2.42458C12.3109 2.41694 12.7286 2.57705 13.0446 2.87119C13.3605 3.16534 13.5499 3.57058 13.5731 4.00161C13.5962 4.43264 13.4513 4.85583 13.1687 5.18212L13.0873 5.26879L5.82333 12.5335C5.75985 12.597 5.68809 12.6517 5.61 12.6961L5.53 12.7361L2.994 13.8895C2.45533 14.1341 1.902 13.6115 2.084 13.0728L2.11066 13.0061L3.264 10.4695C3.30097 10.3879 3.34873 10.3116 3.406 10.2428L3.466 10.1761L10.7307 2.91212ZM12.1447 3.85479C12.0894 3.79967 12.0166 3.76568 11.9389 3.75874C11.8612 3.75179 11.7835 3.77234 11.7193 3.81679L11.6733 3.85479L4.452 11.0761L4.05933 11.9408L4.92333 11.5475L12.1447 4.32679C12.1757 4.29582 12.2003 4.25904 12.2171 4.21854C12.2339 4.17805 12.2426 4.13463 12.2426 4.09079C12.2426 4.04694 12.2339 4.00353 12.2171 3.96303C12.2003 3.92254 12.1757 3.88575 12.1447 3.85479Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default EditIcon;
