import { useState } from "react";

import { useDeleteNote, useUpdateNote } from "@/api/note.ts";
import QuinoEditableItem from "@/components/v3/note/editabel-command-item.tsx";
import { useIsMyNote } from "@/service/hooks/notes.ts";
import { useCurrentNoteId, useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import type { StoreBaseFolderElement } from "@/types/schemas";

const QuinoNoteSelectItem = ({
  noteElement,
  onCloseCallback,
}: {
  noteElement: StoreBaseFolderElement;
  onCloseCallback: () => void;
}) => {
  const { mutate: updateNote } = useUpdateNote();
  const { mutate: deleteNote } = useDeleteNote();
  const [currentNodeId, setCurrentNoteId] = useCurrentNoteId();
  const workspaceId = useCurrentWorkspaceId();
  const isMyNote = useIsMyNote({ noteId: noteElement.id, workspaceId: workspaceId });
  const [state, setState] = useState<{ inputValue: string; initialName: string }>({
    inputValue: `${noteElement.name}`,
    initialName: `${noteElement.name}`,
  });

  return (
    <QuinoEditableItem
      cancelEdit={() => {
        setState((prev) => ({ ...prev, inputValue: prev.initialName }));
      }}
      dateString={noteElement.dateCreated}
      inputValue={state.inputValue}
      isMyNote={isMyNote}
      readOnly={isMyNote}
      selected={currentNodeId === noteElement.id}
      onChange={(e) => setState((prev) => ({ ...prev, inputValue: e.target.value }))}
      onClick={() => {
        setCurrentNoteId(noteElement.id);
        onCloseCallback();
      }}
      onExecuteDelete={() => {
        deleteNote({ noteId: noteElement.id, workspaceId });
      }}
      onSave={() => {
        if (state.inputValue)
          updateNote(
            {
              noteId: noteElement.id,
              name: state.inputValue,
              workspaceId,
            },
            {
              onError: () => {
                setState((prev) => ({ ...prev, inputValue: prev.initialName }));
              },
            },
          );
      }}
    />
  );
};

export default QuinoNoteSelectItem;
