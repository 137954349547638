import { useCallback, useEffect } from "react";

import { useDeleteDocument } from "@/api/document";
import { ModalKeys } from "@/assets/constants/modal";
import { YesNoModal } from "@/components/modals";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { useOpenSignUpToQuinoModal } from "@/service/library.ts";
import { useModalState } from "@/zustand/slices/modal-slice";

const DeleteDocumentModal = () => {
  const { mutate: deleteDocument, isPending, status } = useDeleteDocument();
  const { matched: isDemo } = useIsDemoLikePage();
  const openSignUp = useOpenSignUpToQuinoModal();
  const [{ isOpen, documentId, workspaceId }, setState] = useModalState(ModalKeys.DELETE_DOCUMENT_MODAL);

  const handleCloseModal = useCallback(() => {
    setState({
      isOpen: false,
      documentId: "",
      workspaceId: "",
    });
  }, [setState]);

  const onSubmit = useCallback(() => {
    if (isDemo) {
      openSignUp();
      handleCloseModal();
      return;
    }
    deleteDocument({ workspaceId, documentId });
  }, [isDemo, deleteDocument, workspaceId, documentId, openSignUp, handleCloseModal]);

  useEffect(() => {
    if (status === "error" || status === "success") handleCloseModal();
  }, [handleCloseModal, status]);

  return (
    <YesNoModal
      acceptButtonText="Delete Document"
      description="Are you sure you want to delete this document? Links pointing to the content of this document will be broken. This action cannot be undone later."
      loading={isPending}
      open={isOpen}
      title="Delete Document"
      onCancel={handleCloseModal}
      onYes={onSubmit}
    />
  );
};

export default DeleteDocumentModal;
