import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/utils";

const buttonVariants = cva(
  "focus-visible:ring-ring semibold relative inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg font-jakarta text-base font-bold normal-case text-default antialiased ring-offset-background drop-shadow-none transition-colors duration-300 ease-in-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 dark:text-white",
  {
    variants: {
      variant: {
        contained: "bg-primary-container-default text-primary-text-default hover:bg-primary-container-hover",
        white: "bg-white text-primary hover:bg-primary-container-hover-white",
        "primary-orange": "bg-primitive-orange-300 text-white hover:bg-primitive-orange-300/75",
        destructive: "text-primitive-red-800 hover:bg-primitive-red-50",
        "destructive-filled": "bg-primitive-red-800 text-white hover:bg-primitive-red-900",
        "destructive-secondary": "bg-primitive-red-50 text-primitive-red-800 hover:bg-primitive-red-100",
        outline: "border-input hover:bg-accent hover:text-accent-foreground border",
        secondary: "bg-secondary-container-default text-secondary-text-default hover:bg-secondary-container-hover",
        ghost: "text-tertiary-text-default hover:bg-tertiary-container-hover",
        icon: "bg-transparent fill-primary p-0 hover:bg-tertiary-container-hover dark:hover:bg-white/25",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        lg: "h-14 px-8 text-base",
        md: "h-12 px-5 py-4 text-sm",
        sm: "h-10 px-4 py-3 text-xs",
        xs: "h-7 p-1.5 text-xs",
        icon: "h-8 w-8",
        "icon-large": "h-10 w-10 p-3",
        "icon-extra-large": "h-12 w-12 p-4",
      },
    },
    defaultVariants: {
      variant: "contained",
      size: "md",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
