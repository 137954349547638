import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useInterval } from "usehooks-ts";

import { useProjectProcessingStatus } from "@/api/document.ts";
import { useIsAnyUnderProcessing } from "@/service/hooks/documents";
import { useCurrentDocumentTabEntity } from "@/service/hooks/react-router.ts";
import useAppStateStore from "@/zustand/store";

const DocumentProcessingProvider = ({ children }: { children?: React.ReactNode }) => {
  const [setDocProcessing, setAllToProcessed] = useAppStateStore((state) => [
    state.setDocProcessing,
    state.setAllToProcessed,
  ]);
  const { projectId } = useParams();
  const { data: isAnyUnderProcessing, refetch: refetchProcessing } = useIsAnyUnderProcessing(projectId ?? "");
  const { refetch, data: document } = useCurrentDocumentTabEntity();

  const { data, refetch: refetchAllProcessing } = useProjectProcessingStatus(projectId, {
    enabled:
      isAnyUnderProcessing?.allDocumentsProcessed === false &&
      isAnyUnderProcessing?.documentStatuses.some(
        (item) => item.docProcessedStatus === "UNDER_PROCESSING" || item.docProcessedStatus === "NOT_PROCESSED",
      ),
  });

  useInterval(
    () => {
      void refetchProcessing();
    },
    isAnyUnderProcessing?.allDocumentsProcessed === false ? 10000 : null,
  );

  useEffect(() => {
    if (data) {
      setDocProcessing({ payload: data });
    }
  }, [data, setDocProcessing]);

  useEffect(() => {
    if (
      isAnyUnderProcessing?.allDocumentsProcessed === true &&
      (document?.documentDetails.processingStatus === "UNDER_PROCESSING" ||
        document?.documentDetails.processingStatus === "NOT_PROCESSED")
    ) {
      void refetch();
    } else if (isAnyUnderProcessing?.allDocumentsProcessed === true) {
      setAllToProcessed();
    }
  }, [
    isAnyUnderProcessing?.allDocumentsProcessed,
    document?.documentDetails.processingStatus,
    refetch,
    setAllToProcessed,
  ]);

  useInterval(
    () => {
      void refetch();
      void refetchAllProcessing();
    },
    document &&
      !(
        document.documentDetails.processingStatus === "PROCESSED" ||
        document.documentDetails.processingStatus === "ERROR"
      )
      ? 2000
      : null,
  );

  return (
    <>
      {!isAnyUnderProcessing?.allDocumentsProcessed && (
        <div className="pointer-events-none fixed top-0 z-[200000] hidden h-10 w-full bg-red-500/25 text-center"></div>
      )}
      {children}
    </>
  );
};
export default DocumentProcessingProvider;
