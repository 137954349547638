import { useMemo } from "react";

import { Button } from "@/components/ui/button.tsx";
import { useCurrentDocumentTabId, useCurrentNoteId, useNavigateToResource } from "@/service/hooks/react-router.ts";
import { type StoreBaseFolderElement } from "@/types/schemas";

const ResourceButton = ({ resource }: { resource: StoreBaseFolderElement }) => {
  const currentDocumentId = useCurrentDocumentTabId();
  const [currentNoteId] = useCurrentNoteId();
  const navigate = useNavigateToResource({ resource });
  const selected = useMemo(() => {
    return resource.id === currentDocumentId || resource.id === currentNoteId;
  }, [currentDocumentId, currentNoteId, resource.id]);
  return (
    <Button
      className="block h-10 max-w-full items-center truncate p-2 text-start font-normal text-primary hover:bg-container-tertiary data-[selected=true]:bg-container-tertiary data-[selected=true]:font-bold"
      data-selected={selected}
      size="md"
      variant="ghost"
      onClick={navigate}
    >
      {resource.name}
    </Button>
  );
};

export default ResourceButton;
