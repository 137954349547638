import AboutQuinoGraphic from "./AboutQuinoGraphic";

const AboutQuino = () => {
  return (
    <section className="container bg-primary/5">
      <div className="flex flex-col items-start gap-8 px-5 py-8">
        <div className="">
          <h1 className="mb-4 text-h3 text-primary">About Quino</h1>
          <p className="text-base font-normal text-[#312F33]">
            Quino is a learning enhancement AI tool to summarize documents, chat with your learning materials and
            understand new information faster.
          </p>
        </div>
        <div className="self-center">
          <AboutQuinoGraphic className="h-[11.938rem] w-[24.25rem]" />
        </div>
      </div>
    </section>
  );
};

export default AboutQuino;
