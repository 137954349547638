import { useCallback } from "react";

import { useDeleteProject } from "@/api/project";
import { ModalKeys } from "@/assets/constants/modal";
import YesNoModal from "@/components/modals/yes-no-modal";
import { useModalState } from "@/zustand/slices/modal-slice";

const DeleteProjectModal = () => {
  const [{ isOpen, projectId, workspaceId }, setState] = useModalState(ModalKeys.DELETE_PROJECT_MODAL);

  const { mutate: deleteProject } = useDeleteProject();
  const handleDelete = useCallback(() => {
    if (projectId && workspaceId) {
      deleteProject({ projectId, workspaceId });
      setTimeout(() => setState({ isOpen: false, projectId: null, workspaceId: null }));
    }
  }, [deleteProject, projectId, setState, workspaceId]);

  return (
    <YesNoModal
      description={
        <span className="text-sm font-medium leading-5 text-primary">
          Are you sure you want to delete this project? This action cannot be undone later.
        </span>
      }
      open={isOpen}
      title="Delete Project"
      onCancel={() => setState({ isOpen: false, projectId: null, workspaceId: null })}
      onYes={handleDelete}
    />
  );
};

export default DeleteProjectModal;
