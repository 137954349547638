import { type ChangeEvent, type MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";

import autosize from "autosize";
import { XIcon } from "lucide-react";

import { EditNoteIcon } from "@/components/icons";
import { CheckMarkIcon } from "@/components/icons/summary";
import { Button } from "@/components/ui/button.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Typography } from "@/components/ui/typography.tsx";
import { DeleteIcon } from "@/components/v3/icons";
import { formatWithUTC } from "@/service/time-ago.ts";

function QuinoEditableItem({
  selected,
  onClick,
  inputValue,
  onSave,
  onChange,
  cancelEdit,
  onExecuteDelete,
  dateString,
  readOnly = false,
  isMyNote = true,
}: {
  isMyNote?: boolean;
  readOnly?: boolean;
  selected: boolean;
  inputValue: string;
  onClick: () => void;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  dateString: string;
  onSave: () => void;
  cancelEdit: () => void;
  onExecuteDelete: MouseEventHandler<HTMLButtonElement>;
}) {
  const [{ mode }, setState] = useState<{ mode: "edit" | "view" | "delete" }>({
    mode: "view",
  });
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const cancelEditInternal = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      cancelEdit();
      setState((prev) => ({ ...prev, mode: "view" }));
    },
    [cancelEdit, setState],
  );

  const onDeleteClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      setState((prev) => ({ ...prev, mode: "delete" }));
    },
    [setState],
  );

  const toggleEdit = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      if (mode === "edit") {
        onSave();
      }

      setState((prev) => ({ ...prev, mode: prev.mode === "edit" ? "view" : "edit" }));
      setTimeout(() => textareaRef.current?.focus());
    },
    [mode, onSave],
  );

  useEffect(() => {
    if (textareaRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      autosize(textareaRef.current);
    }
  }, []);

  return (
    <section
      className="group flex w-full cursor-pointer flex-col items-start justify-between p-2 transition-colors data-[mode=delete]:bg-container-tertiary data-[mode=edit]:bg-container-tertiary dark:hover:bg-white/25 dark:data-[mode=edit]:bg-white/25"
      data-mode={mode}
      data-selected-entry={selected}
      onClick={onClick}
    >
      <section className="mb-1 flex w-full flex-row items-center justify-start gap-3">
        <p className="line-clamp-2 w-full px-1 text-sm text-primary group-data-[mode=edit]:hidden group-data-[selected-entry=true]:font-bold dark:text-white/50">
          {inputValue}
        </p>
        <Textarea
          autoFocus
          className="block max-h-[3rem] min-h-0 w-full resize-none rounded-md border-0 bg-white p-1 pr-2 text-sm text-primary outline outline-1 outline-stroke-active group-data-[mode=delete]:hidden group-data-[mode=view]:hidden group-data-[selected-entry=true]:font-bold"
          readOnly={readOnly}
          ref={textareaRef}
          rows={1}
          value={inputValue}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
        />
      </section>
      <section className="flex w-full flex-row items-center justify-between gap-3 px-1">
        <Typography size="ultra-small">{formatWithUTC(dateString)}</Typography>
        {isMyNote ? (
          <section
            className="flex flex-row justify-between gap-2 data-[readonly=true]:invisible"
            data-readonly={readOnly}
          >
            <Button
              className="invisible h-4 w-4 rounded-sm p-0 group-hover:visible group-data-[mode=edit]:visible group-data-[mode=delete]:hidden"
              size="icon"
              variant="icon"
              onClick={toggleEdit}
            >
              <EditNoteIcon
                className="hidden h-4 w-4 fill-default/75 data-[mode=view]:block dark:fill-white/75"
                data-mode={mode}
              />
              <CheckMarkIcon
                className="block h-4 w-4 fill-default/75 data-[mode=view]:hidden dark:fill-white/75"
                data-mode={mode}
              />
            </Button>
            <Button
              className="invisible h-4 w-4 rounded-sm p-0 group-hover:visible group-data-[mode=edit]:visible group-data-[mode=delete]:hidden group-data-[mode=edit]:hidden"
              size="icon"
              type="button"
              variant="icon"
              onClick={onDeleteClick}
            >
              <DeleteIcon className="h-4 w-4 fill-default/75 dark:fill-white/75" />
            </Button>
            <Button
              className="hidden h-4 w-4 rounded-sm p-0 group-data-[mode=edit]:inline-flex group-data-[mode=delete]:hidden"
              size="icon"
              type="button"
              variant="icon"
              onClick={cancelEditInternal}
            >
              <XIcon className="h-4 w-4 fill-default/75 dark:fill-white/75" />
            </Button>
            <Button
              className="shadow-0 hidden h-4 border-0 text-feedback-negative drop-shadow-none group-data-[mode=delete]:inline-flex"
              size="xs"
              type="button"
              variant="ghost"
              onClick={onExecuteDelete}
            >
              <DeleteIcon className="h-4 w-4 fill-feedback-negative dark:fill-white/75" />
              Delete ?
            </Button>
          </section>
        ) : null}
      </section>
    </section>
  );
}

export default QuinoEditableItem;
