import { type QueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@/assets/constants/query-keys.ts";
import { toast } from "@/components/ui/use-toast.ts";
import {
  type DocChatSessionHistory,
  type DocumentChatItem,
  type DocumentChatSession,
  type UpdateDocChatSessionPayload,
} from "@/types/schemas";
import { getKeyDate } from "@/utils";

const insertDocumentChatSessionWithInitialMessage = (queryClient: QueryClient, result: DocumentChatSession) => {
  queryClient.setQueryData<DocChatSessionHistory>(
    [QUERY_KEYS.CHAT_HISTORY_COMPLETE, { projectId: result.projectId }],
    (oldData) =>
      oldData
        ? {
            count: oldData.count + 1,
            docChatSessions: {
              ...oldData.docChatSessions,
              [getKeyDate(result.dateCreated)]: [
                ...(oldData.docChatSessions[getKeyDate(result.dateCreated)]
                  ? oldData.docChatSessions[getKeyDate(result.dateCreated)]
                  : []),
                result,
              ],
            },
          }
        : {
            count: 1,
            docChatSessions: {
              [getKeyDate(result.dateCreated)]: [result],
            },
          },
  );
  //if there is a first element add it to the session otherwise initialize it with an empty item
  queryClient.setQueryData<DocumentChatSession>([QUERY_KEYS.CHAT_SESSION, { sessionId: result.id }], () => ({
    ...result,
    elements: result?.elements?.at(0) ? [result.elements[0]] : [],
  }));

  if (result?.elements?.at(0)) {
    queryClient.setQueryData<DocumentChatItem>([QUERY_KEYS.CHAT_ELEMENT, { elementId: result.elements[0].id }], () => ({
      ...result.elements[0],
    }));
  }
};

const onDeleteSessionSuccess = (
  sessionId: string,
  setSessionId: (newValue: string) => void,
  isDemo: boolean,
  mixpanelTrack: (message: string, payload?: object) => void,
  queryClient: QueryClient,
) => {
  return (result: DocumentChatSession) => {
    if (sessionId === result.id) setSessionId("");
    if (isDemo) mixpanelTrack("document_chat_delete_history_element");
    queryClient.setQueryData<DocChatSessionHistory>(
      [QUERY_KEYS.CHAT_HISTORY_COMPLETE, { projectId: result.projectId }],
      (oldData) => {
        if (!oldData) return oldData;

        Object.keys(oldData.docChatSessions).forEach((item) => {
          oldData.docChatSessions[item] = oldData.docChatSessions[item].filter((item) => item.id !== result.id);
        });

        return { ...oldData, count: oldData.count - 1 };
      },
    );
  };
};

function onUpdateSuccess(queryClient: QueryClient) {
  return (result: DocumentChatItem) => {
    queryClient.setQueryData<DocumentChatItem>([QUERY_KEYS.CHAT_ELEMENT, { elementId: result.id }], (oldData) => {
      if (!oldData) return oldData;
      return { ...oldData, ...result };
    });
  };
}

function onUpdateSessionSuccess(
  queryClient: QueryClient,
  isDemo: boolean,
  mixpanelTrack: (message: string, payload?: object) => void,
) {
  return (
    result: Omit<DocumentChatSession, "elements">,
    variables: { sessionId: string; payload: UpdateDocChatSessionPayload },
  ) => {
    queryClient.setQueryData<DocChatSessionHistory>(
      [QUERY_KEYS.CHAT_HISTORY_COMPLETE, { projectId: result.projectId }],
      (oldData) => {
        if (!oldData) return oldData;

        if (isDemo) mixpanelTrack("document_chat_update_history_element");

        Object.keys(oldData.docChatSessions).forEach((item) => {
          const index = oldData.docChatSessions[item].findIndex((item) => item.id === result.id);
          if (index === -1) return;
          oldData.docChatSessions[item][index] = { ...result };
        });

        return { ...oldData };
      },
    );
    queryClient.setQueryData<DocumentChatSession>(
      [QUERY_KEYS.CHAT_SESSION, { sessionId: variables.sessionId }],
      (oldData) => {
        return oldData ? { ...result, elements: oldData.elements } : oldData;
      },
    );
    toast({
      title: "Session updated!",
      variant: "success",
    });
  };
}

export { insertDocumentChatSessionWithInitialMessage, onDeleteSessionSuccess, onUpdateSuccess, onUpdateSessionSuccess };
