import { useMemo } from "react";

import { useSubscription } from "@/api/resources.ts";

export const useIsInFreeUserGroup = () => {
  const { data: subscription } = useSubscription();
  return useMemo(() => !subscription || subscription.subscriptionPlan === "free", [subscription]);
};

export const useIsInLimitedFreeUserGroup = () => {
  const { data: subscription } = useSubscription();
  return useMemo(() => !subscription || subscription.subscriptionPlan === "free", [subscription]);
};

export const useIsFreeUser = () => {
  const { data: subscription } = useSubscription();
  return useMemo(() => !subscription || subscription.subscriptionPlan === "free", [subscription]);
};
