import { useMemo } from "react";

import { useDocument } from "@/api/document.ts";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { type ViewMode } from "@/types/app.ts";
import { type DocumentEntity } from "@/types/schemas";

export const getPathFromDocument = (document?: DocumentEntity) => {
  if (!document) return "";
  if (document.inheritedFromUrl) return document.inheritedFromUrl;
  return `${document.projectId}/${document.userId}/${document.id}`;
};

export const getViewModeText = (viewMode: ViewMode) => {
  switch (viewMode) {
    case "SPLIT":
      return "Split view";
    case "DOCUMENT":
      return "Document only";
    case "TOOLS":
      return "Tools only";
    default:
      return "";
  }
};

export function useDocumentProcessingState(documentId: string) {
  const workspaceId = useCurrentWorkspaceId();
  const {
    data: documentData,
    isLoading,
    isError,
  } = useDocument(workspaceId, documentId, {
    retry: 10,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    refetchInterval: (query) =>
      query.state.data?.documentDetails.processingStatus === "UNDER_PROCESSING" ? 10000 : false,
  });

  const problemLevel = useMemo(() => {
    if (documentData?.documentDetails.processingStatus === "UNDER_PROCESSING") {
      return "UNDER_PROCESSING";
    }
    if (documentData?.documentDetails.processingStatus === "ERROR") {
      return "ERROR";
    }
    return documentData?.documentDetails.documentHealthLevel;
  }, [documentData]);

  return { detailData: documentData, isLoading, isError, problemLevel };
}
