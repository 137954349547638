import { useCallback, useEffect, useRef, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateParagraph } from "@/api/note.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form.tsx";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp", "image/gif"];

const FormSchema = z.object({
  image: z
    .any()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    .refine((file) => file?.size <= MAX_FILE_SIZE, `Max image size is 5MB.`)
    .refine(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, .png, .gif and .webp formats are supported.",
    ),
});
type FormSchemaType = z.infer<typeof FormSchema>;

const CreateImageNoteParagraph = () => {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
  });
  const [state, setOpenModal] = useModalState(ModalKeys.IMAGE_NOTE_PARAGRAPH);
  const inputElement = useRef<HTMLInputElement | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const createParagraph = useCreateParagraph();

  const handleSubmit = useCallback(
    (submitData: FormSchemaType) => {
      createParagraph.mutate({
        noteId: state.noteId,
        payload: {
          variant: "IMAGE",
          payload: {
            imageToUpload: submitData.image as File,

            position: state.position,
          },
        },
      });
      setOpenModal({ isOpen: false, noteId: "", position: -1 });
      form.reset();
    },
    [state.noteId, setOpenModal, createParagraph, form, state.position],
  );

  const imageFile = form.watch("image") as File;

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();

      reader.readAsDataURL(imageFile);

      reader.onload = () => {
        if (!reader.result) return;
        setImageUrl(reader.result as string);
      };

      reader.onerror = () => {
        console.log("Error reading file");
      };
    }
  }, [imageFile]);

  useEffect(() => {
    form.reset({
      image: null,
    });
    setTimeout(() => setImageUrl(""), 500);
  }, [state.isOpen, form]);

  return (
    <Dialog open={state.isOpen} onOpenChange={() => setOpenModal({ isOpen: false, noteId: "", position: -1 })}>
      <DialogContent className="gap-6">
        <DialogHeader>
          <DialogTitle>Upload Image</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="image"
              render={({ field }) => (
                <FormItem className="px-2">
                  <FormControl>
                    <div className="flex w-full">
                      <label className="flex w-full flex-row justify-center" htmlFor="file-input">
                        {imageUrl ? (
                          <img alt="preview-image" className="hover:cursor-pointer" src={imageUrl} />
                        ) : (
                          <span className="max-h-64 w-full rounded-lg bg-primary/10 px-4 py-3 text-center font-bold text-primary transition-all hover:cursor-pointer hover:bg-primary/5">
                            Choose an Image
                          </span>
                        )}
                      </label>
                      <input
                        multiple
                        accept="image/png, image/jpeg, image/gif, image/webp, image/svg"
                        className="hidden"
                        id="file-input"
                        ref={inputElement}
                        type="file"
                        onChange={() => {
                          const files = inputElement.current?.files;
                          if (files === null || files === undefined || files.length === 0) return;
                          field.onChange(files.item(0));
                          void form.trigger();
                        }}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-6">
              <Button
                type="button"
                variant="ghost"
                onClick={() => setOpenModal({ noteId: "", isOpen: false, position: -1 })}
              >
                Cancel
              </Button>
              <Button disabled={!form.formState.isValid} type="submit">
                Add Image
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateImageNoteParagraph;
