import * as React from "react";

import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/utils";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const year = new Date().getFullYear();

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      captionLayout="dropdown"
      className={cn("p-3", className)}
      classNames={{
        root: "min-h-[350px]",
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm flex min-w-[6rem] justify-between flex-row items-center font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        ),
        nav_button_previous: "!absolute left-1",
        nav_button_next: "!absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-primary-onBg/50 rounded-md w-9 font-normal text-[0.8rem] dark:text-white/50",
        row: "flex w-full mt-2",
        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-neutral-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 rounded-md",
        day: cn(buttonVariants({ variant: "ghost" }), "h-9 w-9 p-0 font-normal aria-selected:opacity-100 rounded-md"),
        day_selected: "bg-[#f0ebf9] focus:bg-secondary-onBg/50 rounded-md",
        day_today: "bg-neutral-100 text-primary-onBg ",
        day_outside: "text-primary-onBg/50 opacity-50",
        day_disabled: "text-primary-onBg/50 opacity-50 ",
        day_range_middle: "aria-selected:bg-neutral-100 aria-selected:text-primary-onBg",
        day_hidden: "invisible",
        caption_dropdowns: "flex flex-row gap-4",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4" />,
        IconRight: () => <ChevronRight className="h-4 w-4" />,
      }}
      fromYear={1900}
      showOutsideDays={showOutsideDays}
      toYear={year}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
