import { z } from "zod";

import { noteParagraphSchema } from "./note.ts";

export const commonModelLakeResponse = z
  .object({
    responseText: z.string(),
  })
  .strict();
export const modelLakeResponse = z
  .object({
    modelResponse: commonModelLakeResponse,
    modelLogId: z.string(),
    modelError: z.boolean(),
  })
  .strict();

export type ModelLakeResponse = z.infer<typeof modelLakeResponse>;

export const simpleModelLakeQueryResponse = z
  .object({
    noteParagraph: noteParagraphSchema,
  })
  .merge(modelLakeResponse)
  .strict();

export type SimpleModelLakeQueryResponse = z.infer<typeof simpleModelLakeQueryResponse>;

export const availableModelsResponse = z
  .object({
    modelName: z.string(),
    modelCode: z.string(),
  })
  .strict();

export type AvailableModelsResponse = z.infer<typeof availableModelsResponse>;

export const trialResponseSchema = z
  .object({
    response: z.string(),
  })
  .strict();

export type TrialResponse = z.infer<typeof trialResponseSchema>;
