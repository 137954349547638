import { type ReactNode, useMemo } from "react";

import { UploadIcon } from "lucide-react";
import { Link } from "react-router-dom";

import { useWorkspaceFromList } from "@/api/workspace";
import { ROUTES } from "@/assets/constants/constants.ts";
import { CalendarIcon } from "@/components/icons";
import { DislikeIcon, DocumentSummaryGeneratedIcon, LikeIcon } from "@/components/icons/summary";
import { Avatar } from "@/components/ui/avatar.tsx";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card.tsx";
import { AddFolder, DeleteIcon } from "@/components/v3";
import Bullet from "@/components/v3/icons/common/bullet.tsx";
import Pencil from "@/components/v3/icons/home/pencil.tsx";
import { UserAvatarImage } from "@/components/v3/user";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { formatWithUTC } from "@/service/time-ago.ts";
import {
  type Activity,
  ACTIVITY_TYPE,
  type AiFunctionUsedActivity,
  type CurrentUserActivity,
  type DocumentDeleteActivity,
  type MultipleDocumentDeleteActivity,
  type NewDocumentUploadActivity,
  type NewProjectCreatedActivity,
  type NewWorkspaceMembersActivity,
  type ProjectDeleteActivity,
  type SummaryGenerationActivity,
  type ParagraphSummaryHighlightActivity,
  type WorkspaceAdminChangedActivity,
  type WorkspaceCratedActivity,
  type ParagraphSummaryVoteActivity,
  type ParagraphSummaryExpandActivity,
} from "@/types/home";
import { type UserData } from "@/types/schemas";
import { cn } from "@/utils";

import UserAdd from "../icons/home/user-add.tsx";

const ActivityDate = ({ activity }: { activity: Activity }) => {
  const workspaceFromList = useWorkspaceFromList(activity.workspaceId);
  return (
    <p className="flex flex-row items-center gap-2 text-xs text-primitive-grey-600">
      <span>{formatWithUTC(activity.dateCreated)}</span> <Bullet className="h-1 w-1" />{" "}
      <span>{workspaceFromList?.name}</span>
    </p>
  );
};
const ActivityDescription = ({ description }: { description: string }) => {
  return <p className="line-clamp-2 text-secondary-onBg">“{description}”</p>;
};

const ActivityWrapper = ({ children }: { children: ReactNode }) => {
  return <section className="text-primary [&_b]:text-default">{children}</section>;
};

const ActivityUser = ({ user }: { user: UserData }) => {
  const currentUserId = useFirebaseUserId();
  return (
    <HoverCard>
      <HoverCardTrigger>
        <b className="mr-0.5">{currentUserId !== user.userId ? user.nickName : "You"}</b>
      </HoverCardTrigger>
      <HoverCardContent className="w-auto rounded-xl p-2" side="top">
        <section className="flex flex-row gap-3">
          <Avatar className="h-12 w-12">
            <UserAvatarImage userId={user.userId} />
          </Avatar>
          <section className="flex flex-col gap-4">
            <section className="flex flex-col gap-0.5">
              <p className="text-sm font-bold text-primary">{user.nickName}</p>
              <p className="text-xs text-primitive-grey-800">{user.email}</p>
            </section>
            <p className="flex flex-row items-center gap-1 text-xs text-primitive-grey-800">
              <CalendarIcon className="h-4 w-4 fill-primitive-grey-800" />
              <span>
                {" "}
                Joined{" "}
                {Intl.DateTimeFormat("en-GB", {
                  dateStyle: "long",
                }).format(user.joinedDate ? new Date(user.joinedDate) : new Date())}
              </span>
            </p>
          </section>
        </section>
      </HoverCardContent>
    </HoverCard>
  );
};

const NewDocumentUpload = ({ activity }: { activity: NewDocumentUploadActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        added a new document to your workspace{" "}
        <Link
          to={`${ROUTES.DOCUMENTS}/${activity.extraInfo.workspaceId}/${activity.extraInfo.projectId}?document=${activity.extraInfo.documentId}`}
        >
          <b>{activity.extraInfo.name}</b>
        </Link>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const SummaryGeneration = ({ activity }: { activity: SummaryGenerationActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        generated a summary of{" "}
        <Link
          to={`${ROUTES.DOCUMENTS}/${activity.extraInfo.workspaceId}/${activity.extraInfo.projectId}?document=${activity.extraInfo.documentId}`}
        >
          <b>{activity.extraInfo.documentName}</b>
        </Link>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const NewWorkspaceMembers = ({ activity }: { activity: NewWorkspaceMembersActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.extraInfo.inviterData} />
        {" invited "} <ActivityUser user={activity.extraInfo.acceptedUserData} /> to your workspace
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const WorkspaceAdminChanged = ({ activity }: { activity: WorkspaceAdminChangedActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.extraInfo.oldAdminData} /> gave the admin role to{" "}
        <ActivityUser user={activity.extraInfo.newAdminData} />
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const NewProjectCreated = ({ activity }: { activity: NewProjectCreatedActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        created a new project in your workspace:{" "}
        <Link to={`${ROUTES.WORKSPACES}/${activity.extraInfo.workspaceId}/${activity.extraInfo.id}`}>
          <b>{activity.extraInfo.name}</b>
        </Link>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};
const WorkspaceCrated = ({ activity }: { activity: WorkspaceCratedActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        created a new workspace:{" "}
        <Link to={`${ROUTES.WORKSPACES}/${activity.extraInfo.workspaceId}`}>
          <b>{activity.extraInfo.name}</b>
        </Link>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const ProjectDelete = ({ activity }: { activity: ProjectDeleteActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        deleted a project in your workspace: <b>{activity.extraInfo.name}</b>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const AiFunctionUsed = ({ activity }: { activity: AiFunctionUsedActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        used an AI function in: <b>{activity.extraInfo.documentName}</b>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const ListedActivities = ({ activity }: { activity: CurrentUserActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        created a new project in your workspace <b>{activity.workspaceId}</b>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const ParagraphSummaryHighlight = ({ activity }: { activity: ParagraphSummaryHighlightActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        highlighted a summary in{" "}
        <Link
          to={`${ROUTES.DOCUMENTS}/${activity.extraInfo.workspaceId}/${activity.extraInfo.projectId}?document=${activity.extraInfo.documentId}`}
        >
          <b>{activity.extraInfo.documentName}</b>
        </Link>
      </p>
      <ActivityDescription description={activity.extraInfo.paragraphText} />
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const ParagraphSummaryVote = ({ activity }: { activity: ParagraphSummaryVoteActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        {activity.extraInfo.likeDislikeValue === "UPVOTE" ? "upvoted" : "downvoted"} a summary in{" "}
        <Link
          to={`${ROUTES.DOCUMENTS}/${activity.extraInfo.workspaceId}/${activity.extraInfo.projectId}?document=${activity.extraInfo.documentId}`}
        >
          <Link
            to={`${ROUTES.DOCUMENTS}/${activity.extraInfo.workspaceId}/${activity.extraInfo.projectId}?document=${activity.extraInfo.documentId}`}
          >
            <b>{activity.extraInfo.documentName}</b>
          </Link>
        </Link>
      </p>
      <ActivityDescription description={activity.extraInfo.paragraphText} />
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const ParagraphSummaryExpand = ({ activity }: { activity: ParagraphSummaryExpandActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        expanded a summary in{" "}
        <Link
          to={`${ROUTES.DOCUMENTS}/${activity.extraInfo.workspaceId}/${activity.extraInfo.projectId}?document=${activity.extraInfo.documentId}`}
        >
          <b>{activity.extraInfo.documentName}</b>
        </Link>
      </p>
      <ActivityDescription description={activity.extraInfo.paragraphText} />
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const Deleted = ({ activity }: { activity: DocumentDeleteActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        deleted a document in your workspace: <b>{activity.extraInfo.name}</b>
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};

const DeletedMultiple = ({ activity }: { activity: MultipleDocumentDeleteActivity }) => {
  return (
    <ActivityWrapper>
      <p className="mb-2">
        <ActivityUser user={activity.userData} />
        deleted ${activity.extraInfo.deletedDocIds.length} documents in the workspace
      </p>
      <ActivityDate activity={activity} />
    </ActivityWrapper>
  );
};
const activityTypeToIcon = (activity: Activity) => {
  switch (activity.activityType) {
    case ACTIVITY_TYPE.PARAGRAPH_SUMMARY_UPVOTE_CHANGE:
      if (activity.extraInfo.likeDislikeValue == "UPVOTE")
        return <LikeIcon className="h-6 w-6 fill-primitive-purple-400" />;
      return <DislikeIcon className="h-6 w-6 fill-primitive-purple-400 stroke-primitive-purple-400" />;
    case ACTIVITY_TYPE.MULTIPLE_DOCUMENT_DELETE:
    case ACTIVITY_TYPE.DOCUMENT_DELETE:
      return <DeleteIcon className="h-6 w-6" />;
    case ACTIVITY_TYPE.DOCUMENT_SUMMARY:
      return <DocumentSummaryGeneratedIcon className="h-6 w-6" />;
    case ACTIVITY_TYPE.DOWNVOTE:
      return <DislikeIcon className="h-6 w-6 stroke-primitive-purple-400" />;
    case ACTIVITY_TYPE.UPVOTE:
      return <LikeIcon className="h-6 w-6 stroke-primitive-purple-400" />;
    case ACTIVITY_TYPE.DOCUMENT_UPLOAD:
      return <UploadIcon className="h-6 w-6 stroke-primitive-purple-400" />;
    case ACTIVITY_TYPE.WORKSPACE_CREATE:
    case ACTIVITY_TYPE.PROJECT_DELETE:
      return <AddFolder className="h-6 w-6" />;
    case ACTIVITY_TYPE.WORKSPACE_INVITE_ACCEPT:
      return <UserAdd className="h-6 w-6 fill-primitive-purple-400" />;
    default:
      return <Pencil className="h-6 w-6" />;
  }
};

const getActivityBgColor = (type: ACTIVITY_TYPE) => {
  switch (type) {
    case ACTIVITY_TYPE.DOCUMENT_DELETE:
    case ACTIVITY_TYPE.MULTIPLE_DOCUMENT_DELETE:
      return "bg-primitive-red-50";
    default:
      return "bg-primitive-purple-200";
  }
};

const ActivityCard = ({ activity }: { activity: Activity }) => {
  const content = useMemo(() => {
    if (activity.activityType === ACTIVITY_TYPE.DOCUMENT_UPLOAD) return <NewDocumentUpload activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.DOCUMENT_DELETE) return <Deleted activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.MULTIPLE_DOCUMENT_DELETE)
      return <DeletedMultiple activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.PARAGRAPH_SUMMARY_HIGHLIGHT)
      return <ParagraphSummaryHighlight activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.PARAGRAPH_SUMMARY_EXPAND)
      return <ParagraphSummaryExpand activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.PARAGRAPH_SUMMARY_UPVOTE_CHANGE)
      return <ParagraphSummaryVote activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.DOCUMENT_SUMMARY) return <SummaryGeneration activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.AI_FUNCTION_USED) return <AiFunctionUsed activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.WORKSPACE_INVITE_ACCEPT)
      return <NewWorkspaceMembers activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.WORKSPACE_ADMIN_CHANGED)
      return <WorkspaceAdminChanged activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.PROJECT_CREATE)
      return <NewProjectCreated activity={activity as NewProjectCreatedActivity} />;
    if (activity.activityType === ACTIVITY_TYPE.PROJECT_DELETE) return <ProjectDelete activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.WORKSPACE_CREATE) return <WorkspaceCrated activity={activity} />;
    if (activity.activityType === ACTIVITY_TYPE.LISTED_ACTIVITIES) return <ListedActivities activity={activity} />;

    return null;
  }, [activity]);

  const icon = useMemo(() => activityTypeToIcon(activity), [activity]);

  if (!content) return null;

  return (
    <section className="mb-2 flex flex-row gap-3">
      <span className="sr-only">{activity.activityType}</span>
      <section className="relative w-20">
        <section className="flex w-20 flex-row items-center">
          <Avatar className={cn("h-12 w-12 items-center justify-center", getActivityBgColor(activity.activityType))}>
            {icon}
          </Avatar>
          <Avatar className="absolute left-7 h-12 w-12 outline outline-2 outline-white">
            <UserAvatarImage userId={activity.userData.userId} />
          </Avatar>
        </section>
      </section>
      {content}
    </section>
  );
};

export default ActivityCard;
