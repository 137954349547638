import React, { useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { useQueryClient } from "@tanstack/react-query";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";

import { useSubscription } from "@/api/resources";
import { ModalKeys } from "@/assets/constants/modal";
import { auth } from "@/firebase";
import { useAuthUserData } from "@/firebase/hooks";
import { useThemeMode } from "@/service/hooks/misc.ts";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { mixpanelIdentify, mixpanelPeopleSet } from "@/service/mixpanel";
import { useDetectAdBlock } from "@/utils/hooks.ts";
import useAppStateStore, { resetAllStores } from "@/zustand/store";

const AppState = ({ children }: { children: React.ReactNode }) => {
  useThemeMode();
  const adBlockDetected = useDetectAdBlock();
  const setModalKey = useAppStateStore((state) => state.setModalKey);
  const setUser = useAppStateStore((state) => state.setUser);
  const { data: userData, isLoading: loading, firebaseUser } = useAuthUserData();
  const [mode] = useThemeMode();
  const { data: subscription } = useSubscription();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isFreeUser = false; //  useIsFreeUser(); needs review
  const { matched: isDemo } = useIsDemoLikePage();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      void queryClient.invalidateQueries();
      void queryClient.resetQueries();
      void queryClient.clear();
    });
  }, [navigate, queryClient, setUser]);

  useEffect(() => {
    // TODO REENABLE THIS LATER if (adBlockDetected && userData?.onboardingDone && subscription?.subscriptionPlan === "freemium")
    if (adBlockDetected && userData?.onboardingDone && isFreeUser) setModalKey(ModalKeys.STOP_ADBLOCK_MODAL, true);
  }, [adBlockDetected, userData, subscription, setModalKey, isFreeUser]);

  useEffect(() => {
    if (loading) return;
    if (userData && firebaseUser) {
      Sentry.setUser({
        id: userData.uid,
        email: userData.email ?? undefined,
        username: userData.email ?? undefined,
        ip_address: "{{auto}}",
      });

      mixpanelIdentify(userData.uid);
      mixpanelPeopleSet({
        theme_mode: mode,
        letter_size: userData.letterSize ?? "normal",
        stripe_customer_id: userData.stripe_customer_id,
      });
    } else if (userData === null) {
      if (!isDemo) resetAllStores();
      Sentry.setUser({
        ip_address: "{{auto}}",
      });
    }
  }, [firebaseUser, isDemo, loading, location.pathname, mode, navigate, userData]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  if (loading) return <section className="fixed h-screen w-screen bg-default"></section>;

  return children;
};

export default AppState;
