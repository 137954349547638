import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

const StarIcon = ({ className, ...props }: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-4 w-[17px] fill-secondary-onBg", className)}
    >
      <path
        clipRule="evenodd"
        d="M7.27999 1.91205C7.35335 1.78615 7.45845 1.6817 7.58478 1.6091C7.71112 1.5365 7.85428 1.49829 7.99999 1.49829C8.1457 1.49829 8.28886 1.5365 8.4152 1.6091C8.54153 1.6817 8.64663 1.78615 8.71999 1.91205L10.5833 5.11071L14.202 5.89471C14.3443 5.92565 14.4761 5.99335 14.5841 6.09107C14.6921 6.18879 14.7726 6.31312 14.8176 6.45166C14.8626 6.59021 14.8705 6.73812 14.8405 6.88066C14.8105 7.0232 14.7437 7.15539 14.6467 7.26405L12.18 10.0247L12.5533 13.708C12.5681 13.8531 12.5445 13.9994 12.4849 14.1325C12.4254 14.2656 12.332 14.3807 12.2141 14.4664C12.0961 14.5521 11.9578 14.6054 11.8128 14.6209C11.6679 14.6363 11.5214 14.6135 11.388 14.5547L7.99999 13.0614L4.61199 14.5547C4.4786 14.6135 4.33212 14.6363 4.18716 14.6209C4.0422 14.6054 3.90385 14.5521 3.7859 14.4664C3.66796 14.3807 3.57457 14.2656 3.51504 14.1325C3.45551 13.9994 3.43193 13.8531 3.44666 13.708L3.81999 10.0247L1.35332 7.26471C1.25614 7.15606 1.18916 7.02382 1.15907 6.88119C1.12897 6.73856 1.13682 6.59053 1.18182 6.45188C1.22682 6.31323 1.30741 6.18882 1.41553 6.09105C1.52365 5.99328 1.65553 5.92558 1.79799 5.89471L5.41666 5.11071L7.27999 1.91205ZM7.99999 3.32471L6.45799 5.97271C6.39968 6.07266 6.3212 6.15937 6.22754 6.22732C6.13388 6.29527 6.0271 6.34297 5.91399 6.36738L2.91932 7.01605L4.96066 9.30071C5.11666 9.47538 5.19199 9.70738 5.16866 9.94005L4.85999 12.9887L7.66399 11.7527C7.76987 11.7061 7.88429 11.682 7.99999 11.682C8.11569 11.682 8.23011 11.7061 8.33599 11.7527L11.14 12.9887L10.8313 9.94005C10.8196 9.82493 10.832 9.70862 10.8677 9.59855C10.9034 9.48848 10.9616 9.38705 11.0387 9.30071L13.0807 7.01605L10.086 6.36738C9.97288 6.34297 9.8661 6.29527 9.77244 6.22732C9.67878 6.15937 9.6003 6.07266 9.54199 5.97271L7.99999 3.32471Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default StarIcon;
