import { ResourceCardType, ROUTES } from "@/assets/constants/constants.ts";
import {
  type DocumentDetailsSchema,
  type DocumentEntity,
  type BaseProjectSchema,
  type FolderElement,
  type NoteEntity,
  type ProjectEntity,
  type ResourceElement,
} from "@/types/schemas";
import {
  type BaseWorkspace,
  type WorkspaceEntity,
  type WorkspaceListItem,
} from "@/types/schemas/api/workspaceEntity.ts";

const nowDate = new Date().toISOString();

export const DEMO_USER_ID = "DEMO_USER_ID";

export const IDS = {
  WORKSPACE: {
    WORKSPACE_ID_ONE: "WORKSPACE_ID_ONE",
    WORKSPACE_ID_TWO: "WORKSPACE_ID_TWO",
    WORKSPACE_ID_THREE: "WORKSPACE_ID_THREE",
  },
  PROJECT: {
    PROJECT_ID_ONE: "PROJECT_ID_ONE",
    PROJECT_ID_TWO: "PROJECT_ID_TWO",
    PROJECT_ID_THREE: "PROJECT_ID_THREE",
  },
  NOTE: {
    NOTE_ID_ONE: "NOTE_ID_ONE",
    NOTE_ID_TWO: "NOTE_ID_TWO",
    NOTE_ID_THREE: "NOTE_ID_THREE",
  },
  DOCUMENT: {
    DOCUMENT_ID_ONE: "DOCUMENT_ID_ONE",
    DOCUMENT_ID_TWO: "DOCUMENT_ID_TWO",
    DOCUMENT_ID_THREE: "DOCUMENT_ID_THREE",
  },
  DOCUMENT_DETAILS: {
    DOCUMENT_DETAILS_ID_ONE: "DOCUMENT_DETAILS_ID_ONE",
    DOCUMENT_DETAILS_ID_TWO: "DOCUMENT_DETAILS_ID_TWO",
    DOCUMENT_DETAILS_ID_THREE: "DOCUMENT_DETAILS_ID_THREE",
  },
  DOCUMENT_SUMMARY_ID: {
    DOCUMENT_SUMMARY_ONE: "DOCUMENT_SUMMARY_ONE",
    DOCUMENT_SUMMARY_TWO: "DOCUMENT_SUMMARY_TWO",
    DOCUMENT_SUMMARY_THREE: "DOCUMENT_SUMMARY_THREE",
  },
};

export const DOCUMENT_SUMMARY_DATA = {
  [IDS.DOCUMENT.DOCUMENT_ID_ONE]: IDS.DOCUMENT_SUMMARY_ID.DOCUMENT_SUMMARY_ONE,
  [IDS.DOCUMENT.DOCUMENT_ID_TWO]: IDS.DOCUMENT_SUMMARY_ID.DOCUMENT_SUMMARY_TWO,
  [IDS.DOCUMENT.DOCUMENT_ID_THREE]: IDS.DOCUMENT_SUMMARY_ID.DOCUMENT_SUMMARY_THREE,
};
export const NOTE_DATA: Record<keyof typeof IDS.NOTE, NoteEntity> = {
  NOTE_ID_ONE: {
    projectId: IDS.PROJECT.PROJECT_ID_ONE,
    name: "General Notes",
    id: IDS.NOTE.NOTE_ID_ONE,
    userId: DEMO_USER_ID,
    dateLastOpen: nowDate,
    noteParagraphs: [],
    dateCreated: nowDate,
    favourite: false,
    elementType: ResourceCardType.NOTE_ELEMENT,
  },
  NOTE_ID_TWO: {
    projectId: IDS.PROJECT.PROJECT_ID_TWO,
    name: "Research Note",
    id: IDS.NOTE.NOTE_ID_TWO,
    userId: DEMO_USER_ID,
    dateLastOpen: nowDate,
    noteParagraphs: [],
    dateCreated: nowDate,
    favourite: false,
    elementType: ResourceCardType.NOTE_ELEMENT,
  },
  NOTE_ID_THREE: {
    projectId: IDS.PROJECT.PROJECT_ID_THREE,
    name: "Notes",
    id: IDS.NOTE.NOTE_ID_THREE,
    userId: DEMO_USER_ID,
    dateLastOpen: nowDate,
    noteParagraphs: [],
    dateCreated: nowDate,
    favourite: false,
    elementType: ResourceCardType.NOTE_ELEMENT,
  },
};
export const DOCUMENT_DETAILS_DATA: Record<keyof typeof IDS.DOCUMENT, DocumentDetailsSchema> = {
  DOCUMENT_ID_ONE: {
    documentId: IDS.DOCUMENT_DETAILS.DOCUMENT_DETAILS_ID_ONE,
    id: IDS.DOCUMENT.DOCUMENT_ID_ONE,
    processingStatus: "PROCESSED",
    documentHealthLevel: "OK",
    sizeOfDocumentInMb: 2,
    errorMessage: undefined,
    numberOfPages: 10,
    dateFinishedProcessing: nowDate,
    dateStartedProcessing: nowDate,
  },
  DOCUMENT_ID_TWO: {
    documentId: IDS.DOCUMENT_DETAILS.DOCUMENT_DETAILS_ID_TWO,
    id: IDS.DOCUMENT.DOCUMENT_ID_TWO,
    processingStatus: "PROCESSED",
    documentHealthLevel: "OK",
    sizeOfDocumentInMb: 2,
    errorMessage: undefined,
    numberOfPages: 10,
    dateFinishedProcessing: nowDate,
    dateStartedProcessing: nowDate,
  },
  DOCUMENT_ID_THREE: {
    documentId: IDS.DOCUMENT_DETAILS.DOCUMENT_DETAILS_ID_TWO,
    id: IDS.DOCUMENT.DOCUMENT_ID_THREE,
    processingStatus: "PROCESSED",
    documentHealthLevel: "OK",
    sizeOfDocumentInMb: 2,
    errorMessage: undefined,
    numberOfPages: 10,
    dateFinishedProcessing: nowDate,
    dateStartedProcessing: nowDate,
  },
};

export const DOCUMENT_DATA: Record<keyof typeof IDS.DOCUMENT, DocumentEntity> = {
  DOCUMENT_ID_ONE: {
    projectId: IDS.PROJECT.PROJECT_ID_ONE,
    name: "Economics of Life",
    id: IDS.DOCUMENT.DOCUMENT_ID_ONE,
    dateLastOpen: nowDate,
    dateCreated: nowDate,
    favourite: false,
    documentUrl: "asd",
    userId: DEMO_USER_ID,
    documentDetails: DOCUMENT_DETAILS_DATA.DOCUMENT_ID_ONE,
    workspaceId: IDS.WORKSPACE.WORKSPACE_ID_ONE,
  },
  DOCUMENT_ID_TWO: {
    projectId: IDS.PROJECT.PROJECT_ID_TWO,
    name: "Attention Is All You Need",
    id: IDS.DOCUMENT.DOCUMENT_ID_TWO,
    dateLastOpen: nowDate,
    dateCreated: nowDate,
    favourite: false,
    documentUrl: "asd",
    userId: DEMO_USER_ID,
    documentDetails: DOCUMENT_DETAILS_DATA.DOCUMENT_ID_TWO,
    workspaceId: IDS.WORKSPACE.WORKSPACE_ID_TWO,
  },
  DOCUMENT_ID_THREE: {
    projectId: IDS.PROJECT.PROJECT_ID_THREE,
    name: "Frankenstein, or the Modern Prometheus",
    id: IDS.DOCUMENT.DOCUMENT_ID_THREE,
    dateLastOpen: nowDate,
    dateCreated: nowDate,
    favourite: false,
    documentUrl: "asd",
    userId: DEMO_USER_ID,
    documentDetails: DOCUMENT_DETAILS_DATA.DOCUMENT_ID_THREE,
    workspaceId: IDS.WORKSPACE.WORKSPACE_ID_THREE,
  },
};

export const mockBaseWorkspacesDict: Record<keyof typeof IDS.WORKSPACE, BaseWorkspace> = {
  WORKSPACE_ID_ONE: {
    id: IDS.PROJECT.PROJECT_ID_ONE,
    adminId: DEMO_USER_ID,
    name: "Economics Project",
  },
  WORKSPACE_ID_TWO: {
    id: IDS.PROJECT.PROJECT_ID_TWO,
    adminId: DEMO_USER_ID,
    name: "Machine Learning Project",
  },
  WORKSPACE_ID_THREE: {
    id: IDS.PROJECT.PROJECT_ID_THREE,
    adminId: "CHART",
    name: "Literature Project",
  },
};

export const mockBaseProjectsDict: Record<keyof typeof IDS.PROJECT, BaseProjectSchema> = {
  PROJECT_ID_ONE: {
    id: IDS.PROJECT.PROJECT_ID_ONE,
    projectIcon: "CHART",
    userId: DEMO_USER_ID,
    name: "Economics Project",
    colorTag: "#733E9C",
  },
  PROJECT_ID_TWO: {
    id: IDS.PROJECT.PROJECT_ID_TWO,
    projectIcon: "CHART",
    userId: DEMO_USER_ID,
    name: "Machine Learning Project",
    colorTag: "#FD9F11",
  },
  PROJECT_ID_THREE: {
    id: IDS.PROJECT.PROJECT_ID_THREE,
    projectIcon: "CHART",
    userId: DEMO_USER_ID,
    name: "Literature Project",
    colorTag: "#46C9E5",
  },
};

const PROJECT_DIRECT_CHILD_ELEMENTS: Record<keyof typeof IDS.PROJECT, FolderElement[]> = {
  PROJECT_ID_ONE: [
    {
      id: NOTE_DATA["NOTE_ID_ONE"].id,
      name: NOTE_DATA["NOTE_ID_ONE"].name,
      projectId: NOTE_DATA["NOTE_ID_ONE"].projectId,
      dateCreated: NOTE_DATA["NOTE_ID_ONE"].dateCreated,
      elementType: ResourceCardType.NOTE_ELEMENT,
      favourite: false,

      userId: DEMO_USER_ID,
    },
    {
      id: DOCUMENT_DATA["DOCUMENT_ID_ONE"].id,
      name: DOCUMENT_DATA["DOCUMENT_ID_ONE"].name,
      projectId: DOCUMENT_DATA["DOCUMENT_ID_ONE"].projectId,
      dateCreated: DOCUMENT_DATA["DOCUMENT_ID_ONE"].dateCreated,
      elementType: ResourceCardType.DOCUMENT_ELEMENT,
      favourite: false,

      userId: DEMO_USER_ID,
    },
  ],
  PROJECT_ID_TWO: [
    {
      id: NOTE_DATA["NOTE_ID_TWO"].id,
      name: NOTE_DATA["NOTE_ID_TWO"].name,
      projectId: NOTE_DATA["NOTE_ID_TWO"].projectId,
      dateCreated: NOTE_DATA["NOTE_ID_TWO"].dateCreated,
      elementType: ResourceCardType.NOTE_ELEMENT,
      favourite: false,

      userId: DEMO_USER_ID,
    },
    {
      id: DOCUMENT_DATA["DOCUMENT_ID_TWO"].id,
      name: DOCUMENT_DATA["DOCUMENT_ID_TWO"].name,
      projectId: DOCUMENT_DATA["DOCUMENT_ID_TWO"].projectId,
      dateCreated: DOCUMENT_DATA["DOCUMENT_ID_TWO"].dateCreated,
      elementType: ResourceCardType.DOCUMENT_ELEMENT,
      favourite: false,

      userId: DEMO_USER_ID,
    },
  ],
  PROJECT_ID_THREE: [
    {
      id: NOTE_DATA["NOTE_ID_THREE"].id,
      name: NOTE_DATA["NOTE_ID_THREE"].name,
      projectId: NOTE_DATA["NOTE_ID_THREE"].projectId,
      dateCreated: NOTE_DATA["NOTE_ID_THREE"].dateCreated,
      elementType: ResourceCardType.NOTE_ELEMENT,
      favourite: false,

      userId: DEMO_USER_ID,
    },
    {
      id: DOCUMENT_DATA["DOCUMENT_ID_THREE"].id,
      name: DOCUMENT_DATA["DOCUMENT_ID_THREE"].name,
      projectId: DOCUMENT_DATA["DOCUMENT_ID_THREE"].projectId,
      dateCreated: DOCUMENT_DATA["DOCUMENT_ID_THREE"].dateCreated,
      elementType: ResourceCardType.DOCUMENT_ELEMENT,
      favourite: false,

      userId: DEMO_USER_ID,
    },
  ],
};

export const mockBaseWorkspaces: WorkspaceListItem[] = [
  {
    id: IDS.WORKSPACE.WORKSPACE_ID_ONE,
    name: "University",
    adminId: DEMO_USER_ID,
    numOfProjects: 2,
    numOfUsers: 1,
    imageUrl: null,
  },
  {
    id: IDS.WORKSPACE.WORKSPACE_ID_TWO,
    name: "School",
    adminId: DEMO_USER_ID,
    numOfProjects: 1,
    numOfUsers: 1,
    imageUrl: null,
  },
  {
    id: IDS.WORKSPACE.WORKSPACE_ID_THREE,
    name: "My Workspace",
    adminId: DEMO_USER_ID,
    numOfProjects: 0,
    numOfUsers: 1,
    imageUrl: null,
  },
];

export const mockBaseProjects: BaseProjectSchema[] = [
  {
    ...mockBaseProjectsDict["PROJECT_ID_ONE"],
  },
  {
    ...mockBaseProjectsDict["PROJECT_ID_TWO"],
  },
  {
    ...mockBaseProjectsDict["PROJECT_ID_THREE"],
  },
];

export const PROJECT_SCHEMA_DATA: Record<keyof typeof IDS.PROJECT, ProjectEntity> = {
  PROJECT_ID_ONE: {
    ...mockBaseProjectsDict["PROJECT_ID_ONE"],
    workspaceId: IDS.WORKSPACE.WORKSPACE_ID_ONE,
    coverImageUrl: "",
    directChildElements: PROJECT_DIRECT_CHILD_ELEMENTS["PROJECT_ID_ONE"],
    dateCreated: nowDate,
  },
  PROJECT_ID_TWO: {
    ...mockBaseProjectsDict["PROJECT_ID_TWO"],
    workspaceId: IDS.WORKSPACE.WORKSPACE_ID_TWO,
    coverImageUrl: "",
    directChildElements: PROJECT_DIRECT_CHILD_ELEMENTS["PROJECT_ID_TWO"],
    dateCreated: nowDate,
  },
  PROJECT_ID_THREE: {
    ...mockBaseProjectsDict["PROJECT_ID_THREE"],
    workspaceId: IDS.WORKSPACE.WORKSPACE_ID_THREE,
    coverImageUrl: "",
    directChildElements: PROJECT_DIRECT_CHILD_ELEMENTS["PROJECT_ID_THREE"],
    dateCreated: nowDate,
  },
};

export const WORKSPACE_SCHEMA_DATA: Record<keyof typeof IDS.WORKSPACE, WorkspaceEntity> = {
  WORKSPACE_ID_ONE: {
    ...mockBaseWorkspacesDict["WORKSPACE_ID_ONE"],
    dateCreated: nowDate,
    adminId: DEMO_USER_ID,
    userIds: [DEMO_USER_ID],
    projects: [PROJECT_SCHEMA_DATA["PROJECT_ID_ONE"], PROJECT_SCHEMA_DATA["PROJECT_ID_TWO"]],
    isPremium: false,
  },
  WORKSPACE_ID_TWO: {
    ...mockBaseWorkspacesDict["WORKSPACE_ID_TWO"],
    dateCreated: nowDate,
    adminId: DEMO_USER_ID,
    userIds: [DEMO_USER_ID],
    projects: [PROJECT_SCHEMA_DATA["PROJECT_ID_THREE"]],
    isPremium: false,
  },
  WORKSPACE_ID_THREE: {
    ...mockBaseWorkspacesDict["WORKSPACE_ID_THREE"],
    dateCreated: nowDate,
    adminId: DEMO_USER_ID,
    userIds: [DEMO_USER_ID],
    projects: [],
    isPremium: false,
  },
};

export type ResourceKeys = keyof typeof IDS.NOTE | keyof typeof IDS.DOCUMENT;
export const RESOURCES_DATA: Record<ResourceKeys, ResourceElement> = {
  DOCUMENT_ID_ONE: { ...DOCUMENT_DATA["DOCUMENT_ID_ONE"] },
  DOCUMENT_ID_TWO: { ...DOCUMENT_DATA["DOCUMENT_ID_TWO"] },
  DOCUMENT_ID_THREE: { ...DOCUMENT_DATA["DOCUMENT_ID_THREE"] },
  NOTE_ID_ONE: { ...NOTE_DATA["NOTE_ID_ONE"] },
  NOTE_ID_TWO: { ...NOTE_DATA["NOTE_ID_TWO"] },
  NOTE_ID_THREE: { ...NOTE_DATA["NOTE_ID_THREE"] },
};

export const QA_BOOK_IDS = {
  DOCUMENT_ID_ONE: "demo_book_01",
  DOCUMENT_ID_TWO: "demo_book_02",
  DOCUMENT_ID_THREE: "demo_book_03",
};

export const DEMO_DOCUMENT_TO_PROJECT = {
  DOCUMENT_ID_ONE: IDS.PROJECT.PROJECT_ID_ONE,
  DOCUMENT_ID_TWO: IDS.PROJECT.PROJECT_ID_TWO,
  DOCUMENT_ID_THREE: IDS.PROJECT.PROJECT_ID_THREE,
};

export const QUINO_DEMO_LINK = `${ROUTES.DEMO}${ROUTES.DOCUMENTS}/all/${IDS.PROJECT.PROJECT_ID_THREE}?note-tab=document-summary&document=${IDS.DOCUMENT.DOCUMENT_ID_THREE}`;

export const TEMPORARY_PROJECT_ID = "preview";
