import type { ElementRef, SVGProps } from "react";

import { cn } from "@/utils";

export const LibraryIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("h-6 w-6 fill-text-default", props.className)}
    >
      <path
        d="M21.328 10C21.399 10 21.4691 10.0151 21.5338 10.0443C21.5985 10.0736 21.6562 10.1162 21.7032 10.1695C21.7501 10.2227 21.7851 10.2854 21.806 10.3532C21.8268 10.4211 21.8329 10.4926 21.824 10.563L21.807 10.643L18.917 20.287C18.861 20.4737 18.7517 20.6401 18.6025 20.7655C18.4532 20.8909 18.2706 20.97 18.077 20.993L17.96 21H4C3.60942 21.0009 3.22721 20.8868 2.901 20.672C2.8736 20.5972 2.86468 20.517 2.875 20.438L2.892 20.356L5.786 10.713C5.84189 10.5264 5.95106 10.3602 6.10011 10.2348C6.24915 10.1093 6.43159 10.0302 6.625 10.007L6.744 10H21.328ZM9.52 3C9.78948 2.99985 10.0562 3.05416 10.3042 3.15967C10.5522 3.26518 10.7762 3.41972 10.963 3.614L11.083 3.751L12.48 5.5H19C19.5046 5.49984 19.9906 5.69041 20.3605 6.0335C20.7305 6.37659 20.9572 6.84685 20.995 7.35L21 7.5V8H6.744C6.13087 7.99999 5.53246 8.18785 5.02934 8.53828C4.52622 8.88871 4.14255 9.38489 3.93 9.96L3.87 10.138L2 16.37V5C1.99984 4.49542 2.19041 4.00943 2.5335 3.63945C2.87659 3.26947 3.34684 3.04284 3.85 3.005L4 3H9.52Z"
        fill="inherit"
      />
    </svg>
  );
};
export default LibraryIcon;
