import { useCallback, useMemo } from "react";

import { useLeaveWorkspace, useWorkspace } from "@/api/workspace.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import { Button } from "@/components/ui/button.tsx";
import { DialogFooter } from "@/components/ui/dialog.tsx";
import {
  QuinoShareLink,
  QuinoPopoverTitle,
  CollaboratorCard,
  SharedIcon,
  SingleIcon,
  NotEnoughIcon,
  WorkspaceInfoCard,
} from "@/components/v3";
import { useFirebaseUserId } from "@/firebase/hooks.ts";
import { useIsInFreeUserGroup } from "@/service/hooks/settings";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const infoCardData = {
  SharedIsActive: {
    icon: <SharedIcon />,
    title: "Shared workspace is active",
    description: "Unlimited document summaries are available in this shared workspace.",
    bgColor: "bg-primitive-green-100",
  },
  SingleUserWorkspace: {
    icon: <SingleIcon />,
    title: "Single-user workspace",
    description:
      "You have 3 monthly document summaries in single-user workspaces. Upgrade to Pro or invite friends to get unlimited summaries.",
    bgColor: "bg-white",
  },
  NotEnoughCollaborators: {
    icon: <NotEnoughIcon />,
    title: "Not enough active collaborators",
    description: "Unlimited document summaries are paused in this shared workspace due to insufficient active members.",
    bgColor: "bg-container-feedback-warning",
  },
};

const WorkspaceCollaboratorsPanel = () => {
  const [{ workspaceId }, setModalState] = useModalState(ModalKeys.WORKSPACE_SETTINGS);
  const { data: workspace } = useWorkspace(workspaceId);
  const currentUserId = useFirebaseUserId();

  const isCurrentUserAdmin = useMemo(() => workspace?.adminId === currentUserId, [workspace?.adminId, currentUserId]);
  const setOpenState = useCallback(
    (value: boolean) => setModalState({ isOpen: value, workspaceId: "" }),
    [setModalState],
  );
  const { mutate: leaveWorkspace } = useLeaveWorkspace();
  const onDeleteClick = useCallback(() => {
    leaveWorkspace(workspaceId);
    setOpenState(false);
  }, [leaveWorkspace, setOpenState, workspaceId]);

  const isFreeUser = useIsInFreeUserGroup();
  const isWorkspacePremium = workspace ? workspace?.isPremium : false;
  const isSingleUser = workspace ? workspace?.userIds.length < 2 : true;

  const PremiumWorkspaceInfoCard = useMemo(() => {
    if (isFreeUser && isWorkspacePremium) return <WorkspaceInfoCard content={infoCardData.SharedIsActive} />;
    if (isFreeUser && !isWorkspacePremium && isSingleUser)
      return <WorkspaceInfoCard content={infoCardData.SingleUserWorkspace} />;
    if (isFreeUser && !isWorkspacePremium && !isSingleUser)
      return <WorkspaceInfoCard content={infoCardData.NotEnoughCollaborators} />;
    return null;
  }, [isFreeUser, isWorkspacePremium, isSingleUser]);

  return (
    <section className="flex flex-col justify-between">
      <section className="flex flex-col gap-2">
        <QuinoPopoverTitle className="p-0" title={"Invite collaborators with link"} />
        <QuinoShareLink workspaceId={workspaceId} />
      </section>
      <section className="mt-4 max-h-[184px] overflow-y-auto">
        <div className="flex h-full flex-col gap-2">
          {workspace?.userIds.map((userId) => (
            <CollaboratorCard
              isAdmin={workspace?.adminId === userId}
              isLoggedInUserCurrentAdmin={isCurrentUserAdmin}
              key={userId}
              userId={userId}
            />
          ))}
        </div>
      </section>
      {PremiumWorkspaceInfoCard}
      <DialogFooter className="mt-12 justify-start sm:justify-start">
        <Button
          className="mt-auto"
          disabled={isCurrentUserAdmin}
          size="sm"
          type="button"
          variant="destructive-secondary"
          onClick={onDeleteClick}
        >
          Leave workspace
        </Button>
      </DialogFooter>
    </section>
  );
};
export default WorkspaceCollaboratorsPanel;
