import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateNote } from "@/api/note";
import { ModalKeys } from "@/assets/constants/modal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import { useCurrentProjectId } from "@/utils";
import { useModalState } from "@/zustand/slices/modal-slice";

const FormSchema = z.object({
  name: z
    .string()
    .min(1, {
      message: "Note title must be at least 1 character",
    })
    .max(100, { message: "Must be at most 100 characters" }),
});
type FormSchemaType = z.infer<typeof FormSchema>;

const CreateNoteModal = () => {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
    },
  });

  const [state, setState] = useModalState(ModalKeys.CREATE_NOTE_OPEN);

  const { mutate: createNote } = useCreateNote();
  const projectId = useCurrentProjectId();
  const workspaceId = useCurrentWorkspaceId();

  const handleSubmit = useCallback(
    ({ name }: FormSchemaType) => {
      if (!projectId) return;
      createNote({ name, projectId, workspaceId });
      setState(false);
      form.reset();
    },
    [projectId, createNote, workspaceId, setState, form],
  );

  useEffect(() => {
    if (!projectId) setState(false);
  }, [projectId, setState]);

  return (
    <Dialog open={state} onOpenChange={setState}>
      <DialogContent className="gap-6">
        <DialogHeader>
          <DialogTitle>New Note</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="px-2">
                  <FormLabel className="text-primary">Note Name</FormLabel>
                  <FormControl>
                    <Input className="resize-none" placeholder="Write here..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-6">
              <Button type="button" variant="secondary" onClick={() => setState(false)}>
                Cancel
              </Button>
              <Button type="submit">Create Note</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNoteModal;
