import { getAnalytics } from "@firebase/analytics";
import { type FirebaseOptions, initializeApp, FirebaseError } from "@firebase/app";
import {
  confirmPasswordReset,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  linkWithPopup,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  unlink,
  updatePassword,
  updateProfile,
  type UserCredential,
} from "@firebase/auth";
import {
  addDoc,
  collection,
  type CollectionReference,
  connectFirestoreEmulator,
  doc,
  type DocumentData,
  getDoc,
  getFirestore,
  setDoc,
  type DocumentReference,
} from "@firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "@firebase/functions";
import { connectStorageEmulator, getStorage } from "@firebase/storage";
import { logEvent } from "firebase/analytics";
import { deleteObject, ref } from "firebase/storage";
import _ from "lodash";
import ReactGA from "react-ga4";

import { CAPTCHA_VERIFICATION_FUNCTION, PROVIDERS, RegistrationTypes } from "@/assets/constants/constants";
import { FLASHCARD_IMAGE_BUCKET, NOTE_PARAGRAPH_IMAGE_BUCKET } from "@/assets/constants/firestore-constants.ts";
import { toast } from "@/components/ui/use-toast";
import { customGAEventSender, sendGAConversionEvent } from "@/service";
import { mixpanelIdentify, mixpanelPeopleSet, mixpanelTrack } from "@/service/mixpanel";
import {
  type FeedbackData,
  type OnboardingPayload,
  type PersonalData,
  type PersonalDataWithStripe,
} from "@/types/schemas";
import { removeEmpty } from "@/utils";

const firebaseAuthDomain = import.meta.env.VITE_AUTH_DOMAIN;
const apiKey = import.meta.env.VITE_FIREBASE_GOOGLE_API_KEY;
const gpcProjectId = import.meta.env.VITE_GCP_PROJECT_ID;
const messageSenderId = import.meta.env.VITE_MESSAGING_SENDER_ID;
const appId = import.meta.env.VITE_WEB_APP_ID;
const measurementId = import.meta.env.VITE_MEASUREMENT_ID;

const config: FirebaseOptions = {
  apiKey,
  authDomain: firebaseAuthDomain,
  projectId: gpcProjectId,
  storageBucket: `${gpcProjectId}.appspot.com`,
  messagingSenderId: messageSenderId,
  appId: appId,
  measurementId: measurementId,
};

const app = initializeApp(config);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics();

const docStorage = getStorage(app, import.meta.env.VITE_DOC_BUCKET_NAME);
const unprocessedDocStorage = getStorage(app, import.meta.env.VITE_UNPROCESSED_DOC_BUCKET_NAME);
const temporaryDocStorage = getStorage(app, import.meta.env.VITE_TEMPORARY_DOCUMENT_BUCKET_NAME);

const feedbackStorage = getStorage(app, import.meta.env.VITE_FEEDBACK_BUCKET_NAME);
const flashcardImageStorage = getStorage(app, FLASHCARD_IMAGE_BUCKET);
const noteParagraphImageBucket = getStorage(app, NOTE_PARAGRAPH_IMAGE_BUCKET);

const functions = getFunctions(app, "europe-west1");

if (import.meta.env.VITE_USE_EMULATOR === "TRUE") {
  const HOST = window.location.hostname ?? "localhost";
  connectFirestoreEmulator(db, HOST, 8080);
  connectAuthEmulator(auth, `http://${HOST}:9099`, { disableWarnings: true });
  connectStorageEmulator(storage, HOST, 9199);
  connectStorageEmulator(docStorage, HOST, 9199);
  connectStorageEmulator(unprocessedDocStorage, HOST, 9199);
  connectStorageEmulator(temporaryDocStorage, HOST, 9199);
  connectStorageEmulator(feedbackStorage, HOST, 9199);
  connectStorageEmulator(flashcardImageStorage, HOST, 9199);
  connectStorageEmulator(noteParagraphImageBucket, HOST, 9199);
  connectFunctionsEmulator(functions, HOST, 5001);
}

const createCollection = <T = DocumentData>(collectionName: string): CollectionReference<T> =>
  collection(db, collectionName) as CollectionReference<T>;
// export const newsAndUpdatesCollection =
//   createCollection<NewsAndUpdate>("news-and-updates");
// export const documentTutorial =
//   createCollection<TutorialStepContent>("document-tutorial");
// export const noteTutorial =
//   createCollection<TutorialStepContent>("note-tutorial");
// export const tutorialCollection =
//   createCollection<TutorialStepContent>("tutorial");
export const maintenanceCollection = createCollection<{
  maintenanceMode: boolean;
}>("maintenance");

export async function getPersonalData(): Promise<PersonalData | undefined> {
  if (auth.currentUser === null) return undefined;
  return (await getDoc(doc(db, "users", auth.currentUser.uid))).data() as PersonalData | undefined;
}

export async function getPersonalDataWithStripe(): Promise<PersonalDataWithStripe | undefined> {
  if (auth.currentUser === null) return undefined;
  const data = (await getDoc(doc(db, "users", auth.currentUser.uid))).data() as PersonalDataWithStripe | undefined;

  if (data && !data.publicMigrationDoneV2) {
    await setPersonalData({ ...data, publicMigrationDoneV2: true });
  }

  return data;
}

export async function saveOnboardingData(payload: OnboardingPayload): Promise<OnboardingPayload> {
  if (auth.currentUser === null) throw Error("User is not authneticated");
  const data = await getPersonalDataWithStripe();
  mixpanelIdentify(auth.currentUser.uid);
  mixpanelPeopleSet({
    $country: payload.countryCode,
    $created: payload.dateOfOnboarding,
    $email: data?.email,
    faculty: data?.faculty,
    $first_name: data?.firstName,
    how_you_know_us: payload.howDoYouKnowUs,
    occupation: payload.occupation,
    stripe_customer_id: data?.stripe_customer_id,
  });

  customGAEventSender("onboarding", "onboarding_done");
  const userDoc = doc(db, "users", auth.currentUser.uid);
  await setDoc(
    userDoc,
    {
      ...payload,
      onboardingDone: true,
    },
    { merge: true },
  );
  return payload;
}

function initializeAnalytics(): void {
  ReactGA.initialize(measurementId);
}

async function setPersonalPublicData(payload: Partial<{ photoUrl: string; displayName: string; email: string }>) {
  if (auth.currentUser === null) return;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  await setDoc(doc(db, "users", auth.currentUser.uid, "public", "account"), _.pickBy(payload, _.identity), {
    merge: true,
  });
}
async function setPersonalData(personalData: Partial<PersonalData>): Promise<void> {
  if (auth.currentUser === null) return;

  await setDoc(doc(db, "users", auth.currentUser.uid), personalData, {
    merge: true,
  });

  await setPersonalPublicData({
    email: personalData?.email ?? undefined,
    photoUrl: personalData?.photoUrl ?? undefined,
    displayName: personalData?.displayName ?? undefined,
  });

  mixpanelIdentify(auth.currentUser.uid);
  const initPayload = {
    $country: personalData.countryCode,
    $created: personalData.dateOfOnboarding,
    $email: personalData.email,
    faculty: personalData.faculty,
    $first_name: personalData.faculty,
    how_you_know_us: personalData.howDoYouKnowUs,
    $last_name: personalData.lastName,
    occupation: personalData.occupation,
  };
  const payload = removeEmpty(initPayload);

  mixpanelPeopleSet({
    ...payload,
  });
}

export async function saveFeedback(feedbackData: FeedbackData): Promise<void> {
  if (auth.currentUser === null) return;
  await addDoc(collection(db, "feedback"), feedbackData);
}

async function userCredentialHandler(
  authFunction: () => Promise<{
    credentials: UserCredential;
    isNew: boolean;
    extraData?: {
      firstName?: string;
      lastName?: string;
      countryCode?: string;
      newsLetter?: boolean;
      referralCode?: string;
    };
  }>,
): Promise<PersonalData | undefined> {
  const authResult = await authFunction();
  if (!authResult.isNew) return;
  const { user, providerId } = authResult.credentials;
  const { extraData } = authResult;
  const initialPersonalData: PersonalData = {
    dateOfOnboarding: null,
    surveyFormClicked: false,
    uid: user.uid,
    displayName: user.displayName,
    firstName: extraData?.firstName ?? null,
    lastName: extraData?.lastName ?? null,
    countryCode: extraData?.countryCode ?? null,
    photoUrl: user.photoURL === "" ? null : user.photoURL,
    provider: providerId,
    occupation: null,
    fieldOfOccupation: null,
    university: null,
    institute: null,
    faculty: null,
    averageStudyTime: null,
    betaTester: null,
    howDoYouKnowUs: null,
    dateOfBirth: null,
    gender: null,
    phoneNumber: null,
    letterSize: null,
    phoneCode: null,
    email: user.email,
    email_verified: false,
    onboardingDone: false,
    newsLetter: extraData?.newsLetter ?? false,
    tutorialDone: false,
    referralCode: extraData?.referralCode ?? null,
    lastSeenUpdate: 0,
    themeMode: "light",
  };
  if (providerId === "google.com")
    logEvent(analytics, "sign_up", {
      method: "google",
    });
  if (providerId === "facebook.com")
    logEvent(analytics, "sign_up", {
      method: "facebook",
    });
  if (providerId === "apple.com")
    logEvent(analytics, "sign_up", {
      method: "apple",
    });
  if (providerId === "password")
    logEvent(analytics, "sign_up", {
      method: "password",
    });

  await setPersonalData(initialPersonalData);
  return initialPersonalData;
}

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export const resetPassword = async (newPassword: string, oldPassword: string): Promise<void> => {
  const user = auth.currentUser;
  if (user === null || user.email === null) return;
  const credential = EmailAuthProvider.credential(user.email, oldPassword);
  await reauthenticateWithCredential(user, credential);
  await updatePassword(user, newPassword);
};

export const validatePassword = async (oldPassword: string) => {
  const user = auth.currentUser;
  if (!user || !user.email) throw new Error("No authenticated user!");
  const credential = EmailAuthProvider.credential(user.email, oldPassword);
  await reauthenticateWithCredential(user, credential);
};

export const forgottenPasswordReset = async (oobCode: string, newPassword: string): Promise<void> => {
  await confirmPasswordReset(auth, oobCode, newPassword);
};

export const linkWithGoogle = async (): Promise<void> => {
  if (auth.currentUser === null) return;
  const result = await linkWithPopup(auth.currentUser, googleProvider);
  GoogleAuthProvider.credentialFromResult(result);
};

export const unlinkWithGoogle = async (): Promise<void> => {
  if (auth.currentUser === null) return;
  await unlink(auth.currentUser, googleProvider.providerId);
};

export const linkWithFacebook = async (): Promise<void> => {
  if (auth.currentUser === null) return;
  const result = await linkWithPopup(auth.currentUser, facebookProvider);
  FacebookAuthProvider.credentialFromResult(result);
};

export const unlinkWithFacebook = async (): Promise<void> => {
  if (auth.currentUser === null) return;
  await unlink(auth.currentUser, facebookProvider.providerId);
};

export const linkWithApple = async (): Promise<void> => {
  if (auth.currentUser === null) return;
  const result = await linkWithPopup(auth.currentUser, appleProvider);
  OAuthProvider.credentialFromResult(result);
};

export const unlinkWithApple = async (): Promise<void> => {
  if (auth.currentUser === null) return;
  await unlink(auth.currentUser, appleProvider.providerId);
};

const signUpWithGoogle = async (extra?: {
  newsLetter?: boolean;
  referralCode?: string;
}): Promise<PersonalData | undefined> =>
  userCredentialHandler(async () => {
    const res = await signInWithPopup(auth, googleProvider);
    const theDoc = (await getDoc(doc(db, "users", res.user.uid))).data() as PersonalData | undefined;

    customGAEventSender("login", "login_with_google");
    const isNew = theDoc === undefined;
    if (isNew) {
      mixpanelTrack("registration", { type: RegistrationTypes.GOOGLE });
      sendGAConversionEvent();
    }

    return {
      credentials: res,
      isNew,
      extraData: {
        newsLetter: extra?.newsLetter,
        referralCode: isNew ? extra?.referralCode : undefined,
      },
    };
  });

const signUpWithFacebook = async (extra?: {
  newsLetter?: boolean;
  referralCode?: string;
}): Promise<PersonalData | undefined> => {
  return userCredentialHandler(async () => {
    try {
      const res = await signInWithPopup(auth, facebookProvider);
      const theDoc = (await getDoc(doc(db, "users", res.user.uid))).data() as PersonalData | undefined;

      customGAEventSender("login", "login_with_facebook");
      const isNew = theDoc === undefined;
      if (isNew) {
        mixpanelTrack("registration", { type: RegistrationTypes.FACEBOOK });
        sendGAConversionEvent();
      }

      return {
        credentials: res,
        isNew,
        extraData: {
          newsLetter: extra?.newsLetter,
          referralCode: isNew ? extra?.referralCode : undefined,
        },
      };
    } catch (error: unknown) {
      if (error instanceof FirebaseError && error.code === "auth/account-exists-with-different-credential") {
        toast({
          title:
            "Your account is linked to an existing provider, please use this for authentication! Please login to link your account.",
          variant: "destructive",
        });
        throw error;
      }
      throw error;
    }
  });
};

const signUpWithApple = async (extra?: {
  newsLetter?: boolean;
  referralCode?: string;
}): Promise<PersonalData | undefined> =>
  userCredentialHandler(async () => {
    const res = await signInWithPopup(auth, appleProvider);
    const theDoc = (await getDoc(doc(db, "users", res.user.uid))).data() as PersonalData | undefined;

    customGAEventSender("login", "login_with_apple");
    const isNew = theDoc === undefined;
    if (isNew) {
      mixpanelTrack("registration", { type: RegistrationTypes.APPLE });
      sendGAConversionEvent();
    }

    return {
      credentials: res,
      isNew,
      extraData: {
        newsLetter: extra?.newsLetter,
        referralCode: isNew ? extra?.referralCode : undefined,
      },
    };
  });

const logInWithEmailAndPassword = async (email: string, password: string): Promise<PersonalData | undefined> =>
  userCredentialHandler(async () => {
    const res = await signInWithEmailAndPassword(auth, email, password);
    customGAEventSender("login", "login_simple");
    return { credentials: res, isNew: false };
  });

const registerWithEmailAndPassword = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  referralCode?: string,
): Promise<PersonalData | undefined> =>
  userCredentialHandler(async () => {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(res.user, {
      displayName: `${firstName} ${lastName}`,
    });
    if (auth.currentUser === null) throw Error("Invalid application state!");
    mixpanelTrack("registration", { type: RegistrationTypes.EMAIL });
    sendGAConversionEvent();

    return {
      credentials: {
        user: auth.currentUser,
        operationType: res.operationType,
        providerId: PROVIDERS.PASSWORD,
      },
      isNew: true,
      extraData: {
        firstName: firstName,
        lastName: lastName,
        referralCode,
      },
    };
  });

const sendPasswordReset = async (email: string): Promise<void> => {
  await sendPasswordResetEmail(auth, email);
};

export async function handleCaptchaVerification(token: string): Promise<boolean> {
  const captchaFunction = httpsCallable<{ token: string }, { success: boolean }>(
    functions,
    CAPTCHA_VERIFICATION_FUNCTION,
    {},
  );
  const result = await captchaFunction({ token });
  return result.data.success;
}

const logout = (): void => {
  void signOut(auth);
};

const sendVerificationEmail = (): boolean => {
  if (auth.currentUser && !auth.currentUser.emailVerified) {
    void sendEmailVerification(auth.currentUser);
    return true;
  }
  return false;
};

const setEmailToVerifiedFirestore = async (): Promise<void> => {
  if (auth.currentUser === null) return;
  await setDoc(
    doc(db, "users", auth.currentUser.uid),
    { email_verified: true },
    {
      merge: true,
    },
  );
};

export async function getFirebaseAccessToken(): Promise<string> {
  return (await auth.currentUser?.getIdToken(false)) ?? "";
}

export async function getAccessToken(): Promise<string> {
  if (process.env.NODE_ENV === "production") {
    return getFirebaseAccessToken();
  }
  return getFirebaseAccessToken();
  // return "rosseb";
}

export const removeFileFromStorage = async (photoUrl: string) => {
  const storageRef = ref(storage, photoUrl);
  await deleteObject(storageRef);
};

export const getFlashCardImageRef = (destinationPath: string) => {
  return ref(flashcardImageStorage, destinationPath);
};
export const getNoteParagarphImageRef = (destinationPath: string) => {
  return ref(noteParagraphImageBucket, destinationPath);
};

const setLastSeenUpdate = async (version: number, uid: string): Promise<void> => {
  const userDoc = doc(db, "users", uid) as DocumentReference<PersonalData>;
  await setDoc(userDoc, { lastSeenUpdate: version }, { merge: true });
};

export {
  app,
  auth,
  db,
  storage,
  docStorage,
  unprocessedDocStorage,
  temporaryDocStorage,
  feedbackStorage,
  functions,
  signUpWithGoogle,
  signUpWithFacebook,
  signUpWithApple,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  analytics,
  sendVerificationEmail,
  setEmailToVerifiedFirestore,
  initializeAnalytics,
  setPersonalData,
  flashcardImageStorage,
  noteParagraphImageBucket,
  setLastSeenUpdate,
};
