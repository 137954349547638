import { type StateCreator } from "zustand";

import { type AppSlice, type LibraryPageSlice } from "@/types/app.ts";

const createLibrarySlice: StateCreator<AppSlice, [["zustand/devtools", never]], [], LibraryPageSlice> = (set) => ({
  showDetailsView: true,
  setShowDetailsView: (value) => set({ showDetailsView: value }, false, "setShowDetailsView"),
  libraryView: "row",
  librarySorting: "ASC",
  setLibrarySorting: (librarySorting) => set({ librarySorting }),
  showFilterBar: false,
  selectedProjects: [],
  selectAllProjects: (workspaces) => set({ selectedProjects: workspaces }),
  selectProject: (workspace) =>
    set((state) => ({
      selectedProjects: [...state.selectedProjects, workspace],
    })),
  unSelectProject: (workspaceId) =>
    set((state) => ({ selectedProjects: state.selectedProjects.filter((resource) => resource.id != workspaceId) })),
  unSelectAllProjects: () => set({ selectedProjects: [] }),
  selectedResources: [],
  selectResource: (resource) =>
    set(
      (state) => ({
        selectedResources: [...state.selectedResources, resource],
      }),
      false,
      "selectResource",
    ),
  unselectResource: (resourceId) =>
    set(
      (state) => ({
        selectedResources: state.selectedResources.filter((resource) => resource.id != resourceId),
      }),
      false,
      "unselectResource",
    ),
  selectAllResources: (resources) => set({ selectedResources: resources }, false, "selectAllResources"),
  unselectAllResources: () => set({ selectedResources: [] }, false, "unselectAllResources"),
  setLibraryView: (view) => set({ libraryView: view }, false, "setLibraryView"),
  setShowFilterBar: (show) => set({ showFilterBar: show }, false, "setShowFilterBar"),
});

export default createLibrarySlice;
