import { useMemo } from "react";

import { AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { useUserPublicData } from "@/firebase/hooks.ts";
import { LoadingSpinner } from "@/pages/loading";
import { getAvatarFallback } from "@/service/user.ts";

const UserAvatarImage = ({ userId }: { userId: string }) => {
  const [publicDataResponse, loading] = useUserPublicData({ userId });
  const publicData = useMemo(() => {
    const data = publicDataResponse?.data();
    return {
      photoUrl: data?.photoUrl,
      avatarFallback: getAvatarFallback(data?.displayName, userId),
      displayName: data?.displayName,
    };
  }, [publicDataResponse, userId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AvatarImage alt="user-avatar" className="h-12 w-12 bg-primary object-cover" src={publicData?.photoUrl ?? ""} />
      <AvatarFallback className="text-md" fallbackColorIndex={publicData?.avatarFallback.fallbackColorIndex ?? 4}>
        {publicData?.avatarFallback.monogram ?? "QU"}
      </AvatarFallback>
    </>
  );
};
export default UserAvatarImage;
