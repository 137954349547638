import CountryLock from "@/components/guards/active-use-case-guard/country-lock.tsx";
import HandleRedirect from "@/components/guards/active-use-case-guard/handle-redirect.tsx";

const ActiveUseCaseGuard = () => {
  return (
    <CountryLock>
      <HandleRedirect />
    </CountryLock>
  );
};
export default ActiveUseCaseGuard;
