import { z } from "zod";

export const basicResponseSchema = z
  .object({
    result: z.string(),
  })
  .strict();

export type BasicResponse = z.infer<typeof basicResponseSchema>;

export const basicArrayStringResponseSchema = z.array(z.string());

export type BasicArrayStringResponse = z.infer<typeof basicArrayStringResponseSchema>;
