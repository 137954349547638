import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Education = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <path
      d="M3.05507 23.2405L7.18046 26.9864L5.34237 34.0656L14.2933 38.7919L14.8463 52.9422L26.8469 41.9894L35.6003 50.7766L45.4311 44.4229L47.1894 36.061L54.5789 34.5915L46.6963 24.2195L53.3772 15.885L39.6595 9.63802L26.7992 17.1291L22.732 8.58469L10.3823 17.2535L13.0832 22.6588L3.05507 23.2405Z"
      fill="#E2D7F4"
    />
    <g clipPath="url(#clip0_2426_703)">
      <path
        d="M55.217 23.2254C53.8971 23.9983 51.9649 25.1275 49.6011 26.4976C49.3803 26.6231 49.1595 26.7535 48.9337 26.884C48.3264 27.2353 47.7643 27.5565 47.2424 27.8577C46.9011 28.0534 46.5598 28.2541 46.2185 28.4499C44.457 29.4586 43.1572 30.1964 41.9125 30.909C39.2376 32.4497 35.3782 34.6881 30.5553 37.5738C29.9932 37.3179 29.4311 37.0569 28.864 36.7909C28.4976 36.6152 28.1312 36.4446 27.7649 36.2689C25.4212 35.1498 23.0222 33.9503 20.5681 32.6605C20.2068 32.4698 19.8454 32.2791 19.4841 32.0834C19.3536 32.0181 19.2181 31.9429 19.0826 31.8726C18.8768 31.7622 18.666 31.6468 18.4603 31.5363C18.0889 31.3356 17.7225 31.1348 17.3562 30.9341C17.0651 30.7735 16.774 30.6129 16.4879 30.4523C15.0425 29.6493 13.6323 28.8463 12.2622 28.0433C9.81309 26.608 7.47439 25.1676 5.24609 23.7373C10.4003 20.435 15.7301 16.7463 21.1553 12.631C23.5241 10.8343 25.7976 9.03762 27.9907 7.25098C33.7221 11.0953 39.9502 14.9496 46.7003 18.6936C49.5811 20.2945 52.4216 21.8051 55.217 23.2254Z"
        fill="#6AC3A5"
        stroke="#326D5D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M27.7361 20.8509C27.9368 22.0454 29.6833 22.7129 30.7523 22.1558C31.224 21.9099 31.5854 21.418 31.6607 20.866C31.8012 19.8472 30.953 18.6628 29.7686 18.6226C28.524 18.5775 27.5604 19.8372 27.7361 20.8509Z"
        fill="#6AC3A5"
        stroke="#326D5D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M49.6026 26.4978L49.0706 41.4836C47.0079 41.9804 44.7395 42.6579 42.3456 43.5964C37.6531 45.4282 33.8741 47.6967 31.0034 49.7493C28.5392 48.1333 25.3072 46.3316 21.3274 44.836C18.0954 43.6215 15.1243 42.8888 12.615 42.4421C12.4995 37.6443 12.3841 32.8414 12.2637 28.0436C13.6338 28.8465 15.044 29.6495 16.4894 30.4525C16.7755 30.6131 17.0665 30.7737 17.3576 30.9343C17.724 31.1351 18.0904 31.3358 18.4617 31.5366C18.6675 31.647 18.8783 31.7624 19.0841 31.8728C19.2196 31.9431 19.3551 32.0183 19.4855 32.0836C19.8469 32.2793 20.2082 32.47 20.5696 32.6607C23.0237 33.9505 25.4226 35.15 27.7664 36.2692C28.1377 36.4448 28.4991 36.6205 28.8654 36.7911C29.4326 37.0571 29.9946 37.3181 30.5567 37.574C35.3797 34.6883 39.239 32.45 41.914 30.9092C43.1586 30.1966 44.4585 29.4588 46.22 28.4501C46.5613 28.2543 46.9026 28.0536 47.2438 27.8579C47.7658 27.5567 48.3279 27.2355 48.9351 26.8842C49.161 26.7538 49.3818 26.6233 49.6026 26.4978Z"
        fill="#6AC3A5"
        stroke="#326D5D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M18.4634 31.5363C18.8046 31.7169 19.1459 31.9026 19.4872 32.0833C19.3567 32.018 19.2212 31.9428 19.0857 31.8725C18.8799 31.7621 18.6691 31.6467 18.4634 31.5363Z"
        fill="#6AC3A5"
        stroke="#326D5D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M49.6073 26.4823V26.4974C49.3865 26.6228 49.1657 26.7533 48.9398 26.8838C48.3326 27.2351 47.7705 27.5563 47.2485 27.8574C48.0365 27.3957 48.8194 26.939 49.6073 26.4823Z"
        fill="#6AC3A5"
        stroke="#326D5D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path d="M28.367 21.8408L18.3748 31.4917C18.3748 31.4917 19.7047 38.6885 16.543 39.883" fill="#6AC3A5" />
      <path
        d="M28.367 21.8408L18.3748 31.4917C18.3748 31.4917 19.7047 38.6885 16.543 39.883"
        stroke="#326D5D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2426_703">
        <rect fill="white" height="43" transform="translate(5 7)" width="50.4728" />
      </clipPath>
    </defs>
  </svg>
);
export default Education;
