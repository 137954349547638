import { useCallback } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthState, useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { z } from "zod";

import { setPasswordResetRequest } from "@/api/user.ts";
import { ROUTES } from "@/assets/constants/constants";
import { BackToLogin } from "@/components/button/navigation";
import { Spinner } from "@/components/icons/common";
import { FormPageLayout } from "@/components/layout";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { auth } from "@/firebase";
import { mixpanelTrack } from "@/service/mixpanel";
import { cn } from "@/utils";

const newPasswordForm = z.object({
  email: z.string().email({ message: "Valid email is required!" }),
});

export type NewPasswordForm = z.infer<typeof newPasswordForm>;

const Reset = () => {
  const { toast } = useToast();
  const form = useForm<NewPasswordForm>({
    mode: "onBlur",
    resolver: zodResolver(newPasswordForm),
  });
  const [user, loading] = useAuthState(auth);
  const [passwordReset, sending] = useSendPasswordResetEmail(auth);

  const onSubmit = useCallback(
    ({ email }: NewPasswordForm) =>
      passwordReset(email)
        .then((res) => {
          void setPasswordResetRequest();
          if (res) {
            toast({
              variant: "success",
              title: "Password reset link sent!",
            });
            mixpanelTrack("password_reset_request");
          } else {
            toast({
              variant: "destructive",
              title: "An error has occurred, the email might be invalid!",
            });
          }
        })
        .catch(() =>
          toast({
            variant: "destructive",
            title: "An error occured while sending the password reset link.",
          }),
        ),
    [passwordReset, toast],
  );

  if (loading)
    return (
      <FormPageLayout>
        <section className="flex flex-row justify-center py-10">
          <Spinner className="h-10 w-10" />
        </section>
      </FormPageLayout>
    );
  if (user) return <Navigate to={ROUTES.WORKSPACES} />;
  return (
    <FormPageLayout title="Reset your password">
      <p className="mb-8 mt-4 text-center text-sm font-normal text-secondary-onBg">
        We&apos;ve sent you an email with a link to reset your password. Please wait a few moments for it to appear in
        your inbox.
      </p>
      <Form {...form}>
        <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem className="w-full">
                <FormLabel>Email address</FormLabel>
                <FormControl>
                  <Input
                    className={cn(fieldState.error && "!border-red-500")}
                    placeholder="example@email.com"
                    type="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">{sending ? <Spinner /> : "Reset password"}</Button>
        </form>
        <BackToLogin />
      </Form>
    </FormPageLayout>
  );
};

export default Reset;
