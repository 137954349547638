import React, { createContext, type Dispatch, type SetStateAction, useContext, useState } from "react";

const DragAndDropContext = createContext<{
  isDragActive: boolean;
  setIsDragActive: Dispatch<SetStateAction<boolean>>;
}>({
  isDragActive: false,
  setIsDragActive: () => new Error("No implementation"),
});

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useDragAndDropContext = () => useContext(DragAndDropContext);
const DragAndDropProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDragActive, setIsDragActive] = useState(false);

  return (
    <DragAndDropContext.Provider value={{ isDragActive, setIsDragActive }}>{children}</DragAndDropContext.Provider>
  );
};

export default DragAndDropProvider;
