import { useEffect } from "react";

import { useLocalStorage } from "usehooks-ts";

import { ModalKeys } from "@/assets/constants/modal.ts";
import QuinoRebornImageDark from "@/assets/svgs/qunio-reborn-dark.svg";
import QuinoRebornImage from "@/assets/svgs/qunio-reborn.svg";
import { Button } from "@/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useThemeModeImage } from "@/utils/hooks.ts";
import { useModalState } from "@/zustand/slices/modal-slice.ts";

const QuinoReborn = () => {
  const [openedOnce, setOpenedOnce] = useLocalStorage(ModalKeys.QUINO_REBORN, false);
  const [open, setOpen] = useModalState(ModalKeys.QUINO_REBORN);
  const [openCookie] = useModalState(ModalKeys.COOKIE_NOTICE_MODAL_OPEN);
  useEffect(() => {
    if (!openedOnce) {
      setOpen(true);
      setOpenedOnce(true);
    }
  }, [openedOnce, setOpenedOnce, setOpen]);

  const image = useThemeModeImage(QuinoRebornImage, QuinoRebornImageDark);

  return (
    <Dialog open={open && !openCookie} onOpenChange={setOpen}>
      <DialogContent className="gap-6">
        <DialogHeader className="gap-6">
          <img alt="reborn-image" src={image} />
          <DialogTitle>Quino is reborn!</DialogTitle>
          <DialogDescription>
            <p className="mb-2">
              We are happy to announce that we released Quino’s biggest update so far to serve your needs even more,
              focusing a lot more on <b>automation</b> and <b>AI features</b>.
            </p>
            <ul className="list-inside list-disc space-y-2">
              <li>Enjoy the refreshed and more professional UI.</li>
              <li>Organize your files and notes with ease with our new system.</li>
              <li>Try the enhanced AI features and boost your productivity!</li>
            </ul>
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button onClick={() => setOpen(false)}>Let&apos;s go</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default QuinoReborn;
