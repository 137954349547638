import { type ElementRef, type SVGProps } from "react";

import { cn } from "@/utils";

const Other = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    fill="none"
    viewBox="0 0 60 57"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-15 w-15", props.className)}
  >
    <path
      d="M42.9667 20.972C42.3038 9.3765 32.0654 -0.345642 20.3395 1.15305C8.47685 2.66898 0.097224 9.08581 1.07793 21.6406C1.85734 31.6201 8.82689 38.2867 19.9587 39.5001C32.2205 40.8373 43.7 33.7971 42.9667 20.972Z"
      fill="#DC5579"
      id="Vector"
      stroke="#8D264A"
      strokeLinejoin="bevel"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    />
    <path
      d="M14.6533 20.3555C14.5532 18.7706 13.0075 17.4418 11.2372 17.6466C9.4462 17.8538 8.18109 18.7309 8.32915 20.4468C8.44682 21.8108 9.49905 22.722 11.1797 22.8878C13.0309 23.0706 14.764 22.1084 14.6533 20.3555Z"
      fill="#FAE6EA"
      id="Vector_2"
    />
    <path
      d="M25.2187 20.3555C25.1187 18.7706 23.5729 17.4418 21.8026 17.6466C20.0116 17.8538 18.7465 18.7309 18.8946 20.4468C19.0122 21.8108 20.0645 22.722 21.7451 22.8878C23.5963 23.0706 25.3295 22.1084 25.2187 20.3555Z"
      fill="#FAE6EA"
      id="Vector_3"
    />
    <path
      d="M35.7842 20.3555C35.6841 18.7706 34.1383 17.4418 32.368 17.6466C30.5771 17.8538 29.3119 18.7309 29.46 20.4468C29.5777 21.8108 30.6299 22.722 32.3105 22.8878C34.1618 23.0706 35.8949 22.1084 35.7842 20.3555Z"
      fill="#FAE6EA"
      id="Vector_4"
    />
    <g id="Vector_5">
      <path
        d="M35.3891 32.1843C35.1831 36.3305 36.221 39.5541 41.6664 44.09C41.6664 44.09 30.704 43.4747 27.6093 35.5849"
        fill="#DC5579"
      />
      <path
        d="M35.3891 32.1843C35.1831 36.3305 36.221 39.5541 41.6664 44.09C41.6664 44.09 30.704 43.4747 27.6093 35.5849"
        stroke="#8D264A"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
    </g>
  </svg>
);
export default Other;
