import { Outlet } from "react-router-dom";

import { ChangePassword, DeleteAccount, EditInfomration } from "@/components/modals";

const Account = () => {
  return (
    <>
      <DeleteAccount />
      <EditInfomration />
      <ChangePassword oldRequired />
      <Outlet />
    </>
  );
};

export default Account;
