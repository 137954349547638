import { useCurrentWorkspace } from "@/api/workspace.ts";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx";
import { QuinoShareLink, QuinoPopoverTitle, ShareArrow, DemoHandlerButton } from "@/components/v3";
import { useDocumentSelectionState } from "@/service/library.ts";

const ShareSelectedResourceButton = () => {
  const { selectedResources } = useDocumentSelectionState();
  const { data: workspace } = useCurrentWorkspace();
  if (selectedResources.length === 0) return null;
  return (
    <Popover>
      <PopoverTrigger asChild>
        <DemoHandlerButton size="sm" variant="ghost">
          <ShareArrow />
          Share
        </DemoHandlerButton>
      </PopoverTrigger>
      <PopoverContent className="w-[26rem]">
        <section className="flex flex-col gap-1">
          <QuinoPopoverTitle title={`Share ${selectedResources.map((item) => `“${item.name}”`).join(", ")}`} />
          <QuinoShareLink workspaceId={workspace?.id ?? ""} />
        </section>
      </PopoverContent>
    </Popover>
  );
};

export default ShareSelectedResourceButton;
