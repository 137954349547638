import { useMemo } from "react";

import { createId } from "@paralleldrive/cuid2";
import Filter from "bad-words";
import { type ClassValue, clsx } from "clsx";
import { format, isToday, isYesterday } from "date-fns";
import { logEvent } from "firebase/analytics";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { type z } from "zod";

import { createNewNote } from "@/api/note";
import { useProjectData } from "@/api/project.ts";
import { postRequest } from "@/api/utils";
import {
  BACKEND_URL,
  FlashCardVariant,
  QUERY_PARAMS,
  ROUTES,
  STRAPI_API_KEY,
  STRAPI_URL,
} from "@/assets/constants/constants";
import { type ViewOptions } from "@/assets/constants/constants";
import { SessionOrderingMethod } from "@/assets/constants/flashcard.ts";
import { projectColorMap, DEFAULT_COLOR_KEY } from "@/assets/constants/projects";
import { analytics, getAccessToken } from "@/firebase";
import { useCurrentWorkspaceId } from "@/service/hooks/react-router.ts";
import {
  type ProjectDocumentRedisTicker,
  type DocumentDetailsSchema,
  type BasicFlashCardDto,
  type FlashCardViewTypes,
  type MultipleChoiceFlashCardDto,
  type MultipleFlashCardType,
  type TrueFalseFlashCardDto,
  LikeDislikeValue,
} from "@/types/schemas";
import { type CheckoutConfig, type CheckoutLinkParams } from "@/types/schemas";

const filter = new Filter();

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getStepPercentageProgress(currentStep: number, allSteps: number): number {
  if (currentStep === -1 || allSteps === -1) return 0;

  return currentStep / allSteps;
}

export const useCurrentProjectId = () => {
  const { projectId } = useParams();
  return useMemo(() => projectId ?? "", [projectId]);
};

export const useCurrentProjectData = () => {
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  return useProjectData(workspaceId, projectId);
};

const pathToDisplayNameMap: Record<ViewOptions, string> = {
  all: "All projects",
  starred: "Starred items",
  shared: "Shared projects",
};

export const useProjectsViewDisplayName = () => {
  const { view: _view } = useParams();
  const view = _view as ViewOptions | undefined;
  return pathToDisplayNameMap[view ?? "all"];
};

export const useView = () => {
  const { view: _view } = useParams();
  return _view as ViewOptions | undefined;
};

export const getNoteTab = (searchParams: URLSearchParams): string => {
  const tabName = searchParams.get(QUERY_PARAMS.NOTE);
  if (tabName !== null) {
    return tabName;
  }
  return "";
};

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getActiveNoteTab = async (
  noteIds: string[],
  activeNoteTab: string | null,
  workspaceId: string,
  projectId: string,
) => {
  if (noteIds.length === 0) {
    const result = await createNewNote({
      projectId,
      workspaceId,
      name: "New note",
    });
    return result.id;
  } else if (!activeNoteTab || noteIds.findIndex((item) => item === activeNoteTab) === -1) {
    return noteIds[0];
  }
  return activeNoteTab;
};

export const getDefaultHeaders = async (isJson: boolean = true): Promise<HeadersInit> => {
  return {
    Authorization: `Bearer ${await getAccessToken()}`,
    ...(isJson ? { "Content-Type": "application/json" } : {}),
  };
};
export const getStrapiDefaultHeaders = (): HeadersInit => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${STRAPI_API_KEY}`,
  };
};

export const getHistoryDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (isToday(date)) {
    return "Today";
  }
  if (isYesterday(date)) {
    return "Yesterday";
  }
  return format(date, "MM-dd-yyy");
};

export const getKeyDate = (dateString: string): string => {
  return format(new Date(dateString), "yyyy-MM-dd");
};

export const useStringifiedFeedback = (likeDislikeValue?: number) =>
  useMemo(
    () =>
      likeDislikeValue !== undefined
        ? likeDislikeValue == 0
          ? "EMPTY"
          : likeDislikeValue > 0
          ? "LIKE"
          : "DISLIKE"
        : "EMPTY",
    [likeDislikeValue],
  );

export const useStringifiedFeedbackSummary = (likeDislikeValue?: LikeDislikeValue) =>
  useMemo(
    () =>
      likeDislikeValue !== undefined
        ? likeDislikeValue == LikeDislikeValue.NEUTRAL
          ? "EMPTY"
          : likeDislikeValue === LikeDislikeValue.UPVOTE
          ? "LIKE"
          : "DISLIKE"
        : "EMPTY",
    [likeDislikeValue],
  );

export const getStrapiMediaUrl = (route: string | undefined): string => {
  try {
    if (route === undefined) throw new Error("Route shouldn't be undefined");

    const url = new URL(route);

    if (url.protocol === "http:" || url.protocol === "https:") return `${route}`;
    else return `${STRAPI_URL}${route}`;
  } catch (_) {
    return `${STRAPI_URL}${route}`;
  }
};

export function validateFormString(inputString: string): boolean {
  if (inputString.length === 0) return true;
  return !filter.isProfane(inputString);
}

export const validPassword = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W_]){1,})(?!.*\s).{8,}$/;

export const checkIfInvalidCard = (card: FlashCardViewTypes): boolean => {
  switch (card.cardType) {
    case FlashCardVariant.BASIC:
      if (!card.question && !card.questionImage) return true;
      if (!card.answer && !card.answerImage) return true;
      break;
    case FlashCardVariant.MULTIPLE:
      if (!card.question && !card.questionImage) return true;
      if (!card.correctAnswer.answer && !card.correctAnswer.answerImage) return true;
      if (!card.incorrectAnswerOne.answer && !card.incorrectAnswerOne.answerImage) return true;
      if (!card.incorrectAnswerTwo.answer && !card.incorrectAnswerTwo.answerImage) return true;
      if (!card.incorrectAnswerThree.answer && !card.incorrectAnswerThree.answerImage) return true;
      break;
    case FlashCardVariant.TRUE_FALSE:
      if (card.answer === undefined) return true;
      if (!card.question && !card.questionImage) return true;
      break;
    default:
      return true;
  }
  return false;
};

export const defaultMultipleCard: MultipleChoiceFlashCardDto = {
  id: "",
  userId: "",
  dateCreated: "",
  cardType: FlashCardVariant.MULTIPLE,
  knowledgeScore: -1,
  question: "",
  questionImage: "",
  answers: [
    {
      answer: "",
      answerImage: null,
      isCorrectAnswer: true,
      id: createId(),
    },
    {
      answer: "",
      answerImage: null,
      isCorrectAnswer: false,
      id: createId(),
    },
    {
      answer: "",
      answerImage: null,
      isCorrectAnswer: false,
      id: createId(),
    },
    {
      answer: "",
      answerImage: null,
      isCorrectAnswer: false,
      id: createId(),
    },
  ],
};

export const defaultTrueFalseCard: TrueFalseFlashCardDto = {
  cardType: FlashCardVariant.TRUE_FALSE,
  userId: "",
  question: "",
  dateCreated: "",
  id: "",
  knowledgeScore: -1,
  questionImage: null,
};

export const defaultBasicCard: BasicFlashCardDto = {
  cardType: FlashCardVariant.BASIC,
  userId: "",
  dateCreated: "",
  id: "",
  knowledgeScore: -1,
  question: "",
  questionImage: "",
  answer: "",
  answerImage: "",
};

export const dtoToCard = (dto: MultipleChoiceFlashCardDto): MultipleFlashCardType => {
  const correctAnswer = dto.answers.find((item) => item.isCorrectAnswer);
  if (!correctAnswer) throw Error("Invalid data structure!");
  const dataSet = dto.answers.filter((item) => !item.isCorrectAnswer);
  return {
    id: dto.id,
    userId: dto.userId,
    knowledgeScore: dto.knowledgeScore,
    correctAnswer: correctAnswer,
    cardType: dto.cardType,
    question: dto.question,
    dateCreated: dto.dateCreated,
    questionImage: dto.questionImage,
    incorrectAnswerOne: dataSet[0],
    incorrectAnswerTwo: dataSet[1],
    incorrectAnswerThree: dataSet[2],
  };
};

export const cardToDto = (entity: MultipleFlashCardType): MultipleChoiceFlashCardDto => {
  return {
    id: entity.id,
    userId: entity.userId,
    knowledgeScore: entity.knowledgeScore,
    cardType: entity.cardType,
    question: entity.question,
    dateCreated: entity.dateCreated,
    questionImage: entity.questionImage,
    answers: [entity.correctAnswer, entity.incorrectAnswerOne, entity.incorrectAnswerTwo, entity.incorrectAnswerThree],
  };
};

export const createCheckoutLink = async ({ priceId, mode, billingAnchor }: CheckoutLinkParams): Promise<void> => {
  logEvent(analytics, "begin_checkout");
  const checkoutConfig: CheckoutConfig = {
    line_items: [
      {
        price: priceId,
        quantity: 1,
      },
    ],
    mode,
    success_url: `${window.location.origin}${ROUTES.SUCCESSFUL_PAYMENT}`,
    cancel_url: `${window.location.origin}${ROUTES.UNSUCCESSFUL_PAYMENT}`,
  };

  const checkoutSession = await postRequest<{
    url: string;
    response_text: string;
  }>(`${BACKEND_URL}/subscriptions/create-checkout-session`, checkoutConfig);

  if (!billingAnchor) {
    window.location.replace(checkoutSession.url);
  } else {
    console.log("downgrade", checkoutSession.response_text);
  }
};

export const decideError = (docStatus?: ProjectDocumentRedisTicker) => {
  if (docStatus?.errorMessage === undefined) return false;
  return docStatus.errorMessage !== null;
};

export const getNameInitials = (name: string): string => name[0];

export const logOptionsToConsole = () => {};
export async function validateZodSchema<T>(data: Promise<T> | T, validator: z.ZodSchema<unknown>): Promise<T> {
  const response = await data;
  if (process.env.NODE_ENV === "development") {
    const parseResult = validator.safeParse(response);
    if (!parseResult.success) {
      console.log(parseResult.error.errors);
      console.error(parseResult.error);
      console.trace();

      alert("Zod schema validation error!\n");
    }
  }

  return response;
}

export const getOutlineColor = (color?: string | null) => {
  if (!color) return projectColorMap[DEFAULT_COLOR_KEY];
  if (Object.hasOwn(projectColorMap, color)) {
    const key = color as keyof typeof projectColorMap;
    return projectColorMap[key];
  }

  return projectColorMap[DEFAULT_COLOR_KEY];
};

export const scaleRelationScore = (score: number) => {
  return Math.pow(score, 5);
};

export const isHealthyDocument = (documentDetails?: DocumentDetailsSchema) => {
  return documentDetails?.documentHealthLevel === "LANGUAGE" || documentDetails?.documentHealthLevel === "OK";
};

export function removeEmpty(obj: object) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined));
}

export function randomNumber(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export const literalToEnum = (value: "random" | "leitner" | "chronological") => {
  switch (value) {
    case "chronological":
      return SessionOrderingMethod.CHRONOLOGICAL;
    case "random":
      return SessionOrderingMethod.RANDOM;
    case "leitner":
      return SessionOrderingMethod.LEITNER;
    default:
      throw Error(`Enum conversion error with value ${value as string}`);
  }
};

export const oneYearFromNowDate = () => {
  // Create a new Date object
  const date = new Date();

  // Add one month to the current date
  date.setFullYear(date.getFullYear() + 1);

  // Output the date one month from now
  return date;
};

export const NOOP_FUNCTION = () => {};

export const getPagesTagForSummaryParagraph = (pageNumbers?: number[]) => {
  if (!pageNumbers) return "";
  pageNumbers.sort((a, b) => a - b);
  if (pageNumbers.length === 1) return `Page ${pageNumbers[0]}`;
  const sorted = pageNumbers.sort((a, b) => a - b);
  return `Page ${sorted[0]}-${sorted[sorted.length - 1]}`;
};

export function copyTextToClipboard(value: string) {
  return navigator.clipboard.writeText(value);
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
