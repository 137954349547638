import { useEffect, useMemo, useState } from "react";

import { updateProfile, type User } from "@firebase/auth";
import { type StorageReference } from "@firebase/storage";
import { createId } from "@paralleldrive/cuid2";
import { useMutation, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { type FirebaseError } from "firebase/app";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData, useDocumentOnce } from "react-firebase-hooks/firestore";

import { PROVIDERS } from "@/assets/constants/constants";
import { DEMO_USER_ID } from "@/assets/constants/demo.ts";
import { toast } from "@/components/ui/use-toast";
import {
  auth,
  db,
  getFlashCardImageRef,
  getPersonalDataWithStripe,
  maintenanceCollection,
  noteParagraphImageBucket,
  removeFileFromStorage,
  setPersonalData,
  storage,
} from "@/firebase/index";
import { useIsDemoLikePage } from "@/service/hooks/misc.ts";
import { getAvatarFallback } from "@/service/user.ts";
import { type QueryConfig } from "@/types/react-query";
import { type PersonalDataWithStripe, type PublicUserData } from "@/types/schemas";
import useAppStateStore from "@/zustand/store.ts";

export const useAppAuthState = () => useAuthState(auth);
export const useMaintenanceMode = (): [boolean, boolean, FirebaseError | undefined] => {
  const userId = useFirebaseUserId();
  const [data, loading, error] = useCollectionData<{
    maintenanceMode: boolean;
  }>(maintenanceCollection);

  const isMaintenance = useMemo(() => {
    if (userId === "Km3mANF7PUcrYxHBNlZgDfRvecp1" || userId === "7MOpC6srF1VPhaB0JAS6KvKtgMa2") return false;
    return data?.[0]?.maintenanceMode ?? false;
  }, [data, userId]);

  return [isMaintenance, loading, error];
};

export const useAuthUserData = (options?: {
  options: QueryConfig<PersonalDataWithStripe>;
}): {
  /// Personal data as required, null of there is no user, undefined is if the query is not yet finalized
  data: PersonalDataWithStripe | null | undefined;
  isLoading: boolean;
  isError: Error | boolean;
  firebaseUser: User | null | undefined;
} => {
  const [user, authLoading, authError] = useAppAuthState();

  const {
    data,
    isLoading: dataLoading,
    isError: dataError,
    isInitialLoading,
  } = useQuery({
    queryKey: ["firebase-user-data", { user: user?.uid }],
    queryFn: getPersonalDataWithStripe,
    enabled: user?.uid !== undefined && user?.uid !== null,
    ...options,
  });
  const isLoading = useMemo(
    () => (authLoading || isInitialLoading ? isInitialLoading || dataLoading : isInitialLoading),
    [authLoading, dataLoading, isInitialLoading],
  );
  const isError = useMemo(() => authError || dataError, [authError, dataError]);
  const responseData = useMemo(() => (user === null ? null : data), [data, user]);
  return { data: responseData, isLoading: isLoading, isError: isError, firebaseUser: user };
};

export const useUpdateFirebaseUserData = () => {
  const queryClient = useQueryClient();
  const [user] = useAppAuthState();
  return useMutation({
    mutationFn: async ({ payload }: { payload: Partial<PersonalDataWithStripe> }) => await setPersonalData(payload),
    onSuccess: (_, { payload }) => {
      queryClient.setQueryData<PersonalDataWithStripe>(["firebase-user-data", { user: user?.uid }], (oldData) =>
        oldData ? { ...oldData, ...payload } : oldData,
      );
    },
  });
};

export const useFirebaseTheme = () => {
  const { data } = useAuthUserData();
  return useMemo(() => data?.themeMode, [data?.themeMode]);
};

export const useIsAuthenticated = () => {
  const [user, loading] = useAppAuthState();

  return useMemo(() => [user !== null && user !== undefined, loading], [user, loading]);
};

export const useIsPasswordProvider = () => {
  const [user] = useAppAuthState();
  return useMemo(
    () => (user ? user.providerData.some((item) => item.providerId === PROVIDERS.PASSWORD) : undefined),
    [user],
  );
};

export const useUpdateUserPhotoUrl = () => {
  const [user] = useAppAuthState();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ file }: { file: File }) => {
      if (!user) return;

      if (user.photoURL) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          await removeFileFromStorage(user.photoURL);
        } catch (e) {
          console.log(e);
        }
      }

      const name = createId().toLowerCase();
      const storagePath = `/${user.uid}/${name}`;
      const storageRef = ref(storage, storagePath);
      const result = await uploadBytes(storageRef, file);

      const downloadUrl = await getDownloadURL(result.ref);

      await updateProfile(user, {
        photoURL: downloadUrl,
      });

      await setPersonalData({ photoUrl: downloadUrl });
      queryClient.setQueryData<PersonalDataWithStripe>(["firebase-user-data", { user: user?.uid }], (data) =>
        data ? { ...data, photoUrl: downloadUrl } : data,
      );
    },
    onSuccess: () => {
      toast({
        title: "User image uploaded!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Failed to upload profile image!",
        variant: "destructive",
      });
    },
  });
};
export const useDeletePhoto = () => {
  const [user] = useAppAuthState();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      if (!user) return;

      if (user.photoURL) {
        await removeFileFromStorage(user.photoURL);
        await updateProfile(user, {
          photoURL: "",
        });
        await setPersonalData({ photoUrl: null });
        queryClient.setQueryData<PersonalDataWithStripe>(["firebase-user-data", { user: user?.uid }], (data) =>
          data ? { ...data, photoUrl: null } : data,
        );
      }
    },
    onSuccess: () => {
      toast({
        title: "User image deleted!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Failed to delete profile image!",
        variant: "destructive",
      });
    },
  });
};

export const useMyAvatarFallback = () => {
  const { data: userData } = useAuthUserData();
  return useMemo(() => getAvatarFallback(userData?.displayName, userData?.uid), [userData]);
};

export const useAuthUserDataBackup = (): {
  /// Personal data as required, null of there is no user, undefined is if the query is not yet finalized
  data: PersonalDataWithStripe | null | undefined;
  isLoading: boolean;
  isError: Error | boolean;
} => {
  const user = useAppStateStore((state) => state.user);
  const { data, isLoading, isError } = useQuery({
    queryKey: ["firebase-user-data", { user: user?.uid }],
    queryFn: getPersonalDataWithStripe,
    enabled: user?.uid !== undefined && user?.uid !== null,
  });

  const responseData = useMemo(() => (user === null ? null : data), [data, user]);
  console.log(user);
  return { data: responseData, isLoading: isLoading, isError: isError };
};

export const useDownloadUrlFromPublicUrl = (docId?: string | null) => {
  const [result, setResult] = useState<undefined | string>(undefined);
  const url = useMemo<{
    status: "invalid" | "base64" | "firebase";
    value: string | StorageReference;
  }>(() => {
    if (!docId)
      return {
        status: "invalid",
        value: "",
      };
    if (docId.startsWith("data:image"))
      return {
        status: "base64",
        value: docId,
      };
    if (docId.startsWith("http"))
      return {
        status: "base64",
        value: docId,
      };
    return {
      status: "firebase",
      value: getFlashCardImageRef(docId),
    };
  }, [docId]);

  useEffect(() => {
    void (async () => {
      if (url.status === "invalid") setResult(undefined);
      else if (url.status === "base64") setResult(url.value as string);
      else setResult(await getDownloadURL(url.value as StorageReference));
    })();
  }, [url]);

  return result;
};

export const useDownloadUrlFromPublicUrlNoteImage = (src: string) => {
  const [result, setResult] = useState<undefined | string>(undefined);
  const url = useMemo<{
    status: "invalid" | "firebase";
    value: string | StorageReference;
  }>(() => {
    if (!src)
      return {
        status: "invalid",
        value: "",
      };

    const url = new URL(src);
    const [, , userId, noteId, imageId] = url.pathname.split("/");

    return {
      status: "firebase",
      value: ref(noteParagraphImageBucket, `${userId}/${noteId}/${imageId}`),
    };
  }, [src]);

  useEffect(() => {
    void (async () => {
      if (url.status === "invalid") setResult(undefined);
      else setResult(await getDownloadURL(url.value as StorageReference));
    })();
  }, [url]);

  return result;
};

export const useFirebaseUserId = () => {
  const [user] = useAppAuthState();
  const { matched: isDemo } = useIsDemoLikePage();

  return useMemo(() => (isDemo ? DEMO_USER_ID : user?.uid ?? ""), [isDemo, user?.uid]);
};

export const useUserPublicData = ({ userId = "" }: { userId: string }) => {
  return useDocumentOnce<PublicUserData>(doc(db, "users", userId, "public", "account"));
};

export const useUsersPublicData = ({ userIds }: { userIds: string[] }) => {
  const queries = useMemo(() => {
    return userIds.map((userId) => ({
      queryKey: ["user-public-data", userId],
      queryFn: async () => {
        const res = await getDoc(doc(db, "users", userId, "public", "account"));
        const result = res.data() as unknown as PublicUserData;
        return { ...result, userId: userId };
      },
      staleTime: Infinity,
    }));
  }, [userIds]);

  return useQueries({
    queries,
  });
};
