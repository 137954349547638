import { type ForwardRefExoticComponent, useCallback, useEffect, useMemo } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useProjectChatHistory } from "@/api/document-chat";
import { useCurrentWorkspace, useWorkspace } from "@/api/workspace.ts";
import { ResourceCardType } from "@/assets/constants/constants.ts";
import { ModalKeys } from "@/assets/constants/modal.ts";
import {
  ChartIcon,
  BookIcon,
  GateIcon,
  MessageDotsIcon,
  FolderIcon,
  SunCloudIcon,
  BusIcon,
  UfoIcon,
  SocksIcon,
  FingerRockIcon,
  BombIcon,
  RabbitIcon,
  MushroomIcon,
  TulipIcon,
  CameraIcon,
  MusicIcon,
  FilmIcon,
  RoadIcon,
  UmbrellaIcon,
  CompassIcon,
  EarthIcon,
  FootIcon,
  WineGlassIcon,
  IceCreamIcon,
  CarrotIcon,
  WineBottleAndGlassIcon,
  PaperIcon,
  ArchiveIcon,
  DizzyFaceIcon,
  SweatFaceIcon,
  CluelessFaceIcon,
  LeftFaceIcon,
  WinkingFaceIcon,
  AngelFaceIcon,
  AtomIcon,
  MagnetIcon,
  GlobeIcon,
  PotionIcon,
  DiaryIcon,
  CalculatorIcon,
  Compass2Icon,
  BookmarkIcon,
  Book2Icon,
  BalanceIcon,
  BackpackIcon,
  TranslateIcon,
  RulerPencilIcon,
  AsteriskIcon,
  TelescopeIcon,
  Telescope2Icon,
  SpeakerIcon,
  SolarPanelIcon,
  AntennaIcon,
  RadioIcon,
  PuzzleIcon,
  MidiIcon,
  PowerLineIcon,
  KeyboardIcon,
  MicroscopeIcon,
  LaptopIcon,
  GuitarIcon,
  ControllerIcon,
  LightningIcon,
  LampIcon,
  CamcoderIcon,
  BulbIcon,
  AlarmIcon,
  CursorIcon,
  ClickIcon,
  HtmlIcon,
  LtgtIcon,
  BugIcon,
  NoteIcon,
  RulerIcon,
  ScissorsIcon,
  FeatherIcon,
  PaintRollIcon,
  PaletteIcon,
  WizardHatIcon,
  LayersIcon,
  FlashcardsIcon,
  BlingIcon,
  MailIcon,
  EmailIcon,
  EnvelopeIcon,
  TargetIcon,
  WalletIcon,
  TagIcon,
  SofaIcon,
  Presentation1Icon,
  Presentation2Icon,
  BaggageIcon,
  MoneyPigIcon,
  CoinIcon,
  PieChartIcon,
  BarChartIcon,
  CashIcon,
  CelebrateIcon,
  CalendarIcon,
  ShoppingBasketIcon,
  AnniversaryIcon,
  AdIcon,
  SydneyIcon,
  NewYorkIcon,
  PizzaTowerIcon,
  HospitalIcon,
  WindmillIcon,
  PyramidsIcon,
  BridgeIcon,
  BankIcon,
} from "@/components/v3/icons/library";
import { type FolderElement, type PlainDocChatSession, ProjectIconsEnum } from "@/types/schemas";
import { useModalState } from "@/zustand/slices/modal-slice.ts";
import useAppStateStore from "@/zustand/store.ts";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumToProjectIcon: { [key in ProjectIconsEnum]: ForwardRefExoticComponent<any> } = {
  [ProjectIconsEnum.CHART]: ChartIcon,
  [ProjectIconsEnum.BOOK]: BookIcon,
  [ProjectIconsEnum.GATE]: GateIcon,
  [ProjectIconsEnum.MESSAGE_DOTS]: MessageDotsIcon,
  [ProjectIconsEnum.FOLDER]: FolderIcon,
  [ProjectIconsEnum.SUN_CLOUD]: SunCloudIcon,
  [ProjectIconsEnum.BUS]: BusIcon,
  [ProjectIconsEnum.UFO]: UfoIcon,
  [ProjectIconsEnum.SOCKS]: SocksIcon,
  [ProjectIconsEnum.FINGER_ROCK]: FingerRockIcon,
  [ProjectIconsEnum.BOMB]: BombIcon,
  [ProjectIconsEnum.RABBIT]: RabbitIcon,
  [ProjectIconsEnum.MUSHROOM]: MushroomIcon,
  [ProjectIconsEnum.TULIP]: TulipIcon,
  [ProjectIconsEnum.CAMERA]: CameraIcon,
  [ProjectIconsEnum.MUSIC]: MusicIcon,
  [ProjectIconsEnum.FILM]: FilmIcon,
  [ProjectIconsEnum.ROAD]: RoadIcon,
  [ProjectIconsEnum.UMBRELLA]: UmbrellaIcon,
  [ProjectIconsEnum.COMPASS]: CompassIcon,
  [ProjectIconsEnum.EARTH]: EarthIcon,
  [ProjectIconsEnum.FOOT]: FootIcon,
  [ProjectIconsEnum.WINE_GLASS]: WineGlassIcon,
  [ProjectIconsEnum.ICE_CREAM]: IceCreamIcon,
  [ProjectIconsEnum.CARROT]: CarrotIcon,
  [ProjectIconsEnum.WINE_BOTTLE_AND_GLASS]: WineBottleAndGlassIcon,
  [ProjectIconsEnum.PAPER]: PaperIcon,
  [ProjectIconsEnum.ARCHIVE]: ArchiveIcon,
  [ProjectIconsEnum.DIZZY_FACE]: DizzyFaceIcon,
  [ProjectIconsEnum.SWEAT_FACE]: SweatFaceIcon,
  [ProjectIconsEnum.CLUESESS_FACE]: CluelessFaceIcon,
  [ProjectIconsEnum.LEFT_FACE]: LeftFaceIcon,
  [ProjectIconsEnum.WINKING_FACE]: WinkingFaceIcon,
  [ProjectIconsEnum.ANGEL_FACE]: AngelFaceIcon,
  [ProjectIconsEnum.ATOM]: AtomIcon,
  [ProjectIconsEnum.MAGNET]: MagnetIcon,
  [ProjectIconsEnum.GLOBE]: GlobeIcon,
  [ProjectIconsEnum.POTION]: PotionIcon,
  [ProjectIconsEnum.DIARY]: DiaryIcon,
  [ProjectIconsEnum.CALCULATOR]: CalculatorIcon,
  [ProjectIconsEnum.COMPASS_2]: Compass2Icon,
  [ProjectIconsEnum.BOOKMARK]: BookmarkIcon,
  [ProjectIconsEnum.BOOK_2]: Book2Icon,
  [ProjectIconsEnum.BALANCE]: BalanceIcon,
  [ProjectIconsEnum.BACKPACK]: BackpackIcon,
  [ProjectIconsEnum.TRANSLATE]: TranslateIcon,
  [ProjectIconsEnum.RULER_PENCIL]: RulerPencilIcon, // Rename to RULER_PENCIL
  [ProjectIconsEnum.ASTERISK]: AsteriskIcon,
  [ProjectIconsEnum.TELESCOPE]: TelescopeIcon,
  [ProjectIconsEnum.TELESCOPE_2]: Telescope2Icon,
  [ProjectIconsEnum.SPEAKER]: SpeakerIcon,
  [ProjectIconsEnum.SOLAR_PANEL]: SolarPanelIcon,
  [ProjectIconsEnum.ANTENNA]: AntennaIcon,
  [ProjectIconsEnum.RADIO]: RadioIcon,
  [ProjectIconsEnum.PUZZLE]: PuzzleIcon,
  [ProjectIconsEnum.MIDI]: MidiIcon,
  [ProjectIconsEnum.POWER_LINE]: PowerLineIcon,
  [ProjectIconsEnum.KEYBOARD]: KeyboardIcon,
  [ProjectIconsEnum.MICROSCOPE]: MicroscopeIcon,
  [ProjectIconsEnum.LAPTOP]: LaptopIcon,
  [ProjectIconsEnum.GUITAR]: GuitarIcon,
  [ProjectIconsEnum.CONTROLLER]: ControllerIcon,
  [ProjectIconsEnum.LIGHTNING]: LightningIcon,
  [ProjectIconsEnum.LAMP]: LampIcon,
  [ProjectIconsEnum.CAMCODER]: CamcoderIcon,
  [ProjectIconsEnum.BULB]: BulbIcon,
  [ProjectIconsEnum.ALARM]: AlarmIcon,
  [ProjectIconsEnum.CURSOR]: CursorIcon,
  [ProjectIconsEnum.CLICK]: ClickIcon,
  [ProjectIconsEnum.HTML]: HtmlIcon,
  [ProjectIconsEnum.LTGT]: LtgtIcon,
  [ProjectIconsEnum.BUG]: BugIcon,
  [ProjectIconsEnum.NOTE]: NoteIcon,
  [ProjectIconsEnum.RULER]: RulerIcon,
  [ProjectIconsEnum.SCRISSORS]: ScissorsIcon,
  [ProjectIconsEnum.FEATHER]: FeatherIcon,
  [ProjectIconsEnum.PAINT_ROLL]: PaintRollIcon,
  [ProjectIconsEnum.PALETTE]: PaletteIcon,
  [ProjectIconsEnum.WIZARD_HAT]: WizardHatIcon,
  [ProjectIconsEnum.LAYERS]: LayersIcon,
  [ProjectIconsEnum.FLASHCARDS]: FlashcardsIcon,
  [ProjectIconsEnum.BLING]: BlingIcon,
  [ProjectIconsEnum.MAIL]: MailIcon,
  [ProjectIconsEnum.EMAIL]: EmailIcon,
  [ProjectIconsEnum.ENVELOPE]: EnvelopeIcon,
  [ProjectIconsEnum.TARGET]: TargetIcon,
  [ProjectIconsEnum.WALLET]: WalletIcon,
  [ProjectIconsEnum.TAG]: TagIcon,
  [ProjectIconsEnum.SOFA]: SofaIcon,
  [ProjectIconsEnum.PRESENTATION_1]: Presentation1Icon,
  [ProjectIconsEnum.PRESENTATION_2]: Presentation2Icon,
  [ProjectIconsEnum.BAGGAGE]: BaggageIcon,
  [ProjectIconsEnum.MONEY_PIG]: MoneyPigIcon,
  [ProjectIconsEnum.COIN]: CoinIcon,
  [ProjectIconsEnum.PIE_CHART]: PieChartIcon,
  [ProjectIconsEnum.BAR_CHART]: BarChartIcon,
  [ProjectIconsEnum.CASH]: CashIcon,
  [ProjectIconsEnum.CELEBRATE]: CelebrateIcon,
  [ProjectIconsEnum.CALENDAR]: CalendarIcon,
  [ProjectIconsEnum.SHOPPING_BASKET]: ShoppingBasketIcon,
  [ProjectIconsEnum.ANNIVERSARY]: AnniversaryIcon,
  [ProjectIconsEnum.AD]: AdIcon,
  [ProjectIconsEnum.SYDNEY]: SydneyIcon,
  [ProjectIconsEnum.NEW_YORK]: NewYorkIcon,
  [ProjectIconsEnum.PIZZA_TOWER]: PizzaTowerIcon,
  [ProjectIconsEnum.HOSPITAL]: HospitalIcon,
  [ProjectIconsEnum.WINDMILL]: WindmillIcon,
  [ProjectIconsEnum.PYRAMIDS]: PyramidsIcon,
  [ProjectIconsEnum.BRIDGE]: BridgeIcon,
  [ProjectIconsEnum.BANK]: BankIcon,
};

export const useLibraryView = (): ["row" | "grid", (view: "grid" | "row") => void] => {
  const [view, setView] = useAppStateStore((state) => [state.libraryView, state.setLibraryView]);
  return useMemo(() => [view, setView], [setView, view]);
};
export const useLibrarySorting = (): ["ASC" | "DESC", (view: "ASC" | "DESC") => void] => {
  const [view, setView] = useAppStateStore((state) => [state.librarySorting, state.setLibrarySorting]);
  return useMemo(() => [view, setView], [setView, view]);
};

export const useOpenSignUpToQuinoModal = () => {
  const [, setModalState] = useModalState(ModalKeys.SIGN_UP_TO_QUINO);

  return useCallback(() => setModalState(true), [setModalState]);
};

export const useUpgradePlanModal = () => {
  return useModalState(ModalKeys.UPDATE_PLAN_MODAL_OPEN);
};

export const sortFolderElements = <T extends { elementType: ResourceCardType; name: string }>(
  folderElements: T[],
  filters?: Array<ResourceCardType> | undefined,
): T[] =>
  folderElements
    .filter((item) => item !== undefined)
    .filter(({ elementType }) => (filters ? filters.includes(elementType) : true))
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => {
      const typeA = a.elementType;
      const typeB = b.elementType;
      if (typeA === typeB && typeA === ResourceCardType.FOLDER_ELEMENT) {
        return 0;
      }
      if (typeA === ResourceCardType.FOLDER_ELEMENT && typeB !== ResourceCardType.FOLDER_ELEMENT) {
        return -1;
      }
      if (typeB === ResourceCardType.FOLDER_ELEMENT && typeA !== ResourceCardType.FOLDER_ELEMENT) {
        return 1;
      }
      return 0;
    })
    .filter(({ elementType }) => elementType !== ResourceCardType.FLASHCARD_DECK_ELEMENT);

export const useSortedFolderElementData = ({
  folderElements,
  filters,
}: {
  folderElements?: FolderElement[];
  filters?: Array<ResourceCardType> | undefined;
}) => {
  return useMemo(
    () => {
      if (!folderElements) return null;
      return sortFolderElements<FolderElement>(folderElements, filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(folderElements)],
  );
};

export const useSidebarLayoutOpen = (): [boolean, (value: boolean) => void] => {
  const [open, setState] = useAppStateStore((state) => [state.sideBarOpen, state.setSideBarOpen]);

  return [open, setState];
};

export const useCurrentProjectChatHistory = (): PlainDocChatSession[] => {
  const { projectId } = useParams();
  const { data: historyData } = useProjectChatHistory(projectId);

  return useMemo<PlainDocChatSession[]>(() => {
    if (!historyData) return [];
    return Object.values(historyData.docChatSessions)
      .map((array) => array)
      .reduce((accumulator, value) => {
        accumulator.push(...value);
        return accumulator;
      }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(historyData)]);
};

export const useIsSelectedDocument = (resourceId: string) => {
  const [selectedResources] = useAppStateStore((state) => [state.selectedResources]);
  return useMemo(
    () => selectedResources.find(({ id }) => id == resourceId) != undefined,
    [selectedResources, resourceId],
  );
};

export const useIsSelectedWorkspace = (workspaceId: string) => {
  const [selectedWorkspaces] = useAppStateStore((state) => [state.selectedProjects]);
  return useMemo(
    () => selectedWorkspaces.find(({ id }) => id == workspaceId) != undefined,
    [selectedWorkspaces, workspaceId],
  );
};

export const useDocumentSelectionState = () =>
  useAppStateStore((state) => ({
    anySelected: state.selectedResources.length > 0,
    selectedResources: state.selectedResources,
    selectResource: state.selectResource,
    unselectResource: state.unselectResource,
    selectAllResources: state.selectAllResources,
    unselectAllResources: state.unselectAllResources,
  }));

export const useProjectSelectionState = () =>
  useAppStateStore((state) => ({
    anySelected: state.selectedProjects.length > 0,
    selectedProjects: state.selectedProjects,
    selectProject: state.selectProject,
    unSelectProject: state.unSelectProject,
    selectAllProjects: state.selectAllProjects,
    unSelectAllProjects: state.unSelectAllProjects,
  }));

export const useClearSelectionOnPageChange = () => {
  const { unselectAllResources } = useDocumentSelectionState();
  const { unSelectAllProjects } = useProjectSelectionState();
  const location = useLocation();
  useEffect(() => {
    unselectAllResources();
    unSelectAllProjects();
  }, [unselectAllResources, location.pathname, unSelectAllProjects]);
};

export const useProjectOfCurrentWorkspace = (projectId?: string) => {
  const { data: workspace } = useCurrentWorkspace();
  return useMemo(() => {
    if (!workspace) return undefined;
    return workspace.projects.find((project) => project.id === projectId);
  }, [projectId, workspace]);
};

export const useCurrentSelectedProject = () => {
  const { selectedProjects } = useProjectSelectionState();
  const projectId = useMemo(() => {
    if (!selectedProjects) return undefined;
    if (selectedProjects.length === 0) return undefined;
    return selectedProjects[0].id;
  }, [selectedProjects]);
  return useProjectOfCurrentWorkspace(projectId);
};

export const useIsMyWorkspace = (workspaceId: string) => {
  const { data: workspace } = useWorkspace(workspaceId);
  return useMemo(() => workspace?.adminId === workspaceId, [workspace?.adminId, workspaceId]);
};
